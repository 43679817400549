import { GLSL3, Matrix4, RawShaderMaterial, Texture } from "three";
import frag from "../Shaders/SsBlur1D.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { makeUniform } from "./Uniforms";
/**
 * A shader to blur the single channel.
 *
 * It takes as input a monochannel floating point texture, and it blurs its data
 * to an output texture of the same format. It uses a Bilateral Blur filter to
 * avoid blurring across depth discontinuities. This helps preserving edges between
 * objects in the foreground and objects in the background.
 */
export class SsBlur1DMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uSingleChannelTex: makeUniform<Texture | null>(null),
		uDepthTex: makeUniform<Texture | null>(null),
		uProjectionMatrixInverse: makeUniform(new Matrix4()),
		uRadius: makeUniform<number>(0.5),
		uLargeKernel: makeUniform<boolean>(false),
	};

	constructor() {
		super({ glslVersion: GLSL3 });
	}
}
