import { TextField, TextFieldProps } from "../input/text-field";
import { ContentEditable } from "./content-editable";
import { FaroRichTextCore, FaroRichTextCoreProps } from "./faro-rich-text-core";

export type FaroRichTextEditorProps = Pick<
  FaroRichTextCoreProps,
  | "dark"
  | "sx"
  | "onError"
  | "onChange"
  | "label"
  | "placeholder"
  | "initialText"
  | "readOnly"
> &
  Pick<TextFieldProps, "fullWidth">;

/**
 * @returns Version of the FaroRichText component that is specialized for editing the content
 */
export function FaroRichTextEditor({
  fullWidth,
  dark,
  sx,
  label,
  placeholder,
  onError,
  onChange,
  readOnly,
  initialText,
}: FaroRichTextEditorProps): JSX.Element {
  return (
    <FaroRichTextCore
      onError={onError}
      initialText={initialText}
      onChange={onChange}
      dark={dark}
      sx={sx}
    >
      <TextField
        multiline
        fullWidth={fullWidth}
        disabled={readOnly}
        label={label}
        slots={{
          input: ContentEditable,
        }}
        inputProps={{ placeholder, dark }}
      />
    </FaroRichTextCore>
  );
}
