import { ApiResponseError } from "../authentication";
import { ErrorMessage } from "./core-api-responses";

/**
 * Error codes returned by the Core API.
 * Note: The list is not complete.
 */
export enum ErrorV2Code {
  /** Missing authentication or missing permissions */
  authenticationError = "authentication_error",
}

/**
 * Error to be thrown when the Core API responds with an error
 */
export class CoreApiError<S extends number = number> extends ApiResponseError<
  S,
  ErrorMessage
> {
  /** Add a more specific name to this error for better reporting */
  name = "Core API Error";

  /** The error code returned by the backend */
  errorV2: ErrorV2Code | string | undefined;

  /**
   * @param status The HTTP error code
   * @param statusText The corresponding text for the HTTP error code
   * @param body Contains the Error Message object returned by the Core API
   */
  constructor(
    public status: S,
    public statusText: string,
    public body: ErrorMessage,
  ) {
    super(status, statusText, body);
    this.message = body.message || "An unknown error occurred";
    this.errorV2 = body.error_v2;
  }
}

/**
 * Note: An authentication error is also thrown when the user has valid authentication, but is missing permission
 *
 * @param error a thrown error
 * @returns whether a thrown error is a Core API authentication error
 */
export function isCoreApiAuthenticationError(
  error: unknown,
): error is CoreApiError {
  return (
    error instanceof CoreApiError &&
    error.errorV2 === ErrorV2Code.authenticationError
  );
}
