import { Camera, Object3D, Scene, WebGLRenderTarget, WebGLRenderer } from "three";
import { CameraMonitor } from "../Utils";
import { EffectPipeline } from "./EffectPipeline";
import { FboContainer } from "./FboContainer";
import { FilteredRenderPass } from "./FilteredRenderPass";
import { RenderComposerPass } from "./RenderComposerPass";

/**
 * Function to select what a sub scene should render of the main scene
 */
export type SceneFilterFunction = (object: Object3D) => boolean;

/**
 * This object keep track of a sub-scene of an effect pipeline
 *
 * A sub scene is defined as the initial rendering of the scene (all of it or only a filtered view)
 * followed by a list of post processing pass that will apply only to this sub-scene
 *
 * Then the sub-scene can be blended with other sub-scenes in the main EffectPipeline to create the
 * complete scene
 */
export class SubScenePipeline extends EffectPipeline implements FboContainer {
	#renderPass: FilteredRenderPass;
	#blendPass: RenderComposerPass;
	#cameraMonitor = new CameraMonitor();

	/**
	 * Create a SubScenePipeline inside an EffectPipeline
	 *
	 * @param renderer The renderer used to render this sub-scene
	 * @param scene The scene we want to render
	 * @param camera The camera used to render this sub-scene
	 * @param filter A filter function to decide what to render of the entire scene
	 */
	constructor(renderer: WebGLRenderer, scene: Scene, camera: Camera, filter: SceneFilterFunction) {
		super(renderer);
		this.composer.renderToScreen = false;
		this.#renderPass = new FilteredRenderPass(scene, camera, filter);
		this.composer.addPass(this.#renderPass);
		this.#blendPass = new RenderComposerPass(this.composer, false);
		this.mustReRender = this.mustReRender.bind(this);
		this.#blendPass.mustReRender = this.mustReRender;
	}

	/** @returns whether something changed in the camera pose or projection, invalidating the rendering result of the last frame. */
	private mustReRender(): boolean {
		return this.#cameraMonitor.cameraChanged(this.#renderPass.camera);
	}

	/** @returns whether this subscene should be rendered on demand or  not */
	get renderOnDemand(): boolean {
		return this.#blendPass.renderOnDemand;
	}

	/** Sets whether this subscene should be rendered on demand or not */
	set renderOnDemand(r: boolean) {
		this.#blendPass.renderOnDemand = r;
	}

	/**
	 * When rendering on demand is active, calling this will trigger a full re-render
	 * of this subsccene at the next frame.
	 */
	invalidate(): void {
		this.#blendPass.lastFBOvalid = false;
	}

	/** @inheritdoc */
	override setSize(width: number, height: number, dpr: number): boolean {
		const ret = super.setSize(width, height, dpr);
		if (ret) this.invalidate();
		return ret;
	}

	/** @inheritdoc */
	override changeCamera(camera: Camera): void {
		if (camera !== this.#renderPass.camera) {
			super.changeCamera(camera);
			this.invalidate();
		}
	}

	/** @inheritdoc */
	override changeScene(scene: Scene): void {
		if (scene !== this.#renderPass.scene) {
			super.changeScene(scene);
			this.invalidate();
		}
	}

	/** @returns the pass to blend this subscene in an effect pipeline */
	get blendPass(): RenderComposerPass {
		return this.#blendPass;
	}

	/** @returns the pass used in this sub-scene to render the sub-scene */
	get renderPass(): FilteredRenderPass {
		return this.#renderPass;
	}

	/** @returns true if this sub-scene will clear the depth buffer before blending */
	get clearDepths(): boolean {
		return this.#blendPass.clearDepth;
	}

	/** Change the clear depth flag */
	set clearDepths(clear: boolean) {
		this.#blendPass.clearDepth = clear;
	}

	/** @returns the filter function used to decide what object to render in this sub-scene */
	get filter(): SceneFilterFunction {
		return this.#renderPass.filter;
	}

	/** Change the current bound filter */
	set filter(f: SceneFilterFunction) {
		this.#renderPass.filter = f;
	}

	/** @returns the camera monitor owned by this subscene */
	get cameraMonitor(): CameraMonitor {
		return this.#cameraMonitor;
	}

	/**
	 * Renders the internal effect composer storing the result in the offscreen FBO.
	 * The rendering may be skipped if 'renderOnDemand' is true and if the FBO from
	 * the last frame is still valid.
	 *
	 * @param renderer The webGL renderer used to render
	 * @param delta Time interval from last rendering call
	 */
	renderToFBO(renderer: WebGLRenderer, delta: number): void {
		this.#blendPass.renderComposerToFBO(renderer, delta);
	}

	/** @returns the offscreen framebuffer onto which the subscene is rendered. */
	get offscreenFbo(): WebGLRenderTarget {
		return this.composer.readBuffer;
	}

	/** @returns whether this subscene is enabled */
	get enabled(): boolean {
		return this.#blendPass.enabled;
	}

	/** Sets whether this subscene is enabled */
	set enabled(e: boolean) {
		this.#blendPass.enabled = e;
	}
}
