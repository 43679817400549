/**
 * Represents a custom field for a topic.
 */
export type TopicCustomField = {
  /** The id of the custom field */
  id: string;

  /** The values of the custom field */
  values: string[];
};

/**
 * Represents the integration types for the BCF Services API.
 */
export enum BcfServicesIntegrationType {
  autodeskAccIssues = "autodesk-acc.issues",
  autodeskAccRfis = "autodesk-acc.rfis",
  autodeskBim360Issues = "autodesk-bim360.issues",
  autodeskBim360Rfis = "autodesk-bim360.rfis",
  procoreObservations = "procore.observations",
  procoreRfis = "procore.rfis",
}
