import { TypedEvent } from "@faro-lotv/foundation";
import { Camera, EventDispatcher, Object3D, Raycaster, Vector2 } from "three";
import { PointerCoords, TouchEvents } from "./TouchEvents";

/** Controls for intersecting the mouse with a list of 3D objects */
export class SelectionControls extends EventDispatcher {
	/** Event emitted when an object is hovered */
	hovered = new TypedEvent<Object3D | undefined>();

	/** Event emitted when an object is clicked */
	clicked = new TypedEvent<Object3D[]>();

	/** Enable/disable the controls while remaining attached to the HTMLElement, if defined */
	enabled = true;

	/** The list of objects used for intersection tests */
	objects: Object3D[] = [];

	/** Enable recursive picking on the provided objects */
	recursive = false;

	/** The events manager */
	#touchEvents = new TouchEvents();

	/** The raycaster used to compute the intersection */
	#raycaster = new Raycaster();

	/**
	 * Create a controls that uses the input
	 *
	 * @param camera The camera used to compute the intersection with the scene
	 * @param target The optional HTMLElement whose events should be processed
	 */
	constructor(
		public camera?: Camera,
		target?: HTMLElement,
	) {
		super();

		this.onMouseMoved = this.onMouseMoved.bind(this);
		this.#touchEvents.mouseMoved.on(this.onMouseMoved);

		this.onMouseClicked = this.onMouseClicked.bind(this);
		this.#touchEvents.mouseSingleClicked.on(this.onMouseClicked);

		this.onTouchTapped = this.onTouchTapped.bind(this);
		this.#touchEvents.tapGesture.on(this.onTouchTapped);

		if (target) {
			this.#touchEvents.attach(target);
		}
	}

	/**
	 * Change the HTML Element which events should be processed
	 *
	 * @param target The target HTMLElement
	 */
	attach(target: HTMLElement): void {
		this.#touchEvents.attach(target);
	}

	/**
	 * Stops the controls from listening to any events
	 */
	detach(): void {
		this.#touchEvents.detach();
	}

	/**
	 * Setup the raycaster used to compute the intersection between the mouse
	 * and the line set
	 *
	 * @param x The x coordinate of the mouse, in pixels
	 * @param y The y coordinate of the mouse, in pixels
	 */
	#setupRayCaster(x: number, y: number): void {
		if (!this.#touchEvents.element || !this.camera) {
			return;
		}
		const target = this.#touchEvents.element;
		const mouse = new Vector2((x / target.clientWidth) * 2 - 1, 1 - (y / target.clientHeight) * 2);
		this.#raycaster.setFromCamera(mouse, this.camera);
	}

	/**
	 * Find if a segment is hovered and send an event to
	 * notify what was found.
	 */
	private onMouseMoved(): void {
		if (!this.camera || !this.enabled) {
			return;
		}

		const pos = this.#touchEvents.mouseCoords.position;
		this.#setupRayCaster(pos.x, pos.y);
		const intersections = this.#raycaster.intersectObjects<Object3D>(this.objects, this.recursive);
		if (intersections.length > 0) {
			this.hovered.emit(intersections[0].object);
		} else {
			this.hovered.emit(undefined);
		}
	}

	/**
	 * Emit a signal with the current hovered segment when clicking with the mouse
	 */
	private onMouseClicked(): void {
		if (!this.camera || !this.enabled) {
			return;
		}
		const pos = this.#touchEvents.mouseCoords.position;
		this.#setupRayCaster(pos.x, pos.y);
		const intersections = this.#raycaster.intersectObjects<Object3D>(this.objects, this.recursive);
		if (intersections.length > 0) {
			this.clicked.emit(intersections.map((intersection) => intersection.object));
		}
	}

	/**
	 * Emit a signal of the clicked segment
	 *
	 * @param ev The tap event
	 */
	private onTouchTapped(ev: PointerCoords): void {
		if (!this.camera || !this.enabled) {
			return;
		}
		this.#setupRayCaster(ev.position.x, ev.position.y);
		const intersections = this.#raycaster.intersectObjects<Object3D>(this.objects, this.recursive);
		if (intersections.length > 0) {
			this.clicked.emit(intersections.map((intersection) => intersection.object));
		}
	}
}
