import { InvalidConfigurationError } from "@faro-lotv/foundation";
import { Camera, WebGLRenderer, WebGLRenderTarget } from "three";
import { EDL_DEFAULT_STRENGTH, EyeDomeMaterial } from "../Materials/EyeDomeMaterial";
import { FullScreenPass } from "./FullScreenPass";

/**
 * A ThreeJS effect pass to add EyeDome shading to a render pipeline
 */
export class EyeDomePass extends FullScreenPass<EyeDomeMaterial> {
	/**
	 * Construct an EyeDomePass
	 *
	 * @param camera The camera used to render the scene, needed to unproject the depth
	 */
	constructor(public camera: Camera) {
		super(new EyeDomeMaterial());
		this.clear = true;
	}

	/**
	 * Render the EyeDome effect on top of a previous rendering
	 *
	 * @param renderer The renderer
	 * @param writeBuffer The write buffer for this pass
	 * @param readBuffer The read buffer for this pass
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("EyeDome pass requires a depth texture in the composer FBO");
		}
		this.material.uniforms.uEDLColor.value = readBuffer.texture;
		this.material.uniforms.uEDLDepth.value = readBuffer.depthTexture;
		this.material.uniforms.uInvProj.value.copy(this.camera.projectionMatrixInverse);
		this.material.uniformsNeedUpdate = true;

		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		if (this.clear) renderer.clear(renderer.autoClearColor, renderer.autoClearDepth, renderer.autoClearStencil);
		this.fsQuad.render(renderer);
	}

	/** @returns EDL strength. Should be greater than 0.0 */
	get strength(): number {
		return this.material.uniforms.edlStrength.value / EDL_DEFAULT_STRENGTH;
	}

	/** Sets the EDL strength. Clamped to not go below 0 */
	set strength(s: number) {
		s = Math.max(s, 0);
		this.material.uniforms.edlStrength.value = s * EDL_DEFAULT_STRENGTH;
	}

	/** @returns the radius of the neighborhood on which EDL is computed, in pixels */
	get radius(): number {
		return this.material.uniforms.radius.value;
	}

	/** Sets the radius of the neighborhood on which EDL is computed, in pixels clamped to not go below 1*/
	set radius(r: number) {
		r = Math.max(r, 0);
		this.material.uniforms.radius.value = r;
	}

	/**
	 * @returns the threshold in meters used to distinguish
	 * wether two neighboring pixels are on the same surface or on a depth discontinuity.
	 * This allows to have the option of coloring depth discontinuities with the black outlines or not.
	 */
	get depthDiscontinuityBias(): number {
		return this.material.uniforms.depthDiscontinuityBias.value;
	}

	/** Sets the depth discontinuity detection threshold */
	set depthDiscontinuityBias(d: number) {
		this.material.uniforms.depthDiscontinuityBias.value = d;
	}

	/** @returns Whether the shader shows only the eye dome shade in grayscale or whether it blends with the original color  */
	get showShadeOnly(): boolean {
		return this.material.uniforms.showShadeOnly.value;
	}

	/** Sets whether the shader shows only the eye dome shade in grayscale, or whether it blends with the original color  */
	set showShadeOnly(s: boolean) {
		this.material.uniforms.showShadeOnly.value = s;
	}

	/** @returns whether the shader shows the black outlines on depth discontinuities or not */
	get showBlackOutlines(): boolean {
		return this.material.uniforms.showBlackOutlines.value;
	}

	/** Sets whether the shader shows the black outlines on depth discontinuities or not */
	set showBlackOutlines(s: boolean) {
		this.material.uniforms.showBlackOutlines.value = s;
	}
}
