/** Binary heap */
export class BinaryHeap<T extends object> {
	/** The array storing the content of the heap */
	content: T[] = [];
	/** The callback used to compute the score of a given element */
	scoreFunction: (element: T) => number;
	/** The callback used to compute the unique id of the element*/
	elementToID: (element: T) => number;
	/**
	 * Map storing for each element in the heap the index in the array.
	 * The structure used is an object since it's implemented as an hash map.
	 * In the future we could change this to an array to trade speed for memory.
	 */
	private contentToIndex: Record<number, number> = {};

	/**
	 * Create an empty heap
	 *
	 * @param scoreFunction The function used to compute the element priority
	 * @param elementToID The function to compute the unique id of an element in the queue
	 */
	constructor(scoreFunction: (element: T) => number, elementToID: (element: T) => number) {
		this.scoreFunction = scoreFunction;
		this.elementToID = elementToID;
	}

	/**
	 * Push an element in the heap
	 *
	 * @param element The new element
	 */
	push(element: T): void {
		// Add the new element to the end of the array.
		this.content.push(element);
		// Set the index of the element equal to the last index of the array
		this.contentToIndex[this.elementToID(element)] = this.content.length - 1;
		// Allow it to bubble up.
		this.bubbleUp(this.content.length - 1);
	}

	/**
	 * Remove the element with the highest priority from the heap
	 *
	 * @returns The element removed
	 */
	pop(): T {
		// Store the first element so we can return it later.
		const result = this.content[0];
		// Remove the index of the element from the map
		delete this.contentToIndex[this.elementToID(result)];
		// Get the element at the end of the array.
		const end = this.content.pop();
		// If there are any elements left, put the end element at the
		// start, and let it sink down.
		if (this.content.length > 0 && end) {
			this.content[0] = end;
			// Updated the index of the element that has moved at the start of the heap
			this.contentToIndex[this.elementToID(end)] = 0;
			// Allow the element to sink down the tree
			this.sinkDown(0);
		}
		return result;
	}

	/**
	 * Remove an element from the heap
	 *
	 * @param element The element to remove
	 */
	remove(element: T): void {
		// Get element id
		const index = this.getIndex(element);
		if (index === undefined) {
			return;
		}

		// When it is found, the process seen in 'pop' is repeated
		// to fill up the hole.
		const end = this.content.pop();
		if (!end) return;
		// If the element we popped was the one we needed to remove,
		// we're done.
		if (index === length - 1) return;
		// Otherwise, we replace the removed element with the popped
		// one, and allow it to float up or sink down as appropriate.
		this.content[index] = end;
		// Updated the index of the element that has moved in this position
		this.contentToIndex[this.elementToID(end)] = index;
		this.bubbleUp(index);
		this.sinkDown(index);
	}

	/**
	 * Remove all elements from the binary heap
	 */
	clear(): void {
		this.content.length = 0;
		this.contentToIndex = {};
	}

	/**
	 * Find the index of the element, if in the heap
	 *
	 * @param element The element to find
	 * @returns The index of the found element, undefined otherwise
	 */
	getIndex(element: T): number | undefined {
		return this.contentToIndex[this.elementToID(element)];
	}

	/**
	 * @returns The number of elements in the heap
	 */
	get length(): number {
		return this.content.length;
	}

	/**
	 * Move an element into the right position inside the heap
	 *
	 * @param n The index of the element
	 */
	bubbleUp(n: number): void {
		// Fetch the element that has to be moved.
		const element = this.content[n];
		const score = this.scoreFunction(element);
		// When at 0, an element can not go up any further.
		while (n > 0) {
			// Compute the parent element's index, and fetch it.
			const parentN = Math.floor((n + 1) / 2) - 1;
			const parent = this.content[parentN];
			// If the parent has a lesser score, things are in order and we
			// are done.
			if (score >= this.scoreFunction(parent)) break;

			// Update the indices of the two elements before swapping them
			this.contentToIndex[this.elementToID(element)] = parentN;
			this.contentToIndex[this.elementToID(parent)] = n;

			// Otherwise, swap the parent with the current element and
			// continue.
			this.content[parentN] = element;
			this.content[n] = parent;
			n = parentN;
		}
	}

	/**
	 * Move an element into the right position inside the heap
	 *
	 * @param n The index of the element
	 */
	sinkDown(n: number): void {
		// Look up the target element and its score.
		const { length } = this.content;
		const element = this.content[n];
		const elemScore = this.scoreFunction(element);

		let swap = null;
		do {
			// Compute the indices of the child elements.
			const child2N = (n + 1) * 2;
			const child1N = child2N - 1;
			// This is used to store the new position of the element,
			// if any.

			swap = null;
			let child1Score = Number.POSITIVE_INFINITY;
			// If the first child exists (is inside the array)...
			if (child1N < length) {
				// Look it up and compute its score.
				const child1 = this.content[child1N];
				child1Score = this.scoreFunction(child1);
				// If the score is less than our element's, we need to swap.
				if (child1Score < elemScore) {
					swap = child1N;
				}
			}
			// Do the same checks for the other child.
			if (child2N < length) {
				const child2 = this.content[child2N];
				const child2Score = this.scoreFunction(child2);
				if (child2Score < (swap === null ? elemScore : child1Score)) {
					swap = child2N;
				}
			}

			// No need to swap further, we are done.
			if (swap === null) {
				break;
			}

			// Update the indices of the two elements before swapping them
			this.contentToIndex[this.elementToID(this.content[swap])] = n;
			this.contentToIndex[this.elementToID(element)] = swap;

			// Otherwise, swap and continue.
			this.content[n] = this.content[swap];
			this.content[swap] = element;
			n = swap;
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		} while (swap !== null);
	}

	/**
	 * Check that the underlying array in a binary heap actually represents
	 * a binary heap
	 *
	 * @param heap The heap to check
	 */
	static checkHeapSanity<T extends object>(heap: BinaryHeap<T>): void {
		if (heap.content.length === 0) return;
		for (let i = 0; i < heap.content.length; ++i) {
			const minScore = heap.scoreFunction(heap.content[i]);
			const child1 = i * 2 + 1;
			const child2 = i * 2 + 2;
			if (child1 < heap.content.length) {
				if (heap.scoreFunction(heap.content[child1]) < minScore) {
					throw new Error(
						`Parent score ${i}:${minScore} greater than child ${child1}:${heap.scoreFunction(
							heap.content[child1],
						)}`,
					);
				}
			}

			if (child2 < heap.content.length) {
				if (heap.scoreFunction(heap.content[child2]) < minScore) {
					throw new Error(
						`Parent score ${i}:${minScore} greater than child ${child2}:${heap.scoreFunction(
							heap.content[child2],
						)}`,
					);
				}
			}

			const element = heap.content[i];
			if (heap.contentToIndex[heap.elementToID(element)] !== i) {
				throw new Error(
					`Index ${i} different from the stored one ${heap.contentToIndex[heap.elementToID(element)]}`,
				);
			}
		}
	}
}
