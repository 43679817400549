import { useCallback, useState } from "react";

/** Object returned by the useCheckForOverflow hook */
export type CheckForOverflowReturn = {
  /** True if the checked elements text is truncated */
  hasOverflown: boolean;

  /** Function to call on an element to check if it's content is overflowing */
  checkForOverflow(target: Element): void;
};

/**
 * Hook that can be used to track an element to check if it's content is overflowing the element
 *
 * @returns a boolean and a callback that can be used to track the element overflow
 */
export function useCheckForOverflow(): CheckForOverflowReturn {
  const [hasOverflown, setHasOverflown] = useState(false);

  const checkForOverflow = useCallback((currentTarget: Element) => {
    setHasOverflown(currentTarget.scrollWidth > currentTarget.clientWidth);
  }, []);

  return {
    hasOverflown,
    checkForOverflow,
  };
}
