precision highp float;
out vec4 outColor;

uniform sampler2D uEffectTex;	// effect texture
uniform sampler2D uDepthTex; 	// depth texture
uniform sampler2D uColorTex;    // color texture

in vec2 vUv;

void main()
{
    outColor = vec4( texture(uColorTex, vUv).rgb * texture(uEffectTex, vUv).r, 1.0);
    gl_FragDepth = texture(uDepthTex, vUv).r;
}
