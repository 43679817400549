import { IElementPointCloudStreamWebShare } from "@faro-lotv/ielement-types";
import {
  AdaptivePointsMaterial,
  GapFillingPointsMaterial,
  LodCachingStrategyMaxChunks,
  LodPointCloud,
  LodPointCloudRaycastingOptions,
  LodTree,
  LodTreeNode,
  PotreeTree,
  TokenProvider,
  WSInstance,
  safeDispose,
} from "@faro-lotv/lotv";
import { UPDATE_LOD_STRUCTURES_PRIORITY } from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useEffect, useMemo, useState } from "react";
import { Camera, Matrix4, Plane, Vector2, Vector3 } from "three";

// Store in memory the last 200 nodes so we don't re-fetch them
const MaxNodesInCache = 200;

/** Pick only what we need from the IElement json */
export type LodPointCloudIElementData = Pick<
  IElementPointCloudStreamWebShare,
  | "webShareProjectName"
  | "uri"
  | "webShareCloudId"
  | "webShareEntityId"
  | "pose"
>;

export type LodPointCloudProps = EventHandlers & {
  /** Opacity */
  opacity?: number;
};

export interface LodPointCloudRendererProps extends LodPointCloudProps {
  /**
   * IElement of LOD Point cloud
   */
  iElement: LodPointCloudIElementData;

  tokenProvider?: TokenProvider | undefined;

  /** List of clipping planes for this point cloud */
  clippingPlanes?: Plane[];

  /** True to clip inside the clipping planes */
  shouldClipInside?: boolean;

  /** Custom raycasting options for this pointcloud */
  raycasting?: LodPointCloudRaycastingOptions;
}

/**
 * @returns A component to load a lod point cloud from an iElement and render it with LodPointCloudRendererBase
 */
export function LodPointCloudRenderer({
  iElement,
  tokenProvider,
  raycasting,
  clippingPlanes = undefined,
  shouldClipInside = false,
  ...rest
}: LodPointCloudRendererProps): JSX.Element | null {
  // A 'tree' state variable that keeps the webshare kdtree data structure.
  // Initialized as undefined.
  const [tree, setTree] = useState<LodTree>();
  // a 'pointCloud' state variable that keeps the LodPointCloud object responsible to render the kdtree
  // Initialized as undefined.
  const [pointCloud, setPointCloud] = useState<LodPointCloud>();

  useEffect(() => {
    // Inside this useEffect hook, we define an async function and we call it.
    // This can generate a race condition, since this hook depends on some parameters
    // such as 'iElement.webShareProjectName'. Therefore, if this hook is called twice
    // quickly, it can happen that the second async funcion terminates before the first
    // causing incorrect behavior. Also, it can happen that the first async call returns
    // when the state has already changed, risking to access memory that is not valid anymore.
    // To avoid this, we set below a 'isHookStateValid' boolean to true and we reset it to false
    // in the returned cleanup function. The cloud is updated only when the boolean is true.
    // If this boolean is omitted, then an error frequently appears to console: "can't
    // perform a react state update to an unmounted component."
    let isHookStateValid = true;
    // Defining an async function that loads the required kdtree
    // and sets it to the state variable.
    async function load(): Promise<void> {
      const webshare = new WSInstance(iElement.uri, tokenProvider);
      const tree = await webshare.getKDTree(
        iElement.webShareProjectName,
        iElement.webShareCloudId,
        iElement.webShareEntityId,
      );
      // if the async function returned 'too late', just discard the tree.
      if (isHookStateValid) {
        // Correcting the lodTree gobal pose from Z-up to Y-up coordinates
        const newPose = tree.worldMatrix.clone();
        const rot = new Matrix4().makeRotationAxis(
          new Vector3(1, 0, 0),
          -Math.PI / 2,
        );
        newPose.premultiply(rot);
        // zeroing translations, they are taken care in the project file.
        newPose.elements[12] =
          newPose.elements[13] =
          newPose.elements[14] =
            0.0;
        tree.setWorldMatrix(newPose);
        setTree(tree);
      }
    }
    // Loading the webshare kdtree
    load()
      // Handling errors in fetching the kdtree
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(error);
        if (isHookStateValid) {
          setTree(undefined);
        }
      });
    return () => {
      // On state update, we set 'isHookStateValid' to false in case the
      // async function didn't return yet.
      isHookStateValid = false;
    };
  }, [setTree, iElement, tokenProvider]);

  const material = useMemo(() => new GapFillingPointsMaterial(), []);
  useEffect(() => {
    material.clippingPlanes = clippingPlanes ?? null;
    material.clipIntersection = shouldClipInside;
  }, [material, clippingPlanes, shouldClipInside]);

  // When the 'tree' changes, we also (re)load the point cloud and store it into its
  // own state variable.
  useEffect(() => {
    if (!tree) {
      return;
    }
    const newPointCloud = new LodPointCloud(tree, material, {
      lodCachingStrategy: new LodCachingStrategyMaxChunks(MaxNodesInCache),
      raycasting,
    });
    setPointCloud(newPointCloud);
    // Returing a callback on how to dispose the point cloud when the state is changed again.
    return () => {
      safeDispose(newPointCloud);
    };
  }, [tree, setPointCloud, material, raycasting]);

  if (!pointCloud) return null;
  return <LodPointCloudRendererBase pointCloud={pointCloud} {...rest} />;
}

export type LodPointCloudRendererBaseProps = EventHandlers &
  LodPointCloudProps & {
    /**
     * IElement of LOD Point cloud
     */
    pointCloud: LodPointCloud;

    /**
     * Whether the point cloud should answser to pointer events via raycasting
     *
     * @default true By default the point cloud handles pointer events via efficient raycasting on the loaded LOD nodes
     */
    raycastEnabled?: boolean;
  };

/**
 * @returns Handling the Level Of Depth (LOD) point cloud
 */
export function LodPointCloudRendererBase({
  pointCloud,
  opacity,
  raycastEnabled = true,
  ...rest
}: LodPointCloudRendererBaseProps): JSX.Element | null {
  const camera = useThree((state) => state.camera);
  const [lastCamera, setLastCamera] = useState<Camera>();

  useEffect(() => {
    // When the camera changes, we assign it to 'lastCamera' to
    // be able to update the lod point cloud at each frame.
    setLastCamera(camera);
  }, [camera, setLastCamera]);

  // make the point cloud react to changes to the 'raycastEnabled' flag
  useEffect(() => {
    pointCloud.raycasting.enabled = raycastEnabled;
  }, [raycastEnabled, pointCloud]);

  /**
   * Update the camera(s) and size of the canvas for the handling of visible nodes
   * in LOD point cloud on each frame
   */
  useFrame(({ gl }) => {
    if (!lastCamera) {
      return;
    }

    const dpr = gl.getPixelRatio();
    const size = gl.getSize(new Vector2()).multiplyScalar(dpr);
    // FIXME: this is a severe bug, captured in ticket https://faro01.atlassian.net/browse/SWEB-1588.
    // At each frame, the lod point cloud visible nodes are update using always the canvas size as screen size.
    // This is for sure wrong in any splitscreen scenario, it works only if the camera viewport is the whole R3F canvas.
    // It works by chance now, just because the splitscreen has a left-right rayout and so the viewport height is the same
    // as the whole canvas, and the potree nodes visibility computer uses only the height.
    pointCloud.updateCamera(lastCamera, size);

    // If we are using the adaptive point size material with sizeAttenuation set to true
    // (i.e., we are using different sizes for the points and not fixed ones), we
    // collect all the nodes currently loaded in GPU (i.e. the ones that are actually
    // rendered) and we update the material to compute the necessary uniforms in the shader.
    if (
      pointCloud.material instanceof AdaptivePointsMaterial &&
      pointCloud.material.sizeAttenuation &&
      pointCloud.tree instanceof PotreeTree
    ) {
      const nodes = Array<LodTreeNode>();
      for (const [nodeId, pc] of pointCloud.nodesInGPU) {
        if (pc.points.visible) {
          nodes.push(pointCloud.tree.getNode(nodeId));
        }
      }
      pointCloud.material.update(
        lastCamera,
        size,
        pointCloud.tree.spacing,
        pointCloud.tree.root.boundingBox,
        nodes,
        dpr,
      );
    }
    // calling this hook with UPDATE_LOD_STRUCTURES_PRIORITY so it is executed
    // after the camera has been moved by controls and animations.
  }, UPDATE_LOD_STRUCTURES_PRIORITY);

  // Set the material's opacity and transparency if provided
  const pointsMaterialProps = useMemo(() => {
    if (opacity !== undefined) {
      return {
        "material-opacity": opacity,
        "material-transparent": opacity !== 1,
      };
    }

    return {};
  }, [opacity]);

  // R3F will not dispose the pointCloud as we're using a primitive tag
  // but without dispose={null} R3F will dispose all the children of the pointcloud
  // removing all the nodes the first time the object is unmounted
  // this will make the point cloud lose sync between the fetched node and the node in gpu
  // and is not what we want
  return (
    <primitive
      object={pointCloud}
      dispose={null}
      {...rest}
      {...pointsMaterialProps}
    />
  );
}
