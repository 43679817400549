import { GUID } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { TokenProvider } from "./auth-types";
import { CoreApiTokenManager } from "./core-api-token-manager";

type Props = {
  /** Base URL to use for the core API. */
  coreApiBaseUrl: string;
  /** ID of the project to request the JWT for. */
  projectId: GUID;

  /** A string to identify a backend client in the format client/version */
  clientId?: string;
};

/**
 * @returns A function that will provide Authorization Tokens for ProjectApi in a HoloBuilder environment
 */
export function useCoreApiTokenProvider({
  coreApiBaseUrl,
  projectId,
  clientId,
}: Props): TokenProvider {
  const provider = useMemo(() => {
    const manager = new CoreApiTokenManager(
      new URL(coreApiBaseUrl),
      projectId,
      clientId,
    );
    return manager.getToken.bind(manager);
  }, [coreApiBaseUrl, projectId]);

  return provider;
}
