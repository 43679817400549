import { WSInstance } from "./WSInstance";

/** A Webshare cdn signature data */
export type WSSignature = {
	/** The cdn base url */
	BaseURL: string;

	/** The policy token */
	Policy: string;

	/** The signature token */
	Signature: string;
};

/**
 * @param response A json response from Webshare
 * @returns true if a generic object is a WSSignature
 */
export function isWSSignature(response: Record<string, unknown>): response is WSSignature {
	return (
		typeof response.BaseURL === "string" &&
		typeof response.Policy === "string" &&
		typeof response.Signature === "string"
	);
}

/** Signed data to access a webshare Cdn */
export type WSCdnSignatures = {
	/** Path of the project that can be accessed by this cdn */
	Path: string;

	/** Internal use */
	KeyPairID: string;

	/** When this signature will expire */
	Expires: number;

	/** All the cdn urls with their signatures */
	Signatures: WSSignature[];
};

/**
 * @param response A json response from Webshare
 * @returns true if a generic object is a WSCdnSignatures
 */
export function isWSCdnSignatures(response: Record<string, unknown>): response is WSCdnSignatures {
	if (typeof response.Path !== "string") return false;
	if (typeof response.KeyPairID !== "string") return false;
	if (typeof response.Expires !== "number") return false;
	if (!Array.isArray(response.Signatures)) return false;
	for (const val of response.Signatures) {
		if (!isWSSignature(val)) return false;
	}
	return true;
}

const AUTO_UPDATE_TIME = 15000;

/**
 * A class to manage the Webshare CDN signatures.
 * Project data from Webshare is distributed trough CDNs.
 * To query project data you need to ask for the CDN url and some additional signatures.
 * This information will expires after a while so a new signature is required
 */
export class WSCdnManager {
	/** timestamp (ms since epoch) when the current signatures will expires */
	#expire: number = -1;

	/** The current signatures */
	#signatures: WSCdnSignatures | undefined;

	/**
	 * Construct a new WSCdnManager. Will not query for the signature in constructions @see updateSignatures
	 *
	 * @param instance The webshare instance we want to query
	 * @param projectName The project name we need to query for project data
	 */
	constructor(
		private instance: WSInstance,
		private projectName: string,
	) {}

	/**
	 * Update the current stored signatures if they're missing or expired
	 *
	 * @param force true to force update not expired signatures
	 * @returns the cdn signature to use for follow-up queries
	 */
	async updateSignatures(force = false): Promise<WSCdnSignatures> {
		// Don't update if we have a valid signature
		if (this.#signatures && !force && Date.now() < this.#expire - AUTO_UPDATE_TIME) {
			return this.#signatures;
		}
		const signatures = await this.instance.getSignedUrls(this.projectName);
		this.#expire = Date.now() + signatures.Expires;
		this.#signatures = signatures;
		return signatures;
	}

	/**
	 * Compute the cdn url for a project data url
	 *
	 * @param path Path to the resources we need
	 * @returns the cdn url to use instead
	 */
	async computeCdnUrl(path: string): Promise<string> {
		const { Signatures, KeyPairID } = await this.updateSignatures();
		const cdn = Signatures[Math.floor(Math.random() * Signatures.length)];
		return `${cdn.BaseURL}${path}?Policy=${cdn.Policy}&Signature=${cdn.Signature}&Key-Pair-Id=${KeyPairID}`;
	}
}
