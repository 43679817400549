varying vec2 vUv;

uniform sampler2D uColorTexture;
uniform sampler2D uDepthTexture;
uniform float uSaturationMultiplier;
uniform float uLightnessMultiplier;

#include <lotv_color_conversions>

void main() {
	gl_FragDepth = texture(uDepthTexture, vUv).r;
    vec3 inputColor = texture(uColorTexture, vUv).rgb;
    vec3 hsl = rgb2hsl(inputColor);
    hsl.g = clamp(hsl.g * uSaturationMultiplier, 0.0, 1.0);
    hsl.b = clamp(hsl.b * uLightnessMultiplier, 0.0, 1.0);
	gl_FragColor = vec4(hsl2rgb(hsl), 1.0);
}
