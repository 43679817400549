import { ShaderMaterial, CubeTexture, Matrix4, Camera, DoubleSide, LinearFilter, WebGLRenderer, Vector4 } from "three";
import vert from "../Shaders/FullScreenQuad.vert";
import frag from "../Shaders/PanoBlendFade.frag";
import { makeOptionalUniform } from "./Uniforms";

/**
 * A material that generates two cube maps and blends them together.
 * The cube map are generated by placing the camera in the position of the two
 * objects provided in construction.
 * The blend is controlled by the blendFactor property
 * 0 will render only the starting cube map
 * 1 will render only the target cube map
 * intermediate values will render a blend of the two cube maps using the specified blending function
 */
export class CubeMapsBlendMaterial extends ShaderMaterial {
	override uniforms = {
		uCurTex: makeOptionalUniform<CubeTexture>(),
		uTarTex: makeOptionalUniform<CubeTexture>(),
		uViewport: makeOptionalUniform<Vector4>(),
		uCurInvPVMMatrix: makeOptionalUniform<Matrix4>(),
		uTarInvPVMMatrix: makeOptionalUniform<Matrix4>(),
		uBlendStep: makeOptionalUniform<number>(),
	};

	#blendFactor = 0;

	/**
	 * Construct the material initializing the shaders.
	 *
	 * @param source The object assigned to the scene rendered to the starting cube map
	 * @param target The object assigned to the scene rendered to the target cube map
	 * @param camera The camera to render this transition
	 */
	constructor(
		source: CubeTexture,
		target: CubeTexture,
		protected camera: Camera,
	) {
		super();

		this.vertexShader = vert;
		this.fragmentShader = frag;
		this.side = DoubleSide;

		this.uniforms.uCurTex.value = source;
		this.uniforms.uCurTex.value.generateMipmaps = false;
		this.uniforms.uCurTex.value.minFilter = LinearFilter;

		this.uniforms.uTarTex.value = target;
		this.uniforms.uTarTex.value.generateMipmaps = false;
		this.uniforms.uTarTex.value.minFilter = LinearFilter;
	}

	/**
	 * Update all the uniforms used by this material.
	 *
	 * @param renderer The current renderer used for rendering.
	 */
	update(renderer: WebGLRenderer): void {
		// Compute uniforms
		const vMatrix = this.camera.matrixWorldInverse.clone();
		vMatrix.setPosition(0, 0, 0);

		const pvMatrix = new Matrix4().multiplyMatrices(this.camera.projectionMatrix, vMatrix);

		// Current matrices
		this.uniforms.uCurInvPVMMatrix.value = pvMatrix.clone().invert();

		// Target matrices
		this.uniforms.uTarInvPVMMatrix.value = pvMatrix.clone().invert();

		// Other uniforms
		this.uniforms.uViewport.value = renderer.getViewport(new Vector4()).multiplyScalar(renderer.getPixelRatio());

		this.uniforms.uBlendStep.value = this.#blendFactor;

		this.uniformsNeedUpdate = true;
	}

	/** Change the current blend factor */
	set blendFactor(factor: number) {
		this.#blendFactor = factor;
	}

	/** @returns the current blend factor */
	get blendFactor(): number {
		return this.#blendFactor;
	}

	/**
	 * Schedule a new cube map generation for the source data
	 *
	 * @param texture The new source texture
	 * @returns the previous texture
	 */
	updateSourceData(texture: CubeTexture): CubeTexture | null {
		const prev = this.uniforms.uCurTex.value;
		this.uniforms.uCurTex.value = texture;
		return prev;
	}

	/**
	 *  Schedule a new cube map generation for the target data
	 *
	 * @param texture The new target texture
	 * @returns the previous texture
	 */
	updateTargetData(texture: CubeTexture): CubeTexture | null {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		const prev = this.uniforms.uTarTex?.value;
		this.uniforms.uTarTex.value = texture;
		return prev;
	}
}
