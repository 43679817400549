import type { PanoProject } from "../Pano/PanoProject";
import { WSInstance } from "./WSInstance";
import { WSProjectDesc } from "./responses/WSProjectDesc.gen";
import { WSScanDesc } from "./responses/WSScanDesc.gen";
import { WSPanoLoader, nextImageUrl, WSPanoObjectDefaults, WSPanoObjectOptions } from "./WSPanoLoader";
import { Matrix4 } from "three";
import { TiledPano } from "../Pano/TiledPano";
import { attachLoader, WithLoader } from "../Utils/Loader";

const FOCUS_TRIPOD_HEIGHT = 1.51;

/**
 * A tiled pano with attached a webshare loader that will load
 * increased resolution of images as times goes by
 */
export type WSPanoObject = TiledPano & WithLoader<WSPanoLoader>;

/**
 * An handle over a webshare pano project
 */
export class WSPanoProject implements PanoProject {
	private panoCache = new Map<number, WSPanoObject>();
	panoOptions = { ...WSPanoObjectDefaults };
	#positions: Matrix4[];
	#placeholders: Matrix4[];
	#tripodHeight = FOCUS_TRIPOD_HEIGHT;

	/**
	 * Construct a WSPanoProject
	 *
	 * @param ws The webshare instance to talk to
	 * @param desc The description of this project
	 * @param scans The list of scans in this project
	 * @param panoOptions Options to use to configure the pano images
	 */
	constructor(
		private ws: WSInstance,
		private desc: WSProjectDesc,
		private scans: WSScanDesc[],
		panoOptions: Partial<WSPanoObjectOptions> = {},
	) {
		Object.assign(this.panoOptions, panoOptions);
		this.#positions = scans.map((s) => new Matrix4().fromArray(s.TransformationGlobal));
		this.#placeholders = this.#positions.map((m) =>
			m.clone().multiply(new Matrix4().makeTranslation(0, 0, -this.#tripodHeight)),
		);
	}

	/** @inheritdoc */
	get name(): string {
		return this.desc.Name;
	}

	/** @inheritdoc */
	get source(): string {
		return "Webshare";
	}

	/** @inheritdoc */
	get numImages(): number {
		return this.scans.length;
	}
	/** @inheritdoc */
	get placeholders(): THREE.Matrix4[] {
		return this.#placeholders;
	}

	/** @inheritdoc */
	async getImage(index: number): Promise<WSPanoObject> {
		let pano = this.panoCache.get(index);
		if (pano) return pano;

		const desc = this.scans[index];
		const url = nextImageUrl(this.ws, this.name, desc, this.panoOptions);
		if (!url) {
			throw new Error("This pano have no available image");
		}
		const texture = await this.ws.textureLoader.load(url).promise;
		// Create a loader for this pano data
		const loader = new WSPanoLoader(this.ws, this.name, desc, texture, this.panoOptions);
		const position = new Matrix4().fromArray(desc.TransformationGlobal);
		// Create the pano with the loader attached
		const image = new TiledPano(texture, position);
		// (-62.5°) the minimum angle of the Focus Scanner
		image.minTheta = -1.0908307824980232;
		pano = attachLoader(image, loader);
		// Connect the pano and loader events
		loader.textureReady.on(pano.replaceOverview.bind(pano));
		loader.depthsReady.on(pano.setDepths.bind(pano));
		pano.beforeRender.on(loader.loadMore.bind(loader));
		pano.disposed.on(loader.cancelPending.bind(loader));
		this.panoCache.set(index, pano);
		return pano;
	}
}
