import { GUID, compareDateTimes } from "@faro-lotv/foundation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDialog } from "../dialog";
import { FileDetails } from "../horizontal-scroll-list/file-details-list";

export type AnnotationAttachmentsReturn = {
  /** List of the file attachments of the annotation */
  attachments: FileDetails[];

  /** Function to add a new attachment to the list. It will allow the deletion if allowDeletion is true */
  addNewAttachment(attachment: FileDetails): void;
};

export type AnnotationFormVariant = "Create" | "Edit";

export type FileAttachment = {
  /** The unique id of the attachment */
  id: GUID;
  /** The name of the attachment */
  name: string;
  /** The file size in kilobytes */
  fileSize?: number | null;
  /** A string representing the creation date of the attachment */
  createdAt: string;
  /** The uri pointing to the source of the attachment */
  uri: string;
  /** The uir pointing to the source of the thumbnail */
  thumbnailUri?: string | null;
};

/**
 * @param fileAttachments IElements list of the annotation's attachments
 * @param allowDeletion if true the deletion of attachments in the list will be allowed
 * @param formVariant type of the annotation's form
 * @param onDeletion Callback executed when the user click the deletion button
 * @param onConfirmDeletion Callback executed when the user confirms the deletion of an attachment
 * @param onCancelDeletion Callback executed when the user cancels the deletion of an attachment
 * @returns the list of attachments and a function to add a new one to the list
 */
export function useAnnotationAttachments(
  fileAttachments: FileAttachment[],
  allowDeletion: boolean,
  formVariant: AnnotationFormVariant,
  onDeletion: (extension: string, isNewAttachment: boolean) => void,
  onConfirmDeletion: () => void,
  onCancelDeletion: () => void,
): AnnotationAttachmentsReturn {
  const [attachments, setAttachments] = useState<FileDetails[]>([]);

  const { createDialog } = useDialog();

  /**
   * This function will be called when the delete button of an attachment will be clicked.
   * It removes a specific attachment from the local list.
   */
  const removeAttachment = useCallback(
    (id: string, extension: string, isNewAttachment: boolean) => {
      onDeletion(extension, isNewAttachment);
      createDialog({
        title: "Remove Attachment?",
        content: `The element will be permanently deleted${formVariant === "Edit" ? " from your project once the annotation is saved" : ""}. This operation cannot be undone.`,
        variant: "danger",
        dark: true,
        onConfirm: () => {
          onConfirmDeletion();
          setAttachments((initialValue) => {
            // Copy the array, to not work directly on the previous state, otherwise it will not trigger the re-render
            const localAttachments = [...initialValue];

            const idx = localAttachments.findIndex(
              (attachment) => attachment.id === id,
            );

            // Remove the deleted attachment from the list
            localAttachments.splice(idx, 1);

            return localAttachments;
          });
          return true;
        },
        onCancel: onCancelDeletion,
      });
    },
    [
      createDialog,
      formVariant,
      onCancelDeletion,
      onConfirmDeletion,
      onDeletion,
    ],
  );

  const createFileDetails = useCallback(
    (attachmentsElements: FileAttachment[]): FileDetails[] =>
      attachmentsElements.map((el) => ({
        id: el.id,
        fileName: el.name,
        fileSize: el.fileSize,
        date: el.createdAt,
        urlOrFile: el.uri,
        thumbnailUrl: el.thumbnailUri,
        onDelete: allowDeletion ? removeAttachment : undefined,
      })),
    [allowDeletion, removeAttachment],
  );

  useEffect(() => {
    // Init the attachments list
    setAttachments(createFileDetails(fileAttachments));
  }, [fileAttachments, createFileDetails]);

  const addNewAttachment = useCallback(
    (attachment: FileDetails) =>
      setAttachments([
        ...attachments,
        allowDeletion
          ? {
              ...attachment,
              onDelete: removeAttachment,
            }
          : attachment,
      ]),
    [allowDeletion, attachments, removeAttachment],
  );

  const sortedAttachments = useMemo(
    () =>
      attachments
        .slice()
        .sort((a: FileDetails, b: FileDetails) =>
          compareDateTimes(b.date, a.date),
        ),
    [attachments],
  );

  return {
    attachments: sortedAttachments,
    addNewAttachment,
  };
}
