// Generated by https://app.quicktype.io/ using the file wstreenodedesc.payload.js
// To parse this data:
//
//   import { Convert } from "./file";
//
//   const wSTreeNode = Convert.toWSTreeNode(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface WSTreeNodeDesc {
	Class: Class;
	ID: string;
	Points: number;
	Min: number[];
	Max: number[];
	Q: number;
	Version?: number;
	BuilderVersion?: number;
	Compression?: string;
	Homogenization?: number;
	SplitFactor?: number;
	Depth?: number;
	Lod?: number;
	LodDepth?: number;
	MinPoints?: number;
	TransformationGlobal?: number[];
}

export enum Class {
	PointCloudNode = "PointCloudNode",
	PointCloudRootNode = "PointCloudRootNode",
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
	public static toWSTreeNodes(json: string): WSTreeNodeDesc[] {
		return cast(JSON.parse(json), a(r("WSTreeNode")));
	}

	public static wSTreeNodesToJson(value: WSTreeNodeDesc[]): string {
		return JSON.stringify(uncast(value, a(r("WSTreeNode"))), null, 2);
	}
}

function invalidValue(typ: any, val: any, key: any = ""): never {
	if (key) {
		throw new Error(
			`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`,
		);
	}
	throw new Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
	if (typ.jsonToJS === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
		typ.jsonToJS = map;
	}
	return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
	if (typ.jsToJSON === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
		typ.jsToJSON = map;
	}
	return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
	function transformPrimitive(typ: string, val: any): any {
		if (typeof typ === typeof val) return val;
		return invalidValue(typ, val, key);
	}

	function transformUnion(typs: any[], val: any): any {
		// val must validate against one typ in typs
		const l = typs.length;
		for (let i = 0; i < l; i++) {
			const typ = typs[i];
			try {
				return transform(val, typ, getProps);
			} catch {}
		}
		return invalidValue(typs, val);
	}

	function transformEnum(cases: string[], val: any): any {
		if (cases.includes(val)) return val;
		return invalidValue(cases, val);
	}

	function transformArray(typ: any, val: any): any {
		// val must be an array with no invalid elements
		if (!Array.isArray(val)) return invalidValue("array", val);
		return val.map((el) => transform(el, typ, getProps));
	}

	function transformDate(val: any): any {
		if (val === null) {
			return null;
		}
		const d = new Date(val);
		if (Number.isNaN(d.valueOf())) {
			return invalidValue("Date", val);
		}
		return d;
	}

	function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
		if (val === null || typeof val !== "object" || Array.isArray(val)) {
			return invalidValue("object", val);
		}
		const result: any = {};
		Object.getOwnPropertyNames(props).forEach((key) => {
			const prop = props[key];
			const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
			result[prop.key] = transform(v, prop.typ, getProps, prop.key);
		});
		Object.getOwnPropertyNames(val).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(props, key)) {
				result[key] = transform(val[key], additional, getProps, key);
			}
		});
		return result;
	}

	if (typ === "any") return val;
	if (typ === null) {
		if (val === null) return val;
		return invalidValue(typ, val);
	}
	if (typ === false) return invalidValue(typ, val);
	while (typeof typ === "object" && typ.ref !== undefined) {
		typ = typeMap[typ.ref];
	}
	if (Array.isArray(typ)) return transformEnum(typ, val);
	if (typeof typ === "object") {
		return typ.hasOwnProperty("unionMembers")
			? transformUnion(typ.unionMembers, val)
			: typ.hasOwnProperty("arrayItems")
				? transformArray(typ.arrayItems, val)
				: typ.hasOwnProperty("props")
					? transformObject(getProps(typ), typ.additional, val)
					: invalidValue(typ, val);
	}
	// Numbers can be parsed by Date but shouldn't be.
	if (typ === Date && typeof val !== "number") return transformDate(val);
	return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
	return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
	return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
	return { arrayItems: typ };
}

function u(...typs: any[]) {
	return { unionMembers: typs };
}

function o(props: any[], additional: any) {
	return { props, additional };
}

function m(additional: any) {
	return { props: [], additional };
}

function r(name: string) {
	return { ref: name };
}

const typeMap: any = {
	WSTreeNode: o(
		[
			{ json: "Class", js: "Class", typ: r("Class") },
			{ json: "ID", js: "ID", typ: "" },
			{ json: "Points", js: "Points", typ: 0 },
			{ json: "Min", js: "Min", typ: a(3.14) },
			{ json: "Max", js: "Max", typ: a(3.14) },
			{ json: "Q", js: "Q", typ: 3.14 },
			{ json: "Version", js: "Version", typ: u(undefined, 0) },
			{ json: "BuilderVersion", js: "BuilderVersion", typ: u(undefined, 0) },
			{ json: "Compression", js: "Compression", typ: u(undefined, "") },
			{ json: "Homogenization", js: "Homogenization", typ: u(undefined, 3.14) },
			{ json: "SplitFactor", js: "SplitFactor", typ: u(undefined, 0) },
			{ json: "Depth", js: "Depth", typ: u(undefined, 0) },
			{ json: "Lod", js: "Lod", typ: u(undefined, 0) },
			{ json: "LodDepth", js: "LodDepth", typ: u(undefined, 0) },
			{ json: "MinPoints", js: "MinPoints", typ: u(undefined, 0) },
			{ json: "TransformationGlobal", js: "TransformationGlobal", typ: u(undefined, a(3.14)) },
		],
		"any",
	),
	Class: ["PointCloudNode", "PointCloudRootNode"],
};
