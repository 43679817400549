import { assert } from "@faro-lotv/foundation";
import { Img360LevelsOfDetail } from "@faro-lotv/ielement-types";
import { ImageNodeFetch, ImageTree, ImageTreeNode } from "@faro-lotv/lotv";
import { Box2, Vector2 } from "three";
import {
  DEFAULT_IMAGE_FETCHER,
  ImageFetcher,
  Img360LodFetch,
} from "./img-360-lod-fetch";

/**
 * Create a key for the map when building the tree structure
 *
 * @param depth The depth level of the tile in the tree
 * @param x The x coordinate of the tile
 * @param y The y coordinate of the tile
 * @returns The key encoding the tile
 */
function createKey(depth: number, x: number, y: number): string {
  return `${depth.toString()}-${x.toString()}-${y.toString()}`;
}

/**
 * Customization options for the Img360LodTree
 *
 * TODO: Extend with props required for signUrls update (https://faro01.atlassian.net/browse/SWEB-5225)
 */
type Img360LodTreeOptions = {
  /** The function used to fetch textures, to allow non browser contexts to inject their implementation */
  fetcher: ImageFetcher;
};

/** Default options for the Img360LodTree class */
const DEFAULT_IMG360_LOD_TREE_OPTIONS: Img360LodTreeOptions = {
  fetcher: DEFAULT_IMAGE_FETCHER,
};

/**
 * A subclass of ImageTree developed to parse the Img360 levelOfDetails prop
 */
export class Img360LodTree extends ImageTree<string> {
  /** Options to customize the lod tree behavior */
  private options: Img360LodTreeOptions;

  /**
   * Constructs a new Img360 ImageTree
   *
   * @param lod data for this element
   * @param width of this lod tree in pixels
   * @param height of this lod tree in pixels
   * @param options to customize this tree instance
   */
  constructor(
    lod: Img360LevelsOfDetail[],
    public width: number,
    public height: number,
    options: Partial<Img360LodTreeOptions> = {},
  ) {
    super();
    assert(
      lod[0].level === 0,
      "The level 0 is missing from the levels of detail structure",
    );
    this.options = {
      ...DEFAULT_IMG360_LOD_TREE_OPTIONS,
      ...options,
    };

    const map = new Map<string, number>();
    for (const { level, dimX, dimY, sources } of lod) {
      // Each level should have all tiles for the level subdivision
      assert(
        dimX * dimY === sources.length,
        `Level ${level} has ${sources.length} sources while ${
          dimX * dimY
        } sources where expected`,
      );

      // Create an ImageTree node in the tree for each tile in this level
      for (const [index, source] of sources.entries()) {
        // Compute the x and y coordinates for the tile
        // as they go from top left to bottom right row major
        const x = index % dimX;
        const y = dimY - Math.floor(index / dimX) - 1;

        // Compute the parent tile for the current tile
        const parentKey = createKey(
          level - 1,
          Math.floor(x / 2),
          Math.floor(y / 2),
        );
        const parent = map.get(parentKey);
        assert(
          parent !== undefined || level === 0,
          `The parent node of level ${level} is missing`,
        );

        // Append the new tile to the parent
        this.appendChildren(parent, {
          source,
          rect: new Box2(
            new Vector2(x / dimX, y / dimY),
            new Vector2((x + 1) / dimX, (y + 1) / dimY),
          ),
        });

        const nodeIdx = this.nodeCount;
        map.set(createKey(level, x, y), nodeIdx - 1);
      }
    }
  }

  /**
   * Get the image for a node
   *
   * @param nodeOrId The node or the node id to fetch the image
   * @returns An object to wait for the image or cancel the fetch
   */
  override getNodeImage(
    nodeOrId: number | ImageTreeNode<string>,
  ): Promise<ImageNodeFetch> {
    const node =
      typeof nodeOrId === "number" ? this.nodesList.at(nodeOrId) : nodeOrId;

    assert(node, "The requested Img360 tile node does not exists");

    return Promise.resolve(
      new Img360LodFetch(node, node.source, this.options.fetcher),
    );
  }
}
