/** A unique symbol indicating that the object is being loaded, e.g. fetched from an API. */
export const LOADING = Symbol("Loading");
export type Loading = typeof LOADING;

/**
 * A value that's either still being loaded ("Loading"),
 * loaded but doesn't exist (undefined), or fully loaded and defined.
 */
export type MaybeLoading<T> = T | undefined | Loading;

/**
 * @param value The value to check
 * @returns True if the value is currently loading, else false.
 */
export function isLoading(value: unknown): value is Loading {
  return value === LOADING;
}

/**
 * @param value The value to check.
 * @returns True if the value is fully loaded and defined, else false.
 */
export function isDefinedAndLoaded<T>(value: MaybeLoading<T>): value is T {
  return value !== undefined && !isLoading(value);
}
