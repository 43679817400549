uniform float rotation;
uniform vec2 center;
uniform vec2 uOffset;
uniform float size;
uniform float minSize;
uniform float maxSize;
uniform float depthToNormalizedScreenHeight;
uniform bool drawOccluded;
uniform bool postProcessingPass;

#include <common>
#include <uv_pars_vertex>
#include <fog_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

const float INV_SQR_TWO = 0.70710678118654752440084436210485;

void main() {

	#include <uv_vertex>
	if (drawOccluded && !postProcessingPass){
		// Don't render it
		return;
	}

	vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );

	vec2 alignedPosition = ( position.xy - ( center - vec2( 0.5 ) ) ) * vec2(size, size);

    // We convert the size from pixels to meters
	float maxDistInMeters = 0.0;
	float minDistInMeters = 0.0;
	// The 'isOrthographic' uniform is given for free by threejs' ShaderMaterial.
	// The if below should not be a performance problem because modern GPUs apply static 
	// branching when the if condition depends only on uniforms.
	if(isOrthographic) {
		float pix2m = depthToNormalizedScreenHeight * INV_SQR_TWO;
		maxDistInMeters = maxSize * pix2m;
		minDistInMeters = minSize * pix2m;
	}
	else {
		// The pixel -> meter conversion is: sizeMeters = sizeInPixel / screenHeight * depthOfTheObject * 2.0 * tan(0.5 * fovy)
		// We also multiply by 1 / sqrt(2) because max and min dist in meters are used to clamp the half diagonal of the square.
		float pix2m = -mvPosition.z * depthToNormalizedScreenHeight * INV_SQR_TWO;
		maxDistInMeters = maxSize * pix2m;
		minDistInMeters = minSize * pix2m;
	}

    // We clamp the distance in the real world, before applying any rotation
    float currentDistance = length(alignedPosition);
    float clampedDistance = clamp(currentDistance, minDistInMeters, maxDistInMeters);
    alignedPosition = alignedPosition / currentDistance * clampedDistance;

	vec2 rotatedPosition;
	rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
	rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;

	mvPosition.xy += rotatedPosition + uOffset;

    gl_Position = projectionMatrix * mvPosition; 

	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	#include <fog_vertex>

}
