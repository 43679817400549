import {
  Box,
  SxProps,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { PropsWithChildren } from "react";
import { neutral } from "../colors";
import { FontWeights } from "../faro-theme";
import { FaroText } from "../text/faro-text/faro-text";

/**
 * @returns A light-themed variant of a Tooltip
 */
export function TooltipLight({ sx, ...rest }: TooltipProps): JSX.Element {
  return (
    <Tooltip
      sx={{ boxShadow: 3, ...sx }}
      componentsProps={{
        tooltip: {
          sx: {
            color: (theme) => theme.palette.darkGrey,
            backgroundColor: (theme) => theme.palette.white,
            fontSize: "14px",
            padding: 2,
            maxWidth: 500,
            boxShadow: 3,
          },
        },
      }}
      {...rest}
    />
  );
}

interface Props extends TooltipProps {
  /** An icon component to be displayed in the title of tooltip */
  icon?: JSX.Element;
  /** The style applied to the tooltip container */
  tooltipSx?: SxProps;
}

/**
 * @returns A Tooltip following the FARO Design
 */
export function FaroTooltip({
  children,
  icon,
  title,
  disableInteractive = true,
  sx,
  tooltipSx,
  ...rest
}: PropsWithChildren<Props>): JSX.Element {
  // Mui is passing fullWidth to the child element i.e to span, which is not a valid prop
  // Hence there is a warning in the console. To avoid that, the fullWidth prop is discarded here.
  if ("fullWidth" in rest) {
    delete rest.fullWidth;
  }

  return (
    <Tooltip
      title={
        // Add the FaroText only if we have a title
        title ? (
          <FaroText
            variant="bodyS"
            fontWeight={FontWeights.SemiBold}
            color={neutral[100]}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.75,
              [".MuiSvgIcon-root"]: {
                fontSize: "1rem",
              },
            }}
          >
            {icon}
            {title}
          </FaroText>
        ) : undefined
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: neutral[950],
            py: 0.75,
            px: 1.5,
            maxWidth: 260,
            lineHeight: "1.1rem",
            border: ({ palette }) => `1px solid ${palette.white}33`,
            borderRadius: 1,
            [`&.${tooltipClasses.tooltip}`]: {
              [`&.${tooltipClasses.tooltipPlacementTop}`]: {
                mb: 1,
              },
              [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
                mt: 1,
              },
              [`&.${tooltipClasses.tooltipPlacementRight}`]: {
                ml: 1,
              },
              [`&.${tooltipClasses.tooltipPlacementLeft}`]: {
                mr: 1,
              },
            },
            ...tooltipSx,
          },
        },
      }}
      disableInteractive={disableInteractive}
      enterDelay={500}
      enterNextDelay={500}
      leaveDelay={200}
      sx={{ ...sx }}
      {...rest}
    >
      {/* The tooltip expects a single child element which can hold a Ref. Hence wrapping it with a Box */}
      <Box component="span">{children}</Box>
    </Tooltip>
  );
}
