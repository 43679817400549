import { validatePrimitive } from "@faro-lotv/foundation";
import { Camera } from "@react-three/fiber";
import { EventDispatcher } from "three";

export type EnableableControls = EventDispatcher & {
  /**
   * If enabled property is set to true, then the user will be able to interact with the controls
   * else the controls will be disabled
   */
  enabled: boolean;
};

/**
 * @returns True if the controls is an instance EventDispatcher and has an "enabled" property which can then be used to toggle the controls
 * If enabled property is set to true, then the user will be able to interact with the controls else the controls will be disabled
 * @param controls Controls to check if it has enabled property
 */
export function isEnableableControls(
  controls: EventDispatcher | null,
): controls is EnableableControls {
  return !!controls && validatePrimitive(controls, "enabled", "boolean");
}

export type ControlsWithCamera = EventDispatcher & {
  camera: Camera;
};

/**
 *
 * @param controls A controls object
 * @returns Whether the given object has a 'camera' property
 */
export function isControlsWithCamera(
  controls: EventDispatcher | null,
): controls is ControlsWithCamera {
  return controls !== null && "camera" in controls;
}
