import { Link } from "@mui/material";
import MaterialAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { SxProps, Theme } from "@mui/system";
import { PropsWithChildren } from "react";
import { SphereXGTextIcon } from "../icons/icons";

interface IProps {
  /** styling properties */
  sx?: SxProps<Theme>;

  /** The url the logo links to */
  logoUrl?: string;

  /**
   * Should show the logo if it is true and hide when false
   *
   * @default true
   */
  shouldShowLogo?: boolean;
}

/** @returns The header for the application */
export function AppBar({
  sx,
  logoUrl,
  shouldShowLogo = true,
  children,
}: PropsWithChildren<IProps>): JSX.Element {
  return (
    <MaterialAppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: "gray50",
        height: "60px",
        paddingLeft: 0,
        paddingRight: 0,
        ...sx,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: "60px",
          pl: 0.75,
          pr: 1,
          py: 0.5,
        }}
      >
        {shouldShowLogo && (
          <Link sx={{ display: "contents" }} href={logoUrl}>
            <SphereXGTextIcon sx={{ width: "125px", height: "100%" }} />
          </Link>
        )}

        <Box component="div" sx={{ width: "100%" }}>
          {children}
        </Box>
      </Toolbar>
    </MaterialAppBar>
  );
}
