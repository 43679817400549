/**
 * @param clientId a string to identify a backend client in the format client/version
 * @returns extra headers to notify to the backend what client is sending the request
 */
export function clientIdHeader(clientId: string | undefined): HeadersInit {
  if (!clientId) {
    return {};
  }
  return { "X-Holobuilder-Component": clientId };
}
