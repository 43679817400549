// Array of 15 elements, three sizes per placeholder class
// stored as (default size, hovered size, selected size).
uniform float sizes[MAX_TEXTURES];

// Flag with the placeholder state
attribute uint state;
flat out int vResourceId;
flat out int vHidden;
#ifdef USE_COLORS
attribute vec3 color;
out vec3 vColor;
#endif

const uint HIDDEN_STATE = 4u;

void main() {
	// Project position
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);

	// 'label' can go from 0 to 4, according to how this placeholder is labeled
	uint label = state >> uint(PLACEHOLDER_LABEL_BIT_POS);
	// 'selectionState' is 0 => default, 1 => hovered, 2 => selected
	uint selectionState = min(2u, state & uint(3));
	// Computing vResourceId id from label and state
	// 'vResourceId' identifies the placeholder's place both
	// in the texture and in the sizes array
	vResourceId = int(3u * label + selectionState);

	// Is this placeholder hidden?
	vHidden = (state & HIDDEN_STATE) == HIDDEN_STATE ? 1 : 0;

    gl_PointSize = sizes[vResourceId];

#ifdef USE_COLORS
    vColor = color;
#endif
}
