import { validateNotNullishObject } from "@faro-lotv/foundation";
import { PreAlignmentJobId } from "./pre-alignment-api-types";

/** The response of the backend with the initiated backend job. */
export interface PreAlignmentStartedJobResponse {
  /** The identifier of the backend job running the pre-alignment. */
  jobId: PreAlignmentJobId;
}

/**
 * @param response The response of the backend.
 * @returns `true`, if the response has the expected format, else `false`.
 */
export function isPreAlignmentStartedJobResponse(
  response: unknown,
): response is PreAlignmentStartedJobResponse {
  return (
    validateNotNullishObject<PreAlignmentStartedJobResponse>(
      response,
      "PreAlignmentStartedJobResponse",
    ) && typeof response.jobId === "string"
  );
}
