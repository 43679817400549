import { Required } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  ProjectPermissions,
  UserDetails,
  UserInfo,
} from "../core-api-responses";

/** Information about the generic user of the project */
export type GenericUserInfo = {
  /** Either the ID or the email of the user to always be able to identify the user */
  identity: string;

  /**
   * User GUID
   *
   * @description The userId can be undefined for the users that are only invited by email, but have not created an account yet
   */
  id?: GUID;

  /** User email */
  email: string;

  /** User Full name */
  name?: string;

  /** User access permissions to a project */
  projectPermissions?: ProjectPermissions[];

  /** User first name */
  firstName?: string;

  /** User last name */
  lastName?: string;

  /** User profile image Url */
  profileImageUrl?: string;
};

/** Information of the registered user */
export type RegisteredUserInfo = Required<GenericUserInfo, "id" | "name">;

/**
 * @returns True if the given userInfo is of a registered user
 * @param userInfo element to check
 */
export function isRegisteredUserInfo(
  userInfo: GenericUserInfo,
): userInfo is RegisteredUserInfo {
  return !!userInfo.id && !!userInfo.name;
}

/**
 * @returns A standardized user info object from the given user details
 * @param userDetails the user details to parse from an API response
 */
export function parseGenericUserInfoFromUserDetails(
  userDetails: UserDetails,
): GenericUserInfo {
  return {
    identity: userDetails.userId,
    id: userDetails.userId,
    email: userDetails.mailAddress,
    name:
      userDetails.name +
      (userDetails.lastName ? ` ${userDetails.lastName}` : ""),
    firstName: userDetails.name,
    lastName: userDetails.lastName,
    profileImageUrl: userDetails.profileImageUrl,
  };
}

/**
 * @returns A standardized user info object from the given user info
 * @param userInfo the user info to parse from an API response
 */
export function parseGenericUserInfoFromUserInfo(
  userInfo: UserInfo,
): GenericUserInfo {
  return {
    identity: userInfo.userId ?? userInfo.mail,
    id: userInfo.userId,
    email: userInfo.mail,
    name: userInfo.name,
    projectPermissions: userInfo.permissions,
  };
}
