import { WSKDTreeNode } from "./WSKDTree";

export enum WSNodeSubdivision {
	Xaxis = 0,
	Yaxis = 1,
	Zaxis = 2,
	NoSubdivision = 3,
	MultipleAxesXY = 4,
	MultipleAxesXZ = 5,
	MultipleAxesYZ = 6,
	MultipleAxesXYZ = 7,
	LessThanTwoChildren = 8,
}

/**
 *
 * @param node The parent node
 * @returns Along which axis the children are subdivided.
 */
export function computeSubdivisionAxis(node: Readonly<WSKDTreeNode>): WSNodeSubdivision {
	const L = node.children.length;
	if (L < 2) return WSNodeSubdivision.LessThanTwoChildren;
	// Start with the hypothesis that there is subdivision along all three axes.
	const subd = [1, 1, 1];
	for (let n = 0; n < L - 1; ++n) {
		const b0 = node.children[n].boundingBox;
		const b1 = node.children[n + 1].boundingBox;
		for (let axis = 0; axis < 3; ++axis) {
			const min0 = b0.min.getComponent(axis);
			const max0 = b0.max.getComponent(axis);
			const min1 = b1.min.getComponent(axis);
			const max1 = b1.max.getComponent(axis);
			const noOverlap = min0 >= max1 || min1 >= max0;
			if (!noOverlap) {
				// At least two children's boxes overlap along this axis.
				subd[axis] = 0;
			}
		}
	}
	const ret = subd[0] + 2 * subd[1] + 4 * subd[2];
	switch (ret) {
		default:
			return WSNodeSubdivision.NoSubdivision;
		case 1:
			return WSNodeSubdivision.Xaxis;
		case 2:
			return WSNodeSubdivision.Yaxis;
		case 3:
			return WSNodeSubdivision.MultipleAxesXY;
		case 4:
			return WSNodeSubdivision.Zaxis;
		case 5:
			return WSNodeSubdivision.MultipleAxesXZ;
		case 6:
			return WSNodeSubdivision.MultipleAxesYZ;
		case 7:
			return WSNodeSubdivision.MultipleAxesXYZ;
	}
}
