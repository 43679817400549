// generated by https://app.quicktype.io/ from wspointclouddesc.payload.js
// To parse this data:
//
//   import { Convert } from "./file";
//
//   const wSPointCloudDesc = Convert.toWSPointCloudDesc(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface WSPointCloudDesc {
	Class: Class;
	UUID: string;
	DisplayName: string;
	Category: string;
	Tags: any[];
	CreatedBy: string;
	ExportVersion: number;
	ParentUUID: string;
	Name: string;
	Revision: number;
	ReadPublic: boolean;
	ReadGroups?: any[];
	WritePublic: boolean;
	WriteGroups?: any[];
	TransformationLocal: number[];
	TransformationGlobal: number[];
	CreationTime: string;
	Description: string;
	Hyperlinks: any[];
	HyperlinksDescriptions: any[];
	Type: Type;
	EntityUUIDs: string[];
	SourceUUIDs: string[];
	SourceTypes: SourceType[];
	SourceRevisions: number[];
	InputMin: any[];
	InputMax: any[];
	ColorRatio: number;
	Homogenization: number;
	Resolution: number;
	State: State;
	DataMin: number[];
	DataMax: number[];
	Points: number;
	Depth: number;
	LodDepth: number;
	SplitFactor: number;
	FormatVersion: number;
	BuilderVersion: number;
	Children: any[];
}

export enum Class {
	WSPointCloud = "WSPointCloud",
}

export enum SourceType {
	EntityCPE = "EntityCPE",
	Scan = "Scan",
}

export enum State {
	Complete = "complete",
}

export enum Type {
	EntityPC = "EntityPC",
	Project = "Project",
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
	public static toWSPointCloudDesc(json: string): WSPointCloudDesc[] {
		return cast(JSON.parse(json), a(r("WSPointCloudDesc")));
	}

	public static wSPointCloudDescToJson(value: WSPointCloudDesc[]): string {
		return JSON.stringify(uncast(value, a(r("WSPointCloudDesc"))), null, 2);
	}
}

function invalidValue(typ: any, val: any, key: any = ""): never {
	if (key) {
		throw Error(
			`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`,
		);
	}
	throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
	if (typ.jsonToJS === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
		typ.jsonToJS = map;
	}
	return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
	if (typ.jsToJSON === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
		typ.jsToJSON = map;
	}
	return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
	function transformPrimitive(typ: string, val: any): any {
		if (typeof typ === typeof val) return val;
		return invalidValue(typ, val, key);
	}

	function transformUnion(typs: any[], val: any): any {
		// val must validate against one typ in typs
		const l = typs.length;
		for (let i = 0; i < l; i++) {
			const typ = typs[i];
			try {
				return transform(val, typ, getProps);
			} catch (_) {}
		}
		return invalidValue(typs, val);
	}

	function transformEnum(cases: string[], val: any): any {
		if (cases.indexOf(val) !== -1) return val;
		return invalidValue(cases, val);
	}

	function transformArray(typ: any, val: any): any {
		// val must be an array with no invalid elements
		if (!Array.isArray(val)) return invalidValue("array", val);
		return val.map((el) => transform(el, typ, getProps));
	}

	function transformDate(val: any): any {
		if (val === null) {
			return null;
		}
		const d = new Date(val);
		if (isNaN(d.valueOf())) {
			return invalidValue("Date", val);
		}
		return d;
	}

	function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
		if (val === null || typeof val !== "object" || Array.isArray(val)) {
			return invalidValue("object", val);
		}
		const result: any = {};
		Object.getOwnPropertyNames(props).forEach((key) => {
			const prop = props[key];
			const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
			result[prop.key] = transform(v, prop.typ, getProps, prop.key);
		});
		Object.getOwnPropertyNames(val).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(props, key)) {
				result[key] = transform(val[key], additional, getProps, key);
			}
		});
		return result;
	}

	if (typ === "any") return val;
	if (typ === null) {
		if (val === null) return val;
		return invalidValue(typ, val);
	}
	if (typ === false) return invalidValue(typ, val);
	while (typeof typ === "object" && typ.ref !== undefined) {
		typ = typeMap[typ.ref];
	}
	if (Array.isArray(typ)) return transformEnum(typ, val);
	if (typeof typ === "object") {
		return typ.hasOwnProperty("unionMembers")
			? transformUnion(typ.unionMembers, val)
			: typ.hasOwnProperty("arrayItems")
				? transformArray(typ.arrayItems, val)
				: typ.hasOwnProperty("props")
					? transformObject(getProps(typ), typ.additional, val)
					: invalidValue(typ, val);
	}
	// Numbers can be parsed by Date but shouldn't be.
	if (typ === Date && typeof val !== "number") return transformDate(val);
	return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
	return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
	return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
	return { arrayItems: typ };
}

function u(...typs: any[]) {
	return { unionMembers: typs };
}

function o(props: any[], additional: any) {
	return { props, additional };
}

function m(additional: any) {
	return { props: [], additional };
}

function r(name: string) {
	return { ref: name };
}

const typeMap: any = {
	WSPointCloudDesc: o(
		[
			{ json: "Class", js: "Class", typ: r("Class") },
			{ json: "UUID", js: "UUID", typ: "" },
			{ json: "DisplayName", js: "DisplayName", typ: "" },
			{ json: "Category", js: "Category", typ: "" },
			{ json: "Tags", js: "Tags", typ: a("any") },
			{ json: "CreatedBy", js: "CreatedBy", typ: "" },
			{ json: "ExportVersion", js: "ExportVersion", typ: 0 },
			{ json: "ParentUUID", js: "ParentUUID", typ: "" },
			{ json: "Name", js: "Name", typ: "" },
			{ json: "Revision", js: "Revision", typ: 0 },
			{ json: "ReadPublic", js: "ReadPublic", typ: true },
			{ json: "ReadGroups", js: "ReadGroups", typ: u(undefined, a("any")) },
			{ json: "WritePublic", js: "WritePublic", typ: true },
			{ json: "WriteGroups", js: "WriteGroups", typ: u(undefined, a("any")) },
			{ json: "TransformationLocal", js: "TransformationLocal", typ: a(3.14) },
			{ json: "TransformationGlobal", js: "TransformationGlobal", typ: a(3.14) },
			{ json: "CreationTime", js: "CreationTime", typ: "" },
			{ json: "Description", js: "Description", typ: "" },
			{ json: "Hyperlinks", js: "Hyperlinks", typ: a("any") },
			{ json: "HyperlinksDescriptions", js: "HyperlinksDescriptions", typ: a("any") },
			{ json: "Type", js: "Type", typ: r("Type") },
			{ json: "EntityUUIDs", js: "EntityUUIDs", typ: a("") },
			{ json: "SourceUUIDs", js: "SourceUUIDs", typ: a("") },
			{ json: "SourceTypes", js: "SourceTypes", typ: a(r("SourceType")) },
			{ json: "SourceRevisions", js: "SourceRevisions", typ: a(0) },
			{ json: "InputMin", js: "InputMin", typ: a("any") },
			{ json: "InputMax", js: "InputMax", typ: a("any") },
			{ json: "ColorRatio", js: "ColorRatio", typ: 0 },
			{ json: "Homogenization", js: "Homogenization", typ: 3.14 },
			{ json: "Resolution", js: "Resolution", typ: 3.14 },
			{ json: "State", js: "State", typ: r("State") },
			{ json: "DataMin", js: "DataMin", typ: a(3.14) },
			{ json: "DataMax", js: "DataMax", typ: a(3.14) },
			{ json: "Points", js: "Points", typ: 0 },
			{ json: "Depth", js: "Depth", typ: 0 },
			{ json: "LodDepth", js: "LodDepth", typ: 0 },
			{ json: "SplitFactor", js: "SplitFactor", typ: 0 },
			{ json: "FormatVersion", js: "FormatVersion", typ: 0 },
			{ json: "BuilderVersion", js: "BuilderVersion", typ: 0 },
			{ json: "Children", js: "Children", typ: a("any") },
		],
		//All fields present in the JSON and not specified above, are parsed as 'any'
		"any",
	),
	Class: ["WSPointCloud"],
	SourceType: ["EntityCPE", "Scan"],
	State: ["complete"],
	Type: ["EntityPC", "Project"],
};
