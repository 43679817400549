import { Grid, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, RefObject, useEffect, useState } from "react";
import { createPortal } from "react-dom";

/** Props for the Canvas overlay */
export type CanvasOverlayProps = PropsWithChildren<{
  /** Handle to R3F created canvas */
  canvas: RefObject<HTMLCanvasElement>;

  sx?: SxProps<Theme>;
}>;

/**
 * R3F Canvas component will create the following component tree: div > div > canvas
 *
 * Any element that is used as an eventSource for R3F events need to live inside the outer div
 * created by R3F
 *
 * As our Overlay can contain divs used for positioning and interacting with Views (split screen, mini map)
 * they have to be rendered in the correct position
 *
 * @returns A component that will take care of rendering the Overlay inside the div created by R3F so the events can
 * be forwarded correctly
 */
export function CanvasOverlay({
  canvas,
  sx,
  children,
}: CanvasOverlayProps): JSX.Element | null {
  const [r3fWrapper, setR3FWrapper] = useState<Element>();
  useEffect(() => {
    if (canvas.current) {
      const canvasElement = canvas.current;
      const internalDiv = canvasElement.parentElement;
      const externalDiv = internalDiv?.parentElement;
      if (!internalDiv || !externalDiv) {
        throw Error(
          "R3F wraps the canvas with two divs, if they're missing something changed in R3F and we need to revise this logic",
        );
      }
      // We want our overlay to be on top of the internalDiv but if we position it with absolute the events will not pas
      // So we set the internalDiv position to absolute instead and place our overlay as its sibling
      internalDiv.style.position = "absolute";
      internalDiv.style.zIndex = "0";
      setR3FWrapper(externalDiv);
    }
  }, [canvas]);

  if (!r3fWrapper || !children) return null;

  return (
    <>
      {createPortal(
        <Grid
          sx={{
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            ...sx,
          }}
          container
          direction="column"
          justifyContent="space-between"
        >
          {children}
        </Grid>,
        r3fWrapper,
      )}
    </>
  );
}
