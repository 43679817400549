import { FetchError } from "@faro-lotv/foundation";
import { Box3, Vector3, Vector3Tuple } from "three";
import { PotreeNode } from "./PotreeNode";
import { PotreeTree } from "./PotreeTree";

/**
 * Names of the attributes in the Potree point cloud that are supported by LotV.
 * The full set of attributes supported by Potree is the one in the specified by
 * the LAS format, see https://www.asprs.org/wp-content/uploads/2019/07/LAS_1_4_r15.pdf
 */
export enum ValidPotreeAttributeNames {
	POSITION = "position",
	COLOR = "rgb",
}

/**
 * The supported encodings used to generate the Potree data
 */
export type PotreeEncodings = "DEFAULT" | "BROTLI";

/**
 * Byte size for each supported attribute data type.
 */
export const PotreePointAttributeTypes: Record<string, number> = {
	double: 8,
	float: 4,
	int8: 1,
	uint8: 1,
	int16: 2,
	uint16: 2,
	int32: 4,
	uint32: 4,
	int64: 8,
	uint64: 8,
};

/**
 * Descriptor of a point cloud attribute. Each attribute is an array of values, where each value
 * can be a single element or a tuple. Currently, we support only 3-elements values: position and colors.
 */
export type PotreeAttribute = {
	/** A description of the attribute */
	description: string;
	/** Byte size of an element of the tuple */
	elementSize: number;
	/** The maximum value of the attribute among all stored values */
	max: Vector3Tuple;
	/** The minimum value of the attribute among all stored values */
	min: Vector3Tuple;
	/** The name of the attribute, e.g "position", "rgb" */
	name: string;
	/** Number of elements in the tuple */
	numElements: number;
	/** The offset to add to the value. Together with the scale allows to retrive the original value*/
	offset: Vector3Tuple;
	/** The scale to apply to the value. Together with the offset allows to retrive the original value*/
	scale: Vector3Tuple;
	/** The byte size of one value, i.e. numElements multiplied by elementSize */
	size: number;
	/** The name of the data type for one element of the tuple, e.g. "int32", "uint16") */
	type: string;
};

/** The hierarchy field in the Potree metadata descriptor */
export type PotreeHierarchyMetadata = {
	/** Max depth of the tree */
	depth: number;
	/** The size in bytes of the first chunk of the tree */
	firstChunkSize: bigint;
	/** Number of levels chunked together before adding proxy nodes in the hierarchy */
	stepSize: number;
};

/** Descriptor of the potree structure */
export type PotreeMetadata = {
	/** The list of attributes stored in the cloud */
	attributes: PotreeAttribute[];
	/** The bounding of the cloud, translated to the origin */
	boundingBox: {
		min: Vector3Tuple;
		max: Vector3Tuple;
	};
	/** Description metadata of the cloud */
	description: string;
	/** Encoding type used for the computing the binary data */
	encoding: PotreeEncodings;
	/** The hierarchy metadata information */
	hierarchy: PotreeHierarchyMetadata;
	/** Name of the point cloud */
	name: string;
	/** The offset to apply to the bounding box. Together with the scale allows to retrive the original bounding box  */
	offset: Vector3Tuple;
	/** The number of points inside the cloud. */
	points: number;
	/** The point cloud projection. Currently not used.*/
	projection: string;
	/** The scale to apply to the value. Together with the offset allows to retrive the original value*/
	scale: Vector3Tuple;
	/** The spacing of the points in the root node  */
	spacing: number;
	/** The version of the potree data */
	version: string;
};

/**
 * Extract a node bounding box based on the parent one. The parent bounding
 * box is subdivided in 8 sub-boxes, each one is specified by one index in the
 * [0 - 7] range.
 *
 * @param bbox The parent bounding box
 * @param index The index of which of the 8 bounding box we need to extract
 * @returns The bounding box
 */
export function createChildAABB(bbox: Box3, index: number): Box3 {
	/**
	 * Index is a bitflag in the format 0b0xyz.
	 * A value of 1 in one of the three components means that
	 * we are taking into account the bounding boxes in the positive semi-space
	 * defined by the corresponding axis passing through the center of the bounding box.
	 *
	 * @example 0b01YZ means all the bounding boxes where x is greater than
	 * 			boundingBoxCenter.x
	 */
	const size = bbox.getSize(new Vector3());
	const b = bbox.clone();
	if ((index & 0b0001) > 0) {
		b.min.z += size.z / 2;
	} else {
		b.max.z -= size.z / 2;
	}

	if ((index & 0b0010) > 0) {
		b.min.y += size.y / 2;
	} else {
		b.max.y -= size.y / 2;
	}

	if ((index & 0b0100) > 0) {
		b.min.x += size.x / 2;
	} else {
		b.max.x -= size.x / 2;
	}
	return b;
}

/**
 * Parse the buffer containing the hierarchy information of a node
 *
 * @param tree The tree to which we want to add new points
 * @param node Node whose hierarchy information should be computed
 * @param buffer The raw data buffer containing the hierarchy information
 * @returns The newly added nodes
 */
export function parseHierarchy(tree: PotreeTree, node: PotreeNode, buffer: ArrayBuffer): PotreeNode[] {
	const BYTES_PER_NODE = 22;
	const view = new DataView(buffer);
	const numNodes = buffer.byteLength / BYTES_PER_NODE;

	const nodes: PotreeNode[] = new Array(numNodes);
	nodes[0] = node;
	let nodePos = 1;

	for (let i = 0; i < numNodes; i++) {
		const current = nodes[i];

		const BYTE_SIZE_OFFSET = 14;
		const type = view.getUint8(i * BYTES_PER_NODE + 0);
		const childMask = view.getUint8(i * BYTES_PER_NODE + 1);
		const numPoints = view.getUint32(i * BYTES_PER_NODE + 2, true);
		const byteOffset = view.getBigInt64(i * BYTES_PER_NODE + 6, true);
		const byteSize = view.getBigInt64(i * BYTES_PER_NODE + BYTE_SIZE_OFFSET, true);

		if (current.isProxyNode) {
			// replace proxy with real node
			current.byteOffset = byteOffset;
			current.byteSize = byteSize;
			current.numPoints = numPoints;
		} else if (type === 2) {
			// load proxy
			current.hierarchyOffset = byteOffset;
			current.hierarchySize = byteSize;
			current.numPoints = numPoints;
		} else {
			// load real node
			current.byteOffset = byteOffset;
			current.byteSize = byteSize;
			current.numPoints = numPoints;
		}

		if (current.byteSize === BigInt(0)) {
			current.numPoints = 0;
		}

		current.isProxyNode = type === 2;

		if (current.isProxyNode) {
			continue;
		}

		for (let childIndex = 0; childIndex < 8; childIndex++) {
			const childExists = ((1 << childIndex) & childMask) !== 0;

			if (!childExists) {
				continue;
			}

			const childName = current.name + childIndex;

			const childAABB = createChildAABB(current.boundingBox, childIndex);
			const child = new PotreeNode(tree.numNodes - 1 + nodePos, current.id, childAABB, current.depth + 1);
			child.name = childName;

			current.children.push(child);

			nodes[nodePos] = child;
			nodePos++;
		}
	}
	// Remove the first element since it's already in the tree
	nodes.shift();
	tree.addNodes(nodes);
	return nodes;
}

/**
 * Load the hierarchy information for this node
 *
 * @param url The url from which the hierarchy should be downloaded
 * @param tree The tree which the node belongs to
 * @param node the node for which we want to load the hierarchy information
 * @param pcBackendUrl server used to optimize range requests
 * @returns the parsed potree nodes
 */
export async function loadHierarchy(
	url: URL,
	tree: PotreeTree,
	node: PotreeNode,
	pcBackendUrl?: string,
): Promise<PotreeNode[]> {
	const { hierarchyOffset, hierarchySize } = node;

	const first = hierarchyOffset;
	const last = first + hierarchySize - BigInt(1);

	let response: Response;

	if (pcBackendUrl) {
		response = await fetch(`${pcBackendUrl}/streaming/range/${first}/${last}${url.pathname}`);
	} else {
		response = await fetch(url, {
			headers: {
				"content-type": "multipart/byteranges",
				Range: `bytes=${first}-${last}`,
			},
		});
	}

	if (!response.ok) throw new FetchError("PotreeNodeFetch", "hierarchy");

	const buffer = await response.arrayBuffer();
	return parseHierarchy(tree, node, buffer);
}
