import { ColorString, blue, cyan, neutral } from "../colors";

type FilterMenuColors = {
  color: ColorString;
  borderColor: ColorString;
  backgroundColor: ColorString;
  scrollbarThumbColor: ColorString;
  checkBoxColor: ColorString;
};

export const LIGHT_COLORS: Readonly<FilterMenuColors> = Object.freeze({
  color: neutral[800],
  borderColor: neutral[200],
  backgroundColor: neutral[0],
  scrollbarThumbColor: neutral[999],
  checkBoxColor: blue[500],
});

export const DARK_COLORS: Readonly<FilterMenuColors> = Object.freeze({
  color: neutral[100],
  borderColor: neutral[800],
  backgroundColor: neutral[999],
  scrollbarThumbColor: neutral[0],
  checkBoxColor: cyan[400],
});
