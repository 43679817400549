uniform vec3 diffuse;
uniform float opacity;

#include <common>
#include <uv_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <alphatest_pars_fragment>
#include <fog_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

void main() {
	#include <clipping_planes_fragment>
	#include <logdepthbuf_fragment>

	vec3 outgoingLight = vec3( 0.0 );

	vec4 diffuseColor = vec4( diffuse, opacity );

	#include <map_fragment>
	#include <alphamap_fragment>
	#include <alphatest_fragment>

	gl_FragColor = diffuseColor;

	#include <tonemapping_fragment>
	#include <colorspace_fragment>
	#include <fog_fragment>
}
