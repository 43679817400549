import type {
  GUID,
  IElementGroup,
  IElementModel3D,
  IElementTypeHint,
  WithHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Adds a Model3d
 */
export interface MutationAdd3dNode extends BaseMutation {
  type: MutationTypes.MutationAdd3dNode;

  /** The Nodes group to create. Only needed, if it doesn't already exist. */
  group?: CreateIElement<WithHint<IElementGroup, IElementTypeHint.nodes>>;

  /** The Model3d to create. */
  newElement: CreateIElement<IElementModel3D>;
}

/**
 * The mutation adds a Model3d node as the child of a node group.
 *
 * ```
 * Group (Nodes)
 * └ [... other nodes]
 * └ Model3d (Node)
 * ```
 *
 * @param elementId The element to attach the Model3d to. This should either be a Nodes group, or an element to attach a new Nodes group to.
 * @param newElement The new Model3d element
 * @param group A new Nodes group to create. Leave undefined if a model group already exists.
 * @returns the mutation to add a Model3d
 */
export function createMutationAdd3dNode(
  elementId: GUID,
  newElement: CreateIElement<IElementModel3D>,
  group?: CreateIElement<WithHint<IElementGroup, IElementTypeHint.nodes>>,
): MutationAdd3dNode {
  return {
    ...createBaseMutation(MutationTypes.MutationAdd3dNode, elementId),
    newElement,
    group,
  };
}
