import { BufferGeometry, Color, ColorRepresentation, Euler, Mesh, MeshBasicMaterial, Vector3 } from "three";

/** Interaction states for the handle */
export enum GizmoHandleState {
	default = "default",
	hovered = "hovered",
	focused = "focused",
}

/** The base color and overrides for the different states of the box handle */
export type GizmoHandleColors = Record<GizmoHandleState, ColorRepresentation>;

/** Defaults colors for X axis */
export const DEFAULT_X_COLORS: GizmoHandleColors = {
	default: "#B30404",
	hovered: "#FFBE19",
	focused: "#FFBE19",
};

/** Defaults colors for Y axis */
export const DEFAULT_Y_COLORS: GizmoHandleColors = {
	default: "#12B058",
	hovered: "#FFBE19",
	focused: "#FFBE19",
};

/** Defaults colors for Z axis */
export const DEFAULT_Z_COLORS: GizmoHandleColors = {
	default: "#0E4ECC",
	hovered: "#FFBE19",
	focused: "#FFBE19",
};

/**
 * A generic interface for objects used as handle by gizmos
 */
export class GizmoHandle extends Mesh<BufferGeometry, MeshBasicMaterial> {
	/** Name to recognize the Object in the scene graph */
	name = "GizmoHandle";

	/** The currently rendered interaction state of the handle */
	#state = GizmoHandleState.default;

	/** Material used for rendering the xray mesh. It's color will be dynamically changed per object. */
	xrayMeshMat: MeshBasicMaterial;

	/**
	 *
	 * @param geometry The buffer describing the handle geoemetry
	 * @param position The position of the handle relative to the parent
	 * @param rotation The rotation of the handle relative to the parent
	 * @param colors The colors to use with the handle
	 */
	constructor(
		geometry: BufferGeometry,
		position: Vector3,
		rotation: Euler,
		public colors: GizmoHandleColors,
	) {
		super(geometry, new MeshBasicMaterial({ color: colors.default }));

		const xrayMeshScale = 0.99;
		this.xrayMeshMat = this.material.clone();
		this.xrayMeshMat.transparent = true;
		this.xrayMeshMat.opacity = 0.5;
		this.xrayMeshMat.depthTest = false;
		const xrayMesh = new Mesh(this.geometry, this.xrayMeshMat);
		// Disable raycast on the xray mesh
		xrayMesh.raycast = () => {};
		this.add(xrayMesh);
		xrayMesh.scale.setScalar(xrayMeshScale);

		this.position.copy(position);
		this.rotation.copy(rotation);

		this.#state = GizmoHandleState.default;
	}

	/**
	 * Change the current interaction state of the handle
	 *
	 * @param state The new state of the handle
	 */
	protected setState(state: GizmoHandleState): void {
		this.#state = state;

		switch (this.#state) {
			case GizmoHandleState.default:
				this.material.color = new Color(this.colors.default);
				this.xrayMeshMat.color = this.material.color;
				this.xrayMeshMat.opacity = 0.5;
				break;
			case GizmoHandleState.hovered:
				this.material.color = new Color(this.colors.hovered);
				this.xrayMeshMat.color = this.material.color;
				this.xrayMeshMat.opacity = 0.8;
				break;
			case GizmoHandleState.focused:
				this.material.color = new Color(this.colors.focused);
				this.xrayMeshMat.color = this.material.color;
				this.xrayMeshMat.opacity = 0.8;
				break;
		}
	}

	/** Change the current interaction state of the handle  */
	set state(state: GizmoHandleState) {
		this.setState(state);
	}

	/** @returns the current interaction state of the handle */
	get state(): GizmoHandleState {
		return this.#state;
	}
}
