import { GUID, ILabel } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Removes a label from an element in the project.
 */
export interface MutationRemoveLabel extends BaseMutation {
  type: MutationTypes.MutationRemoveLabel;

  /** The id of the element to update */
  elementId: GUID;

  /** The id of the label to remove from the element. */
  labelId: ILabel["id"];
}

/**
 * @returns a mutation to remove a label from an element in the project
 * @param elementId The id of the element to update
 * @param labelId The id of the label to remove
 */
export function createMutationRemoveLabel(
  elementId: MutationRemoveLabel["elementId"],
  labelId: MutationRemoveLabel["labelId"],
): MutationRemoveLabel {
  return {
    ...createBaseMutation(MutationTypes.MutationRemoveLabel, elementId),
    labelId,
  };
}
