import { useMediaQuery, useTheme } from "@mui/material";

/**
 * @returns whether the theme's breakpoint xs (or higher) matches
 */
export function useBreakpointXsUp(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("xs"));
}

/**
 * @returns whether the theme's breakpoint sm (or higher) matches
 */
export function useBreakpointSmUp(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("sm"));
}

/**
 * @returns whether the theme's breakpoint md (or higher) matches
 */
export function useBreakpointMdUp(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
}

/**
 * @returns whether the theme's breakpoint lg (or higher) matches
 */
export function useBreakpointLgUp(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("lg"));
}

/**
 * @returns whether the theme's breakpoint xl (or higher) matches
 */
export function useBreakpointXlUp(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("xl"));
}
