#define PHONG

uniform float size;
varying float realSize;
uniform bool debugColors;

uniform bool lights;
uniform vec3 diffuse;
uniform vec3 emissive;
uniform vec3 specular;
uniform float shininess;
uniform float opacity;

#include <common>
#include <color_pars_fragment>
#include <map_particle_pars_fragment>
#include <alphatest_pars_fragment>
#include <fog_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

#include <envmap_common_pars_fragment>
#include <envmap_pars_fragment>

#include <bsdfs>
#include <lights_pars_begin>
#include <normal_pars_fragment>
#include <lights_phong_pars_fragment>
#include <shadowmap_pars_fragment>

void main() {

	#include <clipping_planes_fragment>

	vec4 diffuseColor = vec4( diffuse, opacity );
	ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
	vec3 totalEmissiveRadiance = emissive;
	float specularStrength = 1.0;

	#include <logdepthbuf_fragment>
	#include <map_particle_fragment>
	#include <color_fragment>
	#include <alphamap_fragment>
	#include <alphatest_fragment>
	vec3 outgoingLight = diffuseColor.rgb;

	if(lights)
	{
		#include <normal_fragment_begin>

		// accumulation
		#include <lights_phong_fragment>
		#include <lights_fragment_begin>
		#include <lights_fragment_maps>
		#include <lights_fragment_end>
		// modulation
		#include <aomap_fragment>
		
		outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

		#include <envmap_fragment>
	}
	#include <opaque_fragment>
	#include <tonemapping_fragment>
	#include <colorspace_fragment>
	#include <fog_fragment>
	#include <premultiplied_alpha_fragment>
	if(debugColors)
	{
		if(realSize < 1.0)
		{
			gl_FragColor = vec4(realSize,0.0,0.0,1.0);
		}
		else if(realSize / 10.0 < 1.0)
		{
			gl_FragColor = vec4(0.0,realSize/10.0,0.0,1.0);
		}
		else
		{
			gl_FragColor = vec4(0.0,0.0,realSize/100.0,1.0);
		}
	}
}
