import { useThree } from "@react-three/fiber";

/**
 * Compute the HTML element to use for events inside the current View or return the main
 * R3F HTML element if not in a View.
 * Allow the user to override this logic with a custom element.
 * This hook will usually return the component containing the canvas (e.g. a div)
 * or the part of the canvas intersected by the current view.
 *
 * @param domElement an explicit element to use for target events
 * @returns the HTMLElement to be used for events
 */
export function useThreeEventTarget(domElement?: HTMLElement): HTMLElement {
  const target = useThree((state) => state.events.connected);
  const canvas = useThree((state) => state.gl.domElement);
  const previousRoot = useThree((state) => state.previousRoot);

  if (target && !(target instanceof HTMLElement)) {
    throw new Error("The R3F event target is not a dom element");
  }

  // When we use the <View> class, we override the events.connected property in the R3F state.
  // The previous value of this property is stored in previousRoot: this means that if
  // this property is defined, then the scene is currently inside a a View, otherwise is not.
  if (!previousRoot) {
    // Override the default r3f event target and make application code listen to events on the canvas instead.
    // This is necessary due to our special overlay setup, which makes the application events interfere with the overlay.
    //
    // In `canvas-overlay.tsx` the overlay is placed inside the default r3f event target, which is a parent of the canvas:
    //
    // div (default events.connected if it is not overwritten by a portal)
    //   div
    //     canvas (desired event target to avoid interfering with overlay)
    //   div (mode overlay content)
    //
    // This setup is necessary for the minimap to work, since its event source in the overlay needs to be inside the outer div.
    // (see `canvas-overlay.tsx`). But this has the side effect, that events meant for the overlay also trigger application
    // listeners, which can cause issues if e.g. the listeners for the controls prevent mouse events from working on the UI overlay.
    //
    // Having application listeners attached to the canvas instead makes sure that only events meant for the canvas are processed.
    // This is similar to the setup when `<View>`s are used to create new r3f roots. They default to the context or a sibling in the UI.
    return domElement ?? canvas.parentElement ?? canvas;
  }

  return domElement ?? target ?? canvas;
}
