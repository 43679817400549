import { assert } from "@faro-lotv/foundation";
import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { Divider, Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { neutral } from "../../colors";
import { Option } from "../../dropdown";
import { FaroRichTextEditor } from "../../rich-text";
import { NoTranslate } from "../../translation";
import {
  AnnotationEditorButtons,
  AnnotationEditorButtonsProps,
} from "./annotation-editor-buttons";
import {
  AnnotationEditorHeader,
  AnnotationEditorHeaderProps,
} from "./annotation-editor-header";
import {
  AnnotationEditorMetaFields,
  AnnotationEditorMetaFieldsProps,
} from "./annotation-editor-meta-fields";
import { AnnotationEditorTypeSelection } from "./annotation-editor-type-selection";

/** The maximum allowed number of characters in ProjectAPI for description of the annotation */
const MAX_DESCRIPTION_LENGTH = 10000;

export type AnnotationTypeDropdownProps = {
  /** The annotation type that should be selected in the dropdown */
  selectedAnnotationType: string;

  /** Function called when the selected value of the annotation type dropdown field changes */
  onAnnotationTypeChange(value: string): void;

  /** List of the available annotation type that can be created */
  annotationTypeOptions: Option[];
};

export type AnnotationEditorProps = AnnotationEditorHeaderProps &
  AnnotationEditorMetaFieldsProps &
  AnnotationEditorButtonsProps &
  AnnotationTypeDropdownProps & {
    /**
     * Initial value of the description text field.
     * Updating this prop from parent will have no effect!
     */
    initialDescription?: string;

    /** Function called when the content of the description text field changes */
    onDescriptionChange?(description?: string): void;

    /**
     * Method to call if there's an error with the description
     */
    onDescriptionError(error: Error): void;

    /**
     * If `true`, it indicates that the annotation type dropdown should be displayed otherwise it should be hidden.
     */
    showAnnotationTypeDropdown?: boolean;
  };

/**
 * @returns a dialog used to create and edit annotations and their fields value
 */
export function AnnotationEditor({
  children,
  title,
  initialDescription = "",
  assignee = "",
  initialDate,
  status = "",
  selectedAnnotationType,
  assigneeOptions,
  statusOptions,
  annotationTypeOptions,
  tagsOptions,
  showConfirmButtonSpinner,
  confirmButtonText,
  attachments,
  tags,
  addNewAttachment,
  onTitleChange,
  onDescriptionChange,
  onAssigneeChange,
  onDueDateChange,
  onStatusChange,
  onAnnotationTypeChange,
  onTagsChange,
  onAddNewTagClick,
  onCancelButtonClick,
  onConfirmButtonClick,
  onAttachmentOpened,
  showAnnotationTypeDropdown = false,
  autoFocusTitle = false,
  shouldShowAttachments,
  disabled,
  progress,
  onDescriptionError,
}: PropsWithChildren<AnnotationEditorProps>): JSX.Element {
  const [descriptionLength, setDescriptionLength] = useState(
    initialDescription.length,
  );

  const handleDescriptionChange = useCallback(
    (newDescription: string) => {
      setDescriptionLength(newDescription.length);
      onDescriptionChange?.(newDescription);
    },
    [onDescriptionChange],
  );

  if (showAnnotationTypeDropdown) {
    assert(
      annotationTypeOptions.length > 0,
      "The annotationTypeOptions should not be empty when showAnnotationTypeDropdown is true",
    );
  }

  const finalAnnotationTypeOptions = useMemo(() => {
    assert(annotationTypeOptions, "annotationTypeOptions is invalid");

    return annotationTypeOptions.map((o) => ({
      ...o,
      label: <NoTranslate>{o.label}</NoTranslate>,
    }));
  }, [annotationTypeOptions]);

  return (
    <Stack
      direction="column"
      sx={{
        width: "522px",
        backgroundColor: neutral[999],
        p: 2,
        borderRadius: "4px",
        pointerEvents: disabled ? "none" : undefined,
        flexGrow: 1,
        gap: 3,
        // Allow the main content to scroll if there's not enough space
        overflowY: "auto",
        // but do not add an horizontal scrollbar when the main content needs to scroll
        overflowX: "clip",
      }}
    >
      {showAnnotationTypeDropdown && (
        <>
          <AnnotationEditorTypeSelection
            value={selectedAnnotationType}
            options={finalAnnotationTypeOptions}
            onChange={(e) => onAnnotationTypeChange?.(e.target.value)}
            disabled={disabled}
          />
          <Divider sx={{ bgcolor: neutral[0] }} />
        </>
      )}
      <AnnotationEditorHeader
        title={title}
        autoFocusTitle={autoFocusTitle}
        onTitleChange={onTitleChange}
        disabled={disabled}
      />
      <Stack
        sx={{
          // Add a scrollbar only to the main content if needed
          overflow: "auto",
          flexGrow: 1,
          gap: 3,
          mx: -0.875,
          px: 0.875,
        }}
      >
        <FaroRichTextEditor
          initialText={initialDescription}
          dark
          fullWidth
          readOnly={disabled}
          label="Description"
          placeholder="Insert a description"
          onError={onDescriptionError}
          onChange={handleDescriptionChange}
          sx={{
            height: "120px",
          }}
        />

        {children}
        <AnnotationEditorMetaFields
          disabled={disabled}
          assignee={assignee}
          initialDate={initialDate}
          status={status}
          assigneeOptions={assigneeOptions}
          statusOptions={statusOptions}
          tagsOptions={tagsOptions}
          attachments={attachments}
          tags={tags}
          addNewAttachment={addNewAttachment}
          onAddNewTagClick={onAddNewTagClick}
          onAssigneeChange={onAssigneeChange}
          onDueDateChange={onDueDateChange}
          onStatusChange={onStatusChange}
          onTagsChange={onTagsChange}
          shouldShowAttachments={shouldShowAttachments}
          progress={progress}
          onAttachmentOpened={onAttachmentOpened}
        />
      </Stack>
      <AnnotationEditorButtons
        disabledConfirmButton={
          !title ||
          title.length === 0 ||
          title.length > MAX_NAME_LENGTH ||
          descriptionLength > MAX_DESCRIPTION_LENGTH
        }
        disabled={disabled}
        showConfirmButtonSpinner={showConfirmButtonSpinner}
        confirmButtonText={confirmButtonText}
        onCancelButtonClick={onCancelButtonClick}
        onConfirmButtonClick={onConfirmButtonClick}
      />
    </Stack>
  );
}
