import { Object3D } from "three";
import { hasGeometry, hasMaterial } from "./TypeTraits";

/**
 * Analyze the object prototype and call the dispose method if it exists
 *
 * @param object The object we want to dispose
 */
export function callDisposeIfExists(object: Object3D): void {
	// THREE.js had a dispose method on Scene, they "removed it"
	// replacing it with a version that just logs a warning
	// so the method exists on the prototype but we don't want to call it
	if (object.type === "Scene") return;
	const proto = Object.getPrototypeOf(object);
	if (proto.dispose) {
		proto.dispose.apply(object);
	}
}

/**
 * Default options for the calls to safeDispose
 */
export const disposeDefaults = {
	/** true to dispose all the children of the passed object recursively */
	recursive: true,
	/** true to auto dispose the material of the objects too */
	disposeMaterial: true,
	/** true to auto dispose the geometry of the objects too */
	disposeGeometry: true,
};

/** Type of the options to customize the behavior of the safeDispose function */
export type DisposeOptions = typeof disposeDefaults;

/**
 * A function to dispose an object or an object tree safely
 *
 * This function is needed because using library like ReactThreeFiber we need to interplay
 * with the other library disposal logic that can override the .dispose() method on an Object3D
 * and can dispose of materials, geometry and children in advance changing the .children property to null
 *
 * This function will check for the existence of every property before calling any method to not error out
 * if some other library have already disposed something and will use the .dispose() method from the object
 * prototype and not the instance so it will work even if the .dispose() method have been replaced
 *
 * @param object The object we want to dispose
 * @param options Disposal options like if we want to recurse or if we want or not to dispose materials/geometries too
 */
export function safeDispose(object: Object3D, options: Partial<DisposeOptions> = {}): void {
	const o = { ...disposeDefaults, ...options };
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
	if (o.recursive && object.children) {
		for (const child of object.children) {
			safeDispose(child, o);
		}
	}
	// Call the dispose method from the object prototype if it exists
	callDisposeIfExists(object);
	if (o.disposeGeometry && hasGeometry(object)) {
		object.geometry.dispose();
	}
	if (o.disposeMaterial && hasMaterial(object)) {
		object.material.dispose();
	}
}
