import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalIsTextContentEmpty } from "@lexical/react/useLexicalIsTextContentEmpty";
import { useForkRef } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import { forwardRef, useCallback, useLayoutEffect, useState } from "react";
import { blue, cyan, neutral } from "../colors";
import { FaroRichTextCoreProps } from "./faro-rich-text-core";

type ContentEditableProps = Pick<
  FaroRichTextCoreProps,
  "placeholder" | "readOnly" | "dark"
> &
  // The focus and blur events are implemented, so that styles are correctly applied
  // when the ContentEditable is used as a MUI Input implementation
  Pick<BoxProps, "onFocus" | "onBlur">;

const HORIZONTAL_PADDING = 1.5;

/**
 * @returns The component that is used by lexical to modify the content
 */
export const ContentEditable = forwardRef<HTMLElement, ContentEditableProps>(
  function ContentEditable(
    { placeholder, readOnly, dark, onFocus, onBlur },
    externalRef,
  ): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const [isEditable, setEditable] = useState(false);
    const isEmpty = useLexicalIsTextContentEmpty(editor);

    // Hand control of div over to lexical
    const internalRef = useCallback(
      (rootElement: null | HTMLElement) => {
        // defaultView is required for a root element.
        // In multi-window setups, the defaultView may not exist at certain points.
        if (rootElement?.ownerDocument?.defaultView) {
          editor.setRootElement(rootElement);
        }
      },
      [editor],
    );

    const ref = useForkRef(internalRef, externalRef);

    // Sync editable state between editor and this component
    useLayoutEffect(() => {
      setEditable(editor.isEditable());
      return editor.registerEditableListener((currentIsEditable) => {
        setEditable(currentIsEditable);
      });
    }, [editor]);

    return (
      <Box
        component="div"
        ref={ref}
        contentEditable={isEditable}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto",

          // Removes the browser's default outline when focused/hovered
          outline: "none",

          // Adjusting styling here as lexical will override styles set on other levels
          "& p, &:before": {
            m: 0,
            py: 1.25,

            px: readOnly ? 0 : HORIZONTAL_PADDING,
          },

          "& a": {
            color: dark ? cyan[400] : blue[500],
          },

          // Add a placeholder
          "&:not(:focus):before": {
            content: isEmpty && placeholder ? `"${placeholder}"` : "unset",
            fontStyle: "italic",
            color: neutral[500],
            position: "absolute",
          },
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  },
);
