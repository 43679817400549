import { BufferAttribute, BufferGeometry, Intersection, Mesh, Object3D, Raycaster, ShaderMaterial } from "three";
import frag from "../Shaders/FullScreenQuad.frag";
import vert from "../Shaders/FullScreenQuad.vert";

/** Material for the full screen quad */
export class FullScreenQuadMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;

	constructor() {
		super();
	}
}

/** An object that renders a full screen quad at the far plane */
export class FullScreenQuad extends Mesh<BufferGeometry, FullScreenQuadMaterial> {
	constructor() {
		super(new BufferGeometry(), new FullScreenQuadMaterial());

		this.geometry.setAttribute(
			"position",
			new BufferAttribute(new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, 1, 1, -1, 1]), 3),
		);
		this.geometry.setIndex([0, 3, 1, 3, 2, 1]);
	}

	/** @inheritdoc */
	override raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		const intersection: Intersection<Mesh> = {
			distance: Number.POSITIVE_INFINITY,
			point: raycaster.ray.origin.clone().setY(0),
			object: this,
		};
		intersects.push(intersection);
	}
}
