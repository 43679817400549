import { Disposable } from "@faro-lotv/foundation";

/** @deprecated Import Disposable from @faro-lotv/foundation instead. */
export { type Disposable } from "@faro-lotv/foundation";

/**
 * A wrapper for an object that need to be disposed that can optionally be owned or not
 */
export class MayOwn<T extends Disposable> {
	/** The wrapped object */
	item: T;

	/** true if this handle own the object and need to dispose it */
	owned: boolean;

	/**
	 * Construct a handle to an object and decide if this handle owns it
	 *
	 * @param item we want to wrap
	 * @param owned true if this handle own the item
	 */
	constructor(item: T, owned: boolean) {
		this.item = item;
		this.owned = owned;
	}

	/**
	 * Dispose the item if this handle owns it
	 */
	dispose(): void {
		if (this.owned) {
			this.item.dispose();
		}
	}
}
