import { Size, useThree } from "@react-three/fiber";
import { ViewContext } from "../utils/view";
import { useContext, useEffect, RefObject, useRef } from "react";

/**
 * Sometimes you want your element to react to viewport changes.
 * But R3F size property does not account for our { @see View } class so you can't use that reliably
 *
 * This function will return a ref to the correct viewport.
 *
 * A ref is used because you can use it inside a useFrame callback and it will work as expected.
 * Returning a plain value will be curried over by the useFrame callback and will force you to wrap the
 * useFrame callback in a useCallback for it to react.
 *
 * @returns a ref of the current active viewport, global one or the current View, usable in the useFrame
 */
export function useViewportRef(): RefObject<Size> {
  const size = useThree((s) => s.size);

  const mainSize = useRef(size);

  useEffect(() => {
    mainSize.current = size;
  }, [size]);

  const viewContext = useContext(ViewContext);

  return viewContext?.viewSize ?? mainSize;
}
