import { Quaternion, Vector3 } from "three";

/**
 * @param quaternion to convert
 * @param epsilon to consider an angle 0 to check for degenerate quaternions
 * @param axis object to store the result
 * @returns the axis and angle of rotation for this quaternion
 *
 * Implemented following (https://en.wikipedia.org/wiki/Quaternions_and_spatial_rotation#Recovering_the_axis-angle_representation)
 */
export function quaternionToAxisAngle(quaternion: Quaternion, epsilon = 1e-6, axis?: Vector3): [Vector3, number] {
	axis = (axis ?? new Vector3()).set(0, 0, 1);

	const s = Math.sqrt(quaternion.x * quaternion.x + quaternion.y * quaternion.y + quaternion.z * quaternion.z);

	if (s < epsilon) {
		return [axis, 0];
	}

	const angle = 2 * Math.atan2(s, quaternion.w);

	const x = quaternion.x / s;
	const y = quaternion.y / s;
	const z = quaternion.z / s;

	return [axis.set(x, y, z), angle];
}
