import { WebGLRenderer, Scene, Camera } from "three";
import { EffectPipeline } from "./EffectPipeline";
import { SubScenePipeline, SceneFilterFunction } from "./SubScenePipeline";

/**
 * An effect pipeline with support for sub-scenes, render one scene with a camera in multiple sub-scene passes
 * each sub-scene with it's own effects
 */
export class EffectPipelineWithSubScenes extends EffectPipeline {
	/** A mapping between scenes and their composer passes */
	scenes: SubScenePipeline[] = [];

	/**
	 * Construct an EffectPipeline
	 *
	 * @param renderer The render context to use for the effects
	 * @param scene The scene to render
	 * @param camera The camera to use to render in this pipeline
	 */
	constructor(
		renderer: WebGLRenderer,
		private scene: Scene,
		private camera: Camera,
	) {
		super(renderer);
	}

	/**
	 * Add a new sub-scene to the pipeline and returns it
	 *
	 * The sub scene will be rendered in the current pipeline step and the result of its private
	 * rendering will be blended on the main pipeline read buffer
	 *
	 * @param filter a function to decide what element of the scene we want to render, by default render everything
	 * @returns a sub scene pipeline to add effects to this sub scene
	 */
	addSubScene(filter?: SceneFilterFunction): SubScenePipeline;
	/**
	 * Add a new sub-scene to the pipeline and returns it
	 *
	 * The sub scene will be rendered in the current pipeline step and the result of its private
	 * rendering will be blended on the main pipeline read buffer
	 *
	 * @param subScene the sub scene to render in this pipeline
	 * @returns a sub scene pipeline to add effects to this sub scene
	 */
	addSubScene(subScene: SubScenePipeline): SubScenePipeline;
	/**
	 * Main implementation
	 *
	 * @param filterOrSubScene The filter or the already created sub-scene
	 * @returns a sub scene pipeline to add effects to this sub scene
	 */
	addSubScene(filterOrSubScene: SubScenePipeline | SceneFilterFunction = () => true): SubScenePipeline {
		const subScene =
			filterOrSubScene instanceof SubScenePipeline
				? filterOrSubScene
				: new SubScenePipeline(this.renderer, this.scene, this.camera, filterOrSubScene);
		this.scenes.push(subScene);
		this.addPass(subScene.blendPass);
		this.#updateFirstSceneClearFlag();
		return subScene;
	}

	/**
	 * Remove a sub-scene from this pipeline
	 *
	 * @param subScene The sub scene to remove
	 */
	removeSubScene(subScene: SubScenePipeline): void {
		const idx = this.scenes.indexOf(subScene);
		if (idx === -1) {
			throw new Error("The requested subscene is not in this effect pipeline");
		}
		this.removePass(subScene.blendPass);
		this.scenes.splice(idx, 1);
		this.#updateFirstSceneClearFlag();
	}

	/**
	 * Resize all needed data in this pipeline when the canvas change size
	 *
	 * @param width The new context width
	 * @param height The new context height
	 * @param dpr The new context pixel ratio
	 * @returns whether the new size was different from the old.
	 */
	setSize(width: number, height: number, dpr: number): boolean {
		if (this.width === width && this.height === height) return false;
		super.setSize(width, height, dpr);
		// Update the sub-scenes
		for (const scene of this.scenes) {
			scene.setSize(width, height, dpr);
		}
		return true;
	}

	/**
	 * Change the camera used to render this pipeline
	 *
	 * @param camera the new active camera
	 */
	changeCamera(camera: Camera): void {
		super.changeCamera(camera);
		// Update the sub-scenes
		for (const scene of this.scenes) {
			scene.changeCamera(camera);
		}
	}

	/**
	 * Change the scene used to render this pipeline
	 *
	 * @param scene the new active scene
	 */
	changeScene(scene: Scene): void {
		super.changeScene(scene);
		// Update the sub-scenes
		for (const subScene of this.scenes) {
			subScene.changeScene(scene);
		}
	}

	/**
	 * Make sure only the first blending pass will clear the main framebuffer
	 */
	#updateFirstSceneClearFlag(): void {
		for (const subScene of this.scenes) {
			subScene.blendPass.clear = false;
		}
		this.scenes[0].blendPass.clear = true;
	}
}
