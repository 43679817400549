import { InputLabel } from "@mui/material";
import { PropsWithChildren } from "react";
import { InputLabelDark } from "./base-inputs";
import { InputLabelTag, TagTypes } from "./input-label-tag";

export type InputLabelProps = {
  /** Whether the label should indicate a disabled state */
  disabled?: boolean;

  /** Additional tag to show next to the label, e.g. to mark a field as required */
  tag?: TagTypes;

  /** Enables error styles */
  error: boolean;

  /** Enables dark mode styles */
  dark?: boolean;
};

/**
 * @returns A label to place above input components.
 */
export function FaroInputLabel({
  children,
  disabled,
  tag,
  error,
  dark,
}: PropsWithChildren<InputLabelProps>): JSX.Element | null {
  if (!children) {
    return null;
  }

  const DISABLED_OPACITY = 0.6;

  const InputLabelVariant = dark ? InputLabelDark : InputLabel;

  return (
    <InputLabelVariant
      error={error}
      sx={{
        position: "unset",
        opacity: disabled ? DISABLED_OPACITY : 1,
      }}
      // prevents MUI from hiding the placeholder, when there is a label above the input
      shrink
    >
      {children}
      <InputLabelTag tag={tag} dark={dark} />
    </InputLabelVariant>
  );
}
