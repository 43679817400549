import {
  convertToDateTimeString,
  isDocumentFileExtension,
  isImageFileExtension,
  splitFileNameAndExtension,
} from "@faro-lotv/foundation";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardMediaProps,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  ReactEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NO_TRANSLATE_CLASS } from "../../types";
import { blue, cyan, neutral } from "../colors";
import { FontWeights } from "../faro-theme";
import {
  FaroIconButton,
  FaroIconButtonSizes,
} from "../icon-button/faro-icon-button";
import {
  AttachmentIcon,
  DeleteIcon,
  FileDocumentIcon,
  FileImageIcon,
} from "../icons";
import { FaroText } from "../text/faro-text/faro-text";
import {
  HorizontalScrollList,
  HorizontalScrollListProps,
} from "./horizontal-scroll-list";

export interface FileDetails {
  /** The unique identifier of the file */
  id: string;

  /** The name of the file */
  fileName: string;

  /** The size of the file */
  fileSize?: number | null;

  /** The date the file was uploaded */
  date: string;

  /** The url of the file */
  urlOrFile: string | File;

  /** The url of the thumbnail of the file */
  thumbnailUrl?: string | File | null;

  /**
   * The function to call when the file is deleted
   *
   * @param id - id of the FileDetails object that was passed in
   * @param extension The extension of the file
   * @param isNewAttachment Whether the attachment is a new one
   */
  onDelete?(id: string, extension: string, isNewAttachment: boolean): void;

  /**
   * True if the file is still loading. Provide placeholder strings for other file properties in case the file is loading
   *
   * @default false
   */
  isLoading?: boolean;
}

export interface FileDetailsListProps
  extends Omit<HorizontalScrollListProps, "items"> {
  /** The list of files to display */
  files: FileDetails[];

  /** Text to display in a tooltip when the delete button is hovered */
  deleteTooltipText?: string;

  onAttachmentOpened?(file: string): void;
}

export interface FileCardProps extends FileDetails {
  /** Whether the file card should be scrolled into view on mount */
  scrollIntoView?: boolean;

  /** Text to display in a tooltip when the delete button is hovered */
  deleteTooltipText?: string;

  onAttachmentOpened?(format: string): void;
}

function FileCard({
  id,
  fileName: fileNameWithExtension,
  urlOrFile,
  thumbnailUrl: thumbnail,
  date,
  onDelete,
  isLoading = false,
  deleteTooltipText,
  scrollIntoView = false,
  onAttachmentOpened,
}: FileCardProps): JSX.Element {
  const cardRef = useRef<HTMLDivElement>(null);

  const [isHoveringDeleteButton, setIsHoveringDeleteButton] = useState(false);
  const [isFileHovered, setIsFileHovered] = useState(false);

  const { fileName, extension } = splitFileNameAndExtension(
    fileNameWithExtension,
  );

  const url = useMemo(
    () =>
      typeof urlOrFile === "string"
        ? urlOrFile
        : URL.createObjectURL(urlOrFile),
    [urlOrFile],
  );

  // Scrolls the card into view if requested
  useEffect(() => {
    if (!cardRef.current || !scrollIntoView) return;
    cardRef.current.scrollIntoView();
  }, [scrollIntoView]);

  const thumbnailUrl = useMemo(() => {
    if (!thumbnail) return;
    if (typeof thumbnail === "string") return thumbnail;
    return URL.createObjectURL(thumbnail);
  }, [thumbnail]);

  return (
    <Tooltip
      title="Click to open in new tab"
      open={isFileHovered && !isHoveringDeleteButton}
      onOpen={() => setIsFileHovered(true)}
      onClose={() => setIsFileHovered(false)}
    >
      <Card
        ref={cardRef}
        key={id}
        sx={{
          width: 150,
          display: "flex",
          flexDirection: "column",
          background: neutral[950],
          position: "relative",
          "&:hover": {
            cursor: "pointer",
            background: `${neutral[500]}26`,
          },
          "&:active": {
            background: `${neutral[500]}40`,
          },
        }}
        onClick={() => {
          onAttachmentOpened?.(extension ?? "unknown");
          window.open(url, "_blank");
        }}
      >
        {isLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              sx={{
                aspectRatio: "16/9",
                m: 1,
                height: 100,
                background: neutral[800],
              }}
            />
            <Stack gap={0.75} m={1}>
              <Skeleton
                variant="text"
                sx={{
                  background: neutral[800],
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  background: neutral[800],
                }}
              />
            </Stack>
          </>
        ) : (
          <>
            <CardMediaWithFallback
              component="img"
              alt={fileName}
              src={thumbnailUrl ?? undefined}
              sx={{ aspectRatio: "16/9", objectFit: "cover" }}
              loading="lazy"
              fallback={<FileThumbnailFallback extension={extension} />}
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.25,
                p: 1,
              }}
            >
              <Stack direction="row">
                <FaroText
                  gutterBottom
                  variant="labelS"
                  fontWeight={FontWeights.SemiBold}
                  color={neutral[100]}
                  margin={0}
                  shouldElide
                  className={NO_TRANSLATE_CLASS}
                >
                  {fileName}
                </FaroText>
                {extension && (
                  <FaroText
                    gutterBottom
                    variant="labelS"
                    fontWeight={FontWeights.SemiBold}
                    color={neutral[100]}
                    margin={0}
                    className={NO_TRANSLATE_CLASS}
                  >
                    .{extension}
                  </FaroText>
                )}
              </Stack>
              <FaroText
                variant="labelS"
                fontWeight={FontWeights.Regular}
                color={neutral[400]}
                className={NO_TRANSLATE_CLASS}
              >
                {convertToDateTimeString(date)}
              </FaroText>
            </CardContent>
            <CardActions sx={{ position: "absolute", top: 0, right: 0 }}>
              {isFileHovered && onDelete && (
                <Tooltip
                  title={deleteTooltipText ?? ""}
                  open={isHoveringDeleteButton}
                  onOpen={() => setIsHoveringDeleteButton(true)}
                  onClose={() => setIsHoveringDeleteButton(false)}
                  // This is needed if the tooltip text is not defined, because in that case onClose will not be called
                  onMouseLeave={() => setIsHoveringDeleteButton(false)}
                >
                  <FaroIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(
                        id,
                        extension ?? "unknown",
                        urlOrFile instanceof File,
                      );
                    }}
                    color={neutral[100]}
                    hoverColor={neutral[100]}
                    size={FaroIconButtonSizes.s}
                    margin={0.5}
                    sx={{
                      borderRadius: 1,
                      background: `${neutral[999]}A6`,
                      "&:hover": {
                        background: cyan[600],
                      },
                      "&:active": {
                        background: cyan[700],
                      },
                    }}
                  >
                    <DeleteIcon />
                  </FaroIconButton>
                </Tooltip>
              )}
            </CardActions>
          </>
        )}
      </Card>
    </Tooltip>
  );
}

/**
 *  @returns a list of files with their details in a horizontally scrollable list
 */
export function FileDetailsList({
  files,
  deleteTooltipText,
  onAttachmentOpened,
  ...rest
}: FileDetailsListProps): JSX.Element {
  // Keep track of the previous files state to detect newly added files
  const [prevFiles, setPrevFiles] = useState(files);
  useEffect(() => setPrevFiles(files), [files]);

  const firstNewFile =
    files === prevFiles
      ? undefined
      : files.find((file) => !prevFiles.includes(file));

  return (
    <HorizontalScrollList
      scrollButtonProps={{
        size: FaroIconButtonSizes.s,
        color: neutral[100],
        hoverColor: blue[500],
        sx: {
          boxShadow: `0px 0px 4px ${neutral[100]}CC`,
          background: neutral[950],
          "&:hover": {
            background: neutral[800],
          },
          "&:active": {
            background: neutral[700],
          },
        },
      }}
      {...rest}
      scrollBarSx={{
        backgroundColor: neutral[800],
        thumbColor: neutral[600],
        thumbHoverColor: neutral[500],
      }}
    >
      {files.map((file) => (
        <FileCard
          key={file.id}
          deleteTooltipText={deleteTooltipText}
          scrollIntoView={file === firstNewFile}
          onAttachmentOpened={onAttachmentOpened}
          {...file}
        />
      ))}
    </HorizontalScrollList>
  );
}

interface CardMediaWithFallbackProps extends CardMediaProps<"img"> {
  /** The fallback to display when the card-media fails to load. */
  fallback: ReactNode;
}

/**
 * @param props The CardMedia props to pass through to the Material-UI CardMedia
 * @returns A CardMedia if the image is successfully displayed, or a a fallback otherwise.
 */
function CardMediaWithFallback(props: CardMediaWithFallbackProps): JSX.Element {
  // Keep track of which src attribute failed, so the fetching can be retried if it changes.
  const [lastErroredSrc, setLastErroredSrc] = useState<string>();

  const { src, fallback, onError, sx } = props;

  const handleError: ReactEventHandler<HTMLImageElement> = useCallback(
    (event) => {
      setLastErroredSrc(src);
      onError?.(event);
    },
    [onError, src],
  );

  if (!src || lastErroredSrc === src) {
    return (
      <CardMedia
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: neutral[900],
          ...sx,
        }}
      >
        {fallback}
      </CardMedia>
    );
  }

  return (
    <CardMedia
      {...props}
      onError={handleError}
      className={NO_TRANSLATE_CLASS}
    />
  );
}

interface FileThumbnailFallbackProps {
  /** The fileName to show a fallback for. Changes the icon displayed for the file. */
  extension?: string;
}

/**
 * @returns a fallback icon that visualizes the file type.
 */
function FileThumbnailFallback({
  extension,
}: FileThumbnailFallbackProps): JSX.Element {
  const Icon = useMemo(() => {
    if (isImageFileExtension(extension)) {
      return FileImageIcon;
    } else if (isDocumentFileExtension(extension)) {
      return FileDocumentIcon;
    }
    return AttachmentIcon;
  }, [extension]);

  return <Icon sx={{ fontSize: 32, color: neutral[300] }} />;
}
