import { neutral } from "../colors";
import { FaroText, FaroTextProps } from "../text/faro-text/faro-text";

export type FaroMenuSectionProps = Omit<FaroTextProps, "variant"> & {
  /** true to use the dark variant of the component @default false */
  dark?: boolean;
};

/** @returns a MenuItem styled to be used as a Section header in a FaroMenu */
export function FaroMenuSection({
  children,
  dark = false,
  sx,
  ...rest
}: FaroMenuSectionProps): JSX.Element {
  return (
    <FaroText
      variant="labelS"
      color={dark ? neutral[100] : neutral[800]}
      {...rest}
      sx={{
        display: "inline-block",
        textTransform: "uppercase",
        userSelect: "none",
        p: 1.5,
        pt: 1,
        ...sx,
      }}
    >
      {children}
    </FaroText>
  );
}
