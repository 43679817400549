import { Color, PerspectiveCamera, Texture, Vector4, WebGLRenderTarget, WebGLRenderer } from "three";
import { FullScreenQuad, Pass } from "three/examples/jsm/postprocessing/Pass.js";
import { ComposeFramebuffersMaterial } from "../Materials/ComposeFramebuffersMaterial";
import { MixFramebufferMaterial } from "../Materials/MixFramebufferMaterial";
import { TripleFBOComposerMaterial } from "../Materials/TripleFBOComposerMaterial";
import { CameraMode, SupportedCamera } from "../Utils";
import { SubScenePipeline } from "./SubScenePipeline";

/** The minimum opacity for a sub scene to be rendered. */
const MIN_OPACITY = 0.05;

/**
 * A pass to compose two or three subscenes on top of a framebuffer, by blending their offscreen framebuffers in a variety of ways.
 */
export class ComposeSubscenesPass extends Pass {
	/** Custom shader to compose three input FBOs with correctly blended transparency. */
	#tripleFBOcomposerMaterial: TripleFBOComposerMaterial;
	/** Custom shader to compose two input FBOs in a variety of ways */
	#composeMaterial: ComposeFramebuffersMaterial;
	/** Custom shader to mix an input FBO into the read FBO */
	#mixMaterial: MixFramebufferMaterial;
	/** Usual fullscreen quad fed as geometry for the rendering step */
	#fsQuad: FullScreenQuad;
	/** Camera used for rendering */
	#camera: SupportedCamera;
	/** List of subscenes that are candidate to be composed together */
	#scenes = new Array<SubScenePipeline>();
	/** List of enabled subscenes whose rendering results are to be composed together */
	#enabledScenes = new Array<SubScenePipeline>();
	/** The color used as background */
	#backgroundColor!: Color | Texture | null;

	/** Computes the two subscens to be composed together. They are the first two enabled subscenes. */
	#computeEnabledSubscenes(): void {
		this.#enabledScenes.length = 0;
		for (const s of this.#scenes) {
			if (s.enabled) {
				this.#enabledScenes.push(s);
				if (this.#enabledScenes.length === 3) break;
			}
		}
	}

	/**
	 * Constructs a new instance of ComposeFramebuffersPass pass.
	 *
	 * @param camera The camera used to render the FBOs.
	 * @param backgroundColor if a color is provided it will override the readBuffer provided from the previous pass
	 */
	constructor(camera: SupportedCamera, backgroundColor: Color | Texture | null) {
		super();
		const cameraMode = camera instanceof PerspectiveCamera ? CameraMode.Perspective : CameraMode.Orthographic;
		this.#tripleFBOcomposerMaterial = new TripleFBOComposerMaterial();
		this.#composeMaterial = new ComposeFramebuffersMaterial(cameraMode);
		this.#mixMaterial = new MixFramebufferMaterial(cameraMode);
		this.#tripleFBOcomposerMaterial.uniforms.uMinOpacity.value = MIN_OPACITY;
		this.#composeMaterial.uniforms.uMinOpacity.value = MIN_OPACITY;
		this.#mixMaterial.uniforms.uMinOpacity.value = MIN_OPACITY;
		this.#fsQuad = new FullScreenQuad(this.#composeMaterial);
		this.#camera = camera;
		this.backgroundColor = backgroundColor;
	}

	/**
	 * Change the camera used to render this pass
	 *
	 * @param c The new camera used to render this pass
	 */
	set camera(c: SupportedCamera) {
		const cameraMode = c instanceof PerspectiveCamera ? CameraMode.Perspective : CameraMode.Orthographic;
		this.#composeMaterial.cameraMode = cameraMode;
		this.#mixMaterial.cameraMode = cameraMode;
		this.#camera = c;
	}

	/**
	 * Change the pass background color
	 *
	 * @param backgroundColor The new background to use
	 */
	set backgroundColor(backgroundColor: Color | Texture | null) {
		if (backgroundColor instanceof Color) {
			this.#tripleFBOcomposerMaterial.uniforms.uOverrideBackground.value = true;
			this.#tripleFBOcomposerMaterial.uniforms.uBackground.value = new Vector4(
				backgroundColor.r,
				backgroundColor.g,
				backgroundColor.b,
				1,
			);
			this.#composeMaterial.uniforms.uOverrideBackground.value = true;
			this.#composeMaterial.uniforms.uBackground.value = new Vector4(
				backgroundColor.r,
				backgroundColor.g,
				backgroundColor.b,
				1,
			);
			this.#mixMaterial.uniforms.uOverrideBackground.value = true;
			this.#mixMaterial.uniforms.uBackground.value = new Vector4(
				backgroundColor.r,
				backgroundColor.g,
				backgroundColor.b,
				1,
			);
		} else if (backgroundColor instanceof Texture) {
			// Textures are only allowed so the type is compatible with `scene.background`
			throw new Error("ComposeFramebuffersMaterial: Texture backgrounds are not supported");
		} else {
			this.#tripleFBOcomposerMaterial.uniforms.uOverrideBackground.value = false;
			this.#composeMaterial.uniforms.uOverrideBackground.value = false;
			this.#mixMaterial.uniforms.uOverrideBackground.value = false;
		}
		this.#backgroundColor = backgroundColor;
	}

	/** @returns The pass background color */
	get backgroundColor(): Color | Texture | null {
		return this.#backgroundColor;
	}

	/**
	 * Adds a subscene to be composed inside this pass.
	 *
	 * @param subScene The subscene to be added
	 * @returns The argument subscene.
	 */
	addSubScene(subScene: SubScenePipeline): SubScenePipeline {
		this.#scenes.push(subScene);
		return subScene;
	}

	/**
	 * Remove a sub-scene from this pass
	 *
	 * @param subScene The sub scene to remove
	 */
	removeSubScene(subScene: SubScenePipeline): void {
		const idx = this.#scenes.indexOf(subScene);
		if (idx >= 0) this.#scenes.splice(idx, 1);
	}

	/**
	 * Dispose all internal resources for this object
	 */
	dispose(): void {
		this.#mixMaterial.dispose();
		this.#composeMaterial.dispose();
		this.#tripleFBOcomposerMaterial.dispose();
		this.#fsQuad.dispose();
	}

	/** @inheritdoc */
	render(
		renderer: WebGLRenderer,
		writeBuffer: WebGLRenderTarget,
		readBuffer: WebGLRenderTarget,
		deltaTime: number,
	): void {
		this.#computeEnabledSubscenes();
		if (this.#enabledScenes.length === 0) {
			console.warn("Lotv.ComposeFramebuffersPass expects at least one enabled subscene, zero were found.");
			return;
		}

		switch (this.#enabledScenes.length) {
			case 1:
				if (this.opacity1 > MIN_OPACITY) this.#enabledScenes[0].renderToFBO(renderer, deltaTime);
				this.#mixFbo(renderer, this.#enabledScenes[0].offscreenFbo, writeBuffer, readBuffer);
				break;
			case 2:
				if (this.opacity1 > MIN_OPACITY) this.#enabledScenes[0].renderToFBO(renderer, deltaTime);
				if (this.opacity2 > MIN_OPACITY) this.#enabledScenes[1].renderToFBO(renderer, deltaTime);
				this.#compose(
					renderer,
					this.#enabledScenes[0].offscreenFbo,
					this.#enabledScenes[1].offscreenFbo,
					writeBuffer,
					readBuffer,
				);
				break;
			case 3:
				if (this.opacity1 > MIN_OPACITY) this.#enabledScenes[0].renderToFBO(renderer, deltaTime);
				if (this.opacity2 > MIN_OPACITY) this.#enabledScenes[1].renderToFBO(renderer, deltaTime);
				if (this.opacity3 > MIN_OPACITY) this.#enabledScenes[2].renderToFBO(renderer, deltaTime);
				this.#tripleCompose(
					renderer,
					this.#enabledScenes[0].offscreenFbo,
					this.#enabledScenes[1].offscreenFbo,
					this.#enabledScenes[2].offscreenFbo,
					writeBuffer,
					readBuffer,
				);
				break;
		}
	}

	/**
	 *
	 * @param renderer The renderer
	 * @param fbo The fbo to mix on the pre-existing scene
	 * @param writeBuffer The output fbo
	 * @param readBuffer The fbo with the pre-existing scene
	 */
	#mixFbo(
		renderer: WebGLRenderer,
		fbo: WebGLRenderTarget,
		writeBuffer: WebGLRenderTarget,
		readBuffer: WebGLRenderTarget,
	): void {
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		this.#mixMaterial.uniforms.uColorTex0.value = readBuffer.texture;
		this.#mixMaterial.uniforms.uDepthTex0.value = readBuffer.depthTexture;
		this.#mixMaterial.uniforms.uColorTex1.value = fbo.texture;
		this.#mixMaterial.uniforms.uDepthTex1.value = fbo.depthTexture;
		this.#mixMaterial.uniforms.uNearPlane.value = this.#camera.near;
		this.#mixMaterial.uniforms.uFarPlane.value = this.#camera.far;
		this.#mixMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.material = this.#mixMaterial;

		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		renderer.clear();
		this.#fsQuad.render(renderer);

		renderer.autoClear = oldAutoClear;
	}

	/**
	 * Composes together two FBOs rendering the result to the writeBuffer.
	 *
	 * @param renderer renderer used to render the effect
	 * @param fbo1 First input FBO to compose
	 * @param fbo2 Second input FBO to compose
	 * @param writeBuffer The output FBO
	 * @param readBuffer The input FBO
	 */
	#compose(
		renderer: WebGLRenderer,
		fbo1: WebGLRenderTarget,
		fbo2: WebGLRenderTarget,
		writeBuffer: WebGLRenderTarget,
		readBuffer: WebGLRenderTarget,
	): void {
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		this.#composeMaterial.uniforms.uColorTex0.value = readBuffer.texture;
		this.#composeMaterial.uniforms.uDepthTex0.value = readBuffer.depthTexture;
		this.#composeMaterial.uniforms.uColorTex1.value = fbo1.texture;
		this.#composeMaterial.uniforms.uDepthTex1.value = fbo1.depthTexture;
		this.#composeMaterial.uniforms.uColorTex2.value = fbo2.texture;
		this.#composeMaterial.uniforms.uDepthTex2.value = fbo2.depthTexture;
		this.#composeMaterial.uniforms.uNearPlane.value = this.#camera.near;
		this.#composeMaterial.uniforms.uFarPlane.value = this.#camera.far;
		this.#composeMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.material = this.#composeMaterial;

		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		renderer.clear();
		this.#fsQuad.render(renderer);

		renderer.autoClear = oldAutoClear;
	}

	/**
	 * Composes together three FBOs rendering the result to the writeBuffer.
	 *
	 * @param renderer renderer used to render the effect
	 * @param fbo1 First input FBO to compose
	 * @param fbo2 Second input FBO to compose
	 * @param fbo3 Third input FBO to compuse
	 * @param writeBuffer The output FBO
	 * @param readBuffer The input FBO
	 */
	#tripleCompose(
		renderer: WebGLRenderer,
		fbo1: WebGLRenderTarget,
		fbo2: WebGLRenderTarget,
		fbo3: WebGLRenderTarget,
		writeBuffer: WebGLRenderTarget,
		readBuffer: WebGLRenderTarget,
	): void {
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		const mat = this.#tripleFBOcomposerMaterial;

		mat.uniforms.uColorTex0.value = readBuffer.texture;
		mat.uniforms.uDepthTex0.value = readBuffer.depthTexture;
		mat.uniforms.uColorTex1.value = fbo1.texture;
		mat.uniforms.uDepthTex1.value = fbo1.depthTexture;
		mat.uniforms.uColorTex2.value = fbo2.texture;
		mat.uniforms.uDepthTex2.value = fbo2.depthTexture;
		mat.uniforms.uColorTex3.value = fbo3.texture;
		mat.uniforms.uDepthTex3.value = fbo3.depthTexture;
		mat.uniformsNeedUpdate = true;
		this.#fsQuad.material = mat;

		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		renderer.clear();
		this.#fsQuad.render(renderer);

		renderer.autoClear = oldAutoClear;
	}

	/**
	 * Updates the buffer sizes for this pass and all sub scenes
	 *
	 * @param width width of the canvas
	 * @param height height of the canvas
	 */
	override setSize(width: number, height: number): void {
		super.setSize(width, height);
		for (const scene of this.#scenes) {
			scene.setSize(width, height, scene.dpr);
		}
	}

	/** @returns the depth offset used to discern the two input FBOs, in meters. */
	get scene2DepthOffset(): number {
		return this.#composeMaterial.uniforms.uDepthOffset.value;
	}

	/** Sets the depth offset used to discern the two input FBOs, in meters. */
	set scene2DepthOffset(value: number) {
		this.#composeMaterial.uniforms.uDepthOffset.value = value;
	}

	/** @returns whether the pass combines the two input FBOs using false colors */
	get falseColors(): boolean {
		return this.#composeMaterial.uniforms.uFalseColors.value;
	}

	/** Sets whether the pass combines the two input FBOs using false colors */
	set falseColors(value: boolean) {
		this.#composeMaterial.uniforms.uFalseColors.value = value;
	}

	/** @returns whether the pass renders the thermographic comparison of the two input FBOs. */
	get thermoCompare(): boolean {
		return this.#composeMaterial.uniforms.uThermoCompare.value;
	}

	/** Sets whether the pass renders the thermographic comparison of the two input FBOs. */
	set thermoCompare(t: boolean) {
		this.#composeMaterial.uniforms.uThermoCompare.value = t;
	}

	/** @returns max distance valid for thermographic distance comparison. */
	get thermoThreshold(): number {
		return this.#composeMaterial.uniforms.uThermoThreshold.value;
	}

	/** Sets the max distance valid for thermographic distance comparison. */
	set thermoThreshold(t: number) {
		if (t > 0) this.#composeMaterial.uniforms.uThermoThreshold.value = t;
	}

	/** @returns opacity added to FBO 1 */
	get opacity1(): number {
		return this.#composeMaterial.uniforms.opacity1.value;
	}

	/** Sets the opacity added to FBO 1 */
	set opacity1(o: number) {
		this.#tripleFBOcomposerMaterial.uniforms.opacity1.value = o;
		this.#composeMaterial.uniforms.opacity1.value = o;
		this.#mixMaterial.uniforms.opacity1.value = o;
	}

	/** @returns opacity added to FBO 2 */
	get opacity2(): number {
		return this.#composeMaterial.uniforms.opacity2.value;
	}

	/** Sets the opacity added to FBO 2 */
	set opacity2(o: number) {
		this.#tripleFBOcomposerMaterial.uniforms.opacity2.value = o;
		this.#composeMaterial.uniforms.opacity2.value = o;
	}

	/** @returns the opacity added to FBO 3 */
	get opacity3(): number {
		return this.#tripleFBOcomposerMaterial.uniforms.opacity3.value;
	}

	/** Sets the opacity added to FBO 3 */
	set opacity3(o: number) {
		this.#tripleFBOcomposerMaterial.uniforms.opacity3.value = o;
	}
}
