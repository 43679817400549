import { CubeMapPano } from "@faro-lotv/lotv";
import { IElementImgCube } from "@faro-lotv/ielement-types";
import { useCubeTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { IElementRendererProps } from "../../common/i-element-renderer-types";
import { useEffect, useMemo, useRef } from "react";
import { BoxGeometry, DoubleSide, Matrix4, TextureLoader } from "three";

// An empty matrix4 used in CubeMapPano
const NewMatrix4 = new Matrix4();

/**
 * Try to parse a json string that should contain an array of string
 *
 * @param data the source json string
 * @returns the array or string or undefined if the string is missing or invalid
 */
function tryParseJsonArrayString(
  data: string | undefined,
): string[] | undefined {
  if (!data) {
    return;
  }
  const maybeArray = JSON.parse(data);
  if (
    Array.isArray(maybeArray) &&
    maybeArray.every((val) => typeof val === "string")
  ) {
    return maybeArray;
  }
}

/**
 * @returns the cube map sides from the provided iElement
 *    memoizing the value to avoid making this computation on every render
 * @param iElement ImgCube to convert
 */
function useIElementAsCubeSides(iElement: IElementImgCube): string[] {
  return useMemo(
    () => tryParseJsonArrayString(iElement.json6x1High) ?? [],
    [iElement],
  );
}

// BoxGeometry that is reused in all the CubeMaps placeholders.
const boxGeometry = new BoxGeometry(1, 1, 1);

/**
 * @returns The placeholder cube to be shown when viewed from outside
 */
export function ImgCubePlaceholder({
  iElement,
}: IElementRendererProps<IElementImgCube>): JSX.Element {
  const cubeSides = useIElementAsCubeSides(iElement);

  // Load all the textures of the cube sides.
  const textures = useLoader(TextureLoader, cubeSides);

  return (
    <mesh geometry={boxGeometry}>
      {textures.map((texture, index) => (
        <meshBasicMaterial
          key={index}
          map={texture}
          attach={`material-${index}`}
          side={DoubleSide}
        />
      ))}
    </mesh>
  );
}

interface IProps extends IElementRendererProps<IElementImgCube> {
  /** Once cubeMapPano is loaded, pass the reference to it to the parent */
  onLoaded?(cubeMapPano: CubeMapPano): void;
}

/**
 * @returns The cube which is rendered in the panorama mode
 * Also it uses the LotV class - CubeMapPano
 */
export function ImgCube({ iElement, onLoaded }: IProps): JSX.Element {
  const cubeSides = useIElementAsCubeSides(iElement);

  const cubeTextures = useCubeTexture(cubeSides, { path: "" });

  const panoRef = useRef<CubeMapPano | null>(null);

  // Executes the onPanoramaLoaded event, waiting for useCubeTexture's suspense.
  useEffect(
    () => {
      if (!panoRef.current) {
        throw new Error("panoRef is not assigned");
      }

      onLoaded?.(panoRef.current);
    },
    // should only execute for first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <cubeMapPano ref={panoRef} args={[cubeTextures, NewMatrix4]} />;
}
