import { assert, validateArrayOf } from "@faro-lotv/foundation";
import {
  IElementImgSheetTiled,
  ImgSheetLevelsOfDetail,
  validateImgSheetLevelsOfDetail,
} from "@faro-lotv/ielement-types";
import { FloorPlanTree, ImageTree, LodFloorPlan } from "@faro-lotv/lotv";
import { LinearFilter } from "three";

/** Pick only the data we need from the IElement descriptor */
export type FloorPlanIElementData = Pick<
  IElementImgSheetTiled,
  "levelsOfDetail" | "uri" | "pixelHeight" | "pixelWidth"
>;

/**
 * @returns the levels of details for a tiled image sheet
 * @param iElement to load the lod for
 * @throws if the lod is missing or invalid
 */
export async function loadTiledSheetLod(
  iElement: FloorPlanIElementData,
): Promise<ImgSheetLevelsOfDetail[]> {
  // TODO: Remove levelsOfDetail property (https://faro01.atlassian.net/browse/SWEB-4590)
  let lod = iElement.levelsOfDetail;

  if (iElement.uri) {
    const payload = await fetch(iElement.uri);
    assert(payload.ok, "Unable to load ImgSheetTiled lod payload");
    const payloadJson = await payload.json();
    validateArrayOf({
      object: iElement,
      prop: "levelsOfDetail",
      elementGuard: validateImgSheetLevelsOfDetail,
    });
    lod = payloadJson.levelsOfDetail;
  }

  assert(lod, "Unable to load ImgSheetTiled levels of details");
  return lod;
}

/**
 * @returns the LodFloorPlan object to render an ImgSheetTiled node
 * @param iElement to load
 */
export async function loadLodFloorPlan(
  iElement: FloorPlanIElementData,
): Promise<LodFloorPlan> {
  const lod = await loadTiledSheetLod(iElement);

  // This texture is used in the placeholder sphere
  const texture = await ImageTree.computeOverviewTexture(
    iElement.pixelWidth,
    iElement.pixelHeight,
    lod[0].dimX,
    lod[0].dimY,
    lod[0].sources,
  );

  const tree = new FloorPlanTree(lod);

  // The handling of resolution of tiles is done by LotV class
  // No need for threejs to do the mipmap generation
  texture.generateMipmaps = false;
  texture.minFilter = LinearFilter;
  texture.needsUpdate = true;

  return new LodFloorPlan(tree, { overviewTexture: texture });
}
