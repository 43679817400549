import { GLSL3, Matrix4, RawShaderMaterial, Texture } from "three";
import frag from "../Shaders/Ssao.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { makeUniform } from "./Uniforms";

const SSAO_DEFAULT_BIAS = 0.025;

/**
 * A ThreeJS material to compute in screen space the Ambient Occlusion factor from a depth texture.
 *
 * This shader is suited to be rendered on a fullscreen quad. It takes as input high-precision depth data
 * of the scene from a floating-point depth texture, and from the depth it reconstructs the normals and it
 * computes the ambient occlusion factor per pixel, in the range 0.0 (max occlusion) to 1.0 (no occlusion).
 * The occlusion factor is saved in a one-channel output texture in floating point format.
 */
export class SsaoMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uDepthTex: makeUniform<Texture | null>(null),
		uStrengthFactor: makeUniform<number>(1),
		uSubsampleSamples: makeUniform<number>(1),
		uProjectionMatrix: makeUniform(new Matrix4()),
		uProjectionMatrixInverse: makeUniform(new Matrix4()),
		uRadius: makeUniform<number>(0.5),
		uBias: makeUniform<number>(SSAO_DEFAULT_BIAS),
	};

	/**
	 * Default constructor
	 */
	constructor() {
		super({ glslVersion: GLSL3 });
	}

	/**
	 * @returns the Ssao hemispheric kernel radius
	 */
	get radius(): number {
		return this.uniforms.uRadius.value;
	}

	/**
	 * Sets the Ssao hemispheric kernel radius
	 */
	set radius(r: number) {
		if (r >= 0.01 && r <= 10) {
			this.uniforms.uRadius.value = r;
			this.uniformsNeedUpdate = true;
		}
	}

	/**
	 * @returns The value of depth bias used in the Ssao shader to avoid noise.
	 */
	get depthBias(): number {
		return this.uniforms.uBias.value;
	}

	/**
	 * @param b The value of depth bias to set to the shader.
	 */
	set depthBias(b: number) {
		if (b >= 0 && b < 10) {
			this.uniforms.uBias.value = b;
			this.uniformsNeedUpdate = true;
		}
	}

	/**
	 * @param factor Sets the strength factor for the the shader.
	 */
	set strengthFactor(factor: number) {
		if (factor > 0) {
			this.uniforms.uStrengthFactor.value = factor;
			this.uniformsNeedUpdate = true;
		}
	}

	/**
	 * @returns Gets the strength factor for the the shader.
	 */
	get strengthFactor(): number {
		return this.uniforms.uStrengthFactor.value;
	}

	/**
	 * Sets the subsampling factor of used samples. The higher the factor the
	 * better performance, the worse visual quality.
	 *
	 * @param subsampling subsampling factor(1,2,3...)
	 */
	set subsamplingFactor(subsampling: number) {
		if (subsampling >= 1) {
			this.uniforms.uSubsampleSamples.value = Math.round(subsampling);
			this.uniformsNeedUpdate = true;
		}
	}
}
