import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementAttachment,
  IElementGroup,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddAttachment extends BaseMutation {
  /** The Group(Attachments) to create if the parent element is missing it */
  group?: CreateIElement<IElementGroup>;

  /** New Attachment */
  newElement: CreateIElement<IElementAttachment>;
}

export interface CreateMutationAddAttachment {
  /** Id to assign to the new Attachment element */
  newAttachmentId: GUID;

  /** ID of the Attachment parent, Markup(Markup) */
  markupId: GUID;

  /** ID of the Group(Attachments) that will contain the attachment if it exists, it will created if missing */
  groupId?: GUID;

  /** Name of the Attachment being created */
  name: string;

  /** The Uniform Resource Identifier pointing to the attachment file */
  uri: string;

  /** The unique hash of the uploaded attachment */
  md5Hash: string;

  /** Size of the attachment, in bytes. */
  fileSize?: number | null;

  /** The file name of the attachment */
  fileName: string;

  /** ID of the root element */
  rootId: GUID;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;

  /** The uri to the attachment thumbnail, if exists*/
  thumbnailUri?: string;
}

/**
 * Adds a new Attachment instance to an existing Markup node.
 *
 *```
 * Markup (Markup)
 * └ Group (Attachments)
 *   └ Attachment (null)
 *
 * ```
 *
 * @returns A MutationAddAttachment object
 */
export function createMutationAddAttachment({
  newAttachmentId,
  markupId,
  groupId: maybeGroupId,
  rootId,
  name,
  uri,
  md5Hash,
  fileSize,
  fileName,
  thumbnailUri,
  createdAt = new Date().toISOString(),
}: CreateMutationAddAttachment): MutationAddAttachment {
  const elementId = maybeGroupId ?? markupId;
  const groupId = maybeGroupId ?? generateGUID();

  const group: CreateIElement<IElementGroup> | undefined = maybeGroupId
    ? undefined
    : {
        type: IElementType.group,
        typeHint: IElementTypeHint.attachments,
        parentId: markupId,
        id: groupId,
        rootId,
        name: "Attachments",
        childrenIds: [newAttachmentId],
        xOr: false,
        createdAt,
      };

  const newElement: CreateIElement<IElementAttachment> = {
    type: IElementType.attachment,
    id: newAttachmentId,
    rootId,
    parentId: groupId,
    childrenIds: [],
    name,
    uri,
    createdAt,
    md5Hash,
    fileSize,
    fileName,
    thumbnailUri,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddAttachment, elementId),
    newElement,
    group,
  };
}
