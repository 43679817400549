import { Chip, ChipOwnProps, ChipProps, SxProps } from "@mui/material";
import { CSSProperties, ReactElement, ReactNode } from "react";
import { FontWeights } from "../faro-theme";
import { CloseCancelErrorIcon } from "../icons";
import { FaroTooltip } from "../tooltip/tooltip";

export type FaroChipProps = {
  /** Text to display inside the chip or custom ReactNode */
  label: ReactNode;

  /** Label color */
  color?: CSSProperties["color"];

  /** Chip background color */
  backgroundColor: CSSProperties["color"];

  /** Chip border color. If not passed, color prop will be used */
  borderColor?: CSSProperties["color"];

  /** Tooltip text or custom ReactNode. If not passed it won't show a tooltip */
  tooltip?: ReactNode;

  /**
   * The chip variant to use.
   *
   * @default "filled"
   */
  variant?: ChipOwnProps["variant"];

  /** Optional icon to override the default circle icon of the chip */
  icon?: ReactElement;

  /**
   * Optional text transform to override the default uppercase
   *
   * @default "uppercase"
   */
  textTransform?: CSSProperties["textTransform"];

  /** Optional sx properties to override the chip style. */
  sx?: SxProps;

  /**
   * The size variant to use.
   *
   * @default "small"
   */
  size?: ChipOwnProps["size"];
} & Pick<ChipProps, "onClick" | "onDelete" | "className">;

/**
 * Renders a tag/chip with a tooltip
 *
 * @returns JSX.Element - A tag/chip
 */
export function FaroChip({
  label,
  color,
  borderColor,
  backgroundColor,
  tooltip,
  variant = "filled",
  size = "small",
  icon,
  textTransform = "uppercase",
  sx,
  className,
  onClick,
  onDelete,
}: FaroChipProps): JSX.Element {
  /* Chip uses ButtonBase underneath only when callbacks are defined, but does not expose the properties (i.e. disableRipple) */
  const disableRipple = onClick ?? onDelete ? { disableRipple: true } : {};
  return (
    <FaroTooltip title={tooltip} enterDelay={1000}>
      <Chip
        data-testid="sphere-status-chip"
        label={label}
        icon={icon}
        variant={variant}
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={
          <CloseCancelErrorIcon sx={{ width: "16px", height: "16px" }} />
        }
        sx={[
          {
            backgroundColor,
            color,
            borderColor: `${borderColor ?? color}`,
            fontSize: "0.625rem",
            fontWeight: FontWeights.Bold,
            lineHeight: "0.875rem",
            letterSpacing: "0.5px",
            textTransform,
            "& .MuiChip-icon": {
              marginLeft: 1,
              marginRight: 0,
              color,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        size={size}
        {...disableRipple}
        className={className}
      />
    </FaroTooltip>
  );
}
