import { GUID, ILabel } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Adds a label to an element in the project.
 */
export interface MutationAddLabel extends BaseMutation {
  type: MutationTypes.MutationAddLabel;

  /** The id of the element to update */
  elementId: GUID;

  /** The id of the label to add to the element. */
  labelId: ILabel["id"];
}

/**
 * @returns a mutation to add a label to an element in the project
 * @param elementId The id of the element to update
 * @param labelId The id of the label to add
 */
export function createMutationAddLabel(
  elementId: MutationAddLabel["elementId"],
  labelId: MutationAddLabel["labelId"],
): MutationAddLabel {
  return {
    ...createBaseMutation(MutationTypes.MutationAddLabel, elementId),
    labelId,
  };
}
