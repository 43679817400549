import { GLSL3, RawShaderMaterial, Texture, Vector2 } from "three";
import frag from "../Shaders/MinifyMaterial.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { CameraMode } from "../Utils/CameraUtils";
import { makeUniform } from "./Uniforms";

/**
 * This is a simple shader that produces a downsampled version of the input FBO, downsizing it at inputSize / 2.
 * Input and output FBOs comprise both color and depth texture. For every 2x2 square in the input FBO, the corresponding
 * output pixel is filled. The output depth is the depth closest to the camera among the input depths, and the output color is the color
 * corresponding to the closest depth.
 *
 * In the alpha channel of the output color pixel, a four-bits integer is written. Given the output depth, for each of the
 * four input pixels that is close enough to the output depth, the corresponding bit is raised. This information is used
 * later to determine whetehr the pixel represents an horizontal depth discontinuity, a vertical one, and so on.
 *
 * This shader is also part of the pipeline used to perform 'mipmap' gap filling, downsampled versions of the input FBO are
 * used for fast lookup of pixels in the final gapfilling shader.
 */
export class MinifyMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;

	override uniforms = {
		inColorTex: makeUniform<Texture | null>(null),
		inDepthTex: makeUniform<Texture | null>(null),
		texSizeIn: makeUniform(new Vector2()),
		texSizeOut: makeUniform(new Vector2()),
		// near and far plane of the camera that is rendering the scene.
		nearPlane: makeUniform<number>(0),
		farPlane: makeUniform<number>(0),
	};

	/**
	 *
	 * @param cm Specifies to construct the perspective or the ortho version.
	 */
	constructor(cm: CameraMode) {
		super({ glslVersion: GLSL3 });
		if (cm === CameraMode.Orthographic) {
			this.fragmentShader = `#define ORTHO_PROJECTION\n${frag}`;
		}
	}
}
