import { Paper, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

type HelpBannerProps = {
  /** The text message to display in the help banner. */
  children: ReactNode;

  /** Custom style for the entire HelpBanner */
  sx?: SxProps<Theme>;
};

/**
 * @returns A help banner displaying an informational message to the user.
 */
export function HelpBanner({ children, sx }: HelpBannerProps): JSX.Element {
  return (
    <Paper
      sx={{
        backgroundColor: "gray950",
        color: "whiteGray",
        textAlign: "center",
        borderWidth: "1px",
        borderStyle: "solid",
        opacity: 0.8,
        borderColor: "white10",
        pointerEvents: "none",
        p: 2,
        ...sx,
      }}
    >
      <Typography>{children}</Typography>
    </Paper>
  );
}
