precision highp float;

uniform vec3 uColor;

varying vec4 mPosition;
#if HAS_MESH_TEXTURE
varying vec2 oUV;
#else
varying vec3 mColor;
#endif

void main()
{
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
	gl_Position = projectionMatrix * mvPosition;

	mPosition = modelMatrix * vec4(position, 1.0);
#if HAS_MESH_TEXTURE
	oUV = uv;
#elif defined(USE_COLOR)
	mColor = color;
#else
	mColor = uColor;
#endif
}
