import {
	Color,
	ColorRepresentation,
	PointsMaterial,
	PointsMaterialParameters,
	Shader,
	ShaderLib,
	UniformsLib,
	UniformsUtils,
	WebGLRenderer,
} from "three";

import frag from "../Shaders/LitPoints.frag";
import vert from "../Shaders/LitPoints.vert";
import { makeUniform } from "./Uniforms";

const DEFAULT_SPECULAR_COLOR = 0x111111;

/**
 * A LitPointsMaterialParameters {@extends PointsMaterialParameters}
 */
export interface LitPointsMaterialParameters extends PointsMaterialParameters {
	/**
	 * The emissive color for the points (what color light they appear to glow)
	 *
	 * @defaultValue 0X000000 (black/none)
	 */
	emissive?: ColorRepresentation | undefined;
	/**
	 * Multiplier to the emissive color
	 *
	 * @defaultValue 1
	 */
	emissiveIntensity?: number | undefined;
	/**
	 * The color of the specular reflection
	 *
	 * @defaultValue 0x111111
	 */
	specular?: ColorRepresentation | undefined;

	/**
	 * Controls how sharp the specular reflection is
	 *
	 * @defaultValue 30
	 */
	shininess?: number | undefined;
	/**
	 * The minimum size that points may become, only applies if sizeAttenuation is true
	 *
	 * @defaultValue 0
	 */
	minSize?: number | undefined;
	/**
	 * The maximum size that points may become, only applies if sizeAttenuation is true
	 *
	 * @defaultValue 1000
	 */
	maxSize?: number | undefined;
	/**
	 * A value used to scale the points up and down after the minSize/MaxSize clamping has been applied.
	 *
	 * @defaultValue 1
	 */
	sizeMultiplier?: number | undefined;

	/**
	 * Is this point cloud affected by lights
	 *
	 * @defaultValue true
	 */
	lights?: boolean | undefined;
}

/**
 * A material for rendering point clouds with lighting.  {@extends PointsMaterial}
 * With the added ability to clamp the min and max pixel size of the points when @see sizeAttenuation is True
 */
export class LitPointsMaterial extends PointsMaterial {
	vertexShader: string;
	fragmentShader: string;

	isPointsMaterial = true;
	isShaderMaterial = true;
	uniformsNeedUpdate = false;

	uniforms = ShaderLib.points.uniforms;
	uniformsGroups = [];

	#minSize = 0;
	#maxSize = 1000;
	#sizeMultiplier = 1;
	#emissive = new Color(0x000000);
	#emissiveIntensity = 1;
	#specular = new Color(DEFAULT_SPECULAR_COLOR);
	#shininess = 30;
	#lights: boolean;

	// Disabling required docs for the all the redundant "Sets The ..." comments
	/* eslint-disable jsdoc/require-jsdoc */
	public get minSize(): number {
		return this.#minSize;
	}
	public set minSize(value: number) {
		if (value !== this.#minSize) {
			this.#minSize = value;
			this.uniforms.minSize.value = value;
		}
	}
	public get maxSize(): number {
		return this.#maxSize;
	}
	public set maxSize(value: number) {
		if (value !== this.#maxSize) {
			this.#maxSize = value;
			this.uniforms.maxSize.value = value;
		}
	}
	public get sizeMultiplier(): number {
		return this.#sizeMultiplier;
	}
	public set sizeMultiplier(value: number) {
		if (value !== this.#sizeMultiplier) {
			this.#sizeMultiplier = value;
			this.uniforms.sizeMultiplier.value = value;
		}
	}
	public get emissive(): Color {
		return this.#emissive;
	}
	public set emissive(value: Color) {
		if (value !== this.#emissive) {
			this.#emissive = value;
			this.uniforms.emissive.value = value;
		}
	}
	public get emissiveIntensity(): number {
		return this.#emissiveIntensity;
	}
	public set emissiveIntensity(value: number) {
		if (value !== this.#emissiveIntensity) {
			this.#emissiveIntensity = value;
			this.uniforms.emissiveIntensity.value = value;
		}
	}
	public get specular(): Color {
		return this.#specular;
	}
	public set specular(value: Color) {
		if (value !== this.#specular) {
			this.#specular = value;
			this.uniforms.specular.value = value;
		}
	}
	public get shininess(): number {
		return this.#shininess;
	}
	public set shininess(value: number) {
		if (value !== this.#shininess) {
			this.#shininess = value;
			this.uniforms.shininess.value = value;
		}
	}
	public get lights(): boolean {
		return this.#lights;
	}
	public set lights(value: boolean) {
		if (value !== this.#lights) {
			this.#lights = value;
			this.uniforms.lights.value = value;
			this.needsUpdate = true;
		}
	}

	// @internal
	public get debugColors(): boolean {
		return this.uniforms.debugColors.value;
	}
	// @internal
	public set debugColors(value: boolean) {
		this.uniforms.debugColors.value = value;
	}
	/* eslint-enable jsdoc/require-jsdoc */

	/**
	 * Construct an instance of LitPointsMaterial
	 *
	 * @param parameters An object with one or more properties defining the material's appearance.
	 */
	constructor(parameters: Partial<LitPointsMaterialParameters> = {}) {
		super();
		this.type = "PointsMaterial";
		this.vertexShader = vert;
		this.fragmentShader = frag;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		this.#lights = parameters?.lights ?? true;
		this.#setUniforms();
		this.setValues(parameters);
	}
	/**
	 * Override of onBeforeCompile, called internally
	 *
	 * @param shader the shader
	 * @param renderer the renderer
	 */
	override onBeforeCompile(shader: Shader, renderer: WebGLRenderer): void {
		super.onBeforeCompile(shader, renderer);
		this.#setUniforms(shader);
	}
	/**
	 * sets the uniforms of the material and of the passed in shader if any
	 *
	 * @param shader The shader that is about to be rendered and needs it's uniforms set
	 */
	#setUniforms(shader?: Shader): void {
		this.uniforms = UniformsUtils.merge([
			ShaderLib.points.uniforms,
			this.lights ? UniformsLib.lights : {},
			{
				lights: makeUniform(this.lights),
				minSize: makeUniform(this.minSize),
				maxSize: makeUniform(this.maxSize),
				sizeMultiplier: makeUniform(this.sizeMultiplier),

				emissive: makeUniform(this.emissive),
				emissiveIntensity: makeUniform(this.emissiveIntensity),
				specular: makeUniform(this.specular),
				shininess: makeUniform(this.shininess),

				debugColors: makeUniform(false),
			},
		]);
		if (shader) {
			shader.uniforms = this.uniforms;
		}
	}
	/**
	 * Copies the parameters of a source material to this material
	 *
	 * @param source the source material to copy from
	 * @returns this
	 */
	override copy(source: LitPointsMaterial): this {
		super.copy(source);
		this.minSize = source.minSize;
		this.maxSize = source.maxSize;
		this.sizeMultiplier = source.sizeMultiplier;
		this.emissive.copy(source.emissive);
		this.emissiveIntensity = source.emissiveIntensity;
		this.specular.copy(source.specular);
		this.shininess = source.shininess;
		this.lights = source.lights;
		return this;
	}
}
