import { Color, Quaternion, Vector2, Vector3, Vector4Tuple } from "three";
import {
  Vector2 as Vector2Prop,
  Vector3 as Vector3Prop,
  Quaternion as QuaternionProp,
  Color as ColorProp,
} from "@react-three/fiber";

/**
 * Parse a Vector2Prop to a Vector2
 *
 * @param from The input prop
 * @returns The parsed Vector2
 */
export function parseVector2(from: Vector2Prop): Vector2 {
  if (from instanceof Vector2) {
    return from.clone();
  }
  if (typeof from === "number") {
    return new Vector2(from, from);
  }
  return new Vector2(from[0], from[1]);
}

/**
 * Parse a Vector3Prop to a Vector3
 *
 * @param from The input prop
 * @param to The destination Vector3
 * @returns The parsed Vector3
 */
export function parseVector3(from: Vector3Prop, to = new Vector3()): Vector3 {
  if (from instanceof Vector3) {
    to.copy(from);
  } else if (typeof from === "number") {
    to.set(from, from, from);
  } else {
    to.set(from[0], from[1], from[2]);
  }
  return to;
}

/**
 * Parse a QuaternionProp to a Quaternion
 *
 * @param from The input prop
 * @returns The parsed Quaternion
 */
export function parseQuaternion(from: QuaternionProp): Quaternion {
  if (from instanceof Quaternion) {
    return from.clone();
  }
  return new Quaternion(from[0], from[1], from[2], from[3]);
}

/**
 * Parse a ColorProp to a Color
 *
 * @param from The input prop
 * @returns The parsed Color
 */
export function parseColor(from: ColorProp): Color {
  if (from instanceof Color) {
    return from;
  }
  if (Array.isArray(from)) {
    return new Color(from[0], from[1], from[2]);
  }
  return new Color(from);
}

/**
 * Converts a quaternion to a Vector4Tuple with type safety.
 *
 * @param quaternion The quaternion to convert
 * @returns A Vector4Tuple with the values [x,y,z,w] of the quaternion.
 */
export function quaternionToVector4Tuple(quaternion: Quaternion): Vector4Tuple {
  return [quaternion.x, quaternion.y, quaternion.z, quaternion.w];
}
