uniform float rotation;
uniform vec2 center;
uniform float size;
uniform float depthToNormalizedScreenHeight;
uniform float zOffset;

#include <common>
#include <uv_pars_vertex>
#include <fog_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>


void main() {
	#include <uv_vertex>

	vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );

	vec2 scale;
	scale.x = length( vec3( modelMatrix[ 0 ].x, modelMatrix[ 0 ].y, modelMatrix[ 0 ].z ) );
	scale.y = length( vec3( modelMatrix[ 1 ].x, modelMatrix[ 1 ].y, modelMatrix[ 1 ].z ) );

	vec2 alignedPosition = ( position.xy - ( center - vec2( 0.5 ) ) ) * scale;

	float sizeInMeters = 0.0;

	// The 'isOrthographic' uniform is given for free by threejs' ShaderMaterial.
	// The if below should not be a performance problem because modern GPUs apply static 
	// branching when the if condition depends only on uniforms.
	if(isOrthographic) {
		float pix2m = depthToNormalizedScreenHeight * 5.0f;
		sizeInMeters = size * pix2m;
	}
	else {
		float pix2m = -mvPosition.z * depthToNormalizedScreenHeight * 5.0f;
		sizeInMeters = size * pix2m;
	}

	float currentDistance = length(alignedPosition);
	alignedPosition = alignedPosition * sizeInMeters;

	vec2 rotatedPosition;
	rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
	rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;

	mvPosition.xy += rotatedPosition;

	gl_Position = projectionMatrix * mvPosition;

	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	#include <fog_vertex>

	gl_Position.z += zOffset;
}
