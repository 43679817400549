import { TypedEvent } from "./typed-event";

/** Offset to expire the urls a bit sooner, to account for delays between the url being issued and the request being made */
const EXPIRATION_OFFSET_MS = 60 * 1000;

/** A sentinel date used for a never expiring url */
export const EXPIRATION_NEVER_DATE = new Date("2999-01-01T00:00:00.000Z");

export interface SignedUrlData {
  /** The updated signed url */
  signedUrl: string;

  /** The new expire date */
  expiresOn: Date;
}

/** A function to update the signature of a signed url */
export type UrlUpdater = (url: string) => Promise<SignedUrlData>;

/**
 * A wrapper for a signed url that can be updated if expired
 */
export class SignedUrl {
  urlUpdated = new TypedEvent<SignedUrlData>();

  /**
   * Construct a new SignedUrl
   *
   * @param url the base url to sign
   * @param refreshUrl a function to compute an updated signed url
   * @param data the initial signature if available
   */
  constructor(
    private url: string,
    private refreshUrl: UrlUpdater,
    private data?: SignedUrlData,
  ) {}

  /** @returns true if the signature is not too close to the expiration time */
  public get isValid(): boolean {
    return this.timeUntilExpiredMs > EXPIRATION_OFFSET_MS;
  }

  /** @returns how many milliseconds until this signature will expire */
  public get timeUntilExpiredMs(): number {
    if (!this.data) return 0;

    return new Date(this.data.expiresOn).getTime() - Date.now();
  }

  /** @returns the signed url if valid or undefined otherwise */
  public get signedUrl(): string | undefined {
    if (this.isValid && this.data) {
      return this.data.signedUrl;
    }
  }

  /** @returns an updated signed url, requesting a new one if needed */
  public async requestSignedUrl(): Promise<string> {
    if (!this.data || !this.isValid) {
      return (await this.updateUrl()).signedUrl;
    }

    return this.data.signedUrl;
  }

  /**
   * Request a new signed url and stores it in the cache
   *
   * @returns the new signed url data
   */
  private async updateUrl(): Promise<SignedUrlData> {
    this.data = await this.refreshUrl(this.url);
    this.urlUpdated.emit(this.data);
    return this.data;
  }
}

/**
 * A special signed url that never expires
 */
export class UnsignedUrl extends SignedUrl {
  /**
   * Construct a SignedUrl instance that never expires
   *
   * @param url to treat as forever signed
   */
  constructor(url: string) {
    super(
      url,
      () =>
        Promise.resolve({
          signedUrl: url,
          expiresOn: EXPIRATION_NEVER_DATE,
        }),
      {
        signedUrl: url,
        expiresOn: EXPIRATION_NEVER_DATE,
      },
    );
  }
}
