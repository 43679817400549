import { GUID } from "@faro-lotv/foundation";
import { UserId } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Change the assignee of an Element
 */
export interface MutationUserDirectoryMarkupField extends BaseMutation {
  type: MutationTypes.MutationUserDirectoryMarkupField;

  /**
   * The new list of user ids that will become the assignees of the element.
   * The ProjectAPI only allows one assignee for each Markup
   */
  ids: [UserId];
}

/**
 * @returns a mutation to change a Markup's assignee
 * @param elementId id of the UserDirectoryMarkupField element
 * @param ids new list of user ids that will become the assignees of the element
 */
export function createMutationUserDirectoryMarkupField(
  elementId: GUID,
  ids: [UserId],
): MutationUserDirectoryMarkupField {
  return {
    ...createBaseMutation(
      MutationTypes.MutationUserDirectoryMarkupField,
      elementId,
    ),
    ids,
  };
}
