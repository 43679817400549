uniform float uTransitionStep;
uniform samplerCube uCurTex;
uniform samplerCube uTarTex;

uniform mat4 uCurInvMatrix;
uniform mat4 uTarInvMatrix;

varying vec4 mPosition;

const float PI = 3.141592654;

#if HAS_MESH_TEXTURE
varying vec2 oUV;
uniform sampler2D uMeshTexture;
#else
varying vec3 mColor;
#endif

vec4 computeTexColor(mat4 invPose, vec4 pos, samplerCube tex)
{
    vec4 v = invPose * pos;
    vec4 texColor = textureCube(tex, normalize(v.xyz));
#if HAS_MESH_TEXTURE
    return texColor * texColor.a + (1.0 - texColor.a) * texture2D(uMeshTexture, oUV);
#else
    return texColor * texColor.a + (1.0 - texColor.a) * vec4(mColor, 1.0);
#endif
}

void main()
{
    // Color from source pano
    vec4 curColor = computeTexColor(uCurInvMatrix, mPosition, uCurTex);

    // Color from target pano
    vec4 tarColor = computeTexColor(uTarInvMatrix, mPosition, uTarTex);

	// Blend the two colors based on animation progress
    gl_FragColor = tarColor * uTransitionStep + curColor * (1.0 - uTransitionStep);
}
