import { Types } from "@amplitude/analytics-browser";
import { AmplitudeClient } from "./amplitude/amplitude-client";
import { EventProps, IInitParams, ISetPropsParams } from "./analytics-types";

/**
 * Module to provide analytics functionality
 *
 * Currently only provides a wrapper around AmplitudeClient
 * but might be extended to use different analytics services in the future
 */
class AnalyticsService {
  #client: AmplitudeClient | undefined = undefined;

  #defaultProps: EventProps = {};

  async init(apiKey: string, props: IInitParams = {}): Promise<void> {
    if (!this.#client) {
      this.#client = new AmplitudeClient();
    }

    try {
      await this.#client.init(apiKey, props).promise;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Amplitude client could not be initialized", { error });
    }
  }

  /**
   * Send an event to the analytics service
   *
   * @param eventName - Name of the the event being tracked
   * @param eventProps - Props of the event being tracked
   * @returns - A promise with the result of tracking event
   */
  track<Props extends EventProps = EventProps>(
    eventName: string,
    eventProps?: Props,
  ): Types.AmplitudeReturn<Types.Result> | undefined {
    if (this.#client) {
      return this.#client.trackEvent(eventName, {
        ...eventProps,
        ...this.#defaultProps,
      });
    }
  }

  /**
   * Enable/disable the analytics tracking of the user
   *
   * @param shouldOptOut True if the user wants to opt out of analytical tracking
   * @see https://developers.amplitude.com/docs/typescript-browser#tracking-switch
   */
  set optOutOfAnalytics(shouldOptOut: boolean) {
    if (this.#client) {
      this.#client.optOutOfAnalytics = shouldOptOut;
    }
  }

  /**
   * Set the props that will be attached to every tracking event
   */
  set props({
    defaultProps: newDefaultProps,
    userId,
    userProps,
  }: ISetPropsParams) {
    if (newDefaultProps) {
      this.#defaultProps = { ...newDefaultProps };
    }

    if (this.#client) {
      if (userId) {
        this.#client.userId = userId;
      }

      if (userProps) {
        for (const key in userProps) {
          this.#client.userProperty = {
            propertyName: key,
            propertyValue: userProps[key],
          };
        }
      }
    }
  }
}

export const Analytics = new AnalyticsService();
