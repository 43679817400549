import { BadgeProps, Badge as MuiBadge } from "@mui/material";
import { PropsWithChildren } from "react";
import { neutral, orange } from "../colors";

/**
 * @returns a correctly positioned badge as per the design guideline
 */
export function ChangeIndicatorBadge({
  children,
  variant,
  sx,
  ...rest
}: PropsWithChildren<BadgeProps>): JSX.Element {
  return (
    <MuiBadge
      variant={variant ?? "dot"}
      sx={{
        "& .MuiBadge-dot": {
          backgroundColor: orange[400],
          width: "6px",
          height: "6px",
          border: "none",
          outline: `1px solid ${neutral[0]}}`,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiBadge>
  );
}
