import { GLSL3, RawShaderMaterial, Texture, Vector4 } from "three";
import frag from "../Shaders/ComposeFramebuffers.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { CameraMode } from "../Utils/CameraUtils";
import { makeUniform } from "./Uniforms";

/**
 * A material to blend a framebuffer on top of a background fbo. Composition accounts for transparency of
 * both the input FBO and the background FBO. Moreover, the opacity of the input framebuffer can be
 * customized, hence the name "Mix framebuffer". This material is similar to the BlitMaterial, but
 * allows for correct transparency computation and for an extra opacity parameter.
 */
export class MixFramebufferMaterial extends RawShaderMaterial {
	#cameraMode: CameraMode = CameraMode.Perspective;

	override vertexShader = vert;
	override fragmentShader = `#define SINGLE_FBO\n${frag}`;

	override uniforms = {
		uColorTex0: makeUniform<Texture | null>(null),
		uDepthTex0: makeUniform<Texture | null>(null),
		uColorTex1: makeUniform<Texture | null>(null),
		uDepthTex1: makeUniform<Texture | null>(null),
		opacity1: makeUniform<number>(1.0),
		uNearPlane: makeUniform<number>(0.1),
		uFarPlane: makeUniform<number>(1000.0),
		uBackground: makeUniform(new Vector4(0, 0, 0, 0)),
		uOverrideBackground: makeUniform(false),
		uMinOpacity: makeUniform<number>(0),
	};

	/**
	 * Constructs a new instance of MixFramebufferMaterial.
	 *
	 * @param cm The camera mode to compile the material for
	 */
	constructor(cm: CameraMode) {
		super({ glslVersion: GLSL3 });
		this.cameraMode = cm;
	}

	/** @returns the camera mode the material is compiled for */
	get cameraMode(): CameraMode {
		return this.#cameraMode;
	}

	/** Sets the camera mode the material is compiled for */
	set cameraMode(cm: CameraMode) {
		if (cm !== this.#cameraMode) {
			this.#cameraMode = cm;
			const proj = cm === CameraMode.Orthographic ? "#define ORTHO_PROJECTION\n" : "";
			this.fragmentShader = `#define SINGLE_FBO\n${proj}${frag}`;
		}
	}
}
