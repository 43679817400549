import { assert } from "@faro-lotv/foundation";
import { Box3, Camera, Frustum, Matrix4, PerspectiveCamera, Vector2, Vector3 } from "three";
import { AbstractVisibleNodesStrategy, INFINITE_SIZE_ON_SCREEN } from "../Lod/AbstractVisibleNodesStrategy";
import { LodTree, LodTreeNode, NodeState } from "../Lod/LodTree";
import { OptimizationHint, VisibleNodesStrategy, WeightedNode } from "../Lod/VisibleNodeStrategy";
import { LeanBinaryHeap } from "../Utils/LeanBinaryHeap";
import { clamp, DEG_TO_RAD } from "../Utils/Math";
import { isSupportedCamera } from "../Utils/SupportedCamera";

const RADIUS_MULTIPLIER = Math.sqrt(3) / 2;

const DEFAULT_MIN_PROJECTED_SIZE = 25;

type ProjectionResult = {
	projectedSize: number;
	ndcDepth: number;
};

/** Function that takes the projection data of an object and compute its size in pixels on screen */
type ProjectionFunction = (distance: number, radius: number) => ProjectionResult;

/** Function that checks whether the given bounding box intersects one or more frustums */
type FrustumCheckFnc = (bbox: Box3) => boolean;

const LOGARITHMIC_DISTANCE_WEIGHT = 0.9;

const PROJECTED_SIZE_WEIGHT = 1.0 - LOGARITHMIC_DISTANCE_WEIGHT;

/**
 * A visible nodes strategy that reproduces what the Potree renderer does.
 *
 * WARNING: this strategy does not use the 'targetPixelsPerPoint' property. Instead,
 * set a convenient 'minNodeProjectedSize' property.
 */
export class PotreeVisibleNodesStrategy extends AbstractVisibleNodesStrategy implements VisibleNodesStrategy {
	// These private class members do not start with #hash, because at least in local environments #hash privates are slower
	/** The frustum of the current camera, used to check octree cells visibility */
	private cameraFrustum = new Frustum();
	/** Transform from point cloud local coordinates to camera local coordinates */
	private T = new Matrix4();
	/** Transform from point cloud local coordinates to camera clip coordinates */
	private PT = new Matrix4();
	/** Pre-allocated bounding box center in camera coordinates, to speed up computations */
	private bbCenter = new Vector3();
	/** Minimum node projected vertical size, in pixels. Nodes smaller at screen that this quantity are not visible. */
	private pMinNodeProjectedSize = DEFAULT_MIN_PROJECTED_SIZE;
	/** Coefficient used to convert a node screen size from meters to screen pixels */
	private meters2pix = 1.0;
	/** The current list of visible nodes, returned by this object as a result. It is sorted by weight. */
	private visibleNodes = new Array<WeightedNode>();
	/** Total number of points contained in the visible nodes */
	private numVisiblePoints = 0;
	/** List of nodes that are visible but not downloaded yet */
	private nodesNotInUse = new Array<number>();
	/** Secondary frustum to check octree nodes against, usually used when a clipping box is active on the point cloud */
	private auxFrustum = new Frustum();
	/** Function that determines the projected size and the NDC depth of an octree node */
	private projectionFnc: ProjectionFunction;
	/** Function used to check whether an octree node is inside the given frustum or frustums */
	private frustumChecker: FrustumCheckFnc;
	/** Priority queue used to visit the octree for visibility */
	private priorityQueue = new LeanBinaryHeap<WeightedNode>((x: WeightedNode) => -x.weight);
	/** Camera near plane */
	private near = 0.1;
	/** Camera far plane */
	private far = 1000;
	/** 1.0 / (camera far - camera near plane) */
	private invNearFarDiff = 1.0;
	/** Current node projected size and NDC depth*/
	private projectionResult: ProjectionResult = { projectedSize: INFINITE_SIZE_ON_SCREEN, ndcDepth: 0 };
	/** Whether a full recomputation instead of an incremental computation should be run */
	private needsFullRecomputation = false;

	constructor() {
		super();
		this.maxPointsInGpu = 5 * 1000 * 1000;

		this.projSizePerspective = this.projSizePerspective.bind(this);
		this.projSizeOrthographic = this.projSizeOrthographic.bind(this);
		this.projectionFnc = this.projSizePerspective;

		this.cameraFrustumCheck = this.cameraFrustumCheck.bind(this);
		this.cameraAndAuxFrustumCheck = this.cameraAndAuxFrustumCheck.bind(this);
		this.frustumChecker = this.cameraFrustumCheck;
	}

	/**
	 * Computes the projected size of the node, in screen pixels, assuming perspective projection
	 *
	 * @param distance node->camera distance
	 * @param radius node 3d radius
	 * @returns the projected size and the NDC depth of the node
	 */
	private projSizePerspective(distance: number, radius: number): ProjectionResult {
		const invDistance = 1.0 / distance;
		this.projectionResult.projectedSize = this.meters2pix * radius * invDistance;
		this.projectionResult.ndcDepth = (distance - this.near) * this.far * this.invNearFarDiff * invDistance;
		return this.projectionResult;
	}

	/**
	 * Computes the projected size of the node, in screen pixels, assuming orthographic projection
	 *
	 * @param distance node->camera distance
	 * @param radius node 3d radius
	 * @returns the projected size and the NDC depth of the node
	 */
	private projSizeOrthographic(distance: number, radius: number): ProjectionResult {
		this.projectionResult.projectedSize = this.meters2pix * radius;
		this.projectionResult.ndcDepth = (distance - this.near) * this.invNearFarDiff;
		return this.projectionResult;
	}

	/**
	 *
	 * @param bbox the bounding box to check
	 * @returns Whether the bounding box intersects the camera frustum
	 */
	private cameraFrustumCheck(bbox: Box3): boolean {
		return this.cameraFrustum.intersectsBox(bbox);
	}

	/**
	 *
	 * @param bbox the bounding box to check
	 * @returns Whether the bounding box intersects the camera frustum and the aux frustum
	 */
	private cameraAndAuxFrustumCheck(bbox: Box3): boolean {
		return this.cameraFrustum.intersectsBox(bbox) && this.auxFrustum.intersectsBox(bbox);
	}

	/**
	 * @param node the node
	 */
	private pushNodeToQueueIfVisible(node: LodTreeNode): void {
		const bbox = node.boundingBox;
		if (!this.frustumChecker(bbox)) {
			return;
		}

		bbox.getCenter(this.bbCenter);
		this.bbCenter.applyMatrix4(this.T);
		const distance = clamp(Math.abs(this.bbCenter.z), this.near, this.far);
		const radius = (bbox.max.x - bbox.min.x) * this.pCloudScale * RADIUS_MULTIPLIER;
		const { projectedSize, ndcDepth } = this.projectionFnc(distance, radius);
		if (projectedSize < this.pMinNodeProjectedSize) {
			return;
		}
		const weight = projectedSize * (PROJECTED_SIZE_WEIGHT + LOGARITHMIC_DISTANCE_WEIGHT * (1.0 - ndcDepth));
		this.priorityQueue.push({ id: node.id, weight });
	}

	/**
	 * Visits the octree starting from the elements in the priority queue.
	 * New children to visit are weighted and pushed to the priority queue.
	 * Visit ends when the priority queue is empty.
	 *
	 * @param tree The octree
	 */
	private visitQueue(tree: LodTree): void {
		while (this.priorityQueue.length > 0) {
			const element = this.priorityQueue.pop();
			const node = tree.getNode(element.id);

			if (this.numVisiblePoints + node.numPoints > this.pMaxPointsInGPU) {
				break;
			}

			this.numVisiblePoints += node.numPoints;
			this.visibleNodes.push(element);
			// Put in the viewer only nodes whose parent already has points
			if (node.state !== NodeState.InUse) {
				this.nodesNotInUse.push(node.id);
				continue;
			}
			for (const child of node.children) {
				this.pushNodeToQueueIfVisible(child);
			}
		}
	}

	/**
	 *
	 * @param tree tree
	 * @param cloudWorldMatrix cloud pose matrix
	 * @param camera current camera
	 * @param screenSize current screen size
	 * @param frustum optional aux frustum to give visibility only to nodes within a given clipping box
	 * @returns list of visible weighted node
	 */
	private recompute(
		tree: LodTree,
		cloudWorldMatrix: Matrix4,
		camera: Camera,
		screenSize: Vector2,
		frustum?: Frustum,
	): WeightedNode[] {
		assert(isSupportedCamera(camera), "PotreeVisibleNodesStrategy: unsupported camera type");
		this.near = camera.near;
		this.far = camera.far;
		this.invNearFarDiff = 1.0 / (camera.far - camera.near);

		// T transforms from the LOD cloud local coordinates to the camera local coordinates
		this.T.multiplyMatrices(camera.matrixWorldInverse, cloudWorldMatrix);

		this.PT.multiplyMatrices(camera.projectionMatrix, this.T);
		this.cameraFrustum.setFromProjectionMatrix(this.PT);

		this.extractCloudScale(cloudWorldMatrix);

		if (frustum) {
			this.auxFrustum.copy(frustum);
			this.frustumChecker = this.cameraAndAuxFrustumCheck;
		} else {
			this.frustumChecker = this.cameraFrustumCheck;
		}

		this.visibleNodes.length = 0;

		if (camera instanceof PerspectiveCamera) {
			this.meters2pix = (screenSize.y * 0.5) / Math.tan((camera.getEffectiveFOV() * DEG_TO_RAD) / 2);
			this.projectionFnc = this.projSizePerspective;
		} else {
			// If we enter this scope, typescript knows that 'camera' is an OrthographicCamera
			this.meters2pix = (screenSize.y * camera.zoom) / (camera.top - camera.bottom);
			this.projectionFnc = this.projSizeOrthographic;
		}
		this.numVisiblePoints = 0;

		/** The queue should be empty, but we clear it anyway just in case */
		this.priorityQueue.clear();

		this.priorityQueue.push({ id: 0, weight: Number.MAX_VALUE });

		this.nodesNotInUse.length = 0;

		this.visitQueue(tree);

		return this.visibleNodes;
	}

	/**
	 *
	 * @param tree tree
	 * @returns list of visible weighted node
	 */
	computeIncremental(tree: LodTree): WeightedNode[] {
		/** The queue should be empty, but we clear it anyway just in case */
		this.priorityQueue.clear();

		// Check all nodes that were waiting for download. If a node arrived,
		// put its children in the priority queue. If otherwise the node is still
		// waiting, push it in 'newNodesWaiting'
		const newNodesNotInUse = new Array<number>();
		let nodeArrived = false;
		for (const nodeToWait of this.nodesNotInUse) {
			const node = tree.getNode(nodeToWait);
			if (node.state === NodeState.InUse) {
				nodeArrived = true;
				for (const child of node.children) {
					this.pushNodeToQueueIfVisible(child);
				}
			} else {
				newNodesNotInUse.push(nodeToWait);
			}
		}

		if (nodeArrived) {
			// If a new node arrives, it is very frequent that the number of visible points
			// has changed. For example, the number of points inside a proxy node is only
			// known when the node is downloaded, not before. Therefore, whenever a new node
			// is downloaded, a full recomputation of the visible nodes is scheduled.
			let numVisiblePoints = 0;
			for (const n of this.visibleNodes) {
				numVisiblePoints += tree.getNode(n.id).numPoints;
			}
			this.needsFullRecomputation = numVisiblePoints !== this.numVisiblePoints;
			if (!this.needsFullRecomputation) {
				// Update the list of nodes waiting for download, more nodes will be added
				// inside 'visitQueue'
				this.nodesNotInUse = newNodesNotInUse;
				this.visitQueue(tree);
			}
		}

		return this.visibleNodes;
	}

	/** @inheritdoc */
	compute(
		tree: LodTree,
		cloudWorldMatrix: Matrix4,
		camera: Camera,
		screenSize: Vector2,
		frustum?: Frustum,
	): WeightedNode[] {
		switch (this.optimizationHint) {
			case OptimizationHint.cameraChanging:
				return this.recompute(tree, cloudWorldMatrix, camera, screenSize, frustum);
			case OptimizationHint.cameraSteady: {
				let ret = this.computeIncremental(tree);
				if (this.needsFullRecomputation) {
					ret = this.recompute(tree, cloudWorldMatrix, camera, screenSize, frustum);
					this.needsFullRecomputation = false;
				}
				return ret;
			}
			case OptimizationHint.cameraChangingOnce: {
				const ret = this.recompute(tree, cloudWorldMatrix, camera, screenSize, frustum);
				this.optimizationHint = OptimizationHint.cameraSteady;
				return ret;
			}
		}
	}

	/** @returns the minimum projected size in pixels a node must have to be visible */
	get minNodeProjectedSize(): number {
		return this.pMinNodeProjectedSize;
	}

	/** Sets the minimum projected size in pixels a node must have to be visible */
	set minNodeProjectedSize(m: number) {
		this.pMinNodeProjectedSize = m;
	}

	/** @inheritdoc */
	clone(): PotreeVisibleNodesStrategy {
		const clone = new PotreeVisibleNodesStrategy();
		clone.copy(this);
		clone.minNodeProjectedSize = this.minNodeProjectedSize;
		return clone;
	}
}
