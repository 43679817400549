import {
  Box,
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  Stack,
} from "@mui/material";
import { FaroText } from "../text/faro-text/faro-text";

interface CircularProgressProps extends MuiCircularProgressProps {
  /** Whether to show a percentage label in the center of the circular progress */
  showPercentage?: boolean;
}

/**
 * @returns A circular spinner in the FARO Theme
 * @param spinnerProps props of the underlying mui component
 * @param spinnerProps.showPercentage Whether to show a percentage label in the center of the circular progress
 * @param spinnerProps.size size of the spinner
 * @param spinnerProps.sx Styles applied to the root container component
 */
export function CircularProgress({
  sx,
  showPercentage,
  size = 40,
  ...spinnerProps
}: CircularProgressProps): JSX.Element {
  return (
    <Stack
      role="loading"
      sx={{
        position: "relative",
        width: size,
        height: size,
        ...sx,
      }}
    >
      {/* Static Spinner to render a white circular background */}
      <MuiCircularProgress
        {...spinnerProps}
        variant="determinate"
        value={100}
        size={size}
        sx={{
          color: "black",
          opacity: 0.15,
        }}
      />
      {/* The actual progress spinner*/}
      <MuiCircularProgress
        disableShrink={spinnerProps.variant === "indeterminate"}
        {...spinnerProps}
        size={size}
        sx={{
          color: "currentcolor",
          position: "absolute",
          left: 0,
        }}
      />
      {/* Percentage label */}
      {showPercentage && spinnerProps.value !== undefined && (
        <Box
          component="div"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <FaroText
            variant="bodyM"
            sx={{ color: "currentcolor" }}
          >{`${Math.round(spinnerProps.value)}%`}</FaroText>
        </Box>
      )}
    </Stack>
  );
}
