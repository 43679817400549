import { assert } from "@faro-lotv/foundation";
import { BufferGeometry, Float32BufferAttribute, Int32BufferAttribute, Uint32BufferAttribute, Vector3 } from "three";
import { PLACEHOLDER_LABEL_BIT_POS } from "../Materials/MapPlaceholdersMaterial";

/**
 * BitFlag used to signal the state of a placeholders to the shader
 *
 * Using a BitFlag because a placeholder can be both hovered and selected at the same time
 */
/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum PlaceholderStateFlags {
	/** The placeholder is in the normal state */
	NORMAL = 0,

	/** The placeholder is hovered */
	HOVERED = 1,

	/** The placeholder is selected */
	SELECTED = 2,

	/** The placeholder is hidden */
	HIDDEN = 4,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

/** Attributes needed by the MapPlaceholdersGeometry class */
type MapPlaceholdersGeometryAttributes = {
	/** The positions of all the placeholders */
	position: Float32BufferAttribute;

	/** A flag for each placeholders with it's current state */
	state: Int32BufferAttribute;

	/** The custom RGB color for each placeholder, normalized */
	color: Float32BufferAttribute;
};

type PlaceholderClass = {
	/** Class label, e.g. "PoiHighRes", "Locked", "FlashScan" */
	label: string;
	/** Indices of labeled placeholders */
	indices: number[];
};

/**
 * Geometry class to manage the position and state of all the placeholders
 */
export class MapPlaceholdersGeometry extends BufferGeometry {
	#selected: number | undefined = undefined;
	#hovered: number | undefined = undefined;
	#hidden: number[] = [];
	/**
	 * Max. four different classes of placeholders are allowed.
	 * Each placeholder class is represented with a distinct texture triplet:
	 * the texture are used in the normal, hovered, and selected states respectively.
	 */
	#classes = new Array<PlaceholderClass>();

	override attributes: MapPlaceholdersGeometryAttributes;

	/**
	 * Construct a MapPlaceholdersGeometry on a set of positions
	 *
	 * @param positions all the placeholders positions
	 */
	constructor(positions: Vector3[]) {
		super();
		this.attributes = {
			position: new Float32BufferAttribute(positions.map((p) => p.toArray()).flat(), 3, false),
			state: new Uint32BufferAttribute(new Array(positions.length).fill(0), 1, false),
			color: new Float32BufferAttribute(positions.map(() => [0, 0, 0, 0]).flat(), 3, false),
		};
		this.setAttribute("position", this.attributes.position);
		this.setAttribute("state", this.attributes.state);
		this.setAttribute("color", this.attributes.color);
	}

	/**
	 * @param index of the placeholder
	 * @returns the state of the placeholders
	 */
	activeStateAt(index: number): PlaceholderStateFlags {
		switch (index) {
			case this.#selected:
				return PlaceholderStateFlags.SELECTED;
			case this.#hovered:
				return PlaceholderStateFlags.HOVERED;
		}
		if (this.#hidden.includes(index)) {
			return PlaceholderStateFlags.HIDDEN;
		}
		return PlaceholderStateFlags.NORMAL;
	}

	/** @returns the index of the selected placeholder */
	get selected(): number | undefined {
		return this.#selected;
	}

	/** Set the index of the selected placeholder */
	set selected(index: number | undefined) {
		if (this.#selected !== undefined) {
			const state = this.attributes.state.getX(this.#selected);
			this.attributes.state.set([state & ~PlaceholderStateFlags.SELECTED], this.#selected);
		}
		if (index !== undefined) {
			const state = this.attributes.state.getX(index);
			this.attributes.state.set([state | PlaceholderStateFlags.SELECTED], index);
		}
		this.#selected = index;
		this.attributes.state.needsUpdate = true;
	}

	/** @returns the index of the hovered placeholder */
	get hovered(): number | undefined {
		return this.#hovered;
	}

	/** Set the index of the hovered placeholder */
	set hovered(index: number | undefined) {
		if (this.#hovered !== undefined) {
			const state = this.attributes.state.getX(this.#hovered);
			this.attributes.state.set([state & ~PlaceholderStateFlags.HOVERED], this.#hovered);
		}
		if (index !== undefined) {
			const state = this.attributes.state.getX(index);
			this.attributes.state.set([state | PlaceholderStateFlags.HOVERED], index);
		}
		this.#hovered = index;
		this.attributes.state.needsUpdate = true;
	}

	/** @returns the list of hidden placeholders */
	get hidden(): number[] {
		return this.#hidden;
	}

	/** Sets the list of hidden placeholders */
	set hidden(indexes: number[]) {
		// Shallow comparison to not update the attribute array
		// if the hidden array is the same as before
		if (this.#hidden === indexes) return;
		for (let i = 0; i < this.attributes.state.count; ++i) {
			let state = this.attributes.state.array[i];
			if (indexes.includes(i)) {
				state |= PlaceholderStateFlags.HIDDEN;
			} else {
				state &= ~PlaceholderStateFlags.HIDDEN;
			}
			this.attributes.state.set([state], i);
		}

		this.#hidden = indexes;
		this.attributes.state.needsUpdate = true;
	}

	/** Set the color of each placeholder */
	set colors(array: Float32Array) {
		assert(array.length === this.attributes.position.array.length, "Wrong color array size for MapPlaceholders");
		for (let i = 0; i < array.length / 3; ++i) {
			this.attributes.color.setXYZ(i, array[3 * i], array[3 * i + 1], array[3 * i + 2]);
		}
		this.attributes.color.needsUpdate = true;
	}

	/** @returns all placeholder labels currently in use. */
	getAllLabels(): string[] {
		return this.#classes.map((c) => c.label);
	}

	/**
	 *
	 * @param label Label of a placeholder class, e.g. "PoiHighRes", "Locked", "FlashScan"
	 * @returns the indices of placeholders labeled with the given label
	 */
	getLabeledPlaceholders(label: string): number[] {
		const placeholderClass = this.#classes.find((c) => c.label === label);
		return placeholderClass ? placeholderClass.indices : [];
	}

	/**
	 *
	 * @param label Label of a placeholder class, e.g. "PoiHighRes", "Locked", "FlashScan"
	 * @param indices The new indices of placeholders labeled with the given label
	 */
	setLabeledPlaceholders(label: string, indices: number[]): void {
		const placeholderClassIdx = this.#classes.findIndex((c) => c.label === label);
		if (placeholderClassIdx >= 0) {
			const placeholderClass = this.#classes[placeholderClassIdx];
			this.#unsetLabelIdx(placeholderClassIdx + 1, placeholderClass.indices);
			placeholderClass.indices = indices;
			this.#setLabelIdx(placeholderClassIdx + 1, indices);
		} else {
			if (this.#classes.length >= 4) {
				throw new Error("Maximum number of placeholder classes reached");
			}
			this.#classes.push({ label, indices });
			this.#setLabelIdx(this.#classes.length, indices);
		}
		this.attributes.state.needsUpdate = true;
	}

	/**
	 *
	 * @param index The placeholder index
	 * @returns the label of the given placeholder, from 0 to 4 included.
	 */
	readLabelAt(index: number): number {
		const state = this.attributes.state.array[index];
		return state >> PLACEHOLDER_LABEL_BIT_POS;
	}

	#unsetLabelIdx(labelIdx: number, indices: number[]): void {
		const maskLabel = labelIdx << PLACEHOLDER_LABEL_BIT_POS;
		for (const index of indices) {
			let state = this.attributes.state.array[index];
			state &= ~maskLabel;
			this.attributes.state.set([state], index);
		}
	}

	#setLabelIdx(labelIdx: number, indices: number[]): void {
		const maskLabel = labelIdx << PLACEHOLDER_LABEL_BIT_POS;
		for (const index of indices) {
			let state = this.attributes.state.array[index];
			state |= maskLabel;
			this.attributes.state.set([state], index);
		}
	}
}
