import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Remove an element from the project
 */
export interface MutationDeleteElement extends BaseMutation {
  /** The type of the mutation to apply */
  type: MutationTypes.MutationDeleteElement;

  /** Whether to also remove links referencing the deleted element or any of its descendants. */
  cascadeLinks?: boolean;
}

/**
 * @returns true if it is a valid MutationDeleteElement
 * @param data object to check
 */
export function isMutationDeleteElement(
  data: BaseMutation,
): data is MutationDeleteElement {
  return data.type === MutationTypes.MutationDeleteElement;
}

/**
 * Creates the mutation payload to delete an element from the project
 *
 * @param elementID id of the element to delete
 * @param cascadeLinks Whether to also remove links referencing the deleted element or any of its descendants.
 * @returns The mutation payload to be sent to the ProjectAPI
 */
export function createMutationDeleteElement(
  elementID: string,
  cascadeLinks: boolean = false,
): MutationDeleteElement {
  return {
    ...createBaseMutation(MutationTypes.MutationDeleteElement, elementID),
    cascadeLinks,
  };
}
