import { OverviewPlaceholders } from "@faro-lotv/lotv";
import { useLotvDispose } from "@faro-lotv/spatial-ui";
import { useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Vector3 } from "three";
import { IMapPlaceholderVariants, useMapPlaceholderTextures } from "../hooks";
import {
  HiddenWaypointsComputer,
  useHideWaypoints,
} from "./use-hide-waypoints";
import { useWaypointsEvents } from "./use-waypoints-events";

/** Default size of the texture computed from the svg input files */
const DEFAULT_TEXTURE_SIZE = 512;

/** Default size factor applied to hovered/selected placeholders */
const DEFAULT_SIZE_FACTOR = 1.5;

/** Default waypoints size in meters */
const DEFAULT_BASE_SIZE = 0.5;

export type OverviewWaypointsRendererProps = {
  /** Base meters size of a placeholder @default 0.5 */
  baseSize?: number;

  /** Size factor applied to the hovered placeholder @default 1.5 */
  hoveredSizeFactor?: number;

  /** Size factor applied to the selected placeholder @default 1.5 */
  selectedSizeFactor?: number;

  /** Fades out the placeholders after a specific distance. (Only implemented for 3d Placeholders) */
  fadeOffDistance?: number;

  /** Placeholders to render */
  waypoints: Vector3[];

  /** Overrides the hover state of the internal hover */
  hoveredId?: number;

  /** Id of the placeholder shown as selected */
  selectedId?: number;

  /** Override specific textures */
  textures?: Partial<IMapPlaceholderVariants>;

  /** Show/Hide placeholders @default true */
  visible?: boolean;

  /**
   * If true, the placeholders will face the camera
   *
   * @default false
   */
  shouldFaceCamera?: boolean;

  /** Callback executed when the id of the hovered element changes */
  onWaypointHovered?(placeholderId?: number): void;

  /** Callback to signal a placeholder have been clicked*/
  onWaypointClicked?(placeholderId: number): void;

  /**
   * Optional function to compute the visible placeholders at each frame.
   * The function should return an array of hidden indices.
   */
  computeHidden?: HiddenWaypointsComputer;
};

/** @returns a renderer for placeholders in a 3d scene */
export function OverviewWaypointsRenderer({
  waypoints: inputPlaceholders,
  hoveredId: inputHoveredId,
  selectedId: inputSelectedId,
  baseSize = DEFAULT_BASE_SIZE,
  hoveredSizeFactor = DEFAULT_SIZE_FACTOR,
  selectedSizeFactor = DEFAULT_SIZE_FACTOR,
  textures,
  visible = true,
  fadeOffDistance,
  shouldFaceCamera = false,
  computeHidden,
  onWaypointClicked,
  onWaypointHovered,
}: OverviewWaypointsRendererProps): JSX.Element | null {
  const defaultTextures = useMapPlaceholderTextures(DEFAULT_TEXTURE_SIZE);
  // True if the points have changed from the last time the sub-sampling was computed
  const havePointsChanged = useRef(true);

  const [placeholders, setPlaceholders] = useState(inputPlaceholders);

  const overviewPlaceholders = useMemo(() => {
    if (placeholders.length === 0) {
      return null;
    }
    return new OverviewPlaceholders(placeholders);
  }, [placeholders]);
  useLotvDispose(overviewPlaceholders);

  // Efficient placeholders position update, if the number of placeholders
  // have not changed the object can be kept alive and just updated
  useEffect(() => {
    if (inputPlaceholders.length !== placeholders.length) {
      setPlaceholders(inputPlaceholders);
    } else if (overviewPlaceholders) {
      overviewPlaceholders.updatePositions(inputPlaceholders);
      havePointsChanged.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPlaceholders]);

  useFrame(({ camera }) => {
    if (overviewPlaceholders && shouldFaceCamera) {
      // Update the rotation of the placeholders to face the camera
      overviewPlaceholders.copyRotation(camera.quaternion);
    }
  });

  const {
    hoveredIndex,
    selectedIndex,
    onClick,
    onPointerLeave,
    onPointerMove,
  } = useWaypointsEvents({
    hoveredPlaceholderId: inputHoveredId,
    selectedPlaceholderId: inputSelectedId,
    onWaypointClicked,
    onWaypointHovered,
    indexFromHit: (hit) => hit?.instanceId,
  });

  useHideWaypoints({
    waypointsObject: overviewPlaceholders,
    updateHiddenWaypoints: computeHidden,
    allWaypoints: inputPlaceholders,
    selectedIndex,
    havePointsChanged,
  });

  if (!overviewPlaceholders || inputPlaceholders.length === 0 || !visible) {
    return null;
  }

  return (
    <primitive
      object={overviewPlaceholders}
      size={baseSize}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
      /* eslint-disable react/no-unknown-property */
      defaultMap={textures?.defaultTexture ?? defaultTextures.defaultTexture}
      hoveredMap={textures?.hoveredTexture ?? defaultTextures.hoveredTexture}
      selectedMap={textures?.selectedTexture ?? defaultTextures.selectedTexture}
      customMap={textures?.customTexture ?? defaultTextures.customTexture}
      hovered={hoveredIndex}
      hoveredSizeFactor={hoveredSizeFactor}
      selected={selectedIndex}
      selectedSizeFactor={selectedSizeFactor}
      fadeOffDistance={fadeOffDistance}
      /* eslint-enable react/no-unknown-property */
    />
  );
}
