import { MeshBasicMaterial, MeshBasicMaterialParameters, ShaderLib } from "three";
import frag from "../Shaders/MeshFadeOff.frag";
import vert from "../Shaders/MeshFadeOff.vert";
import { makeUniform } from "./Uniforms";

export type MeshFadeOffMaterialParameters = MeshBasicMaterialParameters & {
	/**
	 * The distance (in meters) at which the opacity starts to decrease
	 *
	 * @default 2
	 */
	startFadeDistance?: number;
	/**
	 * The distance (in meters) at which the opacity becomes 0
	 *
	 * @default 8
	 */
	endFadeDistance?: number;
};

/** A material to fade off a mesh as it gets further away from the camera. */
export class MeshFadeOffMaterial extends MeshBasicMaterial {
	vertexShader: string;
	fragmentShader: string;

	uniforms = {
		...ShaderLib.basic.uniforms,
		uStartFadeDistance: makeUniform<number>(2.0),
		uEndFadeDistance: makeUniform<number>(8.0),
	};

	uniformsNeedUpdate = true;

	/**
	 * Create the material with customized optional properties
	 */
	constructor({ startFadeDistance, endFadeDistance, ...rest }: Partial<MeshFadeOffMaterialParameters> = {}) {
		super(rest);
		this.type = "MeshFadeOffMaterialParameters";
		this.vertexShader = vert;
		this.fragmentShader = frag;
		this.uniforms.uStartFadeDistance.value = startFadeDistance ?? this.uniforms.uStartFadeDistance.value;
		this.uniforms.uEndFadeDistance.value = endFadeDistance ?? this.uniforms.uEndFadeDistance.value;
	}

	/** @returns The distance at which the mesh starts fading away. */
	get startFadeDistance(): number {
		return this.uniforms.uStartFadeDistance.value;
	}

	/** @param value The distance at which the mesh starts fading away. */
	set startFadeDistance(value: number) {
		if (value >= this.endFadeDistance) {
			throw Error("It must be startFadeDistance < endFadeDistance");
		}

		this.uniforms.uStartFadeDistance.value = value;
		this.uniformsNeedUpdate = true;
	}

	/** @returns The distance at which the mesh is fully faded away. */
	get endFadeDistance(): number {
		return this.uniforms.uEndFadeDistance.value;
	}

	/** @param value The distance at which the mesh is fully faded away. */
	set endFadeDistance(value: number) {
		if (value <= this.startFadeDistance) {
			throw Error("It must be startFadeDistance < endFadeDistance");
		}

		this.uniforms.uEndFadeDistance.value = value;
		this.uniformsNeedUpdate = true;
	}
}
