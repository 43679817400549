import { InvalidConfigurationError } from "@faro-lotv/foundation";
import { Camera, FloatType, NearestFilter, RedFormat, WebGLRenderer, WebGLRenderTarget } from "three";
import { FullScreenQuad, Pass } from "three/examples/jsm/postprocessing/Pass.js";
import { SsaoMaterial } from "../Materials/SsaoMaterial";
import { SsBlur1DMaterial } from "../Materials/SsBlur1DMaterial";
import { SsComposeMaterial } from "../Materials/SsComposeMaterial";

/**
 * A ThreeJS effect pass to apply Ssao.
 * This is the classic version of the SSAO technique as explained in the famous tutorial atlearopengl.com.
 * It is excellent in detecting all valleys of the model, even the small ones, and filling them with plausible
 * ambient shadow. However, it requires a very high number of texture lookpus and is therefore unnsuitable
 * to be run on intel GPUs.
 */
export class SsaoPass extends Pass {
	#ssaoMaterial = new SsaoMaterial();
	#ssaoBlurMaterial = new SsBlur1DMaterial();
	#ssaoComposeMaterial = new SsComposeMaterial();
	#fsQuad: FullScreenQuad;
	#width = 4;
	#height = 4;
	#aoFbo: WebGLRenderTarget;
	#aoBlurFbo: WebGLRenderTarget;

	/**
	 *
	 * @returns A newly created FBO with only color texture with one channel
	 */
	#createAoFbo(): WebGLRenderTarget {
		const fbo = new WebGLRenderTarget(this.#width, this.#height);
		fbo.texture.format = RedFormat;
		fbo.texture.type = FloatType;
		fbo.texture.minFilter = NearestFilter;
		fbo.texture.magFilter = NearestFilter;
		fbo.texture.generateMipmaps = false;
		fbo.texture.name = "AOfbo";
		fbo.stencilBuffer = false;
		fbo.depthBuffer = false;
		return fbo;
	}

	/**
	 * Constructs a new instance of Ssao pass.
	 *
	 * @param camera The scenes camera to grab camera matrices.
	 */
	constructor(public camera: Camera) {
		super();
		this.#fsQuad = new FullScreenQuad(this.#ssaoMaterial);
		this.#aoFbo = this.#createAoFbo();
		this.#aoBlurFbo = this.#createAoFbo();
	}

	/**
	 * Resizing aux FBOs if needed.
	 *
	 * @param width FBO resolution width
	 * @param height FBO resolution height
	 */
	#resizeFboOnNeed(width: number, height: number): void {
		if (width !== this.#width || height !== this.#height) {
			this.#width = width;
			this.#height = height;
			this.#aoFbo.dispose();
			this.#aoBlurFbo.dispose();
			this.#aoFbo = this.#createAoFbo();
			this.#aoBlurFbo = this.#createAoFbo();
		}
	}

	/**
	 * Dispose all internal resources
	 */
	dispose(): void {
		this.#aoFbo.dispose();
		this.#aoBlurFbo.dispose();
		this.#ssaoMaterial.dispose();
		this.#ssaoBlurMaterial.dispose();
		this.#ssaoComposeMaterial.dispose();
		this.#fsQuad.dispose();
	}

	/**
	 * Renders Ssao
	 *
	 * @param renderer renderer used to render the effect
	 * @param writeBuffer Buffer to write by renderer
	 * @param readBuffer Buffer to read the textures by renderer
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("Ssao pass requires a depth texture in the composer FBO");
		}
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		this.#resizeFboOnNeed(readBuffer.width, readBuffer.height);

		// computing ambient occlusion factor from the depth texture, and saving it to this.aoFbo.texture
		this.#fsQuad.material = this.#ssaoMaterial;
		this.#ssaoMaterial.uniforms.uDepthTex.value = readBuffer.depthTexture;
		this.#ssaoMaterial.uniforms.uProjectionMatrix.value.copy(this.camera.projectionMatrix);
		this.#ssaoMaterial.uniforms.uProjectionMatrixInverse.value.copy(this.camera.projectionMatrixInverse);
		this.#ssaoMaterial.uniformsNeedUpdate = true;
		renderer.setRenderTarget(this.#aoFbo);
		this.#fsQuad.render(renderer);

		// Blurring the ambient occlusion factor, and saving it to this.aoBlurFbo.texture
		this.#ssaoBlurMaterial.uniforms.uSingleChannelTex.value = this.#aoFbo.texture;
		this.#ssaoBlurMaterial.uniforms.uDepthTex.value = readBuffer.depthTexture;
		this.#ssaoBlurMaterial.uniforms.uProjectionMatrixInverse.value.copy(this.camera.projectionMatrixInverse);
		this.#ssaoBlurMaterial.uniforms.uRadius.value = this.#ssaoMaterial.uniforms.uRadius.value;
		this.#ssaoBlurMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.material = this.#ssaoBlurMaterial;
		renderer.setRenderTarget(this.#aoBlurFbo);
		this.#fsQuad.render(renderer);

		// Composing the final result by multiplying the original color by the occlusion factor for each pixel,
		// and simply copying the depth.
		this.#ssaoComposeMaterial.uniforms.uColorTex.value = readBuffer.texture;
		this.#ssaoComposeMaterial.uniforms.uDepthTex.value = readBuffer.depthTexture;
		this.#ssaoComposeMaterial.uniforms.uEffectTex.value = this.#aoBlurFbo.texture;
		this.#ssaoComposeMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.material = this.#ssaoComposeMaterial;
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.#fsQuad.render(renderer);

		renderer.autoClear = oldAutoClear;
	}

	/** @returns the strength of the ambent occlusion shadows. */
	get strength(): number {
		return this.#ssaoMaterial.uniforms.uStrengthFactor.value;
	}

	/** Sets the strength of the ambient occlusion shadows. */
	set strength(s: number) {
		this.#ssaoMaterial.uniforms.uStrengthFactor.value = s;
	}

	/** @returns the SSAO hemispheric kernel radius. */
	get radius(): number {
		return this.#ssaoMaterial.uniforms.uRadius.value;
	}

	/** Sets the SSAO hemispheric kernel radius. */
	set radius(r: number) {
		this.#ssaoMaterial.uniforms.uRadius.value = r;
	}

	/** @returns the depth bias used by SSAO to avoid noise */
	get bias(): number {
		return this.#ssaoMaterial.uniforms.uBias.value;
	}

	/** Sets the depth bias used by SSAO to avoid noise */
	set bias(b: number) {
		this.#ssaoMaterial.uniforms.uBias.value = b;
	}

	/** @returns the sobsampling factor used in the SSAO kernel samples. */
	get subsampleSamples(): number {
		return this.#ssaoMaterial.uniforms.uSubsampleSamples.value;
	}

	/**
	 * Sets the subsampling factor of used samples. The higher the factor the
	 * better performance, the worse visual quality.
	 *
	 * @param s subsampling factor(1,2,3...)
	 */
	set subsampleSamples(s: number) {
		if (s >= 1) {
			this.#ssaoMaterial.uniforms.uSubsampleSamples.value = Math.round(s);
		}
	}
}
