import {
	Camera,
	DepthStencilFormat,
	DepthTexture,
	LinearFilter,
	NearestFilter,
	RGBAFormat,
	Scene,
	UnsignedInt248Type,
	Vector2,
	WebGLRenderTarget,
	WebGLRenderer,
} from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { Pass } from "three/examples/jsm/postprocessing/Pass.js";
import { changeCamera, changeScene } from "./ReactivePass";

/**
 * A wrapper of 3JS EffectComposer with sensible defaults
 */
export class EffectPipeline {
	/** Current width of the internal render targets */
	width: number;

	/** Current height of the internal render targets */
	height: number;

	/** Current pixel density */
	dpr: number;

	/**
	 * A function to generate a render target to use in our effect composers
	 *
	 * @param width The framebuffer width
	 * @param height The framebuffer height
	 * @returns A correctly initialized render target with depth and stencil attachment
	 */
	static fboTemplate(width: number, height: number): WebGLRenderTarget {
		const fbo = new WebGLRenderTarget(width, height);
		fbo.texture.format = RGBAFormat;
		fbo.texture.minFilter = LinearFilter;
		fbo.texture.magFilter = NearestFilter;
		fbo.texture.generateMipmaps = false;
		fbo.texture.name = "EffectComposer.rt1";
		fbo.stencilBuffer = true;
		fbo.depthBuffer = true;
		fbo.depthTexture = new DepthTexture(width, height);
		fbo.depthTexture.minFilter = fbo.depthTexture.magFilter = NearestFilter;
		fbo.depthTexture.format = DepthStencilFormat;
		fbo.depthTexture.type = UnsignedInt248Type;
		return fbo;
	}

	/** The internal effect composer */
	composer: EffectComposer;

	/**
	 * Construct an EffectPipeline
	 *
	 * @param renderer The render context to use for the effects
	 */
	constructor(protected renderer: WebGLRenderer) {
		const { x, y } = renderer.getSize(new Vector2());
		const dpr = renderer.getPixelRatio();
		this.width = x;
		this.height = y;
		this.dpr = dpr;
		this.composer = new EffectComposer(renderer, EffectPipeline.fboTemplate(this.width * dpr, this.height * dpr));
	}

	/**
	 * Add a pass to the render pipeline
	 *
	 * @param pass An instance of THREEJS Pass class
	 * @returns the appended pass
	 */
	addPass<T extends Pass>(pass: T): T {
		this.composer.addPass(pass);
		return pass;
	}

	/**
	 * Remove a pass from the render pipeline
	 *
	 * @param pass The pass to remove
	 */
	removePass(pass: Pass): void {
		this.composer.removePass(pass);
	}

	/**
	 * Resize all needed data in this pipeline when the canvas change size
	 *
	 * @param width The new context width
	 * @param height The new context height
	 * @param dpr The new context pixel density
	 * @returns whether the new size was different than the old one.
	 */
	setSize(width: number, height: number, dpr: number): boolean {
		if (this.width === width && this.height === height && this.dpr === dpr) return false;
		this.width = width;
		this.height = height;
		this.dpr = dpr;
		// Resize the composer (will resize color texture, taking into account dpr)
		this.composer.setSize(width, height);
		// Re-create the render target (as composer.setSize do not resize them correctly)
		this.composer.renderTarget1.dispose();
		this.composer.renderTarget2.dispose();
		this.composer.renderTarget1 = EffectPipeline.fboTemplate(width * dpr, height * dpr);
		this.composer.renderTarget2 = EffectPipeline.fboTemplate(width * dpr, height * dpr);
		this.composer.readBuffer = this.composer.renderTarget1;
		this.composer.writeBuffer = this.composer.renderTarget2;
		return true;
	}

	/**
	 * Change the camera used to render this pipeline
	 *
	 * @param camera the new active camera
	 */
	changeCamera(camera: Camera): void {
		for (const pass of this.composer.passes) {
			changeCamera(pass, camera);
		}
	}

	/**
	 * Change the scene used to render this pipeline
	 *
	 * @param scene the new active scene
	 */
	changeScene(scene: Scene): void {
		for (const pass of this.composer.passes) {
			changeScene(pass, scene);
		}
	}

	/**
	 * Render this pipeline
	 *
	 * @param delta Seconds passed from previous render
	 */
	render(delta: number): void {
		this.composer.render(delta);
	}
}
