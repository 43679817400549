import {
	ColorRepresentation,
	DoubleSide,
	Euler,
	FrontSide,
	Mesh,
	MeshBasicMaterial,
	PlaneGeometry,
	Vector3,
} from "three";

export enum BoxSideHoverState {
	/** No hover effect */
	none = "none",

	/** Default hover effect */
	default = "default",

	/** Hover effect indicating a selection */
	select = "select",
}

/**
 * Mesh for a single side of the BoxControls box
 */
export class BoxSide extends Mesh {
	/** The default name of the 3D object */
	name = "BoxSide";

	/** Geometry used to render a side of the box */
	static geometry = new PlaneGeometry(1, 1);

	/** The base material of the box side. The parameters are set by #updateMaterial() according to its state. */
	material = new MeshBasicMaterial({ transparent: true, depthWrite: false });

	/**
	 * @param position The position of the box relative to the parent
	 * @param rotation The rotation of the box relative to the parent
	 * @param color The color to use for the box side
	 * @param selectedColor The color to use for a selected box side
	 */
	constructor(
		position: Vector3,
		rotation: Euler,
		private color: ColorRepresentation,
		private selectedColor: ColorRepresentation,
	) {
		super();

		this.geometry = BoxSide.geometry;

		this.#updateMaterial();

		this.position.copy(position);
		this.rotation.copy(rotation);
	}

	#hoverState = BoxSideHoverState.none;
	/** the current hover state */
	set hoverState(hovered: BoxSideHoverState) {
		this.#hoverState = hovered;
		this.#updateMaterial();
	}
	/** @returns the current hover state */
	get hoverState(): BoxSideHoverState {
		return this.#hoverState;
	}

	#selected: boolean = false;
	/** whether the current side is selected */
	set selected(selected: boolean) {
		this.#selected = selected;
		this.#updateMaterial();
	}
	/** @returns whether the current side is selected */
	get selected(): boolean {
		return this.#selected;
	}

	#updateMaterial(): void {
		if (this.selected || this.hoverState !== BoxSideHoverState.none) {
			this.material.side = DoubleSide;
		} else {
			this.material.side = FrontSide;
		}

		switch (this.#hoverState) {
			case BoxSideHoverState.none:
				if (this.selected) {
					this.material.color.set(this.selectedColor);
					this.material.opacity = 0.3;
				} else {
					this.material.color.set(this.color);
					this.material.opacity = 0.2;
				}
				break;
			case BoxSideHoverState.default:
				this.material.color.set(this.color);
				this.material.opacity = 0.6;
				break;
			case BoxSideHoverState.select:
				this.material.color.set(this.selectedColor);
				this.material.opacity = 0.15;
				break;
		}
	}
}
