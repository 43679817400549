import { GUID } from "@faro-lotv/foundation";
import {
  IElementSection,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddArea extends BaseMutation {
  /** New area section element */
  newElement: CreateIElement<IElementSection>;
}

interface CreateMutationAddArea {
  /** ID of the element to be created */
  id: GUID;

  /** Name of the area being created */
  name: string;

  /** ID of the root element */
  rootId: GUID;

  /** ID of the parent group (SlideContainer)  */
  groupId: GUID;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;
}

/**
 * Creates a MutationAddArea object with a new area element.
 *
 *```
 * Group (SlideContainer)
 * └ Section (Area)
 * ```
 *
 * @returns A MutationAddArea object with a new area element.
 */
export function createMutationAddArea({
  id,
  rootId,
  groupId,
  name,
  createdAt = new Date().toISOString(),
}: CreateMutationAddArea): MutationAddArea {
  const area: CreateIElement<IElementSection> = {
    type: IElementType.section,
    typeHint: IElementTypeHint.area,
    id,
    rootId,
    parentId: groupId,
    childrenIds: [],
    name,
    createdAt,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddArea, groupId),
    newElement: area,
  };
}
