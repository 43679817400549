import { GUID, UrlUpdater } from "@faro-lotv/foundation";
import { ProjectApi } from "../project-api";
import { signIElementUris } from "./sign-ielement-uris";

/**
 * Construct a function to request an updated signature for an uri in the project
 *
 * The signature requests are attached to a specific IElement ID, as the user access is checked
 * against a specific element id
 * If the url can't be signed the original url will be returned with to remain compatible with serving
 * files that can't be signed (Eg: Demo project data)
 *
 * @param projectApi client instance to use to request the signatures
 * @param elementId of the element to sign the uri for
 * @returns a function to sign projects uris against a specific IElement
 */
export function makeIElementUriSigner(
  projectApi: Pick<ProjectApi, "signUrls">,
  elementId: GUID,
): UrlUpdater {
  return (url: string) =>
    signIElementUris(projectApi, elementId, [url]).then(
      ({ signedUrls, expiresOn }) => ({
        signedUrl: signedUrls[0],
        expiresOn,
      }),
    );
}
