import { DateTime } from "luxon";
import { assert } from "./assert";

/** The default locale to use for conversion if no locale is passed. In case `defaultLocale` is then `undefined` the system locale is used*/
export let defaultLocale: string | undefined;

/**
 * Change the default locale used for time<->string conversions
 *
 * @param locale the locale string to use or undefined to use the system locale
 */
export function changeDefaultLocale(locale: string | undefined): void {
  defaultLocale = locale;
}

/**
 * @returns The String in the global locale format `{Date} - {Time}`. When `locale` is set then this `locale` for formatting is used
 * Otherwise the `defaultLocale` is used. If `defaultLocale` is not set then the system locale is used.
 * @param timestamp String compatible to parse by Date
 * @param locale The locale String to use for formatting the date and time e.q 'en-US'. If not set first `defaultLocale` is used and
 * if that is not set the system locale is used.
 */
export function convertToDateTimeString(
  timestamp: string,
  locale: string | undefined = defaultLocale,
): string {
  const dateObject = new Date(timestamp);

  if (isNaN(dateObject.getTime())) {
    return "Invalid Timestamp";
  }

  const date = dateObject.toLocaleString(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const time = dateObject
    .toLocaleString(locale, {
      hour: "2-digit",
      hourCycle: "h12",
      minute: "2-digit",
    })
    // Remove whitespace characters and replace them with normal space, this will be removed after fixing the ticket below
    // TODO - https://faro01.atlassian.net/browse/SWEB-1938
    .replace(/\s/g, " ");
  return `${date} - ${time}`;
}

/**
 * @returns Date string in a specific format based on `locale` value. Otherwise the `defaultLocale` is used.
 * If `defaultLocale` is not set then the system locale is used.
 * @param timestamp String compatible to parse by Date
 * @param locale The locale String to use for formatting the date e.q 'en-US'. If not set first `defaultLocale` is used and
 * if that is not set the system locale is used.
 */
export function convertToDateString(
  timestamp: string,
  locale: string | undefined = defaultLocale,
): string {
  return new Date(timestamp).toLocaleString(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

/**
 * @param date object to get the date and time from
 * @returns the date time, in the iso format, without milliseconds and with the timezone offset.
 */
export function dateToISOWithOffset(date: Date): string {
  // Need to set the ms to 0 otherwise the suppressMilliseconds flag will not work
  date.setMilliseconds(0);

  const isoDate = DateTime.fromJSDate(date).toISO({
    suppressMilliseconds: true,
  });
  assert(isoDate, "The input date should be a valid Date object");

  return isoDate;
}

/**
 * @returns Comparison value of two dates:
 *   - <0 means that dateA is before dateB
 *   - 0 means that dateA and dateB are exactly the same
 *   - >0 means that dateA is after dateB
 * @param dateA First date to use for comparison
 * @param dateB Second date to use for comparison
 */
export function compareDateTimes(
  dateA: string | Date,
  dateB: string | Date,
): number {
  const a = typeof dateA === "string" ? Date.parse(dateA) : dateA.getTime();
  const b = typeof dateB === "string" ? Date.parse(dateB) : dateB.getTime();

  assert(!isNaN(a), "dateA is invalid");
  assert(!isNaN(b), "dateB is invalid");

  return a - b;
}

/**
 * @returns a "merged" date using the date of the first Date and the time form the second Date
 * @param date the Date to use the date from
 * @param time the Date to use the time from
 */
export function combineDateAndTime(date: Date, time: Date): Date {
  assert(!isNaN(date.getTime()), "combineDateAndTime: date is invalid");
  assert(!isNaN(time.getTime()), "combineDateAndTime: time is invalid");

  const dateSource = DateTime.fromJSDate(date);

  const dateTime = DateTime.fromJSDate(time).set({
    day: dateSource.day,
    month: dateSource.month,
    year: dateSource.year,
  });

  return dateTime.toJSDate();
}
