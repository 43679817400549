import { validateNotNullishObject, validatePrimitive } from "@faro-lotv/foundation";
import { Box3, Vector3 } from "three";

/** Point defined the model chunk metadata json */
type ModelChunkPoint = {
	X: number;
	Y: number;
	Z: number;
};

function isModelChunkPoint(data: unknown): data is ModelChunkPoint {
	return (
		validatePrimitive(data, "X", "number") &&
		validatePrimitive(data, "Y", "number") &&
		validatePrimitive(data, "Z", "number")
	);
}

type ModelChunkBoundingBox = {
	Min: ModelChunkPoint;
	Max: ModelChunkPoint;
};

function isModelChunkBoundingBox(data: unknown): data is ModelChunkBoundingBox {
	if (!validateNotNullishObject(data, "ModelChunkBoundingBox")) {
		return false;
	}

	const box: Partial<ModelChunkBoundingBox> = data;
	return (
		validatePrimitive(data, "Min", "object") &&
		isModelChunkPoint(box.Min) &&
		validatePrimitive(data, "Max", "object") &&
		isModelChunkPoint(box.Max)
	);
}

/** Metadata of a model chunk */
type ModelChunkMetadata = {
	GlbSize: number;
	GlbUrl: string;
	BoundingBox: ModelChunkBoundingBox;
};

/**
 * Checks if the given data is a valid model chunk metadata
 *
 * @param data The data to be checked
 * @returns True if the data is a valid model chunk metadata
 */
function isModelChunkMetadata(data: unknown): data is ModelChunkMetadata[] {
	if (!Array.isArray(data)) return false;
	for (const val of data) {
		if (!validatePrimitive(val, "GlbSize", "number")) return false;
		if (!validatePrimitive(val, "GlbUrl", "string")) return false;
		if (!validatePrimitive(val, "BoundingBox", "object")) return false;
		if (!isModelChunkBoundingBox(val.BoundingBox)) return false;
	}
	return true;
}

/** State of a model chunk */
export enum StreamCadChunkState {
	WaitForDownload = 0,
	Downloading = 1,
	DownloadSuccess = 2,
	DownloadFailed = 3,
}

/** Model chunk */
export class StreamCadChunk {
	url: URL;
	state = StreamCadChunkState.WaitForDownload;
	boundingBox: Box3;

	/**
	 * Constructs a model chunk from the given metadata
	 *
	 * @param data The metadata of the model chunk
	 */
	constructor(data: ModelChunkMetadata) {
		this.url = new URL(data.GlbUrl);
		this.boundingBox = new Box3(
			new Vector3(data.BoundingBox.Min.X, data.BoundingBox.Min.Y, data.BoundingBox.Min.Z),
			new Vector3(data.BoundingBox.Max.X, data.BoundingBox.Max.Y, data.BoundingBox.Max.Z),
		);
	}
}

/**
 * Load the stream CAD model chunks from the given metadata url.
 *
 * @param url URL of the model chunks metadata
 * @returns The loaded model chunks
 */
export async function loadStreamCadModelChunks(url: URL): Promise<StreamCadChunk[]> {
	const res = await fetch(url);
	if (!res.ok) {
		throw new Error("Failed to fetch the model");
	}
	const chunks = await res.json();

	if (!isModelChunkMetadata(chunks)) {
		throw new Error("Could not parse a list of CAD model chunks from JSON data");
	}

	const modelChunks: StreamCadChunk[] = [];
	for (const chunk of chunks) {
		modelChunks.push(new StreamCadChunk(chunk));
	}
	return modelChunks;
}
