import { Camera, OrthographicCamera, PerspectiveCamera } from "three";

// TODO: Update lotv code to use this type https://faro01.atlassian.net/browse/SWEB-3206
export type SupportedCamera = OrthographicCamera | PerspectiveCamera;

/**
 * @param camera object to check the type of
 * @returns true if the camera is a Orthographic or Perspective camera
 */
export function isSupportedCamera(camera: Camera): camera is SupportedCamera {
	return camera instanceof PerspectiveCamera || camera instanceof OrthographicCamera;
}
