/** Blue color palette */
export const blue = {
  50: "#EFF4FE",
  100: "#DEE8FD",
  200: "#AEC7F9",
  300: "#7EA7F6",
  400: "#4E86F3",
  500: "#1F65F0",
  600: "#0E4ECC",
  700: "#0B3C9D",
  800: "#082A6E",
  900: "#12213D",
} satisfies Color;

/** Cyan color palette */
export const cyan = {
  50: "#F5F9FF",
  100: "#E6F1FF",
  200: "#BDDCFF",
  300: "#8AC0FF",
  400: "#57A5FF",
  500: "#3392FF",
  600: "#2B7BD6",
  700: "#215EA3",
  800: "#164070",
  900: "#12263D",
} satisfies Color;

/** Green color palette */
export const green = {
  50: "#E8FCF3",
  100: "#C7F9DE",
  200: "#99F5C2",
  300: "#6BF0A6",
  400: "#3DEB8A",
  500: "#17DE6F",
  600: "#12B058",
  700: "#0D8040",
  800: "#08532A",
  900: "#123B24",
} satisfies Color;

/** Neutral color palette */
export const neutral = {
  0: "#FFFFFF",
  50: "#F8F9FA",
  100: "#F0F2F5",
  200: "#E0E4EB",
  300: "#C2C9D6",
  400: "#A3AEC2",
  500: "#8592AD",
  600: "#667799",
  700: "#525F7A",
  800: "#363C4D",
  900: "#29303D",
  950: "#20252E",
  999: "#15191F",
  1000: "#000000",
} satisfies Color;

/** Orange color palette */
export const orange = {
  50: "#FFF2EB",
  100: "#FFD3B8",
  200: "#FFB485",
  300: "#FF9452",
  400: "#FF731E",
  500: "#F26005",
  600: "#D65504",
  700: "#A34103",
  800: "#702C02",
  900: "#3D2312",
} satisfies Color;

/** Red color palette */
export const red = {
  50: "#FFEBEB",
  100: "#FDB9B9",
  200: "#FC8787",
  300: "#FB5555",
  400: "#FA2424",
  500: "#E50505",
  600: "#B30404",
  700: "#820303",
  800: "#500202",
  900: "#330F0F",
} satisfies Color;

/** Yellow color palette */
export const yellow = {
  50: "#FFF8E5",
  100: "#FFE9B2",
  200: "#FFDB80",
  300: "#FFCC4C",
  400: "#FFBE19",
  500: "#E8A600",
  600: "#CC9200",
  700: "#805B00",
  800: "#4D3700",
  900: "#33280F",
} satisfies Color;

/** Purple color palette */
export const purple = {
  50: "#F4EBFF",
  100: "#EBD9FF",
  200: "#DDBFFF",
  300: "#C28CFF",
  400: "#A659FF",
  500: "#8B26FF",
  600: "#6F1FCC",
  700: "#531799",
  800: "#380F66",
  900: "#30174C",
} satisfies Color;

/** A string that must start with a # */
export type ColorString = `#${string}`;

/**
 * A color hue with it's different shades
 *
 * Inspired by https://mui.com/material-ui/customization/color/#2014-material-design-color-palettes
 */
export type Color = NumberColorRecord & {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  /* eslint-disable @typescript-eslint/naming-convention */
  50: ColorString;
  100: ColorString;
  200: ColorString;
  300: ColorString;
  400: ColorString;
  500: ColorString;
  600: ColorString;
  700: ColorString;
  800: ColorString;
  900: ColorString;
  /* eslint-enable @typescript-eslint/no-magic-numbers */
  /* eslint-enable @typescript-eslint/naming-convention */
};

/** Helper type to tell TS that the indexes of the Color type are numbers */
type NumberColorRecord = Record<number, ColorString>;

/** Helper type for defining a list of named ColorStrings */
type NamedColorRecord = Record<string, ColorString>;

/**
 * These colors are designed for views where datasets such as point clouds need to be visually inspected.
 * The colors were chosen so that they are easily distinguishable from each other, even if the user is color blind.
 * If only 2 colors are needed, primary and secondary should be used. If more colors are needed, any of other colors
 * can be used additionally.
 */
export const dataComparisonColors: NamedColorRecord = {
  // primary: cyan
  primary: "#3392FF",
  // secondary: orange
  secondary: "#FF731E",

  pink: "#E81CBF",
  teal: "#17DDE5",
  lime: "#C0DB29",
  violet: "#6C008E",
  brown: "#B1410F",
  purple: "#8B26FF",
  burgundy: "#960A76",
  deepGreen: "#20805B",
  blue: "#01426A",
};

/**
 * A sorted list of the data comparison colors, so that they can easily be accessed by index, if required.
 * Sorting order was suggested by design team
 */
export const dataComparisonColorsSorted: ColorString[] = [
  dataComparisonColors.primary,
  dataComparisonColors.secondary,
  dataComparisonColors.pink,
  dataComparisonColors.teal,
  dataComparisonColors.lime,
  dataComparisonColors.violet,
  dataComparisonColors.brown,
  dataComparisonColors.purple,
  dataComparisonColors.burgundy,
  dataComparisonColors.deepGreen,
  dataComparisonColors.blue,
];
