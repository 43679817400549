import {
  MapPlaceholderDefault,
  MapPlaceholderHovered,
  MapPlaceholderSelected,
} from "@faro-lotv/flat-ui";
import { Texture } from "three";
import { useSvg } from "./use-svg";

export interface IMapPlaceholderVariants {
  /** Texture to use when the placeholder  */
  defaultTexture: Texture;
  /** Texture to use when the placeholder is selected */
  selectedTexture: Texture;
  /** Texture to use when the placeholder is hovered over */
  hoveredTexture: Texture;
  /** Texture to use when the placeholder is in a custom state */
  customTexture: Texture;
}
/**
 * Loads all variants of the map placeholder textures using the useSvg hook
 *
 * @param size Resolution of the rasterized svg
 * @returns all the loaded textures needed for the placeholder rendering
 */
export function useMapPlaceholderTextures(
  size: number,
): IMapPlaceholderVariants {
  const defaultTexture = useSvg(MapPlaceholderDefault, size, size);
  const selectedTexture = useSvg(MapPlaceholderSelected, size, size);
  const hoveredTexture = useSvg(MapPlaceholderHovered, size, size);

  return {
    defaultTexture,
    selectedTexture,
    hoveredTexture,
    customTexture: defaultTexture,
  };
}
