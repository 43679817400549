/**
 * The scale of a nodes-group created on client-side.
 * The nodes-groups generated by the Project API have scale 0.1, to compensate for relatively large objects created by the WebEditor.
 * For consistency, groups created by clients should have the same scale.
 */
export const DEFAULT_NODES_GROUP_SCALE_UNIFORM = 0.1;

/**
 * The scale of an analysis section created on client-side.
 * An analysis section is under a nodes-group. To counter balance for the scale at nodes-group (0.1),
 * the analysis section created by clients should have the scale of 10, making the net scale for analysis to be 1.
 */
export const DEFAULT_ANALYSIS_SECTION_SCALE_UNIFORM = 10;
