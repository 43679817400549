precision highp float;

#include <common>
#include <bsdfs>
#include <lights_pars_begin>
#include <clipping_planes_pars_fragment>

in vec3 fragPosition;
in vec3 fragNormal;

// On Intel GPUs, GLSL shaders are translated to directX's HLSL 
// by a non-optimal driver, to say the least. Passing 'flat int' 
// variables between shaders is allowed, it compiles, but the 
// performances are completely unacceptable. Hence we pass a simple float.
in float fragDrawID;

#if COLOR_TEXTURES

in vec2 fTexCoord;
// we allocate only MAX_NUMBER_OF_COLOR_TEXTURES texture samplers 
// because in webgl 2.0 MAX_TEXTURE_IMAGE_UNITS is 16
// and we are already using two for materials and poses.
uniform sampler2D colorTextures[MAX_NUMBER_OF_COLOR_TEXTURES];

#endif

uniform sampler2D materialTexture;

#include <lotv_lighting_computations>
#include <lotv_color_conversions>

uniform vec3 highlightingColor;

// This function retrieves the material of the current fragment, fetching it from the 
// data texture that contains the materials per view.
Material retrieveMaterial() {
    Material ret;

    int i = 4 * int(round(fragDrawID));
    ivec2 sz = textureSize(materialTexture, 0);
    int row = i / sz.x;
    int col = i - row * sz.x;
    ret.ambient = texelFetch(materialTexture, ivec2(col, row), 0).rgb;
    ret.diffuse = texelFetch(materialTexture, ivec2(col + 1, row), 0).rgb;
    ret.specular = texelFetch(materialTexture, ivec2(col + 2, row), 0).rgb;
    vec4 shininessOpacity = texelFetch(materialTexture, ivec2(col + 3, row), 0);
    ret.shininess = shininessOpacity.x;
    ret.opacity = shininessOpacity.y;

#if COLOR_TEXTURES
    int texId = int(shininessOpacity.z);
    if(texId >= 0 && texId < MAX_NUMBER_OF_COLOR_TEXTURES) {
        vec3 color = vec3(1.0);
        switch(texId)
        {
        case 0: color = texture(colorTextures[0], fTexCoord).rgb; break;
        case 1: color = texture(colorTextures[1], fTexCoord).rgb; break;
        case 2: color = texture(colorTextures[2], fTexCoord).rgb; break;
        case 3: color = texture(colorTextures[3], fTexCoord).rgb; break;
        case 4: color = texture(colorTextures[4], fTexCoord).rgb; break;
        case 5: color = texture(colorTextures[5], fTexCoord).rgb; break;
        case 6: color = texture(colorTextures[6], fTexCoord).rgb; break;
        case 7: color = texture(colorTextures[7], fTexCoord).rgb; break;
        case 8: color = texture(colorTextures[8], fTexCoord).rgb; break;
        case 9: color = texture(colorTextures[9], fTexCoord).rgb; break;
        case 10: color = texture(colorTextures[10], fTexCoord).rgb; break;
        case 11: color = texture(colorTextures[11], fTexCoord).rgb; break;
        case 12: color = texture(colorTextures[12], fTexCoord).rgb; break;
        case 13: color = texture(colorTextures[13], fTexCoord).rgb; break;
        }

        if(shininessOpacity.a == 1.0) {    
            float luma = dot(extractLuma, color);
            ret.ambient = ret.diffuse = highlightingColor * luma * 1.2;
        } else {
            ret.ambient = ret.diffuse = color;
        }
    } else if(shininessOpacity.a == 1.0) {
            ret.ambient = ret.diffuse = highlightingColor;
    }
#else
    if(shininessOpacity.a == 1.0) {
        ret.ambient = ret.diffuse = highlightingColor;
    }
#endif
    return ret;    
}

void main() 
{
    #include <clipping_planes_fragment>
    
    Material mat = retrieveMaterial();

    vec3 c = mat.ambient * ambientLightColor * RECIPROCAL_PI;
    vec3 normal = normalize(fragNormal);
    vec3 viewDir = normalize(-fragPosition);

#if NUM_HEMI_LIGHTS > 0
    c += computeHemisphereLights(normal, mat.ambient);
#endif

#if NUM_DIR_LIGHTS > 0
    c += computeDirectionalLights(normal, viewDir, mat);
#endif

#if NUM_POINT_LIGHTS > 0
    c += computePointLights(normal, viewDir, mat);
#endif

#if NUM_SPOT_LIGHTS > 0
    c += computeSpotLights(normal, viewDir, mat);
#endif

    gl_FragColor = vec4(c, mat.opacity);
}
