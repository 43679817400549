import { assert } from "@faro-lotv/foundation";
import {
	Camera,
	CircleGeometry,
	Intersection,
	Object3D,
	OrthographicCamera,
	PerspectiveCamera,
	Raycaster,
	Scene,
	Sphere,
	Sprite,
	Vector2,
	Vector3,
	WebGLRenderer,
} from "three";
import { BillboardScreenSpriteMaterial } from "../Materials";
import { degToRad } from "../Utils";

const DEFAULT_FOVY = 70;

const TEMP_VEC3 = new Vector3();
const TEMP_SPHERE = new Sphere();

/**
 * Billboard sprite that has a fixed size in pixels
 */
export class BillboardScreenSprite extends Sprite {
	declare geometry: CircleGeometry;
	declare material: BillboardScreenSpriteMaterial;

	/**
	 * We remember in this object the screen size and the perspective camera vertical angle,
	 * to ease shader computations with the billboard size.
	 */
	#screenSize = new Vector2();
	#fovy = DEFAULT_FOVY;
	#orthoVsize = 1;

	#radius = 0.1;

	/**
	 * @param geometry circle geometry to reuse for all sprites
	 */
	constructor(geometry?: CircleGeometry) {
		super();
		this.material = new BillboardScreenSpriteMaterial();
		this.geometry = geometry ?? new CircleGeometry(this.#radius);
	}

	/**
	 * Before rendering the billboard, we make sure to update the screen resolution
	 * to the shader.
	 *
	 * @param renderer The renderer
	 * @param _ The scene that is being rendered
	 * @param camera The camera that is being used for rendering
	 */
	override onBeforeRender = (renderer: WebGLRenderer, _: Scene, camera: Camera): void => {
		assert(camera instanceof PerspectiveCamera || camera instanceof OrthographicCamera, "Camera type unsupported");
		if (camera instanceof PerspectiveCamera) {
			this.#setupPix2MetersConversionPerspective(renderer.getSize(new Vector2()), camera.getEffectiveFOV());
		} else {
			// To understand the line below, check out 3js orthocamera implementation, OrthographicCamera.js line 87.
			const ovsize = (camera.top - camera.bottom) / camera.zoom;
			this.#setupPix2MetersConversionOrtho(renderer.getSize(new Vector2()), ovsize);
		}
	};

	/**
	 * Setup shader parameters for correct computations of billboard sizes.
	 *
	 * @param sz The screen size
	 * @param fovy The perspective camera vertical angle
	 */
	#setupPix2MetersConversionPerspective(sz: Vector2, fovy: number): void {
		if (!sz.equals(this.#screenSize) || this.#fovy !== fovy) {
			this.#screenSize.copy(sz);
			this.#fovy = fovy;
			this.material.depthToNormalizedScreenHeight =
				(2 * Math.tan(degToRad(this.#fovy) * 0.5)) / this.#screenSize.y;
		}
	}

	/**
	 * Setup shader parameters for correct computations of billboard sizes.
	 *
	 * @param sz The screen size
	 * @param orthoVsize The ortho projection vertical size
	 */
	#setupPix2MetersConversionOrtho(sz: Vector2, orthoVsize: number): void {
		if (!sz.equals(this.#screenSize) || this.#orthoVsize !== orthoVsize) {
			this.#screenSize.copy(sz);
			this.#orthoVsize = orthoVsize;
			this.material.depthToNormalizedScreenHeight = this.#orthoVsize / this.#screenSize.y;
		}
	}

	/** @returns The size of the diameter of the sprite, in pixels*/
	get size(): number {
		return this.material.size;
	}

	/** Sets the size of the diameter of the sprite, in pixels */
	set size(v: number) {
		this.material.size = v;
	}

	/** @returns this sprite's opacity */
	get opacity(): number {
		return this.material.opacity;
	}

	/** Sets this sprite's opacity */
	set opacity(o: number) {
		this.material.opacity = o;
	}

	/** @inheritdoc */
	override raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		// The raycast is simply computed using a sphere whose radius is adjusted based on the
		// sprite size in pixels
		this.getWorldPosition(TEMP_SPHERE.center);
		TEMP_VEC3.copy(TEMP_SPHERE.center).applyMatrix4(raycaster.camera.matrixWorldInverse);
		const radiusFactor = raycaster.camera instanceof OrthographicCamera ? 1 : TEMP_VEC3.z;
		TEMP_SPHERE.radius = this.material.depthToNormalizedScreenHeight * this.material.size * radiusFactor;
		const point = raycaster.ray.intersectSphere(TEMP_SPHERE, TEMP_VEC3);
		if (point) {
			intersects.push({
				distance: point.distanceTo(raycaster.ray.origin),
				point: TEMP_VEC3.clone(),
				face: null,
				object: this,
			});
		}
	}
}
