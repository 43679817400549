import { Box, Stack, SvgIcon } from "@mui/material";
import { PropsWithChildren, forwardRef, useMemo } from "react";
import { Alert } from "../components/alert/alert";
import { FaroButton } from "../components/button/faro-button";
import { neutral } from "../components/colors";
import { FaroIconButton } from "../components/icon-button/faro-icon-button";
import { CloseIcon } from "../components/icons";
import { FaroText } from "../components/text/faro-text/faro-text";
import { OpenToastOptions, ToastProps } from "./toast-types";

/**
 * @returns Content for toast notifications
 *
 * forwardRef has to be used to get compatibility with notistack
 */
const ToastContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ToastProps>
>(function ToastContainer(
  { children, variant, title, sx, onClose },
  ref,
): JSX.Element {
  return (
    <Stack
      direction="column"
      ref={ref}
      sx={{
        backgroundColor: `${variant}.light`,
        maxWidth: "480px",
        width: "fit-content",
        border: `1px solid ${neutral[1000]}20`,
        // Make sure the background color does not affect (blend) with the border color
        backgroundClip: "padding-box",
        borderRadius: "4px",
        filter: `drop-shadow(0px 2px 6px ${neutral[1000]}40)`,
      }}
    >
      <Alert
        title={title}
        variant={variant}
        action={
          <FaroIconButton size="xs" color={neutral[600]} onClick={onClose}>
            <CloseIcon />
          </FaroIconButton>
        }
        // Add more space between the icon and the title, by changing the alert's container gap value
        sx={{
          pt: 2,
          ">*>.MuiStack-root": { gap: 2 },
          ">.MuiAlert-message": {
            // Align the title and icon with the X button
            display: "flex",
            alignItems: "center",
            py: 0,
          },
          ">.MuiAlert-action": {
            // Remove top padding from X button so that it is aligned with the rest of the content of the alert
            pt: 0,
            alignSelf: "center",
            mr: 0,
          },
          ...sx,
        }}
      />
      {/** Content of the toast (message, image and action) */}
      <Box component="div">{children}</Box>
    </Stack>
  );
});

/** @returns A common UI for snackbars */
export const FaroToast = forwardRef<
  HTMLDivElement,
  PropsWithChildren<OpenToastOptions & { onClose(): void }>
>(function Toast(
  { title, variant = "success", message, onClose, image, action },
  ref,
): JSX.Element {
  const isEmpty = useMemo(
    () => !message && !image && !action,
    [action, image, message],
  );

  return (
    <ToastContainer
      ref={ref}
      title={title}
      variant={variant}
      // Remove extra padding between title and the toast's content if it's present
      sx={{ pb: isEmpty ? 2 : 0 }}
      onClose={onClose}
    >
      <Stack
        direction="column"
        // Add margin left so that the content starts at the same place as the title
        sx={{ ml: "52px", mt: isEmpty ? 0 : 1, mb: isEmpty ? 0 : 2 }}
      >
        {message && (
          <FaroText
            variant="bodyM"
            sx={{
              mr: "52px",
              mb: !!image || !!action ? 2 : 0,
              whiteSpace: "pre-line",
            }}
          >
            {message}
          </FaroText>
        )}

        <Stack
          direction="row"
          justifyContent="end"
          // Keep the distance between the title or message and the image/action always 16px
          sx={{ mt: !isEmpty && !message ? 1 : 0, mr: 2 }}
        >
          {image && (
            <SvgIcon
              component={image}
              inheritViewBox
              sx={{ ml: 0, mr: "auto", mt: "auto", mb: 0 }}
            />
          )}

          {action && (
            <FaroButton
              aria-label={action.label}
              variant="ghost"
              onClick={() => {
                action.onClicked();
                onClose();
              }}
            >
              {action.label}
            </FaroButton>
          )}
        </Stack>
      </Stack>
    </ToastContainer>
  );
});
