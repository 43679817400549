const float PI = 3.141592654;

// Viewport X,Y dimensions in pixels
uniform highp vec4 uViewport;
// Texture of this tile
uniform sampler2D uTile;
// The 2D bounding box of the texture coordinates for this tile as vec4 (i.e {min.x, min.y, max.x, max.y})
uniform vec4 uTileRect;
// The inverse of the projection-view-model matrix
uniform mat4 uInvPVMMatrix;
// The overall opacity of the tile
uniform float uOpacity;
// The polar angle below which every fragment is discarded, in radians
uniform float uMinTheta;

void main() {
    // Get x and y coordinates normalized between [-1, 1]
    // depth 0.9 is used because 1.0 fails on android, -1.0 fails on VR, 0.999 gives artifact on ios, 0.9 works everywhere
    vec4 v = vec4((gl_FragCoord.xy - uViewport.xy) * 2.0 / uViewport.zw - 1.0, 0.9, 1.0);
    
    // From ndc to 3d world
    v = uInvPVMMatrix * v;
    v /= v.w;
    
    // Transform cartesian coordinates to spherical ones.
    // Theta is the polar angle (i.e. elevation wrt the XY plane) in the interval [-PI/2, PI/2]
    float theta = atan(v.z, sqrt(v.x * v.x + v.y * v.y));
    if (theta < uMinTheta) {
        discard;
    }
    // Phi is the azimuth angle, in the interval [-PI, PI]
    float phi = atan(v.y, v.x);
    // Map angles to texture coordinates.
    // Pixel 0, 0 maps to angle 360°, 90°
    // Pixel W, H maps to angle 0°, -90°
    float tx = 1.0 - mod(phi / (2.0 * PI) + 1.0, 1.0);
    float ty = theta / PI + 0.5;

    tx = (tx - uTileRect.x) / (uTileRect.z - uTileRect.x);
    ty = (ty - uTileRect.y) / (uTileRect.w - uTileRect.y);

    gl_FragColor = texture2D(uTile, vec2(tx, ty));
    gl_FragColor.a = uOpacity;
}
