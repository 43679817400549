import { Box3 } from "three";
import { LodTreeNode, NodeState } from "../Lod/LodTree";

/**
 * Potree implementation of a LOD tree node
 */
export class PotreeNode implements LodTreeNode {
	private pId = 0;
	private box = new Box3();
	private pParentId?: number;
	private pChildren = new Array<PotreeNode>();
	private pNumPoints = 0;
	private pPointDensity = 0;
	private pDepth = 0;
	private pUuid = "";

	name: string = "";
	isProxyNode: boolean = false;
	hierarchyOffset: bigint = BigInt(0);
	hierarchySize: bigint = BigInt(0);
	byteOffset: bigint = BigInt(0);
	byteSize: bigint = BigInt(0);

	/**
	 * Create a node of the tree
	 *
	 * @param id The id of the node
	 * @param parentId The id of the parent node
	 * @param boundingBox The bounding box of the node
	 * @param depth The node depth in the tree
	 */
	constructor(id: number, parentId: number | undefined, boundingBox: Box3, depth: number) {
		this.pId = id;
		this.pParentId = parentId;
		this.box = boundingBox;
		this.pDepth = depth;
	}

	/** @returns the unique number for this node in the three */
	get id(): number {
		return this.pId;
	}

	/** @returns the bounding box for the node */
	get boundingBox(): Box3 {
		return this.box;
	}

	/** @returns the id of the parent of this node, undefined if this node is the root */
	get parentId(): number | undefined {
		return this.pParentId;
	}

	/** @returns the children of this node, may be empty if this node is a leaf */
	get children(): PotreeNode[] {
		return this.pChildren;
	}

	/** @returns the number of points in this node */
	get numPoints(): number {
		return this.pNumPoints;
	}

	/** Set the number of points inside this node */
	set numPoints(n: number) {
		this.pNumPoints = n;
	}

	/** @returns the density of points for this node as the distance in millimeters between two consecutive points */
	get pointDensity(): number {
		return this.pPointDensity;
	}

	/** Set the point density of this node */
	set pointDensity(v: number) {
		this.pPointDensity = v;
	}

	/** @returns the depth of this node in the tree */
	get depth(): number {
		return this.pDepth;
	}

	/** @returns the unique id of this node, implementation defined */
	get uuid(): string {
		return this.pUuid;
	}

	/** @inheritdoc */
	public pixelsPerPoint: number = 1;

	/** @inheritdoc */
	public state = NodeState.NotInUse;
}
