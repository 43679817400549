// Textures for the three states
uniform sampler2D map;
uniform sampler2D hoveredMap;
uniform sampler2D selectedMap;

// Min alpha threshold to render a fragment
uniform float alphaTest;

// Distance at which fading starts
uniform float fadeOffDistance;

// UV coordinates for this fragment
in vec2 vUv;

// Input data from the vertex shader
flat in int vInstanceId;
flat in int vHovered;
flat in int vSelected;
in float vHidden;
in float vCamDistance;

void main() {
	if (vHidden > 0.0) {
		discard;
		return;
	}

	// Use the correct texture based on the placeholder state
	if (vInstanceId == vSelected) {
		gl_FragColor = texture2D( selectedMap, vUv );
	} else if (vInstanceId == vHovered) {
		gl_FragColor = texture2D( hoveredMap, vUv );
	} else {
		gl_FragColor = texture2D( map, vUv );
	}

	// Discard all fragments below the alpha threshold
	if ( gl_FragColor.a < alphaTest ) discard;

	// Fade all fragments far away
	gl_FragColor.a = min(gl_FragColor.a, clamp(1. - vCamDistance / fadeOffDistance, 0., 1.));
}
