import {
  Button,
  ButtonProps,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { blend, decomposeColor } from "@mui/system";
import { ReactNode, forwardRef } from "react";
import { blue, cyan, neutral, red } from "../colors";

/** All different style variants for the FARO Button */
export enum FaroButtonVariants {
  /** Highlights the main action on the page. Each page can only have one primary button.  */
  primary = "primary",

  /** For less prominent actions. Can be used in isolation or as part of a button group.  */
  secondary = "secondary",

  /** For the least pronounced actions or as “Cancel” button next to a Primary Button. */
  ghost = "ghost",

  /** Represents main actions that are destructive and irreversible, such as “Delete Account”. */
  destructive = "destructive",

  /** Used when the destructive action is part of a busy interface, and/or is not the main action. */
  destructiveGhost = "destructiveGhost",
}

export enum FaroButtonSizes {
  /** Only for ghost button, for extremely crowded environments, such as a tight tree list */
  xs = "xs",

  /** Used when space does not allow for any other size. Any other use case is highly discouraged. */
  s = "s",

  /** Standard size for web applications. It should always be the default choice. */
  m = "m",

  /** Used for mobile applications, touch interfaces as well as our devices’ firmware UI.  */
  l = "l",
}

export type FaroButtonProps = Omit<
  ButtonProps,
  "variant" | "size" | "color" | "startIcon"
> & {
  /**
   * Style variant for this button
   *
   * @default "primary"
   */
  variant?: `${FaroButtonVariants}`;

  /**
   * Whether to enable dark styles
   *
   * @default false
   */
  dark?: boolean;

  /**
   * Size variant for this button
   *
   * @default "M"
   */
  size?: `${FaroButtonSizes}`;

  /**
   * Display a loading indicator inside the button and disable the button.
   *
   * Should be used when the button triggers an action that takes a while to complete, e.g. a network request.
   *
   * @default false
   */
  isLoading?: boolean;

  /**
   * Define to true to disable this button
   *
   * @default false
   */
  disabled?: boolean;

  /** Optional small icon inside the button */
  icon?: ReactNode;

  /** Content of the button */
  children: ReactNode;

  /** Extra style for this button */
  sx?: ButtonProps["sx"];
};

/** @returns the properly styled Button following the FARO Design System */
export const FaroButton = forwardRef<HTMLButtonElement, FaroButtonProps>(
  function FaroButton(
    {
      variant = FaroButtonVariants.primary,
      dark = false,
      size = FaroButtonSizes.m,
      icon,
      isLoading = false,
      children,
      disabled,
      sx,
      ...rest
    }: FaroButtonProps,
    ref,
  ): JSX.Element {
    const sizeStyles = SIZE_STYLE_MAP[size];
    const styleOverrides = computeStyleOverrides(variant, dark);

    return (
      <Button
        disabled={isLoading || disabled}
        ref={ref}
        sx={[
          sizeStyles,
          styleOverrides,
          {
            // required to make the icon appear in line with the text
            lineHeight: 1,
            "&.MuiButton-root": {
              boxShadow: "none",
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        disableRipple
        {...rest}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {isLoading && (
            <CircularProgress
              size="1em"
              sx={{
                color: "inherit",
                position: "relative",
              }}
            />
          )}
          {icon}
          {children}
        </Stack>
      </Button>
    );
  },
);

interface ButtonColors {
  /** text color */
  text: string;

  /** color of the border */
  border: string;

  /* default background color*/
  idle: string;

  /* background colors for the different states. If transparent, they will be added on top of the idle color */
  hover: string;
  pressed: string;
}

const COLOR_MAP_LIGHT: Record<FaroButtonVariants, ButtonColors> = {
  [FaroButtonVariants.primary]: {
    text: neutral[0],

    border: blue[600],

    idle: blue[500],
    hover: blue[600],
    pressed: blue[700],
  },
  [FaroButtonVariants.secondary]: {
    text: blue[500],

    border: blue[500],

    idle: neutral[0],
    hover: `${neutral[500]}26`,
    pressed: `${neutral[500]}40`,
  },
  [FaroButtonVariants.ghost]: {
    text: blue[500],

    border: "transparent",

    idle: "transparent",
    hover: `${neutral[500]}26`,
    pressed: `${neutral[500]}40`,
  },
  [FaroButtonVariants.destructive]: {
    text: neutral[0],

    border: red[600],

    idle: red[500],
    hover: red[600],
    pressed: red[700],
  },
  [FaroButtonVariants.destructiveGhost]: {
    text: red[500],

    border: "transparent",

    idle: "transparent",
    hover: `${neutral[500]}26`,
    pressed: `${neutral[500]}40`,
  },
};

// Map each FARO button variants to the color to use from the MUI FaroTheme
const COLOR_MAP_DARK: Record<FaroButtonVariants, ButtonColors> = {
  [FaroButtonVariants.primary]: {
    text: neutral[950],

    border: cyan[300],

    idle: cyan[400],
    hover: cyan[300],
    pressed: cyan[200],
  },
  [FaroButtonVariants.secondary]: {
    text: cyan[400],

    border: cyan[400],

    idle: neutral[999],
    hover: `${neutral[500]}26`,
    pressed: `${neutral[500]}40`,
  },
  [FaroButtonVariants.ghost]: {
    text: cyan[400],

    border: "transparent",

    idle: "transparent",
    hover: `${neutral[500]}26`,
    pressed: `${neutral[500]}40`,
  },
  [FaroButtonVariants.destructive]: {
    text: neutral[999],

    border: red[200],

    idle: red[300],
    hover: red[200],
    pressed: red[500],
  },
  [FaroButtonVariants.destructiveGhost]: {
    text: red[300],

    border: "transparent",

    idle: "transparent",
    hover: `${neutral[500]}26`,
    pressed: `${neutral[500]}40`,
  },
};

/**
 * @returns Style overrides on top of MuiButton style required for the FARO Design
 * @param variant the button variant to compute the overrides for
 * @param dark whether to use dark colors
 */
function computeStyleOverrides(
  variant: `${FaroButtonVariants}`,
  dark: boolean,
): SxProps<Theme> | undefined {
  const colors = dark ? COLOR_MAP_DARK[variant] : COLOR_MAP_LIGHT[variant];

  const focusedBorderColor = dark ? neutral[0] : neutral[999];
  const focusedInnerShadowColor = dark ? neutral[950] : neutral[0];

  // Only the secondary button has its border visible in all states
  const borderIdle = `1px solid ${colors.border}`;
  const borderNonIdle =
    variant === "secondary" ? borderIdle : "1px solid transparent";

  return {
    color: colors.text,
    border: borderIdle,
    backgroundColor: colors.idle,

    "&:after": {
      background: "red",
    },

    "&:hover": {
      // Some hover colors are defined as transparent overlays. This mixes them with the idle background.
      backgroundColor: blendColors(colors.idle, colors.hover),
      border: borderNonIdle,
    },

    "&:active": {
      // Some hover colors are defined as transparent overlays. This mixes them with the idle background.
      backgroundColor: blendColors(colors.idle, colors.pressed),
      border: borderNonIdle,
    },

    "&.MuiButton-root:focus-visible": {
      outline: `2px solid ${focusedBorderColor}`,
      boxShadow: `inset 0px 0px 0px 1.5px ${focusedInnerShadowColor}`,
    },

    "&.Mui-disabled": {
      opacity: 0.5,
      color: colors.text,
      border: borderNonIdle,
    },

    transition: "none",
  };
}

/**
 * @returns a blended color that adds the overlayColor on top of the baseColor. The result is slightly different to a css color-mix().
 * @param baseColor color in the background
 * @param overlayColor color on top
 */
function blendColors(baseColor: string, overlayColor: string): string {
  if (baseColor === "transparent") {
    // The Mui helper doesn't deal with transparency correctly
    return overlayColor;
  }

  return blend(
    baseColor,
    overlayColor,
    decomposeColor(overlayColor).values[3] ?? 1,
  );
}

// Specific style for each button size as defined in the FARO Design System
const SIZE_STYLE_MAP: Record<FaroButtonSizes, SxProps<Theme>> = {
  [FaroButtonSizes.xs]: {
    fontSize: "0.625rem",
    height: "1.125rem",
    px: 0.5,
    py: 0.25,
    "&.MuiButton-root .MuiSvgIcon-root, &.MuiButton-root .MuiIcon-root": {
      fontSize: "0.625rem",
    },
  },
  [FaroButtonSizes.s]: {
    fontSize: "0.75rem",
    minWidth: "4.5rem",
    height: "1.875rem",
    px: 1.25,
    pb: 0.875,
    pt: 0.75,
    "&.MuiButton-root .MuiSvgIcon-root, &.MuiButton-root .MuiIcon-root": {
      fontSize: "0.75rem",
    },
  },
  [FaroButtonSizes.m]: {
    fontSize: "0.875rem",
    minWidth: "5.5rem",
    height: "2.25rem",
    px: 2,
    pb: 1.125,
    pt: 1,
    "&.MuiButton-root .MuiSvgIcon-root, &.MuiButton-root .MuiIcon-root": {
      fontSize: "0.875rem",
    },
  },
  [FaroButtonSizes.l]: {
    fontSize: "1rem",
    minWidth: "6.25rem",
    height: "2.70rem",
    px: 2.27,
    py: 1.135,
    "&.MuiButton-root .MuiSvgIcon-root, &.MuiButton-root .MuiIcon-root": {
      fontSize: "1rem",
    },
  },
};
