import { useEffect, useState } from "react";

type UseExternalScriptReturn = {
  /** True if the script is finished loading */
  isDoneLoading: boolean;
};

/**
 * Loading an external script
 *
 * @param scriptUrl The URL of the script to load
 * @returns the state of the loading process
 */
export function useExternalScript(scriptUrl?: string): UseExternalScriptReturn {
  const [isDoneLoading, setIsDoneLoading] = useState(false);

  useEffect(() => {
    if (scriptUrl) {
      const script = document.createElement("script");

      script.src = scriptUrl;
      script.async = false;

      script.addEventListener("load", () => setIsDoneLoading(true));

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptUrl]);

  return { isDoneLoading };
}
