import { IUniform } from "three";

/**
 * Function to initialize a typed uniform for a three shader material
 *
 * @param initialVal The initial value of the uniform
 * @returns A typed IUniform
 */
export function makeUniform<T>(initialVal: T): IUniform<T> {
	return { value: initialVal };
}

/**
 * Initialize a threejs typed uniform with a null
 *
 * @returns A typed IUniform
 */
export function makeOptionalUniform<T>(): IUniform<T | null> {
	return { value: null };
}
