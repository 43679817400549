import { IPose, IQuat, IVec3 } from "@faro-lotv/ielement-types";
import {
  Matrix4,
  Quaternion,
  Vector3,
  Vector3Tuple,
  Vector4Tuple,
} from "./math";

export const Z_TO_Y_UP_QUAT = Object.freeze(
  new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2),
);

export const Z_TO_Y_UP_MATRIX = Object.freeze(
  new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), -Math.PI / 2),
);

/**
 * Holds a ThreeJS transform in a right-handed coordinate system.
 */
interface ThreeTransform {
  position?: Vector3;
  quaternion?: Quaternion;
  scale?: Vector3;
}

/**
 * Holds a ThreeJS transform in a right-handed coordinate system.
 */
interface ThreeTransformTuple {
  position?: Vector3Tuple;
  quaternion?: Vector4Tuple;
  scale?: Vector3Tuple;
}

/**
 * @param transform A transform in a ThreeJs coordinate system
 * @returns An IElement compatible pose
 */
export function convertThreeToIElementTransform(
  transform: ThreeTransform,
): IPose {
  return {
    pos: transform.position
      ? {
          x: transform.position.x,
          y: transform.position.y,
          z: transform.position.z,
        }
      : null,
    rot: transform.quaternion
      ? {
          x: transform.quaternion.x,
          y: transform.quaternion.y,
          z: transform.quaternion.z,
          w: transform.quaternion.w,
        }
      : null,
    scale: transform.scale
      ? {
          x: transform.scale.x,
          y: transform.scale.y,
          z: transform.scale.z,
        }
      : null,
    gps: null,
    isWorldRot: false,
  };
}

/**
 * Convert from left-handed quaternion to right-handed quaternion
 *
 * @param rot The quaternion referred to a left-handed coordinate system
 * @param leftHanded whether this iElement pose is to be interpreted in a left-handed CS (Default: true)
 * @returns The input quaternion referred to a right-handed coordinate system
 */
export function convertIElementToThreeRotation(
  rot: IQuat | null | undefined,
  leftHanded: boolean = true,
): Vector4Tuple {
  if (!rot) return [0, 0, 0, 1];
  const flip = leftHanded ? -1 : 1;
  return [rot.x, rot.y, flip * rot.z, flip * rot.w];
}

/**
 * Convert from left-handed position to right-handed position
 *
 * @param pos The position referred to a left-handed coordinate system
 * @param leftHanded whether this iElement pose is to be interpreted in a left-handed CS (Default: true)
 * @returns The input position referred to a right-handed coordinate system
 */
export function convertIElementToThreePosition(
  pos: IVec3 | null | undefined,
  leftHanded: boolean = true,
): Vector3Tuple {
  if (!pos) return [0, 0, 0];
  const flip = leftHanded ? -1 : 1;
  return [pos.x, pos.y, flip * pos.z];
}

/**
 * Converts from left-handed IElement into right-handed ThreeJS coordinates coordinates.
 *
 * @param pose The pose to convert into the three js coordinate system
 * @param leftHanded whether this iElement pose is to be interpreted in a left-handed CS (Default: true)
 * @returns A ThreeJs compatible transform
 */
export function convertIElementToThreeTransform(
  pose: IPose | null | undefined,
  leftHanded: boolean = true,
): Required<ThreeTransformTuple> {
  return {
    position: convertIElementToThreePosition(pose?.pos, leftHanded),
    quaternion: convertIElementToThreeRotation(pose?.rot, leftHanded),
    scale: [pose?.scale?.x ?? 1, pose?.scale?.y ?? 1, pose?.scale?.z ?? 1],
  };
}

const X_AXIS = new Vector3();
const Y_AXIS = new Vector3();
const Z_AXIS = new Vector3();
const CROSS_PROD = new Vector3();

/**
 * Returns whether the given pose is left-handed or right-handed.
 * This is determined by checking whether the cross product of the X and Y axes
 * is co-directional with the Z axis (right handed) or not (left handed). This
 * computation is valid because we assume that all scale values are always positive.
 * This constraint will be also documented in a confluence page.
 *
 * @param pose The pose to check
 * @returns true if the pose is left-handed, false if it is right-handed
 */
export function isPoseLeftHanded(pose: Matrix4): boolean {
  const e = pose.elements;
  X_AXIS.set(e[0], e[1], e[2]);
  Y_AXIS.set(e[4], e[5], e[6]);
  Z_AXIS.set(e[8], e[9], e[10]);
  CROSS_PROD.crossVectors(X_AXIS, Y_AXIS);
  return CROSS_PROD.dot(Z_AXIS) < 0;
}

const TEMP_VECTOR3 = new Vector3();
const TEMP_MATRIX4 = new Matrix4();

/**
 * Decompose a 4x4 Matrix.
 * This function extends the built-in decompose function defined in Threejs, that has
 * a huge limitation: if the matrix contains a mirroring (the scale of an odd number of axis is negative),
 * then the scale will always have the negative value set in the x component.
 * In the viewer, we expect to have the mirroring always on the z axis, due to the change of reference system
 * from the Project API.
 * This new function allows to choose on which axis the mirroring should be applied.
 *
 * @param matrix The matrix to decompose
 * @param mirroring A flag specifying which axis should contain the flipping, if present
 * @returns The position, rotation and scale components of the matrix
 */
export function decomposeMatrix(
  matrix: Matrix4,
  mirroring: "x" | "y" | "z",
): {
  position: Vector3;
  quaternion: Quaternion;
  scale: Vector3;
} {
  const position = new Vector3();
  const quaternion = new Quaternion();
  const scale = new Vector3();

  const te = matrix.elements;

  let sx = TEMP_VECTOR3.set(te[0], te[1], te[2]).length();
  let sy = TEMP_VECTOR3.set(te[4], te[5], te[6]).length();
  let sz = TEMP_VECTOR3.set(te[8], te[9], te[10]).length();

  // if determine is negative, we need to invert one scale
  const det = matrix.determinant();
  if (det < 0) {
    switch (mirroring) {
      case "x": {
        sx = -sx;
        break;
      }
      case "y": {
        sy = -sy;
        break;
      }
      case "z": {
        sz = -sz;
        break;
      }
    }
  }

  position.x = te[12];
  position.y = te[13];
  position.z = te[14];

  // scale the rotation part
  TEMP_MATRIX4.copy(matrix);

  const invSX = 1 / sx;
  const invSY = 1 / sy;
  const invSZ = 1 / sz;

  TEMP_MATRIX4.elements[0] *= invSX;
  TEMP_MATRIX4.elements[1] *= invSX;
  TEMP_MATRIX4.elements[2] *= invSX;

  TEMP_MATRIX4.elements[4] *= invSY;
  TEMP_MATRIX4.elements[5] *= invSY;
  TEMP_MATRIX4.elements[6] *= invSY;

  TEMP_MATRIX4.elements[8] *= invSZ;
  TEMP_MATRIX4.elements[9] *= invSZ;
  TEMP_MATRIX4.elements[10] *= invSZ;

  quaternion.setFromRotationMatrix(TEMP_MATRIX4);

  scale.x = sx;
  scale.y = sy;
  scale.z = sz;

  return {
    position,
    quaternion,
    scale,
  };
}
