import { GUID } from "@faro-lotv/foundation";
import { AnnotationStatus } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * @param status string to check if it's part of the AnnotationStatus options
 * @returns true if the passed string is a AnnotationStatus option
 */
export function isAnnotationStatus(
  status?: string | null,
): status is AnnotationStatus {
  return !!status && Object.values<string>(AnnotationStatus).includes(status);
}

/**
 * Change the status of a Markup Element
 */
export interface MutationDropDownMarkupField extends BaseMutation {
  type: MutationTypes.MutationDropDownMarkupField;

  /** The new status of the markup */
  value?: `${AnnotationStatus}`;
}

/**
 * @returns a mutation to change an status of a Markup element
 * @param elementId id of the DropDownMarkupField element
 * @param value new state of the element
 */
export function createMutationDropDownMarkupField(
  elementId: GUID,
  value?: `${AnnotationStatus}`,
): MutationDropDownMarkupField {
  return {
    ...createBaseMutation(MutationTypes.MutationDropDownMarkupField, elementId),
    value,
  };
}
