precision highp float;

layout (location = 2) in int drawID;

uniform sampler2D poseTexture;

#include <clipping_planes_pars_vertex>

// On Intel GPUs, GLSL shaders are translated to directX's HLSL 
// by a non-optimal driver, to say the least. Passing 'flat int' 
// variables between shaders is allowed, it compiles, but the 
// performances are completely unacceptable. Hence we pass a simple float.
out float fragDrawID;

// To avoid Z-fighting, an unique depth offset is added to each CAD part. This depth offset
// is chosen as a multiple of three microns, and it is picked in a pseudorandom sequence of 
// 20 different multiples.
const float k_MinDepthIncrement = 0.00003;
const int[20] k_Offsets = int[20](18, 5, 1, 8, 6, 7, 2, 17, 11, 14, 12, 3, 10, 4, 19, 13, 20, 16, 9, 15);

void main() 
{
    ivec2 tsize = textureSize(poseTexture, 0);
    int i = drawID * 4;
    int row = i / tsize.x;
    int col = i - row * tsize.x;
    vec4 X = texelFetch(poseTexture, ivec2(col, row), 0);
    vec4 Y = texelFetch(poseTexture, ivec2(col + 1, row), 0);
    vec4 Z = texelFetch(poseTexture, ivec2(col + 2, row), 0);
    vec4 T = texelFetch(poseTexture, ivec2(col + 3, row), 0);

    if(T.w == 0.0) {
        // If we enter here, the CAD part is not visible.
        // Discard statement is not available in webgl vertex shaders,
        // hence the vertex is moved behind the camera where will be
        // culled out by the GPU.
        gl_Position = vec4(0.0, 0.0, 10.0, 1.0);
        return;
    }
    
    mat4 localMatrix = mat4(X, Y, Z, T);

    mat4 MV = modelViewMatrix * localMatrix;
    vec4 mvPosition = MV * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;

    fragDrawID = float(drawID);

    // offsetting the depth to avoid Z-fighting
    gl_Position.z += k_MinDepthIncrement * float(k_Offsets[drawID % 20]);

    #include <clipping_planes_vertex>
}

