import { ShaderMaterial, Texture, Vector4, Matrix4, Box2, Camera, WebGLRenderer } from "three";
import { makeOptionalUniform, makeUniform } from "./Uniforms";
import frag from "../Shaders/PanoTile.frag";
import vert from "../Shaders/PanoTile.vert";

/**
 * A material to render a tile of a pano image, identified by a texture and the rect covered in uv coordinates
 */
export class PanoTileMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		/** Tile texture */
		uTile: makeOptionalUniform<Texture>(),
		/** Tile rect */
		uTileRect: makeUniform(new Vector4()),
		/** Current viewport */
		uViewport: makeUniform(new Vector4()),
		/** Inverse PVM Matrix of the pano */
		uInvPVMMatrix: makeUniform(new Matrix4()),
		/** Opacity */
		uOpacity: makeUniform(1),
		/** The minimum value theta value that will be rendered */
		uMinTheta: makeUniform(-Math.PI / 2),
	};

	override name = "PanoTileMaterial";

	/**
	 * @returns the minimum angle that can be displayed by this material
	 * @defaultValue -Math.PI / 2 (-90°) will not cut anything
	 */
	public get minTheta(): number {
		return this.uniforms.uMinTheta.value;
	}
	/**
	 * sets the minimum angle that can be displayed by this material
	 *
	 * @defaultValue -Pi/2 (straight down, render everything)
	 */
	public set minTheta(value: number) {
		this.uniforms.uMinTheta.value = value;
	}
	/**
	 * Construct a material to draw a single pano tile
	 *
	 * @param tile A structure describing this tile
	 * @param tile.texture The tile texture
	 * @param tile.rect The rect covered in uv coordinates (col, row, width, height)
	 */
	constructor(tile?: { texture: Texture; rect: Box2 }) {
		super();
		if (tile) {
			this.setTile(tile.texture, tile.rect);
		}
	}

	/**
	 * Add a tile to the set
	 *
	 * @param texture The texture of the tile
	 * @param rect The rect this tile cover of the entire pano image in uv coordinates (col, row, width, height)
	 * Eg: (0, 0.5, 0.5, 1) for the upper right quarter
	 */
	setTile(texture: Texture, rect: Box2): void {
		this.uniforms.uTile.value = texture;
		this.uniforms.uTileRect.value = new Vector4(rect.min.x, rect.min.y, rect.max.x, rect.max.y);
		this.uniformsNeedUpdate = true;
	}

	/**
	 * Update uniforms needed for this material that may change at every render
	 *
	 * @param worldModelMatrix the pano world model matrix
	 * @param camera the current active camera
	 * @param renderer the current renderer
	 */
	update(worldModelMatrix: Matrix4, camera: Camera, renderer: WebGLRenderer): void {
		const vMatrix = camera.matrixWorldInverse.clone();
		vMatrix.setPosition(0, 0, 0);

		const mMatrix = worldModelMatrix.clone();
		mMatrix.setPosition(0, 0, 0);

		const mvMatrix = new Matrix4();
		mvMatrix.multiplyMatrices(vMatrix, mMatrix);
		mvMatrix.setPosition(0, 0, 0);

		const pvmMatrix = new Matrix4();
		pvmMatrix.multiplyMatrices(camera.projectionMatrix, mvMatrix);
		this.uniforms.uInvPVMMatrix.value = pvmMatrix.clone().invert();

		const renderTarget = renderer.getRenderTarget();
		if (renderTarget) {
			/** Custom framebuffer */
			this.uniforms.uViewport.value = renderTarget.viewport;
		} else {
			/** Default framebuffer */
			this.uniforms.uViewport.value = renderer
				.getViewport(new Vector4())
				.multiplyScalar(renderer.getPixelRatio());
		}

		this.uniforms.uOpacity.value = this.opacity;
		this.uniformsNeedUpdate = true;
	}
}
