import { useLayoutEffect, useMemo } from "react";
import { Texture } from "three";

const TEXTURE_CACHE = new Map<string, Texture>();
const TEXTURE_CACHE_REFCOUNT = new Map<string, number>();

/**
 * Returns a cached Texture object without suspending and starts loading it if not started yet.
 *
 * @param cacheKey The key to use to determine equal textures
 * @param loader function that starts loading of the texture
 * @returns the cached Texture
 */
export function useCachedTexture(
  cacheKey: string,
  loader: () => Texture,
): Texture {
  const texture: Texture = useMemo(() => {
    const cached = TEXTURE_CACHE.get(cacheKey);
    if (cached) return cached;

    const tex = loader();
    TEXTURE_CACHE.set(cacheKey, tex);
    return tex;
  }, [cacheKey, loader]);

  // Dispose this texture when the object is unmounted and no references are left
  useLayoutEffect(() => {
    TEXTURE_CACHE_REFCOUNT.set(
      cacheKey,
      (TEXTURE_CACHE_REFCOUNT.get(cacheKey) ?? 0) + 1,
    );

    return () => {
      const refCount = (TEXTURE_CACHE_REFCOUNT.get(cacheKey) ?? 1) - 1;
      TEXTURE_CACHE_REFCOUNT.set(cacheKey, refCount);
      if (refCount <= 0) {
        TEXTURE_CACHE.delete(cacheKey);
        texture.dispose();
      }
    };
  }, [texture, cacheKey]);

  return texture;
}
