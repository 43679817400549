import { useFrame } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import { DirectionalLight, Vector3 } from "three";

type Props = {
  /**
   * Ambiental light intensity from zero to one.
   * 1.0 - ambientIntensity is the intensity of the directional light.
   */
  ambientIntensity?: number;
};

/** @returns lights that illuminate the scene uniformly based on the current camera direction */
export function UniformLights({ ambientIntensity = 0.4 }: Props): JSX.Element {
  const lightRef = useRef<DirectionalLight>(null);

  const viewDirection = useMemo(() => new Vector3(), []);

  useFrame(({ camera }) => {
    if (lightRef.current) {
      const dir = camera.getWorldDirection(viewDirection);
      lightRef.current.position.set(-dir.x, -dir.y, -dir.z);
    }
  });

  return (
    <>
      <directionalLight intensity={1 + ambientIntensity} ref={lightRef} />
      <ambientLight intensity={1 + ambientIntensity} />
    </>
  );
}
