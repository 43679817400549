import { Box3, Frustum, Vector3 } from "three";
import { memberWithPrivateData } from "../Utils/MemoryUtils";

export enum FrustumBoxCheck {
	/** The box is inside the frustum */
	BoxInside = "BoxInside",
	/** The box is outside the frustum */
	BoxOutside = "BoxOutside",
	/** The box intersects the frustum */
	BoxIntersects = "BoxIntersects",
}

/**
 * A slightly more complete frustum-box intersection algorithm than the one shipped with threejs.
 *
 * @param frustum The frustum
 * @param box The 3D box
 * @returns How is box with respect to the frustum: inside, outside, or intersecting
 */
export const frustumIntersectsBox = memberWithPrivateData(() => {
	const vMin = new Vector3();
	const vMax = new Vector3();

	return (frustum: Frustum, box: Box3): FrustumBoxCheck => {
		const { planes } = frustum;

		let ret = FrustumBoxCheck.BoxInside;

		// This is a standard frustum-box intersection algorithm. For each of the six planes,
		// the farthest and the closest bounding box point is stored in vMax and vMin respectively.
		// Then, if the farthest point is below the current plane, it means the whole box is below
		// the plane and therefore 'BoxOutside' is immediately returned. Else, if the farthest point
		// is above the plane but the closest is below, the plane (and therefore the frustum) is
		// intersecting the box. The other planes are still checked because it can be that another
		// plane "sees" the box outside. Otherwise, 'BoxIntersects' is returned. Finally, if farthest
		// and closest point are 'above' all of the six planes, the box is inside the frustum.
		for (let i = 0; i < 6; i++) {
			const plane = planes[i];

			if (plane.normal.x > 0) {
				vMin.x = box.min.x;
				vMax.x = box.max.x;
			} else {
				vMin.x = box.max.x;
				vMax.x = box.min.x;
			}
			if (plane.normal.y > 0) {
				vMin.y = box.min.y;
				vMax.y = box.max.y;
			} else {
				vMin.y = box.max.y;
				vMax.y = box.min.y;
			}
			if (plane.normal.z > 0) {
				vMin.z = box.min.z;
				vMax.z = box.max.z;
			} else {
				vMin.z = box.max.z;
				vMax.z = box.min.z;
			}
			if (plane.distanceToPoint(vMax) < 0) {
				return FrustumBoxCheck.BoxOutside;
			}
			if (plane.distanceToPoint(vMin) <= 0) {
				ret = FrustumBoxCheck.BoxIntersects;
			}
		}
		return ret;
	};
});
