import { InvalidConfigurationError } from "@faro-lotv/foundation";
import {
	Camera,
	Color,
	DepthFormat,
	DepthTexture,
	FloatType,
	NearestFilter,
	OrthographicCamera,
	PerspectiveCamera,
	RGBAFormat,
	ShaderMaterial,
	Texture,
	Vector2,
	WebGLRenderer,
	WebGLRenderTarget,
} from "three";
import { FullScreenQuad, Pass } from "three/examples/jsm/postprocessing/Pass.js";
import { MinifyMaterial } from "../Materials/MinifyMaterial";
import { GapFillOptimizedFor, MipGapFillMaterial } from "../Materials/MipGapFillMaterial";
import { makeUniform } from "../Materials/Uniforms";
import frag from "../Shaders/MipRender.frag";
import vert from "../Shaders/TexturedQuad.vert";
import { CameraMode } from "../Utils/CameraUtils";
import { degToRad } from "../Utils/Math";

// here we assume the max hole size to be gapfillable is 7 cm
const MAX_HOLE_TO_FILL = 0.07;
const DEPTH_BIAS_MULTIPLIER = 19.2;

/**
 * This shader is used to render the intermediate subsampled FBOs that the mip gap fill
 * algorithm produces. It takes care of interpreting correctly the four-bits integer that
 * is written in the alpha channel of the color texture. It has a 'showPlanes' uniform
 * boolean that allows to switch between rendering the RGB colors or rendering a color
 * mapping of the surface layout derived from the information in the alpha channel.
 */
class MipRenderMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uColorTexture: makeUniform<Texture | null>(null),
		uDepthTexture: makeUniform<Texture | null>(null),
		// whether to render colors or plane layouts
		showPlanes: makeUniform(false),
	};
	constructor() {
		super();
	}
}

/**
 * This postprocessing effect performs gap filling taking as input an FBO where point clouds have been rendered.
 * It works by creating four downsampled versions of the input FBO, each version is downsampled by a factor of two
 * with respect to the former. Therefore four auxiliary FBOs are created from the input FBO: one with a pixel every 2x2,
 * another with a pixel every 4x4, another every 8x8, another every 16x16.
 * These four downsampled FBOs are therefore fed to the final rendering step, in which convenient heuristics are exploited to fill the gaps.
 */
export class MipGapFillPass extends Pass {
	#camera: Camera;
	#minifyMaterialPersp: MinifyMaterial;
	#minifyMaterialOrtho: MinifyMaterial;
	#currMinifyMaterial: MinifyMaterial;
	#mipGapFillMaterial: MipGapFillMaterial;
	#mipRender: MipRenderMaterial;
	#fsQuad: FullScreenQuad;
	#fbo2x2: WebGLRenderTarget;
	#fbo4x4: WebGLRenderTarget;
	#fbo8x8: WebGLRenderTarget;
	#fbo16x16: WebGLRenderTarget;
	#screenResolution: Vector2;
	#showMinified = false;
	#subsampleLevel = 2;
	debugColors = false;

	/**
	 * Constructor
	 *
	 * @param camera The camera from which the scene is rendered. Can be perspective or orthographic.
	 * @param o The optimization profile this effect should target.
	 */
	constructor(camera: Camera, o: GapFillOptimizedFor = GapFillOptimizedFor.GapSize) {
		super();
		this.#camera = camera;
		const cm = camera instanceof PerspectiveCamera ? CameraMode.Perspective : CameraMode.Orthographic;
		this.#minifyMaterialPersp = new MinifyMaterial(CameraMode.Perspective);
		this.#minifyMaterialOrtho = new MinifyMaterial(CameraMode.Orthographic);
		this.#currMinifyMaterial =
			cm === CameraMode.Perspective ? this.#minifyMaterialPersp : this.#minifyMaterialOrtho;
		this.#mipGapFillMaterial = new MipGapFillMaterial(cm, o);
		this.#mipRender = new MipRenderMaterial();
		this.#fsQuad = new FullScreenQuad(this.#currMinifyMaterial);
		this.#screenResolution = new Vector2(64, 64);
		this.#fbo2x2 = this.#createAuxFbo(2);
		this.#fbo4x4 = this.#createAuxFbo(4);
		this.#fbo8x8 = this.#createAuxFbo(8);
		this.#fbo16x16 = this.#createAuxFbo(16);
		this.clear = true;
	}

	/**
	 *
	 * @param cm The camera projection mode the gap fill shader needs to target.
	 * @param o The optimization profile the gap fill shader needs to account for.
	 */
	#recompileShaderOnNeed(cm: CameraMode, o: GapFillOptimizedFor): void {
		if (this.#mipGapFillMaterial.cameraMode !== cm || this.#mipGapFillMaterial.optimizedFor !== o) {
			this.#mipGapFillMaterial.dispose();
			this.#mipGapFillMaterial = new MipGapFillMaterial(cm, o);
		}
	}

	/**
	 *
	 * @param factor The level of subsampling of the fbo with respect to the current screen resolution
	 * @returns the newly created FBO
	 */
	#createAuxFbo(factor: number): WebGLRenderTarget {
		let w = Math.floor(this.#screenResolution.x / factor);
		let h = Math.floor(this.#screenResolution.y / factor);
		if (w * factor < this.#screenResolution.x) {
			w++;
		}
		if (h * factor < this.#screenResolution.y) {
			h++;
		}
		const fbo = new WebGLRenderTarget(w, h);
		fbo.texture.format = RGBAFormat;
		fbo.texture.minFilter = NearestFilter;
		fbo.texture.magFilter = NearestFilter;
		fbo.texture.type = FloatType;
		fbo.texture.generateMipmaps = false;
		fbo.texture.name = `Aux FBO lev ${factor}`;
		fbo.stencilBuffer = false;
		fbo.depthBuffer = true;
		fbo.depthTexture = new DepthTexture(w, h);
		fbo.depthTexture.minFilter = fbo.depthTexture.magFilter = NearestFilter;
		fbo.depthTexture.format = DepthFormat;
		fbo.depthTexture.type = FloatType;
		return fbo;
	}

	/**
	 * Dispose of the internal fbo
	 *
	 * @param fbo The FBO to dispose in GPU
	 */
	#disposeFbo(fbo: WebGLRenderTarget): void {
		fbo.texture.dispose();
		fbo.depthTexture.dispose();
		fbo.dispose();
	}

	/**
	 * Dispose internal resources
	 */
	dispose(): void {
		this.#disposeFbo(this.#fbo2x2);
		this.#disposeFbo(this.#fbo4x4);
		this.#disposeFbo(this.#fbo8x8);
		this.#disposeFbo(this.#fbo16x16);
		this.#fsQuad.dispose();
		this.#mipRender.dispose();
		this.#minifyMaterialPersp.dispose();
		this.#minifyMaterialOrtho.dispose();
		this.#mipGapFillMaterial.dispose();
	}

	/**
	 * Resizes the aux FBOs if the user has resized the view.
	 *
	 * @param sz The new screen size
	 */
	#resizeFboOnNeed(sz: Vector2): void {
		if (this.#screenResolution.x !== sz.x || this.#screenResolution.y !== sz.y) {
			this.#screenResolution.copy(sz);
			this.#disposeFbo(this.#fbo2x2);
			this.#disposeFbo(this.#fbo4x4);
			this.#disposeFbo(this.#fbo8x8);
			this.#disposeFbo(this.#fbo16x16);
			this.#fbo2x2 = this.#createAuxFbo(2);
			this.#fbo4x4 = this.#createAuxFbo(4);
			this.#fbo8x8 = this.#createAuxFbo(8);
			this.#fbo16x16 = this.#createAuxFbo(16);
		}
	}

	/**
	 *
	 * @param renderer The renderer
	 * @param inFbo the input FBO
	 * @param outFbo The output FBO, half the resolution of the input FBO in both dimensions.
	 */
	#minify(renderer: WebGLRenderer, inFbo: WebGLRenderTarget, outFbo: WebGLRenderTarget): void {
		renderer.setRenderTarget(outFbo);
		this.#currMinifyMaterial.uniforms.inDepthTex.value = inFbo.depthTexture;
		this.#currMinifyMaterial.uniforms.inColorTex.value = inFbo.texture;
		this.#currMinifyMaterial.uniforms.texSizeIn.value.set(inFbo.width, inFbo.height);
		this.#currMinifyMaterial.uniforms.texSizeOut.value.set(outFbo.width, outFbo.height);
		this.#currMinifyMaterial.uniformsNeedUpdate = true;
		this.#fsQuad.render(renderer);
	}

	/**
	 *
	 * @param renderer renderer
	 * @param writeBuffer write buffer
	 * @param readBuffer read buffer
	 */
	#renderMinified(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		let outFbo = this.#fbo2x2;
		switch (this.#subsampleLevel) {
			case 2:
				this.#minify(renderer, readBuffer, this.#fbo2x2);
				break;
			case 4:
				this.#minify(renderer, readBuffer, this.#fbo2x2);
				this.#minify(renderer, this.#fbo2x2, this.#fbo4x4);
				outFbo = this.#fbo4x4;
				break;
			case 8:
				this.#minify(renderer, readBuffer, this.#fbo2x2);
				this.#minify(renderer, this.#fbo2x2, this.#fbo4x4);
				this.#minify(renderer, this.#fbo4x4, this.#fbo8x8);
				outFbo = this.#fbo8x8;
				break;
			case 16:
				this.#minify(renderer, readBuffer, this.#fbo2x2);
				this.#minify(renderer, this.#fbo2x2, this.#fbo4x4);
				this.#minify(renderer, this.#fbo4x4, this.#fbo8x8);
				this.#minify(renderer, this.#fbo8x8, this.#fbo16x16);
				outFbo = this.#fbo16x16;
				break;
		}
		this.#fsQuad.material = this.#mipRender;
		this.#mipRender.uniforms.uColorTexture.value = outFbo.texture;
		this.#mipRender.uniforms.uDepthTexture.value = outFbo.depthTexture;
		this.#mipRender.uniforms.showPlanes.value = this.debugColors;
		this.#mipRender.uniformsNeedUpdate = true;
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.#fsQuad.render(renderer);
	}

	/** Load uniforms to the minify material and assign it to the fullscreen quad */
	#useMinifyMaterial(): void {
		if (this.#camera instanceof PerspectiveCamera || this.#camera instanceof OrthographicCamera) {
			this.#currMinifyMaterial.uniforms.nearPlane.value = this.#camera.near;
			this.#currMinifyMaterial.uniforms.farPlane.value = this.#camera.far;
		}
		this.#fsQuad.material = this.#currMinifyMaterial;
	}

	/**
	 *
	 * @param readBuffer The input FBO, needed for screen resolution-based settings.
	 */
	#useGapFillMaterial(readBuffer: WebGLRenderTarget): void {
		this.#fsQuad.material = this.#mipGapFillMaterial;
		this.#mipGapFillMaterial.uniforms.depthTex1.value = readBuffer.depthTexture;
		this.#mipGapFillMaterial.uniforms.depthTex2.value = this.#fbo2x2.depthTexture;
		this.#mipGapFillMaterial.uniforms.depthTex4.value = this.#fbo4x4.depthTexture;
		this.#mipGapFillMaterial.uniforms.depthTex16.value = this.#fbo16x16.depthTexture;

		this.#mipGapFillMaterial.uniforms.colorTex1.value = readBuffer.texture;
		this.#mipGapFillMaterial.uniforms.colorTex2.value = this.#fbo2x2.texture;
		this.#mipGapFillMaterial.uniforms.colorTex4.value = this.#fbo4x4.texture;

		this.#mipGapFillMaterial.uniforms.texSize1.value.copy(this.#screenResolution);
		if (this.#camera instanceof PerspectiveCamera) {
			// Some uniforms are used only in perspective projection
			this.#mipGapFillMaterial.uniforms.nearPlane.value = this.#camera.near;
			this.#mipGapFillMaterial.uniforms.farPlane.value = this.#camera.far;

			this.#mipGapFillMaterial.uniforms.depthToFrustumHeight.value =
				2 * Math.tan(degToRad(0.5 * this.#camera.getEffectiveFOV()));
		} else if (this.#camera instanceof OrthographicCamera) {
			// Some other uniforms are used only in ortho projection
			this.#mipGapFillMaterial.uniforms.nearPlane.value = this.#camera.near;
			this.#mipGapFillMaterial.uniforms.farPlane.value = this.#camera.far;

			const pixelsPerMeter = this.#screenResolution.y / (this.#camera.top - this.#camera.bottom);
			const maxGapSizePix = pixelsPerMeter * MAX_HOLE_TO_FILL;
			// 'depthLevel' can be 0 (farthest pixels), 1, 2, and 3 (closest pixels). Different heuristics are applied
			// to compute the neighbourhood of pixels according to their 'depthLevel'
			// In case of orthographic projection, the 'depthLevel' is the same for all pixels because far objects
			// have size equal to near object. In case of perspective projection, the depth level is computed inside
			// the shader for each pixel.
			let depthLevel = 0;
			if (maxGapSizePix > 6) {
				depthLevel = 3;
			} else if (maxGapSizePix > 3) {
				depthLevel = 2;
			} else if (maxGapSizePix > 2) {
				depthLevel = 1;
			}
			this.#mipGapFillMaterial.uniforms.depthLevel.value = depthLevel;
			this.#mipGapFillMaterial.uniforms.finalDepthBias.value = Math.max(
				0.1,
				DEPTH_BIAS_MULTIPLIER / pixelsPerMeter,
			);
		}
		this.#mipGapFillMaterial.uniforms.debugColors.value = this.debugColors;
		this.#mipGapFillMaterial.uniformsNeedUpdate = true;
	}

	/**
	 * Fill the gaps in the current rendered image
	 *
	 * @param renderer The renderer
	 * @param writeBuffer The write buffer for this pass
	 * @param readBuffer The read buffer for this pass
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("Mip gap fill Pass pass requires a depth texture in the composer FBO");
		}
		this.#resizeFboOnNeed(new Vector2(writeBuffer.width, writeBuffer.height));

		const oldClearColor = new Color();
		renderer.getClearColor(oldClearColor);
		const oldClearAlpha = renderer.getClearAlpha();
		renderer.setClearColor(0x000000, 0);
		// without the two lines below, mip gap filling does not work when renderer.autoClear is false.
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		this.#useMinifyMaterial();

		if (this.#showMinified) {
			this.#renderMinified(renderer, writeBuffer, readBuffer);
			renderer.setClearColor(oldClearColor, oldClearAlpha);
			renderer.autoClear = oldAutoClear;
			return;
		}

		// We do four rendering steps. At each rendering step we subsample a new
		// FBO from the former by a factor of 2 in each dimension
		this.#minify(renderer, readBuffer, this.#fbo2x2);
		this.#minify(renderer, this.#fbo2x2, this.#fbo4x4);
		this.#minify(renderer, this.#fbo4x4, this.#fbo8x8);
		this.#minify(renderer, this.#fbo8x8, this.#fbo16x16);

		// Finally, we compute the result of gap filling using the four subsampled fbos
		this.#useGapFillMaterial(readBuffer);
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		renderer.setClearColor(oldClearColor, oldClearAlpha);
		renderer.clear(true, true, false);
		this.#fsQuad.render(renderer);
		renderer.autoClear = oldAutoClear;
	}

	/** @returns true if we're rendering the 16x16 subsampled image for debug. */
	get showMinified(): boolean {
		return this.#showMinified;
	}

	/** Sets whether the pass shows the subsampled fbo or not. */
	set showMinified(m: boolean) {
		this.#showMinified = m;
	}

	/** @returns which of the subsampled fbos we want to see for ddebugging purposes. */
	get subsampleLevel(): number {
		return this.#subsampleLevel;
	}

	/** Sets which of the subsampled fbos we want to see for debugging */
	set subsampleLevel(n: number) {
		// Discard all invalid values
		if (n < 2) {
			return;
		}
		if (n < 4) {
			this.#subsampleLevel = 2;
		} else if (n < 8) {
			this.#subsampleLevel = 4;
		} else if (n < 16) {
			this.#subsampleLevel = 8;
		} else {
			this.#subsampleLevel = 16;
		}
	}

	/** @returns The optimization profile of the gap fill effect. */
	get optimizedFor(): GapFillOptimizedFor {
		return this.#mipGapFillMaterial.optimizedFor;
	}

	/** Sets the optimization profile of the gap fill effect. */
	set optimizedFor(o: GapFillOptimizedFor) {
		this.#recompileShaderOnNeed(this.#mipGapFillMaterial.cameraMode, o);
	}

	/** @returns the camera used to render the scene. */
	get camera(): Camera {
		return this.#camera;
	}

	/** Sets the camera used to render the scene. */
	set camera(c: Camera) {
		this.#camera = c;
		const cm = this.#camera instanceof PerspectiveCamera ? CameraMode.Perspective : CameraMode.Orthographic;
		this.#currMinifyMaterial =
			cm === CameraMode.Perspective ? this.#minifyMaterialPersp : this.#minifyMaterialOrtho;
		this.#recompileShaderOnNeed(cm, this.optimizedFor);
	}
}
