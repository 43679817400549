// To parse this data:
//
//   import { Convert } from "./file";
//
//   const wSProjectDesc = Convert.toWSProjectDesc(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface WSProjectDesc {
	Class: string;
	Name: string;
	DisplayName: string;
	Description: string;
	Keywords: string;
	Latitude: number;
	Longitude: number;
	Image: string;
	Public: boolean;
	Featured: boolean;
	ExportVersion: number;
	ProjectUUID: string;
	Enable3D?: boolean;
	Type: string;
	PointClouds: PointClouds | null;
	Trafo: number[] | null;
	TrafoName: string;
	Oriented: boolean;
	Secret: string;
	SecretEnabled: boolean;
	UUID?: string;
	ExportRevision?: number;
	ExportUUID?: string;
	UploadState?: string;
	NumberOfScans?: number;
	OldestScanRecordingTime?: string;
	NewestScanRecordingTime?: string;
	NewestScanModificationTime?: string;
	WorkspaceModificationTime?: string;
	SceneVersion?: string;
	Uploader?: number;
	Simple3DEnabled?: boolean;
	UploadDate?: string;
	MaskFacesState?: string;
	UpdateDate?: string;
	Creator?: number;
	CreationDate?: string;
	State?: string;
	GroupIds?: null;
	Groups?: null;
}

export interface PointClouds {
	EntityPC?: EntityPC;
	Project?: EntityPC;
}

export interface EntityPC {
	pending: number;
	complete: number;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
	public static toWSProjectDesc(json: string): WSProjectDesc[] {
		return cast(JSON.parse(json), a(r("WSProjectDesc")));
	}

	public static wSProjectDescToJson(value: WSProjectDesc[]): string {
		return JSON.stringify(uncast(value, a(r("WSProjectDesc"))), null, 2);
	}
}

function invalidValue(typ: any, val: any, key: any = ""): never {
	if (key) {
		throw Error(
			`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`,
		);
	}
	throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ: any): any {
	if (typ.jsonToJS === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
		typ.jsonToJS = map;
	}
	return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
	if (typ.jsToJSON === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
		typ.jsToJSON = map;
	}
	return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
	function transformPrimitive(typ: string, val: any): any {
		if (typeof typ === typeof val) return val;
		return invalidValue(typ, val, key);
	}

	function transformUnion(typs: any[], val: any): any {
		// val must validate against one typ in typs
		const l = typs.length;
		for (let i = 0; i < l; i++) {
			const typ = typs[i];
			try {
				return transform(val, typ, getProps);
			} catch (_) {}
		}
		return invalidValue(typs, val);
	}

	function transformEnum(cases: string[], val: any): any {
		if (cases.indexOf(val) !== -1) return val;
		return invalidValue(cases, val);
	}

	function transformArray(typ: any, val: any): any {
		// val must be an array with no invalid elements
		if (!Array.isArray(val)) return invalidValue("array", val);
		return val.map((el) => transform(el, typ, getProps));
	}

	function transformDate(val: any): any {
		if (val === null) {
			return null;
		}
		const d = new Date(val);
		if (isNaN(d.valueOf())) {
			return invalidValue("Date", val);
		}
		return d;
	}

	function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
		if (val === null || typeof val !== "object" || Array.isArray(val)) {
			return invalidValue("object", val);
		}
		const result: any = {};
		Object.getOwnPropertyNames(props).forEach((key) => {
			const prop = props[key];
			const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
			result[prop.key] = transform(v, prop.typ, getProps, prop.key);
		});
		Object.getOwnPropertyNames(val).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(props, key)) {
				result[key] = transform(val[key], additional, getProps, key);
			}
		});
		return result;
	}

	if (typ === "any") return val;
	if (typ === null) {
		if (val === null) return val;
		return invalidValue(typ, val);
	}
	if (typ === false) return invalidValue(typ, val);
	while (typeof typ === "object" && typ.ref !== undefined) {
		typ = typeMap[typ.ref];
	}
	if (Array.isArray(typ)) return transformEnum(typ, val);
	if (typeof typ === "object") {
		return typ.hasOwnProperty("unionMembers")
			? transformUnion(typ.unionMembers, val)
			: typ.hasOwnProperty("arrayItems")
				? transformArray(typ.arrayItems, val)
				: typ.hasOwnProperty("props")
					? transformObject(getProps(typ), typ.additional, val)
					: invalidValue(typ, val);
	}
	// Numbers can be parsed by Date but shouldn't be.
	if (typ === Date && typeof val !== "number") return transformDate(val);
	return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
	return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
	return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
	return { arrayItems: typ };
}

function u(...typs: any[]) {
	return { unionMembers: typs };
}

function o(props: any[], additional: any) {
	return { props, additional };
}

function m(additional: any) {
	return { props: [], additional };
}

function r(name: string) {
	return { ref: name };
}

const typeMap: any = {
	WSProjectDesc: o(
		[
			{ json: "Class", js: "Class", typ: "" },
			{ json: "Name", js: "Name", typ: "" },
			{ json: "DisplayName", js: "DisplayName", typ: "" },
			{ json: "Description", js: "Description", typ: "" },
			{ json: "Keywords", js: "Keywords", typ: "" },
			{ json: "Latitude", js: "Latitude", typ: 3.14 },
			{ json: "Longitude", js: "Longitude", typ: 3.14 },
			{ json: "Image", js: "Image", typ: "" },
			{ json: "Public", js: "Public", typ: true },
			{ json: "Featured", js: "Featured", typ: true },
			{ json: "ExportVersion", js: "ExportVersion", typ: 0 },
			{ json: "ProjectUUID", js: "ProjectUUID", typ: "" },
			{ json: "Enable3D", js: "Enable3D", typ: u(undefined, true) },
			{ json: "Type", js: "Type", typ: "" },
			{ json: "PointClouds", js: "PointClouds", typ: u(r("PointClouds"), null) },
			{ json: "Trafo", js: "Trafo", typ: u(a(3.14), null) },
			{ json: "TrafoName", js: "TrafoName", typ: "" },
			{ json: "Oriented", js: "Oriented", typ: true },
			{ json: "Secret", js: "Secret", typ: "" },
			{ json: "SecretEnabled", js: "SecretEnabled", typ: true },
			{ json: "UUID", js: "UUID", typ: u(undefined, "") },
			{ json: "ExportRevision", js: "ExportRevision", typ: u(undefined, 0) },
			{ json: "ExportUUID", js: "ExportUUID", typ: u(undefined, "") },
			{ json: "UploadState", js: "UploadState", typ: u(undefined, "") },
			{ json: "NumberOfScans", js: "NumberOfScans", typ: u(undefined, 0) },
			{ json: "OldestScanRecordingTime", js: "OldestScanRecordingTime", typ: u(undefined, "") },
			{ json: "NewestScanRecordingTime", js: "NewestScanRecordingTime", typ: u(undefined, "") },
			{ json: "NewestScanModificationTime", js: "NewestScanModificationTime", typ: u(undefined, "") },
			{ json: "WorkspaceModificationTime", js: "WorkspaceModificationTime", typ: u(undefined, "") },
			{ json: "SceneVersion", js: "SceneVersion", typ: u(undefined, "") },
			{ json: "Uploader", js: "Uploader", typ: u(undefined, 0) },
			{ json: "Simple3DEnabled", js: "Simple3DEnabled", typ: u(undefined, true) },
			{ json: "UploadDate", js: "UploadDate", typ: u(undefined, "") },
			{ json: "MaskFacesState", js: "MaskFacesState", typ: u(undefined, "") },
			{ json: "UpdateDate", js: "UpdateDate", typ: u(undefined, "") },
			{ json: "Creator", js: "Creator", typ: u(undefined, 0) },
			{ json: "CreationDate", js: "CreationDate", typ: u(undefined, "") },
			{ json: "State", js: "State", typ: u(undefined, "") },
			{ json: "GroupIds", js: "GroupIds", typ: u(undefined, null) },
			{ json: "Groups", js: "Groups", typ: u(undefined, null) },
		],
		false,
	),
	PointClouds: o(
		[
			{ json: "EntityPC", js: "EntityPC", typ: u(undefined, r("EntityPC")) },
			{ json: "Project", js: "Project", typ: u(undefined, r("EntityPC")) },
		],
		false,
	),
	EntityPC: o(
		[
			{ json: "pending", js: "pending", typ: 0 },
			{ json: "complete", js: "complete", typ: 0 },
		],
		false,
	),
};
