import { pixels2m } from "@faro-lotv/lotv";
import { PointsProps, Size } from "@react-three/fiber";
import { RefObject, forwardRef, useEffect, useRef } from "react";
import {
  BufferGeometry,
  Intersection,
  Object3D,
  PointsMaterial,
  Raycaster,
  Sphere,
  Points as ThreePoints,
  Uint8BufferAttribute,
  Vector3,
} from "three";
import { useViewportRef } from "../hooks";

/** A shared geometry when we need just one point without normals or colors */
const SINGLE_POINT_GEOMETRY = new BufferGeometry().setAttribute(
  "position",
  new Uint8BufferAttribute([0, 0, 0], 3, false),
);

/** A single sphere to use for raycasting to not allocate in the raycast method */
const HIT_SPHERE = new Sphere();

/**
 * Function to generate an optimized raycaster for Points in a 2d map
 *
 * Map the size of the ortho projection to the width/height of the viewport to match the points geometry
 * that is in pixel size to meters so we can do precise raycasts instead of the tolerance based raycast of threejs
 *
 * @param viewport The size of the viewport this camera is targeting
 * @returns A function to use as a raycaster for a Points THREEJS object
 */
function customRaycaster(viewport: RefObject<Size>) {
  return function (
    this: ThreePoints<BufferGeometry, PointsMaterial>,
    raycaster: Raycaster,
    intersects: Array<Intersection<Object3D>>,
  ) {
    // Use default raycaster if we don't have a valid viewport or a camera
    if (!viewport.current) {
      const proto = Object.getPrototypeOf(this);
      proto.raycast.apply(this, [raycaster, intersects]);
      return;
    }
    // Use the global position of the object as the center of our hit sphere
    this.getWorldPosition(HIT_SPHERE.center);
    // Compute the radius mapping the size of the point in pixels to the size in meters
    HIT_SPHERE.radius = pixels2m(
      this.material.size,
      raycaster.camera,
      viewport.current.height,
      HIT_SPHERE.center.distanceTo(raycaster.camera.position),
    );
    // Intersect the ray with our sphere and not the original point geometry
    const hit = raycaster.ray.intersectSphere(HIT_SPHERE, new Vector3());
    if (hit) {
      intersects.push({
        distance: raycaster.ray.origin.distanceTo(hit),
        object: this,
        point: hit,
      });
    }
  };
}

/**
 * A special version of R3F Points object with a custom raycaster that will work precisely
 * but more slowly evaluating the pixel2meter factor of the current camera and casting on a sphere
 * containing the point instead of using a fixed threshold
 *
 * Useful for points used to interact with the user, like the placeholders in a map, or a pivot point
 */
export const PrecisePoints = forwardRef<ThreePoints, PointsProps>(
  function Points(props, ref): JSX.Element {
    // We want to forward a ref but to use it too so we need to forward it manually
    const points = useRef<ThreePoints>(null);
    useEffect(() => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(points.current);
      } else {
        ref.current = points.current;
      }
    }, [points, ref]);

    // The specialized raycaster need to know the viewport
    const viewport = useViewportRef();
    useEffect(() => {
      if (!points.current) return;
      points.current.raycast = customRaycaster(viewport);
    }, [viewport, points]);

    return <points ref={points} {...props} />;
  },
);

/**
 * A geometry when you need only one single point, will share the geometry
 * object with all the other single points users
 *
 * @returns A primitive pointing to a shared single point buffer geometry
 */
export function SinglePointGeometry(): JSX.Element {
  return <primitive object={SINGLE_POINT_GEOMETRY} attach="geometry" />;
}
