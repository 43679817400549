import {
	BufferGeometry,
	Camera,
	InterleavedBuffer,
	InterleavedBufferAttribute,
	Intersection,
	Mesh,
	OrthographicCamera,
	PerspectiveCamera,
	Raycaster,
	Vector2,
	Vector3,
	WebGLRenderer,
} from "three";
import { LineSegmentMaterial } from "../Materials";
import { LineSegmentMaterialParameters } from "../Materials/LineSegmentMaterial";
import { degToRad } from "../Utils";

/** Create only one geometry for all the lines */
const SEGMENT_GEOMETRY = (() => {
	const ITEM_SIZE_FOR_VERTEX = 5;
	const geometry = new BufferGeometry();
	const float32Array = new Float32Array([-1, -1, 0, 0, 0, 1, -1, 0, 1, 0, 1, 1, 0, 1, 1, -1, 1, 0, 0, 1]);
	const interleavedBuffer = new InterleavedBuffer(float32Array, ITEM_SIZE_FOR_VERTEX);
	geometry.setIndex([0, 1, 2, 0, 2, 3]);
	geometry.setAttribute("position", new InterleavedBufferAttribute(interleavedBuffer, 3, 0, false));
	geometry.setAttribute("uv", new InterleavedBufferAttribute(interleavedBuffer, 2, 3, false));
	return geometry;
})();

/**
 * Render a segment as a quad made by two triangles. In order to have a fixed pixel size in
 * both persepective and orthographic views, we proceed as follow:
 * 1. Set the Segment position as the middle point of the segment
 * 2. Set the "direction" uniform as the half vector of the segment
 * 3. Create a geometry containing the offsets from the middle points (-1,-1), (1,-1), (1, 1), (-1, 1)
 *
 * Then in the shader:
 * 1. We project the middle point to screen (NDC)
 * 2. We add the half direction to the middle point and we project the obtained point to screen (NDC)
 * 3. We extract the direction in NDC and the orthogonal direction in 2D NDC
 * 4. We sum the orthogonal direction to the point obtained in step 2
 */
export class LineSegment extends Mesh<BufferGeometry, LineSegmentMaterial> {
	declare material: LineSegmentMaterial;

	/**
	 * Create a segment renderable given the two points that define the segment
	 *
	 * @param p0 The starting point of the segment
	 * @param p1 The ending point of the segment
	 * @param parameters The list of paramters for the segment material
	 */
	constructor(p0: Vector3, p1: Vector3, parameters: Partial<LineSegmentMaterialParameters> = {}) {
		super();
		this.geometry = SEGMENT_GEOMETRY;
		this.material = new LineSegmentMaterial(parameters);
		this.material.direction = new Vector3().subVectors(p0, p1).multiplyScalar(0.5);
		this.position.copy(new Vector3().addVectors(p0, p1).multiplyScalar(0.5));
	}

	/**
	 * Update the segment by changing the start and end point
	 *
	 * @param p0 The new starting point of the segment
	 * @param p1 The new ending point of the segment
	 */
	updateSegment(p0: Vector3, p1: Vector3): void {
		this.material.direction = new Vector3().subVectors(p0, p1).multiplyScalar(0.5);
		this.position.copy(new Vector3().addVectors(p0, p1).multiplyScalar(0.5));
	}

	override onBeforeRender = (renderer: WebGLRenderer): void => {
		this.material.viewport = renderer.getSize(new Vector2());
	};

	/**
	 * Compute the conversion factor from pixels to meters
	 *
	 * @param camera The camera used to render this object
	 * @returns The conversion factor
	 */
	#computePixelsToMetersFactor(camera: Camera): number {
		const { viewport } = this.material;
		if (camera instanceof PerspectiveCamera) {
			const pixelsToMeters = (2 * Math.tan(degToRad(camera.fov) * 0.5)) / viewport.y;
			const lineDepth = -this.position
				.clone()
				.applyMatrix4(this.matrixWorld)
				.applyMatrix4(camera.matrixWorldInverse).z;
			return pixelsToMeters * lineDepth;
		} else if (camera instanceof OrthographicCamera) {
			const ovsize = (camera.top - camera.bottom) / camera.zoom;
			const pixelsToMeters = ovsize / viewport.y;
			return pixelsToMeters;
		}
		throw new TypeError("Unsupported camera type");
	}

	/**
	 * Compute the intersection between a ray and this segment in 3D, by generating a
	 * cylinder that represents the line
	 *
	 * @param raycaster The raycaster used to compute the intersection
	 * @param intersects The array containing all the computed intersections
	 */
	override raycast(raycaster: Raycaster, intersects: Array<Intersection<LineSegment>>): void {
		const SENSITIVITY = 10;
		const pixelsToMeters = this.#computePixelsToMetersFactor(raycaster.camera);

		// Compute the 3D cylinder the represents our line
		const radius = pixelsToMeters * (this.material.thickness + SENSITIVITY);
		const direction = this.material.direction.clone();

		// Intersect the cylinder with the ray
		const closestPoint = new Vector3();
		const distance = raycaster.ray.distanceSqToSegment(
			this.position.clone().sub(direction),
			this.position.clone().add(direction),
			new Vector3(),
			closestPoint,
		);
		if (distance > radius * radius) {
			return;
		}

		if (closestPoint.clone().sub(this.position).length() > direction.length()) {
			return;
		}

		const intersection: Intersection<LineSegment> = {
			distance,
			point: closestPoint,
			object: this,
		};
		intersects.push(intersection);
	}
}
