precision highp float;

#ifdef ORTHO_PROJECTION

float depthToViewZ( const in float linearClipZ, const in float near, const in float far ) {
	return linearClipZ * ( near - far ) - near;
}

#else

float depthToViewZ( const in float invClipZ, const in float near, const in float far ) {
	return ( near * far ) / ( ( far - near ) * invClipZ - far );
}

#endif

out vec4 outColor;

uniform sampler2D inColorTex;
uniform sampler2D inDepthTex;
uniform ivec2 texSizeIn;
uniform vec2 texSizeOut;
uniform float nearPlane;
uniform float farPlane;

in vec2 vUv;

const float DEPTH_EQUALITY_THRESHOLD = 0.1;

// 2   3
// 0   1
// =>
const int k_Horizontal = 15;
const int k_LeaningBL = 14;
const int k_LeaningBR = 13;
const int k_LeaningB = 12;
const int k_LeaningTL = 11;
const int k_LeaningL = 10;
const int k_SaddleTR = 9;
const int k_LeaningHardBL = 8;
const int k_LeaningTR = 7;
const int k_SaddleTL = 6;
const int k_LeaningR = 5;
const int k_LeaningHardBR = 4;
const int k_LeaningT = 3;
const int k_LeaningHardTL =2;
const int k_LeaningHardTR = 1;
const int k_EmptyPix = 0;

void main() {
    ivec2 pix = ivec2(texSizeOut * vUv);
    ivec2 inPix = pix * 2;
    ivec2 incr = ivec2(inPix.x == texSizeIn.x - 1 ? 0 : 1, inPix.y == texSizeIn.y - 1 ? 0 : 1);
    ivec2 inTCoords[4] = ivec2[4](inPix, inPix + ivec2(incr.x, 0), inPix + ivec2(0, incr.y), inPix + incr);
    float depths[4] = float[4](
        texelFetch(inDepthTex, inTCoords[0], 0).r,
        texelFetch(inDepthTex, inTCoords[1], 0).r,
        texelFetch(inDepthTex, inTCoords[2], 0).r,
        texelFetch(inDepthTex, inTCoords[3], 0).r
    );
    float camDepths[4] = float[4](
        depthToViewZ(depths[0], nearPlane, farPlane),
        depthToViewZ(depths[1], nearPlane, farPlane),
        depthToViewZ(depths[2], nearPlane, farPlane),
        depthToViewZ(depths[3], nearPlane, farPlane)
    );
    int minDepthIdx = 0;
    float minDepth = depths[0];
    for(int i = 1; i < 4; ++i) {
        float currDepth = depths[i];
        if(minDepth > currDepth) {
            minDepth = currDepth;
            minDepthIdx = i;
        }
    }
    gl_FragDepth = minDepth;
    
    vec3 avgColor = vec3(0, 0, 0);
    int pixCount = 0;

    uint planeLabel = 0u;
    float camDepthThresh = max(-farPlane, camDepths[minDepthIdx] - DEPTH_EQUALITY_THRESHOLD);
    for(int i = 0; i < 4; ++i) {
        if(camDepths[i] > camDepthThresh) {
            planeLabel += (1u << i);
            avgColor += texelFetch(inColorTex, inTCoords[i], 0).rgb;
            pixCount++;
        }
    }
    avgColor /= float(pixCount);

    outColor = vec4(avgColor, float(planeLabel));
}
