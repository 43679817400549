/**
 * Percentage of available space used that will trigger the clean of the cache
 */
const SPACE_PERC_CLEAN_THR = 0.9;
/**
 * How often to run the auto clean of the cache
 */
const AUTO_CLEAN_INTERVAL = 5000;
/**
 * Name for the lotv cache in the browser
 */
export const LOTV_CACHE_NAME = "lotv";

/**
 * Check if the browser has the caches object available
 *
 * @returns true if the browser have a caches object
 */
export function browserCacheExists(): boolean {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
	return globalThis.caches !== undefined;
}

/**
 * Wrapper around browser cache to cache lotv data that will auto clean if needed
 */
export class LotvCache {
	private doClean = false;
	disable = false;
	cachedUrls: string[] = [];

	/**
	 * @param cacheName name of the browser cache to use
	 */
	constructor(private cacheName = LOTV_CACHE_NAME) {
		setTimeout(() => {
			this.cleanIfNeeded();
		}, AUTO_CLEAN_INTERVAL);
	}

	/**
	 * @returns true if we have a cache to use
	 */
	async cacheAvailable(): Promise<boolean> {
		return (await this.openCache()) !== undefined;
	}

	/**
	 * @returns the number of url cached
	 */
	async numEntries(): Promise<number> {
		const c = await this.openCache();
		if (!c) {
			return 0;
		}
		const keys = await c.keys();
		return keys.length;
	}

	/**
	 *  Run cleanup routine if we're short on memory
	 *
	 * @returns a promise that will resolve after the cleanup is done
	 */
	async cleanIfNeeded(): Promise<void> {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
			if (!this.doClean && navigator.storage.estimate !== undefined) {
				const estimate = await navigator.storage.estimate();
				if (estimate.quota && estimate.usage && estimate.usage / estimate.quota > SPACE_PERC_CLEAN_THR) {
					this.doClean = true;
				}
			}
			// eslint-disable-next-line no-empty
		} catch {}
		if (this.doClean) await this.cleanup();

		setTimeout(() => {
			this.cleanIfNeeded();
		}, AUTO_CLEAN_INTERVAL);
	}

	/**
	 * Cleanup the cache, remove everything for now
	 */
	async cleanup(): Promise<void> {
		try {
			this.cachedUrls = [];
			await caches.delete(this.cacheName);
			this.doClean = false;
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * Open the cache storage for lotv
	 *
	 * @returns A Cache instance to use
	 */
	private async openCache(): Promise<Cache | undefined> {
		if (this.disable) return undefined;
		try {
			return await caches.open(this.cacheName);
		} catch {
			return undefined;
		}
	}

	/**
	 * Search if an url is cached
	 *
	 * @param url The url to check
	 * @returns The cached response or undefined if the url is not in the cache
	 */
	async match(url: string): Promise<Response | undefined> {
		const c = await this.openCache();
		if (!c) return undefined;
		return c.match(url);
	}

	/**
	 * Put a new url in the cache with it's response
	 *
	 * @param url The url to cache
	 * @param res The fetch response received
	 */
	async put(url: string, res: Response): Promise<void> {
		try {
			const c = await this.openCache();
			if (!c) return;
			await c.put(url, res);
			this.cachedUrls.push(url);
		} catch (err) {
			if (err instanceof Error && err.message.includes("Abort")) return;
			this.doClean = true;
		}
	}

	/**
	 * Check if we've cached an url (no async)
	 *
	 * @param url The url to check
	 * @returns true if it's have been cached by this cache object
	 */
	isCached(url: string): boolean {
		return this.cachedUrls.includes(url);
	}
}

export const lotvCache = new LotvCache();
