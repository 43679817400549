import { Camera, Group, Object3D, Vector3 } from "three";
import { memberWithPrivateData, pixels2m } from "../../Utils";

/** A generic gizmo class with utility functions */
export class Gizmo extends Group {
	#element: HTMLElement;
	#camera: Camera;

	/**
	 * @param element The HTML element on which the scene is rendered
	 * @param camera The camera used in the scene
	 */
	constructor(element: HTMLElement, camera: Camera) {
		super();
		this.#element = element;
		this.#camera = camera;
	}

	/**
	 * @returns the scale factor in meter that corresponds to a certain size in pixels,
	 * based on the distance from the camera of the input object
	 * @param object the 3D object whose distance from the camera is used as a reference
	 * @param size the size in pixels
	 */
	protected computePixelsToMetersFactor = memberWithPrivateData(() => {
		const handlePos = new Vector3();

		return (object: Object3D, size: number): number => {
			const vh = this.#element.clientHeight;
			object.getWorldPosition(handlePos);
			handlePos.applyMatrix4(this.#camera.matrixWorldInverse);
			const dist = Math.abs(handlePos.z);
			return pixels2m(size, this.#camera, vh, dist);
		};
	});
}
