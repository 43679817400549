// The backend uses snake-case variable names
/* eslint-disable @typescript-eslint/naming-convention */

/** The identifier of a job running in the backend. */
export type BackendJobId = string;

/** Registration algorithms supported by the backend. */
export enum RegistrationAlgorithm {
  /**
   * Uses a modified version Open3Ds "Iterative Closest Point" algorithm.
   * https://faro01.atlassian.net/wiki/spaces/NewRegistr/pages/3775824552/ICP
   */
  Open3dIcp = "Open3dICP",
  /**
   * NDR Algorithm from FAROs RLY library, as used by SCENEs "Cloud to Cloud" registration.
   * This option allows to optionally run the TVB algorithm as a pre-registration step.
   * https://faro01.atlassian.net/wiki/spaces/NewRegistr/pages/3473871294/NDR+Cloud+to+Cloud
   */
  Ndr = "RLY",
  /**
   * TVB Algorithm from FAROs RLY library, as used by SCENEs "Top View Based" registration.
   * Usually used for pre-registration, followed by NDR or ICP.
   * https://faro01.atlassian.net/wiki/spaces/NewRegistr/pages/3474230117/TVB+Top+View+Based
   */
  Tvb = "TVB",
}

/**
 * Checks if passed string is a valid registration algorithm.
 *
 * @param str the string to be checked
 * @returns true if the passed string is a valid registration algorithm
 */
export function isRegistrationAlgorithm(
  str: string,
): str is RegistrationAlgorithm {
  return Object.values<string>(RegistrationAlgorithm).includes(str);
}

/** Registration parameters shared by all supported algorithms */
type SharedRegistrationParams = {
  /** Determines voxel size when down-sampling the pointcloud before registration */
  voxel_size: number;
  /** Automatically cascade the voxel size if the result is not good enough */
  cascade_voxel_size: boolean;
  /**
   *  Tells the algorithm to automatically choose the point cloud with more points as reference internally.
   *  If reference and model cloud are switched internally, the result will be inverted and still be applied to
   *  to the reference cloud the user has chosen.
   */
  use_large_pc_as_reference: boolean;
};

/** Registration parameters supported by TVB */
export type TvbParams = SharedRegistrationParams;

/** Registration parameters supported by NDR */
export type NdrParams = TvbParams & {
  // Whether Top-View-Based registration should be used
  tvb: boolean;
};

/** Registration parameters supported by icp */
export type Open3dIcpParams = SharedRegistrationParams & {
  /** Stopping criteria for our ICP (Iterative closest point) algorithm */
  max_iterations: number;
  /** Stopping criteria; checks if the iterative solutions are not changing too much */
  relative_error: number;
  /** Defines the radius of search for correspondences source<->target */
  factor_threshold: number;
  /** if `true`, voxel_size is calculated automatically */
  auto_voxel_size: boolean;
  /** if `true`, factor_threshold is calculated automatically */
  auto_factor_threshold: boolean;
};

/** Settings for the NDR algorithm. */
export type NdrAlgorithmSettings = {
  algorithm: RegistrationAlgorithm.Ndr;
  parameters: NdrParams;
};

/** Settings for the Open3D ICP algorithm. */
export type Open3dIcpAlgorithmSettings = {
  algorithm: RegistrationAlgorithm.Open3dIcp;
  parameters: Open3dIcpParams;
};

/** Settings for the TVB algorithm. */
export type TvbAlgorithmSettings = {
  algorithm: RegistrationAlgorithm.Tvb;
  parameters: SharedRegistrationParams;
};

/** The algorithm type and parameters for automatic registration. */
export type RegistrationAlgorithmSettings =
  | NdrAlgorithmSettings
  | Open3dIcpAlgorithmSettings
  | TvbAlgorithmSettings;
