import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useExternalScript } from "../hooks/use-external-script";
import { HolobuilderCookieManager } from "./cookie-manager-types";

// Extending the window global to contain the HolobuilderCookieManager object
declare global {
  interface Window {
    HolobuilderCookieManager?: HolobuilderCookieManager;
  }
}

type CookieManagerContext = {
  /** True if the script is finished loading */
  isManagerLoaded: boolean;

  /** True if the cookie-manager has been initialized (i.e., all functionality is available) */
  isInitialized: boolean;
};

export const HBCookieManagerContext = createContext<
  CookieManagerContext | undefined
>(undefined);

export type HBCookieManagerProviderProps = {
  /** URL of the main cookie-manager script */
  cookieManagerUrl?: string;
};

/**
 * @returns The context that provides access to the HB cookie-manager.
 *  Will load the provided script URL and then call the init function of the cookie-manager.
 *  Note: The init call will show the UI of the cookie-manager if the user didn't interact with it yet.
 */
export function HBCookieManagerProvider({
  children,
  cookieManagerUrl,
}: PropsWithChildren<HBCookieManagerProviderProps>): JSX.Element | null {
  // Load the main cookie manager script
  const { isDoneLoading } = useExternalScript(cookieManagerUrl);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize the cookie-manager once the script has been loaded
  useEffect(() => {
    if (isDoneLoading && window.HolobuilderCookieManager) {
      window.HolobuilderCookieManager.init({});
      setIsInitialized(true);
    }
  }, [isDoneLoading]);

  return (
    <HBCookieManagerContext.Provider
      value={{ isManagerLoaded: isDoneLoading, isInitialized }}
    >
      {children}
    </HBCookieManagerContext.Provider>
  );
}
