import { Box3, Matrix4, Plane, Sphere, Vector2, Vector3 } from "three";
import { isPointInPolygon } from "../Algorithms/Polygons";
import { computeOrthogonalVector, getLotvMath } from "../Utils/Math";
import { memberWithPrivateData } from "../Utils/MemoryUtils";
import { LodPointCloud } from "./LodPointCloud";
import { traverseLodTree } from "./LodTreeTraversal";

/** The points selection result */
export type PointsSelectionResult = {
	/** The selected points coordinates relative to a origin point, array of [x,y,z,x,y,z...] */
	points: Float32Array;

	/**
	 * The selected points' origin in world CS, used to avoid precision loss when store large coordinates as Float32Array
	 */
	origin: Vector3;
};

/** Options to control the selection of points inside a sphere */
export type SphereSelectionOptions = {
	/** The sphere in world coordinates system */
	sphere: Sphere;

	/** The maximum number of points to be collected */
	maxNumberOfPoints: number;

	/** The minimal the node point density, as the distance in millimeters between two consecutive points */
	minPointDensity: number;
};

/**
 * Select points from the LodPointCloud that are inside a sphere.
 *
 * @param pointCloud The point cloud to extract the points from.
 * @param options The options to control the extraction.
 * @returns Points selection or undefined if no point is found.
 */
export function selectPointsInSphere(
	pointCloud: LodPointCloud,
	options: SphereSelectionOptions,
): PointsSelectionResult | undefined {
	if (options.sphere.radius <= 0) {
		return;
	}

	const matrixWorldInv = pointCloud.matrixWorld.clone();
	matrixWorldInv.invert();
	const sphere = options.sphere.clone().applyMatrix4(matrixWorldInv);

	const points: number[] = [];
	traverseLodTree(pointCloud, {
		checkNodeBox(box: Box3): boolean {
			return box.intersectsSphere(sphere);
		},
		processNodePoint(point: Vector3): boolean {
			if (!sphere.containsPoint(point)) return false;
			// convert the point the world coordinate system
			point.applyMatrix4(pointCloud.matrixWorld);
			// store coordinates relative to the sphere center
			point.sub(sphere.center);
			points.push(point.x, point.y, point.z);
			return true;
		},
		maxNumberOfPoints: options.maxNumberOfPoints,
		minPointDensity: options.minPointDensity,
	});

	return points.length > 0 ? { points: new Float32Array(points), origin: sphere.center } : undefined;
}

/** Options to control the selection of points by a polygon */
export type PolygonSelectionOptions = {
	/** The polygon points in world coordinates system */
	polygon: Vector3[];

	/** The planar threshold, the selected points will be kept between two parallel planes with this distance */
	planeThreshold: number;

	/** The maximum number of points to be collected */
	maxNumberOfPoints: number;

	/** The minimal the node point density, as the distance in millimeters between two consecutive points */
	minPointDensity: number;
};

/**
 * Select points from the LodPointCloud that are inside a planar polygon.
 *  - The polygon points no necessary to be exact coplanar
 *  - A reference plane is created by best fiting the polygon points
 *  - The 3D polygon is projected to the reference plane to create a 2D polygon
 *  - The cloud points are selected according to criteria:
 *      - within [-threshod/2, threshold/2] of the reference plane
 *      - 2d projection of the point on the reference plane is inside the 2D polygon
 *      - only the node points in memory will be selected
 * This type of selection is not mean to select general 3d objects. Typical user cases
 * are to select points on a flat surface such as floor or wall.
 *
 * @param pointCloud The point cloud to extract the points from.
 * @param options The options to control the extraction.
 * @returns Points selection or undefined if no point is found.
 */
export async function selectPointsByPolygon(
	pointCloud: LodPointCloud,
	options: PolygonSelectionOptions,
): Promise<PointsSelectionResult | undefined> {
	const projPolygon = await createProjectedPolygon2d(options.polygon);
	if (!projPolygon) {
		return;
	}

	// Center of the polygon would be close to the selected points
	// Use it as the origin to reduce the precision loss
	const origin = options.polygon.reduce((acc, p) => acc.add(p), new Vector3()).divideScalar(options.polygon.length);

	const matrixWorldInv = pointCloud.matrixWorld.clone();
	matrixWorldInv.invert();
	// Bring reference geometry to cloud points' coordinate system
	projPolygon.projPlane.applyTransformation(matrixWorldInv);
	projPolygon.boundingSphere.applyMatrix4(matrixWorldInv);

	const halfPlaneThreshold = options.planeThreshold / 2;
	const points: number[] = [];
	traverseLodTree(pointCloud, {
		checkNodeBox(box: Box3): boolean {
			// check if the box could be potentially intersect with the 2d polygon
			return (
				box.intersectsSphere(projPolygon.boundingSphere) &&
				distancePlaneToBox(projPolygon.projPlane.plane, box) < halfPlaneThreshold
			);
		},
		processNodePoint(point: Vector3): boolean {
			if (!isPointInProjectedPolygon(point, projPolygon, halfPlaneThreshold)) return false;

			// convert the point the world coordinate system
			point.applyMatrix4(pointCloud.matrixWorld);
			// store coordinates relative to the origin
			point.sub(origin);
			points.push(point.x, point.y, point.z);
			return true;
		},
		maxNumberOfPoints: options.maxNumberOfPoints,
		minPointDensity: options.minPointDensity,
	});

	return {
		points: new Float32Array(points),
		origin,
	};
}

/**
 * Geometry data derived from a 3D planar polygon and can be used to check if a point is inside the polygon.
 */
export type ProjectedPolygon2d = {
	// reference project plane
	projPlane: ProjectPlane;
	// the projected 2d polygon in plane's 2d coordinate system
	polygon2d: Vector2[];
	// the bounding sphere of the 3d polygon
	boundingSphere: Sphere;
};

/**
 * Create reference project plane and projected 2d polygon from a 3d polygon
 *
 * @param polygon The input 3d polygon
 * @returns The reference project plane and projected 2d polygon, or undefined if the polygon is degenerate
 */
export async function createProjectedPolygon2d(polygon: Vector3[]): Promise<ProjectedPolygon2d | undefined> {
	const lotvMath = await getLotvMath();
	const plane = lotvMath.fitPlane(polygonToArray(polygon));
	if (!plane) return;

	const projPlane = new ProjectPlane(
		new Vector3(plane.point.x, plane.point.y, plane.point.z),
		new Vector3(plane.normal.x, plane.normal.y, plane.normal.z),
	);

	// project the polygon to the plane
	const nbPoints = polygon.length;
	const polygon2d = new Array(nbPoints);
	let radius = 0;
	for (let i = 0; i < nbPoints; i++) {
		polygon2d[i] = projPlane.project(polygon[i], new Vector2());
		radius = Math.max(radius, polygon[i].distanceTo(projPlane.origin));
	}
	const boundingSphere = new Sphere(projPlane.origin.clone(), radius);

	return { projPlane, polygon2d, boundingSphere };
}

/**
 * Check if a point is inside a projected 2d polygon
 *
 * @param point the point to check
 * @param projPolygon Projected 2d polygon used to check if the point is inside
 * @param distanceThreshold The half thickness of the reference plane
 * @returns true if the point is inside the polygon
 */
export const isPointInProjectedPolygon = memberWithPrivateData(() => {
	const tmpProjectPoint = new Vector2();

	return (point: Vector3, projPolygon: ProjectedPolygon2d, distanceThreshold: number): boolean => {
		// check if the point is close to the reference plane
		if (Math.abs(projPolygon.projPlane.distance(point)) > distanceThreshold) return false;

		// exclude points outside the bounding sphere
		if (!projPolygon.boundingSphere.containsPoint(point)) return false;

		// check if the point's projection on the reference plane is inside the 2d polygon
		projPolygon.projPlane.project(point, tmpProjectPoint);
		if (!isPointInPolygon(tmpProjectPoint, projPolygon.polygon2d)) return false;

		return true;
	};
});

// Utility represents a project plane with a 2d coordinate system (u,v) defined at the
// plane center. It can be used to project 3d points to 2d coordinate system on the plane.
class ProjectPlane {
	origin: Vector3;
	normal: Vector3;
	planeU: Vector3;
	planeV: Vector3;
	plane: Plane;

	#tmp = new Vector3();

	// Set origin and normal of the projection plane, the plane uv directions are defined
	// as vectors orthogonal to the normal.
	constructor(planeCenter: Vector3, planeNormal: Vector3) {
		this.origin = planeCenter;
		this.normal = planeNormal.normalize();
		this.planeU = computeOrthogonalVector(this.normal).normalize();
		this.planeV = this.normal.clone().cross(this.planeU).normalize();

		this.plane = new Plane().setFromNormalAndCoplanarPoint(this.normal, this.origin);
	}

	// Project a 3d point to the 2d coordinate system defined by the plane.
	project(point: Vector3, out: Vector2): Vector2 {
		this.#tmp.subVectors(point, this.origin);
		out.x = this.#tmp.dot(this.planeU);
		out.y = this.#tmp.dot(this.planeV);
		return out;
	}

	// Compute the distance of a 3d point to the plane
	distance(point: Vector3): number {
		return this.plane.distanceToPoint(point);
	}

	// Apply a transformation to the plane
	applyTransformation(transform: Matrix4): void {
		this.origin.applyMatrix4(transform);
		this.normal.transformDirection(transform);
		this.planeU.transformDirection(transform);
		this.planeV.transformDirection(transform);
		this.plane.applyMatrix4(transform);
	}
}

// Utility function converting polygon of Vector3[] to a Float32Array
function polygonToArray(polygon: Vector3[]): Float32Array {
	const array = new Float32Array(polygon.length * 3);
	for (let i = 0; i < polygon.length; i++) {
		array[i * 3] = polygon[i].x;
		array[i * 3 + 1] = polygon[i].y;
		array[i * 3 + 2] = polygon[i].z;
	}
	return array;
}

// cached box corners for distancePlaneToBox, avoid repleated allocation
const boxCorners = [
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
	new Vector3(0, 0, 0),
];

// Compute distance from a plane to a box
function distancePlaneToBox(plane: Plane, box: Box3): number {
	// intersection means distance is 0
	if (box.intersectsPlane(plane)) return 0;

	// otherwise find the minimum distance from box corners to the plane
	boxCorners[0].set(box.min.x, box.min.y, box.min.z);
	boxCorners[1].set(box.min.x, box.min.y, box.max.z);
	boxCorners[2].set(box.min.x, box.max.y, box.min.z);
	boxCorners[3].set(box.min.x, box.max.y, box.max.z);
	boxCorners[4].set(box.max.x, box.min.y, box.min.z);
	boxCorners[5].set(box.max.x, box.min.y, box.max.z);
	boxCorners[6].set(box.max.x, box.max.y, box.min.z);
	boxCorners[7].set(box.max.x, box.max.y, box.max.z);
	let distance = Infinity;
	for (const corner of boxCorners) {
		const d = Math.abs(plane.distanceToPoint(corner));
		distance = Math.min(distance, d);
	}
	return distance;
}
