import { v4 as uuidv4 } from "uuid";

/** A global unique identifier for an iElement. */
export type GUID = string;

// Bitmask to mask individual byte
const BITMASK_BYTE = 0xff;

// The string length of the UUID portion in the GUID
const UUID_PORTION = 19;

/**
 * Generates a GUID that is accepted by the ProjectAPI.
 *
 * @param date Date to encode into the UUID
 * @returns Sequential version of an UUID v4, contains the timestamps encoded
 */
export function generateGUID(date = new Date()): GUID {
  const uuid = uuidv4();

  // Convert current date time to UTC ticks
  const ticks = unixTimestampToDotNetTicks(date.getTime());
  const tickBytes = longToBytes(ticks);

  // See https://github.com/dotnet/efcore/blob/main/src/EFCore/ValueGeneration/SequentialGuidValueGenerator.cs
  const encodedTimestamp =
    // code gets unreadable with template strings
    // eslint-disable-next-line prefer-template
    tickBytes[1].toString(16).padStart(2, "0") +
    tickBytes[0].toString(16).padStart(2, "0") +
    "-" +
    tickBytes[7].toString(16).padStart(2, "0") +
    tickBytes[6].toString(16).padStart(2, "0") +
    tickBytes[5].toString(16).padStart(2, "0") +
    tickBytes[4].toString(16).padStart(2, "0") +
    tickBytes[3].toString(16).padStart(2, "0") +
    tickBytes[2].toString(16).padStart(2, "0");

  return uuid.substring(0, UUID_PORTION) + encodedTimestamp;
}

/**
 * Taken from https://stackoverflow.com/a/12965194
 *
 * @param {number} long Number to convert
 * @returns {number[]} Resulting byte array
 */
function longToBytes(long: number): number[] {
  const byteArray = [0, 0, 0, 0, 0, 0, 0, 0];
  for (let index = 0; index < byteArray.length; index++) {
    const byte = long & BITMASK_BYTE;
    byteArray[index] = byte;
    long = (long - byte) / 256;
  }
  return byteArray;
}

/**
 * Converts a UNIX epoch timestamp to .net ticks
 *
 * This is necessary as the JavaScript Date type's origin is the Unix epoch (midnight on 1 January 1970),
 * while the .NET DateTime type's origin is midnight on 1 January 0001
 *
 * @param {number} unixTimestamp Timestamp to convert
 * @returns {number} .net time
 */
function unixTimestampToDotNetTicks(unixTimestamp: number): number {
  // the number of .net ticks at the unix epoch
  const epochTicks = 621355968000000000;

  // there are 10000 .net ticks per millisecond
  const ticksPerMillisecond = 10000;

  return unixTimestamp * ticksPerMillisecond + epochTicks;
}
