import { SpriteMaterial, ShaderLib, SpriteMaterialParameters, Texture, Vector2 } from "three";
import vert from "../Shaders/BillboardSprite.vert";
import frag from "../Shaders/OccludedObject.frag";
import { OcclusionAwareMaterial } from "./OcclusionAwareMaterial";
import { makeUniform } from "./Uniforms";

const DEFAULT_OCCLUDED_OPACITY = 0.2;

/**
 * A material to render icons as only billboard as annotations.
 * WARNING: this shader assumes perspective projection. More work is planned
 * to support also orthographic projection.
 */
export class BillboardSpriteMaterial extends SpriteMaterial implements OcclusionAwareMaterial {
	readonly isOcclusionAware = true;
	vertexShader: string;
	fragmentShader: string;
	isShaderMaterial = true;
	// As the BillboardSpriteMaterial is being used as a shaderMaterial by setting isShaderMaterial to true,
	// the variable uniformsGroups needs to be added because three js expects every shaderMaterial to have this.
	uniformsGroups = [];

	#size = 1;
	#minSize = 5;
	#maxSize = 30;

	uniforms = {
		...ShaderLib.sprite.uniforms,
		size: makeUniform(this.size),
		minSize: makeUniform(this.minSize),
		maxSize: makeUniform(this.maxSize),
		depthToNormalizedScreenHeight: makeUniform(1),
		occludedOpacity: makeUniform(DEFAULT_OCCLUDED_OPACITY),
		uDepthTexture: makeUniform<Texture | null>(null),
		drawOccluded: makeUniform(false),
		postProcessingPass: makeUniform(false),
		uOffset: makeUniform(new Vector2()),
	};

	/** @returns the current bound depth texture for occlusion queries */
	get depthTexture(): Texture | null {
		return this.uniforms.uDepthTexture.value;
	}

	/** Change the current bound depth texture for occlusion queries */
	set depthTexture(t: Texture | null) {
		this.uniforms.uDepthTexture.value = t;
	}

	/** @returns true if you want to render occluded parts */
	get drawOccluded(): boolean {
		return this.uniforms.drawOccluded.value;
	}

	/** Set to true to render occluded parts, requires depthTexture !== null */
	set drawOccluded(val: boolean) {
		this.uniforms.drawOccluded.value = val;
	}

	/** @returns true if you are using the post processing pass */
	get postProcessingPass(): boolean {
		return this.uniforms.postProcessingPass.value;
	}

	/** Set to true inside the post processing pass, to render the occluded parts */
	set postProcessingPass(val: boolean) {
		this.uniforms.postProcessingPass.value = val;
	}

	/** @returns The vertical size of the billboard sprite, in meters */
	public get size(): number {
		return this.#size;
	}

	/** Sets the vertical size of the billboard sprite, in meters */
	public set size(value: number) {
		if (value !== this.#size) {
			this.#size = value;
			this.uniforms.size.value = value;
		}
	}

	/** @returns the minimum verticalsize of the billboard sprite, in pixels */
	public get minSize(): number {
		return this.#minSize;
	}

	/** Sets the minimum verticalsize of the billboard sprite, in pixels */
	public set minSize(value: number) {
		if (value !== this.#minSize) {
			this.#minSize = value;
			this.uniforms.minSize.value = value;
		}
	}

	/** @returns the maximum verticalsize of the billboard sprite, in pixels */
	public get maxSize(): number {
		return this.#maxSize;
	}

	/** Sets the maximum verticalsize of the billboard sprite, in pixels */
	public set maxSize(value: number) {
		if (value !== this.#maxSize) {
			this.#maxSize = value;
			this.uniforms.maxSize.value = value;
		}
	}

	/** @returns the depth to normalized screen height perspective multiplier. */
	public get depthToNormalizedScreenHeight(): number {
		return this.uniforms.depthToNormalizedScreenHeight.value;
	}

	/**
	 * Sets  the depth to normalized screen height perspective multiplier.
	 * This is defined as 2.0 * tan(0.5 * fovy) / screenHeight for perspective projection.
	 * For ortho projection, it is defined as (top - bottom) / screenHeight
	 */
	public set depthToNormalizedScreenHeight(d: number) {
		this.uniforms.depthToNormalizedScreenHeight.value = d;
	}

	/** Set the 2D screen offset */
	public set offset(offset: Vector2) {
		this.uniforms.uOffset.value = offset;
	}

	/** @returns The 2D screen offset*/
	public get offset(): Vector2 {
		return this.uniforms.uOffset.value;
	}

	/**
	 * Construct an instance of BillboardSpriteMaterial
	 *
	 * @param parameters An object with one or more properties defining the material's appearance.
	 */
	constructor(parameters: Partial<SpriteMaterialParameters> = {}) {
		super();
		this.type = "BillboardSpriteMaterial";
		this.vertexShader = vert;
		this.fragmentShader = frag;
		this.depthWrite = false;
		this.setValues(parameters);
	}
}
