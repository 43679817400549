/**
 * Call an outer function directly to initialize scoped private data.
 *
 * This can be useful to initialize a memory allocation and then reuse that memory for the inner function.
 * The advantage of using this pattern is that the temporary variables don't pollute the namespace of
 * the class and without the risk of other functions accessing the temporary data.
 *
 * @example
 * class Foo {
 *   computeWorldDirection = memberWithPrivateData(() => {
 *     // Allocate data which can be reused in the inner function
 *     const worldPos = new Vector3();
 *
 *     // The inner function, which can be called normally through the class
 *     return (camera: Camera) => {
 *        // Reuse the allocated data.
 *        this.camera.getWorldPosition(worldPos);
 *
 *        // Calculate something with `worldPos`
 *     }
 *   });
 * }
 * @param f The function to allocate the private data, which returns an inner function that can use this private data.
 * @returns The inner function that can use the private data.
 */
export function memberWithPrivateData<T extends (...args: never[]) => unknown>(f: () => T): T {
	return f();
}
