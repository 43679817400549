uniform vec3 direction;
uniform float thickness;
uniform vec2 viewport;

#include <common>
#include <uv_pars_vertex>
#include <fog_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

void main() {

    #include <uv_vertex>
    
    // Compute the world position of the middle point of the segment
    vec4 worldPosition = modelMatrix * vec4(0.0, 0.0, 0.0, 1.0);
    
    // Project the world position on screen and normalize the coordinate by the w component. 
    // The normalization is fundamental, since it removes any dependency from the distance
    // to the camera when using a perspective one.
    vec4 pvmPosition = projectionMatrix * viewMatrix * worldPosition;
    pvmPosition /= pvmPosition.w;

    // Comput the end point of the segment (and normalize it too!)
    vec4 pvmTarget = projectionMatrix * viewMatrix * (worldPosition + vec4(direction * position.x, 0.0));
    pvmTarget /= pvmTarget.w;

    // Compute how much space the segment thickness occupies in NDC
    float offset = thickness / viewport.x;
    float aspectRatio = viewport.x / viewport.y;

    // Compute the segment direction in ndc (multiplied by position.x to take into account the sign)
    vec4 pvmDirection = (pvmTarget - pvmPosition) * position.x;

    // Compute the orthogonal direction to the segment direction in ndc
    vec4 pvmOrthogonal = normalize(vec4(-pvmDirection.y, pvmDirection.x * aspectRatio, 0.0, 0.0));

    // Scale the orthogonal direction by the thickness and adjust the aspect ratio along y
    pvmOrthogonal = vec4(pvmOrthogonal.x * offset, pvmOrthogonal.y * offset * aspectRatio, 0.0, 0.0) * position.y;

    // Compute the final piel position by adding the orthogonal vector to the segment end point
    pvmTarget += pvmOrthogonal;

    gl_Position = pvmTarget;
    
    #include <logdepthbuf_vertex>
    #include <clipping_planes_vertex>
    #include <fog_vertex>
}