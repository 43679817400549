precision highp float;

#ifdef ORTHO_PROJECTION

float viewZToDepth( const in float viewZ, const in float near, const in float far ) {
	return ( viewZ + near ) / ( near - far );
}
float depthToViewZ( const in float linearClipZ, const in float near, const in float far ) {
	return linearClipZ * ( near - far ) - near;
}

#else

float viewZToDepth( const in float viewZ, const in float near, const in float far ) {
	return ( ( near + viewZ ) * far ) / ( ( far - near ) * viewZ );
}
float depthToViewZ( const in float invClipZ, const in float near, const in float far ) {
	return ( near * far ) / ( ( far - near ) * invClipZ - far );
}

#endif

uniform sampler2D depthTex1;
uniform sampler2D depthTex2;
uniform sampler2D depthTex4;
uniform sampler2D depthTex16;

uniform sampler2D colorTex1;
uniform sampler2D colorTex2;
uniform sampler2D colorTex4;

uniform vec2 texSize1;

uniform float nearPlane;
uniform float farPlane;

uniform bool debugColors;

#ifdef ORTHO_PROJECTION

uniform int depthLevel;
uniform float finalDepthBias;

#else

uniform float depthToFrustumHeight;

#endif

out vec4 outColor;

in vec2 vUv;

const float DEPTH_EQUALITY_THRESHOLD = 0.1;

const uint N_EMPTY = 0u;
const uint N_BELOW = 1u;
const uint N_ABOVE = 2u;
const uint N_OK = N_BELOW | N_ABOVE;   

const uint k_BottomRow = 3u;
const uint k_TopRow = 12u;
const uint k_LeftColumn = 5u;
const uint k_RightColumn = 10u;
const uint k_BL_TR_Diagonal = 9u;
const uint k_TL_BR_Diagonal = 6u;

#ifdef OPTIMIZED_FOR_GAP_SIZE
const int MAX_BRIDGE_DIST = 5;
const int GAP_FILL_CONFIDENCE_FALLOFF = 7;
#else
const int MAX_BRIDGE_DIST = 3;
const int GAP_FILL_CONFIDENCE_FALLOFF = 5;
#endif
const int GAP_FILL_CONFIDENCE_INTERVAL = 2 * MAX_BRIDGE_DIST + 1 - GAP_FILL_CONFIDENCE_FALLOFF;

uint hasRow(const in uint planeLayout, const in int row) {
    uint mask = k_BottomRow << (2 * row);
    return (planeLayout & mask) != 0u ? N_OK : N_ABOVE >> row;
}

uint hasColumn(const in uint planeLayout, const in int col) {
    uint mask = k_LeftColumn << col;
    return (planeLayout & mask) != 0u ? N_OK : N_ABOVE >> col;
}

const int leftOrRight[2] = int[2](1, -1);

const int topOrBottom[2] = int[2](1, -1);

void main() {
    // vUv.x goes from left screen to right screen. vUv.y goes from bottom screen to top screen.
    // Computing current pixel's coordinates at every level of resolution.
    ivec2 pix1 = ivec2(vUv * texSize1);
    ivec2 pix2_0 = pix1 / 2;
    ivec2 pix4_0 = pix2_0 / 2;
    ivec2 pix8_0 = pix4_0 / 2;
    ivec2 pix16_0 = pix8_0 / 2;

    vec4 origColor = texelFetch(colorTex1, pix1, 0); // to copy the background color

    // read the depth at 16x16 subsampling
    float depth16 = texelFetch(depthTex16, pix16_0, 0).r;
    // if even the 16x16 pix is empty, discard the fragment
    if(depth16 == 1.0) {
        outColor = origColor;
        gl_FragDepth = 1.0;
        return;
    }
    // Read the depth at max resolution
    float depth = texelFetch(depthTex1, pix1, 0).r;
    // In the 'camDepth' variable we store the depth of the current fragment in camera coordinates.
    // This will be used as a threshold: neighboring pixels with camera depth higher than 'camDepth'
    // are candidates to 'build a bridge' across our pixel and therefore to fill it.
    float camDepth = depthToViewZ(depth, nearPlane, farPlane);
    float camDepth16 = depthToViewZ(depth16, nearPlane, farPlane);
    //if pix at max res and pix at max subsampling belong to the
    //same surface, finished! No need to gapfill.
    if(abs(camDepth - camDepth16) < DEPTH_EQUALITY_THRESHOLD) {
        outColor = origColor;
        gl_FragDepth = depth;
        return;
    }

#ifndef ORTHO_PROJECTION
    float frustumHeight = -camDepth16 * depthToFrustumHeight;
    float maxGapSizePix = texSize1.y * 0.07 / frustumHeight; //here we assume the max hole size to be gapfillable is 7 cm
    // 'depthLevel' can be 0 (farthest group), 1, 2, and 3 (closest group). Different heuristics are applied
    // to compute the neighbourhood of pixels according to their 'depthLevel'
    int depthLevel = maxGapSizePix > 2.0 ? (maxGapSizePix > 3.0 ? (maxGapSizePix > 6.0 ? 3 : 2) : 1) : 0;

    // To determine whether a pixel should be gap-filled or not, we want to keep only really good depths.
    // Why the magic number 19.2 = 2.4 * 8 below? Beacuse of a lot of experiments.
    // If it is higher than 19.2, then color will start to bleed from surfaces in the back to the front.
    // If it is lower than 19.2, then forward points in depth discontinuities will fill the color of
    // their neighbors that are a bit in the back.
    float finalDepthBias = max(DEPTH_EQUALITY_THRESHOLD, 19.2 * ( frustumHeight / texSize1.y ));

#endif

    // We add a 'finalDepthBias' quantity to camDepth. Therefore, we take as potential bridges
    // only pixels that are really in front of the current pixel, to be sure that we are not 
    // gap-filling a pixel on a depth discontinuity.
    camDepth += finalDepthBias;

    // The four variables below are also used to determine whether a pixel should be gap-filled or not,
    // and are also used to determine at which depth the surface that we want to reconstruct is located.
    float topDepth = -farPlane;
    float bottomDepth = -farPlane;
    float leftDepth = -farPlane;
    float rightDepth = -farPlane;
    
    // We store one mask for each direction about the neighbor quality! binary 01: below. binary 10: above. binary 11: ok!
    uint topQuality = N_EMPTY;
    uint bottomQuality = N_EMPTY;
    uint leftQuality = N_EMPTY;
    uint rightQuality = N_EMPTY;

    int topDistance = MAX_BRIDGE_DIST;
    int bottomDistance = MAX_BRIDGE_DIST;
    int leftDistance = MAX_BRIDGE_DIST;
    int rightDistance = MAX_BRIDGE_DIST;

    // Here we allocate three arrays to store the color and depth values of the neighbors we visit, even if they are farther than
    // our 'camDepth' threshold. To select which of these neighbors contribute to the final color and depth, we use different
    // thresholds than the threshold used to determine the 'bridging' neighbors.
#ifdef OPTIMIZED_FOR_GAP_SIZE
    float camDepths[24] = float[24](0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                                    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0);
    vec3 colors[24] = vec3[24]( vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), 
                                vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), 
                                vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), 
                                vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0));
    float weights[24] = float[24](0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
                                  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0);
#else
    float camDepths[12] = float[12](0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0);
    vec3 colors[12] = vec3[12]( vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), 
                                vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0),
                                vec3(0.0, 0.0, 0.0), vec3(0.0, 0.0, 0.0) );
    float weights[12] = float[12](0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0);
#endif
    int samples = 0;

    ivec2 double_pix2_0 = pix2_0 * 2;
    ivec2 whereAmI = pix1 - double_pix2_0;

    // Through the shader, we fetch some pixels at given resolutions. We use this naming convention:
    // pix'm'_'n' => coordinates of the 'n'-th pixel fetched at level of detail 'm'x'm'
    // Examples: 
    //       pix4_3: third neighbor pixel collected at resolution 4x4
    //       camDepth2_4: depth in camera coordinates of fourth neighbor pixel collected at resolution 2x2
    //       pix2_0: coordinates that the current fragment has at resolutionn 2x2
    ivec2 pix1_1 = pix1 + ivec2(leftOrRight[whereAmI.x], 0);
    ivec2 pix1_2 = pix1 + ivec2(0, topOrBottom[whereAmI.y]);
    float camDepth1_1 = depthToViewZ(texelFetch(depthTex1, pix1_1, 0).r, nearPlane, farPlane);
    float camDepth1_2 = depthToViewZ(texelFetch(depthTex1, pix1_2, 0).r, nearPlane, farPlane);

    if(camDepth1_1 > camDepth) {
        if(pix1_1.x - pix1.x > 0) {
            rightDepth = camDepth1_1;
            rightQuality = N_OK;
            rightDistance = 1;
        }
        else {
            leftDepth = camDepth1_1;
            leftQuality = N_OK;
            leftDistance = 1;
        }
    }
    weights[samples] = 1.0;
    colors[samples] = texelFetch(colorTex1, pix1_1, 0).rgb;
    camDepths[samples] = camDepth1_1;
    samples++;

    if(camDepth1_2 > camDepth) {
        if(pix1_2.y - pix1.y > 0) {
            topDepth = camDepth1_2;
            topQuality = N_OK;
            topDistance = 1;
        }
        else {
            bottomDepth = camDepth1_2;
            bottomQuality = N_OK;
            bottomDistance = 1;
        }
    }
    weights[samples] = 1.0;
    colors[samples] = texelFetch(colorTex1, pix1_2, 0).rgb;
    camDepths[samples] = camDepth1_2;
    samples++;

    ivec2 pix2_1 = pix2_0 + ivec2(1, 0);
    ivec2 pix2_2 = pix2_0 + ivec2(0, 1);
    ivec2 pix2_3 = pix2_0 + ivec2(-1, 0);
    ivec2 pix2_4 = pix2_0 + ivec2(0, -1);
    float camDepth2_1 = depthToViewZ(texelFetch(depthTex2, pix2_1, 0).r, nearPlane, farPlane);
    float camDepth2_2 = depthToViewZ(texelFetch(depthTex2, pix2_2, 0).r, nearPlane, farPlane);
    float camDepth2_3 = depthToViewZ(texelFetch(depthTex2, pix2_3, 0).r, nearPlane, farPlane);
    float camDepth2_4 = depthToViewZ(texelFetch(depthTex2, pix2_4, 0).r, nearPlane, farPlane);

    weights[samples] = 0.25;
    vec4 tf = texelFetch(colorTex2, pix2_1, 0);
    colors[samples] = tf.rgb;
    camDepths[samples] = camDepth2_1;
    if(camDepth2_1 > camDepth) {
        uint planeLayout = uint(tf.a);
        rightQuality |= hasRow(planeLayout, whereAmI.y);
        rightDepth = max(rightDepth, camDepth2_1);
        rightDistance = min(rightDistance, 2 - whereAmI.x + (((planeLayout & k_LeftColumn) == 0u) ? 1 : 0));
    }
    samples++;

    weights[samples] = 0.25;
    tf = texelFetch(colorTex2, pix2_2, 0);
    colors[samples] = tf.rgb;
    camDepths[samples] = camDepth2_2;
    if(camDepth2_2 > camDepth) {
        uint planeLayout = uint(tf.a);
        topQuality |= hasColumn(planeLayout, whereAmI.x);
        topDepth = max(topDepth, camDepth2_2);
        topDistance = min(topDistance, 2 - whereAmI.y + (((planeLayout & k_BottomRow) == 0u) ? 1 : 0));
    }
    samples++;

    weights[samples] = 0.25;
    tf = texelFetch(colorTex2, pix2_3, 0);
    colors[samples] = tf.rgb;
    camDepths[samples] = camDepth2_3;
    if(camDepth2_3 > camDepth) {
        uint planeLayout = uint(tf.a);
        leftQuality |= hasRow(planeLayout, whereAmI.y);
        leftDepth = max(leftDepth, camDepth2_3);
        leftDistance = min(leftDistance, whereAmI.x + 1 + (((planeLayout & k_RightColumn) == 0u) ? 1 : 0));
    }
    samples++;

    weights[samples] = 0.25;
    tf = texelFetch(colorTex2, pix2_4, 0);
    colors[samples] = tf.rgb;
    camDepths[samples] = camDepth2_4;
    if(camDepth2_4 > camDepth) {
        uint planeLayout = uint(tf.a);
        bottomQuality |= hasColumn(planeLayout, whereAmI.x);
        bottomDepth = max(bottomDepth, camDepth2_4);
        bottomDistance = min(bottomDistance, whereAmI.y + 1 + (((planeLayout & k_TopRow) == 0u) ? 1 : 0));
    }
    samples++;

    if(depthLevel >= 1)  {
        int whereAmIdx = whereAmI.x + 2 * whereAmI.y;
        // pixel has medium distance to camera. Let's enlarge the neigbourhood search by just a bit.
        ivec2 pix2_5 = pix2_0 + ivec2(1, 1);
        ivec2 pix2_6 = pix2_0 + ivec2(-1, 1);
        ivec2 pix2_7 = pix2_0 + ivec2(-1, -1);
        ivec2 pix2_8 = pix2_0 + ivec2(1, -1);
        float camDepth2_5 = depthToViewZ(texelFetch(depthTex2, pix2_5, 0).r, nearPlane, farPlane);
        float camDepth2_6 = depthToViewZ(texelFetch(depthTex2, pix2_6, 0).r, nearPlane, farPlane);
        float camDepth2_7 = depthToViewZ(texelFetch(depthTex2, pix2_7, 0).r, nearPlane, farPlane);
        float camDepth2_8 = depthToViewZ(texelFetch(depthTex2, pix2_8, 0).r, nearPlane, farPlane);

        weights[samples] = 0.125;
        vec4 tf = texelFetch(colorTex2, pix2_5, 0);
        colors[samples] = tf.rgb;
        camDepths[samples] = camDepth2_5;
        if(camDepth2_5 > camDepth) {
            uint planeLayout = uint(tf.a);
            if(whereAmIdx != 2) {
                topQuality |= N_ABOVE;
                topDepth = max(topDepth, camDepth2_5);
                topDistance = min(topDistance, 2 - whereAmI.y + (((planeLayout & k_BottomRow) == 0u) ? 1 : 0));
            }
            if(whereAmIdx != 1) {
                rightQuality |= N_ABOVE;
                rightDepth = max(rightDepth, camDepth2_5);
                rightDistance = min(rightDistance, 2 - whereAmI.x + (((planeLayout & k_LeftColumn) == 0u) ? 1 : 0));
            }
        }
        samples++;

        weights[samples] = 0.125;
        tf = texelFetch(colorTex2, pix2_6, 0);
        colors[samples] = tf.rgb;
        camDepths[samples] = camDepth2_6;
        if(camDepth2_6 > camDepth) {
            uint planeLayout = uint(tf.a);
            if(whereAmIdx != 3) {
                topQuality |= N_BELOW;
                topDepth = max(topDepth, camDepth2_6);
                topDistance = min(topDistance, 2 - whereAmI.y + (((planeLayout & k_BottomRow) == 0u) ? 1 : 0));
            }
            if(whereAmIdx != 0) {
                leftQuality |= N_ABOVE;
                leftDepth = max(leftDepth, camDepth2_6);
                leftDistance = min(leftDistance, whereAmI.x + 1 + (((planeLayout & k_RightColumn) == 0u) ? 1 : 0));
            }
        }
        samples++;

        weights[samples] = 0.125;
        tf = texelFetch(colorTex2, pix2_7, 0);
        colors[samples] = tf.rgb;
        camDepths[samples] = camDepth2_7;
        if(camDepth2_7 > camDepth) {
            uint planeLayout = uint(tf.a);
            if(whereAmIdx != 1) {
                bottomQuality |= N_BELOW;
                bottomDepth = max(bottomDepth, camDepth2_7);
                bottomDistance = min(bottomDistance, whereAmI.y + 1 + (((planeLayout & k_TopRow) == 0u) ? 1 : 0));
            }
            if(whereAmIdx != 2) {
                leftQuality |= N_BELOW;
                leftDepth = max(leftDepth, camDepth2_7);
                leftDistance = min(leftDistance, whereAmI.x + 1 + (((planeLayout & k_RightColumn) == 0u) ? 1 : 0));
            }
        }
        samples++;

        weights[samples] = 0.125;
        tf = texelFetch(colorTex2, pix2_8, 0);
        colors[samples] = tf.rgb;
        camDepths[samples] = camDepth2_8;
        if(camDepth2_8 > camDepth) {
            uint planeLayout = uint(tf.a);
            if(whereAmIdx != 0) {
                bottomQuality |= N_ABOVE;
                bottomDepth = max(bottomDepth, camDepth2_8);
                bottomDistance = min(bottomDistance, whereAmI.y + 1 + (((planeLayout & k_TopRow) == 0u) ? 1 : 0));
            }
            if(whereAmIdx != 3) {
                rightQuality |= N_BELOW;
                rightDepth = max(rightDepth, camDepth2_8);
                rightDistance = min(rightDistance, 2 - whereAmI.x + (((planeLayout & k_LeftColumn) == 0u) ? 1 : 0));
            }
        }
        samples++;

    }

#ifdef OPTIMIZED_FOR_GAP_SIZE
    if(depthLevel > 1) {
        // we have time: visit more 2x2 pixels.
        //Three to the right
        weights[samples] = 0.0625;
        ivec2 pix2_x = pix2_0 + ivec2(2, -1);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        float newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            rightQuality |= N_BELOW;
            rightDepth = max(rightDepth, newCamDepth);
            rightDistance = min(rightDistance, 4 - whereAmI.x + (((planeLayout & k_LeftColumn) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(2, 0);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            rightQuality |= hasRow(planeLayout, whereAmI.y);
            rightDepth = max(rightDepth, newCamDepth);
            rightDistance = min(rightDistance, 4 - whereAmI.x + (((planeLayout & k_LeftColumn) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(2, 1);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            rightQuality |= N_ABOVE;
            rightDepth = max(rightDepth, newCamDepth);
            rightDistance = min(rightDistance, 4 - whereAmI.x + (((planeLayout & k_LeftColumn) == 0u) ? 1 : 0));
        }
        samples++;

        // Three to the top
        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(1, 2);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            topQuality |= N_ABOVE;
            topDepth = max(topDepth, newCamDepth);
            topDistance = min(topDistance, 4 - whereAmI.y + (((planeLayout & k_BottomRow) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(0, 2);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            topQuality |= hasColumn(planeLayout, whereAmI.x);
            topDepth = max(topDepth, newCamDepth);
            topDistance = min(topDistance, 4 - whereAmI.y + (((planeLayout & k_BottomRow) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(-1, 2);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            topQuality |= N_BELOW;
            topDepth = max(topDepth, newCamDepth);
            topDistance = min(topDistance, 4 - whereAmI.y + (((planeLayout & k_BottomRow) == 0u) ? 1 : 0));
        }
        samples++;

        // Three to the left
        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(-2, 1);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            leftQuality |= N_ABOVE;
            leftDepth = max(leftDepth, newCamDepth);
            leftDistance = min(leftDistance, whereAmI.x + 3 + (((planeLayout & k_RightColumn) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(-2, 0);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            leftQuality |= hasRow(planeLayout, whereAmI.y);
            leftDepth = max(leftDepth, newCamDepth);
            leftDistance = min(leftDistance, whereAmI.x + 3 + (((planeLayout & k_RightColumn) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(-2, -1);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            leftQuality |= N_BELOW;
            leftDepth = max(leftDepth, newCamDepth);
            leftDistance = min(leftDistance, whereAmI.x + 3 + (((planeLayout & k_RightColumn) == 0u) ? 1 : 0));
        }
        samples++;

        // Three to the bottom
        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(1, -2);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            bottomQuality |= N_ABOVE;
            bottomDepth = max(bottomDepth, newCamDepth);
            bottomDistance = min(bottomDistance, whereAmI.y + 3 + (((planeLayout & k_TopRow) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(0, -2);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            bottomQuality |= hasColumn(planeLayout, whereAmI.x);
            bottomDepth = max(bottomDepth, newCamDepth);
            bottomDistance = min(bottomDistance, whereAmI.y + 3 + (((planeLayout & k_TopRow) == 0u) ? 1 : 0));
        }
        samples++;

        weights[samples] = 0.0625;
        pix2_x = pix2_0 + ivec2(-1, -2);
        tf = texelFetch(colorTex2, pix2_x, 0);
        colors[samples] = tf.rgb;
        newCamDepth = depthToViewZ(texelFetch(depthTex2, pix2_x, 0).r, nearPlane, farPlane);
        camDepths[samples] = newCamDepth;
        if(newCamDepth > camDepth) {
            uint planeLayout = uint(tf.a);
            bottomQuality |= N_BELOW;
            bottomDepth = max(bottomDepth, newCamDepth);
            bottomDistance = min(bottomDistance, whereAmI.y + 3 + (((planeLayout & k_TopRow) == 0u) ? 1 : 0));
        }
        samples++;
    }
#endif

    int vertDistance = topDistance + bottomDistance;
    int horizDistance = leftDistance + rightDistance;
    bool bridge1 = topQuality != N_EMPTY && bottomQuality != N_EMPTY && 
            (topQuality == N_OK || bottomQuality == N_OK) && 
            (vertDistance <= MAX_BRIDGE_DIST);
    bool bridge2 = leftQuality != N_EMPTY && rightQuality != N_EMPTY && 
            (leftQuality == N_OK || rightQuality == N_OK) && 
            (horizDistance <= MAX_BRIDGE_DIST);

    bool gapFillable = bridge1  || bridge2;
    if(!gapFillable) {
        outColor = origColor;
        gl_FragDepth = depth;
        return;
    }

    //Good. Now we need to determine which color to fill our pixel with, and which depth to assign to it.
    //We need to avoid blending in pixels that are either behind or in front of the desired surface to reconstruct.

    //Also, we move away from a black-and-white determination of whether a pixel is gapfillable or not,
    //and we create a measure of confidence that a pixels is to be gapfilled.

    float gapFilledCamDepth = 0.0;
    vec3 gapFilledColor = vec3(0.0, 0.0, 0.0);

    float blendingDepth = 0.0;
    if(bridge1 && bridge2) {
        blendingDepth = max(min(topDepth, bottomDepth), min(leftDepth, rightDepth));
    }
    else if(bridge1) {
        blendingDepth = min(topDepth, bottomDepth);
    }
    else if(bridge2) {
        blendingDepth = min(leftDepth, rightDepth);
    }
    // without the 0.9 coefficient below, colors that are in the back start bleeding to the front.
    float minBlendingDepth = blendingDepth - 0.9 * (finalDepthBias + DEPTH_EQUALITY_THRESHOLD);
    float maxBlendingDepth = blendingDepth + DEPTH_EQUALITY_THRESHOLD;
    float sumWeights = 0.0;
    for(int s = 0; s < samples; ++s) {
        float currD = camDepths[s];
        // We blend in only neighboring pixels that have camera depth in between 'minBlendingDepth' and 'maxBlendingDepth'
        if(currD > minBlendingDepth && currD < maxBlendingDepth) {
            float currW = weights[s];
            gapFilledColor += colors[s] * currW;
            gapFilledCamDepth += currD * currW;
            sumWeights += currW;
        }
    }
    float invW = 1.0 / sumWeights;
    gapFilledColor *= invW;
    gapFilledCamDepth *= invW;

    int gapSize = (bridge1 ? vertDistance : MAX_BRIDGE_DIST) + (bridge2 ? horizDistance : MAX_BRIDGE_DIST);
    float gapFillConfidence = clamp(float(GAP_FILL_CONFIDENCE_INTERVAL - (gapSize - GAP_FILL_CONFIDENCE_FALLOFF)) / 
        float(GAP_FILL_CONFIDENCE_INTERVAL), 0.0, 1.0);

    if(debugColors) {
        // switch(depthLevel) {
        //     case 0: outColor = vec4(1.0, 0.0, 0.0, 1.0); break;
        //     case 1: outColor = vec4(1.0, 1.0, 0.0, 1.0); break;
        //     case 2: outColor = vec4(0.0, 1.0, 0.0, 1.0); break;
        //     case 3: outColor = vec4(0.0, 1.0, 1.0, 1.0); break;
        // }
        // if(bridge1 && bridge2) outColor = vec4(1.0, 1.0, 0.0, 1.0);
        // else if(bridge1) outColor = vec4(1.0, 0.0, 0.0, 1.0);
        // else outColor = vec4(0.0, 1.0, 0.0, 1.0);
        // int whereAmIdx = whereAmI.x + 2 * whereAmI.y;
        // switch(whereAmIdx) {
        //     case 0: outColor = vec4(1.0, 0.0, 0.0, 1.0); break;
        //     case 1: outColor = vec4(1.0, 1.0, 0.0, 1.0); break; // bottom right appears jittered in top-to-bottom and left-to-right depth discs
        //     case 2: outColor = vec4(0.0, 1.0, 0.0, 1.0); break; // top left appears jittered in bottom-to-top and right-to-left depth discs 
        //     case 3: outColor = vec4(0.0, 1.0, 1.0, 1.0); break;
        // }
#ifdef OPTIMIZED_FOR_GAP_SIZE
        switch(gapSize) {
            case 1:
            case 2: 
            case 3:
            case 4: 
            case 5: outColor = vec4(1.0, 0.0, 1.0, 1.0); break;
            case 6: outColor = vec4(0.0, 0.0, 1.0, 1.0); break;
            case 7: outColor = vec4(0.0, 1.0, 1.0, 1.0); break;
            case 8: outColor = vec4(0.0, 1.0, 0.0, 1.0); break;
            case 9: outColor = vec4(1.0, 1.0, 0.0, 1.0); break;
            default: outColor = vec4(1.0, 0.0, 0.0, 1.0); break;
        }
#else
        switch(gapSize) {
            case 1:
            case 2: 
            case 3: outColor = vec4(1.0, 0.0, 1.0, 1.0); break;
            case 4: outColor = vec4(0.0, 0.0, 1.0, 1.0); break;
            case 5: outColor = vec4(0.0, 1.0, 0.0, 1.0); break;
            default: outColor = vec4(1.0, 0.0, 0.0, 1.0); break;
        }
#endif
    }
    else {
        float outAlpha = gapFillConfidence + (1.0 - gapFillConfidence) * origColor.a;
        outColor = vec4(gapFilledColor * gapFillConfidence + origColor.rgb * (1.0 - gapFillConfidence), outAlpha);
    }
    gl_FragDepth = viewZToDepth(gapFilledCamDepth, nearPlane, farPlane);
}

