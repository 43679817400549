import { TypedEvent } from "@faro-lotv/foundation";

/**
 * Generic animation interface
 */
export abstract class LotvAnimation {
	/** Check if the animation is canceled */
	#canceled = false;

	/** Check if the animation started */
	#started = false;

	/**
	 * Signal to notify the animation started
	 */
	started = new TypedEvent<LotvAnimation>();

	/**
	 * Signal to notify the animation finished
	 */
	completed = new TypedEvent<LotvAnimation>();

	/**
	 * Update the animation
	 *
	 * @param elapsed Seconds passed from last update
	 * @returns true if this update completed the animation
	 */
	abstract update(elapsed: number): boolean;

	/**
	 * Cancel the animation
	 */
	cancel(): void {
		this.#canceled = true;
	}

	/**
	 * @returns True if the animation has been canceled
	 */
	get canceled(): boolean {
		return this.#canceled;
	}

	/**
	 * Call in update, check if the animation just started and emit the relative event
	 */
	startIfNeeded(): void {
		if (!this.#started) {
			this.#started = true;
			this.started.emit(this);
		}
	}

	/**
	 * @returns true if this animation already started
	 */
	get isStarted(): boolean {
		return this.#started;
	}

	/**
	 * Return a new sequence animation catenating this animation with a new one
	 *
	 * @param anim The animation to append
	 * @returns A sequence animation [this, anim]
	 */
	afterThen(anim: LotvAnimation): SequenceAnimation {
		return new SequenceAnimation([this, anim]);
	}

	/**
	 * Return a new parallel animation between this and anim
	 *
	 * @param anim The animation to run in parallel to this one
	 * @returns A parallel animation [this, anim]
	 */
	while(anim: LotvAnimation): ParallelAnimation {
		return new ParallelAnimation([this, anim]);
	}
}

/**
 * Animation that does nothing for a certain time
 */
export class VoidAnimation extends LotvAnimation {
	/**
	 * Time passed from the start of the animation
	 */
	time = 0;

	/**
	 * Construct a void animation with a given duration
	 *
	 * @param duration The animation duration in seconds
	 */
	constructor(private duration: number) {
		super();
	}

	/**
	 * Increment the overall animation time
	 *
	 * @param elapsed The time passed from the last update, in seconds
	 * @returns True if the animation is completed
	 */
	update(elapsed: number): boolean {
		this.time += elapsed;

		if (this.time >= this.duration || this.canceled) {
			this.completed.emit(this);
			return true;
		}
		return false;
	}
}

/**
 * Animation that just waits for a promise to be resolved
 */
export class WaitForPromiseAnimation extends LotvAnimation {
	/**
	 * Flag specifying if the animation finished
	 */
	#done = false;

	/**
	 * Construct an animation that depends for a specific promise
	 *
	 * @param promise The promise which the animation waits for.
	 */
	constructor(promise: Promise<unknown>) {
		super();
		promise.then(() => (this.#done = true)).catch(() => (this.#done = true));
	}

	/**
	 * Check if the promise has been resolved (or rejected)
	 *
	 * @returns True if the animation finished
	 */
	update(): boolean {
		if (this.#done || this.canceled) {
			this.completed.emit(this);
			return true;
		}
		return false;
	}

	/**
	 * Cancel the animation
	 */
	cancel(): void {
		super.cancel();
		this.#done = true;
	}
}

/**
 * Utility function to cancel a list of animations
 *
 * @param animations The list of animations to cancel
 */
function cancelAnimations(animations: LotvAnimation[]): void {
	for (const animation of animations) {
		animation.cancel();
	}
}

/**
 * An animation collecting sequential animations
 */
export class SequenceAnimation extends LotvAnimation {
	/**
	 * The index of the animation currently running
	 */
	index = 0;

	/**
	 * Construct an animation composed by a list of sequential animations
	 *
	 * @param animations The list of sequential animations
	 */
	constructor(private animations: LotvAnimation[]) {
		super();
	}

	/**
	 * Append a new animation at the end of the current sequence
	 *
	 * @param animation The new animation to add to the sequence
	 * @returns this
	 */
	append(animation: LotvAnimation): this {
		if (this.isStarted) throw new Error("Unable to append animation to an already started SequenceAnimation");
		this.animations.push(animation);
		return this;
	}

	/**
	 * Update the animation
	 *
	 * @param elapsed The time passed from the last update, in seconds
	 * @returns True if all the animations in the sequence are completed
	 */
	update(elapsed: number): boolean {
		if (this.animations[this.index]?.update(elapsed)) {
			this.index++;
		}
		if (this.index >= this.animations.length || this.canceled) {
			this.completed.emit(this);
			return true;
		}
		return false;
	}

	/**
	 * Cancel all the animations
	 */
	cancel(): void {
		super.cancel();
		cancelAnimations(this.animations);
	}

	/**
	 * @inheritdoc
	 */
	override afterThen(anim: LotvAnimation): this {
		return this.append(anim);
	}
}

/**
 * An animation collecting parallel animations
 */
export class ParallelAnimation extends LotvAnimation {
	/**
	 * Construct an animation composed by a list of concurrent animations
	 *
	 * @param animations The list of concurrent animations
	 */
	constructor(private animations: LotvAnimation[]) {
		super();
	}

	/**
	 * Append another animation to this parallel animation
	 *
	 * @param anim The animation to add
	 * @returns this
	 */
	append(anim: LotvAnimation): this {
		if (this.isStarted) throw new Error("Unable to append animation to an already started ParallelAnimation");
		this.animations.push(anim);
		return this;
	}

	/**
	 * Update all the animations in the collection
	 *
	 * @param elapsed The time passed from the last update, in seconds
	 * @returns True if all the animations are completed
	 */
	update(elapsed: number): boolean {
		const running = new Array<LotvAnimation>();

		for (const anim of this.animations) {
			if (!anim.update(elapsed)) {
				running.push(anim);
			}
		}

		this.animations = running;
		if (running.length === 0 || this.canceled) {
			this.completed.emit(this);
			return true;
		}
		return false;
	}

	/**
	 * Cancel all the animations
	 */
	cancel(): void {
		super.cancel();
		cancelAnimations(this.animations);
	}

	/**
	 * @inheritdoc
	 */
	override while(anim: LotvAnimation): this {
		return this.append(anim);
	}
}

/**
 * An object that can combine multiple animations and execute all of them
 */
export class AnimationManager {
	/**
	 * The list of animations managed by this object
	 */
	animations: LotvAnimation[] = [];

	/**
	 * Add an animation to the list of the animations
	 *
	 * @param animation The animation to the list
	 */
	run(animation: LotvAnimation): void {
		this.animations.push(animation);
	}

	/**
	 * Update all the animations
	 *
	 * @param elapsed The time passed from the last update, in seconds
	 */
	update(elapsed: number): void {
		const running = new Array<LotvAnimation>();

		for (const anim of this.animations) {
			anim.startIfNeeded();
			if (!anim.update(elapsed)) {
				running.push(anim);
			}
		}

		this.animations = running;
	}

	/**
	 * @returns False if at least one animation is still running.
	 */
	get running(): boolean {
		return this.animations.length > 0;
	}
}
