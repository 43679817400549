import { GUID, IElementGenericMarkupField } from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddMarkupField<
  Field extends IElementGenericMarkupField = IElementGenericMarkupField,
> extends BaseMutation {
  /** New MarkupField to add to a Markup element */
  newElement: CreateIElement<Field>;
}

/**
 * Adds a new MarkupField to a Markup
 *
 *```
 * Markup (Markup)
 * └ MarkupField
 *
 * ```
 *
 * @param markupId id of the markup to add the field to
 * @param newField to add to the markup
 * @returns A MutationAddMarkupField object
 */
export function createMutationAddMarkupField<
  Field extends IElementGenericMarkupField = IElementGenericMarkupField,
>(
  markupId: GUID,
  newField: MutationAddMarkupField<Field>["newElement"],
): MutationAddMarkupField<Field> {
  return {
    ...createBaseMutation(MutationTypes.MutationAddMarkupField, markupId),
    newElement: newField,
  };
}
