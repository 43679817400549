import { AutoLinkNode, LinkNode } from "@lexical/link";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  LINK,
} from "@lexical/markdown";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { SxProps, Theme } from "@mui/material";
import {
  AnchorPointPlugin,
  createAnchorPoint,
  DEFAULT_URL_REGEX,
} from "lexical-anchorpoint";
import { LexicalEditor } from "lexical/LexicalEditor";
import { cloneElement, useEffect, useRef } from "react";
import { TextFieldProps } from "../input/text-field";

const ANCHOR_POINTS = [
  createAnchorPoint(DEFAULT_URL_REGEX, (text) =>
    text.startsWith("http") ? text : `https://${text}`,
  ),
];

/** List of markdown strings we want to support for rendering */
const MARKDOWN_TRANSFORMERS = [LINK];

export type FaroRichTextCoreProps = Pick<
  TextFieldProps,
  "dark" | "readOnly" | "label"
> & {
  /** Optional initial text to render */
  initialText?: string;

  /** Method to be called when the text content changes */
  onChange?(text: string): void;

  /** Method that will be called when an error occurs */
  onError(error: Error): void;

  /** Optional style to apply */
  sx?: SxProps<Theme>;

  /**
   * Optional string to render while the component:
   *  - is not in read only mode, and
   *  - is empty, and
   *  - doesn't have focus
   */
  placeholder?: string;

  /**
   * Component used for rendering
   *
   * During rendering, the attributes of FaroRichTextProps will be passed as props, which means that the interface needs
   * to be compatible in that regard.
   * Furthermore, the component used need to use the ContentEditable component im some regard, which serves as a anchor point for lexical.
   */
  children: JSX.Element;

  /**
   * Optional plugins to pass to lexical
   * More information about lexical plugins can be found here:
   *  - https://lexical.dev/docs/react/plugins
   *  - https://lexical.dev/docs/react/create_plugin
   *  - https://lexical.dev/docs/getting-started/creating-plugin
   */
  lexicalPlugins?: JSX.Element;
};

/**
 * @returns Renderer for rich text, that also allows the user to edit the content
 */
export function FaroRichTextCore({
  readOnly,
  onError,
  initialText,
  onChange,
  dark,
  placeholder,
  label,
  sx,
  children,
  lexicalPlugins,
}: FaroRichTextCoreProps): JSX.Element {
  const editorRef = useRef<LexicalEditor>(null);

  const initialConfig: InitialConfigType = {
    namespace: "FaroRichText",
    onError,
    nodes: [AutoLinkNode, LinkNode],
  };

  // Adding initial text to the editor
  useEffect(() => {
    if (!initialText) return;

    // Get the current active element in the DOM
    const { activeElement } = document;

    // Update the editor with the initial text
    // This will focus the editor after updating
    editorRef.current?.update(
      () => {
        $convertFromMarkdownString(initialText, MARKDOWN_TRANSFORMERS);
      },
      {
        // Restore the original active element after updating
        onUpdate: () => {
          if (activeElement instanceof HTMLElement) {
            // Remove selection to avoid issues with the focus
            window.getSelection()?.removeAllRanges();

            // Focus the original element
            activeElement.focus();
          }
        },
      },
    );
  }, [initialText]);

  // Update edit-mode
  useEffect(() => {
    editorRef.current?.setEditable(!readOnly);
  }, [readOnly]);

  // Attach event listener for updating parent component
  useEffect(() => {
    editorRef.current?.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        onChange?.($convertToMarkdownString(MARKDOWN_TRANSFORMERS));
      });
    });
  }, [onChange]);

  // Adjust links to always open in a new tab
  useEffect(() => {
    if (!editorRef.current) return;

    const removeLinkListener = editorRef.current.registerNodeTransform(
      LinkNode,
      (node) => {
        if (!node) return;
        node.__target = "_blank";
      },
    );
    const removeAutoLinkListener = editorRef.current.registerNodeTransform(
      AutoLinkNode,
      (node) => {
        if (!node) return;
        node.__target = "_blank";
      },
    );

    return () => {
      removeLinkListener();
      removeAutoLinkListener();
    };
  }, [editorRef]);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={cloneElement<
          Omit<FaroRichTextCoreProps, "children" | "readOnly">
        >(children, {
          onError,
          initialText,
          onChange,
          dark,
          placeholder,
          label,
          sx,
        })}
        // The placeholder is rendered below the text field, which makes it kinda useless (hence passing null)
        // A custom placeholder is implemented in ContentEditable
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />

      {/* Create ref to editor for usage within this component */}
      <EditorRefPlugin editorRef={editorRef} />

      {/* Allow to use keyboard shortcuts for undo/redo */}
      <HistoryPlugin />

      {/*
       * Converting markdown string to rendered markdown
       * Will only convert parts of the string as defined by the transformers
       */}
      <MarkdownShortcutPlugin transformers={MARKDOWN_TRANSFORMERS} />

      {/*
       * Automatically convert URLs (using the matchers) to clickable links
       * Need to ba added in addition to the markdown plugin as plain URLs are not supported there
       */}
      <AnchorPointPlugin points={ANCHOR_POINTS} />

      {lexicalPlugins}
    </LexicalComposer>
  );
}
