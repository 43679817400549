precision highp float;

#include <clipping_planes_pars_fragment>

// On Intel GPUs, GLSL shaders are translated to directX's HLSL 
// by a non-optimal driver, to say the least. Passing 'flat int' 
// variables between shaders is allowed, it compiles, but the 
// performances are completely unacceptable. Hence we pass a simple float.
in float fragDrawID;
uniform float drawIdOffset;

const float invCh = 1.0 / 255.0;

void main() 
{
    #include <clipping_planes_fragment>

    // converting the drawID into a readable color
    int drawId = int(round(fragDrawID + drawIdOffset)) * 2  + 2;
    int red = drawId >> 16;
    int rest = drawId - red * 65536;
    int green = rest >> 8;
    int blue = rest - green * 256;
    vec3 color = vec3(float(red), float(green), float(blue)) * invCh;
    gl_FragColor = vec4(color, 1.0);
}
