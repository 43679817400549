import { reproportionCamera } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Camera } from "three";

/**
 * Re-compute the camera proportion when the aspect ratio changes.
 *
 * It's useful in modes that manage an ortho camera internally
 *
 * @param camera to update the proportion of
 */
export function useReproportionCamera(camera: Camera): void {
  const aspectRatio = useThree((s) => s.size.width / s.size.height);

  useEffect(() => {
    reproportionCamera(camera, aspectRatio);
  }, [aspectRatio, camera]);
}
