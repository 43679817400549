import { ILabel, LabelType } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Adds a label to the list of allowed labels of the project.
 */
export interface MutationAddAllowedLabel extends BaseMutation {
  type: MutationTypes.MutationAddAllowedLabel;

  /** The {@link LabelType} to add. */
  label: Pick<ILabel, "id" | "name" | "createdAt" | "resourceId">;
}

/**
 * @returns a mutation to add an {@link LabelType} to the list of a project allowed labels
 * @param label The {@link LabelType} to be added to the project
 */
export function createMutationAddAllowedLabel(
  label: MutationAddAllowedLabel["label"],
): MutationAddAllowedLabel {
  return {
    ...createBaseMutation(MutationTypes.MutationAddAllowedLabel, undefined),
    label,
  };
}
