import { EffectPipeline as LotvEffectPipeline } from "@faro-lotv/lotv";
import { RenderCallback, useThree } from "@react-three/fiber";
import { PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { useViewportRef } from "../hooks";
import { ViewContext } from "../utils";
import { CustomRenderCall } from "../utils/custom-render-call";

type EffectPipelineProps = PropsWithChildren<{
  /** True to enable this effect pipeline @default true */
  enabled?: boolean;
}>;

/**
 * @returns a react three fiber wrapper for a Lotv EffectPipeline
 */
export function EffectPipeline({
  children,
  enabled = true,
}: EffectPipelineProps): JSX.Element {
  const defaultRenderer = useThree((s) => s.gl);
  const context = useContext(ViewContext);
  const viewport = useViewportRef();
  const renderer = context?.renderer ?? defaultRenderer;

  // The effect pipeline needs to keep the renderer synchronized with the currently used by the view
  const pipeline = useMemo(() => new LotvEffectPipeline(renderer), [renderer]);

  const renderCallback = useCallback<RenderCallback>(
    ({ gl, size, scene, camera }, delta) => {
      // The context could be not yet ready, wait for it before rendering
      if (gl !== pipeline.composer.renderer) return;
      const sz = viewport.current ?? size;
      if (sz.width === 0 || sz.height === 0) return;
      const dpr = gl.getPixelRatio();
      pipeline.setSize(sz.width, sz.height, dpr);
      pipeline.changeScene(scene);
      pipeline.changeCamera(camera);
      pipeline.render(delta);
    },
    [pipeline, viewport],
  );

  return (
    <>
      {enabled && <CustomRenderCall callback={renderCallback} />}
      <primitive object={pipeline}>{children}</primitive>
    </>
  );
}
