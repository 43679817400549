import { LotvRenderer, reproportionCamera } from "@faro-lotv/lotv";
import { RenderCallback, RootState } from "@react-three/fiber";
import { OrthographicCamera, PerspectiveCamera, Vector2, Vector4 } from "three";

/**
 * @param a first rect
 * @param b second rect
 * @returns true if the two rects represent the same area
 */
export function areSameRect(a: DOMRect, b: DOMRect): boolean {
  return (
    a.bottom === b.bottom &&
    a.top === b.top &&
    a.left === b.left &&
    a.right === b.right
  );
}

/**
 * Compute the rect of an element relative to the canvas
 *
 * @param element The element we want the rect
 * @param canvas The canvas to use as a reference system
 * @returns The element rect relative to the canvas position
 */
export function getElementRectInCanvas(
  element: Element,
  canvas: HTMLCanvasElement,
): DOMRect {
  const canvasRect = canvas.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  elementRect.x -= canvasRect.x;
  elementRect.y -= canvasRect.y;
  return elementRect;
}

export interface RenderingLogic {
  /**
   * Initialize the policy and store the properties that must be re-applied after the rendering
   *
   * @param state The current R3F state
   * @param camera The current camera used by the scene
   * @returns The DOM rect where the scene will be rendered
   */
  init(
    state: RootState,
    camera: OrthographicCamera | PerspectiveCamera,
  ): DOMRect;
  /**
   * Reset the properties changed during init phase
   *
   * @param state The current R3F state
   */
  reset(state: RootState): void;
  /**
   * The actual rendering call of the policy
   *
   * @param renderCallback An optional custom rendering callback
   * @param state The current R3F state
   * @param delta The time passed since the last rendering call
   * @param frame The state of all of the tracked objects for an XRSession
   */
  render(
    renderCallback: RenderCallback | undefined,
    state: RootState,
    delta: number,
    frame?: XRFrame,
  ): void;
  /**
   * Dispose the resources allocated by the policy
   */
  dispose(): void;
}

/**
 * Render the scene on the same input canvas but applying scissor testing
 */
export class ScissorRenderingLogic implements RenderingLogic {
  private viewport = new Vector4();
  private scissor = new Vector4();
  private scissorTest = false;

  /**
   * Construct the policy
   *
   * @param trackingElement The target element used to determine the scissor testing
   */
  constructor(private trackingElement: Element) {}

  /** @inheritdoc */
  init(
    state: RootState,
    camera: OrthographicCamera | PerspectiveCamera,
  ): DOMRect {
    const { gl } = state;
    gl.getViewport(this.viewport);
    gl.getScissor(this.scissor);
    this.scissorTest = gl.getScissorTest();

    // Track the element bounding rect
    const newRect = getElementRectInCanvas(this.trackingElement, gl.domElement);

    // Prepare state for scissor test rendering
    const { left, bottom, width, height } = newRect;
    const positiveYUpBottom = state.size.height - bottom;
    const aspectRatio = width / height;
    gl.setScissorTest(true);
    gl.setScissor(left, positiveYUpBottom, width, height);
    gl.setViewport(left, positiveYUpBottom, width, height);
    // This is potentially a bug, because this function modifies the camera
    // frustum *after* that the LOD visible nodes have been computed for that frustum.
    // See also bug ticket https://faro01.atlassian.net/browse/SWEB-1588
    reproportionCamera(camera, aspectRatio);

    return newRect;
  }

  /** @inheritdoc */
  render(
    renderCallback: RenderCallback | undefined,
    state: RootState,
    delta: number,
    frame: XRFrame,
  ): void {
    if (renderCallback) {
      renderCallback(state, delta, frame);
    } else {
      state.gl.render(state.scene, state.camera);
    }
  }

  /** @inheritdoc */
  reset(state: RootState): void {
    const { gl } = state;
    gl.setScissorTest(this.scissorTest);
    gl.setScissor(this.scissor);
    gl.setViewport(this.viewport);
  }

  /** @inheritdoc */
  dispose(): void {}
}

/** Render the scene on different canvas by creating a custom renderer */
export class OffCanvasRenderingLogic implements RenderingLogic {
  public renderer: LotvRenderer;

  /**
   * Construct the policy
   *
   * @param canvasElement The canvas element on which we blit the render target
   */
  constructor(private canvasElement: HTMLCanvasElement) {
    this.renderer = new LotvRenderer({ canvas: canvasElement });
  }

  /** @inheritdoc */
  init(
    state: RootState,
    camera: OrthographicCamera | PerspectiveCamera,
  ): DOMRect {
    const { canvasElement } = this;
    const newRect = getElementRectInCanvas(canvasElement, state.gl.domElement);

    const WIDTH = canvasElement.clientWidth;
    const HEIGHT = canvasElement.clientHeight;

    const currentSize = this.renderer.getSize(new Vector2());
    if (WIDTH !== currentSize.width || HEIGHT !== currentSize.height) {
      // Keep the canvas size aligned to its CSS size
      canvasElement.width = WIDTH;
      canvasElement.height = HEIGHT;
      this.renderer.setPixelRatio(state.gl.getPixelRatio());
      this.renderer.setSize(WIDTH, HEIGHT, false);
    }

    reproportionCamera(camera, WIDTH / HEIGHT);

    return newRect;
  }

  /** @inheritdoc */
  render(
    renderCallback: RenderCallback | undefined,
    state: RootState,
    delta: number,
    frame: XRFrame,
  ): void {
    if (renderCallback) {
      renderCallback({ ...state, gl: this.renderer }, delta, frame);
    } else {
      this.renderer.render(state.scene, state.camera);
    }
  }

  /** @inheritdoc */
  reset(): void {}

  /** @inheritdoc */
  dispose(): void {
    this.renderer.dispose();
    this.renderer.forceContextLoss();
  }
}
