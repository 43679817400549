import { GroupProps, useFrame } from "@react-three/fiber";
import { PropsWithChildren, useRef, useState } from "react";
import { Group, Quaternion, Vector3 } from "three";

/**
 * @returns Resets the worldRotation of an object nullifying any parent's rotation
 */
export function RotationReset({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  const groupRef1 = useRef<Group | null>(null);
  const groupRef2 = useRef<Group | null>(null);

  const [workingQuat] = useState(() => new Quaternion());

  useFrame(() => {
    if (groupRef1.current && groupRef2.current) {
      const rotationWorld = groupRef1.current.getWorldQuaternion(workingQuat);

      groupRef2.current.quaternion.copy(rotationWorld.invert());
    }
  });

  return (
    <group ref={groupRef1}>
      <group ref={groupRef2}>{children}</group>
    </group>
  );
}

/**
 * @returns Removes any scale from the hierarchy, providing an unscaled transform for it's children.
 */
export function ScaleReset({
  children,
  ...groupProps
}: PropsWithChildren<GroupProps>): JSX.Element {
  const groupRef1 = useRef<Group | null>(null);
  const groupRef2 = useRef<Group | null>(null);

  const [tempVec] = useState(() => new Vector3());
  const [lastWorldScale] = useState(() => new Vector3());

  useFrame(() => {
    if (groupRef1.current && groupRef2.current) {
      const worldScale = groupRef1.current.getWorldScale(tempVec);

      // Avoid expensive division if possible
      if (!worldScale.equals(lastWorldScale)) {
        groupRef2.current.scale.set(
          1 / worldScale.x,
          1 / worldScale.y,
          1 / worldScale.z,
        );

        lastWorldScale.copy(worldScale);
      }
    }
  });

  return (
    <group ref={groupRef1} {...groupProps}>
      <group ref={groupRef2}>{children}</group>
    </group>
  );
}
