import { pixels2m } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  BufferGeometry,
  Group,
  Intersection,
  OrthographicCamera,
  Plane,
  Quaternion,
  Ray,
  Raycaster,
  Vector3,
} from "three";
import { MeshBVH } from "three-mesh-bvh";
import { useViewportRef } from "../hooks";

interface Props extends EventHandlers {
  /** The points of the line */
  points: Vector3[];

  /** The width of the line in pixels */
  lineWidth: number;
}

/**
 * @returns An invisible Object3D to detect screen space line collisions efficiently in an orthographic projection.
 * The speed-up is achieved by restricting the solution space to a single plane and by using the three-mesh-bvh library
 * to construct an efficient spatial tree (bvh = Bounding volume hierarchy) for the collision query.
 */
export function ScreenSpaceOrthographicLineCollision({
  points,
  lineWidth,
  ...events
}: Props): JSX.Element {
  const groupRef = useRef<Group>(null);
  const viewport = useViewportRef();

  const camera = useThree((s) => s.camera);

  if (!(camera instanceof OrthographicCamera)) {
    throw new Error(
      "ScreenSpaceOrthographicLineCollision only works with orthographic projection",
    );
  }

  // intersectionPlane is perpendicular to the camera viewing direction
  // and passes through the first of the points.
  const intersectionPlane = useMemo(() => {
    const dir = camera.getWorldDirection(new Vector3());
    const d = points[0].dot(dir);
    return new Plane(dir, -d);
  }, [camera, points]);

  // A BVH for the path mesh projected onto the intersectionPlane
  const bvh = useMemo(() => {
    const orthoPlanePoints = points.map((p) =>
      intersectionPlane.projectPoint(p, new Vector3()),
    );

    const linesGeometry = new BufferGeometry().setFromPoints(orthoPlanePoints);

    const indices = [];
    for (let i = 0; i < orthoPlanePoints.length - 1; i++) {
      // MeshBVH assumes a triangle geometry, so we need to define three vertices on a line
      indices.push(i, i, i + 1);
    }

    linesGeometry.setIndex(indices);

    return new MeshBVH(linesGeometry);
  }, [intersectionPlane, points]);

  const [rayPlaneIntersection] = useState(() => new Vector3());
  const [closestPointTarget] = useState(() => ({
    point: new Vector3(),
    distance: 0,
    faceIndex: 0,
  }));

  const [localRay] = useState(() => new Ray());
  const [worldToLocalQuat] = useState(() => new Quaternion());

  // The raycast gets the closest point of the projected path mesh to the raycasts hit point on the intersection plane
  const fastLineRaycast = useCallback(
    (raycaster: Raycaster, intersects: Intersection[]) => {
      if (!groupRef.current || !viewport.current) return;

      localRay.copy(raycaster.ray);
      groupRef.current.worldToLocal(localRay.origin);
      localRay.direction.applyQuaternion(
        groupRef.current.getWorldQuaternion(worldToLocalQuat).invert(),
      );

      localRay.intersectPlane(intersectionPlane, rayPlaneIntersection);

      const lineWidthWorld = pixels2m(
        lineWidth,
        camera,
        viewport.current.height,
        0,
      );

      if (
        bvh.closestPointToPoint(
          rayPlaneIntersection,
          closestPointTarget,
          // set min = max so the query exits as soon as any point in range is found
          lineWidthWorld / 2,
          lineWidthWorld / 2,
        )
      ) {
        intersects.push({
          distance: closestPointTarget.point.distanceTo(raycaster.ray.origin),
          object: groupRef.current,
          point: closestPointTarget.point.clone(),
        });
      }
    },
    [
      bvh,
      camera,
      closestPointTarget,
      intersectionPlane,
      lineWidth,
      localRay,
      rayPlaneIntersection,
      viewport,
      worldToLocalQuat,
    ],
  );

  return (
    <group
      ref={groupRef}
      {...events}
      // eslint-disable-next-line react/no-unknown-property
      raycast={fastLineRaycast}
    />
  );
}
