import { Matrix4, Object3D } from "three";
import { SubScenePipeline } from "../PostProcessing/SubScenePipeline";
import { RenderingPolicy } from "./RenderingPolicy";

/**
 * Basic rendering policy to make the 'render on demand' work on a single model.
 */
export class BasicRenderingPolicy implements RenderingPolicy {
	#model: Object3D | undefined;
	#modelPose = new Matrix4();
	#subscene: SubScenePipeline | undefined;
	#monitorConnected = false;
	#callsToCameraStarted = 0;

	/**
	 * Construct a new BasicRenderingPolicy
	 *
	 * @param o The model to render in the subscene. The subscene is invalidate when this model changes pose
	 */
	constructor(o: Object3D | undefined) {
		this.#model = o;
		this.onCameraStartedMoving = this.onCameraStartedMoving.bind(this);
		this.onCameraStoppedMoving = this.onCameraStoppedMoving.bind(this);
	}

	/** When the camera stops, the scene goes in render-on-demand mode. */
	onCameraStoppedMoving(): void {
		if (this.#callsToCameraStarted === 0) return;
		this.#callsToCameraStarted--;
		if (this.#callsToCameraStarted !== 0) return;
		if (!this.#subscene) return;
		this.#subscene.renderOnDemand = true;
		this.#subscene.invalidate();
	}

	/** When the camera starts moving, the scene is rendered at every frame. */
	onCameraStartedMoving(): void {
		this.#callsToCameraStarted++;
		if (this.#callsToCameraStarted !== 1) return;
		if (!this.#subscene) return;
		this.#subscene.renderOnDemand = false;
	}

	/**
	 *
	 * @param scene The subscene whose camera monitor is going to be listened.
	 */
	#connectCameraMonitor(scene: SubScenePipeline): void {
		if (!this.#monitorConnected) {
			scene.cameraMonitor.cameraStartedMoving.on(this.onCameraStartedMoving);
			scene.cameraMonitor.cameraStoppedMoving.on(this.onCameraStoppedMoving);
			this.#monitorConnected = true;
		}
	}

	/**
	 *
	 * @param scene The subscene whose camera monitor is being disconnected from.
	 */
	#disconnectCameraMonitor(scene: SubScenePipeline): void {
		scene.cameraMonitor.cameraStartedMoving.off(this.onCameraStartedMoving);
		scene.cameraMonitor.cameraStoppedMoving.off(this.onCameraStoppedMoving);
		this.#monitorConnected = false;
	}

	/** @returns the subscene managed by this policy. */
	get subScene(): SubScenePipeline | undefined {
		return this.#subscene;
	}

	/** Sets the subscene that is rendering the point cloud. */
	set subScene(s: SubScenePipeline | undefined) {
		if (this.#subscene) {
			// resetting parameters on old scene
			this.#subscene.renderOnDemand = false;
			this.#disconnectCameraMonitor(this.#subscene);
		}
		this.#subscene = s;
		if (this.#subscene) {
			this.#connectCameraMonitor(this.#subscene);
		}
	}

	/** @inheritdoc */
	sceneChanged(): boolean {
		if (!this.#model) return false;
		if (!this.#modelPose.equals(this.#model.matrixWorld)) {
			this.#modelPose.copy(this.#model.matrixWorld);
			return true;
		}
		return false;
	}

	/** Disconnecting all signals and resetting all parameters. */
	dispose(): void {
		this.subScene = undefined;
		this.#model = undefined;
	}
}
