import { Optional } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import {
  SendTypedAuthenticatedJsonRequestParams,
  TokenProvider,
  sendAuthenticatedJsonRequest,
} from "../authentication";
import { StartPreAlignmentParams } from "./pre-alignment-api-parameters";
import {
  PreAlignmentStartedJobResponse,
  isPreAlignmentStartedJobResponse,
} from "./pre-alignment-api-responses";

/** The path to the orbis pre-alignment trigger function. */
const PRE_ALIGNMENT_HTTP_TRIGGER_PATH = "/api/httpTrigger";

type PreAlignmentApiClientParams = {
  /** Id of the current project. */
  projectId: GUID;
  /** To get authentication tokens for this project. */
  tokenProvider: TokenProvider;
  /** The base url of the pre-alignment API. */
  baseUrl: string;
  /** The ID of the client making the request. */
  clientId?: string;
};

/** The client for the Pre-Alignment API. */
export class PreAlignmentApiClient {
  /** Id of the current project. */
  private projectId: GUID;
  /** To get authentication tokens for this project. */
  private tokenProvider: TokenProvider;
  /** The base url of the pre-alignment API. */
  private baseUrl: string;
  /** The ID of the client making the request. */
  private clientId?: string;

  /** Create a new API client to make requests to the pre-alignment backend. */
  constructor({
    projectId,
    tokenProvider,
    baseUrl,
    clientId,
  }: PreAlignmentApiClientParams) {
    this.projectId = projectId;
    this.tokenProvider = tokenProvider;
    this.baseUrl = baseUrl;
    this.clientId = clientId;
  }

  /**
   * Start the pre-alignment for the given orbis datasets.
   *
   * @returns The response of the backend.
   */
  async startPreAlignment({
    sheetIds,
  }: StartPreAlignmentParams): Promise<PreAlignmentStartedJobResponse> {
    return await this.makeAuthorizedRequest({
      httpMethod: "POST",
      path: PRE_ALIGNMENT_HTTP_TRIGGER_PATH,
      requestBody: {
        projectId: this.projectId,
        sheetIds,
      },
      typeGuard: isPreAlignmentStartedJobResponse,
    });
  }

  /**
   * Make an authorized request to the pre-alignment API.
   *
   * @param params The parameters to send with the request.
   * Mostly the same as for `sendAuthenticatedJsonRequest`.
   * The base URL, token provider and client ID are set automatically.
   * @returns the parsed json response
   */
  private async makeAuthorizedRequest<T>(
    params: Optional<
      SendTypedAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return await sendAuthenticatedJsonRequest({
      ...params,
      baseUrl: this.baseUrl,
      tokenProvider: this.tokenProvider,
      clientId: this.clientId,
    });
  }
}
