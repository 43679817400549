import { Box, Stack, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useCheckForOverflow } from "../../hooks";
import {
  FaroIconButton,
  FaroIconButtonProps,
} from "../icon-button/faro-icon-button";
import { ArrowRightIcon } from "../icons";

/** The direction to scroll the list */
enum ScrollDirection {
  next = "Next",
  previous = "Previous",
}

export interface HorizontalScrollListProps {
  /** True if the list should move each page per scroll or per click of arrow buttons */
  isPageScroll?: boolean;

  /** True if the scrollbar should be hidden */
  shouldHideScrollbar?: boolean;

  /**
   * True if the arrow buttons should be shown
   *
   * @default true
   */
  shouldShowButtons?: boolean;

  /** Additional props to update the previous and next buttons */
  scrollButtonProps?: Omit<FaroIconButtonProps, "onClick" | "children">;

  /** The style of the cards list */
  sx?: SxProps<Theme>;

  /** Optional style to apply the the list wrapper/container */
  wrapperSx?: SxProps<Theme>;

  /** The style of the scrollbar appearance */
  scrollBarSx?: {
    /** The color of the scrollbar track */
    backgroundColor: string;

    /** The color of the thumb */
    thumbColor: string;

    /** The color of the thumb when hovered */
    thumbHoverColor: string;
  };
}

/**
 * @returns a list of items in a horizontal layout with an ability to scroll
 * - Allows navigation through the items using the mouse wheel or the arrow buttons
 */
export function HorizontalScrollList({
  children,
  isPageScroll,
  shouldHideScrollbar,
  shouldShowButtons = true,
  scrollButtonProps,
  sx,
  wrapperSx,
  scrollBarSx,
}: PropsWithChildren<HorizontalScrollListProps>): JSX.Element | null {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { sx: scrollButtonSx, ...restScrollButtonProps } =
    scrollButtonProps ?? {};

  // Logic to check if the scroll container has overflowed
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    function onResize(): void {
      if (scrollContainerRef.current) {
        checkForOverflow(scrollContainerRef.current);
      }
    }
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(scrollContainerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [checkForOverflow]);

  /** Callback when the wheel event occurs on the scrollable list */
  const onWheelOverScrollContainer = useCallback(
    (event: React.WheelEvent): void => {
      if (!scrollContainerRef.current) return;
      scrollContainerRef.current.scrollLeft += event.deltaY;
    },
    [],
  );

  const handleScrollOnClick = useCallback(
    (scrollDirection: ScrollDirection) => {
      if (!scrollContainerRef.current) return;
      const elements = Array.from(scrollContainerRef.current.children);

      if (isPageScroll) {
        const parentRect = scrollContainerRef.current.getBoundingClientRect();
        for (const element of elements) {
          const rect = element.getBoundingClientRect();

          // If the scroll direction is 'next' and the right edge of the child element is beyond the right edge of the parent,
          // calculate the scroll amount and scroll to the right by that amount.
          if (scrollDirection === ScrollDirection.next) {
            if (rect.right > parentRect.right) {
              const scrollAmount = rect.left - parentRect.left;
              scrollContainerRef.current.scrollLeft += scrollAmount;
              break;
            }
          }
          // If the left edge of the child element is beyond the left edge of the parent,
          // calculate the scroll amount and scroll to the left by that amount.
          else if (rect.left > parentRect.left) {
            const scrollAmount = parentRect.right - rect.left;
            scrollContainerRef.current.scrollLeft -= scrollAmount;
            break;
          }
        }
      } else {
        const childWidth = elements[0].getBoundingClientRect().width;

        // If the scroll direction is 'next', scroll to the right by the width of the child element.
        // If the scroll direction is 'previous', scroll to the left by the width of the child element.
        scrollContainerRef.current.scrollLeft =
          scrollDirection === ScrollDirection.next
            ? scrollContainerRef.current.scrollLeft + childWidth
            : scrollContainerRef.current.scrollLeft - childWidth;
      }
    },
    [isPageScroll],
  );

  if (!children) return null;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      position="relative"
      sx={wrapperSx}
    >
      <Box
        component="div"
        gap={1}
        ref={scrollContainerRef}
        onWheel={onWheelOverScrollContainer}
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          gridAutoColumns: "max-content",
          gridTemplateRows: "1fr",
          width: "100%",
          overflowX: "auto",
          padding: "1rem",
          pb: hasOverflown ? 1 : undefined,

          scrollBehavior: "smooth",
          overscrollBehaviorInline: "contain",
          scrollSnapType: "inline mandatory",
          scrollPadding: "8px",
          ["& > *"]: {
            scrollSnapAlign: "start",
          },

          ...(shouldHideScrollbar
            ? {
                ["&::-webkit-scrollbar"]: {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }
            : {
                scrollbarWidth: "thin",
                ...(scrollBarSx && {
                  ["&::-webkit-scrollbar-thumb"]: {
                    background: scrollBarSx.backgroundColor,
                    ["&:hover"]: {
                      background: scrollBarSx.thumbHoverColor,
                    },
                  },
                  scrollbarColor: `${scrollBarSx.thumbColor} ${scrollBarSx.backgroundColor}`,
                }),
              }),
          ...sx,
        }}
      >
        {children}
      </Box>
      {shouldShowButtons && hasOverflown && (
        <Stack
          direction="row"
          justifyContent="space-between"
          position="absolute"
          width="fill-available"
          sx={{ pointerEvents: "none" }}
        >
          <FaroIconButton
            onClick={() => handleScrollOnClick(ScrollDirection.previous)}
            sx={{
              pointerEvents: "auto",
              ...scrollButtonSx,
            }}
            {...restScrollButtonProps}
          >
            <ArrowRightIcon sx={{ transform: "rotate(180deg)" }} />
          </FaroIconButton>
          <FaroIconButton
            onClick={() => handleScrollOnClick(ScrollDirection.next)}
            sx={{
              pointerEvents: "auto",
              ...scrollButtonSx,
            }}
            {...restScrollButtonProps}
          >
            <ArrowRightIcon />
          </FaroIconButton>
        </Stack>
      )}
    </Stack>
  );
}
