precision highp float;
precision mediump int;
#define varying in
out float ao;                   // The output texture has only one red channel.

const float M_PI = 3.14159265;
const int NUM_OF_SAMPLES = 11;      // Number of samples to use - herustic, resulted from quality performance trials
const int NUM_OF_SPIRAL_TURNS = 7;  // Number of turns on spiral - herustic, resulted from quality performance trials
const float EPSILON = 0.01;     // Epsilon value to prevent zero divison

uniform sampler2D uDepthTex; 	// depth texture
uniform float uStrengthFactor;  // strength factor for scaling the obscurance
uniform float uRadius;          // hemispheric kernel radius inside of which to evaluate for occlusion.
uniform float uBias;            // uBias to avoid noise in ao computation.
uniform mat4 uInvPMatrix;       // Inverse of the projection matrix

varying vec2 vUv;

float random(vec3 scale, float seed) {
    return fract(sin(dot(gl_FragCoord.xyz + seed, scale)) * 43758.5453 + seed);
}

vec3 getPoint(vec2 offset_vec) {
    float d = 2.0 * texture(uDepthTex, (vUv + offset_vec)).r - 1.0;
    float x = 2.0 * (vUv.x + offset_vec.x) - 1.0;
    float y = 2.0 * (vUv.y + offset_vec.y) - 1.0;
    vec4 v_pos = uInvPMatrix * vec4(x, y, d, 1.0) ;
    v_pos /= v_pos.w;
    return v_pos.xyz;
}

vec3 getOriginNormal(vec2 texelSizes)
{
	// Calculate the 3D point on the near plane for the frag coordinates.
	// Get points at a predefined offset by 1/Vx, 1/Vy
	vec3 v_pos_xp = getPoint(vec2( texelSizes.x, 0.0));
	vec3 v_pos_xn = getPoint(vec2(-texelSizes.x, 0.0));
	vec3 v_pos_yp = getPoint(vec2(0.0,  texelSizes.y));
	vec3 v_pos_yn = getPoint(vec2(0.0, -texelSizes.y));

	// Apply central difference scheme
	vec3 X = 0.5 * (v_pos_xp - v_pos_xn);
	vec3 Y = 0.5 * (v_pos_yp - v_pos_yn);

	return normalize(cross(X, Y));
}

/*
    This shader uses a version of Scalable Ambient Obscurance.
    It tries to sample points in a spiral trend and tries to asses
    the obscurance depending on the weight of the angles around the point.
    For more info check the following resources:
    - https://research.nvidia.com/sites/default/files/pubs/2012-06_Scalable-Ambient-Obscurance/McGuire12SAO.pdf
    - https://www.davepagurek.com/blog/realtime-shadows/
*/
void main() {
	float depth = texture(uDepthTex, vUv).r;
	if (depth == 1.0)
	{
        ao = 1.0;
        return;
	}
	vec2 tsz = vec2(textureSize(uDepthTex, 0));
    vec2 texelSizes = vec2(1.0)/tsz;
    vec2 screenSpaceOrigin = gl_FragCoord.xy * texelSizes;
	vec3 center = getPoint(vec2(0.0,0.0));
	vec3 centerNormal = getOriginNormal(texelSizes);

	vec3 randomScale = vec3(12.9898, 78.233, 151.7182);
    vec3 sampleNoise = vec3(
      random(randomScale, 0.0),
      random(randomScale, 1.0),
      random(randomScale, 2.0));

    float initialAngle = 2.0 * M_PI * sampleNoise.x;
    float screenSpaceSampleRadius  = 100.0 * uRadius / center.z;
    float occlusion = 0.0;
    float radius = min(center.z, uRadius);
    float radiusSquared = radius * radius;

    for (int sampleNumber = 0; sampleNumber < NUM_OF_SAMPLES; sampleNumber++) {
      // Step 1:
      // Looking at the 2D image of the scene, sample the points surrounding the current one
      // in a spiral pattern
      float sampleProgress = (float(sampleNumber) + 0.5) * (1.0 / float(NUM_OF_SAMPLES));
      float angle = sampleProgress * (float(NUM_OF_SPIRAL_TURNS) * 2.0 * M_PI) + initialAngle;

      float sampleDistance = sampleProgress * screenSpaceSampleRadius;
      vec2 angleUnitVector = vec2(cos(angle), sin(angle));

      // Step 2:
      // Get the 3d coordinate corresponding to the sample on the spiral
      vec3 worldSpaceSample = getPoint(sampleDistance * angleUnitVector * texelSizes);

      // Step 3:
      // Approximate occlusion from this sample
      vec3 originToSample = worldSpaceSample - center;
      float squaredDistanceToSample = dot(originToSample, originToSample);

      // vn is proportional to how close the sample point is to the origin point along
      // the normal at the origin
      float vn = dot(originToSample, centerNormal) - uBias;

      // f is proportional to how close the sample point is to the origin point in the
      // sphere of influence in world space
      float f = max(radiusSquared - squaredDistanceToSample, 0.0) / radiusSquared;
      float sampleOcclusion =  f * f * f * max(vn / (EPSILON + squaredDistanceToSample), 0.0);

      // Accumulate occlusion
      occlusion += sampleOcclusion;
    }

    occlusion = 1.0 - occlusion / (4.0 * float(NUM_OF_SAMPLES));
	ao = clamp(pow(occlusion, 1.0 + uStrengthFactor), 0.0, 1.0);
}
