/**
 * Set the zIndex ranges for the different variants of the annotation.
 * (far - near) / (rangeMax - rangeMin) = precision(m)
 * Precision is the minimum distance for a correct z-sorting
 */
export const ANNOTATION_ZINDEX_RANGE_MAP: Record<string, [number, number]> = {
  // On a project of 1km, the precision will be 1cm
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  full: [500000, 400001],
  toolbar: [400000, 300001],
  small: [300000, 200001],
  title: [200000, 100001],
  collapsed: [100000, 0],
  /* eslint-enable @typescript-eslint/no-magic-numbers */
};
