import { Camera, Group, Vector2 } from "three";
import { lowerBoundTyped } from "../Utils/SortedArrays";
import { NodeCacheElement } from "./LodMultiview";
import { WeightedNode } from "./VisibleNodeStrategy";

/**
 * A binary predicate to compare a WeightedNode and a number.
 * This is used for fast search of a node ID in a sorted list of weighted nodes,
 * which is critical for the LOD visible nodes computation, update and management.
 *
 * @param a The left hand side of the comparison: a pair (node ID, sreen occupancy)
 * @param b The right hand side of the comparison: a node ID
 * @returns true iff a is strictly less than b
 */
function isLess(a: WeightedNode, b: number): boolean {
	return a.id < b;
}

/**
 * Returns whether the list A includes node b.
 * Precondition: the list A is sorted by node ID.
 *
 * @param A The list of WeightedNodes, sorted by increasing node ID
 * @param b The node ID to search for
 * @returns true iff the list A includes node b
 */
function includesT(A: WeightedNode[], b: number): boolean {
	const idx = lowerBoundTyped(A, b, isLess);
	return idx < A.length && A[idx].id === b;
}

/**
 * Return the index of b in the array A, or undefined if b is not in A
 * Precondition: A is sorted by node ID
 *
 * @param A The list of WeightedNodes, sorted by increasing node ID
 * @param b The node ID to search for
 * @returns the index of b in the array A, or undefined if b is not in A
 */
function indexOfT(A: WeightedNode[], b: number): number | undefined {
	const idx = lowerBoundTyped(A, b, isLess);
	return idx < A.length && A[idx].id === b ? idx : undefined;
}

/**
 * This abstract class provides interfaces and implementaton for a group that can handle
 * LOD structures.
 * Lod point clouds can inherit from this class.
 */
export abstract class LodGroup<Element = NodeCacheElement> extends Group {
	/**
	 * The list of visible nodes, stored in order of node ID.
	 * Furthermore, each node carries a weight of importance.
	 */
	protected currentVisibleNodes = new Array<WeightedNode>();

	/**
	 * Arrays that we allocate only once to remove need for garbage
	 * collection while computing and managing visible nodes to load and
	 * unload from GPU.
	 */
	#sortedNewNodes = new Array<WeightedNode>();
	#nodesNotVisible = new Array<WeightedNode>();
	#nodesNotInMemory = new Array<WeightedNode>();
	#nodesToLoad = new Array<WeightedNode>();
	#nodesToUnload = new Array<WeightedNode>();
	#nodesToRequest = new Array<WeightedNode>();
	#unusedNodes = new Array<WeightedNode>();

	constructor() {
		super();
	}

	/**
	 *
	 * @param camera The camera
	 * @param screenSize The screen resolution
	 * @returns List of node indices currently visible from the given camera and given screen resolution.
	 */
	protected abstract computeVisibleNodes(camera: Camera, screenSize: Vector2): WeightedNode[];

	/**
	 * Starts the request or the download of the listed nodes.
	 *
	 * @param nodes List of nodes to be requested (downloaded)
	 */
	protected abstract requestNodes(nodes: WeightedNode[]): void;

	/**
	 * Checks for all the nodes that are in memory but not visible,
	 * whether they should still be kept in cache, or unloaded.
	 *
	 * @param unusedNodes List of node indices that can be unloaded
	 */
	protected abstract cacheOrDisposeNodes(nodes: WeightedNode[]): void;

	/**
	 *
	 * @param nodeIdx Index of requested node
	 * @returns Node data if the node is rendered, undefined otherwise.
	 */
	protected abstract getNodeInGPU(nodeIdx: number): Element | undefined;

	/**
	 *
	 * @param nodeIdx Index of requested node
	 * @returns Node data if the node is in memory, undefined otherwise
	 */
	protected abstract getNodeInMemory(nodeIdx: number): Element | undefined;

	/**
	 * Brings 'node', which is already loaded, into visibility, often by adding it to
	 * this group and conveniently updating related data structures.
	 *
	 * @param node The node index
	 * @param nodeData The node point data
	 * @param nodeVisibility Initial visibility traits for the new visible node.
	 */
	protected abstract setNodeVisible(node: number, nodeData: Element): void;

	/**
	 * Brings 'node', which is being rendered, out of visibility, often by removing it
	 * from this group and conveniently updating related data structures.
	 *
	 * @param node The node index
	 * @param nodeData The node point data
	 */
	protected abstract setNodeNotVisible(node: number, nodeData: Element): void;

	/**
	 * Unload all the nodes currently visible
	 */
	removeAllNodes(): void {
		this.#unloadNodes(this.currentVisibleNodes);
		this.currentVisibleNodes = [];
	}

	/**
	 * This function should be called every time that the camera, the screen resolution or the point density parameter
	 * change. It recomputes which are the visible nodes of the LOD tree, and it consequently determines which new
	 * nodes should be loaded to GPU for rendering and which should be unloaded.
	 *
	 * @param camera The camera from which the cloud is being rendered
	 * @param screenSize The current screen resolution
	 */
	updateCamera(camera: Camera, screenSize: Vector2): void {
		// The returned list 'newVisibleNodes' is sorted according to the screen occupancy of the nodes.
		const newVisibleNodes = this.computeVisibleNodes(camera, screenSize);

		// We create another list 'sortedNewNodes' and we sort it by node ID, for fast
		// computation of set differences.
		this.#sortedNewNodes.length = 0;
		this.#sortedNewNodes.push(...newVisibleNodes);
		this.#sortedNewNodes.sort((a, b) => a.id - b.id);
		let toLoad = this.#computeToLoadList(this.#sortedNewNodes);
		const toUnload = this.#computeToUnloadList(this.#sortedNewNodes);
		const unusedNodes = this.#unloadNodes(toUnload);
		if (toUnload.length > 0 || toLoad.length > 0) {
			this.#assignVisibleNodes(this.#sortedNewNodes);
		} else {
			// Caching and early return
			this.cacheOrDisposeNodes(unusedNodes);
			this.requestNodes([]);
			return;
		}
		// Check if any of the nodes to be loaded is already visible
		toLoad = this.#removeNodesAlreadyVisible(toLoad);

		// Check if any of the nodes to be loaded is already in memory. If so, just bring
		// it to visibility. If not, return it
		toLoad = this.#checkNodesInMemoryForVisibility(toLoad);

		this.cacheOrDisposeNodes(unusedNodes);

		// 'toLoad' is sorted by index, to enable fast computations. However,
		// we want to load the nodes in the same order that they had when returned from
		// the 'visibleNodesStrategy' object.
		this.#nodesToRequest.length = 0;
		// Deliberately avoiding array.filter and array.map to not allocate memory along the hot code path.
		for (const n of newVisibleNodes) {
			if (includesT(toLoad, n.id)) {
				this.#nodesToRequest.push(n);
			}
		}
		this.requestNodes(this.#nodesToRequest);
	}

	/**
	 * This method returns the list of visible nodes
	 *
	 * @returns The list of nodes currently visible
	 */
	getVisibleNodes(): WeightedNode[] {
		return this.currentVisibleNodes;
	}

	/** @returns the total number of visible nodes */
	get visibleNodesCount(): number {
		return this.currentVisibleNodes.length;
	}

	/**
	 *
	 * @param nodeIdx Index of the queried node
	 * @returns Whether the node is visible by this LOD view.
	 */
	getNodeVisibility(nodeIdx: number): boolean {
		return includesT(this.currentVisibleNodes, nodeIdx);
	}

	/**
	 * @param visibleNodes The new visible nodes
	 */
	#assignVisibleNodes(visibleNodes: WeightedNode[]): void {
		// Assigning to 'nodes' the right length from start
		// to minimize memory allocations.
		this.currentVisibleNodes.length = visibleNodes.length;
		for (let i = 0; i < this.currentVisibleNodes.length; ++i) {
			this.currentVisibleNodes[i] = visibleNodes[i];
		}
	}

	/**
	 * This function computes the difference between the current visible nodes and
	 * the new visible nodes. Among the new visible nodes, if
	 * the node is already visible then its weight is updated. If it is not visible,
	 * it is added to the returned array.
	 *
	 * @param newVisibleNodes The new visible nodes for this update.
	 * @returns The list of nodes to load.
	 */
	#computeToLoadList(newVisibleNodes: WeightedNode[]): WeightedNode[] {
		this.#nodesToLoad.length = 0;
		if (newVisibleNodes.length === 0) {
			return this.#nodesToLoad;
		}
		if (this.currentVisibleNodes.length === 0) {
			return newVisibleNodes;
		}
		for (const n of newVisibleNodes) {
			const idx = indexOfT(this.currentVisibleNodes, n.id);
			this.#nodesToLoad.push(n);
			if (idx !== undefined && this.currentVisibleNodes[idx].weight !== n.weight) {
				// otherwise, update its weight.
				this.currentVisibleNodes[idx].weight = n.weight;
			}
		}
		return this.#nodesToLoad;
	}

	/**
	 *
	 * @param newVisibleNodes The new visible nodes for this update.
	 * @returns The list of nodes to unload
	 */
	#computeToUnloadList(newVisibleNodes: WeightedNode[]): WeightedNode[] {
		this.#nodesToUnload.length = 0;
		for (const n of this.currentVisibleNodes) {
			if (!includesT(newVisibleNodes, n.id)) {
				// if a current node is not in the new nodes, it should be unloaded.
				this.#nodesToUnload.push(n);
			}
		}
		return this.#nodesToUnload;
	}

	/**
	 *
	 * @param toLoad List of nodes to load
	 * @returns Same list as input without the nodes that are already visible
	 */
	#removeNodesAlreadyVisible(toLoad: WeightedNode[]): WeightedNode[] {
		this.#nodesNotVisible.length = 0;
		for (const node of toLoad) {
			const alreadyInGpu = this.getNodeInGPU(node.id);
			if (!alreadyInGpu) {
				this.#nodesNotVisible.push(node);
			}
		}
		return this.#nodesNotVisible;
	}

	/**
	 * This method removes from the list of nodes to load all the nodes that are already available in memory.
	 * If it finds a node both in the toLoad list and in memory, it then puts it in GPU.
	 *
	 * @param toLoad List of nodes to clean up.
	 * @returns the list of node to load that were not already in cache.
	 */
	#checkNodesInMemoryForVisibility(toLoad: WeightedNode[]): WeightedNode[] {
		this.#nodesNotInMemory.length = 0;
		for (const node of toLoad) {
			const nodeData = this.getNodeInMemory(node.id);
			if (nodeData === undefined) {
				this.#nodesNotInMemory.push(node);
			} else {
				this.setNodeVisible(node.id, nodeData);
			}
		}
		return this.#nodesNotInMemory;
	}

	/**
	 * Unloads all nodes in the 'toUnload' list.
	 * Here 'unloading' means just removing the node from the scene. The real unloading
	 * depends on the caching policy as well.
	 *
	 * @param toUnload The list of nodes that fell out of visibility and must be unloaded from GPU
	 * @returns The list of the nodes that are not used anymore. It is possible that
	 * some of these unused nodes are still stored in the cache to enable fast availability when
	 * they are visible again.
	 */
	#unloadNodes(toUnload: WeightedNode[]): WeightedNode[] {
		this.#unusedNodes.length = 0;
		for (const n of toUnload) {
			const element = this.getNodeInGPU(n.id);
			if (element !== undefined) {
				this.setNodeNotVisible(n.id, element);
			}
			this.#unusedNodes.push(n);
		}
		return this.#unusedNodes;
	}
}
