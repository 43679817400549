import { Size, useThree } from "@react-three/fiber";
import { useEffect } from "react";

type ResizeCallback = (size: Size) => void;

/**
 * This hook allows user code to register a callback that is called whenever
 * the R3F canvas is resized. It is useful also for components rendering in Views,
 * because if the main canvas resizes also the children views resize.
 *
 * @param callback function to be called whenever R3F canvas size changes.
 */
export function useOnResize(callback: ResizeCallback): void {
  const size = useThree((s) => s.size);

  useEffect(() => {
    callback(size);
  }, [size, callback]);
}
