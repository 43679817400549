import { InvalidConfigurationError } from "@faro-lotv/foundation";
import { WebGLRenderer, WebGLRenderTarget } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { BlitMaterial } from "../Materials/BlitMaterial";
import { FullScreenPass } from "./FullScreenPass";

/**
 * A RenderPass that instead of rendering a scene render the result of another effect composer render
 */
export class RenderComposerPass extends FullScreenPass<BlitMaterial> {
	/** True to clear the depth buffer before blending this pass */
	clearDepth = false;
	/**
	 * This property determines whether this subscene will be rendered at every frame
	 * or whether the output of the previous frame will be just copied on
	 * in case the camera and the scene props didn't change.
	 */
	renderOnDemand = false;
	/**
	 * Whether the output FBO copmputed at the last frame is still valid this frame.
	 */
	lastFBOvalid = false;
	/**
	 * @returns whether this subscene must be re-rendered,
	 * or we can just copy the result of the last rendering.
	 */
	mustReRender: () => boolean = () => true;

	/**
	 * Constructs a RenderComposerPass
	 *
	 * @param composer The source composer we want to render
	 * @param clear True to clear before render
	 * @param swap True to swap after this pass
	 */
	constructor(
		private composer: EffectComposer,
		clear = false,
		swap = false,
	) {
		super(new BlitMaterial());
		this.needsSwap = swap;
		this.clear = clear;
	}

	/**
	 * Renders the internal effect composer storing the result in the offscreen FBO
	 * "this.composer.readBuffer". The rendering may be skipped if 'renderOnDemand'
	 * is true and if the FBO from the last frame is still valid.
	 *
	 * @param renderer The webGL renderer used to render
	 * @param delta Time interval from last rendering call
	 */
	renderComposerToFBO(renderer: WebGLRenderer, delta: number): void {
		// You never want the default auto clear to run if enabled on the renderer
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = false;

		if (!this.renderOnDemand || !this.lastFBOvalid || this.mustReRender()) {
			this.composer.render(delta);
			// Now 'this.composer.readBuffer' contains the rendering result of the whole subscene
			// therefore we store the information that the last FBO is valid.
			this.lastFBOvalid = true;
		}
		renderer.autoClear = oldAutoClear;
	}

	/**
	 * Blits the internal FBO to the main FBO.
	 *
	 * @param renderer The WebGL renderer used to render
	 * @param readBuffer The main FBO to output the rendering to.
	 */
	blitFBOonMain(renderer: WebGLRenderer, readBuffer: WebGLRenderTarget): void {
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = false;

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("RenderComposerPass requires a depth texture in the composer FBO");
		}
		this.material.uniforms.uColorTexture.value = this.composer.readBuffer.texture;
		this.material.uniforms.uDepthTexture.value = this.composer.readBuffer.depthTexture;
		this.material.uniformsNeedUpdate = true;

		renderer.setRenderTarget(readBuffer);

		if (this.clear) renderer.clear(renderer.autoClearColor, renderer.autoClearDepth, renderer.autoClearStencil);
		if (this.clearDepth) renderer.clear(false, true, false);

		this.fsQuad.render(renderer);

		if (this.renderToScreen) {
			this.material.uniforms.uColorTexture.value = readBuffer.texture;
			this.material.uniforms.uDepthTexture.value = readBuffer.depthTexture;
			this.material.uniformsNeedUpdate = true;
			renderer.setRenderTarget(null);
			this.fsQuad.render(renderer);
		}

		renderer.autoClear = oldAutoClear;
	}

	/**
	 * Render the result (readBuffer) of the source composer on the current readBuffer (Simulating a RenderPass)
	 *
	 * @inheritdoc
	 */
	render(
		renderer: WebGLRenderer,
		writeBuffer: WebGLRenderTarget,
		readBuffer: WebGLRenderTarget,
		delta: number,
	): void {
		this.renderComposerToFBO(renderer, delta);
		this.blitFBOonMain(renderer, readBuffer);
	}
}
