/* **** THREEJS LIGHTS ****** */

struct Material {
    vec3 ambient;
    vec3 diffuse;
    vec3 specular;
    float shininess;
    float opacity;
};

// See also
// C:\dev\LotvTS\node_modules\three\src\renderers\shaders\ShaderChunk\lights_pars_begin.glsl.js

// NUM_DIR_LIGHTS and the similar variables are magically set by 3JS to the amount of lights that 
// the scene has.
#if NUM_DIR_LIGHTS > 0

    // fast function without specularity support
    vec3 computeDirectionalLights(const in vec3 normal, const in vec3 viewDir, const in vec3 diff) {
        vec3 c = vec3(0.0);
        // Computing effect of directional lights
        for(int d = 0; d < NUM_DIR_LIGHTS; ++d) {
            vec3 lightDir = normalize(directionalLights[d].direction);
            //computing diffuse light
            float ldiff = max(dot(lightDir, normal), 0.0);
            c += diff * ldiff * directionalLights[d].color;
        }
        return c * RECIPROCAL_PI;
    }

    // with specular light support
    vec3 computeDirectionalLights(const in vec3 normal, const in vec3 viewDir, const in Material mat) {
        vec3 c = vec3(0.0);
        // Computing effect of directional lights
        for(int d = 0; d < NUM_DIR_LIGHTS; ++d) {
            vec3 lightDir = normalize(directionalLights[d].direction);
            //computing diffuse light
            float ldiff = max(dot(lightDir, normal), 0.0);
            c += mat.diffuse * ldiff * directionalLights[d].color;
            //computing specular light
            vec3 refDir = reflect(-lightDir, normal);
            float specAngle = max(dot(refDir, viewDir), 0.0);
            float lspec = pow(specAngle, mat.shininess);
            c += mat.specular * lspec * directionalLights[d].color;
        }
        return c * RECIPROCAL_PI;
    }

#endif

#if NUM_POINT_LIGHTS > 0

    // Computing effect of point lights;
    vec3 computePointLights(const in vec3 normal, const in vec3 viewDir, const in vec3 diff) {
        vec3 c = vec3(0.0);
        for(int p = 0; p < NUM_POINT_LIGHTS; ++p) {
            vec3 lightVec = pointLights[p].position - fragPosition;
            float distance = length(lightVec);
            vec3 lightDir = lightVec / distance;
            // diffuse shading
            float ldiff = max(dot(normal, lightDir), 0.0);
            // attenuation
            float attenuation = getDistanceAttenuation(distance, pointLights[p].distance, pointLights[p].decay);
            vec3 aColor = attenuation * pointLights[p].color;
            // combine results
            c += diff * ldiff * aColor;
        }
        return c * RECIPROCAL_PI;
    }

    vec3 computePointLights(const in vec3 normal, const in vec3 viewDir, const in Material mat) {
        vec3 c = vec3(0.0);
        for(int p = 0; p < NUM_POINT_LIGHTS; ++p) {
            vec3 lightVec = pointLights[p].position - fragPosition;
            float distance = length(lightVec);
            vec3 lightDir = lightVec / distance;
            // diffuse shading
            float ldiff = max(dot(normal, lightDir), 0.0);
            // specular shading
            vec3 reflectDir = reflect(-lightDir, normal);
            float lspec = pow(max(dot(viewDir, reflectDir), 0.0), mat.shininess);
            // attenuation
            float attenuation = getDistanceAttenuation(distance, pointLights[p].distance, pointLights[p].decay);
            vec3 aColor = attenuation * pointLights[p].color;
            // combine results
            c += mat.diffuse * ldiff * aColor + mat.specular * lspec * aColor;
        }
        return c * RECIPROCAL_PI;
    }

#endif

#if NUM_SPOT_LIGHTS > 0

    vec3 computeSpotLights(const in vec3 normal, const in vec3 viewDir, const in vec3 diff) {
        vec3 c = vec3(0.0);
        // Accounting for spot lights;
        for(int s = 0; s < NUM_SPOT_LIGHTS; ++s) {
            vec3 lightVec = spotLights[s].position - fragPosition;
            float distance = length(lightVec);
            vec3 lightDir = lightVec / distance;
            float angleCos = dot(lightDir, spotLights[s].direction);
            float spotAttenuation = getSpotAttenuation( spotLights[s].coneCos, spotLights[s].penumbraCos, angleCos );
            if(spotAttenuation > 0.0) {
                float attenuation = getDistanceAttenuation(distance, spotLights[s].distance, spotLights[s].decay);
                vec3 lColor = spotLights[s].color * spotAttenuation * attenuation;
                c += diff * lColor;
            }
        }
        return c * RECIPROCAL_PI;
    }

    vec3 computeSpotLights(const in vec3 normal, const in vec3 viewDir, const in Material mat) {
        vec3 c = vec3(0.0);
        // Accounting for spot lights;
        for(int s = 0; s < NUM_SPOT_LIGHTS; ++s) {
            vec3 lightVec = spotLights[s].position - fragPosition;
            float distance = length(lightVec);
            vec3 lightDir = lightVec / distance;
            float angleCos = dot(lightDir, spotLights[s].direction);
            float spotAttenuation = getSpotAttenuation( spotLights[s].coneCos, spotLights[s].penumbraCos, angleCos );
            if(spotAttenuation > 0.0) {
                float attenuation = getDistanceAttenuation(distance, spotLights[s].distance, spotLights[s].decay);
                vec3 lColor = spotLights[s].color * spotAttenuation * attenuation;
                vec3 reflectDir = reflect(-lightDir, normal);
                float lspec = pow(max(dot(viewDir, reflectDir), 0.0), mat.shininess);
                c += mat.diffuse * lColor + mat.specular * lspec * lColor;
            }
        }
        return c * RECIPROCAL_PI;
    }

#endif

#if NUM_HEMI_LIGHTS > 0

    vec3 computeHemisphereLights(const in vec3 normal, const in vec3 diff) {
        vec3 c = vec3(0.0);
        for(int h = 0; h < NUM_HEMI_LIGHTS; ++h) {
            float doth = dot(normal, hemisphereLights[h].direction);
            float w = 0.5 + doth * 0.5;
            vec3 irradiance = mix(hemisphereLights[h].groundColor, hemisphereLights[h].skyColor, w);
            c += diff * irradiance;
        }
        return c * RECIPROCAL_PI;
    }

#endif

/************* END THREEJS LIGHTS  ******************/
