import {
  validateNonEmptyString,
  validateNotNullishObject,
} from "@faro-lotv/foundation";
import { Matrix4Tuple } from "three";
import { TokenProvider, sendAuthenticatedJsonRequest } from "../authentication";

export type PointCloudFormat = {
  /** The extension of the point cloud format (e.g "cpe") */
  name: string;

  /** The human readable string used to identify the format (e.g "FARO CPE") */
  displayName: string;

  /** Max volume this exporter can export in cubic meters */
  volumeLimit?: number;
};

export type SubVolumeExportRequest = {
  /** The unique id of the user */
  userId: string;
  /** The unique id of the current project */
  projectId: string;
  /** The unique id of the point cloud that should be exported */
  pointCloudId: string;
  /** The format of the exported point cloud file */
  format: string;
  /**
   * The name to use for the exported file
   *
   * @default export
   */
  fileName?: string;
  /**
   * The transformation matrix that brings the unitary bounding box [0, 0, 0] - [1, 1, 1]
   * to the user-defined one in world coordinates
   */
  boundingBox: Matrix4Tuple;
  /**
   * Optional transformation that is applied to the point cloud points before exporting.
   * The transformation is in the Y-UP right handed reference system and it's in a row-major
   * representation.
   */
  transformation?: Matrix4Tuple;
  /**
   * Optional custom density value for the point cloud to export, expressed as the minimum distance
   * between two point in meters
   */
  minimumPointSpacing?: number;
};

export type SubVolumeExportResponse = {
  /** The id of the job performing the export operation */
  jobId: string;
};

/**
 * @returns True if the input is of PointCloudFormat type
 * @param format The element to check
 */
function isPointCloudFormat(
  format: Partial<PointCloudFormat>,
): format is PointCloudFormat {
  return (
    format.name !== undefined &&
    typeof format.name === "string" &&
    format.displayName !== undefined &&
    typeof format.displayName === "string" &&
    (format.volumeLimit === undefined || typeof format.volumeLimit === "number")
  );
}

/**
 * @returns True if the input element is a list of valid point cloud formats
 * @param response The element to check
 */
function isPointCloudFormatList(
  response: unknown,
): response is PointCloudFormat[] {
  return (
    Array.isArray(response) && response.every((e) => isPointCloudFormat(e))
  );
}

/**
 * @returns True if the input element is a valid response for the sub volume export request
 * @param response The element to check
 */
function isValidSubVolumeResponse(
  response: unknown,
): response is SubVolumeExportResponse {
  return (
    validateNotNullishObject(response, "SubVolumeResponse") &&
    validateNonEmptyString(response, "jobId")
  );
}

/** A client that manages the communication with the PointCloud API */
export class PointCloudApiClient {
  /**
   * Create a client given the path to the API entry point
   *
   * @param endpoint The url pointing to the PointCloud API
   * @param tokenProvider Callback to get the authorization token for the backend
   */
  constructor(
    private endpoint: string,
    private tokenProvider: TokenProvider,
  ) {}

  /**
   * @param signal to abort this request
   * @returns The list of available format for exporting a point cloud
   */
  getFormats(signal?: AbortSignal): Promise<PointCloudFormat[]> {
    return sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: "/exporter/formats",
      typeGuard: isPointCloudFormatList,
      tokenProvider: this.tokenProvider,
      signal,
    });
  }

  /**
   * Export a sub-part of a point cloud containg by a bounding box.
   * The bounding box must be defined by the transformation that maps the unitary bounding box [0, 0, 0] - [1, 1, 1]
   * to the user-defined one in world coordinates
   *
   * @returns the backend response with the job id of this export
   */
  exportSubVolume({
    userId,
    projectId,
    pointCloudId,
    format,
    fileName,
    boundingBox,
    transformation,
    minimumPointSpacing,
  }: SubVolumeExportRequest): Promise<SubVolumeExportResponse> {
    const requestBody = {
      userId,
      projectId,
      pointCloudId,
      posedBoundingBox: boundingBox,
      outputFormat: format,
      fileName,
      transformation,
      minimumPointSpacing,
    };

    return sendAuthenticatedJsonRequest({
      baseUrl: this.endpoint,
      path: "/exporter/export",
      httpMethod: "POST",
      requestBody,
      typeGuard: isValidSubVolumeResponse,
      tokenProvider: this.tokenProvider,
    });
  }
}
