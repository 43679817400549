import {
	AddEquation,
	Blending,
	BlendingDstFactor,
	BlendingEquation,
	BlendingSrcFactor,
	Box3,
	BufferAttribute,
	BufferGeometry,
	Color,
	CustomBlending,
	Intersection,
	LinearSRGBColorSpace,
	Material,
	Matrix4,
	Mesh,
	MeshStandardMaterial,
	Object3D,
	OneFactor,
	Raycaster,
	Sphere,
	SrcAlphaFactor,
	Texture,
	Vector3,
} from "three";
import { CadModelMaterial, MAX_NUMBER_OF_COLOR_TEXTURES } from "../Materials/CadModelMaterial";
import { assert } from "../Utils";
import { TypeArrayConstructor, getTypedArrayConstructor } from "../Utils/TypedArrayConstructor";
import {
	CAD_DEFAULT_COLOR,
	CAD_DEFAULT_SHININESS,
	CAD_DEFAULT_SPECULAR_COLOR,
	CadNode,
	CadNodeMaterial,
} from "./CadModelDataStructures";

/**
 * Computes the bounding sphere from the bounding box of a geometry.
 * This function is not meant for export outside the library.
 *
 * @param geometry A BufferGeometry with a valid bounding box
 */
function computeBoundingSphereFromBoundingBox(geometry: BufferGeometry): void {
	if (!geometry.boundingBox || geometry.boundingBox.isEmpty()) return;
	geometry.boundingSphere = new Sphere();
	const box = geometry.boundingBox;
	const center = box.getCenter(new Vector3());
	geometry.boundingSphere.setFromPoints([box.min, box.max], center);
}

/**
 * Initializes the material of a CAD node, staring from the original material of the
 * corresponding submesh. If the material is textured, the texture is added to the
 * list of textures if not present
 *
 * This function is not meant for export outside the library.
 *
 * @param node The CAd Node
 * @param isTextured Whether the CAD model is textured
 * @param textures the list of textures of the CAD model
 */
function initMaterial(node: CadNode, isTextured: boolean, textures: Texture[]): void {
	// In the materialsBuffer we store 12 floats per node. Three for the ambient color,
	// three for the diffuse, three for the specular, and the last three are for
	// shininess, opacity and texture ID (or -1 if no texture).
	const color = CAD_DEFAULT_COLOR.clone();
	let texId = -1;
	let opacity = 1.0;
	const mesh = node.originalMesh;
	if (mesh.material instanceof MeshStandardMaterial) {
		const mat = mesh.material;
		({ opacity } = mat);
		color.set(mat.color.r, mat.color.g, mat.color.b);
		readColorsPerVertex(mesh.geometry, color);
		const texture = mat.map;
		if (texture && isTextured) {
			texId = textures.findIndex((tex) => tex.uuid === texture.uuid);
			// if there is a new texture, and there is enough space to store it,
			// assign 'texId' to it. If the limit of 16 textures bound at the same time
			// is reached, just discarde the texture as it is not possible to change this WebGL
			// constraint.
			if (texId < 0 && textures.length < MAX_NUMBER_OF_COLOR_TEXTURES - 1) {
				texId = textures.length;
				textures.push(texture);
			}
		}
	}

	const diffuse = color.clone();
	increaseLightness(diffuse);

	node.material = {
		ambient: color,
		diffuse,
		specular: CAD_DEFAULT_SPECULAR_COLOR.clone(),
		shininess: CAD_DEFAULT_SHININESS,
		opacity,
	};
	node.textureId = texId;
}

// Cache temporary value to avoid reallocations
const colorRGB = new Color();

// Increase the lightness of a color. This is used to make the color of the CAD model
// brighter, as the original colors are often too dark. Expecially when the model is
// assigned with black color, which will be rendered as a completely black blob without
// any detail shading.
function increaseLightness(color: Vector3): void {
	colorRGB.setFromVector3(color);
	const hsl = { h: 0, s: 0, l: 0 };
	colorRGB.getHSL(hsl);
	// The lightness value is up scaled from original range [0, 1] to [0.4,1], so a
	// minimum value of 0.4 is used to avoid completely black. This 0.4 minimal is
	// chosen after experiments with different values.
	// eslint-disable-next-line @typescript-eslint/no-magic-numbers
	hsl.l = hsl.l * 0.6 + 0.4;
	colorRGB.setHSL(hsl.h, hsl.s, hsl.l);
	color.setFromColor(colorRGB);
}

/**
 * Assigns the material rhs to the material lhs, keeping distinct objects.
 *
 * @param lhs Destination of the assignment
 * @param rhs Source of the assignment
 */
export function assignMaterial(lhs: CadNodeMaterial, rhs: CadNodeMaterial): void {
	lhs.ambient.copy(rhs.ambient);
	lhs.diffuse.copy(rhs.diffuse);
	lhs.specular.copy(rhs.specular);
	lhs.shininess = rhs.shininess;
	lhs.opacity = rhs.opacity;
}

/**
 * Force all the geometries in the array to have the same attributes
 *
 * @param meshes The geometries that must be uniformed
 */
function makeGeometriesMergable(meshes: Mesh[]): void {
	for (const m of meshes) {
		const g = m.geometry;
		assert(g.index !== null, "Geometries must be indexed.");

		// Removing colors per vertex: our tests show that they are useless in CAD,
		// they are filled with the same color per solid that is already registered into
		// our material inside the 'initMaterial' function
		if (g.hasAttribute("color")) {
			g.deleteAttribute("color");
		}
	}

	const attributesUsed: Record<
		string,
		{
			itemSize: number;
			constructor: TypeArrayConstructor;
		}
	> = {};
	for (const mesh of meshes) {
		const { geometry } = mesh;
		for (const name in geometry.attributes) {
			const constructor = getTypedArrayConstructor(geometry.attributes[name].array);
			if (!constructor) throw new Error(`Unsupported array type for ${name} attribute`);
			attributesUsed[name] = {
				itemSize: geometry.attributes[name].itemSize,
				constructor,
			};
		}
	}

	for (const mesh of meshes) {
		const { geometry } = mesh;
		const V = geometry.getAttribute("position").count;
		for (const name in attributesUsed) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
			if (!geometry.attributes[name]) {
				const { itemSize } = attributesUsed[name];
				const buffer = new attributesUsed[name].constructor(V * itemSize);
				geometry.setAttribute(name, new BufferAttribute(buffer, itemSize));
			}
		}
	}
}

/**
 *
 * @param geometry Root element of a scene graph
 * @param color output color
 */
function readColorsPerVertex(geometry: BufferGeometry, color: Vector3): void {
	if (geometry.hasAttribute("color")) {
		const cpv = geometry.getAttribute("color");
		const sz = cpv.itemSize;
		const hc = Math.floor(cpv.count / 2);
		color.x = cpv.array[sz * hc];
		color.y = cpv.array[sz * hc + 1];
		color.z = cpv.array[sz * hc + 2];
	}
}

type MeshSortData = {
	bboxVolume: number;
	opacity: number;
	index: number;
};

/**
 * Sorts the meshes in the scene from most opaque to most transparent.
 *
 * @param scene The scene to sort
 * @returns the list of original CAD parts, sorted putting opaque parts first and transparent parts last.
 * Also, parts are stored from smallest to greatest bounding box
 */
export function sortMeshes(scene: Object3D): Mesh[] {
	const meshes = new Array<Mesh>();
	// Traverse the scene graph and store all meshes.
	// For each mesh, store opacity and bbox volume.
	const stack: Object3D[] = [scene];
	const sortData = new Array<MeshSortData>();
	while (stack.length > 0) {
		const currObject = stack.pop();
		assert(currObject);
		if (currObject instanceof Mesh) {
			if (!currObject.geometry.boundingBox) {
				currObject.geometry.computeBoundingBox();
			}
			if (!currObject.geometry.boundingSphere) {
				computeBoundingSphereFromBoundingBox(currObject.geometry);
			}
			let bboxVolume = 0;
			if (currObject.geometry.boundingBox) {
				const bx = currObject.geometry.boundingBox;
				bboxVolume = (bx.max.x - bx.min.x) * (bx.max.y - bx.min.y) * (bx.max.z - bx.min.z);
			}
			const opacity = getOpacity(currObject);
			meshes.push(currObject);
			sortData.push({
				bboxVolume,
				opacity,
				index: meshes.length - 1,
			});
		}
		for (const c of currObject.children) {
			stack.push(c);
		}
	}

	if (sortData.length === 0) return new Array<Mesh>();

	// Sort meshes from most opaque to most transparent.
	// If opacity is the same, sort meshes from biggest to smallest bounding box
	sortData.sort((a, b) => {
		const diff = b.opacity - a.opacity;
		if (diff === 0) return b.bboxVolume - a.bboxVolume;
		return diff;
	});

	const result = new Array<Mesh>();
	for (const d of sortData) result.push(meshes[d.index]);

	return result;
}

function getOpacity(mesh: Mesh): number {
	return mesh.material instanceof Material ? mesh.material.opacity : 1.0;
}

/**
 * Categorize the meshes in the scene according to rendering mode,
 * such as opaque, transparent, with or without textures.
 */
type MeshCategory = {
	opaqueNoTexture: Mesh[];
	opaqueTexture: Mesh[];
	transparentNoTexture: Mesh[];
	transparentTexture: Mesh[];
};

/**
 * Categorize the meshes in the scene in opaque and transparent parts.
 *
 * @param meshes The meshes to categorize
 * @returns Mesh categorized according to rendering mode
 */
export function categorizeMeshes(meshes: Mesh[]): MeshCategory {
	const opaqueNoTexture = [];
	const opaqueTexture = [];
	const transparentNoTexture = [];
	const transparentTexture = [];

	for (const mesh of meshes) {
		const opacity = getOpacity(mesh);
		const isTextured = mesh.geometry.hasAttribute("uv");
		if (opacity === 1.0) {
			if (isTextured) {
				opaqueTexture.push(mesh);
			} else {
				opaqueNoTexture.push(mesh);
			}
		} else if (isTextured) {
			transparentTexture.push(mesh);
		} else {
			transparentNoTexture.push(mesh);
		}
	}
	return {
		opaqueNoTexture,
		opaqueTexture,
		transparentNoTexture,
		transparentTexture,
	};
}

/**
 *	Create the CAD nodes from the meshes and store them in the cadNodes array
 *
 * @param meshes The meshes to create the nodes from
 * @param cadNodes The array where the nodes will be stored
 * @param colorTextures The list of textures of the CAD model
 * @param offset model offset position
 */
export function createCadNodes(meshes: Mesh[], cadNodes: CadNode[], colorTextures: Texture[], offset: Vector3): void {
	const isTextured = meshes.some((m) => m.geometry.hasAttribute("uv"));

	let iOffset = 0;
	let vertexOffset = 0;
	if (cadNodes.length > 0) {
		const lastNode = cadNodes[cadNodes.length - 1];
		iOffset = lastNode.iOffset + lastNode.iCount;
		vertexOffset = lastNode.vOffset + lastNode.vCount;
	}

	// Creating all Cad nodes
	const offsetMatrix = new Matrix4().makeTranslation(-offset.x, -offset.y, -offset.z);
	for (const currObject of meshes) {
		assert(currObject.geometry.index, "Cannot load non-indexed CAD geometry.");
		const cadNode = new CadNode(currObject);
		cadNode.name = currObject.name;
		// The new node id by default visible
		cadNode.visible = true;
		// copying into the node the original pose and offset it by model position
		cadNode.matrixWorld.copy(currObject.matrixWorld).premultiply(offsetMatrix);
		// Compute vertex interval
		cadNode.vCount = currObject.geometry.getAttribute("position").count;
		cadNode.vOffset = vertexOffset;
		vertexOffset += cadNode.vCount;
		// Compute index interval
		cadNode.iOffset = iOffset;
		cadNode.iCount = currObject.geometry.index.count;
		iOffset += cadNode.iCount;
		// Copy over bbox and bsphere
		// Bounding box and sphere are computed before
		assert(currObject.geometry.boundingBox);
		cadNode.boundingBox.copy(currObject.geometry.boundingBox);
		assert(currObject.geometry.boundingSphere);
		cadNode.boundingSphere.copy(currObject.geometry.boundingSphere);
		// init material
		initMaterial(cadNode, isTextured, colorTextures);
		// For meshes converted from SVF, the ObjectID is included in the "name" field of the mesh.
		const objectId = parseInt(currObject.name, 10);
		cadNode.objectId = Number.isNaN(objectId) ? undefined : objectId;
		// push results
		cadNodes.push(cadNode);
	}
}

type GeometrySizeInfo = {
	nbVertices: number;
	nbIndices: number;
	normal: boolean;
	uv: boolean;
};

function getMergedGeometrySize(meshes: Mesh[]): GeometrySizeInfo {
	let nbVertices = 0;
	let normalCount = 0;
	let uvCount = 0;
	let nbIndices = 0;

	for (const m of meshes) {
		const geom = m.geometry;
		assert(geom.hasAttribute("position"), "Geometry must have 'position' attribute");
		nbVertices += geom.getAttribute("position").count;

		if (geom.hasAttribute("normal")) {
			normalCount += geom.getAttribute("normal").count;
		}
		if (geom.hasAttribute("uv")) {
			uvCount += geom.getAttribute("uv").count;
		}

		const index = geom.getIndex();
		assert(index, "Geometry must have 'index' attribute");
		nbIndices += index.count;
	}

	assert(
		(normalCount === nbVertices || normalCount === 0) && (uvCount === nbVertices || uvCount === 0),
		"Normals/UVs must be present for all vertices or none",
	);

	return { nbVertices, nbIndices, normal: normalCount > 0, uv: uvCount > 0 };
}

/**
 * Create a new BufferGeometry with enough or more buffer allocated to store
 * the merged geometries of the CAD nodes
 *
 * @param meshes The list of meshes
 * @param capacityFactor The factor of the capacity to allocate, >1.0 to allocate more memory
 * @returns The new BufferGeometry
 */
export function createNewGeometry(meshes: Mesh[], capacityFactor = 1.0): BufferGeometry {
	const sizeInfo = getMergedGeometrySize(meshes);
	sizeInfo.nbVertices *= capacityFactor;
	sizeInfo.nbVertices *= capacityFactor;
	return createBuffers(new BufferGeometry(), sizeInfo);
}

/**
 * Create BufferGeometry with the buffers allocated according to the given size.
 *
 * @param geometry The geometry to allocate the buffers
 * @param capacity Sizes to allocated the buffers
 * @returns The BufferGeometry with the buffers allocated
 */
function createBuffers(geometry: BufferGeometry, capacity: GeometrySizeInfo): BufferGeometry {
	const positionBuffer = new BufferAttribute(new Float32Array(capacity.nbVertices * 3), 3);
	geometry.setAttribute("position", positionBuffer);

	const drawIDBuffer = new BufferAttribute(new Int32Array(capacity.nbVertices), 1);
	geometry.setAttribute("drawID", drawIDBuffer);

	if (capacity.normal) {
		const normalBuffer = new BufferAttribute(new Float32Array(capacity.nbVertices * 3), 3);
		geometry.setAttribute("normal", normalBuffer);
	}

	if (capacity.uv) {
		const uvBuffer = new BufferAttribute(new Float32Array(capacity.nbVertices * 2), 2);
		geometry.setAttribute("uv", uvBuffer);
	}

	const indexBuffer = new BufferAttribute(new Uint32Array(capacity.nbIndices), 1);
	geometry.setIndex(indexBuffer);
	geometry.setDrawRange(0, 0);
	geometry.userData.nbVerticesUsed = 0;
	geometry.userData.nbIndicesUsed = 0;
	return geometry;
}

function resizeFloat32Attribute(attribute: BufferAttribute, newCount: number): BufferAttribute {
	assert(attribute.array instanceof Float32Array, "Unexpected array type");
	const newArray = new Float32Array(newCount * attribute.itemSize);
	newArray.set(attribute.array);
	return new BufferAttribute(newArray, attribute.itemSize);
}

function resizeInt32Attribute(attribute: BufferAttribute, newCount: number): BufferAttribute {
	assert(attribute.array instanceof Int32Array, "Unexpected array type");
	const newArray = new Int32Array(newCount * attribute.itemSize);
	newArray.set(attribute.array);
	return new BufferAttribute(newArray, attribute.itemSize);
}

/**
 * When buffer is not big enough to accommodate new meshes, it grows at
 * a constant factor in order to avoid frequent reallocations.
 */
const BUFFER_GROW_FACTOR = 1.5;

/**
 * Reserve enough memory in the geometry to store the merged geometries of the meshes.
 *
 * @param geometry The geometry to reserve the memory
 * @param meshes The list of meshes to merge
 * @returns Whether the attribute and index buffers have been resized
 */
function reserveBuffers(
	geometry: BufferGeometry,
	meshes: Mesh[],
): { attributeResized: boolean; indexResized: boolean } {
	const size = getMergedGeometrySize(meshes);
	assert(
		geometry.hasAttribute("normal") === size.normal && geometry.hasAttribute("uv") === size.uv,
		"Geometries must have the same attributes to be mergeable.",
	);

	const nbVerticesUsed: number = geometry.userData.nbVerticesUsed;
	let newNbVertices = nbVerticesUsed + size.nbVertices;

	const positions = geometry.getAttribute("position");
	const drawIDs = geometry.getAttribute("drawID");
	assert(positions instanceof BufferAttribute && drawIDs instanceof BufferAttribute);

	const attributeResized = positions.count < newNbVertices;
	if (attributeResized) {
		newNbVertices = Math.max(newNbVertices, Math.ceil(positions.count * BUFFER_GROW_FACTOR));

		geometry.setAttribute("position", resizeFloat32Attribute(positions, newNbVertices));
		geometry.setAttribute("drawID", resizeInt32Attribute(drawIDs, newNbVertices));

		if (geometry.hasAttribute("normal")) {
			const normals = geometry.getAttribute("normal");
			assert(normals instanceof BufferAttribute, "Normal buffer must be a BufferAttribute");
			geometry.setAttribute("normal", resizeFloat32Attribute(normals, newNbVertices));
		}
		if (geometry.hasAttribute("uv")) {
			const uvs = geometry.getAttribute("uv");
			assert(uvs instanceof BufferAttribute, "UV buffer must be a BufferAttribute");
			geometry.setAttribute("uv", resizeFloat32Attribute(uvs, newNbVertices));
		}
	}

	const indices = geometry.getIndex();
	assert(indices && indices.array instanceof Uint32Array, "Index array must be a Uint32Array");
	const nbIndicesUsed: number = geometry.userData.nbIndicesUsed;
	let newNbIndices = nbIndicesUsed + size.nbIndices;

	const indexResized = indices.count < newNbIndices;
	if (indexResized) {
		newNbIndices = Math.max(newNbIndices, Math.ceil(indices.count * BUFFER_GROW_FACTOR));
		const newIndex = new Uint32Array(newNbIndices);
		newIndex.set(indices.array);
		geometry.setIndex(new BufferAttribute(newIndex, 1));
	}

	return { attributeResized, indexResized };
}

/**
 * Computes the main geometry of the CAD model, merging all the geometries of the CAD nodes.
 * The drawID attribute is also set in the geometry.
 *
 * @param geometry The geometry to merge into
 * @param cadNodes The list of CAD nodes
 * @param nodeIdxBegin The index of the first node to consider
 * @param nodeIdxEnd The index of the last node to consider
 */
export function computeMainGeometry(
	geometry: BufferGeometry,
	cadNodes: CadNode[],
	nodeIdxBegin: number,
	nodeIdxEnd: number,
): void {
	assert(geometry.userData.nbVerticesUsed !== undefined, "Geometry must be created with preallocated buffers.");

	const meshes = new Array<Mesh>();
	for (let i = nodeIdxBegin; i < nodeIdxEnd; ++i) {
		meshes.push(cadNodes[i].originalMesh);
	}
	if (meshes.length === 0) return;

	makeGeometriesMergable(meshes);

	mergeGeometries(geometry, meshes);

	const nodeBox = new Box3();
	if (geometry.boundingBox === null) {
		geometry.boundingBox = new Box3();
	}

	const drawids = geometry.getAttribute("drawID").array;
	assert(drawids instanceof Int32Array, "DrawID buffer must be a Int32Array");

	for (let nodeIdx = nodeIdxBegin; nodeIdx < nodeIdxEnd; ++nodeIdx) {
		const cadNode = cadNodes[nodeIdx];

		// Releasing memory of the single meshes, since the big buffer has already been created.
		cadNode.originalMesh.geometry.dispose();
		// Each CAD node knows its draw range inside the big buffer
		cadNode.originalMesh.geometry = geometry;

		// setup bounding box
		nodeBox.copy(cadNode.boundingBox);
		nodeBox.applyMatrix4(cadNode.matrixWorld);
		geometry.boundingBox.union(nodeBox);

		// setting up drawID attribute that will be read in the shader
		for (let i = cadNode.vOffset; i < cadNode.vOffset + cadNode.vCount; ++i) {
			drawids[i] = nodeIdx;
		}
	}
	geometry.getAttribute("drawID").needsUpdate = true;

	computeBoundingSphereFromBoundingBox(geometry);
}

/**
 * Merge the geometries of a list of meshes into a single geometry
 *
 * @param geometry The geometry to merge into
 * @param meshes The list of meshes
 */
function mergeGeometries(geometry: BufferGeometry, meshes: Mesh[]): void {
	const { attributeResized, indexResized } = reserveBuffers(geometry, meshes);

	const newVertices = mergeAttributeData(geometry, meshes, "position", attributeResized);
	mergeAttributeData(geometry, meshes, "normal", attributeResized);
	mergeAttributeData(geometry, meshes, "uv", attributeResized);

	const newIndices = mergeIndexData(geometry, meshes, indexResized);

	geometry.userData.nbVerticesUsed += newVertices;
	geometry.userData.nbIndicesUsed += newIndices;
	geometry.setDrawRange(0, geometry.userData.nbIndicesUsed);
}

/**
 * Merege attribute data of the meshes into the geometry
 *
 * @param geometry The geometry to merge into
 * @param meshes The meshes to be merged
 * @param attributeName Attribute of the merge to be merged
 * @param updateAll Whether to update all the attribute or just the new range
 * @returns Number of new vertices added to the geometry
 */
function mergeAttributeData(
	geometry: BufferGeometry,
	meshes: Mesh[],
	attributeName: string,
	updateAll: boolean,
): number {
	if (!geometry.hasAttribute(attributeName)) return 0;

	// At this point, `meshes` is expected to be non-empty and have consistent attributes due to previous
	// call of `makeGeometriesMergable`.
	assert(meshes[0].geometry.hasAttribute(attributeName), `Geometries are not mergeable, missing ${attributeName}`);

	const totalLength = meshes.reduce((acc, m) => {
		return acc + m.geometry.getAttribute(attributeName).array.length;
	}, 0);
	if (totalLength === 0) return 0;

	const attribute = geometry.getAttribute(attributeName);
	assert(attribute instanceof BufferAttribute && attribute.array instanceof Float32Array);

	let offset = attribute.itemSize * geometry.userData.nbVerticesUsed;
	assert(
		attribute.array.length >= offset + totalLength,
		"Buffer is not big enough to store the merged attribute data.",
	);
	if (updateAll) {
		attribute.clearUpdateRanges();
	} else {
		attribute.addUpdateRange(offset, totalLength);
	}

	for (const m of meshes) {
		const p = m.geometry.getAttribute(attributeName);
		attribute.array.set(p.array, offset);
		offset += p.array.length;
	}

	attribute.needsUpdate = true;
	return totalLength / attribute.itemSize;
}

/**
 * Merge index data of the meshes into the geometry
 *
 * @param geometry The geometry to merge into
 * @param meshes The meshes to be merged
 * @param updateAll Whether to update all the index or just the new range
 * @returns Number of new index added to the geometry
 */
function mergeIndexData(geometry: BufferGeometry, meshes: Mesh[], updateAll: boolean): number {
	// At this point, `meshes` is expected to be non-empty and have consistent attributes due to previous
	// call of `makeGeometriesMergable`.
	assert(meshes.length > 0, "The list of meshes to merge can't be empty.");
	assert(
		meshes.every((mesh) => mesh.geometry.index),
		"Geometries must be indexed.",
	);

	const totalLength = meshes.reduce((acc, m) => acc + (m.geometry.index?.count ?? 0), 0);
	if (totalLength === 0) return 0;

	const index = geometry.getIndex();
	assert(index && index.array instanceof Uint32Array, "Unexpected index attribute");

	let offset: number = geometry.userData.nbIndicesUsed;
	assert(index.array.length >= offset + totalLength, "Buffer is not big enough to store the merged index.");

	if (updateAll) {
		index.clearUpdateRanges();
	} else {
		index.addUpdateRange(offset, totalLength);
	}

	let indexOffset: number = geometry.userData.nbVerticesUsed;
	for (const m of meshes) {
		const g = m.geometry;

		const idx = g.index;
		assert(idx, "Geometry must have 'index' attribute");

		for (let j = 0; j < idx.count; ++j) {
			index.array[offset + j] = idx.getX(j) + indexOffset;
		}
		offset += idx.count;
		indexOffset += g.getAttribute("position").count;
	}
	index.needsUpdate = true;

	return totalLength;
}

/**
 * If GPU data present indexing mistakes, a warning message is sent to console.
 * This helps debugging on e.g. Apple platforms.
 *
 * @param geometry The geometry to check
 */
export function checkDataSanity(geometry: BufferGeometry): void {
	const vCount = geometry.getAttribute("position").count;
	const nCount = geometry.getAttribute("normal").count;
	if (nCount !== vCount) {
		console.warn(`Error in CAD model VBO: normals should be ${vCount} instead of ${nCount}`);
	}
	if (geometry.hasAttribute("uv")) {
		const uvCount = geometry.getAttribute("uv").count;
		if (uvCount !== vCount) {
			console.warn(`Error in CAD model VBO: texture coordinates should be ${vCount} instead of ${uvCount}`);
		}
	}
	assert(geometry.index);
	const indices = geometry.index.array;
	let invalidIndices = 0;
	for (let i = 0; i < geometry.index.count; ++i) {
		const index = indices[i];
		if (index < 0 || index >= vCount) invalidIndices++;
	}
	if (invalidIndices > 0) {
		console.warn(`Error in CAD model EBO: ${invalidIndices} invalid indices found out of ${indices.length}`);
	}
}

/**
 * Raycast the CAD model on a single node of the merged mesh
 *
 * @param mergedMesh The merged mesh of the CAD model
 * @param node The node to raycast
 * @param raycaster The raycaster object
 * @param intersections The list of found intersections
 */
export function raycastCadNode(
	mergedMesh: Mesh,
	node: CadNode,
	raycaster: Raycaster,
	intersections: Array<Intersection<Object3D>>,
): void {
	const oldDrawStart = mergedMesh.geometry.drawRange.start;
	const oldDrawCount = mergedMesh.geometry.drawRange.count;

	const mesh = node.originalMesh;
	mesh.matrixWorld.multiplyMatrices(mergedMesh.matrixWorld, node.matrixWorld);
	mesh.geometry.setDrawRange(node.iOffset, node.iCount);
	const oldBbox = mergedMesh.geometry.boundingBox;
	const oldBSphere = mergedMesh.geometry.boundingSphere;
	mesh.geometry.boundingBox = node.boundingBox;
	mesh.geometry.boundingSphere = node.boundingSphere;

	mesh.raycast(raycaster, intersections);

	mergedMesh.geometry.boundingBox = oldBbox;
	mergedMesh.geometry.boundingSphere = oldBSphere;
	mergedMesh.geometry.setDrawRange(oldDrawStart, oldDrawCount);
}

/**
 * Adjusts the encoding of all textures in the scene to LinearEncoding
 *
 * @param scene The scene to adjust the textures encoding
 */
export function adjustTexturesEncoding(scene: Object3D): void {
	scene.traverse((o) => {
		if (o instanceof Mesh && o.material.map instanceof Texture) {
			o.material.map.colorSpace = LinearSRGBColorSpace;
			o.material.map.needsUpdate = true;
		}
	});
}

/** Backup material properties changed when set to tomographic mode */
export type TomographicMaterialBackup = {
	blending: Blending;
	blendEquation: BlendingEquation;
	blendSrc: BlendingSrcFactor | BlendingDstFactor;
	blendDst: BlendingDstFactor;
	blendSrcAlpha: number | null;
	blendDstAlpha: number | null;
	transparent: boolean;
	depthTest: boolean;
	depthWrite: boolean;
	isTextured: boolean;
};

/**
 * Set the material of the CAD model to the tomographic material.
 *
 * @param cadMaterial The material of the CAD model
 * @returns The backup of the original material properties
 */
export function setTomographicMaterial(cadMaterial: CadModelMaterial): TomographicMaterialBackup {
	const {
		blending,
		blendEquation,
		blendSrc,
		blendDst,
		blendSrcAlpha,
		blendDstAlpha,
		transparent,
		depthTest,
		depthWrite,
		isTextured,
	} = cadMaterial;
	cadMaterial.blending = CustomBlending;
	cadMaterial.blendEquation = AddEquation;
	cadMaterial.blendSrc = SrcAlphaFactor;
	cadMaterial.blendDst = OneFactor;
	cadMaterial.blendSrcAlpha = SrcAlphaFactor;
	cadMaterial.blendDstAlpha = OneFactor;
	cadMaterial.transparent = true;
	cadMaterial.depthTest = false;
	cadMaterial.depthWrite = false;
	cadMaterial.isTextured = false;

	return {
		blending,
		blendEquation,
		blendSrc,
		blendDst,
		blendSrcAlpha,
		blendDstAlpha,
		transparent,
		depthTest,
		depthWrite,
		isTextured,
	};
}

/**
 * Restore the original material properties of the CAD model and its nodes.
 *
 * @param cadMaterial The material of the CAD model
 * @param backup The backup of the original material properties
 */
export function restoreMaterial(cadMaterial: CadModelMaterial, backup: TomographicMaterialBackup): void {
	Object.assign(cadMaterial, backup);
}
