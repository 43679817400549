import { Color, CubeCamera, CubeTexture, Object3D, Scene, Vector3, WebGLCubeRenderTarget, WebGLRenderer } from "three";

/**
 * Default settings used to generate an environment map
 */
export const environmentMapDefaultOptions = {
	/** Size of each texture of the cubemap*/
	size: 1024,
	/** Near plane for the camera used to generate the cube map */
	near: 0.01,
	/** Far plane for the camera used to generate the cube map */
	far: 1000,
};

/**
 * Type with all the custom options for environment map generation
 */
export type EnvironmentMapOptions = typeof environmentMapDefaultOptions;

/**
 * A class to generate and manage the resources for an environment map
 * An environment map is a cube map generated from an environment (a Scene or object tree) from a specific point in the space
 * to be used for further effects like animations or reflections
 */
export class EnvironmentMap {
	#environment: Object3D;
	#renderer: WebGLRenderer;
	#camera: CubeCamera;
	#target: WebGLCubeRenderTarget;
	#options = { ...environmentMapDefaultOptions };
	#position: Vector3;

	/** @returns the environment cube map */
	get map(): CubeTexture {
		return this.#target.texture;
	}

	/** @returns the position in which this environment map was taken */
	get position(): Vector3 {
		return this.#position;
	}

	/**
	 * Construct a new environment map
	 *
	 * @param environment The object/scene to use for the environment generation
	 * @param renderer The render context for the env map resources
	 * @param position The position in space from where to generate the environment map
	 * @param options Additional options like the texture size or the near/far plane
	 */
	constructor(
		environment: Object3D,
		renderer: WebGLRenderer,
		position: Vector3,
		options: Partial<EnvironmentMapOptions> = {},
	) {
		Object.assign(this.#options, options);
		this.#environment = environment;
		this.#renderer = renderer;
		this.#position = position;
		this.#target = new WebGLCubeRenderTarget(this.#options.size);
		this.#camera = new CubeCamera(this.#options.near, this.#options.far, this.#target);
		this.#camera.position.copy(this.#position);
		this.update();
	}

	/**
	 * Dispose all resources for this env map
	 */
	dispose(): void {
		this.#target.texture.dispose();
		this.#target.dispose();
	}

	/**
	 * Update the cube map from the source environment
	 */
	update(): void {
		if (this.#environment instanceof Scene) {
			this.#camera.update(this.#renderer, this.#environment);
		} else {
			// Update the world matrix if needed
			this.#environment.updateMatrixWorld();
			const { parent } = this.#environment;

			// Create a new scene that does not automatically updates the matrices of the objects
			const scene = new Scene();
			scene.matrixWorldAutoUpdate = false;
			scene.add(this.#environment);

			// Make the background transparent
			const clearColor = this.#renderer.getClearColor(new Color());
			const clearAlpha = this.#renderer.getClearAlpha();
			this.#renderer.setClearColor(new Color(), 0);
			this.#camera.update(this.#renderer, scene);
			this.#renderer.setClearColor(clearColor, clearAlpha);

			if (parent) {
				parent.add(this.#environment);
			}
		}
		this.#target.texture.name = `EnvMap from ${this.#environment.name || this.#environment.uuid}`;
	}
}
