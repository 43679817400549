import { Box3, Vector3 } from "three";

/** The calculated frustum for an orthographic camera. */
export type OrthoFrustum = {
  /** The center point of the bounding box that is focused by the camera. */
  bboxCenter: Vector3;

  /** Camera frustum top plane. */
  top: number;

  /** Camera frustum bottom plane. */
  bottom: number;

  /** Camera frustum left plane. */
  left: number;

  /** Camera frustum right plane. */
  right: number;
};

/**
 * @param worldPositions Placeholders positions
 * @param aspectRatio Screen aspect ratio
 * @param paddingFactor Factor to modify the zoom of the camera relative to the bounds of @see worldPositions
 * by default Add a 30% padding so that all elements are properly visible.
 * @returns Orthocamera position and frustum parameters.
 */
export function computeOrthoFrustum(
  worldPositions: number[][],
  aspectRatio: number,
  paddingFactor = 1.3,
): OrthoFrustum {
  const box = new Box3().setFromArray(worldPositions.flat(1));
  // Make sure the box is not too small (for example with project with a single capture)
  const MIN_BOX_SIZE = 5;
  const boxMinSize = Math.min(box.max.x - box.min.x, box.max.z - box.min.z);
  if (boxMinSize < MIN_BOX_SIZE) {
    box.expandByScalar(MIN_BOX_SIZE - boxMinSize);
  }
  return computeOrthoFrustumFromBox(box, aspectRatio, paddingFactor);
}

/**
 * @param bbox Box to be included in the frustum
 * @param aspectRatio Screen aspect ratio
 * @param paddingFactor Factor to modify the zoom of the camera relative to the bounds of @see worldPositions
 * by default Add a 30% padding so that all elements are properly visible.
 * @returns Orthocamera position and frustum parameters.
 */
export function computeOrthoFrustumFromBox(
  bbox: Box3,
  aspectRatio: number,
  paddingFactor = 1.3,
): OrthoFrustum {
  if (aspectRatio <= 0) {
    throw Error("aspect ratio cannot be less than 1");
  }
  const center = bbox.getCenter(new Vector3());
  const size = bbox.getSize(new Vector3());

  // Add a 30% padding so that all elements are properly visible.

  // We select the size by picking the max one and increasing it by the padding factor
  const iAspectRatio = size.x / size.z;
  const screenSize =
    (iAspectRatio < aspectRatio ? size.z * 0.5 : size.x * 0.5) * paddingFactor;
  const widthFactor = iAspectRatio < aspectRatio ? aspectRatio : 1;
  const heightFactor = iAspectRatio < aspectRatio ? 1 : 1 / aspectRatio;

  return {
    bboxCenter: center,
    top: screenSize * heightFactor,
    bottom: -screenSize * heightFactor,
    left: -screenSize * widthFactor,
    right: screenSize * widthFactor,
  };
}
