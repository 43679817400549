import { ShaderMaterial, Texture } from "three";
import vert from "../Shaders/TexturedQuad.vert";
import frag from "../Shaders/Desaturate.frag";
import { makeUniform } from "./Uniforms";
import { initShaderChunk } from "../Shaders/InitShaderChunk";

/**
 * A material to convert the input framebuffer to black and white in real-time.
 * Can be modified to do more advanced HSL color manipulations.
 */
export class DesaturateMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uColorTexture: makeUniform<Texture | null>(null),
		uDepthTexture: makeUniform<Texture | null>(null),
		uSaturationMultiplier: makeUniform(0),
		uLightnessMultiplier: makeUniform(1),
	};

	constructor() {
		super();
		initShaderChunk();
	}
}
