import { GLSL3, RawShaderMaterial, Texture, Vector4 } from "three";
import frag from "../Shaders/ComposeFramebuffers.frag";
import { initShaderChunk } from "../Shaders/InitShaderChunk";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { CameraMode } from "../Utils";
import { makeUniform } from "./Uniforms";

/**
 * A material to compose two framebuffers on top of a background buffer. Composition can happen with blending,
 * trivially with opaque rendering, or implementing thermographic distance along the camera view direction.
 */
export class ComposeFramebuffersMaterial extends RawShaderMaterial {
	#cameraMode: CameraMode = CameraMode.Perspective;

	override vertexShader = vert;
	override fragmentShader = frag;

	override uniforms = {
		uColorTex0: makeUniform<Texture | null>(null),
		uDepthTex0: makeUniform<Texture | null>(null),
		uColorTex1: makeUniform<Texture | null>(null),
		uDepthTex1: makeUniform<Texture | null>(null),
		uColorTex2: makeUniform<Texture | null>(null),
		uDepthTex2: makeUniform<Texture | null>(null),
		opacity1: makeUniform<number>(1.0),
		opacity2: makeUniform<number>(1.0),
		uDepthOffset: makeUniform(0),
		uNearPlane: makeUniform<number>(0.1),
		uFarPlane: makeUniform<number>(1000.0),
		uFalseColors: makeUniform(false),
		uThermoCompare: makeUniform(false),
		uThermoThreshold: makeUniform<number>(1),
		uBackground: makeUniform(new Vector4(0, 0, 0, 0)),
		uOverrideBackground: makeUniform(false),
		uMinOpacity: makeUniform<number>(0),
	};

	/**
	 * Constructs a new instance of ComposeFramebuffersMaterial.
	 *
	 * @param cm The camera mode to compile the material for
	 */
	constructor(cm: CameraMode) {
		super({ glslVersion: GLSL3 });
		this.cameraMode = cm;
		initShaderChunk();
	}

	/** @returns the camera mode the material is compiled for */
	get cameraMode(): CameraMode {
		return this.#cameraMode;
	}

	/** Sets the camera mode the material is compiled for */
	set cameraMode(cm: CameraMode) {
		if (cm !== this.#cameraMode) {
			this.#cameraMode = cm;
			const proj = cm === CameraMode.Orthographic ? "#define ORTHO_PROJECTION\n" : "";
			this.fragmentShader = `${proj}${frag}`;
		}
	}
}
