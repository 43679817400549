import { GLSL3, Matrix4, RawShaderMaterial, Texture } from "three";
import frag from "../Shaders/FastSsao.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { makeUniform } from "./Uniforms";

const FAST_SSAO_DEFAULT_BIAS = 0.05;

/**
 * A ThreeJS material to compute in screen space the Ambient Occlusion factor from a depth texture.
 *
 * This shader is suited to be rendered on a fullscreen quad. It takes as input high-precision depth data
 * of the scene from a floating-point depth texture, and from the depth it reconstructs the normals and it
 * computes the ambient occlusion factor per pixel, in the range 0.0 (max occlusion) to 1.0 (no occlusion).
 * The occlusion factor is saved in a one-channel output texture in floating point format.
 */
export class FastSsaoMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uDepthTex: makeUniform<Texture | null>(null),
		uStrengthFactor: makeUniform<number>(1),
		uRadius: makeUniform<number>(1),
		uBias: makeUniform<number>(FAST_SSAO_DEFAULT_BIAS),
		uInvPMatrix: makeUniform(new Matrix4()),
	};

	/**
	 * Default constructor
	 */
	constructor() {
		super({ glslVersion: GLSL3 });
	}

	/**
	 * @returns the Fast Ssao sampling radius
	 */
	get radius(): number {
		return this.uniforms.uRadius.value;
	}

	/**
	 * Sets the Ssao hemspheric kernel radius
	 */
	set radius(r: number) {
		if (r >= 0.01 && r <= 10) {
			this.uniforms.uRadius.value = r;
			this.uniformsNeedUpdate = true;
		}
	}

	/**
	 * @returns The value of angle bias used in the Fast Ssao shader to avoid noise.
	 */
	get angleBias(): number {
		return this.uniforms.uBias.value;
	}

	/**
	 * @param b The value of angle bias to set to the shader.
	 */
	set angleBias(b: number) {
		if (b >= 0 && b < 10) {
			this.uniforms.uBias.value = b;
			this.uniformsNeedUpdate = true;
		}
	}

	/**
	 * @param factor Sets the strength factor for the the shader.
	 */
	set strengthFactor(factor: number) {
		if (factor > 0) {
			this.uniforms.uStrengthFactor.value = factor;
			this.uniformsNeedUpdate = true;
		}
	}

	/**
	 * @returns Gets the strength factor for the the shader.
	 */
	get strengthFactor(): number {
		return this.uniforms.uStrengthFactor.value;
	}
}
