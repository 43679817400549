import { assert } from "@faro-lotv/foundation";
import { ShaderMaterial, Texture } from "three";
import { makeUniform } from "../Materials/Uniforms";
import frag from "../Shaders/MapPlaceholders.frag";
import vert from "../Shaders/MapPlaceholders.vert";

const DEFAULT_SIZE = 16.0;
const DEFAULT_HOVER_SIZE = 20.0;
const DEFAULT_SELECTED_SIZE = 24.0;
export const PLACEHOLDER_LABEL_BIT_POS = 4;

/** A placeholder texture used to fill missing textures in the material */
const EMPTY_TEXTURE = new Texture();

const MAX_TEXTURES = 15;

export enum PlaceholdersTexture {
	/** Labels the texture used on a placeholder class by default */
	Default = 0,
	/** Labels the texture used on a placeholder class when it's hovered */
	Hovered = 1,
	/** Labels the texture used on a placeholder class when it's selected */
	Selected = 2,
}

export type PlaceholderSize = {
	/** Pixel size of the placeholder in the default state */
	default: number;
	/** Pixel size of the placeholder when it is hovered */
	hovered: number;
	/** Pixel size of the placeholder when it is selected */
	selected: number;
};

/**
 * Specialized material to render placeholders with a fixed pixel size using a PointCloud
 *
 * @see MapPlaceholders
 */
export class MapPlaceholdersMaterial extends ShaderMaterial {
	/** Custom fragment shader source code */
	fragmentShader = frag;

	/** Custom vertex shader source code */
	vertexShader = vert;

	/** Default to transparent to get proper rendering with non quad textures */
	transparent = true;

	uniforms = {
		/**
		 * Array of max 15 textures that the material handles at the same time.
		 * Textures are stored as triplets for each label: [default, hovered, selected]
		 */
		textures: makeUniform(new Array<Texture>()),

		sizes: makeUniform(new Array<number>()),

		/** Min alpha threshold to render a fragment */
		alphaTest: makeUniform(0.1),
	};

	/**
	 * Constructs a new instance of MapPlaceholdersMaterial
	 */
	constructor() {
		super();

		this.defines = { ...this.defines, MAX_TEXTURES, PLACEHOLDER_LABEL_BIT_POS };

		this.uniforms.textures.value = new Array(MAX_TEXTURES).fill(EMPTY_TEXTURE);
		for (let i = 0; i < MAX_TEXTURES / 3; i++) {
			this.uniforms.sizes.value[3 * i] = DEFAULT_SIZE;
			this.uniforms.sizes.value[3 * i + 1] = DEFAULT_HOVER_SIZE;
			this.uniforms.sizes.value[3 * i + 2] = DEFAULT_SELECTED_SIZE;
		}
	}

	/**
	 * Gets the map of the given type for any of the placeholder labels.
	 *
	 * @param labelIndex Index of the label whose map is requested
	 * @param textureType Type of the map requested
	 * @returns map of the given type for the given label
	 */
	getMap(labelIndex: number, textureType: PlaceholdersTexture): Texture {
		assert(labelIndex >= 0 && labelIndex <= 4, "Invalid range for placeholder class label");
		return this.uniforms.textures.value[labelIndex * 3 + textureType];
	}

	/**
	 * Sets the map of given type for any of the placeholder labels.
	 *
	 * @param labelIndex Index of the label whose default map is being set
	 * @param textureType Type of the map being set
	 * @param texture Texture to be set as map of given type for the given label
	 */
	setMap(labelIndex: number, textureType: PlaceholdersTexture, texture: Texture | undefined): void {
		assert(labelIndex >= 0 && labelIndex <= 4, "Invalid range for placeholder class label");
		this.uniforms.textures.value[labelIndex * 3 + textureType] = texture ?? EMPTY_TEXTURE;
	}

	/**
	 *
	 * @param labelIndex Index of the label whose size is requested
	 * @returns The sizes used for the given placeholder label
	 */
	getSizes(labelIndex: number): PlaceholderSize {
		assert(labelIndex >= 0 && labelIndex <= 4, "Invalid range for placeholder class label");
		return {
			default: this.uniforms.sizes.value[3 * labelIndex],
			hovered: this.uniforms.sizes.value[3 * labelIndex + 1],
			selected: this.uniforms.sizes.value[3 * labelIndex + 2],
		};
	}

	/**
	 *
	 * @param labelIndex Index of the label whose size is being set
	 * @param sizes Sizes to be set for the given placeholder label, in pixels
	 */
	setSizes(labelIndex: number, sizes: PlaceholderSize): void {
		assert(labelIndex >= 0 && labelIndex <= 4, "Invalid range for placeholder class label");
		assert(
			sizes.default > 0 && sizes.hovered > 0 && sizes.selected > 0,
			"Placeholder class sizes must be strictly positive",
		);
		this.uniforms.sizes.value[3 * labelIndex] = sizes.default;
		this.uniforms.sizes.value[3 * labelIndex + 1] = sizes.hovered;
		this.uniforms.sizes.value[3 * labelIndex + 2] = sizes.selected;
		this.uniformsNeedUpdate = true;
	}

	/** @returns the maximum pixel size set for the current placeholder classes */
	get maximumSize(): number {
		return Math.max(...this.uniforms.sizes.value);
	}
}
