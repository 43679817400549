import { TypedEvent, assert } from "@faro-lotv/foundation";
import { Box3, Group, Intersection, Object3D, Plane, Raycaster, Vector3 } from "three";
import {
	adjustTexturesEncoding,
	categorizeMeshes,
	checkDataSanity,
	raycastCadNode,
	sortMeshes,
} from "./CadModelPrivateUtils";
import { CadRenderingMode, ICadModel } from "./ICadModel";
import { StreamCadChunk, StreamCadChunkState } from "./StreamCadChunk";
import { StreamCadFetcher } from "./StreamCadFetcher";
import { MeshMode, StreamCadMesh } from "./StreamCadMesh";

/**
 * A CAD model that can be streamed in chunks.
 */
export class StreamCadModel extends Group implements ICadModel {
	/** List of meshes of each type, ordered according to the `MeshMode` enum value */
	#meshes: StreamCadMesh[] = [
		new StreamCadMesh(MeshMode.OpaqueNoTexture),
		new StreamCadMesh(MeshMode.OpaqueTexture),
		new StreamCadMesh(MeshMode.TransparentNoTexture),
		new StreamCadMesh(MeshMode.TransparentTexture),
	];

	/** The bounding box of this CAD model */
	#boundingBox: Box3;
	/** The rendering mode of this CAD model */
	#renderingMode = CadRenderingMode.OpaqueScene;
	/** List of model chunks */
	#chunks: StreamCadChunk[];
	/** Fetcher used to download model chunks */
	#fetcher = new StreamCadFetcher();
	/** Whether the model is currently streaming */
	#isStreaming = false;
	/** Whether the streaming is completed */
	#isCompleted = false;
	/** Event emitted when a chunk is loaded */
	#chunkLoaded = new TypedEvent<void>();
	/** Event emitted when streaming completed */
	#streamCompleted = new TypedEvent<void>();
	/** Number of chunks loaded */
	#chunksLoaded = 0;
	/**
	 * Fetching chunk will stop when the accumulated buffer size pass the threshold value returned by this function.
	 * We are using a function instead of a value because the actual value is not yet ready when starting loading.
	 */
	#getMaxByteLength: () => number;
	/** Event emitted when the list of visible objects changed */
	#visibleObjectsChanged = new TypedEvent<void>();
	/** Map linking objectIDs to the corresponding list of CAD part / sub model pair */
	#objectIdMap = new Map<number, Array<{ mesh: StreamCadMesh; index: number }>>();

	/**
	 * Constructs a StreamCadModel object from a list of chunks
	 *
	 * @param chunks The list of chunks define the stream CAD model
	 * @param getMaxByteLength function returning the maximum number of byte to load in memory
	 */
	constructor(chunks: StreamCadChunk[], getMaxByteLength: () => number) {
		super();
		this.#chunks = chunks;
		this.#getMaxByteLength = getMaxByteLength;

		for (const mesh of this.#meshes) {
			this.add(mesh);
		}
		this.#setRenderingMode(CadRenderingMode.OpaqueScene);

		// Over allocate first chunk to reduce reallocation frequency.
		// Set first allocate factor to be 3 if there are more than 3 chunks.
		const firstAllocateFactor = Math.min(3, this.#chunks.length);
		for (const mesh of this.#meshes) {
			mesh.firstAllocateFactor = firstAllocateFactor;
		}

		this.#isStreaming = false;
		this.#isCompleted = this.#chunks.length === 0;

		this.#fetcher.chunkReady.on(({ chunk, model }) => this.#onChunkReady(chunk, model));
		this.#fetcher.chunkAborted.on(({ chunk }) => this.#onChunkAborted(chunk));
		this.#fetcher.chunkFailed.on(({ chunk, reason }) => this.#onChunkFailed(chunk, reason));

		this.#boundingBox = new Box3();
		for (const chunk of this.#chunks) {
			this.#boundingBox.union(chunk.boundingBox);
		}
	}

	/**
	 * Get status of model's loading progress.
	 *
	 * @returns number of chunks loaded to date, plus total number of chunks to be loaded.
	 */
	get loadingProgress(): [number, number] {
		return [this.#chunksLoaded, this.#chunks.length];
	}

	/** Set max byte length of the geometry data of this model  */
	set maxBufferBytes(value: () => number) {
		this.#getMaxByteLength = value;
	}

	/** @returns The max byte length of the geometry data of this model */
	get maxBufferBytes(): number {
		return this.#getMaxByteLength();
	}

	/**
	 * @returns The event emitted when a chunk is loaded
	 */
	get chunkLoaded(): TypedEvent<void> {
		return this.#chunkLoaded;
	}

	/**
	 * @returns The event emitted when streaming is completed
	 */
	get streamCompleted(): TypedEvent<void> {
		return this.#streamCompleted;
	}

	/** Starts streaming the model chunks */
	startStream(): void {
		if (this.#isStreaming || this.#isCompleted) return;

		this.#isStreaming = true;
		this.#loadNextChunk();
	}

	/** Stops streaming the model chunks */
	stopStream(): void {
		this.#isStreaming = false;
		this.#fetcher.abort();
	}

	/** Stream is completed */
	#streamFinished(): void {
		this.#isStreaming = false;
		this.#isCompleted = true;
		this.#streamCompleted.emit();

		for (const mesh of this.#meshes) {
			// check sanity of non-empty geometry
			if (mesh.geometry.hasAttribute("position")) {
				checkDataSanity(mesh.geometry);
			}
		}
	}

	/** Loads the next chunk in the list of chunks */
	#loadNextChunk(): void {
		if (this.byteLength >= this.#getMaxByteLength()) {
			// reached the max buffer size, or all chunks are loaded
			this.#streamFinished();
			return;
		}
		const chunk = this.#nextChunkToLoad();
		if (!chunk) {
			// all chunks loaded
			this.#streamFinished();
			return;
		}
		if (!this.#fetcher.isBusy) {
			chunk.state = StreamCadChunkState.Downloading;
			this.#fetcher.fetch(chunk);
		}
	}

	/**
	 * @returns The the next chunk to be loaded, or undefined if no more chunks to load
	 */
	#nextChunkToLoad(): StreamCadChunk | undefined {
		const idx = this.#chunks.findIndex((chunk) => chunk.state === StreamCadChunkState.WaitForDownload);
		if (idx === -1) return undefined;
		return this.#chunks[idx];
	}

	/**
	 * Callback function when a chunk is downloaded successfully
	 *
	 * @param chunk The downloaded chunk
	 * @param model The model loaded from the chunk
	 */
	#onChunkReady(chunk: StreamCadChunk, model: Group): void {
		// If user stop stream during loading the gltf, process with `addModel` would freeze the browser.
		// For now just discard the model and will download it again when streaming resume.
		if (!this.#isStreaming) {
			chunk.state = StreamCadChunkState.WaitForDownload;
			return;
		}

		this.#addModel(model);
		chunk.state = StreamCadChunkState.DownloadSuccess;

		this.#chunksLoaded++;
		this.#updateStats();
		this.#chunkLoaded.emit();

		if (this.#isStreaming) {
			this.#loadNextChunk();
		}
	}

	/**
	 * Callback function when a chunk is aborted
	 *
	 * @param chunk The aborted chunk
	 */
	#onChunkAborted(chunk: StreamCadChunk): void {
		chunk.state = StreamCadChunkState.WaitForDownload;
		if (this.#isStreaming) {
			this.#loadNextChunk();
		}
	}

	/**
	 * Callback function when a chunk has failed to download
	 *
	 * @param chunk The failed chunk
	 * @param reason Reason for the failure
	 */
	#onChunkFailed(chunk: StreamCadChunk, reason: Error): void {
		console.error(reason);
		chunk.state = StreamCadChunkState.DownloadFailed;
	}

	/** Load one chunk async */
	async loadOneChunk(): Promise<void> {
		if (this.#isStreaming || this.#isCompleted) return;

		const chunk = this.#nextChunkToLoad();
		if (!chunk) return;
		chunk.state = StreamCadChunkState.Downloading;
		const model = await this.#fetcher.fetchAsync(chunk);
		if (!model) {
			chunk.state = StreamCadChunkState.DownloadFailed;
			return;
		}
		chunk.state = StreamCadChunkState.DownloadSuccess;

		this.#addModel(model);
		this.#chunksLoaded++;
		this.#updateStats();
		this.#chunkLoaded.emit();

		if (this.byteLength >= this.#getMaxByteLength() || this.#nextChunkToLoad() === undefined) {
			// reached the max buffer size, or all chunks are loaded
			this.#streamFinished();
		}
	}

	/**
	 * Add a model scene graph, extract all meshes of the input model and merge them
	 * to the specific model buffer according to its rendering mode.
	 *
	 * @param model The model to be added
	 */
	#addModel(model: Group): void {
		// Adjusting all textures to linear encoding
		adjustTexturesEncoding(model);

		// Computing the absolute pose matrices of all nodes in the CAD scene graph
		model.updateWorldMatrix(true, true);

		const meshes = sortMeshes(model);

		if (this.#chunksLoaded === 0) {
			meshes[0].getWorldPosition(this.position);
		}

		const categorized = categorizeMeshes(meshes);

		const cadNodeIdxBegin = new Array<number>();
		for (const mesh of this.#meshes) {
			cadNodeIdxBegin.push(mesh.cadNodes.length);
		}

		this.#meshes[MeshMode.OpaqueNoTexture].addMeshes(categorized.opaqueNoTexture, this.position);
		this.#meshes[MeshMode.OpaqueTexture].addMeshes(categorized.opaqueTexture, this.position);
		this.#meshes[MeshMode.TransparentNoTexture].addMeshes(categorized.transparentNoTexture, this.position);
		this.#meshes[MeshMode.TransparentTexture].addMeshes(categorized.transparentTexture, this.position);

		for (let i = 0; i < this.#meshes.length; ++i) {
			this.#computeObjectIdMap(this.#meshes[i], cadNodeIdxBegin[i], this.#meshes[i].cadNodes.length);
		}

		// update transparent mesh draw id offset
		let drawIdOffset = 0;
		for (const mesh of this.#meshes) {
			mesh.drawIdOffset = drawIdOffset;
			drawIdOffset += mesh.cadNodes.length;
		}
	}

	/** @inheritdoc */
	boundingBox(): Box3 {
		return this.#boundingBox;
	}

	/** @inheritdoc */
	nodesCount(): number {
		let count = 0;
		for (const mesh of this.#meshes) {
			count += mesh.cadNodes.length;
		}
		return count;
	}

	/** @inheritdoc */
	set renderingMode(m: CadRenderingMode) {
		if (m === this.#renderingMode) return;
		this.#setRenderingMode(m);
	}

	/** @inheritdoc */
	get renderingMode(): CadRenderingMode {
		return this.#renderingMode;
	}

	/**
	 * Set the rendering mode of this object
	 *
	 * 	@param m The new rendering mode
	 */
	#setRenderingMode(m: CadRenderingMode): void {
		this.#renderingMode = m;
		switch (this.#renderingMode) {
			case CadRenderingMode.OpaqueScene:
				for (const mesh of this.#meshes) {
					mesh.switchToDefaultMaterial();
					mesh.visible = !mesh.isTransparent;
				}
				break;
			case CadRenderingMode.TransparentScene:
				for (const mesh of this.#meshes) {
					mesh.switchToDefaultMaterial();
					mesh.visible = mesh.isTransparent;
				}
				break;
			case CadRenderingMode.PartsIDs:
				for (const mesh of this.#meshes) {
					mesh.switchToPartIDsMaterial();
					mesh.visible = true;
				}
				break;
		}
	}

	/**
	 * Get the CadModelMesh object that contains the n-th node in the StreamCadModel and
	 * the index of the node in the CadModelMesh.
	 *
	 * @param n The index of the queried node
	 * @returns The CadModelMesh object that contains the n-th node, and the index of the node in the CadModelMesh
	 */
	#getCadNodeIdx(n: number): { mesh: StreamCadMesh; idx: number } {
		let idxBegin = 0;
		for (const mesh of this.#meshes) {
			const idxEnd = idxBegin + mesh.cadNodes.length;
			if (n >= idxBegin && n < idxEnd) {
				return { mesh, idx: n - idxBegin };
			}
			idxBegin = idxEnd;
		}
		assert(false, "Invalid node index");
	}

	/** @inheritdoc */
	setObjectHighlighted(objectId: number, v: boolean): void {
		const ids = this.#objectIdMap.get(objectId);
		if (!ids) return;
		for (const { mesh, index } of ids) {
			mesh.setNodeHighlighted(index, v);
		}
	}

	/** @inheritdoc */
	isObjectHighlighted(objectId: number): boolean {
		const ids = this.#objectIdMap.get(objectId);
		if (!ids || ids.length === 0) return false;
		return ids[0].mesh.cadNodes[ids[0].index].highlighted;
	}

	/** @inheritdoc */
	get highlightingColor(): Vector3 {
		return this.#meshes[0].highlightingColor;
	}

	/** @inheritdoc */
	set highlightingColor(color: Vector3) {
		for (const mesh of this.#meshes) {
			mesh.highlightingColor = color;
		}
	}

	/**
	 * Check if this model contains the Object3D id
	 *
	 * @param obj The object to check
	 * @returns True if the object is contained in the model
	 */
	contains(obj: Object3D): boolean {
		if (obj.id === this.id) {
			return true;
		}

		for (const mesh of this.#meshes) {
			if (obj.id === mesh.id) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Returns the byte length of the geometry data of this model
	 *
	 * @returns The byte length of all meshes in the model
	 */
	get byteLength(): number {
		let bytes = 0;
		for (const mesh of this.#meshes) {
			bytes += mesh.byteLength;
		}
		return bytes;
	}

	/** @returns Whether the model is currently streaming */
	get isStreaming(): boolean {
		return this.#isStreaming;
	}

	/**
	 * @returns Whether the streaming is completed
	 */
	get isCompleted(): boolean {
		return this.#isCompleted;
	}

	/** @returns the stats of the model */
	get stats(): Readonly<StreamCadModelStats> {
		return this.#stats;
	}

	#stats: StreamCadModelStats = {
		bufferSize: "0 MB",
		chunks: "0/0",
	};

	/** Update stats */
	#updateStats(): void {
		const size = (this.byteLength / 1024 / 1024).toFixed();
		const maxSize = (this.#getMaxByteLength() / 1024 / 1024).toFixed();
		this.#stats.bufferSize = `${size} MB / ${maxSize} MB`;
		this.#stats.chunks = `${this.#chunksLoaded}/${this.#chunks.length}`;
	}

	/**
	 * Computes the map from ObjectID to CadNodes belong to the Object
	 *
	 * @param mesh The mesh to be processed
	 * @param idxBegin The begin index of the CadNodes to be processed
	 * @param idxEnd The end index of the CadNodes to be processed
	 */
	#computeObjectIdMap(mesh: StreamCadMesh, idxBegin: number, idxEnd: number): void {
		for (let index = idxBegin; index < idxEnd; index++) {
			const node = mesh.cadNodes[index];
			if (node.objectId === undefined) continue;

			let ids = this.#objectIdMap.get(node.objectId);
			if (!ids) {
				ids = [];
				this.#objectIdMap.set(node.objectId, ids);
			}
			ids.push({ mesh, index });
		}
	}

	/** @inheritdoc */
	drawIdToObjectId(drawId: number): number | undefined {
		if (drawId < 0 || drawId >= this.nodesCount()) return undefined;
		const { mesh, idx } = this.#getCadNodeIdx(drawId);
		return mesh.cadNodes[idx].objectId;
	}

	/** @inheritdoc */
	setObjectVisible(objectId: number, v: boolean): void {
		const ids = this.#objectIdMap.get(objectId);
		if (!ids) return;

		let changed = false;
		for (const { mesh, index } of ids) {
			if (mesh.setNodeVisible(index, v)) {
				changed = true;
			}
		}
		if (changed) {
			this.#visibleObjectsChanged.emit();
		}
	}

	/** @inheritdoc */
	isObjectVisible(objectId: number): boolean {
		const ids = this.#objectIdMap.get(objectId);
		if (!ids || ids.length === 0) return false;
		return ids[0].mesh.cadNodes[ids[0].index].visible;
	}

	/** @inheritdoc */
	get visibleObjectsChanged(): TypedEvent<void> {
		return this.#visibleObjectsChanged;
	}

	/** @inheritdoc */
	get clippingInLocalTransform(): boolean {
		return this.#meshes[0].clippingInLocalTransform;
	}

	/** @inheritdoc */
	set clippingInLocalTransform(value: boolean) {
		for (const mesh of this.#meshes) {
			mesh.clippingInLocalTransform = value;
		}
	}

	/** @inheritdoc */
	get clipping(): boolean {
		return this.#meshes[0].material.clipping;
	}

	/** @inheritdoc */
	set clipping(v: boolean) {
		for (const mesh of this.#meshes) {
			mesh.material.clipping = v;
		}
	}

	/** @inheritdoc */
	set clippingPlanes(planes: Plane[]) {
		for (const mesh of this.#meshes) {
			mesh.material.clippingPlanes = planes;
		}
	}

	/** @inheritdoc */
	get clipIntersection(): boolean {
		return this.#meshes[0].material.clipIntersection;
	}

	/** @inheritdoc */
	set clipIntersection(v: boolean) {
		for (const mesh of this.#meshes) {
			mesh.material.clipIntersection = v;
		}
	}

	/** @inheritdoc */
	raycastNode(n: number, raycaster: Raycaster, intersections: Array<Intersection<Object3D>>): void {
		const { mesh, idx } = this.#getCadNodeIdx(n);
		raycastCadNode(mesh, mesh.cadNodes[idx], raycaster, intersections);
	}

	/** @inheritdoc */
	get objectIds(): IterableIterator<number> {
		return this.#objectIdMap.keys();
	}

	/** @inheritdoc */
	nodeBoundingBox(n: number, ret = new Box3()): Box3 {
		const { mesh, idx } = this.#getCadNodeIdx(n);
		const node = mesh.cadNodes[idx];
		return ret.copy(node.boundingBox).applyMatrix4(node.matrixWorld).applyMatrix4(mesh.matrixWorld);
	}

	/** @inheritdoc */
	setTomographic(tomographic: boolean): void {
		// Make sure CAD model is rendered as opaque scene, where the material
		// is an instance of CadModelMaterial
		this.renderingMode = CadRenderingMode.OpaqueScene;
		for (const mesh of this.#meshes) {
			mesh.setTomographic(tomographic);
		}
	}
}

/** Stats of StreamCadModel */
type StreamCadModelStats = {
	bufferSize: string;
	chunks: string;
};
