import {
  Box,
  SxProps,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useCheckForOverflow } from "../../hooks/use-check-for-overflow";
import { FaroText, FaroTextProps } from "./faro-text/faro-text";

/** @returns a text node that will not overflow, instead will truncate and show a tooltip */
export function TruncatedText({
  children,
  ...rest
}: TypographyProps): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  return (
    <Tooltip title={children} disableHoverListener={!hasOverflown}>
      <Typography
        onMouseEnter={(ev) => checkForOverflow(ev.currentTarget)}
        noWrap
        {...rest}
      >
        {children}
      </Typography>
    </Tooltip>
  );
}

export type TruncatedFaroTextProps = FaroTextProps & {
  /** Styling for the container div that is used to manage the truncation */
  containerSx?: SxProps;
};

/** @returns a text node that will not overflow, instead will truncate and show a tooltip */
export function TruncatedFaroText({
  children,
  containerSx,
  ...rest
}: TruncatedFaroTextProps): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  return (
    <Tooltip title={children} disableHoverListener={!hasOverflown}>
      <Box
        // This box is needed for the tooltip to work
        component="div"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          minWidth: 0,
          ...containerSx,
        }}
      >
        <FaroText
          // a block element is needed for useCheckForOverflow to work
          component="div"
          onMouseEnter={(ev) => checkForOverflow(ev.currentTarget)}
          shouldElide
          {...rest}
        >
          {children}
        </FaroText>
      </Box>
    </Tooltip>
  );
}
