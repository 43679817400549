import { validateArrayOf } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "./progress-api-types";

/**
 * Only the properties which are used in the Viewer are defined below.
 * For more information or adding further properties please refer to the swagger guide given below
 * https://progress.api.dev.holobuilder.eu/swagger/index.html
 */

/** Possible values of the status of a background task */
export enum BackgroundTaskInfoType {
  state = "State",
  progress = "Progress",
}

/** Information on current progress of a task, based on which the progress percentage can be calculated */
type Progress = {
  /** Number of steps already completed */
  current: number;

  /** Total number of steps required to be completed */
  total: number;
};

export type DownloadResult = {
  /** Download url for an export task */
  downloadUrl: string;
};

/**
 * @returns true if the object is a DownloadResult
 * @param obj object to be checked
 */
export function validateDownloadResult(obj: unknown): obj is DownloadResult {
  if (!obj || typeof obj !== "object") {
    return false;
  }

  const toCheck: Partial<DownloadResult> = obj;

  return typeof toCheck.downloadUrl === "string";
}

type BackgroundTaskInfo = {
  /** Unique identifier of the update */
  id: GUID;

  /** Type of the task status information which can be related to either a State or a Progress type */
  type: BackgroundTaskInfoType;

  /** ISO-8601 datetime string when the update happened */
  changedAt: string;

  /**
   * Additional arbitrary internal developer text message about this update (eg. a stack trace).
   * Not intended to be shown to an end-user. Requires the `hb-admin = true` claim by the requesting entity.
   */
  devMessage: string | null;

  /**
   * An indication that an operation has failed. Additional information might be available in the devMessage field.
   *
   * See https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3539435793/Error+Codes+Overview.
   */
  errorCode?: string | null;

  // TODO: According to the docs, this object might only contain either state or progress, not both
  // See https://progress.api.dev.holobuilder.com/swagger/index.html#tag/Status/paths/~1v1~1status~1latest/get

  /** State transition, a conditional assignment of a state to the state machine name */
  state?: BackgroundTaskState;

  /** Movement towards a refined, improved, or otherwise desired state */
  progress?: Progress;

  /**
   * A result field containing info related to the task
   * Possible values: https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3590587083/Task+Result+Overview
   */
  result?: DownloadResult | unknown;
};

type BackgroundTaskInfoState = BackgroundTaskInfo & {
  /** The background task's status type, 'State' in this case */
  type: BackgroundTaskInfoType.state;

  /** The background task's current state */
  state: BackgroundTaskState;
};

type BackgroundTaskInfoProgress = BackgroundTaskInfo & {
  /** The background task's status type, 'Progress' in this case */
  type: BackgroundTaskInfoType.progress;

  /** The background task's current progress */
  progress: Progress;
};

/**
 * Details on a long running background task
 */
export type BackgroundTaskDetails = {
  /** Unique identifier of the task */
  id: GUID;

  /** The date when the task has been created, as an ISO 8601 time string. */
  createdAt: string;

  /** Type of the task. e.g 'PointCloudE57ToLaz' for a PointCloud processing task */
  taskType: string | null;

  /** Resources for the appropriate interpretation of this task */
  context: BackgroundTaskContext;

  /** The latest task status update available for this task */
  status: BackgroundTaskInfo;

  /**
   * An unordered optional collection of arbitrary strings a producer might attach
   * to enable clients to implement more granular filtering depending on the use-case.
   */
  tags?: string[];
};

type BackgroundTaskContext = {
  /** The company for which this task was performed */
  companyId: GUID | null;

  /** The relationship to an IElement.Id from the project-api-v2 */
  elementId: GUID | null;

  /** The project for which this task was performed */
  projectId: GUID | null;

  /** The user for which this task was performed */
  userId: GUID | null;

  /**
   * A unique identifier that is added to the very first interaction to identify the context and is passed to all
   * components that are involved in the transaction flow. In typical cases a random UUID is generated by the frontend
   * when a user triggers an action. This UUID is then passed on to all backends involved in the execution of that
   * action. If a backend calls another backends, they also pass on the value.
   */
  correlationId: GUID | null;

  /**
   * Uniquely identifies tasks that share the same context,
   * for example (taskType + companyId + projectId + userId + timestamp). A collection of multiple smaller tasks
   * together define the overall job. In many cases this value is probably the same as the Azure Batch Job ID and
   * the SessionId.
   */
  jobId: GUID;

  /**
   * An unordered optional collection of arbitrary strings a producer might attach
   * to enable clients to implement more granular filtering depending on the use-case.
   */
  tags?: string[];
};

/**
 * @returns true if the provided object is of type BackgroundTaskContext
 * @param context object to check
 */
export function isBackgroundTaskContext(
  context: unknown,
): context is BackgroundTaskContext {
  if (!context || typeof context !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskContext> = context;

  return (
    (typeof toCheck.companyId === "string" || toCheck.companyId === null) &&
    (typeof toCheck.elementId === "string" || toCheck.elementId === null) &&
    (typeof toCheck.projectId === "string" || toCheck.projectId === null) &&
    (typeof toCheck.userId === "string" || toCheck.userId === null) &&
    (typeof toCheck.correlationId === "string" ||
      toCheck.correlationId === null) &&
    typeof toCheck.jobId === "string" &&
    (toCheck.tags === undefined ||
      validateArrayOf({
        object: toCheck,
        prop: "tags",
        elementGuard: (x) => typeof x === "string",
      }))
  );
}

/** The response from the progress API request for the endpoint '/v1/status/latest' */
export type ProgressApiLatestStatusResponse = {
  /** List of tasks with their status details */
  data: BackgroundTaskDetails[];
  /** The token of the next page, if it exists*/
  after: string | null;
  /** The token of the previous page, if it exists*/
  before: string | null;
};

/**
 * @returns true if the object is a ProgressApiLatestStatusResponse
 * @param data object to be checked if it is of type ProgressApiLatestStatusResponse
 */
export function isProgressApiLatestStatusResponse(
  data: unknown,
): data is ProgressApiLatestStatusResponse {
  if (!data || typeof data !== "object") {
    return false;
  }

  const toCheck: Partial<ProgressApiLatestStatusResponse> = data;

  return !!toCheck.data && toCheck.data.every(isBackgroundTaskDetails);
}

/**
 * @returns true if the object is a BackgroundTaskDetails
 * @param data object to be checked if it is of type BackgroundTaskDetails
 */
export function isBackgroundTaskDetails(
  data: unknown,
): data is BackgroundTaskDetails {
  if (!data || typeof data !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskDetails> = data;

  return (
    typeof toCheck.id === "string" &&
    typeof toCheck.createdAt === "string" &&
    (typeof toCheck.taskType === "string" || toCheck.taskType === null) &&
    isBackgroundTaskInfo(toCheck.status) &&
    isBackgroundTaskContext(toCheck.context)
  );
}

/**
 * @returns true if the object is a BackgroundTaskInfo
 * @param status object to be checked if it is of type BackgroundTaskInfo
 */
export function isBackgroundTaskInfo(
  status: unknown,
): status is BackgroundTaskInfo {
  if (!status || typeof status !== "object") {
    return false;
  }

  const toCheck: Partial<BackgroundTaskInfo> = status;

  return (
    typeof toCheck.id === "string" &&
    Object.values<unknown>(BackgroundTaskInfoType).includes(toCheck.type) &&
    typeof toCheck.changedAt === "string" &&
    (typeof toCheck.devMessage === "string" || toCheck.devMessage === null) &&
    (typeof toCheck.errorCode === "string" || !toCheck.errorCode)
  );
}

/**
 * @returns true if the object is a BackgroundTaskInfoState
 * @param status object to be checked if it is of type BackgroundTaskInfoState
 */
export function isBackgroundTaskInfoState(
  status: unknown,
): status is BackgroundTaskInfoState {
  if (
    !isBackgroundTaskInfo(status) ||
    status.type !== BackgroundTaskInfoType.state
  ) {
    return false;
  }
  return Object.values<unknown>(BackgroundTaskState).includes(status.state);
}

/**
 * @returns true if the object is a BackgroundTaskInfoProgress
 * @param status object to be checked if it is of type BackgroundTaskInfoProgress
 */
export function isBackgroundTaskInfoProgress(
  status: unknown,
): status is BackgroundTaskInfoProgress {
  if (
    !isBackgroundTaskInfo(status) ||
    status.type !== BackgroundTaskInfoType.progress ||
    status.progress === undefined
  ) {
    return false;
  }
  const toCheck: Partial<Progress> = status.progress;

  return (
    typeof toCheck.current === "number" && typeof toCheck.total === "number"
  );
}
