import { Easing, EasingFunction } from "@faro-lotv/foundation";
import {
  Animation3d,
  hasLoader,
  LotvAnimation,
  ObjectPropertyAnimation,
  Pano,
  PanoBlend,
  PanoColoredDollhouse,
  ParallelAnimation,
  safeDispose,
} from "@faro-lotv/lotv";
import { UPDATE_CONTROLS_PRIORITY } from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Camera, Group, Mesh } from "three";

type IProps = {
  /** Starting pano */
  from: Pano;
  /** Target pano */
  to: Pano;
  /** Dollhouse to color with the panos during animation */
  dollhouse?: Group | Mesh | null;
  /** Camera to move along the path between from to to pano */
  camera: Camera;
  /** Duration of the entire animation */
  duration?: number;
  /** Easing to use to update camera and dollhouse during animation */
  easing: EasingFunction;
  /** Callback to signal the animation is completed */
  onCompleted?(): void;
  /** Callback called once the animation has been created */
  afterMount?(): void;
};

/**
 * @returns the animation for dollhouse
 */
export function DollhouseAnimation({
  from,
  to,
  dollhouse,
  camera,
  duration = 1,
  easing = Easing.linear,
  onCompleted,
  afterMount,
}: IProps): JSX.Element {
  const { gl } = useThree();

  const [animation] = useState<Animation3d>(() => {
    const animations: LotvAnimation[] = [];

    from.updateWorldMatrix(true, false);
    to.updateWorldMatrix(true, false);

    const object = new Group();
    const panoBlend = new PanoBlend(from, to, camera, gl);
    panoBlend.material.depthTest = false;
    panoBlend.material.depthWrite = true;
    object.add(panoBlend);
    animations.push(
      new ObjectPropertyAnimation(panoBlend, "blendFactor", 0, 1, {
        ease: easing,
        duration,
      }),
    );

    if (dollhouse) {
      dollhouse.updateMatrixWorld();
      const panoDollhouse = new PanoColoredDollhouse(dollhouse, from, to, gl);
      object.add(panoDollhouse);
      animations.push(
        new ObjectPropertyAnimation(panoDollhouse, "blendFactor", 0, 1, {
          ease: easing,
          duration,
        }),
      );
    }

    const animation = new ParallelAnimation(animations);
    animation.completed.on(() => {
      if (onCompleted) {
        onCompleted();
      }
    });

    return {
      animation,
      object,
    };
  });

  // Create the animation object based on input props
  useEffect(() => {
    if (afterMount) {
      afterMount();
    }

    return () => {
      safeDispose(animation.object);
    };
  }, [animation.object, afterMount]);

  // Update the animation object at each frame
  useFrame((_, delta) => {
    if (hasLoader(from)) {
      from.loader.loadMore();
    }
    if (hasLoader(to)) {
      to.loader.loadMore();
    }

    animation.animation.startIfNeeded();
    animation.animation.update(delta);
    // Dollhouse animation just moved the camera, executing this with UPDATE_CONTROLS_PRIORITY
    // so that it is guaranteed that camera monitoring and lod visibility tasks come after this.
  }, UPDATE_CONTROLS_PRIORITY);

  // Until the animation is ready render from pano, then render the animation
  return <primitive object={animation.object} />;
}
