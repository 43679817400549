import LotvMath from "@faro-lotv/lotvmath";
import { Plane, Vector3 } from "three";

export const DEG_TO_RAD = Math.PI / 180;
export const RAD_TO_DEG = 180 / Math.PI;

/**
 * Convert a number representing an angle in radians to degrees.
 *
 * @param degrees The angle value in degree.
 * @returns The input angle converted to radians.
 */
export function degToRad(degrees: number): number {
	return degrees * DEG_TO_RAD;
}

/**
 * Convert a number representing an angle in degrees to radians.
 *
 * @param radians The angle value in radians.
 * @returns The input angle converted to degrees.
 */
export function radToDeg(radians: number): number {
	return radians * RAD_TO_DEG;
}

/**
 * Clamp a number inside a range
 *
 * @param num The number to clamp
 * @param min The start of the range (must be < max)
 * @param max The end of the range (must be > min)
 * @returns num clamped between min and max
 */
export function clamp(num: number, min: number, max: number): number {
	return Math.max(Math.min(num, max), min);
}

/**
 * Spherical angles.
 */
export type RotAngles = {
	/** camera yaw angle (rotation around vertical axis) in radians  */
	theta: number;
	/** camera pitch angle (rotation around X axis) in  radians */
	phi: number;
};

/**
 * Converts a vector of length one from cartesian coordinates to spherical angles.
 *
 * @param dir A normalized direction in cartesian coordinates
 * @param Yup True whether the coordinate system is Y-up, false if it is Z-up
 * @returns The spherical angles
 */
export function cartesian2spherical(dir: Vector3, Yup: boolean): RotAngles {
	const { x } = dir;
	let { y, z } = dir;

	if (Yup) {
		y = -dir.z;
		z = dir.y;
	}
	const phi = Math.asin(z);
	const cosPhi = Math.sqrt(x * x + y * y);
	if (cosPhi === 0) {
		return { theta: 0, phi };
	}
	const invCosPhi = 1 / cosPhi;
	const theta = Math.atan2(y * invCosPhi, x * invCosPhi);
	return { theta, phi };
}

/**
 * Converts a direction from spherical angles to a 3D normalized cartesian vector.
 *
 * @param angles The input spherical angles
 * @param Yup True whether the coordinate system is Y-up, false if it is Z-up
 * @param out Vector to return the result in (to avoid allocations)
 * @returns The result direction
 */
export function spherical2cartesian(angles: RotAngles, Yup: boolean, out = new Vector3()): Vector3 {
	const cosPhi = Math.cos(angles.phi);

	out.x = Math.cos(angles.theta) * cosPhi;
	if (Yup) {
		out.y = Math.sin(angles.phi);
		out.z = -Math.sin(angles.theta) * cosPhi;
	} else {
		out.y = Math.sin(angles.theta) * cosPhi;
		out.z = Math.sin(angles.phi);
	}
	return out;
}

/**
 * The LotvMath WASM module and the function to load it
 */
let lotvMath: Promise<typeof LotvMath> | undefined;

/**
 * Load the LotvMath WASM module
 *
 * @returns The LotvMath module
 */
export async function getLotvMath(): Promise<typeof LotvMath> {
	if (lotvMath === undefined) {
		lotvMath = LotvMath();
	}
	return await lotvMath;
}

/**
 * Compute a vector orthogonal to the input
 *
 * @param vec The input vector
 * @param out Vector to return the result in (to avoid allocations)
 * @returns A vector orthogonal to the input, not normalized
 */
export function computeOrthogonalVector(vec: Vector3, out = new Vector3()): Vector3 {
	if (Math.abs(vec.x) >= Math.abs(vec.y)) {
		out.set(vec.z, 0, -vec.x);
	} else {
		out.set(0, vec.z, -vec.y);
	}
	return out;
}

/**
 * Calculate the average unsigned distance from the points to the plane
 *
 * @param plane The plane
 * @param points The points
 * @returns The average unsigned distance
 */
export function averageUnsignedDistance(plane: Plane, points: Vector3[]): number {
	if (points.length === 0) return 0;

	const distanceSum = points.reduce(
		(accumulator, currentValue) => accumulator + Math.abs(plane.distanceToPoint(currentValue)),
		0,
	);

	return distanceSum / points.length;
}

/** Result of the plane fitting algorithm */
export type FitPlaneResult = {
	/** Point on the plane */
	point: Vector3;
	/** Normal vector of the plane */
	normal: Vector3;
};

/**
 * Find multiple planes in a set of data points using the RANSAC method.
 * The algorithm works by finding one plane at a time and remove the inliers from
 * the input data, then repeat the process untill the criteria are met.
 *
 * This is a wrapper to LotvMath `findPlanes` WASM method, and converts its result
 * types to threejs object types.
 *
 * @param positions The input data points, position x,y,z data in a flat array
 * @param inlierThreshold The threshold distance for a point to be considered an inlier
 * @param bestFitInliers If true, the method will return the best fit plane using only the inliers
 * @param numPlanes The number of planes to find
 * @param remainedOutliersRatio Remained outliers ratio threshold, the algorithm will stop try
 * 		to find more planes if the current remained outliers are below this threshold.
 * @returns The fit planes or undefined if the fit algorithm failed to find a result
 */
export async function findPlanes(
	positions: Float32Array | Float64Array,
	inlierThreshold: number,
	bestFitInliers: boolean,
	numPlanes: number,
	remainedOutliersRatio: number,
): Promise<FitPlaneResult[] | undefined> {
	const lotvMath = await getLotvMath();
	const result = lotvMath.findPlanes(positions, inlierThreshold, bestFitInliers, numPlanes, remainedOutliersRatio);
	if (!result) return;

	return result.map((plane) => ({
		normal: new Vector3(plane.normal.x, plane.normal.y, plane.normal.z),
		point: new Vector3(plane.point.x, plane.point.y, plane.point.z),
	}));
}
