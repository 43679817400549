import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { EventDispatcher } from "three";

/**
 * Register the controls in the R3F store with the custom ones and restore them
 * on unmount
 *
 * @param controls The controls that should be registered in the R3F store
 */
export function useOverrideControls(controls: EventDispatcher): void {
  const set = useThree((s) => s.set);
  const get = useThree((s) => s.get);
  useEffect(() => {
    const oldControls = get().controls;
    set({ controls });

    return () => {
      set({ controls: oldControls });
    };
  }, [controls, get, set]);
}
