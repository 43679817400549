import { IElement } from "@faro-lotv/ielement-types";

/**
 * @returns True if the iElement was obtained by copying data from a video mode path
 * @param iElement The element to check
 */
export function isIElementVideoModeCopy(iElement: IElement): boolean {
  return (
    !!iElement.labels &&
    iElement.labels.some(({ labelType }) => labelType === "videomode")
  );
}
