import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementGroup,
  IElementModel3D,
  IElementSection,
  IElementTimeSeries,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * This mutation adds a new bim model to a project. The final IElement structure is:
 *
 * ```
 * ProjectRoot
 * └ Group (BimModel)
 *   └ TimeSeries (BimModel)
 *     └ Section (BimModel)
 *       └ Group (BimImport)
 *         └ Model3d (BimModel)
 * ```
 */
export interface MutationAddImportBimModel extends BaseMutation {
  /** The type of the mutation to apply */
  type: MutationTypes.MutationImportBimModel;

  /**
   * A new Group for the CADs of a specific sheet.
   * Provide only if no group for CADs exists yet.
   */
  group?: CreateIElement<IElementGroup>;

  /**
   * A new Timeseries for the CADs of a specific sheet.
   * Provide only if no timeseries for CADs exists yet.
   * The timeseries may contain one or more sections, each section refers to one CAD model.
   */
  timeSeries?: CreateIElement<IElementTimeSeries>;

  /**
   * This section refers to a specific imported CAD model.
   * The section may contain iElement nodes for the raw imported file, intermediate conversion files
   * and output a file that can be rendered, all related to the same CAD model.
   */
  newElement: CreateIElement<IElementSection>;

  /**
   * A new parent/wrapper section for the CAD files.
   * This is a child of the section element 'newElement'
   * and contains the input files that define the CAD raw data (e.g. .rvt, .ifc, .dwg, etc.)
   */
  inputGroup: CreateIElement<IElementGroup>;

  /** New model3d element, child of 'inputGroup'. Contains the raw input CAD files to be imported. */
  inputFiles: Array<CreateIElement<IElementModel3D>>;
}

/**
 * All the data required to create a new IElementModel3d node to the project
 */
export type CreateMutationImportBimModelArguments = {
  /** Id of the root of the project */
  rootId: GUID;

  /** Id of the group that contains all CADs of the floor, if undefined a new Group will be created */
  groupId?: GUID;

  /** Id of an already existing TimeSeries to use for this Model3d, if undefined a new TimeSeries will be created */
  timeseriesId?: GUID;

  /** Type of the new Model3d node */
  type: IElementModel3D["type"];

  /** Name for the new Model3d node */
  name: string;

  /** Name of the file */
  fileName: string;

  /** File md5Hash encoded in base 16 (hexadecimal). */
  md5Hash: string;

  /** Size of the file, in bytes. */
  fileSize: number;

  /** Url to the new Model3d file */
  uri: string;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;

  /** if the current user is internal user (email domain is @faro.com, or @holobuilder.com) */
  isInternalUser: boolean;
};

/**
 * The mutation creates a structure like this:
 *
 * ```
 * ProjectRoot
 * └ Group (BimModel)
 *   └ TimeSeries (BimModel)
 *     └ Section (BimModel)
 *       └ Group (BimImport)
 *         └ Model3d (BimModel)
 * ```
 *
 * @returns a mutation to add a new BIM model to a project
 */
export function createMutationImportBimModel({
  rootId,
  groupId,
  timeseriesId,
  type,
  name,
  md5Hash,
  fileSize,
  fileName,
  uri,
  createdAt = new Date().toISOString(),
  isInternalUser,
}: CreateMutationImportBimModelArguments): MutationAddImportBimModel {
  const elementId = timeseriesId ?? groupId ?? rootId;
  const cadObjectsGroupId = groupId ?? generateGUID();
  const targetTimeseriesId = timeseriesId ?? generateGUID();

  const sectionId = generateGUID();
  const inputGroupId = generateGUID();
  const model3dId = generateGUID();

  // Create a new group if it's missing
  const group: CreateIElement<IElementGroup> | undefined = groupId
    ? undefined
    : {
        type: IElementType.group,
        typeHint: IElementTypeHint.bimModel,
        id: cadObjectsGroupId,
        rootId,
        parentId: rootId,
        childrenIds: [targetTimeseriesId],
        name: "CAD Objects",
        createdAt,
        xOr: false,
      };

  // Create a new timeseries if it's missing
  const timeSeries: CreateIElement<IElementTimeSeries> | undefined =
    timeseriesId
      ? undefined
      : {
          type: IElementType.timeSeries,
          typeHint: IElementTypeHint.bimModel,
          id: targetTimeseriesId,
          rootId,
          parentId: cadObjectsGroupId,
          childrenIds: [sectionId],
          name: "CAD Model",
          createdAt,
        };

  return {
    ...createBaseMutation(MutationTypes.MutationImportBimModel, elementId),
    group,
    timeSeries,
    newElement: {
      id: sectionId,
      name,
      type: IElementType.section,
      typeHint: IElementTypeHint.bimModel,
      rootId,
      parentId: targetTimeseriesId,
      childrenIds: [inputGroupId],
      createdAt,
      pose: {
        isWorldPose: true,
        isWorldRot: true,
        isWorldScale: true,
        scale: { x: 1, y: 1, z: 1 },
        rot: { x: 0, y: 0, z: 0, w: 1 },
        pos: { x: 0, y: 0, z: 0 },
        gps: null,
      },
    },
    inputGroup: {
      id: inputGroupId,
      name,
      type: IElementType.group,
      typeHint: IElementTypeHint.bimImport,
      createdAt,
      parentId: sectionId,
      rootId,
      childrenIds: [model3dId],
      xOr: false,
    },
    inputFiles: [
      {
        id: model3dId,
        name,
        type,
        typeHint: IElementTypeHint.bimModel,
        uri,
        md5Hash,
        fileSize,
        fileName,
        rootId,
        parentId: inputGroupId,
        childrenIds: null,
        createdAt,
        metaDataMap: { createdByInternalUser: isInternalUser },
      },
    ],
  };
}
