varying vec2 vUv;

uniform sampler2D uColorTexture;
uniform sampler2D uDepthTexture;

void main() {
	vec2 sz = vec2(textureSize(uDepthTexture, 0));
	gl_FragDepth = texelFetch(uDepthTexture, ivec2(sz * vUv), 0).r;
	gl_FragColor = texture(uColorTexture, vUv);
}
