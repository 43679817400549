import { ReactComponent as Data3dSvg } from "../../icons/3d-data.svg";
import { ReactComponent as SphereXGTextSvg } from "../../icons/Sphere XG Logo - Color - RGB.svg";
import { ReactComponent as AlignIconSvg } from "../../icons/align-iterative.svg";
import { ReactComponent as AlignToModelSvgIcon } from "../../icons/align-to-model.svg";
import { ReactComponent as AlignToPointCloudIconSvg } from "../../icons/align-to-point-cloud.svg";
import { ReactComponent as AlignToSheetIconSvg } from "../../icons/align-to-sheet.svg";
import { ReactComponent as AnalysisMarkerSVG } from "../../icons/analysis-marker.svg";
import { ReactComponent as AnchorIconSvg } from "../../icons/anchor.svg";
import { ReactComponent as AnnotationIconSvg } from "../../icons/annotation.svg";
import {
  ReactComponent as ArrowDown2Svg,
  ReactComponent as ArrowDownSvg,
} from "../../icons/arrow-down.svg";
import { ReactComponent as ArrowRightSvg } from "../../icons/arrow-right.svg";
import { ReactComponent as AttachmentSvg } from "../../icons/attachment.svg";
import { ReactComponent as AutoClipCurrentViewSvg } from "../../icons/auto-clip-current-view.svg";
import { ReactComponent as AutodeskSvg } from "../../icons/autodesk-logo.svg";
import { ReactComponent as BlendData } from "../../icons/blend-data.svg";
import { ReactComponent as BuildingElevationSvg } from "../../icons/building-elevation.svg";
import { ReactComponent as CadSmallSvg } from "../../icons/cad-small.svg";
import { ReactComponent as CalendarSvg } from "../../icons/calendar.svg";
import { ReactComponent as CameraVideoTrajectorySmallSvg } from "../../icons/camera-video-trajectory-small.svg";
import { ReactComponent as CheckMark2Svg } from "../../icons/checkmark-2.svg";
import { ReactComponent as CheckMark3Svg } from "../../icons/checkmark-3.svg";
import { ReactComponent as CheckMark4Svg } from "../../icons/checkmark-4.svg";
import { ReactComponent as CheckMarkCircleFill } from "../../icons/checkmark-circle-fill.svg";
import { ReactComponent as CheckmarkIconSvg } from "../../icons/checkmark.svg";
import { ReactComponent as ClipboxExportSvg } from "../../icons/clipbox-export.svg";
import { ReactComponent as ClippingBoxIconSvg } from "../../icons/clipping-box.svg";
import { ReactComponent as ClockSmallSvg } from "../../icons/clock-small.svg";
import { ReactComponent as CloseCancelErrorSvg } from "../../icons/close-cancel-error.svg";
import { ReactComponent as CloseIconSvg } from "../../icons/close.svg";
import { ReactComponent as CloudProcessingGraphicIconSvg } from "../../icons/cloud-processing-graphic.svg";
import { ReactComponent as CloudSvg } from "../../icons/cloud.svg";
import { ReactComponent as CompassGoToSvg } from "../../icons/compass-go-to.svg";
import { ReactComponent as CoordinateGlobalSvg } from "../../icons/coordinates-global.svg";
import { ReactComponent as CoordinatesIconSvg } from "../../icons/coordinates.svg";
import { ReactComponent as CopyFileSvg } from "../../icons/copy-file.svg";
import { ReactComponent as CropIconSvg } from "../../icons/crop.svg";
import { ReactComponent as DeleteIconSvg } from "../../icons/delete.svg";
import { ReactComponent as DeviationLabel } from "../../icons/deviation-label.svg";
import { ReactComponent as DisabledSvg } from "../../icons/disabled.svg";
import { ReactComponent as HeatmapIconSvg } from "../../icons/distance-coloring.svg";
import { ReactComponent as DollhouseIconSvg } from "../../icons/dollhouse.svg";
import { ReactComponent as DollhouseWarningIconSvg } from "../../icons/dollhouse_warning.svg";
import { ReactComponent as ThreeDotsSvg } from "../../icons/dots_vertical.svg";
import { ReactComponent as DownloadIconSvg } from "../../icons/download.svg";
import { ReactComponent as EditSvg } from "../../icons/edit.svg";
import { ReactComponent as EmailSentSvg } from "../../icons/email_sent.svg";
import { ReactComponent as ErrorIconSvg } from "../../icons/error.svg";
import { ReactComponent as ErrorPageSvg } from "../../icons/error_page.svg";
import { ReactComponent as ExchangeArrowsIconSvg } from "../../icons/exchange-arrows.svg";
import { ReactComponent as ExclamationMarkCircleFill } from "../../icons/exclamation-mark-circle-fill.svg";
import { ReactComponent as ExclamationMark } from "../../icons/exclamationmark.svg";
import { ReactComponent as ExitFullScreenIconSvg } from "../../icons/exit-full-screen.svg";
import { ReactComponent as ExpandSvg } from "../../icons/expand.svg";
import { ReactComponent as ExportPointCloudIconSvg } from "../../icons/export-point-cloud.svg";
import { ReactComponent as ExportIconSvg } from "../../icons/export.svg";
import { ReactComponent as ExtractCrossSectionSvg } from "../../icons/extract-cross-section.svg";
import { ReactComponent as FaroLogoCompactIconSvg } from "../../icons/faro-logo-compact.svg";
import { ReactComponent as FaroLogoIconSvg } from "../../icons/faro-logo.svg";
import { ReactComponent as FeedbackIconSvg } from "../../icons/feedback.svg";
import { ReactComponent as FileDocumentSvg } from "../../icons/file-document.svg";
import { ReactComponent as FileImageSvg } from "../../icons/file-image.svg";
import { ReactComponent as FilterSvg } from "../../icons/filter.svg";
import { ReactComponent as FindCheckmarkSvg } from "../../icons/find-checkmark.svg";
import { ReactComponent as FolderSmallSvg } from "../../icons/folder-small.svg";
import { ReactComponent as FullScreenIconSvg } from "../../icons/full-screen.svg";
import { ReactComponent as CloudConnectionIconSvg } from "../../icons/generic-connection-cloud.svg";
import { ReactComponent as GeoReferenceSvg } from "../../icons/geo-reference.svg";
import { ReactComponent as GridSvg } from "../../icons/grid.svg";
import { ReactComponent as HamburgerMenu } from "../../icons/hamburger-menu.svg";
import { ReactComponent as HBLogoBlackText } from "../../icons/hblogo_blacktext.svg";
import { ReactComponent as HelpCenterSVG } from "../../icons/help-center.svg";
import { ReactComponent as HelpIconSvg } from "../../icons/help.svg";
import { ReactComponent as HistoryRedoSvg } from "../../icons/history-redo.svg";
import { ReactComponent as HistoryUndoSvg } from "../../icons/history-undo.svg";
import { ReactComponent as HomeIconSvg } from "../../icons/home.svg";
import { ReactComponent as Image360SmallSvg } from "../../icons/image-360-small.svg";
import { ReactComponent as InfoCircleFill } from "../../icons/info-circle-fill.svg";
import { ReactComponent as InfoIconSvg } from "../../icons/info.svg";
import { ReactComponent as InProgressIconSvg } from "../../icons/inprogress.svg";
import { ReactComponent as IntensityPanoIconSvg } from "../../icons/intensity-pano.svg";
import { ReactComponent as LayersIconSvg } from "../../icons/layers.svg";
import { ReactComponent as LayoutSheetSmallSvg } from "../../icons/layout-sheet-small.svg";
import { ReactComponent as LinkHorizontalIconSvg } from "../../icons/linkicon-horizontal.svg";
import { ReactComponent as LocationMapSvg } from "../../icons/location-map.svg";
import { ReactComponent as LockRotationIconSvg } from "../../icons/lock-rotation.svg";
import { ReactComponent as MapIconLargeSvg } from "../../icons/map-large.svg";
import { ReactComponent as MapPlaceholderDefaultSvg } from "../../icons/map-placeholder-default.svg";
import { ReactComponent as MapPlaceholderHoveredSvg } from "../../icons/map-placeholder-hover.svg";
import { ReactComponent as MapPlaceholderSelectedHoveredSvg } from "../../icons/map-placeholder-selected-hover.svg";
import { ReactComponent as MapPlaceholderSelectedSvg } from "../../icons/map-placeholder-selected.svg";
import { ReactComponent as MarkupPolygonSvg } from "../../icons/markup-polygon.svg";
import { ReactComponent as MeasureShowAxesSvg } from "../../icons/measure-show-axes.svg";
import { ReactComponent as MeasurementSvg } from "../../icons/measurement-icon.svg";
import { ReactComponent as MeasurementMarkerSvg } from "../../icons/measurement-marker.svg";
import { ReactComponent as ModelDollhouseSvg } from "../../icons/model-dollhouse.svg";
import { ReactComponent as MoveElementIconSvg } from "../../icons/move-element.svg";
import { ReactComponent as NonVisibleSvg } from "../../icons/non-visible.svg";
import { ReactComponent as OpenLinkIconSvg } from "../../icons/open-link.svg";
import { ReactComponent as PointCloudZAxis } from "../../icons/pc-z-axis.svg";
import { ReactComponent as PhoneSvg } from "../../icons/phone.svg";
import { ReactComponent as PlusCircleSvg } from "../../icons/plus-circle.svg";
import { ReactComponent as PlusSvg } from "../../icons/plus.svg";
import { ReactComponent as PointCloudAnalysisColormapSVG } from "../../icons/point-cloud-analysis-colormap-range.svg";
import { ReactComponent as PointCloudAnalysisReferencePlaneSVG } from "../../icons/point-cloud-analysis-reference-plane.svg";
import { ReactComponent as PointCloudAnalysisSVG } from "../../icons/point-cloud-analysis.svg";
import { ReactComponent as PointCloudFlashSmall } from "../../icons/point-cloud-flash-small.svg";
import { ReactComponent as PointCloudImageSmallSvg } from "../../icons/point-cloud-image-small.svg";
import { ReactComponent as PointCloudSmallSvg } from "../../icons/point-cloud-small.svg";
import { ReactComponent as PointCloudTrajectorySmallSvg } from "../../icons/point-cloud-trajectory-small.svg";
import { ReactComponent as PointCloudWarningSmallSvg } from "../../icons/point-cloud-warning-small.svg";
import { ReactComponent as PointCloudSvg } from "../../icons/point-cloud.svg";
import { ReactComponent as ProcoreSvg } from "../../icons/procore-logo.svg";
import { ReactComponent as QuestionMarkInCircleSVG } from "../../icons/question-mark-in-circle.svg";
import { ReactComponent as RecenterViewIconSvg } from "../../icons/recenter-view.svg";
import { ReactComponent as RegistrationInteractiveSvg } from "../../icons/registration-interactive.svg";
import { ReactComponent as ResetScaleIconSvg } from "../../icons/reset-scale.svg";
import { ReactComponent as ResetIconSvg } from "../../icons/reset.svg";
import { ReactComponent as RevitIconSvg } from "../../icons/revit.svg";
import { ReactComponent as RotateClockwiseIconSvg } from "../../icons/rotate-cw.svg";
import { ReactComponent as RulerIconSvg } from "../../icons/ruler.svg";
import { ReactComponent as RunningIconSvg } from "../../icons/running.svg";
import { ReactComponent as ScanClusterIconSvg } from "../../icons/scan-cluster.svg";
import { ReactComponent as ScanIconSvg } from "../../icons/scan.svg";
import { ReactComponent as SchoolIconSvg } from "../../icons/school.svg";
import { ReactComponent as SearchIconSvg } from "../../icons/search.svg";
import { ReactComponent as SettingsSvg } from "../../icons/settings.svg";
import { ReactComponent as ShareLinkSvg } from "../../icons/share_link.svg";
import { ReactComponent as SheetNewSvg } from "../../icons/sheet-new.svg";
import { ReactComponent as SheetSaturationToggleIconSvg } from "../../icons/sheet-saturation-toggle.svg";
import { ReactComponent as ShieldWarningIconSvg } from "../../icons/shield-warning.svg";
import { ReactComponent as SliderHandlerSvg } from "../../icons/slider-handler.svg";
import { ReactComponent as SortDownIconSvg } from "../../icons/sort-down.svg";
import { ReactComponent as SortUpIconSvg } from "../../icons/sort-up.svg";
import { ReactComponent as SortIconSvg } from "../../icons/sort.svg";
import { ReactComponent as SphereXgSvg } from "../../icons/sphere-xg-logo.svg";
import { ReactComponent as SplitscreenStartIconSvg } from "../../icons/splitscreen-start.svg";
import { ReactComponent as OpacitySettingsIconSvg } from "../../icons/swatch-transparency.svg";
import { ReactComponent as SyncCamerasOnWaypointIconSvg } from "../../icons/sync-cameras-on-waypoint.svg";
import { ReactComponent as TagsIconSvg } from "../../icons/tags.svg";
import { ReactComponent as ToolIconSvg } from "../../icons/tool.svg";
import { ReactComponent as TrajectoryAlignIconSvg } from "../../icons/trajectory-align.svg";
import { ReactComponent as TrajectoryCorrectionIconSvg } from "../../icons/trajectory-correction.svg";
import { ReactComponent as Update360TransformationIconSvg } from "../../icons/update-360-transformation.svg";
import { ReactComponent as UploadIconSvg } from "../../icons/upload.svg";
import { ReactComponent as UserLocationIconSvg } from "../../icons/user-location.svg";
import { ReactComponent as VideoCameraIconSvg } from "../../icons/video-camera.svg";
import { ReactComponent as VideoSVG } from "../../icons/video.svg";
import { ReactComponent as ViewOptionsSvg } from "../../icons/view-options.svg";
import { ReactComponent as VisibleSvg } from "../../icons/visible.svg";
import { ReactComponent as WarningIconSvg } from "../../icons/warning.svg";
import { ReactComponent as XMarkCircleFill } from "../../icons/xmark-circle-fill.svg";
import { ReactComponent as ZoomAllIconSvg } from "../../icons/zoom-all.svg";
import { FaroSvgIcon, FaroSvgIconProps } from "./svg-icon";

/** Props type for a single icon, without the source as that's part on the icon component */
type SvgIconProps = Omit<FaroSvgIconProps, "source">;

/**
 * @param props to forward to the underlying icon
 * @returns an icon to close, cancel operations (X)
 */
export function CloseCancelErrorIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CloseCancelErrorSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns arrow-down icon
 */
export function ArrowDownIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ArrowDownSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns crop icon
 */
export function CropIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CropIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Rotate clockwise icon
 */
export function RotateClockwiseIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={RotateClockwiseIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns icon for the markup polygon in the 3d scene
 */
export function MarkupPolygonIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MarkupPolygonSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Second version of arrow-down icon
 */
export function ArrowDown2Icon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ArrowDown2Svg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns clock icon
 */
export function ClockSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ClockSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon to close, cancel operations (X)
 */
export function WorldCoordinateIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CoordinateGlobalSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns dollhouse icon
 */
export function DollhouseIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={DollhouseIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns dollhouse warning icon
 */
export function DollhouseWarningIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={DollhouseWarningIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Download Point Cloud Icon
 */
export function DownloadIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={DownloadIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns faro-logo icon
 */
export function FaroLogoIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FaroLogoIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns faro-logo icon compact version
 */
export function FaroLogoCompactIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FaroLogoCompactIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns revit icon
 */
export function RevitIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={RevitIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns exchange arrows icon
 */
export function ExchangeArrowsIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExchangeArrowsIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns home icon
 */
export function HomeIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HomeIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns help icon
 */
export function HelpIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HelpIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns map icon
 */
export function LayoutSheetSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={LayoutSheetSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns larger version of the map icon
 */
export function MapLargeIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MapIconLargeSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns cad model icon
 */
export function CadSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CadSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns show/visible icon (opposite of NonVisibleIcon)
 */
export function VisibleIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={VisibleSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns no-show/hide icon (opposite of VisibleIcon)
 */
export function NonVisibleIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={NonVisibleSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns cad model icon
 */
export function ModelDollhouseIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ModelDollhouseSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns panorama icon
 */
export function Img360SmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={Image360SmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns close icon
 */
export function CloseIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CloseIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns splitscreen-start icon
 */
export function SplitscreenStartIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SplitscreenStartIconSvg} {...props} />;
}

/**
 *
 * @param props to forward to the underlying icon
 * @returns splitscreen sync camera on waypoint icon
 */
export function SyncCamerasOnWaypointIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SyncCamerasOnWaypointIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Horizontal link icon
 */
export function LinkHorizontalIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={LinkHorizontalIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Enter Full Screen icon
 */
export function FullScreenIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FullScreenIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Exit Full Screen link icon
 */
export function ExitFullScreenIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExitFullScreenIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the user location on a minimap
 */
export function UserLocationIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={UserLocationIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Running Icon
 */
export function RunningIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={RunningIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns School Icon
 */
export function SchoolIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SchoolIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns PointCloud Icon
 */
export function PointCloudIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Placeholder for a location on a map
 */
export function MapPlaceholderDefaultIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MapPlaceholderDefaultSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Placeholder for a selected over location on a map
 */
export function MapPlaceholderSelectedIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MapPlaceholderSelectedSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Placeholder for a hovered over location on a map
 */
export function MapPlaceholderHoveredIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MapPlaceholderHoveredSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Placeholder for a selected and hovered over location on a map
 */
export function MapPlaceholderSelectedHoveredIcon(
  props: SvgIconProps,
): JSX.Element {
  return <FaroSvgIcon source={MapPlaceholderSelectedHoveredSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Shield Warning Icon
 */
export function ShieldWarningIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ShieldWarningIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Phone Icon
 */
export function PhoneIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PhoneSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Sheet New Icon
 */
export function SheetNewIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SheetNewSvg} {...props} />;
}

/**
 * @returns Icon for feedback
 * @param props to forward to the underlying icon
 */
export function FeedbackIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FeedbackIconSvg} {...props} />;
}

/**
 * @returns Icon for a cloud
 * @param props to forward to the underlying icon
 */
export function CloudIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CloudSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for Disabled elements
 */
export function DisabledIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={DisabledSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Shield Warning Icon
 */
export function WarningIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={WarningIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Spyglass Search Icon
 */
export function SearchIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SearchIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Lock Icon
 */
export function LockRotationIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={LockRotationIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Update360Transformation Icon
 */
export function Update360TransformationIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={Update360TransformationIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Upload Icon
 */
export function UploadIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={UploadIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Delete Icon
 */
export function DeleteIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={DeleteIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Export PointCloud Icon
 */
export function ExportPointCloudIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExportPointCloudIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Annotation Icon
 */
export function AnnotationIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AnnotationIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Ruler Icon
 */
export function RulerIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={RulerIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Clipping Box Icon
 */
export function ClippingBoxIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ClippingBoxIconSvg} {...props} />;
}

/**
 *
 * @param props to forward to the underlying icon
 * @returns Clipping Box Export Icon
 */
export function ClipboxExportIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ClipboxExportSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Align Icon
 */
export function AlignIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AlignIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Align PointCloud Icon
 */
export function AlignToPointCloudIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AlignToPointCloudIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Align to CAD Icon
 */
export function AlignToModelIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AlignToModelSvgIcon} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Align to Sheet Icon
 */
export function AlignToSheetIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AlignToSheetIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns coordinates system Icon
 */
export function CoordinatesIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CoordinatesIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Error Icon
 */
export function ErrorIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ErrorIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Info Icon
 */
export function InfoIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={InfoIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Checkmark Icon
 */
export function CheckmarkIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CheckmarkIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Second version of the checkmark Icon
 */
export function Checkmark2Icon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CheckMark2Svg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Third version of the checkmark Icon
 */
export function Checkmark3Icon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CheckMark3Svg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Fourth version of the checkmark Icon
 */
export function Checkmark4Icon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CheckMark4Svg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Generic export icon
 */
export function ExportIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExportIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Reset icon
 */
export function ResetIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ResetIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Three dots Icon
 */
export function ThreeDotsIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ThreeDotsSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Measurement marker icon
 */
export function MeasurementMarkerIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MeasurementMarkerSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Copy file Icon
 */
export function CopyFileIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CopyFileSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns HB Logo with black text Icon
 */
export function HBLogoBlackTextIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HBLogoBlackText} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Sphere XG Logo with text Icon
 */
export function SphereXGTextIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SphereXGTextSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns ExclamationMark Icon
 */
export function ExclamationMarkIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExclamationMark} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns  GeoReference Icon
 */
export function GeoReferenceIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={GeoReferenceSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Open Link Icon
 */
export function OpenLinkIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={OpenLinkIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Reset scale Icon
 */
export function ResetScaleIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ResetScaleIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Recenter Icon
 */
export function RecenterViewIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={RecenterViewIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Video camera Icon
 */
export function VideoCameraIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={VideoCameraIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Sheet color mode Icon
 */
export function SheetSaturationToggleIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SheetSaturationToggleIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Opacity settings button icon
 */
export function OpacitySettingsIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={OpacitySettingsIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Heatmap button icon
 */
export function HeatmapIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HeatmapIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Circled Checkmark Icon
 */
export function CheckmarkCircleFillIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CheckMarkCircleFill} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Measure distance with horizontal and vertical components Checkmark Icon
 */
export function MeasureShowAxesIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MeasureShowAxesSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns 3D mark Icon
 */
export function Data3dIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={Data3dSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Circled Exclamation Mark Icon
 */
export function ExclamationMarkCircleFillIcon(
  props: SvgIconProps,
): JSX.Element {
  return <FaroSvgIcon source={ExclamationMarkCircleFill} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Circled Info Icon
 */
export function InfoCircleFillIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={InfoCircleFill} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Circled X Icon
 */
export function XMarkCircleFillIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={XMarkCircleFill} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns In progress icon
 */
export function InProgressIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={InProgressIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Tool Icon
 */
export function ToolIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ToolIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Path Alignment Tool Icon
 */
export function TrajectoryAlignIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={TrajectoryAlignIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Path Adjustment Tool Icon
 */
export function TrajectoryCorrectionIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={TrajectoryCorrectionIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the slider handler
 */
export function SliderHandlerIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SliderHandlerSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the history undo
 */
export function HistoryUndoIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HistoryUndoSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the history redo
 */
export function HistoryRedoIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HistoryRedoSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the folder
 */
export function FolderSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FolderSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for question mark inside a circle
 */
export function QuestionMarkInCircleIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={QuestionMarkInCircleSVG} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for editing actions
 */
export function EditIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={EditSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for video trajectory sessions
 */
export function CameraVideoTrajectorySmallIcon(
  props: SvgIconProps,
): JSX.Element {
  return <FaroSvgIcon source={CameraVideoTrajectorySmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for a pointcloud session with 360 images
 */
export function PointCloudImageSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudImageSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for a small pointcloud
 */
export function PointCloudSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for a pointcloud session with a trajectory
 */
export function PointCloudTrajectorySmallIcon(
  props: SvgIconProps,
): JSX.Element {
  return <FaroSvgIcon source={PointCloudTrajectorySmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for flash point clouds in an Orbis dataset
 */
export function PointCloudFlashSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudFlashSmall} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for a small pointcloud with a warning
 */
export function PointCloudWarningSmallIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudWarningSmallSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon representing a point cloud with its z-axis
 */
export function PointCloudZAxisIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudZAxis} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for sharing a link
 */
export function ShareLinkIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ShareLinkSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the interactive registration (alignment) of multiple point clouds
 */
export function RegistrationInteractiveIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={RegistrationInteractiveSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for sharing a link
 */
export function GridIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={GridSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for overlay different data
 */
export function OverlayDataIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={BlendData} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for responsive menu different data
 */
export function HamburgerMenuIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HamburgerMenu} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for the calendar
 */
export function CalendarIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CalendarSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for expanding something
 */
export function ExpandIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExpandSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for attachments
 */
export function AttachmentIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AttachmentSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for measurement annotations
 */
export function MeasurementIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MeasurementSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon for arrow right
 */
export function ArrowRightIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ArrowRightSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns extract-cross-section icon
 */
export function ExtractCrossSectionIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ExtractCrossSectionSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon with a circle and a plus symbol
 */
export function PlusCircleIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PlusCircleSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Icon with a plus symbol
 */
export function PlusIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PlusSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns a file icon for documents
 */
export function FileDocumentIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FileDocumentSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns a file icon for images
 */
export function FileImageIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FileImageSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon indicating an auto clipping of the current view
 */
export function AutoClipCurrentViewIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AutoClipCurrentViewSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting a checkmark in a magnifier
 */
export function FindCheckmarkIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FindCheckmarkSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting a filtering funnel
 */
export function FilterIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={FilterSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting a cloud processing
 */
export function CloudProcessingGraphicIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CloudProcessingGraphicIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting an intensity panorama image
 */
export function IntensityPanoIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={IntensityPanoIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting a cloud connection
 */
export function CloudConnectionIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CloudConnectionIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting a list of layers
 */
export function LayersIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={LayersIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for application settings
 */
export function SettingsIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SettingsSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for application view options
 */
export function ViewOptionsIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ViewOptionsSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting a set of tags
 */
export function TagsIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={TagsIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an anchor icon
 */
export function AnchorIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AnchorIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for representing the elevation of elements
 */
export function BuildingElevationIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={BuildingElevationSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon depicting the action of moving an element in a tree
 */
export function MoveElementIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={MoveElementIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for representing the videos
 */
export function VideoIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={VideoSVG} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for representing the help center
 */
export function HelpCenterIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={HelpCenterSVG} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for a single scan
 */
export function ScanIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ScanIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for a cluster of scans
 */
export function ScanClusterIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ScanClusterIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns PointCloud analysis button icon
 */
export function PointCloudAnalysisIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={PointCloudAnalysisSVG} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns AnalysisMarker icon
 */
export function AnalysisMarkerIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AnalysisMarkerSVG} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns PointCloud analysis colormap option button icon
 */
export function PointCloudAnalysisColormapIcon(
  props: SvgIconProps,
): JSX.Element {
  return <FaroSvgIcon source={PointCloudAnalysisColormapSVG} {...props} />;
}
/**
 * @param props to forward to the underlying icon
 * @returns PointCloud analysis reference plane setting button icon
 */
export function PointCloudAnalysisReferencePlaneIcon(
  props: SvgIconProps,
): JSX.Element {
  return (
    <FaroSvgIcon source={PointCloudAnalysisReferencePlaneSVG} {...props} />
  );
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for representing the bi-directional sorting
 */
export function SortIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SortIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for representing the ascending sorting
 */
export function SortUpIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SortUpIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon for representing the descending sorting
 */
export function SortDownIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SortDownIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon indicating a zoom on everything
 */
export function ZoomAllIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ZoomAllIconSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns an icon indicating a go to action
 */
export function CompassGoToIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={CompassGoToSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns The Sphere XG logo
 */
export function SphereXGIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={SphereXgSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns The Autodesk logo
 */
export function AutodeskIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={AutodeskSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns The Procore logo
 */
export function ProcoreIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={ProcoreSvg} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Add label Icon
 */
export function AddLabelIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={DeviationLabel} {...props} />;
}

/**
 * @param props to forward to the underlying icon
 * @returns Location Map Icon
 */
export function LocationMapIcon(props: SvgIconProps): JSX.Element {
  return <FaroSvgIcon source={LocationMapSvg} {...props} />;
}

export { EmailSentSvg, ErrorPageSvg };
