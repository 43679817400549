import {
	AddEquation,
	Camera,
	Color,
	CustomBlending,
	DepthFormat,
	DepthTexture,
	FloatType,
	Group,
	Light,
	NearestFilter,
	Object3D,
	OneFactor,
	Points,
	PointsMaterial,
	RGBAFormat,
	Scene,
	SrcAlphaFactor,
	Vector2,
	WebGLRenderer,
	WebGLRenderTarget,
} from "three";
import { TomographicComposeAdaptiveMaterial } from "../Materials/TomographicComposeAdaptiveMaterial";
import { CadModel } from "../Mesh/CadModel";
import { hasMaterial } from "../Utils";
import { FullScreenPass } from "./FullScreenPass";

// default filter to check which objects to render in the tomographic view
const defaultFilter = (o: Object3D): boolean =>
	o instanceof Points || o instanceof CadModel || (hasMaterial(o) && o.material.transparent === true);

/**
 * A pass suited to render a model from a nadiral orthographic view, in order to
 * highlight its edges. The rendered model can be a point cloud, a CAD model, a mesh, etc.
 *
 * This pass performs two sub-passes: at first the model is render with additive blending
 * on an offscreen fbo. Then, a second pass detects the walls and the important edges and
 * composes them with the opaque scene FBO.
 *
 * To ensure that the default values work for the point cloud use case, the point clouds
 * rendered should use the material created by the function 'createTomographicMaterialPoints'
 * of this class.
 */
export class TomographicModelPass extends FullScreenPass<TomographicComposeAdaptiveMaterial> {
	#blendAccumulationFbo: WebGLRenderTarget;
	#resolution = new Vector2(4, 4);

	/**
	 *
	 * @param scene The scene to render
	 * @param camera The camera used to render the scene
	 * @param filter the filter to select what object to process (default is no filtering)
	 */
	constructor(
		public scene: Scene,
		public camera: Camera,
		public filter: (object: Object3D) => boolean = defaultFilter,
	) {
		super(new TomographicComposeAdaptiveMaterial());
		this.fsQuad.material = this.material;
		this.#blendAccumulationFbo = this.#createBlendAccumulationFbo();
	}

	/**
	 * @returns a new FBO allocated in GPU with two color texture attachments
	 */
	#createBlendAccumulationFbo(): WebGLRenderTarget {
		const width = this.#resolution.x;
		const height = this.#resolution.y;
		const fbo = new WebGLRenderTarget(width, height);

		fbo.texture.minFilter = NearestFilter;
		fbo.texture.magFilter = NearestFilter;
		fbo.texture.format = RGBAFormat;
		fbo.texture.type = FloatType;

		fbo.stencilBuffer = false;
		fbo.depthTexture = new DepthTexture(width, width);
		fbo.depthTexture.minFilter = fbo.depthTexture.magFilter = NearestFilter;
		fbo.depthTexture.format = DepthFormat;
		fbo.depthTexture.type = FloatType;
		fbo.depthBuffer = true;
		return fbo;
	}

	/**
	 * Resizing blend accumulation FBOs if needed.
	 */
	#resizeFboOnNeed(): void {
		if (
			this.#blendAccumulationFbo.width !== this.#resolution.x ||
			this.#blendAccumulationFbo.height !== this.#resolution.y
		) {
			this.#blendAccumulationFbo.texture.dispose();
			this.#blendAccumulationFbo.dispose();
			this.#blendAccumulationFbo = this.#createBlendAccumulationFbo();
		}
	}

	/**
	 * Renders the transparent scene onto the intermediate accumulation FBO
	 *
	 * @param renderer The current renderer
	 */
	#renderTransparentObjects(renderer: WebGLRenderer): void {
		renderer.setRenderTarget(this.#blendAccumulationFbo);
		renderer.clear(true, true, false);
		renderer.render(this.scene, this.camera);
	}

	/**
	 * Composes the tomographic cloud FBO with the opaque scene/background FBO, computing a convenient
	 * weight to blend background and cloud so that the cloud features are exposed.
	 *
	 * @param renderer The current renderer
	 * @param writeBuffer The output FBO
	 * @param readBuffer The input FBO with the opaque scene.
	 */
	#composeAdaptive(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		renderer.autoClear = true;
		const cmat = this.material;
		cmat.uniforms.inputColorTex.value = this.#blendAccumulationFbo.texture;
		cmat.uniforms.opaqueDepthTex.value = readBuffer.depthTexture;
		cmat.uniforms.opaqueColorTex.value = readBuffer.texture;
		cmat.uniformsNeedUpdate = true;
		this.fsQuad.material = cmat;
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.fsQuad.render(renderer);
		renderer.autoClear = false;
	}

	/**
	 * @param renderer renderer used to render the effect
	 * @param writeBuffer Buffer to write by renderer
	 * @param readBuffer Buffer to read the textures by renderer
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// Hide all filtered objects and store their original visibility flag
		const visMap = new Map<Object3D, boolean>();
		this.scene.traverse((o) => {
			if (o === this.scene || o instanceof Group || o instanceof Light) return;
			visMap.set(o, o.visible);
			o.visible = o.visible && this.filter(o);
		});

		renderer.getSize(this.#resolution);
		this.#resizeFboOnNeed();

		const oldAutoClear = renderer.autoClear;
		// We render transparent objects on top of opaque ones.
		renderer.autoClear = false;
		// We need to set the scene's background to null, because this is the only way to convince 3js
		// to clear our accumulation buffers with rgba = 0, 0, 0, 0.
		const oldbg = this.scene.background;
		this.scene.background = null;

		// First, render the scene's transparent elements to the blend accumulation fbos
		this.#renderTransparentObjects(renderer);

		// Then, compose the final result with the weighted average of the blended pixels.
		this.#composeAdaptive(renderer, writeBuffer, readBuffer);

		renderer.autoClear = oldAutoClear;
		this.scene.background = oldbg;
		// Restore original visibility flags
		for (const [object, visibility] of visMap.entries()) {
			object.visible = visibility;
		}
	}

	/** @returns the opacity that the tomographic rendering has when the minimum point count is met on a pixel */
	get opacity(): number {
		return this.material.uniforms.opacity.value;
	}

	/** Sets the opacity that the tomographic rendering has when the minimum point count is met on a pixel */
	set opacity(o: number) {
		this.material.uniforms.opacity.value = o;
	}

	/** @returns the color used to render the walls and edges of the point cloud */
	get tomographicColor(): Color {
		return this.material.uniforms.tomoColor.value;
	}

	/** Sets the color used to render the walls and edges of the point cloud */
	set tomographicColor(c: Color) {
		this.material.uniforms.tomoColor.value.copy(c);
	}

	/** @returns by how many stdandard deviation the point count should be higher than its neighbors' average to be a feature.*/
	get deviationFromAverage(): number {
		return this.material.uniforms.deviationFromAverage.value;
	}

	/** Sets the deviation from average parameter. */
	set deviationFromAverage(d: number) {
		this.material.uniforms.deviationFromAverage.value = d;
	}

	/** @returns the bias used on the point count threshold. */
	get bias(): number {
		return this.material.uniforms.bias.value;
	}

	/** Sets the bias used on the point count threshold. */
	set bias(b: number) {
		this.material.uniforms.bias.value = b;
	}

	/** @returns the outlier rejection factor. */
	get outlierRejectionFactor(): number {
		return this.material.uniforms.outlierRejectionFactor.value;
	}

	/** Sets the outlier rejection factor. */
	set outlierRejectionFactor(o: number) {
		this.material.uniforms.outlierRejectionFactor.value = o;
	}

	/**
	 * @param dpr An optional parameter, useful to adapt the tomographic rendering to screens with device pixel ratio
	 * different from 1.0.
	 * @returns a newly created instance of the points material to be used in combination with this pass
	 * for point cloud tomographic rendering.
	 */
	static createTomographicMaterialPoints(dpr = 1.0): PointsMaterial {
		// Experimentally we find that this formula allows the tomographic rendering
		// to adapt to the DPR of the device. It has been tested with dpr = 1.0 and dpr = 2.5.
		const pointsSize = 3.0 / dpr;
		return new PointsMaterial({
			vertexColors: false,
			sizeAttenuation: false,
			size: pointsSize,
			blending: CustomBlending,
			blendEquation: AddEquation,
			blendSrc: SrcAlphaFactor,
			blendDst: OneFactor,
			blendSrcAlpha: SrcAlphaFactor,
			blendDstAlpha: OneFactor,
			opacity: 0.01,
			transparent: true,
			color: 0xffffff,
			depthTest: false,
			depthWrite: false,
		});
	}

	/**
	 * Disposes all the GPU resources allocated by this object.
	 */
	dispose(): void {
		this.#blendAccumulationFbo.texture.dispose();
		this.#blendAccumulationFbo.dispose();
		super.dispose();
	}
}
