import { InvalidConfigurationError } from "@faro-lotv/foundation";
import { WebGLRenderer, WebGLRenderTarget } from "three";
import { FxaaMaterial } from "../Materials/FxaaMaterial";
import { FullScreenPass } from "./FullScreenPass";

/**
 * A ThreeJS effect pass to apply Fx Anti-Aliasing
 */
export class FxaaPass extends FullScreenPass<FxaaMaterial> {
	/**
	 * Default constructor
	 */
	constructor() {
		super(new FxaaMaterial());
	}

	/**
	 * Renders the Fx Anti-Aliasing
	 *
	 * @param renderer renderer used to render the effect
	 * @param writeBuffer Buffer to write by renderer
	 * @param readBuffer Buffer to read the textures by renderer
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("Fxaa pass requires a depth texture in the composer FBO");
		}

		this.material.uniforms.uColorTex.value = readBuffer.texture;
		this.material.uniforms.uResolution.value.set(readBuffer.width, readBuffer.height);
		this.material.uniformsNeedUpdate = true;
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.fsQuad.render(renderer);
	}
}
