/**
 * Implementation of Lotv BoxControls
 *
 * see comment on the constructor for important notes on usage
 *
 * This file mirror how OrbitControls are implemented in react-three/drei
 */
import { CursorStyle } from "@faro-lotv/flat-ui";
import {
  BoxControls as BoxControlsImpl,
  BoxControlsInteractionType,
  BoxGizmoColors,
} from "@faro-lotv/lotv";
import { ReactThreeFiber, useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { Camera, Plane, Quaternion, Vector3 } from "three";
import {
  TypedEventCallback,
  useCustomCanvasCursor,
  useTypedEvent,
} from "../hooks";
import { useThreeEventTarget } from "../hooks/use-three-event-target";

export type BoxControlsProps = Omit<
  ReactThreeFiber.Overwrite<
    // This line will bring in to props any public property of the LotV BoxControls class
    ReactThreeFiber.Object3DNode<BoxControlsImpl, typeof BoxControlsImpl>,
    {
      /** The camera this control uses for raycasts */
      camera?: Camera;
      /** The dom element used to receive the events */
      domElement?: HTMLElement;
      /** Position of the clipping box */
      position?: Vector3;
      /** Size of the clipping box */
      size?: Vector3;
      /** Rotation of the clipping box */
      rotation?: Quaternion;
      /** True if the clipping box should be visible */
      isVisible?: boolean;
      /** Optional override for the default colors */
      colors?: Partial<BoxGizmoColors>;
      /** Callback called every time the user start interacting with the clipping box */
      onInteractionStarted?: TypedEventCallback<
        BoxControlsImpl["interactStarted"]
      >;
      /** Callback called every time the user stop interacting with the clipping box */
      onInteractionStopped?: TypedEventCallback<
        BoxControlsImpl["interactionStopped"]
      >;
      /** Callback called any time the clipping planes change */
      clippingPlanesChanged?(planes: Plane[]): void;
      /** Callback called when the user selects a side */
      onSideSelected?: TypedEventCallback<BoxControlsImpl["sideSelected"]>;
    }
  >,
  // If there's a property called ref we need to omit it to not conflict with React ref property
  "ref"
>;

/**
 * The {ref} can be undefined as it is created only after we initialize it on the dom element
 */
export type BoxControlsRef = BoxControlsImpl | undefined;

/**
 * IMPORTANT: If you want the box to clip your geometry. You must assign the clipping planes
 * received from @see clippingPlanesChanged to any geometry you want clipped and you must set
 *
 * @see gl.localClippingEnabled = true;
 */
export const BoxControls = forwardRef<BoxControlsRef, BoxControlsProps>(
  function BoxControls(
    {
      camera,
      domElement,
      position,
      size,
      rotation,
      isVisible,
      onInteractionStarted,
      onInteractionStopped,
      clippingPlanesChanged,
      onSideSelected,
      colors,
      // These are the BoxControls props we want just to forward down to the LotV class
      ...args
    }: BoxControlsProps,
    ref,
  ) {
    // This is the currently default registered R3F camera
    const defaultCamera = useThree((state) => state.camera);

    // R3F EventManager target
    const eventTarget = useThreeEventTarget(domElement);

    // Compute camera to control
    const explicitCamera = camera ?? defaultCamera;

    const [interactionType, setInteractionType] =
      useState<BoxControlsInteractionType>();

    useBoxControlsCursor(interactionType);

    // Create control class
    const controls = useMemo(() => {
      return new BoxControlsImpl({
        camera: explicitCamera,
        element: eventTarget,
        size,
        position,
        rotation,
        colors,
        up: new Vector3(0, 0, 1),
      });
    }, [eventTarget, explicitCamera, position, size, rotation, colors]);
    // Expose internal events
    useTypedEvent(controls.interactStarted, onInteractionStarted);
    useTypedEvent(controls.interactionStopped, onInteractionStopped);
    useTypedEvent(controls.clippingPlanesChanged, clippingPlanesChanged);
    useTypedEvent(controls.sideSelected, onSideSelected);
    useTypedEvent(controls.hoveredInteractionTypeChanged, setInteractionType);
    // Ensure the controls are attached to the correct element to listen events from
    useEffect(() => {
      controls.attachElement(eventTarget);
      if (clippingPlanesChanged) {
        clippingPlanesChanged(controls.getClippingPlanes());
      }
      // When the controls changes disable previous one to disconnect from dom events
      return () => {
        controls.detachElement();
      };
    }, [clippingPlanesChanged, controls, eventTarget]);

    useEffect(() => {
      return () => controls.dispose();
    }, [controls]);

    return (
      <primitive ref={ref} object={controls} visible={isVisible} {...args} />
    );
  },
);

function useBoxControlsCursor(
  interactionType?: BoxControlsInteractionType,
): void {
  let cursorToShow;

  switch (interactionType) {
    case "selectSide":
      cursorToShow = CursorStyle.pointer;
      break;
    default:
      cursorToShow = CursorStyle.default;
      break;
  }

  useCustomCanvasCursor({
    cursorToShow,
    enable: !!interactionType,
  });
}
