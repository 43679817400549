import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent, SVGProps } from "react";

export type FaroSvgIconProps = SvgIconProps & {
  /** SVG as ReactComponent in order to be used in mui icon */
  source: FunctionComponent<SVGProps<SVGSVGElement>>;
};

/** @returns Wrapper for SVG icons */
export function FaroSvgIcon({
  source,
  ...rest
}: FaroSvgIconProps): JSX.Element {
  const inheritViewBox = rest.inheritViewBox ?? !rest.viewBox;
  return (
    <SvgIcon component={source} inheritViewBox={inheritViewBox} {...rest} />
  );
}
