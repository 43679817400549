import { GUID, dateToISOWithOffset } from "@faro-lotv/foundation";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Change the due date of a Markup
 */
export interface MutationDateTimeMarkupField extends BaseMutation {
  type: MutationTypes.MutationDateTimeMarkupField;

  /** The new due date of the DateTimeMarkupField element */
  dateTime: string;
}

/**
 * @returns a mutation to change the due date of a DateTimeMarkupField element
 * @param elementId id of the element
 * @param dateTime new due date of the element
 */
export function createMutationDateTimeMarkupField(
  elementId: GUID,
  dateTime: Date,
): MutationDateTimeMarkupField {
  const dateWithOffset = dateToISOWithOffset(dateTime);

  return {
    ...createBaseMutation(MutationTypes.MutationDateTimeMarkupField, elementId),
    dateTime: dateWithOffset,
  };
}
