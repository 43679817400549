import {
  BillboardSprite,
  CubeMapPano,
  FullScreenQuad,
  LineMaterial,
  LineSet,
  LodFloorPlan,
  LodPano,
  MeshFadeOffMaterial,
  PanoBlend,
  SelectionControls,
} from "@faro-lotv/lotv";
import { Object3DNode, extend } from "@react-three/fiber";

declare global {
  // TODO:https://faro01.atlassian.net/browse/SWEB-162
  // namespace is required to type the components correctly
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      cubeMapPano: Object3DNode<CubeMapPano, typeof CubeMapPano>;
      lodPano: Object3DNode<LodPano, typeof LodPano>;
      panoBlend: Object3DNode<PanoBlend, typeof PanoBlend>;
      lodFloorPlan: Object3DNode<LodFloorPlan, typeof LodFloorPlan>;
      billboardSprite: Object3DNode<BillboardSprite, typeof BillboardSprite>;
      lineSet: Object3DNode<LineSet, typeof LineSet>;
      selectionControls: Object3DNode<
        SelectionControls,
        typeof SelectionControls
      >;
      lineMaterial: Object3DNode<LineMaterial, typeof LineMaterial>;
      meshFadeOffMaterial: Object3DNode<
        MeshFadeOffMaterial,
        typeof MeshFadeOffMaterial
      >;
      fullScreenQuad: Object3DNode<FullScreenQuad, typeof FullScreenQuad>;
    }
  }
}

/**
 * Makes LotV components available directly as JSX tags
 */
export function extendR3FWithLotVComponents(): void {
  extend({
    CubeMapPano,
    LodPano,
    PanoBlend,
    LodFloorPlan,
    BillboardSprite,
    LineSet,
    SelectionControls,
    LineMaterial,
    MeshFadeOffMaterial,
    FullScreenQuad,
  });
}
