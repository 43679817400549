import { robustFetchJson } from "@faro-lotv/foundation";
import { Box3, Matrix4, Vector3 } from "three";
import { PotreeNode } from "./PotreeNode";
import { PotreeTree, PotreeUrls } from "./PotreeTree";
import { PotreeMetadata } from "./PotreeTypes";

/**
 * Fetches from the backend a new Potree point cloud
 *
 * @param urls The URLs pointing to the files describing the Potree point cloud
 * @param pcBackendUrl the URL to the point cloud backend to use to optimize data range requests
 * @returns the loaded Potree tree
 */
export async function createPotree(urls: PotreeUrls, pcBackendUrl?: string): Promise<PotreeTree> {
	const metadata: PotreeMetadata = await robustFetchJson({
		input: urls.metadata,
		// TODO: add type-guard https://faro01.atlassian.net/browse/NRT-1325
		validateJson: (obj: unknown): obj is PotreeMetadata => true,
	});

	const offset = new Vector3().fromArray([...metadata.boundingBox.min]);
	const bbox = new Box3().setFromArray([...metadata.boundingBox.max, ...metadata.boundingBox.min]);
	bbox.min.sub(offset);
	bbox.max.sub(offset);
	const root = new PotreeNode(0, undefined, bbox, 0);
	root.name = "r";
	root.isProxyNode = true;
	root.hierarchyOffset = BigInt(0);
	root.hierarchySize = BigInt(metadata.hierarchy.firstChunkSize);
	root.byteOffset = BigInt(0);

	const tree = new PotreeTree(urls, metadata, root, pcBackendUrl);
	tree.setWorldMatrix(new Matrix4().makeTranslation(offset.x, offset.y, offset.z));

	return tree;
}
