const float PI = 3.141592654;

// viewport dimension
uniform highp vec4 uViewport;
// panorama texture  (color)
uniform samplerCube uTex;
uniform mat4 uInvPVMMatrix;
uniform float uOpacity;

void main() {
    // depth 0.9 is used because 1.0 fails on android, -1.0 fails on VR, 0.999 gives artifact on ios, 0.9 works everywhere
    vec4 v = vec4((gl_FragCoord.xy - uViewport.xy) * 2.0 / uViewport.zw - 1.0, 0.9, 1.0);
    
    v = uInvPVMMatrix * v;
    v.x = v.x / v.w;
    v.y = v.y / v.w;
    v.z = v.z / v.w;

    vec3 dir = normalize(v.xyz);
    gl_FragColor = textureCube(uTex, dir);
    gl_FragColor.a = uOpacity;
}
