import { Object3D } from "three";
import { PointCloud } from "../PointCloud/PointCloud";
import { NodeCacheElement } from "./LodMultiview";

/**
 * An object that keeps a chuck of points in GPU memory, providing functions
 * to interact with the layers for multiview logic.
 * This is used only within the Lotv library.
 */
export class PointsCacheElement implements NodeCacheElement {
	/** The points buffer together with its layer */
	points: PointCloud;
	/** The point count */
	pointCount: number;
	/** Last rendered time stored for caching purposes */
	lastRenderedTime: number;

	/**
	 * @param points The point cloud
	 * @param pointCount The point count
	 * @param lastTime Timestamp of last time this element was visible.
	 */
	constructor(points: PointCloud, pointCount: number, lastTime: number) {
		this.points = points;
		this.pointCount = pointCount;
		this.lastRenderedTime = lastTime;
	}

	/** @returns the points as an object3D for management by LodMultiview. */
	get object(): Object3D {
		return this.points;
	}
}
