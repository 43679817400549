import { includes } from "@faro-lotv/foundation";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { Stack } from "@mui/system";
import { PropsWithChildren, useMemo, useState } from "react";
import { LanguageCodes } from "../../types/localize-types";
import { blue, neutral } from "../colors";
import { ExportIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";
import { TranslateVar } from "../translation";
import { UserAvatar } from "../user-avatar/user-avatar";
import { UserMenuBody } from "./user-menu-body";
import { UserMenuProps } from "./user-menu-types";

/** Defines the default size for all icons */
const ICONS_SIZE = "20px";

const hoverEffectCss: CSSProperties = {
  backgroundColor: neutral[100],
  borderColor: blue[500],
  ["& .MuiTypography-root"]: {
    color: blue[500],
  },
};

/**
 * @returns the user menu without its options inside. Pass the options/items as children of the component.
 */
export function UserMenu({
  userDisplayInfo,
  anchorElement,
  open,
  links,
  appVersion,
  lastPageUpdate,
  additionalAboutInfo,
  onOpenAbout,
  linkStyle,
  closeMenu,
  sx,
  children,
}: PropsWithChildren<UserMenuProps>): JSX.Element {
  const { email, firstName, lastName } = userDisplayInfo;

  const fullName = useMemo(
    () => `${firstName} ${lastName}`,
    [firstName, lastName],
  );

  return (
    <Menu
      anchorEl={anchorElement}
      id="account-menu"
      open={open}
      onClose={closeMenu}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "none",
          border: `1px solid ${neutral[200]}`,
          borderRadius: 0,
          elevation: 0,
          overflow: "visible",
          width: "275px",
          "& .MuiList-root": {
            padding: 0,
          },
        },
        ...sx,
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {/* Top section of the card for user avatar and user details */}
      <Box
        component="div"
        sx={{ display: "flex", alignItems: "center", padding: "16px" }}
      >
        {/* Section for user's avatar */}
        <Box component="div">
          <UserAvatar
            userDisplayInfo={userDisplayInfo}
            size="l"
            shouldShowGradientBorder
          />
        </Box>

        {/* Section for user details like name and email */}
        <Box
          component="div"
          sx={{
            paddingLeft: "16px",
            overflow: "hidden",
          }}
        >
          {/* Row for the user full name */}
          <Box
            component="div"
            sx={{
              color: neutral[800],
              fontSize: "14px",
              fontWeight: 500,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip
              title={<TranslateVar name="fullName">{fullName}</TranslateVar>}
            >
              <Box component="div">
                <FaroText variant="bodyS">
                  <TranslateVar name="fullName">{fullName}</TranslateVar>
                </FaroText>
              </Box>
            </Tooltip>
          </Box>

          {/* Row for the user email */}
          <Box
            component="div"
            sx={{
              color: neutral[800],
              fontSize: "12px",
              fontWeight: 400,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip title={<TranslateVar name="email">{email}</TranslateVar>}>
              <Box component="div">
                <FaroText variant="bodyS">
                  <TranslateVar name="email">{email}</TranslateVar>
                </FaroText>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <UserMenuBody
        links={links}
        linkStyle={linkStyle}
        appVersion={appVersion}
        lastPageUpdate={lastPageUpdate}
        additionalAboutInfo={additionalAboutInfo}
        onOpenAbout={onOpenAbout}
      >
        {children}
      </UserMenuBody>
    </Menu>
  );
}

type MenuOption = {
  /** Function called when the menu option is clicked */
  onClick?(): void;
};

/**
 * @returns the Account & Security menu item
 */
export function MenuAccountSecurityOption({
  onClick,
}: MenuOption): JSX.Element {
  return (
    <MenuItem onClick={onClick}>
      <ListItemText>
        <FaroText variant="bodyM">Account & Security</FaroText>
      </ListItemText>
    </MenuItem>
  );
}

/**
 * @returns the Sign Out menu item with the icon
 */
export function MenuSignOutOption({ onClick }: MenuOption): JSX.Element {
  return (
    <MenuItem onClick={onClick}>
      <ListItemText>
        <FaroText variant="bodyM">Log Out</FaroText>
      </ListItemText>
      <ListItemIcon sx={{ justifyContent: "end" }}>
        <ExportIcon
          sx={{
            rotate: "-90deg",
            color: neutral[800],
            height: ICONS_SIZE,
            width: ICONS_SIZE,
          }}
        />
      </ListItemIcon>
    </MenuItem>
  );
}

export interface LanguageObject {
  /** Shorthand notation ISO code for the language (i.e. "de" for German) */
  code: LanguageCodes;

  /** Fully written name of the language in the respective language (i.e. "Deutsch" for German) */
  name: string;
}

/**
 * @returns true if that language object maps to one of the supported languages in the app
 */
export function isSupportedLanguageObject(language: {
  code: string;
  name: string;
}): language is LanguageObject {
  return includes(Object.values(LanguageCodes), language.code);
}

interface LanguageSelectorProps {
  /** List of the available language objects */
  languages: LanguageObject[];

  /** The language code of the selected language */
  selectedLanguage: string;

  /** Callback function on select a language */
  onLanguageChange(languageCode: string): void;
}
/**
 * @returns the language selector menu
 */
export function LanguageSelectOption({
  selectedLanguage,
  languages,
  onLanguageChange,
}: LanguageSelectorProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <MenuItem
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <ListItemText>
          <FaroText variant="bodyM">Language</FaroText>
        </ListItemText>
        <ListItemIcon
          sx={{
            fontSize: "14px",
            fontWeight: "normal",
            color: neutral[800],
            alignItems: "flex-start",
            justifyContent: "end",
            // The icon svg has too much margin that does not look aligned.
            marginRight: "-5px",
          }}
        >
          <TranslateVar name="selectedLanguage">
            {selectedLanguage}
          </TranslateVar>
          <ChevronRightIcon
            style={{
              width: ICONS_SIZE,
              height: ICONS_SIZE,
              // The icon svg has too much margin that does not look aligned.
              marginTop: "1px",
            }}
          />
        </ListItemIcon>
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {languages.map((language) => (
          <MenuItem
            disableRipple
            sx={{
              minWidth: "140px",
              "&:hover": {
                ...hoverEffectCss,
              },
              "&.Mui-selected": {
                ...hoverEffectCss,
              },
            }}
            key={language.code}
            onClick={() => onLanguageChange(language.code)}
            selected={language.code === selectedLanguage}
          >
            <Stack
              sx={{
                border: `solid ${neutral[800]} 1px`,
                borderRadius: "2px",
                marginRight: "8px",
                padding: "2px",
                width: "16px",
                height: "16px",
                fontSize: "10px",
                justifyContent: "center",
                alignItems: "center",
                color: neutral[800],
              }}
            >
              <TranslateVar name="languageCode">{language.code}</TranslateVar>
            </Stack>
            <ListItemText
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "14px",
                  color: neutral[800],
                },
              }}
            >
              <TranslateVar name="languageName">{language.name}</TranslateVar>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
