import { ToggleButtonProps } from "@mui/material";
import { ToggleButton } from "./toggle-button";

/**
 * @returns A round toggle with optimized styles for icons
 */
export function ToggleIconButton({
  children,
  ...props
}: ToggleButtonProps): JSX.Element {
  return (
    <ToggleButton
      sx={{
        borderRadius: "50%",
        color: (theme) => theme.palette.primary.main,
        ["&:hover"]: {
          color: (theme) => theme.palette.white,
        },
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </ToggleButton>
  );
}
