import { validatePrimitive } from "@faro-lotv/foundation";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Removes a VideoRecordings section and all images that were generated during its video processing
 */
export interface MutationDeleteVideoModeData extends BaseMutation {
  /** The type of the mutation to apply */
  type: MutationTypes.MutationDeleteVideoModeData;

  /** Whether to also remove links referencing the deleted element or any of its descendants. */
  cascadeLinks?: boolean;
}

/**
 * @returns true if it is a valid MutationDeleteVideoModeData
 * @param data object to check
 */
export function isMutationDeleteVideoModeData(
  data: BaseMutation,
): data is MutationDeleteVideoModeData {
  return (
    data.type === MutationTypes.MutationDeleteVideoModeData &&
    (validatePrimitive(data, "cascadeLinks", "undefined") ||
      validatePrimitive(data, "cascadeLinks", "boolean"))
  );
}

/**
 * Creates the mutation payload to delete a VideoRecordings section from the project
 *
 * @param elementID id of the VideoRecordings section
 * @param cascadeLinks Whether to also remove links referencing the deleted element or any of its descendants
 * @returns The mutation payload to be sent to the ProjectAPI
 */
export function createMutationDeleteVideoModeData(
  elementID: string,
  cascadeLinks = false,
): MutationDeleteVideoModeData {
  return {
    ...createBaseMutation(MutationTypes.MutationDeleteVideoModeData, elementID),
    cascadeLinks,
  };
}
