import { assert } from "./assert";

/**
 * @returns the index of the nth occurrence of the pattern in text
 * @param text to search into
 * @param pattern to search
 * @param nth occurrence index to return (starting from 1 for the 1st occurrence)
 */
export function nthIndexOf(
  text: string,
  pattern: string,
  nth: number,
): number | undefined {
  if (!pattern) return;
  const R = new RegExp(pattern, "g");
  const matches = [...text.matchAll(R)];
  return matches.at(nth - 1)?.index;
}

/**
 * Parse a string that is expected to be an url inferring the protocol if missing
 *
 * @param link string that may miss the protocol
 * @returns a complete url string with the protocol at the start
 */
export function parseUrlLikeString(link: string): string {
  assert(link.length > 0, "Invalid empty url string");

  // Return the original string if it started with a valid protocol
  const VALID_PROTOCOLS = ["http:", "https:", "mailto:", "ftp:", "file:"];
  if (VALID_PROTOCOLS.some((protocol) => link.startsWith(protocol))) {
    return link;
  }

  // Check if the link resemble an email
  if (mayBeEmail(link)) {
    return `mailto:${link}`;
  }

  // Fallback to a https for everything else
  return `https://${link}`;
}

/**
 * @param text to check
 * @returns true if the text may be an email
 *
 * NOTE: There's no safe way to validate an email client side
 * this function uses a regex that may reject valid email address
 * so it can't be used for real validation
 */
export function mayBeEmail(text: string): boolean {
  const R =
    /[\w\d!#$%&'*+/=?^_`{|}~-]+(?:\.[\w\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w\d](?:[\w\d]*[\w\d])?\.)+[\w\d](?:[\w\d-]*[\w\d])?/;
  return R.test(text);
}
