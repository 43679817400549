import { GUID, SignedUrl, SignedUrlData } from "@faro-lotv/foundation";
import { ProjectApi } from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import { requestSignedUrlForIElement } from "../utils/request-signed-url-for-ielement";

/**
 * Request an updated and valid signed url for a project resource.
 * Useful when the signed url of a resources is required immediately by a component
 * Eg: to load a floor image texture
 *
 * NOTE: This hook will not re-request an updated signature when the first one expires
 * as it expect the resource to be immediately fetched and cached for the current component lifetime
 *
 * If the component may need to request the resources at a later time you can use {@link requestSignedUrlForIElement}
 *
 * @param projectApi client class to request new signatures
 * @param uri the uri to sign
 * @param elementId the id of the element that contains this resource
 * @param initialSignature the initial signature for this uri if available
 * @returns a valid signed url for the requested resource if available, if not undefined
 */
export function useSignedUrl(
  projectApi: ProjectApi,
  uri: string,
  elementId: GUID,
  initialSignature?: SignedUrlData,
): string | undefined | Error {
  const [signedUrl, setSignedUrl] = useState<string | Error>();

  useEffect(() => {
    requestSignedUrlForIElement(
      projectApi,
      uri,
      elementId,
      initialSignature,
    ).then((resultSignedUrl) => {
      setSignedUrl(resultSignedUrl);
    });
  }, [uri, elementId, initialSignature, projectApi]);

  return signedUrl;
}

/**
 * A signed URL which is refreshed when it expires.
 *
 * @param signedUrl The signed url to validate
 * @returns A valid signed URL string.
 */
export function useValidSignedUrl(signedUrl?: SignedUrl): string | undefined {
  const [validUrl, setValidUrl] = useState<string | undefined>(
    signedUrl?.isValid ? signedUrl.signedUrl : undefined,
  );

  // Refresh the URL when it expired
  useEffect(() => {
    if (!signedUrl || signedUrl.isValid) {
      setValidUrl(signedUrl?.signedUrl);
      return;
    }

    signedUrl.requestSignedUrl().then((newUrl) => {
      setValidUrl(newUrl);
    });
  }, [signedUrl]);

  return validUrl;
}

/**
 * @param url The URL to validate.
 * @returns If the URL is a string, it's returned as-is.
 *  If the URL is a {@link SignedUrl}, it's validated and returned as a string.
 */
export function useValidUrl(url?: SignedUrl | string): string | undefined {
  const signedUrl = useValidSignedUrl(
    url instanceof SignedUrl ? url : undefined,
  );

  return typeof url === "string" ? url : signedUrl;
}
