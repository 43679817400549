import { DoubleSide, ShaderMaterial, Texture } from "three";
import frag from "../Shaders/OverviewPlaceholders.frag";
import vert from "../Shaders/OverviewPlaceholders.vert";
import { makeOptionalUniform, makeUniform } from "./Uniforms";

const DEFAULT_HOVER_SIZE = 1.5;

const DEFAULT_FADE_OFF_DISTANCE = Number.MAX_VALUE;

/**
 * Specialized material to render placeholders using Instancing.
 *
 * @see OverviewPlaceholders
 */
export class OverviewPlaceholdersMaterial extends ShaderMaterial {
	/** Custom fragment shader source code */
	fragmentShader = frag;

	/** Custom vertex shader source code */
	vertexShader = vert;

	/** Default to transparent to get proper rendering with non quad textures */
	transparent = true;

	/** Default to show placeholders from both side */
	side = DoubleSide;

	uniforms = {
		/** Factor applied to the size for hovered placeholders */
		hoveredSizeFactor: makeUniform(DEFAULT_HOVER_SIZE),

		/** Factor applied to the size for selected placeholders */
		selectedSizeFactor: makeUniform(DEFAULT_HOVER_SIZE),

		/** Index of the hovered placeholder (or -1 if no placeholder is selected) */
		hovered: makeUniform(-1),

		/** Index of the selected placeholder (or -1 if no placeholder is selected) */
		selected: makeUniform(-1),

		/** Default texture used to render the placeholders */
		map: makeOptionalUniform<Texture>(),

		/** Texture used for hovered placeholders */
		hoveredMap: makeOptionalUniform<Texture>(),

		/** Texture used for the selected placeholders */
		selectedMap: makeOptionalUniform<Texture>(),

		/** Min alpha threshold to render a fragment */
		alphaTest: makeUniform(0.1),

		/** Distance at which the placeholders will start fading */
		fadeOffDistance: makeUniform(DEFAULT_FADE_OFF_DISTANCE),
	};
}
