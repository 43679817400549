import { Box, Stack } from "@mui/material";
import { CSSProperties } from "react";
import { ReactComponent as LoadingScreenSvg } from "./loading-screen.svg";

/** @returns a looping loading animation for FARO applications */
export function LoadingScreen(): JSX.Element {
  return (
    <Stack
      sx={{ width: "100%", height: "100%", overflow: "hidden" }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        component="div"
        sx={{
          minWidth: "200px",
          svg: {
            width: "100%",
            height: "100%",
          },
          // the svgo importer renames classes/ids of svg file
          // the only safe way to identify the group of circles is to use the g element selector
          "g:nth-of-type(1)": {
            ...generateGroupAnimation(1),
          },
          "g:nth-of-type(2)": {
            ...generateGroupAnimation(2),
          },
          "g:nth-of-type(3)": {
            ...generateGroupAnimation(3),
          },
          "g:nth-of-type(4)": {
            ...generateGroupAnimation(4),
          },
          "g:nth-of-type(5)": {
            ...generateGroupAnimation(5),
          },
          "g:nth-of-type(6)": {
            ...generateGroupAnimation(6),
          },
        }}
      >
        <LoadingScreenSvg />
      </Box>
    </Stack>
  );
}

/**
 * @param groupId id of the group of points inside the logo svg
 * @returns the CSS style to animate that group of points
 *
 * The animation is done by group of points, but all the animations share the same duration.
 * What changes between one group of point and the other is how fast they reach the final opacity value
 *
 * When all of them reach the final value then they animate back fast to the start value and the animation restarts
 */
function generateGroupAnimation(groupId: number): CSSProperties {
  // Initial opacity value
  const startOpacity = 0.3;

  // Final opacity value
  const finalOpacity = 1;

  // At what percentage of the full animation the opacity of this group should reach the final value
  const fadeInEndPercentage = (90 / 6) * groupId;

  return {
    // A keyframe for the animation of this specific group of points
    [`@keyframes loop${groupId}`]: {
      // All the points starts at the start value
      "0%": {
        opacity: startOpacity,
      },
      // Each group reach the final opacity value at a different animation percentage
      [`${fadeInEndPercentage}%`]: {
        opacity: finalOpacity,
      },
      // Then all the groups remain stable until 90% of the full animation
      "90%": {
        opacity: finalOpacity,
      },
      // Then the're animated back to the start opacity value before the animation restarts
      "100%": {
        opacity: startOpacity,
      },
    },

    animationName: `loop${groupId}`,
    // Full animation duration
    animationDuration: "4.5s",
    // The animation is an infinite loop
    animationIterationCount: "infinite",
    // At the end of the animation restart from the beginning
    animationDirection: "forward",
    // Easing curve to start slow and end fast computed by trial and error with the designers
    animationTimingFunction: "cubic-bezier(1,-0.01,.67,1.01)",
  };
}
