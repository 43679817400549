import {
  CadImporterErrorCode,
  cadImporterTaskErrorToUserMessage,
  isCadImporterErrorCode,
} from "./cad-importer-error-codes";
import {
  PCStreamGeneratorErrorCode,
  isPCStreamGeneratorErrorCode,
  pcsgTaskErrorToUserMessage,
} from "./pc-stream-generator-error-codes";

const DEFAULT_ERROR_MESSAGE = "Unexpected error. Please contact support.";

/**
 * Convert an error code to a message for the user.
 *
 * @param taskName Name of the task, which will be rendered for some messages.
 * @param errorCode The error code to generate an error message for.
 *   If not given, a generic message will be shown.
 * @returns A message explaining the error to the user.
 */
export function taskErrorToUserMessage(
  taskName: string,
  errorCode?: CadImporterErrorCode | PCStreamGeneratorErrorCode,
): string {
  if (isCadImporterErrorCode(errorCode)) {
    return cadImporterTaskErrorToUserMessage(taskName, errorCode);
  }

  if (isPCStreamGeneratorErrorCode(errorCode)) {
    return pcsgTaskErrorToUserMessage(taskName, errorCode);
  }

  return DEFAULT_ERROR_MESSAGE;
}
