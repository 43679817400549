/**
 * @param file file to get am image for
 * @returns the Image object for the file
 */
// eslint-disable-next-line require-await -- FIXME
export async function parseImageFromFile(
  file: File,
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.onerror = reject;

    image.src = URL.createObjectURL(file);
  });
}

interface Dimensions {
  width: number;
  height: number;
}

/**
 *
 * @param rect original size of the image
 * @param angle angle to rotate image in degrees
 * @returns dimensions of rotated image
 *
 * Helper function to compute dimensions of rotated image
 */
export function computeRotatedImageDimensions(
  rect: Dimensions,
  angle: number,
): Dimensions {
  const angleInRadians = (angle * Math.PI) / 180;
  const width =
    Math.abs(rect.width * Math.cos(angleInRadians)) +
    Math.abs(rect.height * Math.sin(angleInRadians));
  const height =
    Math.abs(rect.height * Math.cos(angleInRadians)) +
    Math.abs(rect.width * Math.sin(angleInRadians));

  return { width, height };
}
