import Data3d from "./3d-data.svg";
import ArrowDown from "./arrow-down.svg";
import ArrowRight from "./arrow-right.svg";
import Attachment from "./attachment.svg";
import BuildingElevation from "./building-elevation.svg";
import CheckMark2 from "./checkmark-2.svg";
import CheckMarkCircleFill from "./checkmark-circle-fill.svg";
import Clock from "./clock.svg";
import CloseCancelError from "./close-cancel-error.svg";
import Close from "./close.svg";
import ClusterLocation from "./cluster-location.svg";
import CopyFile from "./copy-file.svg";
import CrosshairMarkerBlue from "./crosshair_marker_blue.svg";
import CrosshairMarkerOrange from "./crosshair_marker_orange.svg";
import CrosshairWithCircle from "./crosshair_with_circle.svg";
import Delete from "./delete.svg";
import Disabled from "./disabled.svg";
import Dollhouse from "./dollhouse.svg";
import DotsVertical from "./dots_vertical.svg";
import Download from "./download.svg";
import Edit from "./edit.svg";
import EmailSent from "./email_sent.svg";
import ErrorPage from "./error_page.svg";
import ExclamationMarkCircleFill from "./exclamation-mark-circle-fill.svg";
import ExclamationMark from "./exclamationmark.svg";
import Expand from "./expand.svg";
import ExportPointCloud from "./export-point-cloud.svg";
import FaroLogo from "./faro-logo.svg";
import Feedback from "./feedback.svg";
import Folder from "./folder.svg";
import Grid from "./grid.svg";
import HBLogoBlackText from "./hblogo_blacktext.svg";
import Help from "./help.svg";
import Home from "./home.svg";
import InfoCircleFill from "./info-circle-fill.svg";
import Info from "./info.svg";
import InProgress from "./inprogress.svg";
import LensActive from "./lens-active.svg";
import LensDefault from "./lens-default.svg";
import LineHandler from "./line-handler.svg";
import LinkHorizontal from "./linkicon-horizontal.svg";
import LocationMap from "./location-map.svg";
import LocationTransparent from "./Location.svg";
import LocationOpaque from "./Location_FullOpaque.svg";
import LocationPlaceholderDefault from "./location_placeholder_default.svg";
import LocationPlaceholderHover from "./location_placeholder_hover.svg";
import MapLarge from "./map-large.svg";
import MapPlaceholderDefault from "./map-placeholder-default.svg";
import MapPlaceholderHovered from "./map-placeholder-hover.svg";
import MapPlaceholderSelectedHovered from "./map-placeholder-selected-hover.svg";
import MapPlaceholderSelected from "./map-placeholder-selected.svg";
import Map from "./map.svg";
import MarkupPolygon from "./markup-polygon.svg";
import Markup from "./markup.svg";
import MeasureShowAxes from "./measure-show-axes.svg";
import Panorama from "./panorama.svg";
import Phone from "./phone.svg";
import Pivot from "./pivot.svg";
import PlusCircle from "./plus-circle.svg";
import PointCloud from "./point-cloud.svg";
import QuestionMarkInCircle from "./question-mark-in-circle.svg";
import Running from "./running.svg";
import School from "./school.svg";
import Search from "./search.svg";
import ShieldWarning from "./shield-warning.svg";
import SliderHandler from "./slider-handler.svg";
import SplitscreenStart from "./splitscreen-start.svg";
import Upload from "./upload.svg";
import UserLocationNoFov from "./user-location-no-fov.svg";
import UserLocation from "./user-location.svg";
import VideoCamera from "./video-camera.svg";
import Warning from "./warning.svg";
import XMarkCircleFill from "./xmark-circle-fill.svg";

export {
  ArrowDown,
  ArrowRight,
  Attachment,
  BuildingElevation,
  CheckMark2,
  CheckMarkCircleFill,
  Clock,
  Close,
  CloseCancelError,
  ClusterLocation,
  CopyFile,
  CrosshairMarkerBlue,
  CrosshairMarkerOrange,
  CrosshairWithCircle,
  Data3d,
  Delete,
  Disabled,
  Dollhouse,
  DotsVertical,
  Download,
  Edit,
  EmailSent,
  ErrorPage,
  ExclamationMark,
  ExclamationMarkCircleFill,
  Expand,
  ExportPointCloud,
  FaroLogo,
  Feedback,
  Folder,
  Grid,
  HBLogoBlackText,
  Help,
  Home,
  InProgress,
  Info,
  InfoCircleFill,
  LensActive,
  LensDefault,
  LineHandler,
  LinkHorizontal,
  LocationMap,
  LocationOpaque,
  LocationPlaceholderDefault,
  LocationPlaceholderHover,
  LocationTransparent,
  Map,
  MapLarge,
  MapPlaceholderDefault,
  MapPlaceholderHovered,
  MapPlaceholderSelected,
  MapPlaceholderSelectedHovered,
  Markup,
  MarkupPolygon,
  MeasureShowAxes,
  Panorama,
  Phone,
  Pivot,
  PlusCircle,
  PointCloud,
  QuestionMarkInCircle,
  Running,
  School,
  Search,
  ShieldWarning,
  SliderHandler,
  SplitscreenStart,
  Upload,
  UserLocation,
  UserLocationNoFov,
  VideoCamera,
  Warning,
  XMarkCircleFill,
};

// These center offsets will put the origin of the cursor at the center of the svg
// The height and width of the svg CrosshairWithCircle is 90
export const CROSSHAIR_CENTER = 45;
// The height and width of the svgs LensDefault & LensActive is 128.
export const LENS_CENTER = 64;
