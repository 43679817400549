import { Object3D, BufferGeometry, Material, Color, Texture } from "three";

/**
 * An instance of Object3D with a geometry property
 */
export type ObjectWithGeometry = Object3D & { geometry: BufferGeometry };

/**
 * Function to check if an object has a geometry property
 *
 * @param object The object we want to check
 * @returns true if this object has a geometry property
 */
export function hasGeometry(object: Object3D): object is ObjectWithGeometry {
	// Below it is safe to use a type assertion because we check the line after
	// whether the assertion was successful or not.
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const test = object as ObjectWithGeometry;
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
	return test.geometry !== undefined && test.geometry instanceof BufferGeometry;
}

/**
 * An instance of an Object3D with a material property
 */
export type ObjectWithMaterial = Object3D & { material: Material };

/**
 * Function to check if an object has a material property
 *
 * @param object The object we want to check
 * @returns true if the object has a material property
 */
export function hasMaterial(object: Object3D): object is ObjectWithMaterial {
	// Below it is safe to use a type assertion because we check the line after
	// whether the assertion was successful or not.
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const test = object as ObjectWithMaterial;
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
	return test.material !== undefined && test.material instanceof Material;
}

/** A material with a 'color: Color' property */
export type ColoredMaterial = Material & { color: Color };

/**
 * Returns whether a given material has a color property of type Color.
 *
 * @param m A given threeJS material.
 * @returns whether m is a colored material.
 */
export function isColoredMaterial(m: Material | Material[]): m is ColoredMaterial {
	if (!(m instanceof Material)) return false;
	if (!("color" in m)) return false;
	return true;
}

/**
 * Check if a material has a color texture
 *
 * @param material The material to check
 * @returns true if the material have a texture defined
 */
export function isTexturedMaterial(material: Material | Material[]): material is Material & { map: Texture } {
	if (!(material instanceof Material)) return false;
	if (!("map" in material)) return false;
	return true;
}
