/**
 * Enum representing the available language codes.
 */
export enum LanguageCodes {
  English = "en",
  Spanish = "es",
  French = "fr",
  German = "de",
  Japanese = "ja",
}

/**
 * @returns The corresponding entry in the LanguageCodes enum for the given string
 * @param code Code to map
 */
export function codeToLanguageCodes(code: string): LanguageCodes {
  switch (code) {
    case "en":
      return LanguageCodes.English;
    case "es":
      return LanguageCodes.Spanish;
    case "fr":
      return LanguageCodes.French;
    case "de":
      return LanguageCodes.German;
    case "ja":
      return LanguageCodes.Japanese;
  }

  throw new Error(`Language code ${code} not supported.`);
}

/** CSS class to be used in order to disable translations for parts of the UI */
export const NO_TRANSLATE_CLASS = "notranslate";
