import { PropOptional, validateArrayOf } from "@faro-lotv/foundation";

/**
 * Format sent by the backend to describe an object in the model tree.
 * An object is the instance of a part or of a solid in the assembly model.
 * Note: the name and case of the fields is defined form the json contents.
 */
export type CadModelTreeObjectDescription = {
  /**
   * Autodesk/APS object id.
   * Is unique in the model tree = can be used as a key.
   * This id is NOT persistent and may change for the same model on each conversion.
   */
  ObjectId: number;

  /**
   * Optional object name.
   * Can be empty.
   * Is not unique in the model tree = do not use as a key.
   */
  Name: string | null;

  /**
   * Optional persistent id.
   * This id is only available for some formats (Revit and NavisWorks).
   * When available, this is is persistent and constant even when importing a edited version of the CAD model.
   */
  PersistentId: number | null;

  /**
   * Optional list of children objects.
   * Can be empty.
   */
  Children: CadModelTreeObjectDescription[] | null;
};

/**
 * @returns True if the generic record describes an object in model tree (CadModelTreeObjectDescription)
 * @param data The generic input record
 */
export function isCadModelTreeObjectDescription(
  data: Record<string, unknown>,
): data is CadModelTreeObjectDescription {
  // test the root properties
  if (
    !(
      typeof data.ObjectId === "number" &&
      (typeof data.Name === "string" || data.Name === null) &&
      (typeof data.PersistentId === "number" || data.PersistentId === null) &&
      (Array.isArray(data.Children) || data.Children === null)
    )
  ) {
    return false;
  }
  // recursively test the items in the children array
  return validateArrayOf({
    object: data,
    prop: "Children",
    elementGuard: isCadModelTreeObjectDescription,
    optionality: PropOptional,
  });
}

/** Type used for a complete model tree = an array of CadModelTreeObjectDescription for the root of the model. */
export type CadModelTreeDescription = CadModelTreeObjectDescription[];

/**
 * @returns True if the generic record describes a CAD model tree (CadModelTreeObjectDescription[])
 * @param data The generic input record
 */
export function isCadModelTreeDescription(
  data: Array<Record<string, unknown>>,
): data is CadModelTreeDescription {
  // test the root properties
  if (!Array.isArray(data)) return false;
  // recursively test the items in the children array
  for (const child of data) {
    if (!isCadModelTreeObjectDescription(child)) {
      return false;
    }
  }

  return true;
}
