import { PropOptional, validatePrimitive } from "@faro-lotv/foundation";

/** Format sent by the backend to describe a generic model3d stream */
export type Model3dStreamDescription = {
  /** Optional URL to the glb file of the model. Was generated only until 2024-08-12. */
  meshGlbUrl?: string | null;

  /** URL that contains the optional metadata of the model */
  metadataUrl: string | null;

  /** URL that contains the optional model tree (this tag was introduced on October 5th = it might be missing in some projects) */
  modelTreeUrl: string | null;

  /** URL that contains the metadata of list sub meshes. Was not generated prior 2024-06-14. */
  subMeshesUrl?: string | null;
};

/**
 * @returns True if the generic record describes the urls of a model3d stream
 * @param data The generic input record
 */
export function isModel3dStreamURLs(
  data: Record<string, unknown>,
): data is Model3dStreamDescription {
  return (
    validatePrimitive(data, "meshGlbUrl", "string", PropOptional) &&
    validatePrimitive(data, "subMeshesUrl", "string", PropOptional) &&
    (typeof data.metadataUrl === "string" || data.metadataUrl === null) &&
    (typeof data.modelTreeUrl === "string" || data.modelTreeUrl === null) &&
    (!!data.metadataUrl || !!data.meshGlbUrl || !!data.subMeshesUrl)
  );
}

/**
 * ISO 8601 date (midnight UTC) when Model3dStreamDescription.subMeshesUrl started to be valid on PROD.
 * The value of subMeshesUrl for an Model3dStreamDescription generated before this date should be ignored.
 */
export const firstVersionSubMeshesInProd = "2024-06-14";
