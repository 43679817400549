import { assert } from "@faro-lotv/foundation";
import { Camera, OrthographicCamera, PerspectiveCamera, Scene, Sprite, Texture, Vector2, WebGLRenderer } from "three";
import { BillboardSpriteMaterial } from "../Materials";
import { degToRad } from "../Utils";
import { Annotation } from "./Annotation";

const DEFAULT_FOVY = 70;

/**
 * This class is responsible for rendering a sprite that is always front-facing, rescales with
 * the perspective projection and has a minimum and maximum vertical size in pixels.
 * The billboard sprite has a position like an object in the 3d scene, and can be rotated around
 * the camera focal axis.
 */
export class BillboardSprite extends Sprite {
	declare material: BillboardSpriteMaterial;
	/**
	 * We remember in this object the screen size and the perspective camera vertical angle,
	 * to ease shader computations with the billboard size.
	 */
	#screenSize = new Vector2();
	#fovy = DEFAULT_FOVY;
	#orthoVsize = 1;

	/**
	 * Constructs a new billboard sprite to add to the scene. If the input
	 * annotation's symbol is undefined, the sprite is rendered as a full
	 * colored square.
	 *
	 * @param obj Annotation object to build the Sprite from
	 */
	constructor(obj: Annotation) {
		super();
		// TODO: Do we want that each annotation compiles its ownn shader? probabily not...
		this.material = new BillboardSpriteMaterial({ color: obj.color, map: obj.symbol });
		this.position.copy(obj.position);
	}

	/**
	 *
	 * @param icon Sets the icon to render
	 */
	setIcon(icon: Texture): void {
		this.material.map = icon;
	}

	/** @returns The vertical size of the billboard sprite, in meters */
	get size(): number {
		return this.material.size;
	}

	/** Sets the vertical size of the billboard sprite, in meters */
	set size(v: number) {
		this.material.size = v;
	}

	/** @returns the maximum verticalsize of the billboard sprite, in pixels */
	get maxSize(): number {
		return this.material.maxSize;
	}

	/** Sets the maximum verticalsize of the billboard sprite, in pixels */
	set maxSize(v: number) {
		this.material.maxSize = v;
	}

	/** @returns the minimum verticalsize of the billboard sprite, in pixels */
	get minSize(): number {
		return this.material.minSize;
	}

	/** Sets the minimum verticalsize of the billboard sprite, in pixels */
	set minSize(v: number) {
		this.material.minSize = v;
	}

	/**
	 * Setup shader parameters for correct computations of billboard sizes.
	 *
	 * @param sz The screen size
	 * @param fovy The perspective camera vertical angle
	 */
	#setupPix2MetersConversionPerspective(sz: Vector2, fovy: number): void {
		if (!sz.equals(this.#screenSize) || this.#fovy !== fovy) {
			this.#screenSize.copy(sz);
			this.#fovy = fovy;
			this.material.depthToNormalizedScreenHeight =
				(2 * Math.tan(degToRad(this.#fovy) * 0.5)) / this.#screenSize.y;
		}
	}

	/**
	 * Setup shader parameters for correct computations of billboard sizes.
	 *
	 * @param sz The screen size
	 * @param orthoVsize The ortho projection vertical size
	 */
	#setupPix2MetersConversionOrtho(sz: Vector2, orthoVsize: number): void {
		if (!sz.equals(this.#screenSize) || this.#orthoVsize !== orthoVsize) {
			this.#screenSize.copy(sz);
			this.#orthoVsize = orthoVsize;
			this.material.depthToNormalizedScreenHeight = this.#orthoVsize / this.#screenSize.y;
		}
	}

	/**
	 * Before rendering the billboard, we make sure to update the screen resolution
	 * to the shader.
	 *
	 * @param renderer The renderer
	 * @param _ The scene that is being rendered
	 * @param camera The camera that is being used for rendering
	 */
	override onBeforeRender = (renderer: WebGLRenderer, _: Scene, camera: Camera): void => {
		assert(camera instanceof PerspectiveCamera || camera instanceof OrthographicCamera, "Camera type unsupported");
		if (camera instanceof PerspectiveCamera) {
			this.#setupPix2MetersConversionPerspective(renderer.getSize(new Vector2()), camera.getEffectiveFOV());
		} else {
			// To understand the line below, check out 3js orthocamera implementation, OrthographicCamera.js line 87.
			const ovsize = (camera.top - camera.bottom) / camera.zoom;
			this.#setupPix2MetersConversionOrtho(renderer.getSize(new Vector2()), ovsize);
		}
	};
}
