import { Badge, BadgeProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { neutral, orange } from "../colors";
import { FontWeights } from "../faro-theme";

export type ToolBadgeProps = PropsWithChildren &
  Pick<BadgeProps, "badgeContent">;

/** @returns a Badge styled to be used on a Tool in a Toolbar */
export function ToolBadge({
  children,
  badgeContent,
}: ToolBadgeProps): JSX.Element {
  return (
    <Badge
      sx={{
        "& .MuiBadge-badge": {
          border: `1px solid ${neutral[999]}`,
          height: "18px",
          width: "18px",
          backgroundColor: orange[400],
          color: neutral[950],
          fontWeight: FontWeights.SemiBold,
          px: (badgeContent?.toString().length ?? 0) > 1 ? "4px" : "1px",
          py: "1px",
        },
      }}
      badgeContent={badgeContent}
    >
      {children}
    </Badge>
  );
}
