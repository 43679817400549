import { ShaderLib, SpriteMaterial, SpriteMaterialParameters } from "three";
import frag from "../Shaders/BillboardScreenSprite.frag";
import vert from "../Shaders/BillboardScreenSprite.vert";
import { makeUniform } from "./Uniforms";

/**
 * Material that renders a billboard Sprite with a fixed screen size
 */
export class BillboardScreenSpriteMaterial extends SpriteMaterial {
	vertexShader: string;
	fragmentShader: string;
	isShaderMaterial = true;

	// As the BillboardScreenSpriteMaterial is being used as a shaderMaterial by setting isShaderMaterial to true,
	// the variable uniformsGroups needs to be added because three js expects every shaderMaterial to have this.
	uniformsGroups = [];

	// Size of the diameter of the sprite, in pixels
	#size = 1;

	uniforms = {
		...ShaderLib.sprite.uniforms,
		size: makeUniform(this.#size),
		depthToNormalizedScreenHeight: makeUniform(1),
		zOffset: makeUniform(0),
	};

	/**
	 * @param parameters An object with one or more properties defining the material's appearance.
	 */
	constructor(parameters: Partial<SpriteMaterialParameters> = {}) {
		super();
		this.type = "BillboardScreenSpriteMaterial";
		this.vertexShader = vert;
		this.fragmentShader = frag;
		this.setValues(parameters);
	}

	/** @returns The size of the diameter of the sprite, in pixels */
	public get size(): number {
		return this.#size;
	}

	/** Sets the size of the diameter of the sprite, in pixels */
	public set size(value: number) {
		if (value !== this.#size) {
			this.#size = value;
			this.uniforms.size.value = value;
		}
	}

	/** @returns The depth to normalized screen height perspective multiplier. */
	public get depthToNormalizedScreenHeight(): number {
		return this.uniforms.depthToNormalizedScreenHeight.value;
	}

	/**
	 * Sets the depth to normalized screen height perspective multiplier.
	 * This is defined as 2.0 * tan(0.5 * fovy) / screenHeight for perspective projection.
	 * For ortho projection, it is defined as (top - bottom) / screenHeight
	 */
	public set depthToNormalizedScreenHeight(d: number) {
		this.uniforms.depthToNormalizedScreenHeight.value = d;
	}

	/** @returns the offset that will be applied to the line z camera position while rendering */
	get zOffset(): number {
		return this.uniforms.zOffset.value;
	}

	/** Change the camera space z offset used while rendering */
	set zOffset(offset: number) {
		this.uniforms.zOffset.value = offset;
	}
}
