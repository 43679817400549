import { Vector3 } from "three";
import type { LodNodeFetch } from "../Lod/LodTree";
import { PointCloudChunk } from "../PointCloud";
import { PotreeNode } from "./PotreeNode";
import { PotreeNodeAbortRequest, PotreeNodeFetchRequest, PotreeNodeResponses } from "./PotreePoints";
import { PotreeTree } from "./PotreeTree";

type EventListener = (event: MessageEvent<PotreeNodeResponses>) => void;

/**
 * Potree implementation of LodNodeFetch
 */
export class PotreeNodeFetch implements LodNodeFetch {
	#uuid = "";

	#promise: null | Promise<PointCloudChunk>;
	#worker: Worker | null;

	/**
	 * We store here the event listener that will be added to the worker.
	 * In this way, we can remove the event listener from the worker when node fetching is finished or canceled.
	 * If we do not do that, then the worker will store thousands of event listeners, that will capture all the WSNodeFetch
	 * object in the closure, data buffers included, therefore javascript will not be able to deallocate the data buffers
	 * after download, crashing the page for out of memory after a while.
	 */
	private eventListener: EventListener | null = null;

	/**
	 * Create a fetcher for a node data
	 *
	 * @param tree The tree the node belongs to
	 * @param node The node for which the data should be fetched
	 * @param url The basic url pointing to the Potree data
	 * @param worker The worker used to load and parse the new points
	 * @param pcBackendUrl The url of the point cloud backend to use to optimize data range requests
	 */
	constructor(
		public tree: PotreeTree,
		public node: PotreeNode,
		url: URL,
		worker: Worker,
		pcBackendUrl?: string,
	) {
		this.#worker = worker;
		this.#promise = new Promise<PointCloudChunk>((resolve, reject) => {
			const el = (event: MessageEvent<PotreeNodeResponses>): void => {
				if (event.data.entity !== tree.uuid || event.data.nodeId !== node.name) return;
				switch (event.data.type) {
					case "PotreeNodeFetchSuccess":
						node.pointDensity = event.data.density;
						resolve(event.data.points);
						break;
					case "PotreeNodeFetchFailed":
						reject(event.data.error);
						break;
				}
				// Detaching the event listener from the worker, so the garbage collector can dispose of this object and its buffers.
				this.cleanup();
			};
			// storing the event listener to remove it from the worker on cleanup.
			this.eventListener = el;
			worker.addEventListener("message", el);
		});

		const rootBox = new Vector3(...tree.metadata.boundingBox.min);
		const msg: PotreeNodeFetchRequest = {
			type: "PotreeNodeFetchRequest",
			pcBackendUrl,
			entity: tree.uuid,
			byteStart: node.byteOffset,
			bytesEnd: node.byteOffset + node.byteSize - BigInt(1),
			nodeId: node.name,
			url: url.toString(),
			attributes: tree.metadata.attributes,
			encoding: tree.metadata.encoding,
			descriptor: {
				box: {
					max: node.boundingBox.max.clone().add(rootBox).toArray(),
					min: node.boundingBox.min.clone().add(rootBox).toArray(),
				},
				numPoints: node.numPoints,
				scale: tree.metadata.scale,
				offset: [tree.metadata.offset[0], tree.metadata.offset[1], tree.metadata.offset[2]],
				minimumBoundingBox: [node.boundingBox.min.x, node.boundingBox.min.y, node.boundingBox.min.z],
			},
		};
		// TODO: the buffers shared between main thread and worker
		// should be transformed into transferables https://faro01.atlassian.net/browse/SWEB-1285
		this.#worker.postMessage(msg);
	}

	/** @returns the unique id of the node fetched  */
	get uuid(): string {
		return this.#uuid;
	}

	/** @returns the promise that wait for the points to arrive */
	points(): Promise<PointCloudChunk> {
		if (!this.#promise) {
			throw new Error("Cannot ask points to expired Lod node fetch.");
		}
		return this.#promise;
	}
	/**
	 * Abort this fetch, the promise returned by node() will be rejected
	 */
	abort(): void {
		if (!this.#worker) {
			return;
		}
		const msg: PotreeNodeAbortRequest = {
			type: "PotreeNodeAbortRequest",
			entity: this.tree.uuid,
			nodeId: this.node.uuid,
		};
		this.#worker.postMessage(msg);
	}

	/** Sets to null internal references to buffers so that memory can be deallocated. */
	cleanup(): void {
		this.#promise = null;
		if (this.#worker && this.eventListener) {
			this.#worker.removeEventListener("message", this.eventListener);
		}
		this.#worker = null;
		this.eventListener = null;
	}
}
