/**
 * Implementation of Lotv Map2DControls
 * This file mirrors the sphere-controls.tsx one
 */
import { Map2DControls as Map2DControlsImpl } from "@faro-lotv/lotv";
import { UPDATE_CONTROLS_PRIORITY } from "@faro-lotv/spatial-ui";
import { ReactThreeFiber, useFrame, useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useMemo } from "react";
import { Camera } from "three";
import { useOverrideControls } from "../../hooks/use-override-controls";
import { useThreeEventTarget } from "../hooks/use-three-event-target";

export type Map2DControlsProps = Omit<
  ReactThreeFiber.Overwrite<
    ReactThreeFiber.Object3DNode<Map2DControlsImpl, typeof Map2DControlsImpl>,
    {
      /** The camera this controls will animage */
      camera?: Camera;
      /** The dom element used to receive the events */
      domElement?: HTMLElement;
      /** Movement inertia, in the range of [0, 1) */
      movementInertia?: number;
      /** Rotation inertia, in the range of [0, 1) */
      rotationInertia?: number;

      /**
       * Whether this is the primary control of the view.
       *
       * If set to `false`, interaction with the left mouse button will be disabled.
       *
       * @default true
       */
      isPrimaryControl?: boolean;
    }
  >,
  "ref"
>;

/**
 * The {ref} can be undefined as it is created only after we initialize it on the dom element
 */
export type Map2DControlsRef = Map2DControlsImpl | undefined;

/**
 * This component wraps the class Lotv.Map2DControls. It provides mouse and touch controls
 * for the given camera and the given dom element. These controls are suited for use in
 * a minimap canvas, or in a 2D overview map. The camera is set to look in the nadiral
 * direction (top-to-down).
 *
 * Mouse interactin: the user can translate the view with the right mouse button,
 * rotate it with the left, and zoom it with the mouse wheel.
 *
 * Touch interaction: the view is translated with the single touch, rotated and
 * zoomed with two touches.
 *
 * I'm using forwardRef here so it's possible to get a ref on the controls if needed.
 */
export const Map2DControls = forwardRef<Map2DControlsRef, Map2DControlsProps>(
  function Map2DControls(
    {
      camera,
      domElement,
      movementInertia,
      rotationInertia,
      isPrimaryControl = true,
      ...restProps
    }: Map2DControlsProps,
    ref,
  ): JSX.Element {
    // The entire 3RF state has been invalidated, need to recreate everything
    const invalidate = useThree((state) => state.invalidate);
    // This is the currently default registered R3F camera
    const defaultCamera = useThree((state) => state.camera);

    // R3F EventManager target
    const eventTarget = useThreeEventTarget(domElement);

    // Compute camera to control
    const explCamera = camera ?? defaultCamera;

    // Create control class
    const controls = useMemo(
      () => new Map2DControlsImpl(explCamera),
      [explCamera],
    );
    // Ensure the controls are attached to the correct element to listen events from
    useEffect(() => {
      controls.attach(eventTarget);
      // When the controls changes disable previous one to disconnect from dom events
      return () => {
        controls.detach();
      };
    }, [controls, eventTarget, invalidate]);

    // Register this control as the default and restore the old default when unmounted
    useOverrideControls(controls);

    // If controls is enabled call updated at every frame
    useFrame((_, delta) => {
      if (controls.enabled) {
        controls.update(delta);
      }
      // The controls just moved the camera, executing this with UPDATE_CONTROLS_PRIORITY
      // so that it is guaranteed that camera monitoring and lod visibility tasks come after this.
    }, UPDATE_CONTROLS_PRIORITY);

    return (
      <primitive
        ref={ref}
        object={controls}
        // Following props are part of the controls
        // eslint-disable-next-line react/no-unknown-property
        movementInertia={movementInertia}
        // eslint-disable-next-line react/no-unknown-property
        rotationInertia={rotationInertia}
        // eslint-disable-next-line react/no-unknown-property
        enableRotation={false}
        // eslint-disable-next-line react/no-unknown-property
        isPrimaryControl={isPrimaryControl}
        {...restProps}
      />
    );
  },
);
