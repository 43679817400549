import { walkWithQueue } from "@faro-lotv/foundation";
import { Camera, Frustum, Matrix4, Vector2, Vector3 } from "three";
import { AbstractVisibleNodesStrategy, INFINITE_SIZE_ON_SCREEN, SizedNode } from "../Lod";
import { LodTree, LodTreeNode } from "../Lod/LodTree";
import { VisibleNodesStrategy, WeightedNode } from "../Lod/VisibleNodeStrategy";
import { closestPointOnBox, invertedRigid } from "../Utils/GeometricUtils";
import { WSNodeSubdivision, computeSubdivisionAxis } from "./ComputeSubdivisionAxis";
import { WSKDTreeNode } from "./WSKDTree";

const DEFAULT_POINT_BUDGET = 8500000;
const NODE_TOO_CLOSE_THRESHOLD = -2;

/**
 * This class handles the computation of which nodes from a Webshare KD tree are visible given a camera,
 * a screen resolution, and a point density on screen parameter.
 *
 * It is optimized for an octree. It performs a breadth-first visit of the tree nodes. Each node is scored
 * according to its pixels occupancy on screen, which is inferred by the pixels-per-point density on
 * screen of the node, multiplied by the number of points contained in the node.
 *
 * When computing the pixels-per-point density of a given node, care is taken to avoid mistakes when the
 * node's bounding box may be irregular and cannot be trusted. Each node in the webshare kdtree is divided
 * along only one axis. When the children of a node are looked from the side (camera focal axis orthogonal to
 * subdivision axis), then we trust the parent's bounding box and not the children's.
 *
 * Finally, the list of visible nodes is sorted by pixel occupancy from most prominent to least prominent,
 * and clamped so that the sum of displayed points is lower than maxPointsInGPU.
 */
export class WSVisibleNodesStrategy extends AbstractVisibleNodesStrategy implements VisibleNodesStrategy {
	/** The position of the camera in the point cloud local coordinates */
	#camPos = new Vector3();
	/** The transform from cloud to camera coordinates. */
	#T = new Matrix4();
	/** The transform from camera to cloud coordinates. */
	#Tinv = new Matrix4();
	/** The camera frustum is allocated here only once. */
	#frustum = new Frustum();
	/**
	 * Below we store two points A and B, needed inside the function 'computePixelsPerPoint'
	 * We allocate them only once instead of allocating them for every node at every frame.
	 */
	#A = new Vector3();
	/** Same as above */
	#B = new Vector3();
	/**
	 * The closest point of a node's bounding box to the current camera, allocated only once
	 * to avoid allocations for every node at every frame.
	 */
	#closestPoint = new Vector3();
	/** Parameter used in the #checkNode function to determine how to handle the node's bounding box.*/
	#cosLimit = Math.cos((45 * Math.PI) / 180);
	/** The axis along which the current node's children are subdivided. Can be either X, Y or Z */
	#nodeSubdivisionAxis = new Vector3();

	constructor() {
		super();
		/**
		 * After many experiments, we set this target pixels per point value, so that
		 * it is easy for the gap filling to close the gaps.
		 */
		this.pTargetPixelsPerPoint = 0.5;
		/**
		 * By setting a low 'target pixels per point' value, we risk to load a lot of
		 * nodes and to place approx. 400 GPU calls per frame for rendering points.
		 * Let's avoid that by having a low 'max points in GPU' budget. This will
		 * mostly discard nodes that are behind and favor nodes in the front.
		 */
		this.pMaxPointsInGPU = DEFAULT_POINT_BUDGET;
	}

	/**
	 * Given a tree node and some camera parameters, computes the point density on screen that
	 * the node offers to the current viewport. The density is in pixels per point.
	 *
	 * @param node The argument node
	 * @param P The camera projection matrix
	 * @param screenSize The current screen resolution
	 * @returns The point density of the node perceived from the given viewport, in pixels per point.
	 */
	protected computePixelsPerPoint(node: Partial<LodTreeNode>, P: Matrix4, screenSize: Vector2): number {
		if (!node.boundingBox || !node.pointDensity) {
			throw new Error("Invalid input node.");
		}

		const { closestPoint, pointInside } = closestPointOnBox(this.#camPos, node.boundingBox, this.#closestPoint);
		// If the camera is inside the node's bounding box, we return a very high value
		// because it does not make sense to talk about pixel density, since A could
		// be anywhere far from the real points.
		if (pointInside) {
			return INFINITE_SIZE_ON_SCREEN;
		}
		// bringing closestPoint in camera coordinates
		closestPoint.applyMatrix4(this.#T);
		// If the closest point is behind the camera, the following computations do not make sense.
		// If it is not behind, then it should also be inside the camera's frustum, being the box's closest
		// point to the origin.
		if (closestPoint.z >= NODE_TOO_CLOSE_THRESHOLD) {
			return INFINITE_SIZE_ON_SCREEN;
		}
		// We want to determine the pixels per point of this node in a way that is independent of the camera rotation,
		// it should depend only of the distance camera origin -> closest point on box.
		// therefore, we place a point A along the camera focal axis at the given distance,
		// and a point B upwards one step according to the nodes point density.
		this.#A.set(0, 0, -closestPoint.length());
		// Converting node.pointDensity from millimeters to meters, and applying the
		// cloud scale because A and B are in camera coordinates and not in cloud coordinates.
		this.#B.set(0, node.pointDensity * 0.001 * this.pCloudScale, this.#A.z);
		// Then we project A and B on screen and we measure their distance in pixels.
		this.#A.applyMatrix4(P);
		this.#B.applyMatrix4(P);
		// Measuring the distance between A and B in pixels
		return Math.min(Math.abs(this.#A.y - this.#B.y) * 0.5 * screenSize.y, INFINITE_SIZE_ON_SCREEN);
	}

	/**
	 * Check which node should be used to compute the pixel density
	 *
	 * @param tree The input LODTree
	 * @param currNode The node to check
	 * @param camView The camera view direction in the tree system coordinate
	 * @returns The node to use for computing pixel point size
	 */
	#checkNode(tree: LodTree, currNode: WSKDTreeNode, camView: Vector3): Partial<LodTreeNode> {
		const subdivisionType: WSNodeSubdivision = computeSubdivisionAxis(currNode);
		let useParentReference = true;
		switch (subdivisionType) {
			case WSNodeSubdivision.Xaxis:
				this.#nodeSubdivisionAxis.set(1, 0, 0);
				break;
			case WSNodeSubdivision.Yaxis:
				this.#nodeSubdivisionAxis.set(0, 1, 0);
				break;
			case WSNodeSubdivision.Zaxis:
				this.#nodeSubdivisionAxis.set(0, 0, 1);
				break;
			default:
				useParentReference = false;
				break;
		}
		if (useParentReference) {
			useParentReference = Math.abs(camView.dot(this.#nodeSubdivisionAxis)) < this.#cosLimit;
		}

		return {
			boundingBox:
				useParentReference && currNode.parentId !== undefined
					? tree.getNode(currNode.parentId).boundingBox
					: currNode.boundingBox,
			pointDensity: currNode.pointDensity,
		};
	}

	/**
	 * Given a camera, this algorithm returns the list of nodes that are visible from the camera, sorted
	 * from highest to lowest estimated screen size.
	 *
	 * @param tree The tree on which to perform the computation
	 * @param cloudWorldMatrix The global pose of the LOD point cloud.
	 * Warning: the scale of the point cloud must be uniform.
	 * @param camera The camera from which the scene is being rendered.
	 * @param screenSize The current screen size, in pixels.
	 * @returns the list of indices of the visible nodes, sorted from highest to lowest screen occupancy.
	 */
	public compute(tree: LodTree, cloudWorldMatrix: Matrix4, camera: Camera, screenSize: Vector2): WeightedNode[] {
		// Computing the cloud uniform scale from the cloud world matrix.
		// Passing directly the cloud.scale property here does not work,
		// because the cloud.scale property might be 1.0 while the parent group scale may be != 1.0
		this.extractCloudScale(cloudWorldMatrix);
		// T transforms from the LOD cloud local coordinates to the camera local coordinates
		this.#T.multiplyMatrices(camera.matrixWorldInverse, cloudWorldMatrix);
		// Tinv transforms from the camera local coordinates to the LOD cloud local coordinates.
		this.#Tinv = invertedRigid(this.#T, this.#Tinv);
		// Storing into this.#camPos the camera position in the cloud local coordinates.
		// It will be needed inside 'computePixelsPerPoint'
		this.#camPos.setFromMatrixPosition(this.#Tinv);
		// Allocating the result list of nodes
		const visibleNodes = new Array<SizedNode>();
		// Computing the camera frustum in the cloud local coordinates.
		this.#frustum.setFromProjectionMatrix(new Matrix4().multiplyMatrices(camera.projectionMatrix, this.#T));

		walkWithQueue([0], (n, append) => {
			const currNode = tree.getNode(n);
			if (!this.#frustum.intersectsBox(currNode.boundingBox)) {
				return;
			}

			// If this node has points, compute the metrics for visualization
			// purposes
			let shouldVisitChildren = true;
			if (currNode.numPoints > 0) {
				const pixelsPerPoint = this.computePixelsPerPoint(currNode, camera.projectionMatrix, screenSize);
				const sz =
					pixelsPerPoint === INFINITE_SIZE_ON_SCREEN
						? INFINITE_SIZE_ON_SCREEN
						: pixelsPerPoint * currNode.numPoints;
				currNode.pixelsPerPoint = pixelsPerPoint;
				visibleNodes.push(new SizedNode(n, sz, pixelsPerPoint));
				shouldVisitChildren = pixelsPerPoint > this.pTargetPixelsPerPoint;
			}
			if (shouldVisitChildren) {
				for (const child of currNode.children) {
					append(child.id);
				}
			}
		});
		if (visibleNodes.length === 0) {
			return [];
		}
		return this.capVisibleNodesByNumPoints(visibleNodes, tree);
	}

	/** @inheritdoc */
	clone(): WSVisibleNodesStrategy {
		const clone = new WSVisibleNodesStrategy();
		clone.copy(this);
		return clone;
	}
}
