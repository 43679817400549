import { safeDispose } from "@faro-lotv/lotv";
import { useEffect } from "react";
import { Object3D } from "three";

/**
 * This hook guarantees that the custom disposal logic for Lotv objects is executed properly
 * in a R3F context
 *
 * Use this hook if you're creating a component that owns and should dispose a Lotv object
 * when unmounted.
 *
 * Do not use this hook if the component do not own the Lotv object.
 *
 * R3F may overwrite the "dispose" method of an object for it's own purposes and can dispose
 * the children of an object before the object itself is disposed forcing the "children" property
 * to null (breaking the contract of the Object3D type)
 *
 * To work around it Lotv implements a custom dispose function to make sure the original dispose
 * method of the object prototype is invoked and that the children are checked before being disposed.
 *
 * This hook register the lotv custom dispose logic to trigger when the object is unmounted.
 *
 * @param object3d to dispose on unmount
 */
export function useLotvDispose(object3d?: Object3D | null): void {
  useEffect(() => {
    if (!object3d) {
      return;
    }
    return () => safeDispose(object3d);
  }, [object3d]);
}
