import { DependencyList, EffectCallback, useEffect } from "react";

/**
 * Hook similar to useEffect but will be executed only when the dependencies
 * specified are called, ignoring the useEffect warning about missing dependencies
 *
 * This is not for generic reactivity, but to respond to specific changes
 *
 * @param effect The effect to run
 * @param dependencies The dependencies to trigger this effect
 */
export function useNonExhaustiveEffect(
  effect: EffectCallback,
  dependencies: DependencyList,
): void {
  // This hook is to be explicit in code when we don't want exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, dependencies);
}
