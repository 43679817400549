import { removeExtension } from "@faro-lotv/foundation";
import { parseImageFromFile } from "./image-utils";

interface GenerateThumbnailParams {
  /** The image to generate a thumbnail for */
  image: HTMLImageElement;

  /**
   * Max size of a length
   *
   * @default 1024
   */
  maxSize?: number;

  /**
   * The compression ratio of the thumbnail jpeg
   *
   * @default 0.85
   */
  compressRatio?: number;
}

/**
 * @returns an image file containing the resized image, can be a thumbnail file as well
 * @param file the image file to generate a thumbnail for or to resize
 * @param params The resizing parameters
 */
export async function resizeImage(
  file: File,
  params?: Omit<GenerateThumbnailParams, "image">,
): Promise<File> {
  const image = await parseImageFromFile(file);
  return new File(
    [await generateThumbnailBlob({ ...params, image })],
    `${removeExtension(file.name)}-thumb.jpg`,
  );
}

/**
 * @returns a thumbnail image for an image
 */
// eslint-disable-next-line require-await -- FIXME
export async function generateThumbnailBlob({
  compressRatio = 0.85,
  ...params
}: GenerateThumbnailParams): Promise<Blob> {
  const canvas = generateThumbnailCanvas(params);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
          return;
        }
        reject(
          Error("generateThumbnailBlob: Failed to convert thumbnail to blob"),
        );
      },
      "image/jpeg",
      compressRatio,
    );
  });
}

/**
 * @returns a new canvas with a thumbnail of the image drawn on it.
 */
export function generateThumbnailCanvas({
  image,
  maxSize = 1024,
}: GenerateThumbnailParams): HTMLCanvasElement {
  const originHeight: number = image.height;
  const originWidth: number = image.width;

  maxSize = Math.min(maxSize, Math.max(image.width, image.height));

  const aspectX: number = maxSize / originWidth;
  const aspectY: number = maxSize / originHeight;

  let newWidth, newHeight;

  if (aspectX > aspectY) {
    newWidth = Math.round(originWidth * aspectY);
    newHeight = Math.round(originHeight * aspectY);
  } else {
    newWidth = Math.round(originWidth * aspectX);
    newHeight = Math.round(originHeight * aspectX);
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = newWidth;
  canvas.height = newHeight;

  if (!ctx) throw new Error("generateThumbnail: Failed to create context");

  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  return canvas;
}
