import { Box, SxProps, Theme } from "@mui/material";
import {
  CSSProperties,
  PropsWithChildren,
  DragEvent as ReactDragEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { cyan, neutral } from "../colors";
import { useIsUserDragging } from "./use-is-user-dragging";

/**
 * Style variants of the drop handler
 * - transparent: Variant that is invisible, until dragged over
 * - dark: suitable for dark backgrounds
 */
type DropHandlerVariant = "transparent" | "dark";

/**
 * States of the drop handler to apply different styles to
 * - root: shared styles applied all the time
 * - hover: when user hovers over the drop handler (without a file)
 * - active: when user is currently clicking the drop handler
 * - appDrag: when user is dragging a file anywhere in the app
 * - zoneDrag: when user is dragging a file over the drop handler
 */
type DropHandlerStyleState =
  | "root"
  | "hover"
  | "active"
  | "appDrag"
  | "zoneDrag";

type DropHandlerProps = PropsWithChildren & {
  /** Optional style to apply to the component */
  sx?: SxProps<Theme>;
  /** Which style variant of the dropdown should be used*/
  variant?: DropHandlerVariant;
  /**
   * Enable/Disable the drop handler
   *
   * @default true
   */
  enabled?: boolean;
  /** Callback executed to check if the dragged files are supported */
  canBeDropped(event: ReactDragEvent<HTMLElement>): boolean;
  /** Callback executed when files are dropped */
  onDrop(event: ReactDragEvent<HTMLElement>): void;
  /** Callback executed when the drop area is clicked */
  onClick?(): void;
};

const VARIANT_MAP: Record<
  DropHandlerVariant,
  // This is using CSSProperties instead of SxProps, because Typescript's type inference can't handle the spreading otherwise.
  Partial<Record<DropHandlerStyleState, CSSProperties>>
> = {
  transparent: {
    root: {
      border: "solid",
      borderColor: "transparent",
      backgroundColor: "transparent",
      borderRadius: "0.25rem",
    },
    zoneDrag: {
      border: "dashed",
      borderColor: "primary.main",
      borderRadius: "0.25rem",
    },
  },
  dark: {
    root: {
      background: neutral[950],
      border: `1px dashed ${neutral[700]}`,
      borderRadius: "3px",
    },
    hover: {
      cursor: "pointer",
      background: `${neutral[500]}26`,
      borderColor: cyan[500],
    },
    active: {
      background: `${neutral[500]}40`,
      borderColor: cyan[500],
    },
    zoneDrag: {
      border: "dashed",
      background: `${neutral[500]}26`,
      borderColor: cyan[500],
    },
  },
};

/** @returns A dropzone with a predefined style */
export function DropHandler({
  children,
  sx,
  variant = "transparent",
  enabled = true,
  canBeDropped,
  onDrop: onClientDrop,
  onClick,
}: DropHandlerProps): JSX.Element | null {
  const isAppDragging = useIsUserDragging();

  /** Check if the current element is receiving dragging events */
  const [isDragging, setIsDragging] = useState(false);

  const onDragEnterAndOver = useCallback(
    (e: ReactDragEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (canBeDropped(e)) {
        e.dataTransfer.dropEffect = "copy";
        e.dataTransfer.effectAllowed = "all";
      } else {
        e.dataTransfer.dropEffect = "none";
        e.dataTransfer.effectAllowed = "none";
      }

      e.stopPropagation();
      setIsDragging(true);
    },
    [canBeDropped],
  );

  const onDragLeave = useCallback((e: ReactDragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const onDrop = useCallback(
    (e: ReactDragEvent<HTMLDivElement>) => {
      onDragLeave(e);
      onClientDrop(e);
    },
    [onClientDrop, onDragLeave],
  );

  const style: CSSProperties = useMemo(() => {
    const rootStyle = {
      width: "100%",
      height: "100%",

      ...VARIANT_MAP[variant].root,

      "&:hover": VARIANT_MAP[variant].hover,

      "&:active": VARIANT_MAP[variant].active,
    };

    const appDraggingStyle = {
      ...rootStyle,
      zIndex: 1,
      ...VARIANT_MAP[variant].appDrag,
    };

    const dropZoneDraggingStyle = {
      ...rootStyle,
      zIndex: 1,
      ...VARIANT_MAP[variant].zoneDrag,
    };

    if (isDragging) return dropZoneDraggingStyle;
    if (isAppDragging) return appDraggingStyle;
    return rootStyle;
  }, [variant, isDragging, isAppDragging]);

  if (!enabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Box
      component="div"
      sx={{
        ...style,
        ...sx,
      }}
      onDragEnter={onDragEnterAndOver}
      onDragOver={onDragEnterAndOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}
