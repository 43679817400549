import { Color, Group, Vector3 } from "three";
import { LineSegmentMaterialParameters } from "../Materials/LineSegmentMaterial";
import { LineSegment } from "./LineSegment";

/**
 * This class manages a group of Line segments.
 */
export class LineSet extends Group {
	override children: LineSegment[] = [];

	/** The rendering parameters of the group */
	#parameters: LineSegmentMaterialParameters;

	/**
	 * Create a line set with a camera attached to it.
	 *
	 * @param parameters The rendering parameters of the segments
	 */
	constructor(parameters: LineSegmentMaterialParameters = {}) {
		super();
		this.#parameters = parameters;
	}

	/**
	 * Add a new segment to the set
	 *
	 * @param p0 The starting point of the new segment
	 * @param p1 The ending point of the new segment
	 * @returns The newly added segment
	 */
	addSegment(p0: Vector3, p1: Vector3): LineSegment {
		const segment = new LineSegment(p0, p1, this.#parameters);
		this.add(segment);
		return segment;
	}

	/** Set the rendering color */
	set diffuse(c: Color) {
		this.#parameters.diffuse = c;
		for (const child of this.children) {
			child.material.diffuse = c;
		}
	}

	/** @returns The color used for rendering */
	get diffuse(): Color {
		return this.#parameters.diffuse ?? new Color();
	}

	/** Set the line thickness in pixels */
	set thickness(t: number) {
		this.#parameters.thickness = t;
		for (const child of this.children) {
			child.material.thickness = t;
		}
	}

	/** @returns The color used for rendering */
	get thickness(): number {
		return this.#parameters.thickness ?? 1;
	}

	/** Set the line opacity. Must be a value between 0.0 and 1.0. */
	set opacity(opacity: number) {
		this.#parameters.opacity = opacity;
		for (const child of this.children) {
			// We need to update the uniform directly here.
			// `LineSegmentMaterial` can't implement a setter,
			// because one is already defined in a parent class
			child.material.uniforms.opacity.value = opacity;
			child.material.uniformsNeedUpdate = true;
		}
	}

	/** @returns The opacity of the lines, a value between 0.0 and 1.0. */
	get opacity(): number {
		return this.#parameters.opacity ?? 1.0;
	}
}
