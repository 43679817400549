import { GUID, generateGUID } from "@faro-lotv/foundation";
import { IElement } from "./i-element";
import { IElementBase, IElementType } from "./i-element-base";

/**
 * Creates a generic IElement {@link IElementBase} based on the provided props.
 * If not provided id, createdAt, modifiedAt, modifiedBy and root_Id are automatically initialized with
 * {@link IElementBase.id}: a random GUID
 * {@link IElementBase.createdAt}: and {@link IElementBase.modifiedAt} the current date and time
 * {@link IElementBase.modifiedBy}: the same value as {@link IElementBase.createdBy}
 * {@link IElementBase.root_Id}: the same value as {@link IElementBase.rootId}
 * All other props are initialized with null, if not provided.
 *
 * @param props to use for the new IElement. name, rootId, createdBy and type are required.
 * @returns a valid IElement with all values initialized.
 */
export function createIElementBase(
  props: Partial<IElementBase> & {
    name: string;
    rootId: GUID;
    createdBy: GUID;
    type: IElementType | string;
  },
): IElementBase {
  const now = new Date().toISOString();
  const ielement: IElementBase = {
    ...IELEMENT_TEMPLATE,
    ...props,
    // Automatically initialize certain props, if they are not provided:
    id: props.id ?? generateGUID(),
    createdAt: props.createdAt ?? now,
    modifiedAt: props.modifiedAt ?? now,
    modifiedBy: props.modifiedBy ?? props.createdBy,
    root_Id: props.root_Id ?? props.rootId,
  };
  return ielement;
}

/**
 * Creates a specific IElement of the specified type, ensuring that all the required props for the chosen type are passed.
 * Initialization of not provided props is identical to {@link createIElementBase}.
 *
 * @param props to use for the new IElement instance. name, rootId, createdBy and type are required.
 * Type must be the type of the chosen IElement.
 * @returns a valid IElement of the specified type
 */
export function createIElement<T extends IElement>(
  props: Omit<T, keyof IElementBase> &
    Partial<IElementBase> & {
      type: T["type"];
      name: string;
      rootId: GUID;
      createdBy: GUID;
    },
): T {
  // @ts-expect-error: Typescript can not prove that we're assigning all properties required for all possible values of T.
  // But the props argument is defined in a way that we're enforcing all required props, or an error will be shown.
  // So even though type script can't know this, we can be sure, that this function will always return a valid T for each possible T.
  const ielement: T = {
    ...createIElementBase(props),
  };
  return ielement;
}

const IELEMENT_TEMPLATE: Readonly<IElementBase> = {
  // These props are not nullable, so we have to initialize them with dummy values.
  // They are always overwritten by the createIElementBase function, so the actual values don't matter.
  id: "",
  rootId: "",
  root_Id: "",
  createdBy: "",
  createdAt: "",
  modifiedBy: "",
  modifiedAt: "",
  name: "",
  type: IElementType.projectRoot,

  // These props are nullable, so we can initialize them with null.
  // The initial value will be kept if they are not provided in the props argument of createIElementBase.
  childrenIds: null,
  children_Ids: null,
  typeHint: null,
  descr: null,
  parentId: null,
  parent_Id: null,
  thumbnailUri: null,
  pose: null,
  metaDataMap: null,
  metaData: null,
  lastModifiedInDb: null,
  labels: null,
};
