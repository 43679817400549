varying vec2 vUv;
uniform vec2 uResolution;
uniform sampler2D uColorTex;

// Fast Approximate Anti Aliasing is a cheap edge detection based blurring algorithm.
// It tries to detect the jagged  edges and tries to blur those parts.
// o o X
// o o X
// o X X
// An example of how the jagged edge looks above. The algorithm calculates the
// luma of the corners and tries to see if there is an increase horizontally 
// or vertically. If yes, this points out the jagged edge. Then it calculates the
// magnitude and tries to colorize locally there. This approach keeps the 
// anti aliasing single pass and number of operations really low.
// Suggested heuristics work pretty well. Wrong herustics might end up as more jagged
// edges or extra blurred scene.
void main() {
    // Some heuristics
    const float FXAA_REDUCE_MIN = 1.0/128.0;
    const float FXAA_REDUCE_MUL = 1.0/8.0;
    const float FXAA_SPAN_MAX   = 8.0;
    const vec3 luma = vec3(0.299, 0.587, 0.114);

    vec2 fragCoord = vUv * uResolution;
    vec2 inverseVP = 1.0 / uResolution.xy;
    vec2 v_rgbNW = (fragCoord + vec2(-1.0, -1.0)) * inverseVP;
    vec2 v_rgbNE = (fragCoord + vec2(1.0, -1.0)) * inverseVP;
    vec2 v_rgbSW = (fragCoord + vec2(-1.0, 1.0)) * inverseVP;
    vec2 v_rgbSE = (fragCoord + vec2(1.0, 1.0)) * inverseVP;
    vec2 v_rgbM = vec2(fragCoord * inverseVP);
    vec3 rgbNW = texture2D(uColorTex, v_rgbNW).xyz;
    vec3 rgbNE = texture2D(uColorTex, v_rgbNE).xyz;
    vec3 rgbSW = texture2D(uColorTex, v_rgbSW).xyz;
    vec3 rgbSE = texture2D(uColorTex, v_rgbSE).xyz;
    vec3 rgbM  = texture2D(uColorTex, v_rgbM).xyz;

    float lumaNW = dot(rgbNW, luma);
    float lumaNE = dot(rgbNE, luma);
    float lumaSW = dot(rgbSW, luma);
    float lumaSE = dot(rgbSE, luma);
    float lumaM  = dot(rgbM,  luma);
    
    // Find the direction of the edges
    mediump vec2 dir;
    dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE));
    dir.y =  ((lumaNW + lumaSW) - (lumaNE + lumaSE));
    
    // Reduction number in order not to have a zero division
    float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) *
                          (0.25 * FXAA_REDUCE_MUL), FXAA_REDUCE_MIN);
    
    // Scaling the minimum edge to 1.
    float rcpDirMin = 1.0 / (min(abs(dir.x), abs(dir.y)) + dirReduce);
    // Clamp the value to between some min and max to not to overblur. 
    dir = clamp(dir * rcpDirMin, vec2(-FXAA_SPAN_MAX), vec2(FXAA_SPAN_MAX)) * inverseVP;
    
    // Get some color at a distance away in the picked direction.
    vec3 rgbA = 0.5 * (
        texture2D(uColorTex, v_rgbM + dir * (1.0 / 3.0 - 0.5)).xyz +
        texture2D(uColorTex, v_rgbM + dir * (2.0 / 3.0 - 0.5)).xyz);
    // Get some other color mixed some middle color.
    vec3 rgbB = rgbA * 0.5 + 0.25 * (
        texture2D(uColorTex, v_rgbM + dir * -0.5).xyz +
        texture2D(uColorTex, v_rgbM + dir * 0.5).xyz);

    // Calculate the luma for new colors and pic the most suitable.
    float lumaB = dot(rgbB, luma);
    float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE)));
    float lumaMax = max(lumaM, max(max(lumaNW, lumaNE), max(lumaSW, lumaSE)));
    if ((lumaB < lumaMin) || (lumaB > lumaMax))
        gl_FragColor = vec4(rgbA, 1.0);
    else
        gl_FragColor = vec4(rgbB, 1.0);
}
