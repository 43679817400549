import { TypedEvent, assert } from "@faro-lotv/foundation";
import { Clock, MOUSE, Vector2 } from "three";

/** Experimentally, on my Android smartphone all taps last from 0.06 to 0.13 seconds*/
const MAX_SECONDS_FOR_TAP = 0.18;

/** Maximum time span between two single taps to trigger a double tap event. */
const MAX_SECONDS_FOR_DOUBLE_TAP = 0.4;

/**
 * The duration a user has to press for a long press to be registered.
 *
 * Corresponds to the default value used on React Native.
 */
const LONG_PRESS_MS = 500;

/**
 * Value used to distinguish between rotation and pinch and zoom.
 * If the angle between two vectors is above 45 degrees (cosAngle < 0.707), it is a rotation.
 */
const ROTATION_TRESHOLD = 0.707;

/**
 * This data class tracks the pixel coordinates, movement and speed of a given touch/mouse/pen pointer.
 */
export class PointerCoords {
	/**
	 * Current position relative to the application's viewport.
	 * See also https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/clientX
	 */
	clientPosition = new Vector2();
	/** Start position in pixels, relative to the target element */
	startPosition = new Vector2();
	/** Current position in pixels, relative to the target element */
	position = new Vector2();
	/** Last position in pixels, relative to the target element */
	lastPosition = new Vector2();
	/**
	 * Timestamp of pointer creation, needed for tap gesture detection
	 * If the pointer is a mouse, in this member we store the timestamp
	 * of the last mouse pressed event, to detect clicks
	 */
	startTimestamp = 0;
	/** Timestamp of last pointer update, in seconds */
	timestamp = 0;
	/** Time since last pointer update, in seconds */
	deltaTime = 0;
	/** Speed in pixels per second */
	speed = new Vector2();

	/**
	 * @param target The EventTarget that generated the event
	 */
	constructor(public target?: EventTarget) {}

	/**
	 *
	 * @param clientPos Pixel position relative to the current app viewport (ev.clientX, ev.clientY)
	 * @returns Pixel coordinates relative to the target html element
	 */
	relativeCoordinates(clientPos: Vector2): Vector2 {
		const pos = clientPos.clone();
		if (this.target instanceof HTMLElement) {
			const rect = this.target.getBoundingClientRect();
			pos.x -= rect.left;
			pos.y -= rect.top;
		}
		return pos;
	}

	/**
	 *
	 * @param ev The event who's position to start tracking from
	 * @param t Starting timestamp
	 */
	startAt(ev: MouseEvent | Touch, t: number): void {
		this.clientPosition.set(ev.clientX, ev.clientY);
		const pos = this.relativeCoordinates(this.clientPosition);
		this.startPosition.copy(pos);
		this.position.copy(pos);
		this.lastPosition.copy(pos);
		this.timestamp = t;
		this.startTimestamp = t;
	}

	/**
	 *
	 * @param ev The event who's position to track
	 * @param t Current timestamp
	 * @param discardDuplicates Whether to discard this event update if the position remained the same.
	 */
	trackAt(ev: MouseEvent | Touch, t: number, discardDuplicates: boolean): void {
		if (discardDuplicates && this.clientPosition.x === ev.clientX && this.clientPosition.y === ev.clientY) {
			return;
		}
		this.clientPosition.set(ev.clientX, ev.clientY);
		const pos = this.relativeCoordinates(this.clientPosition);
		this.deltaTime = t - this.timestamp;
		this.timestamp = t;
		if (this.deltaTime > 0) {
			this.speed.subVectors(pos, this.position);
			this.speed.divideScalar(this.deltaTime);
		} else {
			this.speed.set(0, 0);
			this.deltaTime = 0;
		}
		this.lastPosition.copy(this.position);
		this.position.copy(pos);
	}

	/**
	 *
	 * @param deltaTime The delta time to compute the movement at the given speed.
	 * @returns Movement done in the given delta time at the current speed, in pixels.
	 */
	getMovement(deltaTime: number): Vector2 {
		const m = this.speed.clone();
		m.multiplyScalar(deltaTime);
		return m;
	}

	/**
	 *
	 * @returns A String description of this object.
	 */
	toString(): string {
		return `Pos: ${this.position.x}, ${this.position.y}`;
	}
}

/** An enum to tag the different two-touch gestures */
export enum DoubleTouchGesture {
	/** Two touches are moving roughly in the same direction */
	Translate = 0,
	/** Two touches are orbiting around the same point */
	Rotate = 1,
	/** Two touches are moving towards each other or far away from each other */
	PinchAndZoom = 2,
}

/** A type describing a double touch event */
export type DoubleTouch = {
	/** Main touch */
	mainTouch: PointerCoords;
	/** Second touch */
	secondTouch: PointerCoords;
	/** Time span on which the movement is computed */
	deltaTime: number;
	/** Movement done by main touch in the given time span, in screen pixels */
	movement1: Vector2;
	/** Movement done by second touch in the given time span, in screen pixels */
	movement2: Vector2;
	/** The previous position of the main touch, in screen pixels */
	prevPos1: Vector2;
	/** The previous position of the second touch, in screen pixels */
	prevPos2: Vector2;
	/** The type of double touch gesture: translate, rotate, or pinch-and-zoom */
	type: DoubleTouchGesture;
	/** only if the gesture is rotate, the rotation angle in counterclockwise radians. */
	rotationAngle: number;
	/**
	 * Only if the gesture is pinch-and-zoom: the zoom ratio.
	 * ratio is < 1 if the user is zooming in, > 1 if the user is zooming out.
	 */
	ratio: number;
};

/**
 *
 * @param b numeric constant candidate to determine a button
 * @returns true iff b is one of the three constants k_LeftMouseButton, k_MidMouseButton or k_RightMouseButton
 */
export function isMouseButton(b: number): b is MOUSE.LEFT | MOUSE.MIDDLE | MOUSE.RIGHT {
	return b === MOUSE.LEFT || b === MOUSE.MIDDLE || b === MOUSE.RIGHT;
}

/**
 * Generic handler to track multiple touch pointers that undergo many PointerEvents
 */
export class TouchEvents {
	/** Flag to track if this events system is enabled or disabled */
	#enabled = true;
	/** Tracked points */
	#pointers = new Array<number>();
	/** Positions of all points */
	#pointerPositions = new Map<number, PointerCoords>();
	/** Clock to check delta between events */
	#clock = new Clock(true);
	/** Last time we detected tap, used to detect doubletap. */
	#lastTapTimestamp = this.#clock.getElapsedTime();
	/** The element we're attached to */
	#element?: HTMLElement;
	/** Function to dispose all connections to #element, it exists only when the connections are active */
	#disposer?: () => void;
	/** Data for mouse tracking */
	mouseCoords = new PointerCoords();
	/** The buttons we received as mouse press */
	#mouseButtonsTracked: number[] = [];
	/** The identifier for the timer to trigger the long press event. */
	// ReturnType needed here to satisfy TS for both node and web https://stackoverflow.com/a/64071901
	#longPressTimer?: ReturnType<typeof setTimeout>;

	/** Touch events a client can listen to */
	touchStarted = new TypedEvent<PointerCoords>();
	touchEnded = new TypedEvent<PointerCoords>();
	singleTouchMoved = new TypedEvent<PointerCoords>();
	doubleTouchMoved = new TypedEvent<DoubleTouch>();
	tapGesture = new TypedEvent<PointerCoords>();
	doubleTap = new TypedEvent<PointerCoords>();
	longPress = new TypedEvent<PointerCoords>();
	pinchAndZoomed = new TypedEvent<DoubleTouch>();
	doubleTouchRotated = new TypedEvent<DoubleTouch>();
	doubleTouchTranslated = new TypedEvent<DoubleTouch>();

	/** Mouse event a client to listen to */
	mousePressed = new TypedEvent<MouseEvent>();
	mouseReleased = new TypedEvent<MouseEvent>();
	mouseDragged = new TypedEvent<MouseEvent>();
	mouseMoved = new TypedEvent<MouseEvent>();
	/**
	 * We provide our own detection of the mouse single click, since the HTML
	 * one does not have limits in time or position.
	 */
	mouseSingleClicked = new TypedEvent<MouseEvent>();
	mouseDoubleClicked = new TypedEvent<MouseEvent>();
	mouseWheel = new TypedEvent<WheelEvent>();

	/**
	 * @returns the height in pixel of the tracked element
	 */
	get elementHeight(): number {
		return this.element?.getBoundingClientRect().height ?? 0;
	}

	/**
	 * @returns the width in pixel of the tracked element
	 */
	get elementWidth(): number {
		return this.element?.getBoundingClientRect().width ?? 0;
	}

	/**
	 *
	 * @param element The html canvas element whose mouse and touch events should be managed.
	 */
	constructor(element?: HTMLElement) {
		this.mouseCoords.timestamp = this.#clock.getElapsedTime();
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchMove = this.onTouchMove.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.onTouchCancel = this.onTouchCancel.bind(this);
		this.onWheel = this.onWheel.bind(this);
		this.onContextMenu = this.onContextMenu.bind(this);

		this.#element = element;
		this.enabled = true;
		this.updateActiveStatus();
	}

	/** @returns true if the events are enabled and attached to an element */
	get active(): boolean {
		return this.#disposer !== undefined;
	}

	/**
	 * Attach to an element to receive events from him
	 *
	 * @param element The element to track
	 */
	attach(element: HTMLElement): void {
		if (this.active) {
			this.detach();
		}
		this.#element = element;
		// disable touch scroll
		this.#element.style.touchAction = "none";
		this.mouseCoords.target = element;
		this.updateActiveStatus();
	}

	/**
	 * Detach from the currently attached element
	 */
	detach(): void {
		this.#element = undefined;
		this.mouseCoords.target = undefined;
		this.updateActiveStatus();
	}

	/**
	 * Tracks the given pointer, recording its current position, start position and last movement.
	 *
	 * @param ev The touch event
	 * @param discardDuplicates Whether updates that leave the touch in the same position should be discarded.
	 */
	private startTrackingPointer(ev: Touch, discardDuplicates = false): void {
		let pp = this.#pointerPositions.get(ev.identifier);
		if (!this.#element) return;
		if (pp === undefined) {
			pp = new PointerCoords(this.#element);
			pp.startAt(ev, this.#clock.getElapsedTime());
			this.#pointerPositions.set(ev.identifier, pp);
			this.#pointers.push(ev.identifier);
		} else {
			if (pp.target !== this.#element) pp.target = this.#element;
			pp.trackAt(ev, this.#clock.getElapsedTime(), discardDuplicates);
		}
	}

	/**
	 *
	 * @param ev The touch event
	 * @param discardDuplicates Whether updates that leave the touch in the same position should be discarded.
	 * @returns true if the touch event started inside this element, false otherwise.
	 */
	private trackPointer(ev: Touch, discardDuplicates = false): boolean {
		const pp = this.#pointerPositions.get(ev.identifier);
		if (pp === undefined) {
			return false;
		}

		pp.trackAt(ev, this.#clock.getElapsedTime(), discardDuplicates);
		return true;
	}

	/**
	 * Stops tracking the given pointerId
	 *
	 * @param pointerId The identifier of the touch event to stop tracking.
	 */
	private removePointer(pointerId: number): void {
		this.#pointerPositions.delete(pointerId);
		for (let i = 0; i < this.#pointers.length; ++i) {
			if (this.#pointers[i] === pointerId) {
				this.#pointers.splice(i, 1);
				return;
			}
		}
	}

	/** @returns How many touch pointers are being tracked. */
	get pointersCount(): number {
		return this.#pointers.length;
	}

	/**
	 *
	 * @param pid ID of the first touch pointer.
	 * @returns the ID of the other touch pointer, during a two-touch interaction.
	 */
	getOtherPointer(pid: number): number {
		return this.#pointers[0] === pid ? this.#pointers[1] : this.#pointers[0];
	}

	/**
	 *
	 * @param pid ID of the first touch pointer.
	 * @returns the coordinates info of the other touch pointer, during a two-touch interaction.
	 */
	getOtherPointerPos(pid: number): PointerCoords {
		const pointer = this.#pointerPositions.get(this.getOtherPointer(pid));
		assert(pointer, "Pointer position does not exist");
		return pointer;
	}

	/**
	 *
	 * @param d1 First delta time
	 * @param d2 Second delta time
	 * @returns the min delta time strictly bigger than zero.
	 */
	getMinDeltaTime(d1: number, d2: number): number {
		if (d2 === 0) {
			return d1;
		} else if (d1 === 0) {
			return d2;
		}
		return Math.min(d1, d2);
	}

	/**
	 *
	 * @param pointerId ID of the queried pointer
	 * @returns coords of the queried pointer.
	 */
	getCoords(pointerId: number): PointerCoords {
		const pointer = this.#pointerPositions.get(pointerId);
		assert(pointer, "Pointer position does not exist");
		return pointer;
	}

	/**
	 *
	 * @param ev the event
	 */
	private onContextMenu(ev: Event): void {
		ev.preventDefault();
	}

	/**
	 *
	 * @param mainTouch The first of the two touches
	 * @param secondTouch The second of the two touches
	 * @returns The double touch event
	 */
	private computeDoubleTouch(mainTouch: PointerCoords, secondTouch: PointerCoords): DoubleTouch {
		const prevPos = mainTouch.position.clone();
		const prevPos2 = secondTouch.position.clone();
		const deltaTime = this.getMinDeltaTime(mainTouch.deltaTime, secondTouch.deltaTime);
		const movement1 = mainTouch.getMovement(deltaTime);
		const movement2 = secondTouch.getMovement(deltaTime);
		prevPos.sub(movement1);
		prevPos2.sub(movement2);
		let type = DoubleTouchGesture.Translate;
		let zoomRatio = 0;
		let rotAngle = 0;
		// If the two touches are not moving roughly in the same direction,
		// it is either a rotation or a pinch and zoom gesture
		if (movement1.dot(movement2) < 0) {
			// To distinguish between rotation and pinch and zoom, we compare the direction between
			// the touches with the average direction they are moving along.
			// If these two directions are 'more perpendicular than collinear', then it is a rotation.
			// If they are 'more collinear', it is pinch and zoom. Therefore,
			// if the angle between these two vectors is above 45 degrees (cosAngle < 0.707), it is a rotation
			const deltaPos = new Vector2().subVectors(secondTouch.position, mainTouch.position);
			const movement = movement2.clone().sub(movement1);
			deltaPos.normalize();
			movement.normalize();
			const cosAngle = Math.abs(deltaPos.dot(movement));
			if (cosAngle < ROTATION_TRESHOLD) {
				type = DoubleTouchGesture.Rotate;
				const previousDeltaPos = new Vector2().subVectors(prevPos2, prevPos);
				previousDeltaPos.normalize();
				rotAngle = Math.asin(previousDeltaPos.cross(deltaPos));
			} else {
				type = DoubleTouchGesture.PinchAndZoom;
				const dNow = secondTouch.position.distanceTo(mainTouch.position);
				const dPrev = prevPos.distanceTo(prevPos2);
				const h = this.elementHeight;
				zoomRatio = h / (h + dNow - dPrev);
			}
		}
		return {
			mainTouch,
			secondTouch,
			deltaTime,
			movement1,
			movement2,
			prevPos1: prevPos,
			prevPos2,
			type,
			rotationAngle: rotAngle,
			ratio: zoomRatio,
		};
	}

	/**
	 * Handles a double touch.
	 *
	 * @param ev The touch event
	 */
	private onDoubleTouch(ev: TouchEvent): void {
		const mainTouchId = ev.changedTouches[0].identifier;
		const secondTouchId =
			ev.changedTouches.length > 1 ? ev.changedTouches[1].identifier : this.getOtherPointer(mainTouchId);
		const mainTouch = this.getCoords(mainTouchId);
		const secondTouch = this.getCoords(secondTouchId);
		const doubleTouch = this.computeDoubleTouch(mainTouch, secondTouch);
		this.doubleTouchMoved.emit(doubleTouch);
		switch (doubleTouch.type) {
			case DoubleTouchGesture.Translate:
				this.doubleTouchTranslated.emit(doubleTouch);
				break;
			case DoubleTouchGesture.Rotate:
				this.doubleTouchRotated.emit(doubleTouch);
				break;
			case DoubleTouchGesture.PinchAndZoom:
				this.pinchAndZoomed.emit(doubleTouch);
				break;
		}
	}

	/**
	 *
	 * @param ev The touch event that started
	 */
	private onTouchStart(ev: TouchEvent): void {
		if (!this.#element) return;
		this.#element.focus();
		// The next line cannot be 'for(const touch of ev.changedTouches)', because ev.changedTouches does not support the iterator.
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let t = 0; t < ev.changedTouches.length; ++t) {
			const touch = ev.changedTouches[t];
			this.startTrackingPointer(touch);
			const pp = this.getCoords(touch.identifier);
			this.touchStarted.emit(pp);
		}

		if (ev.changedTouches.length === 1) {
			// Start tracking a long press
			this.#longPressTimer = setTimeout(() => this.onLongPress(ev.changedTouches[0]), LONG_PRESS_MS);
		} else {
			// Multi-touch, cancel long press timer
			clearTimeout(this.#longPressTimer);
			this.#longPressTimer = undefined;
		}
	}

	/**
	 * The user taps on the same point for a long time.
	 *
	 * @param touch The initial touch which initiated the press.
	 */
	private onLongPress(touch: Touch): void {
		const coords = this.getCoords(touch.identifier);
		this.longPress.emit(coords);
	}

	/**
	 *
	 * @param ev The touch event that signaled the moving pointer
	 */
	private onTouchMove(ev: TouchEvent): void {
		ev.preventDefault();
		// The next line cannot be 'for(const touch of ev.changedTouches)', because ev.changedTouches does not support the iterator.
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let t = 0; t < ev.changedTouches.length; ++t) {
			const touch = ev.changedTouches[t];
			// Below, we call 'trackPointer' with a 'true' flag in the end.
			// The reason is that on Android Firefox the touch sensitivity is low and many events
			// appear in the 'changedTouches' list with the same position as the previous touch move event,
			// resulting in a zero speed and in the impossibility of detecting the movement vector of the touch.
			// That is why we set 'discardDuplicates' to 'true' below, so that all our moving touches always have
			// a meaningful movement and the gesture can be correctly classified.
			this.trackPointer(touch, true);
		}
		switch (this.pointersCount) {
			case 0:
				return;
			case 1:
				this.singleTouchMoved.emit(this.getCoords(this.#pointers[0]));
				break;
			case 2:
				this.onDoubleTouch(ev);
				break;
			default:
				console.warn("Gestures with three or more touches are not supported.");
				break;
		}

		// A long tap should not contain movement
		clearTimeout(this.#longPressTimer);
		this.#longPressTimer = undefined;
	}

	/**
	 *
	 * @param ev The pointer up event
	 */
	private onTouchEnd(ev: TouchEvent): void {
		// The next line cannot be 'for(const touch of ev.changedTouches)', because ev.changedTouches does not support the iterator.
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let t = 0; t < ev.changedTouches.length; ++t) {
			const touch = ev.changedTouches[t];
			if (this.trackPointer(touch)) {
				const pp = this.getCoords(touch.identifier);
				this.touchEnded.emit(pp);
				this.removePointer(touch.identifier);
				const isTap = pp.timestamp - pp.startTimestamp < MAX_SECONDS_FOR_TAP;
				if (isTap) {
					if (pp.timestamp - this.#lastTapTimestamp < MAX_SECONDS_FOR_DOUBLE_TAP) {
						this.doubleTap.emit(pp);
					} else {
						this.#lastTapTimestamp = pp.timestamp;
						this.tapGesture.emit(pp);
					}
				}
			}
		}

		// Stop tracking long press
		clearTimeout(this.#longPressTimer);
		this.#longPressTimer = undefined;
	}

	/**
	 *
	 * @param ev The touch event
	 */
	private onTouchCancel(ev: TouchEvent): void {
		// The next line cannot be 'for(const touch of ev.changedTouches)', because ev.changedTouches does not support the iterator.
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let t = 0; t < ev.changedTouches.length; ++t) {
			const touch = ev.changedTouches[t];
			this.removePointer(touch.identifier);
		}
	}

	/**
	 *
	 * @param ev The mouse down event
	 */
	private onMouseDown(ev: PointerEvent): void {
		if (!this.#element) return;
		if (ev.pointerType !== "mouse") return;
		// Let's track this mouse button
		this.#mouseButtonsTracked.push(ev.button);
		ev.preventDefault();
		this.#element.focus();
		this.mouseCoords.trackAt(ev, this.#clock.getElapsedTime(), false);
		// If the event is from a mouse, we store in 'startTimestamp' the
		// time at which the mouse click/drag started.
		this.mouseCoords.startTimestamp = this.mouseCoords.timestamp;
		this.mousePressed.emit(ev);
	}

	/**
	 *
	 * @param ev The mouse move event
	 */
	private onMouseMove(ev: PointerEvent): void {
		if (!this.#element) return;
		if (ev.pointerType !== "mouse") return;
		ev.preventDefault();
		this.mouseCoords.trackAt(ev, this.#clock.getElapsedTime(), false);
		this.mouseMoved.emit(ev);
		for (const b of this.#mouseButtonsTracked) {
			// To understand how to check which mouse buttons are being dragged,
			// refer to the MouseEvent.buttons documentation:
			// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
			let bmask = 0;
			switch (b) {
				case MOUSE.LEFT:
					bmask = 1;
					break;
				case MOUSE.MIDDLE:
					bmask = 4;
					break;
				case MOUSE.RIGHT:
					bmask = 2;
					break;
			}
			if (ev.buttons & bmask) {
				// The mouse is being moved with some buttons pressed that we captured in the mouse pressed event
				this.#element.setPointerCapture(ev.pointerId);
				this.mouseDragged.emit(ev);
				return;
			}
		}
	}

	/**
	 *
	 * @param ev The mouse up event
	 */
	private onMouseUp(ev: PointerEvent): void {
		if (ev.pointerType !== "mouse") return;
		this.#element?.releasePointerCapture(ev.pointerId);
		// Remove the lifted up button from the list of tracked mouse buttons
		const buttonIndex = this.#mouseButtonsTracked.indexOf(ev.button);
		if (buttonIndex !== -1) {
			this.#mouseButtonsTracked.splice(buttonIndex, 1);
		}
		ev.preventDefault();
		const now = this.#clock.getElapsedTime();
		this.mouseCoords.trackAt(ev, now, false);
		this.mouseReleased.emit(ev);
		const isClick = this.mouseCoords.timestamp - this.mouseCoords.startTimestamp < MAX_SECONDS_FOR_TAP;
		if (isClick && this.mouseCoords.lastPosition.equals(this.mouseCoords.position)) {
			// We have a mouse click. Below we distinguish between single and doubleclck.
			if (now - this.#lastTapTimestamp < MAX_SECONDS_FOR_DOUBLE_TAP) {
				this.mouseDoubleClicked.emit(ev);
			} else {
				this.#lastTapTimestamp = now;
				this.mouseSingleClicked.emit(ev);
			}
		}
	}

	/**
	 * @param ev the wheel event
	 */
	private onWheel(ev: WheelEvent): void {
		ev.preventDefault();
		this.mouseWheel.emit(ev);
	}

	/**
	 * Remove all connections so this class can be collected
	 */
	dispose(): void {
		this.enabled = false;
	}

	/**
	 * Enables / disables this controller
	 */
	set enabled(val: boolean) {
		if (this.#enabled === val) {
			return;
		}
		this.#enabled = val;
		this.updateActiveStatus();
	}

	/** @returns true if this controller is enabled */
	get enabled(): boolean {
		return this.#enabled;
	}

	/**
	 * Connect or disconnect events based on the attached element and the enabled status
	 */
	private updateActiveStatus(): void {
		if (!this.#disposer && this.#element && this.#enabled) {
			// If we're not connected but we have an element and enabled is true then connect all events
			const element = this.#element;
			// There is a reason why we do not use the PointerEvent interface for everything but we use it only
			// for the mouse handling. The reason is that on Android Firefox the PointerEvent interface does not
			// work to detect multitouch gestures. See the open bug at this link:
			// https://bugzilla.mozilla.org/show_bug.cgi?id=1315250
			// Also, we prefer PointerEvent to MouseEvent for the mouse because in this way we track mouse drag
			// actions even outside the canvas or outside the browser.
			element.addEventListener("pointerdown", this.onMouseDown);
			element.addEventListener("pointerup", this.onMouseUp);
			element.addEventListener("pointermove", this.onMouseMove);
			element.addEventListener("wheel", this.onWheel);
			element.addEventListener("touchstart", this.onTouchStart);
			element.addEventListener("touchmove", this.onTouchMove);
			element.addEventListener("touchend", this.onTouchEnd);
			element.addEventListener("touchcancel", this.onTouchCancel);
			element.addEventListener("contextmenu", this.onContextMenu);
			// Store all removeEventListener for later as we may not have an element reference when we disconnect
			this.#disposer = () => {
				element.removeEventListener("pointerdown", this.onMouseDown);
				element.removeEventListener("pointerup", this.onMouseUp);
				element.removeEventListener("pointermove", this.onMouseMove);
				element.removeEventListener("wheel", this.onWheel);
				element.removeEventListener("touchstart", this.onTouchStart);
				element.removeEventListener("touchmove", this.onTouchMove);
				element.removeEventListener("touchend", this.onTouchEnd);
				element.removeEventListener("touchcancel", this.onTouchCancel);
				element.removeEventListener("contextmenu", this.onContextMenu);
			};
		} else if (this.#disposer !== undefined && (!this.#element || !this.#enabled)) {
			// If we're connected but enabled is false or we detached from the element then remove all connections
			this.#disposer();
			this.#disposer = undefined;
		}
	}

	/** @returns the HTML canvas element of which the mouse and touch events are being listened. */
	get element(): HTMLElement | undefined {
		return this.#element;
	}
}
