/**
 * In this file numeric constants are created to define a precise
 * priority of events to be used in R3F's useFrame hook.
 *
 * This hook will execute all the callbacks starting from the lowest-numbered
 * priority to the highest. Priorities higher than 0 will result in overriding the
 * rendering call.
 *
 * The constants below, used correctly, are a means to guarantee that every
 * time 'useFrame' is used, it is called with the correct priority. It should
 * never happen, for example, that a LOD structure visibility nodes are computed
 * before the viewing camera is updated. Instead, the correct sequence of events
 * should be the following:
 *
 * * camera is updated by control classes
 * * camera monitor classes check for camera movement
 * * visibility nodes are recomputed in LOD structures
 * * custom rendering functions (e.g. inside effect pipelines) are called.
 */

/**
 * useFrame hooks with this priority will update the controls or the animations.
 * Updating the controls typically change the camera pose and/or projection according
 * to the mouse/key/touch events received. Updating the animations typically change the
 * camera pose/projection according to the progress of a given animation
 */
export const UPDATE_CONTROLS_PRIORITY = -10;

/**
 * useFrame hooks with this priority will be called typically in split-view or multiple-view
 * scenarios, to ensure that a secondary camera is synced (has same pose/rotation/projection/etc)
 * with a main view camera.
 */
export const CAMERA_SYNC_PRIORITY = -8;

/**
 * useFrame hooks with this priority typically update Lotv.CamerMonitor objects or
 * similar. Purpose of the CameraMonitor is to keep track whether the camera started
 * moving, continued moving, stopped, or continued to be stopped with respect to the
 * previous frame
 */
export const UPDATE_CAMERA_MONITOR_PRIORITY = -5;

/**
 * useFrame hooks with this priority can assume that the camera pose and
 * projection has already been updated and therefore can safely update the
 * LOD visible elements, for example the lod pano tiles, the lod mesh tiles,
 * or the lod point cloud nodes.
 */
export const UPDATE_LOD_STRUCTURES_PRIORITY = -1;

/** The code in this useFrame hook will override react three fiber's rendering call. */
export const OVERRIDE_RENDERING_CALL = 1;
