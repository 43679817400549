import { Box2, DataTexture, Texture } from "three";
import { NodeState } from "./LodTree";
import { DefaultVisibleTilesStrategy, VisibleTilesStrategy } from "./VisibleTilesStrategy";

/**
 * Status of a node fetch
 */
export type ImageNodeFetch = {
	/** Wait for the image to arrive */
	image(): Promise<Texture>;
	/** Abort this fetch, image() promise will be rejected */
	abort(): void;
};

/**
 * Properties of a node
 */
export type ImageNodeOptions<Source = unknown> = {
	/** Url of the node image */
	source: Source;
	/** 2D bounding box of the tile */
	rect: Box2;
};

/**
 * Class describing an image tree node
 */
export class ImageTreeNode<Source = unknown> {
	image?: Texture;
	children?: Array<ImageTreeNode<Source>>;
	depth = 0;
	state = NodeState.NotInUse;
	/**
	 * @param source of this image (Eg. url or signed url data)
	 * @param rect the rect covered in uv coordinates (col, row, width, height)
	 * @param tree the tree that this node is a part of
	 * @param id the id of this node in the tree
	 * @param parent node that this node belongs under, undefined if root node
	 */
	constructor(
		public source: Source,
		public rect: Box2,
		public tree: ImageTree<Source>,
		public id: number,
		public parent?: ImageTreeNode<Source>,
	) {
		this.depth = (this.parent?.depth ?? -1) + 1;
	}
}

/**
 * The abstract concept of an ImageTree, a data structure used for image
 * tiles organization and fetching. It is inherited by Img360LodTree,
 * FloorPlanTree, HoloTree
 */
export abstract class ImageTree<Source = unknown> {
	visibleTilesStrategy: VisibleTilesStrategy = new DefaultVisibleTilesStrategy();
	maxDepth = 0;
	/** The width in pixels of the full resolution of the tree */
	abstract width: number;
	/** The height in pixels of the full resolution of the tree */
	abstract height: number;

	protected nodesList = new Array<ImageTreeNode<Source>>();
	#nextNodeId = 0;

	#rootNodes: number[] = [];

	/**
	 * add child nodes
	 *
	 * @param parent The parent node
	 * @param options The list of URLs/rects that describe the children of this node
	 */
	appendChildren(
		parent: number | ImageTreeNode<Source> | undefined,
		...options: Array<ImageNodeOptions<Source>>
	): void {
		if (typeof parent === "number") {
			parent = this.getNode(parent);
		}
		if (parent && !parent.children) {
			parent.children = new Array<ImageTreeNode<Source>>();
		}
		for (const o of options) {
			const node = new ImageTreeNode<Source>(o.source, o.rect, this, this.#nextNodeId++, parent);
			this.nodesList.push(node);
			parent?.children?.push(node);
			if (node.depth > this.maxDepth) this.maxDepth = node.depth;
			if (!parent) this.#rootNodes.push(this.nodesList.length - 1);
		}
	}

	/**
	 * @returns the number of nodes in this tree
	 */
	get nodeCount(): number {
		return this.nodesList.length;
	}

	/**
	 * Get the node with a specific id
	 *
	 * @param id The id of the node
	 * @returns the node
	 */
	getNode(id: number): ImageTreeNode<Source> {
		return this.nodesList[id];
	}

	/**
	 * Gets the image from a node
	 *
	 * @param index The node index to fetch the image for
	 */
	abstract getNodeImage(index: number | ImageTreeNode<Source>): Promise<ImageNodeFetch>;

	/**
	 * @returns A texture by combining all textures from a specific level of the tree
	 * @param pixelWidth The width in pixels of each tile
	 * @param pixelHeight The height in pixels of each tile
	 * @param dimX The number of textures in the X direction
	 * @param dimY The number of textures in the Y direction
	 * @param sources The list of source elements that will be used to generate the texture
	 */
	static async computeOverviewTexture(
		pixelWidth: number,
		pixelHeight: number,
		dimX: number,
		dimY: number,
		sources: Array<{ x: number; y: number; source: string }>,
	): Promise<Texture> {
		const CHANNELS = 4;

		const width = pixelWidth * dimX;
		const height = pixelHeight * dimY;

		const image = new Uint8Array(width * height * CHANNELS);
		for (let i = 0; i < width * height; ++i) {
			image[4 * i] = 255;
			image[4 * i + 1] = 255;
			image[4 * i + 2] = 255;
			image[4 * i + 3] = 0;
		}

		const textures = await Promise.all(
			sources.map((source) => getRawTexture(pixelWidth, pixelHeight, source.source)),
		);
		for (let i = 0; i < sources.length; ++i) {
			const source = sources[i];
			const texture = textures[i];

			if (!texture) continue;

			const xIndex = pixelWidth * CHANNELS * source.x;
			const yIndex = pixelHeight * source.y;

			for (let x = 0; x < pixelWidth * CHANNELS; ++x) {
				for (let y = 0; y < pixelHeight; ++y) {
					image[width * CHANNELS * (height - y - yIndex) + (x + xIndex)] =
						texture[pixelWidth * CHANNELS * y + x];
				}
			}
		}
		return new DataTexture(image, width, height);
	}

	/** @returns All nodes at level 0 */
	get rootNodes(): number[] {
		return this.#rootNodes;
	}
}

/**
 * @returns The data of an image as a Uint8ClampedArray
 * @param width Width of the texture in pixels
 * @param height Height of the textre in pixels
 * @param url The address pointing to the image
 */
async function getRawTexture(width: number, height: number, url: string): Promise<Uint8ClampedArray | undefined> {
	// create off-screen canvas element
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;
	const ctx = canvas.getContext("2d");
	if (!ctx) return;

	const img = new Image();
	img.crossOrigin = "anonymous";
	await new Promise((resolve, reject) => {
		img.onload = resolve;
		img.onerror = reject;
		img.src = url;
	});
	ctx.drawImage(img, 0, 0);
	const { data } = ctx.getImageData(0, 0, width, height);
	canvas.remove();
	return data;
}
