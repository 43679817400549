import {
  ExternalMarkupIElement,
  GUID,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddExternalMarkup extends BaseMutation {
  /** New external markup element */
  newElement: CreateIElement<ExternalMarkupIElement>;
}

export interface CreateMutationAddExternalMarkup {
  /** ID of the element to be created */
  id: GUID;

  /** The type of the external markup to create */
  type: ExternalMarkupIElement["type"];

  /** The ID of the markup created on the third-party service */
  externalIssueId: GUID;

  /** The Annotation object (Model3D, MarkupPolygon, MeasurePolygon) to attach the Markup to */
  parentId: GUID;

  /** ID of the root element */
  rootId: GUID;

  /** The IDs of the children elements */
  childrenIds?: GUID[] | null;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;
}

/**
 * Adds a new markup instance to an existing Model3d node.
 *
 *```
 * Model3d/Img2d (Node)
 * └ MarkupBim360/MarkupProcoreRfi/MarkupProcoreObservation/MarkupAccIssue/MarkupAccRfi (command)
 * ```
 *
 * @returns A MutationAddExternalMarkup object to create an external markup
 */
export function createMutationAddExternalMarkup({
  id,
  type,
  externalIssueId,
  rootId,
  parentId,
  childrenIds,
  createdAt = new Date().toISOString(),
}: CreateMutationAddExternalMarkup): MutationAddExternalMarkup {
  const newElement: CreateIElement<ExternalMarkupIElement> = {
    id,
    type,
    // The real name will be get when data will be fetched
    name: externalIssueId,
    externalIssueId,
    parentId,
    rootId,
    childrenIds:
      childrenIds !== undefined &&
      childrenIds !== null &&
      childrenIds.length > 0
        ? childrenIds
        : null,
    createdAt,
    typeHint: IElementTypeHint.command,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddExternalMarkup, parentId),
    newElement,
  };
}
