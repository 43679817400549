/**
 * The (known) error codes for failing background tasks coming from the point cloud stream generator.
 *
 * See https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3539435793/Error+Codes+Overview.
 */
export enum PCStreamGeneratorErrorCode {
  /**
   * Error that cannot be mapped to any of the other cases.
   * This error is (almost) never related to the input.
   * Indicates some problem with the system/infrastructure.
   */
  pcsgInternal = "PCSG_Internal",

  /**
   * The axis aligned bounding box of the point cloud is larger than currenty supported.
   * Maximum bounding box edge length supported is around 200km.
   * This could happen if the point cloud coordinates are in mm (or smaller) instead of meters as expected.
   */
  pcsgBoundingBoxTooLarge = "PCSG_BoundingBoxTooLarge",

  /**
   * The point cloud 's axis aligned bounding box edges should be non zero.
   * This could happen if the point cloud contains one or no points.
   * Could also happen if all points lie exactly on the same plane orthogonal to an axis.
   */
  pcsgZeroVolumeBoundingBox = "PCSG_ZeroVolumeBoundingBox",

  /**
   * There was an error caused by some project API call.
   * There’s a high chance that the user changed something in their project which caused the workflow to break.
   * Retrying the upload might fix the error, but highly unlikely.
   *
   * Known examples that cause the error:
   * - User deletes the point cloud node while it’s being processed by the point cloud stream generator.
   */
  pcsgUnexpectedProjectState = "PCSG_UnexpectedProjectState",

  /**
   * Conversion failed for reasons related to the input file, but none of the ones listed in the other error codes.
   * Retrying the upload will never succeed.
   */
  pcsgConversionInternal = "PCSG_ConversionInternal",
}

/**
 * @returns Wether or not the provided string is a valid point cloud stream generator error code
 * @param errorCode String to check
 */
export function isPCStreamGeneratorErrorCode(
  errorCode?: string,
): errorCode is PCStreamGeneratorErrorCode {
  return (
    !!errorCode &&
    Object.values<string>(PCStreamGeneratorErrorCode).includes(errorCode)
  );
}

// TODO: Allow user to contact support (https://faro01.atlassian.net/browse/SWEB-2418)
/**
 * Convert an error code to a message for the user.
 *
 * @param taskName Name of the task, which will be rendered for some messages.
 * @param errorCode The error code to generate an error message for.
 *   If not given, a generic message will be shown.
 * @returns A message explaining the error to the user.
 */
export function pcsgTaskErrorToUserMessage(
  taskName: string,
  errorCode?: PCStreamGeneratorErrorCode,
): string {
  switch (errorCode) {
    case undefined:
      // Missing or unknown error code
      return `The processing of ${taskName} failed. Please, try to import the file with a different file format.`;
    case PCStreamGeneratorErrorCode.pcsgBoundingBoxTooLarge:
      return `The ${taskName} dimensions exceed the maximum size. Please ensure that the point cloud is using meters as a unit of measure.`;
    case PCStreamGeneratorErrorCode.pcsgConversionInternal:
      return "An internal error happened during the conversion. Please try another format, if the error persists contact us.";
    case PCStreamGeneratorErrorCode.pcsgInternal:
      return "An internal error happened. Please try again later, if the error persists contact us.";
    case PCStreamGeneratorErrorCode.pcsgUnexpectedProjectState:
      return "Unexpected project state. The project changed during the processing. Please upload again your file.";
    case PCStreamGeneratorErrorCode.pcsgZeroVolumeBoundingBox:
      return `The processing of ${taskName} failed. The system could not find any points in the uploaded file`;
  }
}
