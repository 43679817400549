import { useEffect } from "react";
import RotationCursor from "../icons/cursor-rotate.svg";
import ScaleCursor from "../icons/cursor-scale.svg";
import TranslationCursor from "../icons/cursor-translate.svg";

/** The size as specified in the SVG images. */
const CURSOR_ICON_SIZE = 24;
/** Center the icon on the cursor. */
const CURSOR_COORDINATES = `${CURSOR_ICON_SIZE / 2} ${CURSOR_ICON_SIZE / 2}`;

/**
 * An enum to decide the cursor style based on the hover and operation
 */
export enum CursorStyle {
  /** Initial state */
  default = "Default",
  /** Cursor style to indicate a click operation */
  pointer = "Pointer",
  /** Cursor style to indicate a rotation operation */
  rotation = "Rotation",
  /** Cursor style to indicate a translation operation */
  translation = "Translation",
  /** Cursor style to indicate a scale operation */
  scale = "Scale",
}

export interface UseCustomCursorProps {
  /**
   * Which custom cursor to use
   */
  cursorToShow: CursorStyle;

  /**
   * Whether to enable the custom cursor. Otherwise no cursor change is made.
   *
   * @default true
   */
  enable?: boolean;

  /**
   * Which element to apply the cursor to.
   *
   * @default document.body
   */
  domElement?: HTMLElement;
}

/**
 * Hook to override the cursor on a dom-element with one of the predefined styles.
 */
export function useCustomCursor({
  enable = true,
  cursorToShow,
  domElement = document.body,
}: UseCustomCursorProps): void {
  useEffect(() => {
    if (enable) {
      const oldCursor = domElement.style.cursor;
      switch (cursorToShow) {
        case CursorStyle.rotation:
          domElement.style.cursor = `url("${RotationCursor}") ${CURSOR_COORDINATES}, auto`;
          break;
        case CursorStyle.translation:
          domElement.style.cursor = `url("${TranslationCursor}") ${CURSOR_COORDINATES}, auto`;
          break;
        case CursorStyle.scale:
          domElement.style.cursor = `url("${ScaleCursor}") ${CURSOR_COORDINATES}, auto`;
          break;
        case CursorStyle.pointer:
          domElement.style.cursor = "pointer";
          break;
        case CursorStyle.default:
          domElement.style.cursor = "default";
      }

      return () => {
        domElement.style.cursor = oldCursor;
      };
    }
  }, [domElement, enable, cursorToShow]);
}
