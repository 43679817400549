import { Color, GLSL3, RawShaderMaterial, Texture } from "three";
import vert from "../Shaders/TexturedQuadRaw.vert";
import frag from "../Shaders/TomographicComposeAdaptive.frag";
import { makeUniform } from "./Uniforms";

/** This color is a FARO blue */
export const TOMOGRAPHIC_COLOR = 0x3455db;
/** Minimum opacity that an edge feature has. */
export const TOMOGRAPHIC_OPACITY = 0.3;
/**
 * We use a bias coefficient to discard annoying small edges in the tomographic point cloud
 * that come from moire effects in point rendering.
 */
export const TOMOGRAPHIC_POINT_COUNT_BIAS = 0.6;
// By how many standard deviations the point count must be higher than the neighors'average
// to be a feature.
const DEVIATION_FROM_AVERAGE = 0.2;
// We reject some outliers when computing the average point count. This allows us to have
// ticker edges.
const OUTLIER_REJECTION_FACTOR = 1.0;

/**
 * A shader that composes a fbo with a point cloud rendered with additive blending
 * with the opaque scene. While composing the blended tomographic cloud,
 * care is taken to detect the tomographic cloud edges with an adaptive approach,
 * because no single hard threshold accomodates all possible models.
 */
export class TomographicComposeAdaptiveMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;

	override uniforms = {
		inputColorTex: makeUniform<Texture | null>(null),
		avgCountTex: makeUniform<Texture | null>(null),
		opaqueColorTex: makeUniform<Texture | null>(null),
		opaqueDepthTex: makeUniform<Texture | null>(null),
		deviationFromAverage: makeUniform(DEVIATION_FROM_AVERAGE),
		opacity: makeUniform(TOMOGRAPHIC_OPACITY),
		bias: makeUniform(TOMOGRAPHIC_POINT_COUNT_BIAS),
		tomoColor: makeUniform(new Color(TOMOGRAPHIC_COLOR)),
		outlierRejectionFactor: makeUniform(OUTLIER_REJECTION_FACTOR),
	};

	constructor() {
		super({ glslVersion: GLSL3 });
	}
}
