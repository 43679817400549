import {
  Color as ColorProp,
  MeshProps,
  Vector2 as Vector2Prop,
} from "@react-three/fiber";
import { useMemo, useRef } from "react";
import { DoubleSide, Mesh, PlaneGeometry, ShaderMaterial } from "three";
import { parseColor, parseVector2 } from "../utils/props";

/** Name assigned to the GridPlane object in the scene graph */
export const GRID_PLANE_NAME = "GridPlane";

const VERY_LARGE_EXTENT = 4000;

const VERTEX_SHADER = `
  varying vec3 worldPosition;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    worldPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const FRAGMENT_SHADER = `
  uniform float modulo;
  uniform float moduloNextLevel;
  uniform float gridRadiusFromCamera;
  uniform vec4 backColor;
  uniform vec4 moduloColor;
  uniform vec4 borderColor;
  uniform float borderWidth;
  uniform float aspectRatio;
  varying vec3 worldPosition;
  varying vec2 vUv;

  float getGrid(vec2 xyTestPos, float size)
  {
    vec2 r = xyTestPos / size;
    vec2 grid = abs(fract(r - 0.5) - 0.5) / fwidth(r);
    float line = min(grid.x, grid.y);
    return 1.0 - min(line, 1.0);
  }

  vec4 getGridColor(vec3 xyzTestPos)
  {
    float d = 1.0 - min(distance(cameraPosition.xz, xyzTestPos.xy) / gridRadiusFromCamera, 1.0);
    float g1 = getGrid(xyzTestPos.xy, modulo);
    float g2 = getGrid(xyzTestPos.xy, moduloNextLevel);
    float blend = mix(g2, g1, g1*g1);
    vec4 lineColor = vec4(moduloColor.rgb, moduloColor.a);
    float borderX = borderWidth*fwidth(xyzTestPos.x);
    float borderY = borderX/aspectRatio;
    if(vUv.x < borderX || vUv.x > 1.0-borderX || vUv.y < borderY || vUv.y > 1.0-borderY ) return borderColor;
    return mix(backColor, lineColor, blend);
  }

  void main() {
    gl_FragColor = getGridColor(worldPosition);
  }
`;

type GridProps = MeshProps & {
  /** The color for the grid background (the spaced between the lines) */
  backgroundColor?: ColorProp;
  /** The opacity for the grid background (the spaced between the lines) 0-1*/
  backgroundOpacity?: number;
  /** The color for the grid lines */
  lineColor?: ColorProp;
  /** The opacity of the grid lines 0-1 */
  lineOpacity?: number;
  /** The color of the border around the whole sheet */
  borderColor?: ColorProp;
  /** The thickness of the border in meters */
  borderWidth?: number;
  /** The size of the sheet in meters */
  size?: Vector2Prop;
};

/**
 * R3F component to display plane with a dynamic meter spaced grid pattern.
 *
 * @returns JSX of grid
 */
export function GridPlane({
  backgroundColor = 0xcccccc,
  backgroundOpacity = 0.5,
  lineColor = 0x222222,
  lineOpacity = 0.5,
  borderColor = 0x000000,
  borderWidth = 0.1,
  size = [VERY_LARGE_EXTENT, VERY_LARGE_EXTENT],
  ...rest
}: GridProps): JSX.Element {
  const mesh = useRef<Mesh<PlaneGeometry, ShaderMaterial>>(null);

  const shaderParameter = useMemo(() => {
    const sz = parseVector2(size);
    return {
      uniforms: {
        modulo: { value: 1 },
        moduloNextLevel: { value: 10 },
        gridRadiusFromCamera: { value: 200 },
        backColor: {
          value: [...parseColor(backgroundColor).toArray(), backgroundOpacity],
        },
        moduloColor: {
          value: [...parseColor(lineColor).toArray(), lineOpacity],
        },
        aspectRatio: {
          value: sz.y / sz.x,
        },
        borderWidth: {
          value: borderWidth,
        },
        borderColor: {
          value: [...parseColor(borderColor).toArray(), lineOpacity],
        },
      },
      fragmentShader: FRAGMENT_SHADER,
      vertexShader: VERTEX_SHADER,
      depthWrite: false,
      transparent: true,
      side: DoubleSide,
    };
  }, [
    backgroundColor,
    backgroundOpacity,
    borderColor,
    borderWidth,
    lineColor,
    lineOpacity,
    size,
  ]);

  return (
    <group>
      <mesh {...rest} ref={mesh} name={GRID_PLANE_NAME}>
        <planeGeometry args={parseVector2(size).toArray()} />
        <shaderMaterial {...shaderParameter} />
      </mesh>
    </group>
  );
}
