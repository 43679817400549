import { generateGUID, getFileExtension } from "@faro-lotv/foundation";
import { Stack } from "@mui/material";
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useToast } from "../../../toast";
import { resizeImage } from "../../../utils";
import { cyan, neutral } from "../../colors";
import { DropHandler } from "../../drop-handler/drop-handler";
import {
  FileDetails,
  FileDetailsList,
} from "../../horizontal-scroll-list/file-details-list";
import { PlusCircleIcon } from "../../icons";
import { FaroText } from "../../text/faro-text/faro-text";

export type FileAttachmentsListProps = {
  /** List of attachments related to the annotation */
  attachments: FileDetails[];

  /** Function to add a new attachment to the attachments list */
  addNewAttachment(attachment: FileDetails, viaDrag: boolean): void;

  /** Callback executed when the user opens an attachment */
  onAttachmentOpened?(file: string): void;
};

/**
 * @returns UI for managing a list of attachments
 */
export function FileAttachmentsList({
  attachments,
  addNewAttachment,
  onAttachmentOpened,
}: FileAttachmentsListProps): JSX.Element | null {
  const [addedFile, setAddedFile] = useState<{
    file: File;
    viaDrag: boolean;
  }>();
  const inputElRef = useRef<HTMLInputElement>(null);

  const openFileExplorer = useCallback(() => {
    inputElRef.current?.click();
  }, []);

  useEffect(() => {
    async function addAttachment(): Promise<void> {
      if (!addedFile) return;
      const { file, viaDrag } = addedFile;
      const thumbnailUrl = await resizeImage(file).catch(() => null);
      addNewAttachment(
        {
          id: generateGUID(),
          date: new Date().toISOString(),
          fileName: file.name,
          fileSize: file.size,
          urlOrFile: file,
          thumbnailUrl,
        },
        viaDrag,
      );

      setAddedFile(undefined);
    }
    addAttachment();
  }, [addedFile, addNewAttachment]);

  const { openToast } = useToast();
  const setFileIfValid = useCallback(
    (file: File, viaDrag: boolean) => {
      if (!isValidFileExtension(file)) {
        openToast({
          title: "Failed to import file",
          message: "The file extension is not supported.",
          variant: "error",
        });
        return;
      } else if (file.size > Math.pow(1024, 3)) {
        openToast({
          title: "File is too large",
          message: "The maximum size allowed is 1GB.",
          variant: "error",
        });
        return;
      }
      setAddedFile({ file, viaDrag });
    },
    [openToast],
  );

  const setFileFromDrop = useCallback(
    (e: DragEvent<HTMLElement>) => {
      e.preventDefault();
      // Allow only one file to be dropped
      if (e.dataTransfer.files.length !== 1) return;

      setFileIfValid(e.dataTransfer.files[0], true);
    },
    [setFileIfValid],
  );

  const hasAttachments = !!attachments.length;

  return (
    <Stack direction="row">
      <DropHandler
        sx={{
          width: hasAttachments ? CARD_WIDTH : "100%",
          height: CARD_HEIGHT,
          mr: hasAttachments ? 2 : undefined,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
        variant="dark"
        onClick={openFileExplorer}
        onDrop={setFileFromDrop}
        canBeDropped={(e) => e.dataTransfer.items.length === 1}
      >
        <input
          ref={inputElRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (!file) {
              return;
            }
            setFileIfValid(file, false);
          }}
          multiple={false}
          accept={INPUT_ACCEPT}
        />
        <Stack
          sx={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hasAttachments ? (
            <>
              <PlusCircleIcon
                sx={{ color: neutral[900], width: "40px", height: "40px" }}
              />
              <FaroText variant="buttonM">Add more</FaroText>
            </>
          ) : (
            <FaroText
              variant="bodyM"
              sx={{
                color: neutral[100],
                maxWidth: "140px",
                textAlign: "center",
              }}
            >
              Drag your file here or click to
              <span style={{ color: cyan[500] }}> select it</span>
            </FaroText>
          )}
        </Stack>
      </DropHandler>
      {hasAttachments && (
        <FileDetailsList
          // This is allowed since the dialog has a fixed width
          wrapperSx={{ width: "330px" }}
          sx={{ p: 0, pb: 1 }}
          files={attachments}
          shouldShowButtons={false}
          deleteTooltipText="Remove attachment"
          onAttachmentOpened={onAttachmentOpened}
        />
      )}
    </Stack>
  );
}

// Dimensions of the file cards. Hardcoded to provide a stable layout when the scrollbar state changes.
const CARD_HEIGHT = "134px";
const CARD_WIDTH = "150px";

const FILE_EXTENSIONS_WHITE_LIST = [
  // Documents
  "csv",
  "docx",
  "pdf",
  "pptx",
  "txt",
  "vcax",
  "xlsx",
  // Images
  "bmp",
  "gif",
  "jfif",
  "jpeg",
  "jpg",
  "png",
  "tif",
  "tiff",
  // Video
  "avi",
  "webm",
  "mov",
  "mp4",
  // 3D Models
  "dwg",
  "fls",
  "ifc",
  "jt",
  "obj",
  "ply",
  "ptx",
  "rvt",
  "sab",
  "sat",
  "step",
  "stp",
  // Point Cloud formats
  "cpe",
  "dxf",
  "e57",
  "igs",
  "las",
  "laz",
  "pod",
  "pts",
  "pxy",
  "rcp",
  "rcs",
  "swp",
  "wrl",
  "xyz",
  "xyb",
  // Archives
  "7z",
  "rar",
  "zip",
  // Misc
  "kmz",
  "woff",
  "wmz",
].sort();

const INPUT_ACCEPT = FILE_EXTENSIONS_WHITE_LIST.reduce(
  (prev, next) => (prev ? `${prev}, .${next}` : `.${next}`),
  "",
);

/**
 * @returns true if the file has a supported format
 * @param file The file to check
 */
function isValidFileExtension(file: File): boolean {
  const extension = getFileExtension(file.name)?.toLowerCase();
  return !!extension && FILE_EXTENSIONS_WHITE_LIST.includes(extension);
}
