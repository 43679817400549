import { GUID, generateGUID } from "@faro-lotv/foundation";
import {
  IElementPointCloudE57,
  IElementType,
  IElementWithFileUri,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationGenerateFloorplansForPointCloud extends BaseMutation {
  /** New area section element */
  newElement: CreateIElement<IElementPointCloudE57>;
}

interface CreateMutationGenerateFloorplansForPointCloud
  extends Pick<
    IElementWithFileUri,
    "uri" | "md5Hash" | "fileName" | "fileSize"
  > {
  /** Name of the area being created */
  name: string;

  /** ID of the root element */
  rootId: GUID;

  /**
   * ID of the layer section to create the point cloud in
   */
  layerId: GUID;
}

/**
 * Adds a new PointCloudE57 to a layer section and calls the Captur3d Api, to create a Floor Plan.
 * The layer section must not contain another PointCloudE57 This mutation will result in the following structure:
 *
 *```
 * Section (Layer)
 * └ PointCloudE57 (None)
 * ```
 *
 * @returns A MutationGenerateFloorplansForPointCloud object with a new area element.
 */
export function createMutationGenerateFloorplansForPointCloud({
  rootId,
  layerId,
  name,
  uri,
  md5Hash,
  fileName,
  fileSize,
}: CreateMutationGenerateFloorplansForPointCloud): MutationGenerateFloorplansForPointCloud {
  const pointCloud: CreateIElement<IElementPointCloudE57> = {
    type: IElementType.pointCloudE57,
    parentId: layerId,
    id: generateGUID(),
    uri,
    rootId,
    name,
    md5Hash,
    fileName,
    fileSize,
  };

  return {
    ...createBaseMutation(
      MutationTypes.MutationGenerateFloorplansForPointCloud,
      layerId,
    ),
    newElement: pointCloud,
  };
}
