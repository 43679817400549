import { blue, cyan, neutral, red } from "../colors";

export interface InputColors {
  /** Text color */
  text: string;

  /** Text color */
  textReadOnly: string;

  /** Color of the help-text and character counter */
  helpText: string;

  /** Border colors for different css states */
  borderIdle: string;
  borderHover: string;
  borderFocused: string;
  borderDisabled: string;
  borderReadOnly: string;

  /** Default background color (only used for the outlined variant) */
  backgroundOutlined: string;

  /** Read-only background color (only used for the outlined variant) */
  backgroundOutlinedReadOnly: string;

  /** Disabled background color (only used for the outlined variant) */
  backgroundOutlinedDisabled: string;

  /** Color used to highlight borders and text */
  error: string;

  /** Color of the placeholder text */
  placeholder: string;
}

export const INPUT_LIGHT_COLORS: InputColors = {
  text: neutral[800],
  textReadOnly: neutral[700],

  helpText: neutral[600],

  borderIdle: neutral[500],
  borderHover: neutral[800],
  borderFocused: blue[500],
  borderDisabled: "transparent",
  borderReadOnly: "transparent",

  backgroundOutlined: neutral[0],
  backgroundOutlinedReadOnly: neutral[100],
  backgroundOutlinedDisabled: neutral[100],

  error: red[500],

  placeholder: neutral[500],
};

export const INPUT_DARK_COLORS: InputColors = {
  text: neutral[0],
  textReadOnly: neutral[400],

  helpText: neutral[300],

  borderIdle: neutral[700],
  borderHover: neutral[100],
  borderFocused: cyan[400],
  borderDisabled: "transparent",
  borderReadOnly: "transparent",

  backgroundOutlined: neutral[999],
  backgroundOutlinedReadOnly: neutral[900],
  backgroundOutlinedDisabled: neutral[900],

  error: red[300],

  placeholder: neutral[500],
};
