import { TypedEvent, assert } from "@faro-lotv/foundation";
import {
	Box3,
	Clock,
	Color,
	ColorRepresentation,
	Object3D,
	OrthographicCamera,
	PerspectiveCamera,
	Scene,
	Vector2,
	WebGLRenderer,
} from "three";
import { LotvRenderer } from "./ThreeExt/LotvRenderer";
import { reproportionCamera, zoomOn } from "./Utils";

const DEFAULT_BACKGROUD_COLOR = 0xaaaaaa;

/**
 * Customization options to construct a Viewer
 */
type ViewerOptions = {
	/**
	 * The camera to use to render the scene
	 */
	camera: OrthographicCamera | PerspectiveCamera;
	/**
	 * The THREE renderer (default to LotvRenderer)
	 */
	renderer: WebGLRenderer;
	/**
	 * The scene to render
	 */
	scene: Scene;
	/**
	 * The background clear color
	 */
	backgroundColor: ColorRepresentation;
};

/**
 * Stats about the used resources in a viewer
 */
export type ViewerStats = {
	/** Number of programs loaded in GPU */
	programs: number;
	/** Number of textures loaded in GPU */
	textures: number;
	/** Number of geometries loaded in GPU */
	geometries: number;
	/** Number of render calls used to complete last draw */
	renderCalls: number;
};

/**
 * A class that manage all resources to render a scene on a canvas
 */
export class Viewer {
	public renderer: WebGLRenderer;
	public camera: OrthographicCamera | PerspectiveCamera;
	public scene: Scene;
	/**
	 * The function used to render the scene on this viewer can be replaced to change how the viewer renders
	 *
	 * @param delta Seconds passed from last render
	 * @param renderer The render context
	 * @param scene This viewer scene
	 * @param camera This viewer camera
	 */
	public renderFunction = (
		delta: number,
		renderer: WebGLRenderer,
		scene: Scene,
		camera: OrthographicCamera | PerspectiveCamera,
	): void => {
		renderer.render(scene, camera);
	};
	#clock = new Clock();
	beforeRender = new TypedEvent<number>();
	afterRender = new TypedEvent<void>();
	canvasResized = new TypedEvent<{ width: number; height: number; dpr: number }>();

	autoAdjustDpr = false;
	renderLoopId?: number;

	#fps = 0;
	#stats: ViewerStats = {
		geometries: 0,
		textures: 0,
		programs: 0,
		renderCalls: 0,
	};
	/** The screen resolution before multiplication by DPR */
	#screenSize = new Vector2();

	/**
	 * Construct a new Viewer to render on a canvas
	 *
	 * @param canvas The canvas to render to
	 * @param backgroundColor The background color to use
	 */
	constructor(canvas: HTMLCanvasElement, backgroundColor?: Color);
	/**
	 * Construct a new Viewer
	 *
	 * @param options The customization component for the viewer
	 */
	constructor(options: Partial<ViewerOptions>);
	/**
	 * @internal
	 */
	constructor(input: HTMLCanvasElement | Partial<ViewerOptions>, backgroundColor?: Color) {
		if (input instanceof HTMLCanvasElement) {
			const w = input.clientWidth || input.width;
			const h = input.clientHeight || input.height;
			this.camera = new PerspectiveCamera(45, w / h, 0.01, 100);
			this.renderer = new LotvRenderer({ canvas: input, premultipliedAlpha: false });
			this.renderer.setSize(w, h, false);
			this.scene = new Scene();
			this.scene.background = backgroundColor ?? new Color(DEFAULT_BACKGROUD_COLOR);
		} else {
			this.renderer = input.renderer ?? new LotvRenderer({ premultipliedAlpha: false });

			const canvas = this.renderer.domElement;
			const w = canvas.clientWidth || canvas.width;
			const h = canvas.clientHeight || canvas.height;
			this.camera = input.camera ?? new PerspectiveCamera(45, w / h, 0.01, 100);

			this.scene = input.scene ?? new Scene();
			this.scene.background = new Color(input.backgroundColor ?? DEFAULT_BACKGROUD_COLOR);
		}
		this.beforeRender.on((delta) => {
			if (delta > 0) {
				this.#fps = 1 / delta;
			}
		});
		this.afterRender.on(() => {
			this.#updateStats();
		});
	}

	/** @returns the number of frame per second, updated at each render */
	get fps(): number {
		return this.#fps;
	}

	/** @returns the effective canvas size with dpr applied */
	get effectiveSize(): Vector2 {
		this.renderer.getSize(this.#screenSize);
		return this.#screenSize.clone().multiplyScalar(this.renderer.getPixelRatio());
	}

	/** @returns the effictive canvas width with dpr applied */
	get effectiveWidth(): number {
		return this.effectiveSize.width;
	}

	/** @returns the effictive canvas height with dpr applied */
	get effectiveHeight(): number {
		return this.effectiveSize.height;
	}

	/**
	 * Resize the viewer to keep the correct aspect ratio when the target canvas change size
	 *
	 * @param force Force resize even if width/height seem not changed
	 */
	resize(force = false): void {
		const { canvas } = this.renderer.getContext();
		assert(canvas instanceof HTMLCanvasElement, "OffscreenCanvas still not supported by LotV Viewer");
		const rdpr = this.renderer.getPixelRatio();
		const wdpr = window.devicePixelRatio;
		const dpr = this.autoAdjustDpr ? wdpr : rdpr;

		// Canvas.width returns a value after multiplication by DPR,
		// updated by WebGLRenderer at every setSize.
		// Canvas.clientWidth returns a value before multiplication by DPR.

		// The only reason why we append '|| canvas.width' in the line below
		// is to make sure the viewer can be initialized from unit tests as well.
		const newW = canvas.clientWidth || canvas.width;
		const newH = canvas.clientHeight || canvas.height;
		this.renderer.getSize(this.#screenSize);

		if (force || this.#screenSize.x !== newW || this.#screenSize.y !== newH || dpr !== rdpr) {
			this.renderer.setPixelRatio(dpr);
			this.renderer.setSize(newW, newH, false);
			this.#screenSize.set(newW, newH);
			// It would be nice to have a Camera base class maybe
			// that extends Camera and add a couple of useful functions.
			reproportionCamera(this.camera, newW / newH);
			this.canvasResized.emit({ width: newW, height: newH, dpr });
		}
	}

	/**
	 * Add one object to the scene
	 *
	 * @param object The object to add
	 */
	add(object: Object3D): void {
		this.scene.add(object);
	}

	/**
	 * Rome one object to the scene
	 *
	 * @param object The object to remove
	 */
	remove(object: Object3D): void {
		this.scene.remove(object);
	}

	/**
	 * Start the internal render loop
	 */
	draw(): void {
		this.renderLoopId = requestAnimationFrame(() => this.draw());
		this.resize();
		this.drawOnce();
	}

	/**
	 * Stop the internal render loop
	 */
	stopDrawing(): void {
		if (this.renderLoopId) {
			cancelAnimationFrame(this.renderLoopId);
			delete this.renderLoopId;
		}
	}

	/**
	 * Draw the scene only one time without starting the render loop
	 */
	drawOnce(): void {
		const delta = this.#clock.getDelta();
		this.beforeRender.emit(delta);
		this.renderFunction(delta, this.renderer, this.scene, this.camera);
		this.afterRender.emit();
	}

	/**
	 * Return the ImageData computed from the current state of the canvas
	 *
	 * @returns An ImageData with the current rendered image
	 */
	currentImage(): ImageData {
		const ctx = this.renderer.getContext();
		const { x: width, y: height } = this.renderer
			.getSize(new Vector2())
			.multiplyScalar(this.renderer.getPixelRatio());
		const data = new Uint8Array(width * height * 4);
		ctx.readPixels(0, 0, width, height, ctx.RGBA, ctx.UNSIGNED_BYTE, data);
		const flipped = new Uint8ClampedArray(data.length);
		for (let row = 0; row < height; ++row) {
			const inputRow = height - row - 1;
			const rowData = data.subarray(inputRow * width * 4, (inputRow * width + width) * 4);
			flipped.set(rowData, row * width * 4);
		}
		return new ImageData(flipped, width, height);
	}

	/**
	 * @returns the dom element we're rendering on
	 */
	get domElement(): HTMLCanvasElement {
		return this.renderer.domElement;
	}

	/**
	 * Resets the view centering the given bounding box.
	 *
	 * @param bbox The bounding box to center the view on.
	 */
	zoomOn(bbox: Box3): void {
		const sz = this.renderer.getSize(new Vector2());
		zoomOn(this.camera, bbox, sz.x / sz.y);
	}

	/** @returns info on currently used resouces */
	get stats(): Readonly<ViewerStats> {
		return this.#stats;
	}

	/** Update stats */
	#updateStats(): void {
		const { geometries, textures } = this.renderer.info.memory;
		const programs = this.renderer.info.programs?.length ?? 0;
		this.#stats.geometries = geometries;
		this.#stats.textures = textures;
		this.#stats.programs = programs;
		this.#stats.renderCalls = this.renderer.info.render.calls;
	}
}
