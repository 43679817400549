precision highp float;
#define varying in
out float outColor;              // The output texture has only one red channel.

uniform sampler2D uDepthTexture;

varying vec2 vUv;

void main() {
	outColor = texture(uDepthTexture, vUv).r;
}
