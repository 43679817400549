import { InvalidConfigurationError } from "@faro-lotv/foundation";
import { WebGLRenderer, WebGLRenderTarget } from "three";
import { DesaturateMaterial } from "../Materials/DesaturateMaterial";
import { FullScreenPass } from "./FullScreenPass";

/**
 * A ThreeJS pass to desaturate the input FBO and forward the depth.
 * It is also possible to manipulate the saturation and the lightness of the input FBO in other ways as well.
 */
export class DesaturatePass extends FullScreenPass<DesaturateMaterial> {
	/**
	 * Construct a DesaturatePass
	 */
	constructor() {
		super(new DesaturateMaterial());
		this.clear = true;
	}

	/**
	 * Copy the currently stored depths from the writeBuffer to the readBuffer
	 *
	 * @param renderer The renderer
	 * @param writeBuffer The write buffer for this pass
	 * @param readBuffer The read buffer for this pass
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!readBuffer.depthTexture) {
			throw new InvalidConfigurationError("DesaturatePass pass requires a depth texture in the composer FBO");
		}

		// Without renderer.autoClear = true, in some pipelines the grayscale FBO is
		// partially hidden by the former color FBO when the depth test does not pass.
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;
		this.material.uniforms.uColorTexture.value = readBuffer.texture;
		this.material.uniforms.uDepthTexture.value = readBuffer.depthTexture;
		this.material.uniformsNeedUpdate = true;
		renderer.setRenderTarget(this.renderToScreen ? null : writeBuffer);
		this.fsQuad.render(renderer);
		renderer.autoClear = oldAutoClear;
	}

	/** @returns The multiplier applied to the saturation coefficient of the HSL color. Default is zero (desaturating the color) */
	get saturationMultiplier(): number {
		return this.material.uniforms.uSaturationMultiplier.value;
	}

	/** Sets the multiplier applied to the saturation coefficient of the HSL color */
	set saturationMultiplier(s: number) {
		this.material.uniforms.uSaturationMultiplier.value = s;
	}

	/** @returns The multiplier applied to the lightness coefficient of the HSL color. Default is one (lightness unchanged) */
	get lightnessMultiplier(): number {
		return this.material.uniforms.uLightnessMultiplier.value;
	}

	/** Sets The multiplier applied to the lightness coefficient of the HSL color. */
	set lightnessMultiplier(l: number) {
		this.material.uniforms.uLightnessMultiplier.value = l;
	}
}
