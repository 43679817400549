import type { TypedArray } from "three";

/** An interface generalizing the constructor signature of TypedArray objects */
export interface TypeArrayConstructor {
	new (size?: number): TypedArray;
}

/**
 * Return the right constructor function based on the input array
 *
 * @param array The input array which constructor we are interested in
 * @returns The constructor, or undefined if the type is not supported
 */
export function getTypedArrayConstructor(array: ArrayLike<number>): TypeArrayConstructor | undefined {
	if (array instanceof Int8Array) {
		return Int8Array;
	}
	if (array instanceof Uint8Array) {
		return Uint8Array;
	}
	if (array instanceof Uint8ClampedArray) {
		return Uint8ClampedArray;
	}
	if (array instanceof Int16Array) {
		return Int16Array;
	}
	if (array instanceof Uint16Array) {
		return Uint16Array;
	}
	if (array instanceof Int32Array) {
		return Int32Array;
	}
	if (array instanceof Uint32Array) {
		return Uint32Array;
	}
	if (array instanceof Float32Array) {
		return Float32Array;
	}
	if (array instanceof Float64Array) {
		return Float64Array;
	}
}
