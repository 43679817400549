import { assert } from "@faro-lotv/foundation";
import { BufferGeometry, DoubleSide, FrontSide, Mesh, Texture, Vector3 } from "three";
import { CadModelIDsMaterial, CadModelMaterial, MAX_NUMBER_OF_COLOR_TEXTURES } from "../Materials";
import { CadBasicRenderBuffers, CadNode } from "./CadModelDataStructures";
import {
	TomographicMaterialBackup,
	computeMainGeometry,
	createCadNodes,
	createNewGeometry,
	restoreMaterial,
	setTomographicMaterial,
} from "./CadModelPrivateUtils";

/** A placeholder texture used to fill missing textures in the material */
const EMPTY_TEXTURE = new Texture();

/** Mesh rendering mode */
export enum MeshMode {
	OpaqueNoTexture = 0,
	OpaqueTexture = 1,
	TransparentNoTexture = 2,
	TransparentTexture = 3,
}

/**
 * CAD model mesh with a single merged BufferGeometry created by merging input meshes.
 * It support append new meshes, and the merged BufferGeometry can be expended when
 * needed. To ensure the meshes are mergeable, they need to be of the same rendering mode,
 * such as opaque, transparent, with or without textures.
 *
 * By storing all data in a single buffer, it reduces the draw calls to a minmum. This could
 * be very memory intensive for model that contains a large numbers instances of same object,
 * because each object will be duplicated in the buffer.
 */
export class StreamCadMesh extends Mesh<BufferGeometry, CadModelMaterial | CadModelIDsMaterial> {
	/** Material to be used to render the model */
	#defaultMaterial = new CadModelMaterial();
	/** Material to be used to render the cad part IDs */
	#partIDsMaterial = new CadModelIDsMaterial();
	/** All color textures of this model */
	#allColorTextures = new Array<Texture>();
	/** Color textures passed to shader, limited to max number available in GPU */
	#colorTextures = new Array<Texture>();
	/** Render data */
	#renderBuffers = new CadBasicRenderBuffers();
	/** List of the CAD nodes */
	cadNodes = new Array<CadNode>();
	/** Whether this mesh is transparent */
	#isTransparent = false;
	/** Whether this mesh is textured */
	#isTextured = false;
	/** The allocation factor when adding the first chunk of meshes */
	#firstAllocateFactor = 1.0;
	/** Backup material properties when set to tomographic mode, used to restore the normal material properties */
	#tomographicBackup: TomographicMaterialBackup | undefined = undefined;

	/**
	 * @param type The rendering mode of this mesh
	 */
	constructor(type: MeshMode) {
		super();
		this.#isTransparent = type === MeshMode.TransparentNoTexture || type === MeshMode.TransparentTexture;
		if (this.#isTransparent) {
			this.#defaultMaterial.side = FrontSide;
			this.#defaultMaterial.transparent = true;
			this.#defaultMaterial.depthWrite = false;
		} else {
			this.#defaultMaterial.side = DoubleSide;
			this.#defaultMaterial.transparent = false;
			this.#defaultMaterial.depthWrite = true;
		}
		this.#isTextured = type === MeshMode.OpaqueTexture || type === MeshMode.TransparentTexture;
		if (this.#isTextured) {
			this.#defaultMaterial.isTextured = true;
			this.#colorTextures = new Array(MAX_NUMBER_OF_COLOR_TEXTURES).fill(EMPTY_TEXTURE);
		}

		this.material = this.#defaultMaterial;

		// Cad is empty for now, the data textures are still allocated so
		// webgl errors are avoided.
		this.#renderBuffers.allocateBuffers(64);
	}

	/** Set the allocation factor when adding the first chunk of meshes */
	set firstAllocateFactor(value: number) {
		this.#firstAllocateFactor = Math.max(1.0, value);
	}

	/**
	 * @returns Whether this mesh is transparent
	 */
	get isTransparent(): boolean {
		return this.#isTransparent;
	}

	/**
	 * @returns Whether this mesh is textured
	 */
	get isTextured(): boolean {
		return this.#isTextured;
	}

	/**
	 * Adds new meshes to be merged in to this model
	 *
	 * @param meshes The meshes to be added
	 * @param offset model offset position
	 */
	addMeshes(meshes: Mesh[], offset: Vector3): void {
		if (meshes.length === 0) return;

		const isTextured = meshes.some((m) => m.geometry.hasAttribute("uv"));
		assert(isTextured === this.#isTextured, "All meshes must have the same texturing state");

		const nodeIdxBegin = this.cadNodes.length;
		createCadNodes(meshes, this.cadNodes, this.#allColorTextures, offset);
		const nodeIdxEnd = this.cadNodes.length;

		// Create the geometry if it's empty
		if (this.geometry.getIndex() === null) {
			this.geometry = createNewGeometry(meshes, this.#firstAllocateFactor);
		}

		computeMainGeometry(this.geometry, this.cadNodes, nodeIdxBegin, nodeIdxEnd);
		this.#initDataTextures();

		if (this.#isTextured) {
			for (let i = 0; i < MAX_NUMBER_OF_COLOR_TEXTURES; ++i) {
				this.#colorTextures[i] = i < this.#allColorTextures.length ? this.#allColorTextures[i] : EMPTY_TEXTURE;
			}
			this.#defaultMaterial.uniforms.colorTextures.value = this.#colorTextures;
			this.#defaultMaterial.uniforms.colorTextures.needsUpdate = true;
		}
	}

	override onBeforeRender = (): void => {
		this.#renderBuffers.maybeUpdatePosesTexture();
		if (this.material !== this.#partIDsMaterial) {
			this.#renderBuffers.maybeUpdateMaterialsTexture();
		}
	};

	/** Inits the data textures with all Cad parts' poses and materials. */
	#initDataTextures(): void {
		// For simplicty, data textures are recreated everytime new meshes are added.
		// This is not optimal, but number of chunks is not expected to be huge. This
		// can be optimized if needed.
		this.#renderBuffers.dispose();
		// allocate data buffers for rendering
		this.#renderBuffers.allocateBuffers(this.cadNodes.length);

		// fill the buffers
		for (let n = 0; n < this.cadNodes.length; ++n) {
			const node = this.cadNodes[n];
			this.#renderBuffers.setPose(node, n);
			this.#renderBuffers.setMaterial(node, n);
		}
		this.#renderBuffers.maybeUpdatePosesTexture();
		this.#renderBuffers.maybeUpdateMaterialsTexture();
		this.#defaultMaterial.uniforms.poseTexture.value = this.#renderBuffers.posesDataTexture;
		this.#partIDsMaterial.uniforms.poseTexture.value = this.#renderBuffers.posesDataTexture;
		this.#defaultMaterial.uniforms.materialTexture.value = this.#renderBuffers.materialsDataTexture;
	}

	/** Switches the material of this object to the default material, if needed */
	switchToDefaultMaterial(): void {
		if (this.material === this.#defaultMaterial) return;
		this.#defaultMaterial.clippingPlanes = this.material.clippingPlanes;
		this.#defaultMaterial.clipping = this.material.clipping;
		this.#defaultMaterial.clipIntersection = this.material.clipIntersection;
		this.material = this.#defaultMaterial;
	}

	/** Switching the material of this object to the part ids material, if needed */
	switchToPartIDsMaterial(): void {
		if (this.material === this.#partIDsMaterial) return;
		this.#partIDsMaterial.clippingPlanes = this.material.clippingPlanes;
		this.#partIDsMaterial.clipping = this.material.clipping;
		this.#partIDsMaterial.clipIntersection = this.material.clipIntersection;
		this.material = this.#partIDsMaterial;
	}

	/** Set draw ID offset value for this model */
	set drawIdOffset(value: number) {
		this.#partIDsMaterial.uniforms.drawIdOffset.value = value;
	}

	/**
	 * Sets whether node n is highlighted or not
	 *
	 * @param n The index of the node
	 * @param v True iff node n should be highlighted.
	 */
	setNodeHighlighted(n: number, v: boolean): void {
		const cadNode = this.cadNodes[n];
		if (v !== cadNode.highlighted) {
			cadNode.highlighted = v;
			this.#renderBuffers.setMaterial(cadNode, n);
		}
	}

	/**
	 * @returns the highlighting color
	 */
	get highlightingColor(): Vector3 {
		return this.#defaultMaterial.uniforms.highlightingColor.value.clone();
	}

	/**
	 * Sets the CAD part highlighting color
	 *
	 * @param color a triplet with RGB components from 0 to 1
	 */
	set highlightingColor(color: Vector3) {
		this.#defaultMaterial.uniforms.highlightingColor.value.copy(color);
	}

	/**
	 * Sets whether node n is visible or not
	 *
	 * @param n Index of the queried node
	 * @param v Whether n should be visible or not
	 * @returns True if the visibility flag was changed
	 */
	setNodeVisible(n: number, v: boolean): boolean {
		const cadNode = this.cadNodes[n];
		const changed = cadNode.visible !== v;
		if (changed) {
			cadNode.visible = v;
			this.#renderBuffers.updateVisibilityFlag(cadNode, n);
		}
		return changed;
	}

	/**
	 * Returns the byte length of the geometry data of this mesh.
	 *
	 * @returns The byte length of the buffers
	 */
	get byteLength(): number {
		if (!this.geometry.hasAttribute("position")) return 0;

		const nbVertices: number = this.geometry.userData.nbVerticesUsed;
		let byteLength = nbVertices * 3 * 4;
		if (this.geometry.hasAttribute("normal")) {
			byteLength += nbVertices * 3 * 4;
		}
		if (this.geometry.hasAttribute("uv")) {
			byteLength += nbVertices * 2 * 4;
		}
		if (this.geometry.hasAttribute("drawID")) {
			byteLength += nbVertices * 4;
		}
		const idx = this.geometry.getIndex();
		if (idx) {
			const nbIndicesUsed: number = this.geometry.userData.nbIndicesUsed;
			byteLength += nbIndicesUsed * 4;
		}
		return byteLength;
	}

	/**
	 * @returns flag indicates clipping planes are in CAD's local transform
	 */
	get clippingInLocalTransform(): boolean {
		return this.#defaultMaterial.uniforms.clippingInLocalTransform.value;
	}

	/**
	 * Set flag indicates clipping planes are in CAD's local transform
	 *
	 * @param value Whether the clipping planes are in CAD's local transform or not
	 */
	set clippingInLocalTransform(value: boolean) {
		this.#defaultMaterial.uniforms.clippingInLocalTransform.value = value;
	}

	/**
	 * Raycast method is overriden to avoid threejs to run the default raycasting.
	 */
	raycast(): void {
		// This object contains merged buffer with transformation stored in a texture buffer,
		// which is not suitable for the default raycasting of threejs. So we override the
		// raycast with empty method to avoid threejs to run the default raycasting.
	}

	/**
	 * Set the model to be suitable for tomographic rendering, see TomographicModelPass.
	 *
	 * @param tomographic set to true to enable tomographic rendering
	 */
	setTomographic(tomographic: boolean): void {
		if (tomographic) {
			if (this.#tomographicBackup === undefined) {
				this.#tomographicBackup = setTomographicMaterial(this.#defaultMaterial);
			}
		} else if (this.#tomographicBackup) {
			restoreMaterial(this.#defaultMaterial, this.#tomographicBackup);
			this.#tomographicBackup = undefined;
		}
	}
}
