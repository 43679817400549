/**
 * Contains data properties to store the major
 * information of each tiled pano data tree branch
 *
 * @deprecated the jsonAxB properties of IElements are now replaced by the levelsOfDetails property
 */
export interface LegacyIElementTilesLevels {
  /** Dimension, means count of lod panos in X direction */
  dimX: number;

  /** Dimension, means count of lod panos in Y direction */
  dimY: number;

  /** Array of URLs of corresponding panos images */
  sources: string[];
}

/**
 * Type guard function to check if an unknown json implements the ILevel interface
 *
 * @param json Input json parsed from the backend
 * @returns true if json matches the ILevel interface
 */
function isLevel(json: unknown): json is LegacyIElementTilesLevels {
  if (typeof json !== "object" || !json) {
    return false;
  }
  const level: Partial<LegacyIElementTilesLevels> = json;

  return (
    level.dimX !== undefined &&
    typeof level.dimX === "number" &&
    level.dimY !== undefined &&
    typeof level.dimY === "number" &&
    level.sources !== undefined &&
    Array.isArray(level.sources)
  );
}

/**
 * Parse a json ILevel string from an Image360IElement (eg: json1x1/json2x1)
 *
 * @param input The string with the level information from the iElement json
 * @returns the parsed ILevel
 * @deprecated the jsonAxB properties of IElements are now replaced by the levelsOfDetails property
 */
export function parseLevel(input: string): LegacyIElementTilesLevels {
  const json: unknown = JSON.parse(input);
  if (isLevel(json)) {
    return json;
  }
  throw new Error("Input json level is not a valid ILevel string");
}
