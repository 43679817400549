import { PointCloud } from "@faro-lotv/lotv";
import { Intersection, Vector2, Vector3 } from "three";

/**
 * @param ev a dom event on the canvas
 * @param result object to place the result into
 * @returns the x, y ndc coordinates to be used for raycasting
 */
export function computePointerNdcCoordinates(
  ev: PointerEvent | MouseEvent | Touch,
  result?: Vector2,
): Vector2 {
  if (!(ev.target instanceof Element)) throw new Error("invalid event");
  return computeNdcCoordinates(ev.clientX, ev.clientY, ev.target, result);
}

/**
 * @param clientX Mouse/Pointer/Touch event's clientX property
 * @param clientY Mouse/Pointer/Touch event's clientX property
 * @param eventTarget Target element of the event
 * @param result object to place the result into
 * @returns the x, y ndc coordinates to be used for raycasting
 */
export function computeNdcCoordinates(
  clientX: number,
  clientY: number,
  eventTarget: Element,
  result = new Vector2(),
): Vector2 {
  const { width, height, left, top } = eventTarget.getBoundingClientRect();
  const x = clientX - left;
  const y = clientY - top;

  return result.set((x / width) * 2 - 1, -(y / height) * 2 + 1);
}

/**
 * @returns the user picked point, taking care to return a valid PointCloud point when picking on point clouds
 * @param hit returned by ThreeJS ray-casting
 * @param point already allocated to save one allocation inside this function
 *
 * TODO: Add proper tests (https://faro01.atlassian.net/browse/SWEB-1714)
 */
export function getPickedPoint(
  hit: Intersection,
  point = new Vector3(),
): Vector3 {
  // Only for point cloud use the exact PointCloud point and not the projection on the ray
  if (hit.index && hit.object instanceof PointCloud) {
    return hit.object.localToWorld(hit.object.getLocalPoint(hit.index, point));
  }
  // Use the reported ray-casted point for all the other ThreeJS objects
  return point.copy(hit.point);
}
