import { ShaderMaterial, Texture, Matrix4 } from "three";
import vert from "../Shaders/TexturedQuad.vert";
import frag from "../Shaders/EyeDome.frag";
import { makeUniform } from "./Uniforms";

export const EDL_DEFAULT_STRENGTH = 300;
const EDL_DEFAULT_BIAS = 0.05;

/**
 * A material to apply the EyeDome effect, requires in input both a diffuse and a depth texture
 *
 * The effect is sensible to depth precision so it's suggested, for best result, to work with a Float
 * depth texture
 */
export class EyeDomeMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		edlStrength: makeUniform(EDL_DEFAULT_STRENGTH),
		radius: makeUniform(1),
		depthDiscontinuityBias: makeUniform(EDL_DEFAULT_BIAS),
		showShadeOnly: makeUniform(false),
		showBlackOutlines: makeUniform(false),
		uEDLColor: makeUniform<Texture | null>(null),
		uEDLDepth: makeUniform<Texture | null>(null),
		uInvProj: makeUniform(new Matrix4()),
	};
	constructor() {
		super();
	}
}
