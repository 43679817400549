import {
  GUID,
  IElementGenericMarkupField,
  IElementMarkup,
  IElementType,
  IElementTypeHint,
  MarkupIElement,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddMarkup extends BaseMutation {
  /** The Markup fields to create with the IElement */
  markupFields: Array<CreateIElement<IElementGenericMarkupField>>;

  /** New area section element */
  newElement: CreateIElement<MarkupIElement>;
}

export interface CreateMutationAddMarkup {
  /** ID of the element to be created */
  id: GUID;

  /** Title of the Markup being created */
  name: string;

  /** Description of the Markup being created */
  description: string;

  /** The template used for this markup. The templates are defined in the `Project Root>Markup Templates` group. */
  templateId?: string;

  /**
   * The markup fields of the created markup.
   * Apart from the name and description, all other markup fields are additional IElements.
   * The markup fields in this array need to be linked to a markup field template inside the markup template.
   */
  markupFields: Array<CreateIElement<IElementGenericMarkupField>>;

  /** The Annotation object (Model3D, MarkupPolygon, MeasurePolygon) to attach the Markup to */
  annotationId: GUID;

  /** ID of the root element */
  rootId: GUID;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;
}

/**
 * Adds a new markup instance to an existing Model3d node.
 *
 *```
 * Model3d (Node)
 * └ Markup (Markup)
 * ```
 *
 * @returns A MutationAddArea object to create a markup
 */
export function createMutationAddMarkup({
  id,
  rootId,
  name,
  description,
  templateId,
  markupFields,
  annotationId,
  createdAt = new Date().toISOString(),
}: CreateMutationAddMarkup): MutationAddMarkup {
  const newElement: CreateIElement<IElementMarkup> = {
    type: IElementType.markup,
    typeHint: IElementTypeHint.markup,
    id,
    rootId,
    parentId: annotationId,
    childrenIds: [],
    name,
    descr: description || null,
    createdAt,
    templateId: templateId ?? null,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddMarkup, annotationId),
    newElement,
    markupFields,
  };
}
