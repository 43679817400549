import { FxaaPass as LotvFxaaPass } from "@faro-lotv/lotv";
import { useState } from "react";
import { attachPass } from "../attach-utils";

export type FxaaPassProps = {
  /** Whether the pass is enabled */
  enabled?: boolean;
};

/** @returns A pass to perform fast anti-aliasing on the input FBO*/
export function FxaaPass({ enabled = true }: FxaaPassProps): JSX.Element {
  const [pass] = useState(() => new LotvFxaaPass());
  return (
    <primitive
      name="FxaaPass"
      object={pass}
      attach={attachPass}
      enabled={enabled}
    />
  );
}
