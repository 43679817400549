const vec3 extractLuma = vec3(0.2126, 0.7152, 0.0722);

// This function converts a color value from HSL to RGB spaces.
vec3 hsl2rgb( in vec3 c )
{
    vec3 rgb = clamp( 
        abs(
            mod(c.x * 6.0 + vec3(0.0, 4.0, 2.0), 6.0) - 3.0)
        -1.0, 
    0.0, 1.0 );

    return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));
}

// This function converts a color value from RGB  to HSL spaces.
vec3 rgb2hsl( in vec3 c ){
    float h = 0.0;
	float s = 0.0;
	float l = 0.0;
	float r = c.r;
	float g = c.g;
	float b = c.b;
	float cMin = min( r, min( g, b ) );
	float cMax = max( r, max( g, b ) );
    float cSum = cMin + cMax;

	l = cSum / 2.0;
	if ( cMax > cMin ) {
		float cDelta = cMax - cMin;
        
        //s = l < .05 ? cDelta / cSum : cDelta / ( 2.0 - cSum ); Original
		s = l < .0 ? cDelta / cSum : cDelta / ( 2.0 - cSum );
        
		if ( r == cMax ) {
			h = ( g - b ) / cDelta;
		} else if ( g == cMax ) {
			h = 2.0 + ( b - r ) / cDelta;
		} else {
			h = 4.0 + ( r - g ) / cDelta;
		}

		if ( h < 0.0) {
			h += 6.0;
		}
		h = h / 6.0;
	}
	return vec3( h, s, l );
}

