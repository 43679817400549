import { Object3D } from "three";

/**
 * @returns true if ancestor is an ancestor of object
 * @param object The child object to start searching from
 * @param ancestor The ancestor to find
 */
export function isAncestor(object: Object3D, ancestor: Object3D): boolean {
	if (object === ancestor) return true;
	if (!object.parent) return false;
	return isAncestor(object.parent, ancestor);
}
