import { GUID } from "@faro-lotv/foundation";
import {
  BackendJobId,
  RegistrationAlgorithmSettings,
} from "./registration-api-types";

export type StartRegistrationParams = {
  /** The IDs of the reference point clouds. They are expected to be aligned already. */
  referenceCloudIds: GUID[];

  /** The IDs of the model point clouds, which will be aligned to the references. */
  modelCloudIds: GUID[];

  /** The settings for the algorithm to use for automatic registration. */
  algorithmSettings: RegistrationAlgorithmSettings;

  /**
   * The transform matrix from the model to the reference point cloud.
   *
   * This is needed to determine the relative transform between the point clouds
   * even if their position is not yet persisted to the Project API.
   *
   * TODO: Document which format this must be in.
   */
  modelToRefWorldTransform: number[][];
};

export type CancelRegistrationParams = {
  /** The ID of the job to cancel. */
  jobId: BackendJobId;

  /** The IDs of the reference point clouds. They are expected to be aligned already. */
  referenceCloudIds: GUID[];

  /** The IDs of the model point clouds, which will be aligned to the references. */
  modelCloudIds: GUID[];
};

export type StartCaptureTreeRegistrationParams = {
  /**
   * The ID of the revision where the registration should be performed.
   * The revision must be in state `Started` or `UserModified`.
   *
   * If omitted, the backend will create a new revision.
   */
  revisionId?: GUID;
  /**
   * Optional parameter. With the capture tree we can re-use registration results from previous runs, which
   * are saved on local edges. If this flag is set to true, the registration will ignore local edges
   * and recompute the registration from scratch. If it's false it will use the local edges, if they exist.
   * If omitted, the backend will chose a reasonable option.
   */
  ignoreLocalEdges?: boolean;
  /**
   * Optional parameter that enforces usage of the given registration algorithm.
   * If omitted the backend will chose the best algorithm based on the input data.
   */
  registrationAlgorithm?: string;
  /**
   * Optional parameter that enforces the automatic publishing of the results. If omitted the results
   * may still be published depending on the client whitelist that created the revision.
   */
  autoPublish?: boolean;
  /**
   * Optional set of parameters which are passed to the registration algorithm.
   * If omitted the backend will use default parameters based on the algorithm.
   */
  parameters?: Record<string, unknown>;
};

export type StartPointCloudMergeParams = {
  /** The ID of the element where the merged point cloud is inserted as new dataset. */
  outputTimeSeriesId: GUID;

  /**
   * The date that will be set as the creation date for the merged point cloud.
   * Some users want to use the capture date instead of the current date here.
   */
  outputDate: Date;

  /** The name of the new dataset section which will be created for the merged point cloud. */
  outputSectionName: string;

  /**
   * The IDs of the point clouds to merge.
   *
   * The backend accepts `PointCloudLaz`, `Section.DataSetPCloudUpload` and `Section.DataSession` IElements.
   */
  pointCloudIds: GUID[];
};

/** The task types the worker can perform, as defined by the backend. */
export enum RegistrationTaskType {
  graphOptimization = "GraphOptimization",
  metricsCalculation = "MetricsCalculation",
  reportGeneration = "ReportGeneration",
  c2cRegistration = "C2CRegistration",
  mergePointClouds = "MergePointClouds",
}
