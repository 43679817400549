import { IElementImg360 } from "@faro-lotv/ielement-types";
import { Img360LodTree } from "./img-360-lod-tree";
import { IElement360Tree, parseLevel } from "./legacy";

/** The IElementImg360 properties needed to initialize a ImageTree */
export type IElementImg360PanoData = Pick<
  IElementImg360,
  | "levelsOfDetail"
  | "pixelHeight"
  | "pixelWidth"
  | "json1x1"
  | "json2x1"
  | "json4x2"
  | "json8x4"
>;

/**
 * @param iElement to generate the lod tree for
 * @returns the proper pano lod tree implementation based on the element properties
 */
export function createPanoLodTree(
  iElement: IElementImg360PanoData,
): Img360LodTree | IElement360Tree {
  return iElement.levelsOfDetail
    ? new Img360LodTree(
        iElement.levelsOfDetail,
        iElement.pixelWidth,
        iElement.pixelHeight,
      )
    : new IElement360Tree(iElement);
}

/**
 * @param iElement to get the overview texture url for
 * @returns the url to fetch an overview texture for the pano
 */
export function getPanoOverviewUrl(iElement: IElementImg360PanoData): string {
  if (iElement.levelsOfDetail) {
    return iElement.levelsOfDetail[0].sources[0];
  }
  return parseLevel(iElement.json1x1).sources[0];
}
