import { UserDisplayInfo } from "../../types/user-display-info";

/**
 * Gets the initials for a user.
 * If both first name and last name are available, it will use the first letter of each.
 * If only either the first or last name is available, it will use the first two letters.
 * If none of them are available, the first two letter of the email will be used.
 *
 * @param user Object containing the user information
 * @returns The two letters of the user name in uppercase or one letter if only one is available.
 */
export function getUserInitials(user?: UserDisplayInfo | null): string {
  let initials: string = "";

  const maxDigits = 2;

  if (!user) {
    return "";
  }

  const { firstName, lastName, email, name } = user;

  if (firstName && lastName) {
    initials = `${firstName[0]}${lastName[0]}`;
  } else if (firstName) {
    initials = firstName.substring(0, maxDigits);
  } else if (lastName) {
    initials = lastName.substring(0, maxDigits);
  } else if (name) {
    // When fetching the logged in user, the response includes first and last name properties.
    // However, when fetching the users list, only a combined name property is included.
    // Therefore we try to infer the first and last name splitting the name separated by a space.
    const split = removeExtraSpaces(name).split(" ");
    if (split.length >= 2) {
      initials = (split[0][0] || "") + (split[1][0] || "");
    } else {
      initials = name.substring(0, maxDigits);
    }
  } else if (email) {
    initials = email.substring(0, maxDigits);
  }
  return initials.toLocaleUpperCase();
}

/**
 * Removes all extra spaces from a string to have maximum one space and trims the text.
 * E.g. From " Test   Method " the three spaces are combined and trimmed into "Test Method"
 *
 * @param str The string to remove its spaces.
 * @returns New string with removed spaces.
 */
export function removeExtraSpaces(str: string | undefined | null): string {
  return (str ?? "").replace(/ +(?= )/g, "").trim();
}
