import type { LodNodeFetch } from "../Lod/LodTree";
import { WSNodeAbortRequest, WSNodeFetchRequest, WSNodeResponses, WSPoints } from "./WSPoints";

type EventListener = (event: MessageEvent<WSNodeResponses>) => void;

/**
 * A class to track and abort node fetches
 */
export class WSNodeFetch implements LodNodeFetch {
	private promise: Promise<WSPoints> | null;
	private worker: Worker | null;
	// We store here the event listener that will be added to the worker.
	// In this way, we can remove the event listener from the worker when node fetching is finished or canceled.
	// If we do not do that, then the worker will store thousands of event listeners, that will capture all the WSNodeFetch
	// object in the closure, data buffers included, therefore javascript will not be able to deallocate the data buffers
	// after download, crashing the page for out of memory after a while.
	private eventListener: EventListener | null = null;

	/**
	 * Fetch a webshare kdtree node
	 *
	 * @param entity The point cloud entity id
	 * @param nodeId The node id in the kdtree structure
	 * @param url The full url to fetch this node
	 * @param w The worker to use to fetch this node
	 */
	constructor(
		public entity: string,
		public nodeId: string,
		url: string,
		w: Worker,
	) {
		this.worker = w;
		this.promise = new Promise<WSPoints>((resolve, reject) => {
			const el = (event: MessageEvent<WSNodeResponses>): void => {
				if (event.data.entity !== this.entity || event.data.nodeId !== this.nodeId) return;
				switch (event.data.type) {
					case "NodeFetchSuccess":
						resolve(event.data.points);
						break;
					case "NodeFetchFailed":
						reject(event.data.error);
						break;
				}
				// Detaching the event listener from the worker, so the garbage collector can dispose of this object and its buffers.
				this.cleanup();
			};
			// storing the event listener to remove it from the worker on cleanup.
			this.eventListener = el;
			w.addEventListener("message", el);
		});
		const msg: WSNodeFetchRequest = {
			type: "NodeFetchRequest",
			entity,
			nodeId,
			url,
		};
		// TODO: the buffers shared between main thread and worker
		// should be transformed into transferables https://faro01.atlassian.net/browse/SWEB-1285
		w.postMessage(msg);
	}

	/** @returns the unique id the fetching node */
	get uuid(): string {
		return this.nodeId;
	}

	/**
	 * @returns A promise to wait for the node points
	 */
	points(): Promise<WSPoints> {
		if (!this.promise) {
			throw new Error("Cannot ask points to expired Lod node fetch.");
		}
		return this.promise;
	}

	/**
	 * Abort this fetch, the promise returned by node() will be rejected
	 */
	abort(): void {
		if (!this.worker) {
			return;
		}
		const msg: WSNodeAbortRequest = {
			type: "NodeAbortRequest",
			entity: this.entity,
			nodeId: this.nodeId,
		};
		this.worker.postMessage(msg);
	}

	/** Sets to null internal references to buffers so that memory can be deallocated. */
	cleanup(): void {
		this.promise = null;
		if (this.worker && this.eventListener) {
			this.worker.removeEventListener("message", this.eventListener);
		}
		this.worker = null;
		this.eventListener = null;
	}
}
