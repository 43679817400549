import { Disposable, TypedEvent } from "@faro-lotv/foundation";
import { Box3, Camera, Intersection, Material, Matrix4, Raycaster, Vector2 } from "three";
import { AdaptivePointsMaterial } from "../Materials/AdaptivePointsMaterial";
import { PointCloud } from "../PointCloud/PointCloud";
import { PointCloudBufferGeometry } from "../PointCloud/PointCloudBufferGeometry";
import { PotreeTree } from "../Potree/PotreeTree";
import { ClipPlanesStatus } from "./ClipPlanesStatus";
import { LodCachingStrategy } from "./LodCachingStrategy";
import { LodGroup } from "./LodGroup";
import { NodeCacheElement } from "./LodMultiview";
import {
	LOD_POINT_CLOUD_RAYCASTING_DEFAULTS,
	LodPointCloudRaycast,
	LodPointCloudRaycastingOptions,
} from "./LodPointCloudRaycast";
import { LodTree, NodeState } from "./LodTree";
import { FetcherClient, LodTreeFetcher } from "./LodTreeFetcher";
import { PointsCacheElement } from "./PointsCacheElement";
import { VisibleNodesStrategy, WeightedNode } from "./VisibleNodeStrategy";

/**
 * Internal type used to implement subsampled rendering functionality.
 */
type SubsampledRenderingOptions = {
	/** Whether a camera renders with subsampled rendering */
	enabled: boolean;
	/** How much of the camera visible nodes should be rendered by the subsampled camera */
	fraction: number;
	/** Maximum count of the camera visible nodes should be rendered by the subsampled camera */
	maxNodes: number;
	/** Min weight (screen occupancy) that a node should have to be rendered during subsampled rendering */
	minWeight: number;
};

export type LodPointCloudOptions = {
	/**
	 * Policy that determines whether nodes that fall out
	 * of visibility are kept in cache and with which criterion
	 * (store max N nodes in cache, store nodes frequently accessed, etc.)
	 */
	lodCachingStrategy: LodCachingStrategy;

	/** Custom raycasting options for this LodPointCloud */
	raycasting: LodPointCloudRaycastingOptions;
};

/**
 * Default options for an LodPointCloud
 */
export const LOD_POINT_CLOUD_DEFAULTS: LodPointCloudOptions = {
	lodCachingStrategy: new LodCachingStrategy(),
	raycasting: LOD_POINT_CLOUD_RAYCASTING_DEFAULTS,
};

/**
 * A class capable of rendering lod point clouds.
 */
export class LodPointCloud extends LodGroup implements FetcherClient {
	/**
	 * The material used to render the point cloud chunks
	 */
	#material: Material;
	/**
	 * The lodTreeFetcher that fetches nodes
	 */
	#lodFetcher: LodTreeFetcher;
	/**
	 * The list of nodes that are cached in GPU memory, but not added to the group for rendering.
	 */
	#nodesInMemory = new Map<number, PointsCacheElement>();
	/**
	 * The list of nodes whose points are currently loaded in GPU and rendered, implemented as a map node idx -> Points object.
	 */
	#nodesInGPU = new Map<number, PointsCacheElement>();
	/**
	 * The total number of points that are currently being rendered.
	 */
	#totPointsInGPU = 0;
	/**
	 * LOD cloud options
	 */
	#options: LodPointCloudOptions;
	/**
	 * A functor used on disposal, to deregister this point cloud
	 * from the 'nodeReady' event of the fetcher.
	 */
	#fetcherEvDetacher: Disposable | null;

	/** The custom raycasting options for this LodPointCloud */
	raycasting: Required<LodPointCloudRaycastingOptions>;

	/** Object providing the raycast algorithm */
	#raycastAlgo: LodPointCloudRaycast;

	/** Cached inverse world matrix to not re-allocate at each raycast */
	#matrixWorldInv = new Matrix4();

	/** Aux world matrix to check whether the inverse world matrix needs to be computed */
	#lastMatrixWorld = new Matrix4();

	/** Whether clip planes are active on the point cloud, and aux data structures */
	#clipStatus = new ClipPlanesStatus();

	/** The rendering options used in case of sub-sampled rendering */
	#subsampledRendering: SubsampledRenderingOptions = { enabled: false, fraction: 1.0, maxNodes: 100, minWeight: 0.0 };

	/** State variable to remember whether all points have been received or this cloud is still waiting for some. */
	#allPointsReceived = false;

	/** Signal emitted whenever a new node, that is visible yet not received, is returned by the fetcher. */
	nodeReady = new TypedEvent<number>();

	/** Signal emitted when all nodes visible from this view have been received and are being rendered. */
	allPointsReceived = new TypedEvent<void>();

	/** The bounding box of the points of the point cloud (in local space). */
	readonly boundingBox: Box3;

	/** @returns true if this PointCloud is mono chromatic */
	get monochrome(): boolean {
		// Can be checked only for Potree point clouds
		if (!(this.tree instanceof PotreeTree)) return false;

		return this.tree.monochrome;
	}

	/**
	 * @returns the current Cache Clean Computer
	 */
	get cacheCleanComputer(): LodCachingStrategy {
		return this.#options.lodCachingStrategy;
	}
	/** @param computer the new Cache Clean Computer*/
	set cacheCleanComputer(computer: LodCachingStrategy) {
		this.#options.lodCachingStrategy = computer;
	}

	/** The object responsible for computing the visible LOD nodes given a tree, a camera, and a screen resolution. */
	visibleNodesStrategy: VisibleNodesStrategy;

	/**
	 * Constructs an object responsible of rendering a Lod point cloud.
	 *
	 * @param tree The lod tree data structure to render, will create and own a LodTreeFetcher internally.
	 * @param material The material to visualize points.
	 * @param options Parameters for the LodPointCloud.
	 */
	constructor(tree: LodTree, material: Material, options?: Partial<LodPointCloudOptions>);
	/**
	 * Constructs an object responsible of rendering a Lod point cloud.
	 *
	 * @param fetcher The LodTreeFetcher used to get the points, will not own the fetcher and will not dispose it.
	 * @param material The material to visualize points.
	 * @param options Parameters for the LodPointCloud.
	 */
	constructor(fetcher: LodTreeFetcher, material: Material, options?: Partial<LodPointCloudOptions>);
	/**
	 * Constructs an object responsible of rendering a Lod point cloud.
	 *
	 * @param treeOrFetcher The lod tree data structure.
	 * @param material The material to visualize points.
	 * @param options Parameters for the LodPointCloud.
	 */
	constructor(treeOrFetcher: LodTree | LodTreeFetcher, material: Material, options?: Partial<LodPointCloudOptions>) {
		super();
		if (treeOrFetcher instanceof LodTreeFetcher) {
			this.#lodFetcher = treeOrFetcher;
		} else {
			this.#lodFetcher = new LodTreeFetcher(treeOrFetcher);
		}
		this.#material = material;
		this.#options = { ...LOD_POINT_CLOUD_DEFAULTS, ...options };
		this.raycasting = { ...LOD_POINT_CLOUD_RAYCASTING_DEFAULTS, ...this.#options.raycasting };

		// The visible nodes strategy should be per cloud and not per tree, since
		// multiple views per tree are allowed
		this.visibleNodesStrategy = this.tree.visibleNodesStrategy.clone();

		this.position.setFromMatrixPosition(this.lodTreeFetcher.tree.worldMatrix);
		this.quaternion.setFromRotationMatrix(this.lodTreeFetcher.tree.worldMatrix);
		this.matrixWorldNeedsUpdate = true;

		// Listen to event of points being downloaded
		this.#fetcherEvDetacher = this.lodTreeFetcher.nodeReady.on((event) => {
			this.#handlePointsReceived(event.nodeIdx, event.points);
		});

		// The tree bounding box includes the tree offset that is copied into this LodPointCloud's transform
		this.boundingBox = this.tree.boundingBox.clone().applyMatrix4(this.tree.worldMatrixInverse);

		// Initialize the raycast algorithm
		this.#raycastAlgo = new LodPointCloudRaycast(this.#lodFetcher.tree, this.#nodesInGPU, this.#clipStatus);
	}

	/** @returns a new LodPointCloud referencing but not owning the same point source of this point cloud */
	createView(): LodPointCloud {
		const newView = new LodPointCloud(this.#lodFetcher, this.material.clone(), {
			lodCachingStrategy: this.cacheCleanComputer,
			raycasting: { ...this.raycasting },
		});
		newView.visibleNodesStrategy = this.visibleNodesStrategy.clone();
		return newView;
	}

	/**
	 * If the tree has a root node with points then pre-load it so when we show the
	 * pointcloud we already have an overview.
	 *
	 * If the tree does not have a proper root node, so the first valid level is
	 * already on multiple children skip the pre-loading for now
	 */
	preloadRootNode(): void {
		// Find the first node in the tree with points
		let firstNodeWithPoints = this.tree.getNode(this.tree.root.id);
		while (firstNodeWithPoints.numPoints === 0 && firstNodeWithPoints.children.length === 1) {
			firstNodeWithPoints = firstNodeWithPoints.children[0];
		}

		// this tree does not have a proper root node so we skip pre-loading
		if (firstNodeWithPoints.numPoints === 0) return;

		this.lodTreeFetcher.requestNodes([{ id: firstNodeWithPoints.id, weight: Number.POSITIVE_INFINITY }], this);
	}

	/**
	 * Dispose all resources for this object
	 */
	dispose(): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		this.#material?.dispose();
		this.removeAllNodes();
		// We need to tell to the fetcher that this object
		// does not need any nodes anymore, otherwise the fetcher will keep
		// references to this object and keep it alive even when it should be
		// deallocated.
		while (this.#nodesInMemory.size > 0) {
			const [n] = this.#nodesInMemory.keys();
			// Removing points of node 'n' from GPU and from RAM.
			const node = this.#nodesInMemory.get(n);
			node?.points.dispose(false);
			this.#nodesInMemory.delete(n);
		}

		// Remove this instance from the fetcher
		this.#lodFetcher.removeClient(this);

		// Stop listening to the fetcher.
		this.#fetcherEvDetacher?.dispose();
		this.#fetcherEvDetacher = null;

		// Dispose the fetcher itself if we're owning it
		this.#lodFetcher.disposeIfNoClients();
	}

	/** @inheritdoc */
	protected computeVisibleNodes(camera: Camera, screenSize: Vector2): WeightedNode[] {
		this.#clipStatus.update(this.#material.clippingPlanes, this.#matrixWorldInv);
		const ret = this.visibleNodesStrategy.compute(
			this.tree,
			this.matrixWorld,
			camera,
			screenSize,
			this.#clipStatus.isClipping ? this.#clipStatus.localFrustum : undefined,
		);
		if (ret.length === 0) return ret;
		if (this.#subsampledRendering.enabled) {
			const maxNodes = Math.min(
				this.#subsampledRendering.maxNodes,
				Math.floor(ret.length * this.#subsampledRendering.fraction),
				ret.length - 1,
			);
			this.#subsampledRendering.minWeight = ret[maxNodes].weight;
		} else {
			this.#subsampledRendering.minWeight = 0.0;
		}
		return ret;
	}

	/** @inheritdoc */
	protected requestNodes(nodes: WeightedNode[]): void {
		// Ask the fetcher to fetch the nodes to load. When the download of each node is finished, handlePointsReceived() will be called
		this.lodTreeFetcher.requestNodes(nodes, this);
	}

	/** @inheritdoc */
	protected cacheOrDisposeNodes(unusedNodes: WeightedNode[]): void {
		const nodesToDelete = this.#options.lodCachingStrategy.computeDisposableNodes(
			this.#nodesInMemory,
			this.#nodesInGPU,
		);
		for (const n of nodesToDelete) {
			// Removing points of node 'n' from GPU and from RAM.
			const node = this.#nodesInMemory.get(n);
			node?.points.dispose(false);
			this.#nodesInMemory.delete(n);
			// Tell the fetcher that this object does not need this node anymore.
			this.lodTreeFetcher.releaseNode(n, this);
			// After many tests, we have validated that, if this is the only client of the fetcher,
			// then node 'n' is guaranteed to be 'NotInUse' after the 'releaseNode' call.
		}
		// Iterate through the unusedNodes. The ones that should remain in the cache have now
		// the 'InUse' state. So we cancel the download of the remaining ones.
		for (const n of unusedNodes) {
			const treeNode = this.tree.getNode(n.id);
			if (treeNode.state === NodeState.Downloading || treeNode.state === NodeState.WaitingForDownload) {
				this.lodTreeFetcher.releaseNode(n.id, this);
			}
		}
	}

	/** @inheritdoc */
	protected getNodeInGPU(nodeIdx: number): NodeCacheElement | undefined {
		return this.#nodesInGPU.get(nodeIdx);
	}

	/** @inheritdoc */
	protected getNodeInMemory(nodeIdx: number): NodeCacheElement | undefined {
		return this.#nodesInMemory.get(nodeIdx);
	}

	/** @inheritdoc */
	protected setNodeVisible(node: number, nodeData: NodeCacheElement): void {
		// By design we are sure that in the line below 'nodeData' can be downcasted to PointsCacheElement,
		// because we construct it as such.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const pointsData = nodeData as PointsCacheElement;
		this.add(pointsData.points);
		this.#nodesInGPU.set(node, pointsData);
		this.#totPointsInGPU += pointsData.pointCount;
	}

	/** @inheritdoc */
	protected setNodeNotVisible(node: number, nodeData: NodeCacheElement): void {
		// By design we are sure that in the line below 'nodeData' can be downcasted to PointsCacheElement,
		// because we construct it as such.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const pointsData = nodeData as PointsCacheElement;
		this.remove(pointsData.points);
		this.#nodesInGPU.delete(node);
		this.#totPointsInGPU -= pointsData.pointCount;
	}

	/** @inheritdoc */
	override updateCamera(camera: Camera, screenSize: Vector2): void {
		super.updateCamera(camera, screenSize);
		this.#checkSubsampledRenderingVisibility();
	}

	/**
	 * Computes which of the visible nodes should
	 * be rendered according to subsampled rendering
	 *
	 */
	#checkSubsampledRenderingVisibility(): void {
		for (const node of this.currentVisibleNodes) {
			const gpuNode = this.#nodesInGPU.get(node.id);
			if (gpuNode) {
				gpuNode.points.visible = node.weight >= this.#subsampledRendering.minWeight;
			}
		}
	}

	/**
	 * Creates a threeJS PointCloud object that encapsulates the given 'points' VBO
	 * for the given node of the LOD structure.
	 *
	 * @param nodeIdx ID of the LOD node
	 * @param points points VBO to be stored in the PointCloud object
	 * @returns the PointCloud object with correct raycasting, updating and bounding box settings.
	 */
	#createPointCloudNode(nodeIdx: number, points: PointCloudBufferGeometry): PointCloud {
		const node = this.tree.getNode(nodeIdx);
		const p = new PointCloud(points, this.#material, { autoUpdateBoundingSphere: false });
		p.geometry.options.color.setHSL(node.depth / (this.tree.maxDepth - 1), 1, 0.5);
		// Disable pc picking by default as we provide a customized picking for LOD Clouds
		p.raycasting.enabled = false;
		const onBeforeRender = p.onBeforeRender.bind(p);
		p.onBeforeRender = (renderer, scene, camera, geometry, material) => {
			onBeforeRender(renderer, scene, camera, geometry, material);
			if (material instanceof AdaptivePointsMaterial) {
				material.updateNode(nodeIdx, node.depth);
			}
		};
		p.geometry.boundingBox = node.boundingBox;
		p.geometry.computeBoundingSphere();
		return p;
	}

	/** Checks whether the 'allPointsReceived' signal should be emitted. */
	#checkAllPointsReceived(): void {
		const allPointsReceived = this.#nodesInGPU.size === this.currentVisibleNodes.length;
		if (allPointsReceived && !this.#allPointsReceived) {
			this.allPointsReceived.emit();
		}
		this.#allPointsReceived = allPointsReceived;
	}

	/**
	 * Just after download, loads a node's points to GPU.
	 *
	 * @param nodeIdx Idx of node that is being loaded to GPU
	 * @param points Point data to be loaded
	 */
	#handlePointsReceived(nodeIdx: number, points: PointCloudBufferGeometry): void {
		const visible = this.getNodeVisibility(nodeIdx);
		if (visible) {
			// In a splitscreen scenario, it is possible that another view has requested
			// 'nodeIdx' while this view has already 'nodeIdx' in GPU or in cache.
			// Therefore, if this view already has the data for 'nodeIdx', there is nothing
			// to do and the function returns.
			if (this.#nodesInGPU.has(nodeIdx)) return;
			let nodeData = this.#nodesInMemory.get(nodeIdx);
			if (nodeData) {
				this.setNodeVisible(nodeIdx, nodeData);
				return;
			}
			// In the most common scenario, this view actually needs the points of 'nodeIdx'.
			// A new PointCloud object is created and initialized for the received points.
			const p = this.#createPointCloudNode(nodeIdx, points);
			nodeData = new PointsCacheElement(p, points.size, performance.now());
			this.#nodesInMemory.set(nodeIdx, nodeData);
			this.setNodeVisible(nodeIdx, nodeData);
			this.nodeReady.emit(nodeIdx);
			this.#checkAllPointsReceived();
		} else {
			// No cameras' current visible nodes contains the node fetched
			this.lodTreeFetcher.releaseNode(nodeIdx, this);
		}
	}

	/** @returns the nodes in GPU */
	get nodesInGPU(): Map<number, PointsCacheElement> {
		return this.#nodesInGPU;
	}

	/** @returns the nodes in Memory */
	get nodesInMemory(): Map<number, PointsCacheElement> {
		return this.#nodesInMemory;
	}

	/** @returns the LOD tree */
	get tree(): LodTree {
		return this.lodTreeFetcher.tree;
	}

	/** @returns how many tree nodes are currently loaded in GPU and being rendered. */
	get renderedNodesCount(): number {
		return this.#nodesInGPU.size;
	}

	/** @returns how many tree nodes are in memory whether they are rendered or not. */
	get nodesInMemoryCount(): number {
		return this.#nodesInMemory.size;
	}

	/** @returns how many points are being rendered right now. */
	get totPointsInGPU(): number {
		return this.#totPointsInGPU;
	}

	/** @returns the object responsible for downloading the tree nodes. */
	get lodTreeFetcher(): LodTreeFetcher {
		return this.#lodFetcher;
	}

	/** @inheritdoc */
	override raycast(raycaster: Raycaster, intersects: Intersection[]): void {
		if (!this.raycasting.enabled) return;
		this.#raycastAlgo.raycast(raycaster, intersects, this.raycasting, this.#matrixWorldInv);
	}

	/** Recomputes the inverse world matrix only if the world matrix changed */
	#maybeUpdateInverseWorldMatrix(): void {
		if (!this.matrixWorld.equals(this.#lastMatrixWorld)) {
			this.#matrixWorldInv.copy(this.matrixWorld).invert();
			this.#lastMatrixWorld.copy(this.matrixWorld);
		}
	}

	/** @inheritdoc */
	override updateMatrixWorld(force?: boolean): void {
		super.updateMatrixWorld(force);
		this.#maybeUpdateInverseWorldMatrix();
	}

	/** @inheritdoc */
	override updateWorldMatrix(updateParents: boolean, updateChildren: boolean): void {
		super.updateWorldMatrix(updateParents, updateChildren);
		this.#maybeUpdateInverseWorldMatrix();
	}

	/** @returns The material used to render all point cloud chunks */
	get material(): Material {
		return this.#material;
	}

	/** Sets the material used to render all point cloud chunks */
	set material(m: Material) {
		this.#material.dispose();
		this.#material = m;
		for (const p of this.#nodesInMemory.values()) {
			p.points.material = this.#material;
		}
	}

	/**
	 *
	 * @returns Whether subsampled rendering is enabled.
	 */
	getSubsampledRenderingOn(): boolean {
		return this.#subsampledRendering.enabled;
	}

	/**
	 * @param on New enablement state of subsampled rendering.
	 */
	setSubsampledRenderingOn(on: boolean): void {
		this.#subsampledRendering.enabled = on;
	}

	/**
	 *
	 * @returns The fraction of nodes to be rendered when subsampled rendering is on
	 */
	getSubsampledRenderingFraction(): number {
		return this.#subsampledRendering.fraction;
	}

	/**
	 * @param f The fraction of visible nodes that should be rendered.
	 * Accepted values range in [0, 1].
	 */
	setSubsampledRenderingFraction(f: number): void {
		this.#subsampledRendering.fraction = f;
	}

	/** @returns the maximum amount of LOD nodes that will be rendered when subsampled rendering is active */
	getSubsampledRenderingMaxNodes(): number {
		return this.#subsampledRendering.maxNodes;
	}

	/** @param m the maximum amount of LOD nodes that will be rendered when subsampled rendering is active */
	setSubsampledRenderingMaxNodes(m: number): void {
		this.#subsampledRendering.maxNodes = m;
	}

	/** @returns whether raycast should guarantee real time performances, giving up a bit of precision */
	get realTimeRaycasting(): boolean {
		return this.#raycastAlgo.realTimeRaycasting;
	}

	/** Sets whether raycast should guarantee real time performances, giving up a bit of precision */
	set realTimeRaycasting(r: boolean) {
		this.#raycastAlgo.realTimeRaycasting = r;
	}
}
