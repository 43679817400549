/**
 * Check if the predicate is valid. If it is false, throw an error.
 *
 * @param predicate The condition to check
 * @param message The message associated to the exception
 */
export function assert(
  predicate: unknown,
  message?: string,
): asserts predicate {
  if (!predicate) {
    throw new Error(message ?? "Assertion failed");
  }
}
