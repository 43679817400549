import { assert } from "@faro-lotv/foundation";
import { LinearFilter, Texture } from "three";
import { useCachedTexture } from "./use-cached-texture";

// Rasterize a little bigger than pixel size so we're crispy even on high-dpi display
// without the need to update the texture if the dpr changes
export const SVG_DPR_FACTOR = 4;

/**
 * Function to load a svg as a Texture with a specific pixel size
 *
 * @param url The url of the svg to load
 * @param width The width in pixel we want to rasterize the svg to, in case not provided, the svg defined width will be used
 * @param height The height in pixel we want to rasterize the svg to, in case not provided, the svg defined height will be used
 * @returns A Texture for this svg
 */
export function useSvg(url: string, width?: number, height?: number): Texture {
  assert(
    width === undefined || width > 0,
    "width should be strictly positive or undefined to use the default svg width",
  );
  assert(
    height === undefined || height > 0,
    "height should be strictly positive or undefined to use the default svg height",
  );

  const cacheKey = `${url}|${width}|${height}`;

  const texture = useCachedTexture(cacheKey, () => {
    const image = document.createElement("img");
    // If the crossOrigin is not set to anonymous, it gives an error while loading the texture as given below
    // Failed to execute 'texImage2D' on 'WebGL2RenderingContext': The image element contains cross-origin data, and may not be loaded
    image.crossOrigin = "anonymous";
    if (width) image.width = width * SVG_DPR_FACTOR;
    if (height) image.height = height * SVG_DPR_FACTOR;
    image.src = url;

    const tex = new Texture(image);
    tex.minFilter = LinearFilter;
    tex.generateMipmaps = true;
    image.onload = () => {
      tex.needsUpdate = true;
    };
    return tex;
  });

  return texture;
}
