/**
 * A loader is an object that loads data for another object
 */
export abstract class Loader {
	// A tag used to implement the hasLoader type assertion
	readonly type = "LotvLoader";
	/** Function to ask the loader to load more data if available */
	abstract loadMore(): void;
	/** Function to ask the loader to cancel all pending downloads */
	abstract cancelPending(): void;
}

/**
 * A type WithLoader is a type that have an attached loader property with the loader instance
 */
export type WithLoader<T extends Loader = Loader> = {
	loader: T;
};

/**
 * Type assertion to check if a type has a loader attached
 *
 * @param x The object we want to assert
 * @returns true if the object has a loader attached
 */
export function hasLoader<O>(x: O & Partial<WithLoader>): x is O & WithLoader {
	return x.loader?.type === "LotvLoader";
}

/**
 * An interface for an object without the loader property
 * to be used in attachLoader to make sure we're not overwriting an already existing
 * loader property on an object
 */
export type WithoutLoader = {
	loader?: never;
};

/**
 * Attach a loader to an object
 *
 * @param x The object we want to attach the loader to
 * @param loader The loader object
 * @returns The object with the loader attached
 */
export function attachLoader<O, L extends Loader>(x: O & WithoutLoader, loader: L): O & WithLoader<L> {
	return Object.assign(x, { loader });
}
