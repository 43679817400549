import { ShaderMaterial, Texture, CubeTexture, Vector2, Matrix4, DoubleSide, Color } from "three";
import { makeOptionalUniform, makeUniform } from "./Uniforms";

import dollhouseTransitionVert from "../Shaders/PanoColoredDollhouse.vert";
import dollhouseTransitionFrag from "../Shaders/PanoColoredDollhouse.frag";
import { EnvironmentMap } from "../Utils";

/**
 * The material used to render a dollhouse (mesh or pointcloud) colored using two pano images
 * the color of one fragment will be computed blending the source and target pano image texture
 * using the panoBlending factor
 * A panoBlending === 0 will use only the source pano texture
 * A panoBlending === 1 will use only the target pano texture
 * Intermediate value will blend the colors from the two textures
 */
export class PanoColoredDollhouseMaterial extends ShaderMaterial {
	override uniforms = {
		/** The original texture of the dollhouse */
		uMeshTexture: makeOptionalUniform<Texture>(),
		/** The starting environment map, i.e. a cube texture */
		uCurTex: makeOptionalUniform<CubeTexture>(),
		/** The target environment map, i.e. a cube texture */
		uTarTex: makeOptionalUniform<CubeTexture>(),
		/** The viewport dimension in pixels */
		uViewport: makeOptionalUniform<Vector2>(),
		/** The inverse model matrix for the starting environment map */
		uCurInvMatrix: makeOptionalUniform<Matrix4>(),
		/** The inverse model matrix for the target environment map */
		uTarInvMatrix: makeOptionalUniform<Matrix4>(),
		/** The blending factor between the two environment maps */
		uTransitionStep: makeUniform<number>(0),
		/** The default fallback color if both texture and vertex colors are missing */
		uColor: makeUniform<Color>(new Color()),
	};
	override vertexShader = dollhouseTransitionVert;
	override fragmentShader = dollhouseTransitionFrag;
	override side = DoubleSide;

	defines = {
		HAS_MESH_TEXTURE: 0,
	};

	#blendFactor = 0;

	/**
	 * Construct the material initializing the shaders.
	 *
	 * @param source The 3D object associated to this material.
	 * @param target The image to visualize at the of the transition.
	 */
	constructor(source: EnvironmentMap, target: EnvironmentMap) {
		super();
		this.updateSource(source);
		this.updateTarget(target);
	}

	/**
	 * Update the cube texture for the source position
	 *
	 * @param source The source environment snapshot
	 * @returns The previously used cube texture
	 */
	updateSource(source: EnvironmentMap): CubeTexture | null {
		const prev = this.uniforms.uCurTex.value;
		const mMatrixCur = new Matrix4().makeTranslation(source.position.x, source.position.y, source.position.z);
		this.uniforms.uCurInvMatrix.value = mMatrixCur.invert();
		this.uniforms.uCurTex.value = source.map;
		return prev;
	}

	/**
	 * Update the cube texture for the source position
	 *
	 * @param target The target environment snapshot
	 * @returns The previously used cube texture
	 */
	updateTarget(target: EnvironmentMap): CubeTexture | null {
		const prev = this.uniforms.uTarTex.value;
		const mMatrixTar = new Matrix4().makeTranslation(target.position.x, target.position.y, target.position.z);
		this.uniforms.uTarInvMatrix.value = mMatrixTar.invert();
		this.uniforms.uTarTex.value = target.map;
		return prev;
	}

	/**
	 * Update all the uniforms used by this material.
	 *
	 * @param viewportSize the size of the viewport
	 * @param color the basic color of the dollhouse
	 * @param meshTexture the original mesh texture to use for point not covered by the snapshots
	 */
	update(viewportSize: Vector2, color: Color, meshTexture?: Texture): void {
		// Other uniforms
		this.uniforms.uViewport.value = viewportSize;
		this.uniforms.uMeshTexture.value = meshTexture ?? null;
		this.defines.HAS_MESH_TEXTURE = meshTexture ? 1 : 0;
		this.uniforms.uColor.value = color;
		this.uniforms.uTransitionStep.value = this.#blendFactor;
		this.uniformsNeedUpdate = true;
	}

	/** Change the current blending factor, 0 use only source pano texture, 1 use only target pano texture */
	set blendFactor(percentage: number) {
		this.#blendFactor = percentage;
	}

	/** @returns the current pano blending factor */
	get blendFactor(): number {
		return this.#blendFactor;
	}
}
