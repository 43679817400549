import frag from "../Shaders/LineMaterial.frag";
import vert from "../Shaders/LineMaterial.vert";

import {
	LineMaterial as LineMaterialOrig,
	LineMaterialParameters as ParametersOrig,
} from "three/examples/jsm/lines/LineMaterial.js";

/** All the custom constructions parameters for the LineMaterial */
export type LineMaterialParameters = ParametersOrig & {
	/** An offset used to move the line on the camera zPlane while rendering */
	zOffset?: number;
};

/**
 * An extension of the ThreeJS LineMaterial with:
 * 1. custom z-offset
 * 2. anti aliasing
 */
export class LineMaterial extends LineMaterialOrig {
	/** @returns the offset that will be applied to the line z camera position while rendering */
	get zOffset(): number {
		return this.uniforms.zOffset.value ?? 0;
	}

	/** Change the camera space z offset used while rendering */
	set zOffset(offset: number) {
		this.uniforms.zOffset.value = offset;
		this.uniformsNeedUpdate = true;
	}

	/**
	 * Initialize a new LineMaterial
	 *
	 * @param parameters to customize the line rendering
	 */
	constructor(parameters?: LineMaterialParameters) {
		const baseParameters = Object.fromEntries(
			Object.entries(parameters ?? {}).filter(([key]) => key !== "zOffset"),
		);
		super(baseParameters);
		this.uniforms.zOffset = {
			value: parameters?.zOffset ?? 0,
		};
		this.vertexShader = vert;
		this.fragmentShader = frag;
	}
}
