import { useState } from "react";
import { ColorRepresentation } from "three";
import { ClearPass as ThreeClearPass } from "three/examples/jsm/postprocessing/ClearPass.js";
import { attachPass } from "../attach-utils";

export type ClearPassProps = {
  /**
   * The clear color to be used
   *
   * @default white
   */
  clearColor?: ColorRepresentation;

  /**
   * Opacity of the selected clear color
   *
   * @default 1.0
   */
  clearAlpha?: number;

  /**
   * Whether the pass is enabled
   *
   * @default true
   */
  enabled?: boolean;
};

/**
 * @returns An EffectPipeline pass that clears the output FBO with the given clear color.
 */
export function ClearPass({
  clearColor = "0xffffff",
  clearAlpha = 1.0,
  enabled = true,
}: ClearPassProps): JSX.Element {
  const [pass] = useState(() => new ThreeClearPass());

  return (
    <primitive
      object={pass}
      attach={attachPass}
      // Following props are part of the pass object
      /* eslint-disable react/no-unknown-property */
      clearColor={clearColor}
      clearAlpha={clearAlpha}
      enabled={enabled}
    />
  );
}
