import { EffectPipelineWithSubScenes as LotvEffectPipelineWithSubScenes } from "@faro-lotv/lotv";
import { RenderCallback, useThree } from "@react-three/fiber";
import { PropsWithChildren, useCallback, useState } from "react";
import { useViewportRef } from "../hooks";
import { CustomRenderCall } from "../utils/custom-render-call";

type EffectPipelineWithSubScenesProps = PropsWithChildren<{
  /** True to enable this effect pipeline @default true */
  enabled?: boolean;
}>;

/**
 * @returns a react three fiber wrapper for a Lotv EffectPipeline
 */
export function EffectPipelineWithSubScenes({
  children,
  enabled = true,
}: EffectPipelineWithSubScenesProps): JSX.Element {
  const gl = useThree((s) => s.gl);
  const scene = useThree((s) => s.scene);
  const camera = useThree((s) => s.camera);
  const viewport = useViewportRef();

  const [pipeline] = useState(
    () => new LotvEffectPipelineWithSubScenes(gl, scene, camera),
  );

  const renderCallback = useCallback<RenderCallback>(
    ({ gl, size, scene, camera }, delta) => {
      const sz = viewport.current ?? size;
      if (sz.width === 0 || sz.height === 0) return;
      const dpr = gl.getPixelRatio();
      pipeline.setSize(sz.width, sz.height, dpr);
      pipeline.changeScene(scene);
      pipeline.changeCamera(camera);
      pipeline.render(delta);
    },
    [pipeline, viewport],
  );

  return (
    <>
      {enabled && <CustomRenderCall callback={renderCallback} />}
      <primitive object={pipeline}>{children}</primitive>
    </>
  );
}
