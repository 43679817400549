#define NEIGHBOUR_COUNT 8

precision mediump float;
precision mediump int;

// Values of sliders and buttons in the overlay
uniform float edlStrength;
uniform float radius;
uniform bool showShadeOnly;
uniform bool showBlackOutlines;
uniform float depthDiscontinuityBias;

// Attachments of the fbo
uniform sampler2D uEDLColor;
uniform sampler2D uEDLDepth;

// Inverse of the projection matrix
uniform mat4 uInvProj;

// Texture coordinate (its value is between 0 and 1)
varying vec2 vUv;

const vec2 neighbours[NEIGHBOUR_COUNT] = vec2[]( 
	vec2(1.0, 0.0),
	vec2(0.707106781, 0.707106781),
	vec2(0.0, 1.0),
	vec2(-0.707106781, 0.707106781),
	vec2(-1.0, 0.0),
	vec2(-0.707106781, -0.707106781),
	vec2(0.0, -1.0),
	vec2(0.707106781, -0.707106781)
);



float unprojectDepth(float depth) {
	if(depth == 1.0)
		return 0.0;
	depth = depth * 2.0 - 1.0;
	vec4 dcamera = uInvProj * vec4(0.0, 0.0, depth, 1.0);
	float ret = -dcamera.z / dcamera.w;
	
	return log2(ret);
}

// This function implements an edge detection algorithm
// It can return 0 or a positive number
// The bigger the number, the bigger the difference in depth with its neighbours
float CalculateObscurance(float depth, vec2 sz){
	vec2 uvRadius = radius / sz;
	
	float sum = 0.0;
	
	for(int i = 0; i < NEIGHBOUR_COUNT; i++) {
		vec2 uvNeighbor = vUv + uvRadius * neighbours[i];
		float neighbourDepthNDC = texture2D(uEDLDepth, uvNeighbor).r;
		// If the neighbour is an empty pixel, this is a border pixel and we give it a heavy black shade.
		if(neighbourDepthNDC == 1.0) {
			if (showBlackOutlines){
				sum += 100.0;
			}else if (100.0 < depthDiscontinuityBias){
				// showBlackOutlines is false and the discontinuity is less than the discontinuity bias
				sum += 100.0;
			}
		}
		// Only pixels that are behind the neighbours receive shading.
		else if(neighbourDepthNDC < gl_FragDepth) {
			float depthDiscontinuity = depth - unprojectDepth(neighbourDepthNDC);

			// take into account this value only if the discontinuity is less than the bias 
			if (showBlackOutlines || abs(depthDiscontinuity) < depthDiscontinuityBias){
				// 'max' below allows to shade pixels that are behind neighboring pixels
				sum += max(0.0, depthDiscontinuity);
			}
		}
	}

	return sum / float(NEIGHBOUR_COUNT);	
}

void main(){
	vec2 sz = vec2(textureSize(uEDLDepth, 0));
	// Texture coordinate that takes into account the size of the texture
	// It indicates a specific pixel in the texture
	ivec2 tc = ivec2(sz * vUv);

	// Color of the texel, in RGBA format
	vec4 cEDL = texelFetch(uEDLColor, tc, 0);
	
	gl_FragDepth = texelFetch(uEDLDepth, tc, 0).r;
	if(gl_FragDepth == 1.0){
		gl_FragColor = cEDL;
		return;
	}

	float depth = unprojectDepth(gl_FragDepth);
	float res = CalculateObscurance(depth, sz);
	float shade = exp(-res * edlStrength);

	gl_FragColor = showShadeOnly ? vec4(shade, shade, shade, 1.0) : vec4(cEDL.rgb * shade, 1.0);
}
