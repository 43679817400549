/**
 * Possible states of a Task in the Progress API
 *
 * docs - https://progress.api.dev.holobuilder.eu/swagger/index.html
 */
export enum BackgroundTaskState {
  /** A task has been created */
  created = "Created",

  /** A task has been scheduled */
  scheduled = "Scheduled",

  /** The task is inprogress */
  started = "Started",

  /** The task has successfully completed */
  succeeded = "Succeeded",

  /** The task has been canceled by the user */
  aborted = "Aborted",

  /** The task has failed */
  failed = "Failed",
}

export const ActiveBackgroundTaskOrderedList = [
  BackgroundTaskState.started,
  BackgroundTaskState.scheduled,
  BackgroundTaskState.created,
];

/**
 * Check if a background task is active (running or will run soon)
 *
 * @param taskState to check
 * @returns true if the task is not finished or failed
 */
export function isBackgroundTaskActive(
  taskState: BackgroundTaskState,
): boolean {
  return ActiveBackgroundTaskOrderedList.includes(taskState);
}
