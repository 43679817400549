import { ShaderMaterial, CubeTexture, Matrix4, LinearFilter, Camera, WebGLRenderer, Vector4 } from "three";
import { makeUniform } from "./Uniforms";
import frag from "../Shaders/CubeMap.frag";
import vert from "../Shaders/FullScreenQuad.vert";

/**
 * A material to render cube maps
 */
export class CubeMapMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uTex: makeUniform<CubeTexture | null>(null),
		uViewport: makeUniform(new Vector4()),
		uInvPVMMatrix: makeUniform(new Matrix4()),
		uOpacity: makeUniform(1),
	};

	/** @returns the currently bound texture */
	get texture(): CubeTexture | null {
		return this.uniforms.uTex.value;
	}

	/**
	 * Update the current texture
	 *
	 * @param texture The new texture
	 * @returns the previous assigned texture if present
	 */
	updateTexture(texture: CubeTexture): CubeTexture | null {
		const prev = this.uniforms.uTex.value;
		if (texture !== this.uniforms.uTex.value) {
			this.uniforms.uTex.value = texture;
			if (!texture.isRenderTargetTexture) {
				this.uniforms.uTex.value.minFilter = LinearFilter;
				this.uniforms.uTex.value.generateMipmaps = false;
				this.uniforms.uTex.value.needsUpdate = true;
			}
		}
		return prev;
	}

	/**
	 * Update uniforms needed for this material that may change at every render
	 *
	 * @param worldModelMatrix the pano world model matrix
	 * @param camera the current active camera
	 * @param renderer the current renderer
	 */
	update(worldModelMatrix: Matrix4, camera: Camera, renderer: WebGLRenderer): void {
		const vMatrix = new Matrix4().extractRotation(camera.matrixWorld).invert();
		vMatrix.setPosition(0, 0, 0);

		const mMatrix = worldModelMatrix.clone();
		mMatrix.setPosition(0, 0, 0);

		const mvMatrix = new Matrix4();
		mvMatrix.multiplyMatrices(vMatrix, mMatrix);
		mvMatrix.setPosition(0, 0, 0);

		const pvmMatrix = new Matrix4();
		pvmMatrix.multiplyMatrices(camera.projectionMatrix, mvMatrix);
		this.uniforms.uInvPVMMatrix.value = pvmMatrix.clone().invert();

		const renderTarget = renderer.getRenderTarget();
		if (renderTarget) {
			/** Custom framebuffer */
			this.uniforms.uViewport.value = renderTarget.viewport;
		} else {
			/** Default framebuffer */
			this.uniforms.uViewport.value = renderer
				.getViewport(new Vector4())
				.multiplyScalar(renderer.getPixelRatio());
		}

		this.uniforms.uOpacity.value = this.opacity;
		this.uniformsNeedUpdate = true;
	}
}
