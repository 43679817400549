/**
 * Get the extension of the provided URI
 *
 * @param uri The URI to grab the extension from
 * @returns Either the extension, or `undefined` in case the URI does not have an extension
 */
export function getFileExtension(uri?: string): string | undefined {
  return uri?.includes(".") ? uri.split(".").pop() : undefined;
}

export function removeExtension(fileName: string): string;
export function removeExtension(fileName?: string): string | undefined;
/**
 * @returns a filename without the extension
 * @param fileName to remove the extension from
 */
export function removeExtension(fileName?: string): string | undefined {
  if (fileName === undefined) return;
  const lastDot = fileName.lastIndexOf(".");
  if (lastDot !== -1) {
    return fileName.substring(0, lastDot);
  }
  return fileName;
}

type SplitFileNameAndExtensionReturn = {
  /** File name without the extension */
  fileName: string;

  /** Extension of the given file name*/
  extension?: string;
};
/**
 * @returns the file name and extension
 * @param fileName name of the file that needs to be split into extension and name
 */
export function splitFileNameAndExtension(
  fileName: string,
): SplitFileNameAndExtensionReturn {
  const splitResult = fileName.split(".");
  const extension = splitResult.length > 1 ? splitResult.pop() : undefined;
  return { fileName: splitResult.join("."), extension };
}

const IMAGE_FILE_EXTENSIONS = ["png", "jpg", "jpeg"];

/**
 * @returns whether the file extensions is a known image file extension.
 * @param extension the extension of the file to check
 */
export function isImageFileExtension(extension: string = ""): boolean {
  return IMAGE_FILE_EXTENSIONS.includes(extension.toLowerCase());
}

/**
 * @returns whether the file extensions is a known document file extension.
 * @param extension the extension of the file to check
 */
export function isDocumentFileExtension(extension: string = ""): boolean {
  return extension.toLowerCase() === "pdf";
}
