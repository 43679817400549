const float PI = 3.141592654;

// viewport dimension
uniform highp vec4 uViewport;
// panorama texture  (color)
uniform samplerCube uCurTex;
uniform samplerCube uTarTex;
uniform mat4 uCurInvPVMMatrix;
uniform mat4 uTarInvPVMMatrix;
uniform float uBlendStep;

/**
 * Compute the tex coordinate from the ndc frag coordinate
 *
 * @param v - ndc coordinate of the frag [-1, 1]
 * @param invPVMMatrix - inverse project view model matrix of the cubemap we want to sample on
 */
vec3 computeTexCoord(vec4 v, mat4 invPVMMatrix) {
    v = invPVMMatrix * v;
    return normalize(v.xyz / v.w);
}


void main() {
    // Compute ndc coordinates in the viewport
    // depth 0.9 is used because 1.0 fails on android, -1.0 fails on VR, 0.999 gives artifact on ios, 0.9 works everywhere
    vec4 ndc = vec4((gl_FragCoord.xy - uViewport.xy) * 2.0 / uViewport.zw - 1.0, 0.9, 1.0);
    // Get texture coordinates for the two cubemaps
    vec3 curUv = computeTexCoord(ndc, uCurInvPVMMatrix);
    vec3 tarUv = computeTexCoord(ndc, uTarInvPVMMatrix);
    // Blend the colors from the cubemaps using the blend step
    gl_FragColor = textureCube(uTarTex, tarUv) * uBlendStep + (1.0 - uBlendStep) * textureCube(uCurTex, curUv);
}
