import { Camera, Object3D, Plane, Raycaster, Vector3 } from "three";
import { computePointerNdcCoordinates } from "../utils/event-utils";

/** The render order of the annotation mesh. Using a higher value to make sure it is always rendered at the end */
const RENDER_ORDER = 1000;

/**
 * The class responsible for handling the logic to create annotations and callbacks
 * TODO: Update the logic to retain the aspect ratio of the mesh on pressing shift
 * https://faro01.atlassian.net/browse/SWEB-3680
 */
export class CreateAnnotationControlsLogic {
  #plane = new Plane();
  #isAnnotationBeingCreated = false;

  #startPoint = new Vector3();
  #startPointLocal = new Vector3();

  #endPoint = new Vector3();
  #endPointLocal = new Vector3();

  #cameraDirection = new Vector3();
  #pointInCameraDirection = new Vector3();

  #camera: Camera;
  #raycaster: Raycaster;
  #domElement: HTMLElement;

  #mesh: Object3D;
  #newMesh?: Object3D;

  /** Callback to notify the annotation changed */
  onAnnotationUpdated?: (mesh?: Object3D) => void;

  /** Callback to notify the user completed the annotation */
  onAnnotationCreated?: (mesh: Object3D) => void;

  /** Callback to notify the user started dragging the annotation */
  onDragStart?: () => void;

  /** Callback to notify the user drag finished */
  onDragEnd?: () => void;

  /**
   * @param camera the camera used to render the scene
   * @param raycaster the raycaster used to determine the intersection of the camera direction and the plane
   * @param domElement the dom element to attach the event listeners to
   * @param mesh the mesh to use as a reference to create the new mesh for every new annotation
   */
  constructor(
    camera: Camera,
    raycaster: Raycaster,
    domElement: HTMLElement,
    mesh: Object3D,
  ) {
    this.#camera = camera;
    this.#raycaster = raycaster;
    this.#domElement = domElement;
    this.#mesh = mesh;

    // Binding methods to the class instance
    this.#onPointerUp = this.#onPointerUp.bind(this);
    this.#onPointerDown = this.#onPointerDown.bind(this);
    this.#onPointerMove = this.#onPointerMove.bind(this);
    this.#onKeyDown = this.#onKeyDown.bind(this);
  }

  #reset = (): void => {
    // Reset the new mesh and start point as well as the flag to indicate the state of the annotation creation
    this.#startPoint.set(NaN, NaN, NaN);
    this.#newMesh = undefined;
    this.#isAnnotationBeingCreated = false;
  };

  #onPointerUp = (event: PointerEvent): void => {
    if (this.#newMesh) {
      this.onAnnotationCreated?.(this.#newMesh);
      event.stopPropagation();
      this.onDragEnd?.();
    }
    this.#reset();
  };

  #onPointerDown = (event: PointerEvent): void => {
    this.#camera.getWorldDirection(this.#cameraDirection);
    this.#camera
      .getWorldDirection(this.#pointInCameraDirection)
      .multiplyScalar(5)
      .add(this.#camera.position);

    // Sets the plane's properties as defined by a normal and an arbitrary coplanar point.
    this.#plane.setFromNormalAndCoplanarPoint(
      this.#cameraDirection,
      this.#pointInCameraDirection,
    );

    this.#getIntersectedPointOnPlane(event, this.#startPoint);

    // Transform start point from world space to local space relative to the camera.
    this.#startPointLocal.copy(this.#startPoint);
    this.#startPointLocal.applyMatrix4(this.#camera.matrixWorldInverse);

    // Enable the flag to indicate that an annotation is being created, if there is a valid start point
    this.#isAnnotationBeingCreated = !this.#startPoint.toArray().some(isNaN);
  };

  #onPointerMove = (event: PointerEvent): void => {
    event.stopPropagation();

    if (this.#isAnnotationBeingCreated) {
      if (!this.#newMesh) {
        // Create a new mesh using the reference mesh passed in the constructor
        this.#newMesh = this.#mesh.clone();

        this.#newMesh.quaternion.copy(this.#camera.quaternion);
        this.#newMesh.renderOrder = RENDER_ORDER;

        this.onDragStart?.();
      }

      this.#getIntersectedPointOnPlane(event, this.#endPoint);

      // Determine the midpoint between the start point and the current point,
      // and set the position of the new mesh to that point so that even when the mesh grows in size it is at start point
      this.#newMesh.position
        .addVectors(this.#startPoint, this.#endPoint)
        .divideScalar(2);

      // Transform the end point from world space to local space relative to the camera.
      this.#endPointLocal.copy(this.#endPoint);
      this.#endPointLocal.applyMatrix4(this.#camera.matrixWorldInverse);

      this.#newMesh.scale.set(
        Math.abs(this.#endPointLocal.x - this.#startPointLocal.x),
        Math.abs(this.#endPointLocal.y - this.#startPointLocal.y),
        1,
      );
      this.onAnnotationUpdated?.(this.#newMesh);
    }
  };

  #onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      this.#reset();
      this.onAnnotationUpdated?.(undefined);
    }
  };

  /**
   * @param event a pointer event
   * @param point a vector3 to store the intersected point
   * Calculates a point on the intersection of the camera direction and the plane
   */
  #getIntersectedPointOnPlane(event: PointerEvent, point: Vector3): void {
    const mouse = computePointerNdcCoordinates(event);
    this.#raycaster.setFromCamera(mouse, this.#camera);
    this.#raycaster.ray.intersectPlane(this.#plane, point);
  }

  /**
   * Attaches the event listeners to the dom element
   */
  start(): void {
    document.addEventListener("keydown", this.#onKeyDown, false);
    this.#domElement.addEventListener("pointerup", this.#onPointerUp, false);
    this.#domElement.addEventListener(
      "pointerdown",
      this.#onPointerDown,
      false,
    );
    this.#domElement.addEventListener(
      "pointermove",
      this.#onPointerMove,
      false,
    );
  }

  /**
   * Removes the event listeners from the dom element
   */
  stop(): void {
    document.removeEventListener("keydown", this.#onKeyDown, false);
    this.#domElement.removeEventListener("pointerup", this.#onPointerUp, false);
    this.#domElement.removeEventListener(
      "pointerdown",
      this.#onPointerDown,
      false,
    );
    this.#domElement.removeEventListener(
      "pointermove",
      this.#onPointerMove,
      false,
    );
  }
}
