import { AbortError } from "@faro-lotv/foundation";
import { CanvasTexture, LinearFilter, Texture } from "three";
import { lotvCache } from "./LotvCache";

export interface TextureFetchRequest {
	promise: Promise<Texture>;
	abort: AbortController;
}

/**
 * A class that provides texture loading. It is similar to the TextureLoader
 * shipped with three.js. However, it keeps a cache of textures already fetched,
 * it does the texture decoding in a separate system thread and not on the main thread,
 * and it is abortable.
 */
export class TextureLoader {
	/**
	 * Starts a texture fetching and loading operation.
	 * The resulting texture is optimized for LOD structures (no mipmaps)
	 * Mipmaps can be requested by setting the following props on the resulting texture:
	 *
	 * texture.generateMipmaps = true;
	 * texture.minFilter = LinearMipMapLinearFilter;
	 *
	 * @param url the url to fetch
	 * @returns an object to await or cancel the fetch
	 */
	load(url: string): TextureFetchRequest {
		const ac = new AbortController();
		return {
			promise: this.doLoad(url, ac.signal),
			abort: ac,
		};
	}

	/**
	 * @param url the url to fetch
	 * @param signal a signal to cancel the fetch
	 * @returns the loaded texture
	 */
	async doLoad(url: string, signal: AbortSignal): Promise<Texture> {
		try {
			let res = await lotvCache.match(url);
			if (!res) {
				res = await fetch(url, { signal });
				if (!res.ok) {
					throw new Error(`Unable to fetch from ${url}`);
				}
				await lotvCache.put(url, res.clone());
			}
			const bmp = await createImageBitmap(await res.blob(), {
				imageOrientation: "flipY",
				colorSpaceConversion: "none",
			});
			const texture = new CanvasTexture(bmp);

			texture.needsUpdate = true;
			texture.minFilter = LinearFilter;
			texture.generateMipmaps = false;
			texture.name = url;
			return texture;
		} catch (err) {
			if (signal.aborted) throw new AbortError(`Texture fetch from url: ${url}`);
			throw err;
		}
	}

	/**
	 * @param url the url to check
	 * @returns true if the url is cached
	 */
	async has(url: string): Promise<boolean> {
		const res = await lotvCache.match(url);
		return res !== undefined;
	}
}
