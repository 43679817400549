import { compareDateTimes } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";

/**
 * Ordering function to sort any elements with createdAt property from newest to oldest
 *
 * @param a first element
 * @param b second element
 * @returns a negative value if the a is newer than b, zero if they were created the same moment, and a positive value otherwise
 */
export function newestToOldest(
  a: Pick<IElement, "createdAt">,
  b: Pick<IElement, "createdAt">,
): number {
  return compareDateTimes(b.createdAt, a.createdAt);
}
