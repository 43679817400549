import { formControlClasses, menuClasses } from "@mui/material";
import { useState } from "react";
import { FaroMenu, FaroMenuProps } from "../menu";
import { DARK_COLORS, LIGHT_COLORS } from "./filter-menu-colors";
import {
  FilterMenuContent,
  FilterMenuContentProps,
  FilterMenuOptionType,
} from "./filter-menu-content";

export type FilterMenuProps = Pick<
  FaroMenuProps,
  "anchorEl" | "anchorOrigin" | "transformOrigin" | "open" | "dark"
> &
  Pick<
    FilterMenuContentProps,
    "headingLabel" | "options" | "onFilterChange" | "onResetFilter"
  > & {
    /**
     * List of selected options to start the menu with.
     * It must be a subset of the @param options because it will be checked against that list
     */
    initialSelectedOptions?: FilterMenuOptionType[];

    /** Callback when the filter menu is closed */
    onFilterMenuClose?(): void;
  };

/** @returns a button to trigger list of options which can be filtered with a search bar */
export function FilterMenu({
  open,
  headingLabel,
  options,
  initialSelectedOptions,
  onFilterChange,
  onResetFilter,
  onFilterMenuClose,
  dark = false,
  ...rest
}: FilterMenuProps): JSX.Element | null {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  const [selectedOptions, setSelectedOptions] = useState<
    FilterMenuOptionType[]
  >(initialSelectedOptions ?? []);

  return (
    <FaroMenu
      open={open}
      {...rest}
      dark={dark}
      onClose={() => onFilterMenuClose?.()}
      sx={{
        [`& .${menuClasses.paper}`]: {
          p: 0.5,
          pb: 0.5,
          maxHeight: "fit-content",
        },
        [`& .${menuClasses.list}`]: {
          m: 0,
          [`& .${formControlClasses.root}`]: {
            width: "100%",
          },
        },

        // scrollbar styles
        scrollbarWidth: "thin",
        ["&::-webkit-scrollbar-thumb"]: {
          bgcolor: `${colors.scrollbarThumbColor}33`,
          ["&:hover"]: {
            bgcolor: `${colors.scrollbarThumbColor}66`,
          },
        },
        scrollbarColor: `${colors.scrollbarThumbColor}33 ${colors.backgroundColor}`,
      }}
    >
      <FilterMenuContent
        headingLabel={headingLabel}
        options={options}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        onFilterChange={onFilterChange}
        onResetFilter={onResetFilter}
        dark={dark}
      />
    </FaroMenu>
  );
}
