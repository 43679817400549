import { log } from "@faro-lotv/foundation";
import { IElement } from "../i-element";
import {
  IElementBase,
  IElementType,
  IElementTypeHint,
} from "../i-element-base";

/**
 * Verify that an IElement has a specific type
 *
 * @param object to check
 * @param requiredType type to check for
 * @returns true if the iElement type matches the required type
 */
export function validateIElementWithType<RequiredType extends IElementType>(
  object: IElementBase,
  requiredType: RequiredType,
): object is IElement & { type: RequiredType } {
  if (object.type !== requiredType) {
    log(`IElement type is ${object.type} but ${requiredType} was expected`);
    return false;
  }
  return true;
}

/**
 * Verify that an IElement has a specific type hint
 *
 * @param object to check
 * @param requiredHint  to check for
 * @returns true if the iElement type matches the required type hint
 */
export function validateIElementWithTypeHint<
  RequiredHint extends IElementTypeHint,
>(
  object: IElementBase,
  requiredHint: RequiredHint,
): object is IElement & { typeHint: RequiredHint } {
  if (object.typeHint !== requiredHint) {
    log(`IElement type is ${object.typeHint} but ${requiredHint} was expected`);
    return false;
  }
  return true;
}

/**
 * Verify that an IElement has a specific type
 *
 * @param object to check
 * @param requiredType type to check for
 * @param requiredHint a type hint to check if defined
 * @returns true if the iElement type matches the required type
 */
export function validateIElementWithTypeAndHint<
  RequiredType extends IElementType,
  RequiredHint extends IElementTypeHint,
>(
  object: IElementBase,
  requiredType: RequiredType,
  requiredHint: RequiredHint,
): object is IElement & { type: RequiredType; typeHint: RequiredHint } {
  return (
    validateIElementWithType(object, requiredType) &&
    validateIElementWithTypeHint(object, requiredHint)
  );
}
