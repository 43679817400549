import { TypedEvent } from "@faro-lotv/foundation";
import { EventDispatcher, MOUSE, Object3D, PerspectiveCamera, Quaternion, Raycaster, Vector2, Vector3 } from "three";
import { SupportedCamera, pixels2m, pixels2radians } from "../Utils";
import { cartesian2spherical, clamp, degToRad, spherical2cartesian } from "../Utils/Math";
import { memberWithPrivateData } from "../Utils/MemoryUtils";
import { KeyboardEvents } from "./KeyboardEvents";
import { SyncableControls } from "./SyncableControls";
import { DoubleTouch, PointerCoords, TouchEvents, isMouseButton } from "./TouchEvents";

const Z_UP = 0;
const Y_UP = 1;

/** Distance to keep the pivot when the camera is moving "with" the pivot (fly mode) */
const PIVOT_DISABLED_DISTANCE = 0.01;

/** Min distance an obstacle need to be to make the camera slow down */
const PASSTHROUGH_DISTANCE = 0.5;

/** Distance of the next obstacle if there are no valid obstacles (define max speed in fly mode) */
const NO_OBSTACLE_DISTANCE = 5;

/**
 * The amount of inertia left after one second in a friction model
 *
 * @deprecated This value is only used for backwards compatibility
 */
const DEFAULT_INERTIA = 0.9;

/** Limit the max phi angle so we don't hit the degenerate 90° case but we still can look at the model from the top/bottom */
const MAX_PHI_DEG = 89.999;

/** Number multiplied when calculating the distance (in meters) traveled */
const DISTANCE_MULTIPLIER = 0.015;

/** Min pix2meter factor to use when the camera is too close to the pivot */
const MIN_PIVOT_DISTANCE_FACTOR = 0.15;

/** Max pix2meter factor to allow when the camera is too far to the pivot */
const MAX_PIVOT_DISTANCE_FACTOR = 15000;

/** Divisor factor to adjust wheel values */
const WHEEL_SENSITIVITY_FACTOR = 2000;

/** Min pix2meter factor to use when the camera is too close to the pivot */
const MIN_PIX2M_FACTOR = 0.01;

/** Max pix2meter factor to allow when the camera is too far to the pivot */
const MAX_PIX2M_FACTOR = 1000;

/** Value used to decrease the ortho camera's frustum planes by 10% */
const DEC_ORTHO_PLANE = 0.9;

/** Value used to increase the ortho camera's frustum planes by 10% */
const INC_ORTHO_PLANE = 1.1;

/** Value used in onWheel when calculating the factor to multiply with the ortho camera's frustum planes */
const ONWHEEL_ORTHO_PLANE_MULTIPLIER = 0.0005;

/** Time window in which the actual movement state is accumulated for a smoother experience with infrequent drag-events */
const ACTUAL_MOTION_SMOOTHING_TIME = 0.05;

/** This constant determines how soon the inertia rotation stops and the camera is steady again. */
const MIN_ROT_INERTIA_SPEED = 5e-4;

/** This constant determines how soon the inertia movement stops and the camera is steady again. */
const MIN_MOV_INERTIA_SPEED = 1e-2;

/** All possible movement types that can be triggered by a mouse drag */
export enum MovementType {
	/** Moving the camera along the project horizontal plane */
	HorizontalTranslation = 0,

	/** Move the camera along the camera plane */
	CameraPan = 1,

	/** Move the camera toward the camera direction */
	Dollying = 2,

	/** Rotate the camera around the pivot point */
	Rotating = 3,

	/** Move the camera up/down along the project vertical direction */
	VerticalTranslation = 4,
}

/**
 * This class contains all parameters that the interactor tracks to build the current
 * camera pose from them. The interactor remembers the rotation pivot coordinates,
 * the distance camera eye -> rotation pivot along the camera focal axis, the camera
 * pitch angle, the camera yaw angle.
 */
class CameraParams {
	/** Current pivot point the camera is targeting */
	rotationPivot = new Vector3();

	/** Desired distance from the pivot point, the pivot is considered disabled if this is < PIVOT_DISABLED_DISTANCE */
	camPivotDistance = 1;

	/** camera pitch angle, in radians. */
	phi = 0;

	/** camera yaw angle, in radians */
	theta = 0;

	/**
	 * Assigns the argument object's data to this object.
	 *
	 * @param rhs The assignment's right-hand side
	 */
	assign(rhs: CameraParams): void {
		this.rotationPivot.copy(rhs.rotationPivot);
		this.camPivotDistance = rhs.camPivotDistance;
		this.phi = rhs.phi;
		this.theta = rhs.theta;
	}

	/**
	 *
	 * @param rhs Other camera params to compare movement to
	 * @returns whether this camera is in a different position than rhs
	 */
	moved(rhs: CameraParams): boolean {
		return (
			Math.abs(this.camPivotDistance - rhs.camPivotDistance) > PIVOT_DISABLED_DISTANCE ||
			Math.abs(this.rotationPivot.x - rhs.rotationPivot.x) > PIVOT_DISABLED_DISTANCE ||
			Math.abs(this.rotationPivot.y - rhs.rotationPivot.y) > PIVOT_DISABLED_DISTANCE ||
			Math.abs(this.rotationPivot.z - rhs.rotationPivot.z) > PIVOT_DISABLED_DISTANCE
		);
	}
}

/** Initial values for a motion state */
const DEFAULT_MOVEMENT_SPEED = new Vector3();
const DEFAULT_CAM_PIVOT_DISTANCE_SPEED = 0;
const DEFAULT_PHI_SPEED = 0;
const DEFAULT_THETA_SPEED = 0;

/**
 * In this object we store the current movement properties of the different motion dimensions of the WalkOrbitControls.
 * Including Translation, Rotation and Pivot-distance speeds.
 */
class MotionState {
	/** Desired next update movement in 3d space m/s */
	movementSpeed = DEFAULT_MOVEMENT_SPEED.clone();

	/** Desired next update movement toward the pivot in m/s */
	camPivotDistanceSpeed = DEFAULT_CAM_PIVOT_DISTANCE_SPEED;

	/** Desired next update pitch rotation in radians/s */
	phiSpeed = DEFAULT_PHI_SPEED;

	/** Desired next update yaw rotation in radians/s */
	thetaSpeed = DEFAULT_THETA_SPEED;

	/** Resets the motion state to the default values */
	reset(): void {
		this.movementSpeed.copy(DEFAULT_MOVEMENT_SPEED);
		this.camPivotDistanceSpeed = DEFAULT_CAM_PIVOT_DISTANCE_SPEED;
		this.phiSpeed = DEFAULT_PHI_SPEED;
		this.thetaSpeed = DEFAULT_THETA_SPEED;
	}

	/**
	 * Copies the values of a motion state
	 *
	 * @param motionState The motion state to copy
	 */
	assign(motionState: MotionState): void {
		this.movementSpeed.copy(motionState.movementSpeed);
		this.camPivotDistanceSpeed = motionState.camPivotDistanceSpeed;
		this.phiSpeed = motionState.phiSpeed;
		this.thetaSpeed = motionState.thetaSpeed;
	}

	/**
	 * Add the given motionState to this one.
	 *
	 * @param motionState The motionState to add.
	 */
	add(motionState: MotionState): void {
		this.movementSpeed.add(motionState.movementSpeed);
		this.camPivotDistanceSpeed += motionState.camPivotDistanceSpeed;
		this.phiSpeed += motionState.phiSpeed;
		this.thetaSpeed += motionState.thetaSpeed;
	}

	/**
	 * Subtract the given motionState from this one.
	 *
	 * @param motionState The motionState to subtract.
	 */
	sub(motionState: MotionState): void {
		this.movementSpeed.sub(motionState.movementSpeed);
		this.camPivotDistanceSpeed -= motionState.camPivotDistanceSpeed;
		this.phiSpeed -= motionState.phiSpeed;
		this.thetaSpeed -= motionState.thetaSpeed;
	}

	/**
	 * Computes a new value for the input speed, decreasing it according to the
	 * given inertia parameter. If the speed value is below the threshold t,
	 * then the returned speed is zero and the inertial movement stops.
	 *
	 * @param speed The initial speed value
	 * @param inertia The inertia factor used to decrease speed
	 * @param t The minimum speed threshold below which the speed is zero and the camera stops.
	 * @returns The new speed value
	 */
	decreaseSpeed(speed: number, inertia: number, t: number): number {
		if (Math.abs(speed) > t) return speed * inertia;
		return 0;
	}

	/**
	 * Applies friction to the current inertial state.
	 *
	 * @param movementInertia Fraction of movement inertia kept after 1s of friction
	 * @param rotationInertia Fraction of rotation inertia kept after 1s of friction
	 * @param deltaTime simulation deltaTime
	 */
	applyFriction(movementInertia: number, rotationInertia: number, deltaTime: number): void {
		const movementInertiaTick = Math.pow(movementInertia, deltaTime);
		const rotationInertiaTick = Math.pow(rotationInertia, deltaTime);

		// Slow down movement
		this.camPivotDistanceSpeed = this.decreaseSpeed(
			this.camPivotDistanceSpeed,
			movementInertiaTick,
			MIN_MOV_INERTIA_SPEED,
		);
		this.movementSpeed.x = this.decreaseSpeed(this.movementSpeed.x, movementInertiaTick, MIN_MOV_INERTIA_SPEED);
		this.movementSpeed.y = this.decreaseSpeed(this.movementSpeed.y, movementInertiaTick, MIN_MOV_INERTIA_SPEED);
		this.movementSpeed.z = this.decreaseSpeed(this.movementSpeed.z, movementInertiaTick, MIN_MOV_INERTIA_SPEED);

		// Slow down rotation
		this.phiSpeed = this.decreaseSpeed(this.phiSpeed, rotationInertiaTick, MIN_ROT_INERTIA_SPEED);
		this.thetaSpeed = this.decreaseSpeed(this.thetaSpeed, rotationInertiaTick, MIN_ROT_INERTIA_SPEED);
	}

	/**
	 * Accelerates the pivotDistanceSpeed towards a targetSpeed
	 *
	 * @param targetSpeed The target speed of the pivotDistance
	 * @param acceleration The acceleration to use
	 * @param deltaTime simulation deltaTime
	 */
	acceleratePivotDistanceTowards(targetSpeed: number, acceleration: number, deltaTime: number): void {
		const pivotDistanceAcceleration = acceleration * deltaTime;

		const camPivotDistanceDiff = this.camPivotDistanceSpeed - targetSpeed;
		this.camPivotDistanceSpeed += clamp(
			camPivotDistanceDiff,
			-pivotDistanceAcceleration,
			pivotDistanceAcceleration,
		);
	}

	/**
	 * Accelerates the movement speed towards the given target speed.
	 *
	 * @param targetSpeed The target to accelerate towards.
	 * @param acceleration How quickly the target speed will be reached.
	 * @param deltaTime The time since the last frame, to make this framerate independent.
	 */
	accelerateMovementSpeedTowards = memberWithPrivateData(() => {
		// Reuse the same vector to avoid allocations
		const movementSpeedDiff = new Vector3();

		return (targetSpeed: Vector3, acceleration: number, deltaTime: number): void => {
			movementSpeedDiff.copy(targetSpeed).sub(this.movementSpeed);
			this.movementSpeed.add(movementSpeedDiff.clampLength(0, acceleration * deltaTime));
		};
	});

	/**
	 * Accelerates the rotation inertia towards a target rotation speed
	 *
	 * @param targetThetaSpeed The target speed of the theta-rotation
	 * @param targetPhiSpeed The target speed of the phi-rotation
	 * @param acceleration The acceleration to use
	 * @param deltaTime simulation deltaTime
	 */
	accelerateRotationSpeedTowards(
		targetThetaSpeed: number,
		targetPhiSpeed: number,
		acceleration: number,
		deltaTime: number,
	): void {
		const angleAcceleration = acceleration * deltaTime;

		const thetaDiff = targetThetaSpeed - this.thetaSpeed;
		this.thetaSpeed += clamp(thetaDiff, -angleAcceleration, angleAcceleration);

		const phiDiff = targetPhiSpeed - this.phiSpeed;
		this.phiSpeed += clamp(phiDiff, -angleAcceleration, angleAcceleration);
	}
}

/**
 * This interactor supports two rotation modes. The pointer/arrow keys
 * can rotate the view direction (like in a first-person shooter),
 * or can rotate the model (like in a 360 images viewer where the object
 * remains under the pointer while rotating)
 */
export enum PointerRotates {
	ViewDirection = "ViewDirection",
	Model = "Model",
}

/**
 * Purpose of this class is to provide a 3D interactor for mouse, keys and touch events,
 * that combines the behaviors of an orbit controller and a walk mode. Both Y-up and Z-up
 * cameras are supported by this interactor.
 *
 * Behaviors:
 *    Mouse Drag:   Each button can be configured separately to:
 * 					Rotate: Rotate the camera around the pivot if valid or around the camera itself
 *                  HorizontalTranslation: Move the camera along the project horizontal plane
 *                  CameraPan: Move the camera along the camera view plane
 *                  VerticalTranslation: Move the camera up/down along the project up direction
 *    Mouse Wheel:  If the projection is perspective, the camera translates back and forth along its view direction.
 *                  The translation speed depends on how close the camera is to an obstruction or the pivot point
 *                  If on the other hand the projection is orthographic, mouse wheel spin causes the ortho projection to zoom in and out.
 *    One-touch drag: orbiting around rotation pivot
 *    Two-touch drag: combination of
 *                     - translation on horizontal plane
 *                     - dollying along focal axis
 *                     - rotation around Z axis.
 *    Arrow keys:   orbits around the pivot point.
 *    WASD keys:    walk (Camera translates on its current horizontal plane).
 *    E key:        translates vertically up
 *    Q key:        translates vertically down.
 *
 * Camera zoom is NOT SUPPORTED by this interactor.
 *
 * Also, this interactor accounts for movement and rotation inertia. Inertia is present only in mouse interaction.
 * Touchscreen interaction goes without inertia.
 */
export class WalkOrbitControls extends EventDispatcher implements SyncableControls {
	/** The camera to be controlled */
	#camera: SupportedCamera;

	/** Whether the current controls are enabled */
	#enabled = true;

	/**  Movement type: horiz translating, dollying, rotating. */
	#movementType = MovementType.HorizontalTranslation;

	/** The last mouse position used to calculate a drag event's delta movement */
	#lastMousePos = new Vector2();

	/** the data in the member #currCamera are always synced with the current camera */
	#currCamera = new CameraParams();

	/** lastCamera is needed to compute the movement speed between lastCamera and currCamera for the inertia effect */
	#lastCamera = new CameraParams();

	/** Handling touch events */
	#touchEvents = new TouchEvents();

	/** Handling keyboard events */
	#keyboardEvents = new KeyboardEvents();

	/** Movement in Keyboard space */
	#keyTranslation = new Vector3();
	#keyRotation = new Vector2();

	/** Motion state of different keyboard inputs */
	#keyboardMotionState = new MotionState();
	#mouseMotionState = new MotionState();
	#touchMotionState = new MotionState();

	/** Motion state applied at the frame */
	#combinedMotionState = new MotionState();

	/** Actual motion state including all non-inertial movements. Calculated at fixed intervals for smoothing */
	#actualMotionState = new MotionState();

	/** Internal raycaster instance used to check for obstacles */
	#raycaster = new Raycaster();

	/** Up direction (either Z or Y up are supported) */
	#upDirection = Y_UP;

	/** Whether the camera is moving or not */
	#isCameraMoving = false;

	/**
	 * This interactor supports two rotation modes. The pointer/arrow keys
	 * can rotate the view direction (like in a first-person shooter),
	 * or can rotate the model (like in a 360 images viewer where the object
	 * remains under the pointer while rotating)
	 */
	#pointerRotates = PointerRotates.ViewDirection;
	#rotationMultiplier = 1;

	/** Min Phi angle the camera should rotate to in radians */
	minPhi = degToRad(-MAX_PHI_DEG);

	/** Max Phi angle the camera should rotate to in radians*/
	maxPhi = degToRad(MAX_PHI_DEG);

	/** Distance in meters of the camera from the pivot point when the pivot point changes */
	focusDistance = 5;

	/** Sensitivity of the mouse wheel, higher means higher movement speed */
	wheelSensitivity = 1;

	/** Custom viewport height, default to canvas height if not defined */
	viewportHeight?: number;

	/** A map for each action a mouse button need to trigger */
	mouseBindings: Record<MOUSE.LEFT | MOUSE.MIDDLE | MOUSE.RIGHT, MovementType> = {
		[MOUSE.LEFT]: MovementType.Rotating,
		[MOUSE.RIGHT]: MovementType.HorizontalTranslation,
		[MOUSE.MIDDLE]: MovementType.CameraPan,
	};

	/** Parameters for accelerated movement of the keyboard controls */
	keyboardSettings = {
		/** Translation speed at in m/s (scales with the pivot distance) */
		movementSpeed: 24,

		/** Translation acceleration in m/s^2 (scales with the pivot distance) */
		movementAcceleration: 150,

		/** Rotation speed in rad/s */
		rotationSpeed: 2,

		/** Rotation acceleration in rad/s^2 */
		rotationAcceleration: 5,

		/** Multiplier for speed when the sprint key is pressed */
		sprintSpeedMultiplier: 5.625,

		/**  Multiplier for the movement acceleration when the sprint key is pressed */
		sprintAccelerationMultiplier: 8,
	};

	/**
	 * Bindings for keyboard movement. Uses the key.code property.
	 * see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
	 */
	keyboardBindings = {
		movement: {
			forward: "KeyW",
			backward: "KeyS",
			left: "KeyA",
			right: "KeyD",
			up: "KeyE",
			down: "KeyQ",
			sprint: "ShiftLeft",
		},
		rotation: {
			left: "ArrowLeft",
			right: "ArrowRight",
			up: "ArrowUp",
			down: "ArrowDown",
		},
	};

	/** Smooth mouse movement settings */
	mouseSettings = {
		/** Fraction of movement inertia kept after 1s of friction in range (0,1) */
		movementInertia: 0.03,

		/** Fraction of rotation inertia kept after 1s of friction in range (0,1) */
		rotationInertia: 0.03,
	};

	/** @deprecated Use mouseSettings.rotationFriction instead */
	rotationInertia: number = DEFAULT_INERTIA;

	/** @deprecated Use mouseSettings.movementFriction instead */
	movementInertia: number = DEFAULT_INERTIA;

	/** Objects to check for obstacles while zooming  */
	obstacles?: Object3D[];

	/** Signal the target is not valid anymore */
	targetDismissed = new TypedEvent<void>();

	/** Event fired when the user interacts with the control */
	userInteracted = new TypedEvent<void>();

	/** Event fired when the user started moving the camera position, either via pointer or via keys. */
	cameraStartedTranslating = new TypedEvent<void>();

	/** Event fired when the user stopped moving the camera position */
	cameraStoppedTranslating = new TypedEvent<void>();

	/**
	 * Construct the walk/orbit control class
	 *
	 * @param camera The camera to control
	 * @param element The element to listen for events
	 */
	constructor(camera: SupportedCamera, element?: HTMLCanvasElement) {
		super();
		this.#camera = camera;
		this.updateFromCamera();
		this.removeTarget(true);

		this.onSingleTouchMove = this.onSingleTouchMove.bind(this);
		this.onDoubleTouchRotate = this.onDoubleTouchRotate.bind(this);
		this.onDoubleTouchTranslate = this.onDoubleTouchTranslate.bind(this);
		this.onPinchAndZoom = this.onPinchAndZoom.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDrag = this.onMouseDrag.bind(this);
		this.onWheel = this.onWheel.bind(this);

		this.updateMovementFromKeys = this.updateMovementFromKeys.bind(this);

		this.attachListeners();
		if (element) {
			this.attach(element);
		}
	}

	/**
	 *
	 * @param forward How many meters to translate in the forward direction
	 * @param side How many meters to translate in the right-side direction
	 * @param out Vector to return the result in (to avoid allocations)
	 * @returns A vector corresponding to a horizontal camera movement.
	 */
	#getHorizontalTranslation = memberWithPrivateData(() => {
		// Avoid new allocations by reusing the same vector
		const diry = new Vector3();

		return (forward: number, side: number, out: Vector3): Vector3 => {
			const dir = this.#camera.getWorldDirection(out);
			if (this.#upDirection === Y_UP) {
				dir.y = 0;
			} else {
				dir.z = 0;
			}
			dir.normalize();

			diry.crossVectors(this.#camera.up, dir);

			return dir.multiplyScalar(forward).add(diry.multiplyScalar(side));
		};
	});

	/**
	 * @param y amount of movement.
	 * @param out Vector to return the result in (to avoid allocations)
	 * @returns A vector corresponding to a vertical camera movement.
	 */
	#getVerticalTranslation(y: number, out: Vector3): Vector3 {
		return out.copy(this.#camera.up).multiplyScalar(y);
	}

	/**
	 * Computes the #keyBoardInertia form the current users keyboard inputs.
	 *
	 * @param deltaTime The deltaTime of the current frame
	 */
	private computeKeyboardMotionStateFromInputs = memberWithPrivateData(() => {
		// Avoid allocations by reusing the same vectors
		const vecHoriz = new Vector3();
		const vecVert = new Vector3();

		return (deltaTime: number): void => {
			// Convert from keyboard-space to world-space
			const pivotDistanceMultiplier = this.computePivotDistanceFactor();

			this.#getHorizontalTranslation(this.#keyTranslation.z, this.#keyTranslation.x, vecHoriz);
			this.#getVerticalTranslation(this.#keyTranslation.y, vecVert);

			const sprinting = this.#keyboardEvents.isCodePressed(this.keyboardBindings.movement.sprint);

			const speedFactor = sprinting ? this.keyboardSettings.sprintSpeedMultiplier : 1;

			const accelerationFactor = sprinting ? this.keyboardSettings.sprintAccelerationMultiplier : 1;

			const maxSpeed = this.keyboardSettings.movementSpeed * speedFactor * pivotDistanceMultiplier;

			// accelerate keyboard motion state towards target speed
			this.#keyboardMotionState.accelerateMovementSpeedTowards(
				vecHoriz.add(vecVert).normalize().multiplyScalar(maxSpeed),
				this.keyboardSettings.movementAcceleration * pivotDistanceMultiplier * accelerationFactor,
				deltaTime,
			);

			// If the user was sprinting, and the sprint key is released before the movement key,
			// the user does not want to decelerate slowly.
			this.#keyboardMotionState.movementSpeed.clampLength(0, maxSpeed);

			this.#keyboardMotionState.accelerateRotationSpeedTowards(
				this.#keyRotation.x * this.keyboardSettings.rotationSpeed * this.#rotationMultiplier,
				this.#keyRotation.y * this.keyboardSettings.rotationSpeed,
				this.keyboardSettings.rotationAcceleration,
				deltaTime,
			);
		};
	});

	/** Updates the movement information from the currently pressed keys */
	private updateMovementFromKeys(): void {
		this.#keyTranslation.set(
			this.#keyboardEvents.twoCodesToAxis(
				this.keyboardBindings.movement.left,
				this.keyboardBindings.movement.right,
			),
			this.#keyboardEvents.twoCodesToAxis(this.keyboardBindings.movement.up, this.keyboardBindings.movement.down),
			this.#keyboardEvents.twoCodesToAxis(
				this.keyboardBindings.movement.forward,
				this.keyboardBindings.movement.backward,
			),
		);

		this.#keyRotation.set(
			this.#keyboardEvents.twoCodesToAxis(
				this.keyboardBindings.rotation.left,
				this.keyboardBindings.rotation.right,
			),
			this.#keyboardEvents.twoCodesToAxis(this.keyboardBindings.rotation.up, this.keyboardBindings.rotation.down),
		);
	}

	/** Updates the camera pose with the interactor's internal members. */
	private updateCamera = memberWithPrivateData(() => {
		// Reuse vector to reduce allocations
		const dir = new Vector3();

		return (): void => {
			if (!this.enabled) return;

			spherical2cartesian(
				{ theta: this.#currCamera.theta, phi: this.#currCamera.phi },
				this.#upDirection === Y_UP,
				dir,
			);
			this.#camera.position.copy(this.#currCamera.rotationPivot);
			this.#camera.position.add(dir.multiplyScalar(-this.#currCamera.camPivotDistance));

			if (this.#currCamera.camPivotDistance < PIVOT_DISABLED_DISTANCE) {
				this.removeTarget(true);
			} else {
				this.#camera.lookAt(this.#currCamera.rotationPivot);
			}
		};
	});

	/** @returns the reference height used for computation, the viewport one if defined or the canvas one */
	get #referenceHeight(): number {
		return this.viewportHeight ?? this.#touchEvents.elementHeight;
	}

	/**
	 * This function computes the constant that converts pixel movements into meter movements
	 * needed for computation of touch interactions such as pinch and dolly and two touch translate.
	 * Adjust it to make sure a minimun default is accounted when the camera is super close to the pivot
	 * and return the updated factor
	 *
	 * @returns a factor to use to map pixels to meters for movements
	 */
	private computePix2MeterFactor(): number {
		const pix2meters = pixels2m(1, this.#camera, this.#referenceHeight, this.#currCamera.camPivotDistance);
		return clamp(pix2meters, MIN_PIX2M_FACTOR, MAX_PIX2M_FACTOR);
	}

	/** @returns the scaling factor accounting for the pivot distance in keyboard controls */
	private computePivotDistanceFactor(): number {
		return clamp(
			this.#currCamera.camPivotDistance * DISTANCE_MULTIPLIER,
			MIN_PIVOT_DISTANCE_FACTOR,
			MAX_PIVOT_DISTANCE_FACTOR,
		);
	}

	/** @returns the current pixels/radians ratio, useful to convert pointer movements into rotations */
	#computePix2Radians(): number {
		return this.#currCamera.camPivotDistance <= PIVOT_DISABLED_DISTANCE
			? pixels2radians(this.#camera, this.#referenceHeight)
			: 5 / this.#referenceHeight;
	}

	/**
	 *
	 * @param pp The touch event that moved
	 */
	private onSingleTouchMove(pp: PointerCoords): void {
		const pix2radians = this.#computePix2Radians();
		const m = pp.getMovement(pp.deltaTime);
		this.#currCamera.theta += -m.x * pix2radians * this.#rotationMultiplier;
		this.#currCamera.phi += -m.y * pix2radians * this.#rotationMultiplier;
		this.#currCamera.phi = clamp(this.#currCamera.phi, this.minPhi, this.maxPhi);

		this.userInteracted.emit();
	}

	/**
	 * On double touch translate, we translate the camera along the projection
	 * of its focal axis on the horizontal plane.
	 *
	 * @param dt The double touch event
	 */
	private onDoubleTouchTranslate = memberWithPrivateData(() => {
		const translation = new Vector2();
		const horizontalTranslation = new Vector3();

		return (dt: DoubleTouch): void => {
			const p2m = this.computePix2MeterFactor();
			translation.addVectors(dt.movement1, dt.movement2);
			translation.multiplyScalar(0.5);
			this.#currCamera.rotationPivot.add(
				this.#getHorizontalTranslation(translation.y * p2m, translation.x * p2m, horizontalTranslation),
			);

			this.userInteracted.emit();
		};
	});

	/**
	 * On double touch rotate, we rotate the model around the vertical direction.
	 *
	 * @param dt The double touch event
	 */
	private onDoubleTouchRotate(dt: DoubleTouch): void {
		this.#currCamera.theta +=
			this.#rotationMultiplier *
			(this.#camera.up.dot(this.#camera.getWorldDirection(new Vector3())) <= 0
				? dt.rotationAngle
				: -dt.rotationAngle);

		this.userInteracted.emit();
	}

	/**
	 * If the user does a pinch and zoom gesture, we do not zoom
	 * but we translate the camera along its view diraction (dollying)
	 *
	 * @param dt The double touch event
	 */
	private onPinchAndZoom(dt: DoubleTouch): void {
		const p2m = this.computePix2MeterFactor();
		const dNow = dt.mainTouch.position.distanceTo(dt.secondTouch.position);
		const dPrev = dt.prevPos1.distanceTo(dt.prevPos2);
		const dollyForward = (dNow - dPrev) * p2m;
		this.#currCamera.camPivotDistance -= dollyForward;

		this.userInteracted.emit();
	}

	/**
	 * @returns The distance in meters to the closest obstacle, or to the pivot if not obstacle is defined
	 */
	private distanceToClosestObstacle(): number {
		if (this.obstacles) {
			this.#raycaster.set(this.camera.position, this.camera.getWorldDirection(this.#raycaster.ray.direction));
			const hits = this.#raycaster.intersectObjects(this.obstacles);
			for (const hit of hits) {
				if (hit.distance > PASSTHROUGH_DISTANCE) {
					return hit.distance;
				}
			}
		}
		if (this.hasValidTarget && this.#currCamera.camPivotDistance > PASSTHROUGH_DISTANCE) {
			return this.#currCamera.camPivotDistance;
		}
		return NO_OBSTACLE_DISTANCE;
	}

	/**
	 * @param ev the wheel event
	 */
	private onWheel(ev: WheelEvent): void {
		if (this.#camera instanceof PerspectiveCamera) {
			this.#lastCamera.assign(this.#currCamera);
			const d = this.distanceToClosestObstacle();
			const movement = (d * ev.deltaY * this.wheelSensitivity) / WHEEL_SENSITIVITY_FACTOR;
			if (this.hasValidTarget) {
				this.#currCamera.camPivotDistance += movement;
				this.#movementType = MovementType.Dollying;
			} else {
				this.#currCamera.rotationPivot.add(
					this.camera.getWorldDirection(new Vector3()).multiplyScalar(-movement),
				);
				this.#movementType = MovementType.VerticalTranslation;
			}
		} else {
			// If the camera is orthographic, it does not make sense to translate along the focal axis.
			// Instead, we can change the vertical ortho size on mouse wheel.
			// When I spin by mouse wheel forward of *one* tick, ev.deltaY is -100!
			const f = clamp(1 + ev.deltaY * ONWHEEL_ORTHO_PLANE_MULTIPLIER, DEC_ORTHO_PLANE, INC_ORTHO_PLANE);
			this.#camera.top *= f;
			this.#camera.bottom *= f;
			this.#camera.left *= f;
			this.#camera.right *= f;
			this.#camera.updateProjectionMatrix();
		}
		this.userInteracted.emit();
	}

	/**
	 *
	 * @param ev The mouse down event
	 */
	private onMouseDown(ev: MouseEvent): void {
		if (!isMouseButton(ev.button)) return;
		this.#movementType = this.mouseBindings[ev.button];
		this.#lastMousePos.set(ev.clientX, ev.clientY);
		this.#lastCamera.assign(this.#currCamera);

		// Stop all existing mouse inertia
		this.#mouseMotionState.reset();
	}

	/**
	 * @param ev The mouse up event
	 */
	private onMouseUp(ev: MouseEvent): void {
		if (ev.buttons === 0) {
			this.#mouseMotionState.assign(this.#actualMotionState);
			// Subtract all the inertia that has been applied by other input methods
			this.#mouseMotionState.sub(this.#combinedMotionState);
		}
	}

	/**
	 *
	 * @param ev The mouse drag event
	 */
	private onMouseDrag(ev: MouseEvent): void {
		// How much did the mouse move since last time?
		// We can't use `ev.movementX/Y`, because the units differ by browser and OS
		const deltaX = ev.clientX - this.#lastMousePos.x;
		const deltaY = ev.clientY - this.#lastMousePos.y;

		switch (this.#movementType) {
			case MovementType.Rotating:
				this.onMouseRotate(deltaX, deltaY);
				break;
			case MovementType.HorizontalTranslation:
				this.onMouseWalk(deltaX, deltaY);
				break;
			case MovementType.CameraPan:
				this.onMousePan(deltaX, deltaY);
				break;
			case MovementType.Dollying:
				this.onMouseDolly(deltaY);
				break;
			case MovementType.VerticalTranslation:
				this.onMouseVerticalMove(deltaY);
				break;
		}

		this.#lastMousePos.set(ev.clientX, ev.clientY);

		this.userInteracted.emit();
	}

	/**
	 *
	 * @param x moved X coordinate
	 * @param y moved Y coordinate
	 */
	private onMouseRotate(x: number, y: number): void {
		const p2r = this.#computePix2Radians();
		this.#currCamera.theta += -x * p2r * this.#rotationMultiplier;
		this.#currCamera.phi += -y * p2r * this.#rotationMultiplier;
		this.#currCamera.phi = clamp(this.#currCamera.phi, this.minPhi, this.maxPhi);
	}

	/**
	 *
	 * @param x moved X coordinate
	 * @param y moved Y coordinate
	 */
	private onMouseWalk = memberWithPrivateData(() => {
		const horiz = new Vector3();

		return (x: number, y: number): void => {
			const p2m = this.computePix2MeterFactor();
			const dy = y * p2m;
			const dx = x * p2m;
			this.#currCamera.rotationPivot.add(this.#getHorizontalTranslation(dy, dx, horiz));
		};
	});

	/**
	 * Manage panning action given current mouse position
	 *
	 * @param x The x position of the mouse (in pixels)
	 * @param y The y position of the mouse (in pixels)
	 */
	private onMousePan = memberWithPrivateData(() => {
		const xAxis = new Vector3();
		const yAxis = new Vector3();

		return (x: number, y: number): void => {
			const p2m = this.computePix2MeterFactor();
			const me = this.#camera.matrixWorld.elements;
			xAxis.set(me[0], me[1], me[2]);
			yAxis.set(me[4], me[5], me[6]);
			const dx = -x * p2m;
			const dy = y * p2m;
			this.#currCamera.rotationPivot.addScaledVector(xAxis, dx);
			this.#currCamera.rotationPivot.addScaledVector(yAxis, dy);
		};
	});

	/**
	 *
	 * @param y The number of pixels the mouse moved since last time.
	 */
	private onMouseVerticalMove(y: number): void {
		const p2m = this.computePix2MeterFactor();
		this.#currCamera.rotationPivot.addScaledVector(this.#camera.up, y * p2m);
	}

	/**
	 *
	 * @param y new mouse Y coordinate
	 */
	private onMouseDolly(y: number): void {
		const p2m = this.computePix2MeterFactor();
		const dy = y * p2m;
		this.#currCamera.camPivotDistance += dy;
	}

	/** Currently accumulated time for the actual movement state calculation */
	#timeSinceActualMotionStateUpdate = 0;

	/**
	 * @param deltaTime Time interval on which the speed is computed.
	 */
	private storeActualMotionState(deltaTime: number): void {
		this.#timeSinceActualMotionStateUpdate += deltaTime;

		// Only update the motion state after a given time to make this frame rate independent.
		// Otherwise, on high frame rates, it can happen that the mouse up event triggers multiple
		// frames after the last drag event, resulting in an abrupt stop of the inertial movement.
		if (this.#timeSinceActualMotionStateUpdate > ACTUAL_MOTION_SMOOTHING_TIME) {
			const invD = 1 / this.#timeSinceActualMotionStateUpdate;
			this.#actualMotionState.camPivotDistanceSpeed =
				(this.#currCamera.camPivotDistance - this.#lastCamera.camPivotDistance) * invD;
			this.#actualMotionState.movementSpeed.subVectors(
				this.#currCamera.rotationPivot,
				this.#lastCamera.rotationPivot,
			);
			this.#actualMotionState.movementSpeed.multiplyScalar(invD);
			this.#actualMotionState.phiSpeed = (this.#currCamera.phi - this.#lastCamera.phi) * invD;
			this.#actualMotionState.thetaSpeed = (this.#currCamera.theta - this.#lastCamera.theta) * invD;
			this.#lastCamera.assign(this.#currCamera);

			this.#timeSinceActualMotionStateUpdate = 0;
		}
	}

	/** Check whether the camera is still or translating. Emits a signal when the camera start a translation. */
	#checkIsCameraMoving(): void {
		const isMoving = this.#currCamera.moved(this.#lastCamera);
		if (isMoving && !this.#isCameraMoving) {
			this.cameraStartedTranslating.emit();
		} else if (!isMoving && this.#isCameraMoving) {
			this.cameraStoppedTranslating.emit();
		}
		this.#isCameraMoving = isMoving;
	}

	/**
	 * @param deltaTime Time interval on which the inertial movement is computed.
	 */
	private applyInertialMovement(deltaTime: number): void {
		this.#currCamera.camPivotDistance += this.#combinedMotionState.camPivotDistanceSpeed * deltaTime;

		this.#currCamera.rotationPivot.addScaledVector(this.#combinedMotionState.movementSpeed, deltaTime);

		this.#currCamera.phi += this.#combinedMotionState.phiSpeed * deltaTime;
		this.#currCamera.theta += this.#combinedMotionState.thetaSpeed * deltaTime;
		this.#currCamera.phi = clamp(this.#currCamera.phi, this.minPhi, this.maxPhi);

		this.#checkIsCameraMoving();
	}

	/**
	 * Calculates the control's inertia and updates the camera's position.
	 *
	 * @param deltaTime Time elapsed since last interactor update.
	 */
	public update(deltaTime: number): void {
		if (!this.#enabled) return;

		// calculate target speed or move camera directly
		this.computeKeyboardMotionStateFromInputs(deltaTime);

		// combine mouse + keyboard + touch motion
		this.#combinedMotionState.assign(this.#mouseMotionState);
		this.#combinedMotionState.add(this.#keyboardMotionState);
		this.#combinedMotionState.add(this.#touchMotionState);

		this.applyInertialMovement(deltaTime);
		this.updateCamera();

		this.storeActualMotionState(deltaTime);

		this.#mouseMotionState.applyFriction(
			this.mouseSettings.movementInertia,
			this.mouseSettings.rotationInertia,
			deltaTime,
		);
	}

	/** */
	private attachListeners(): void {
		this.#touchEvents.singleTouchMoved.on(this.onSingleTouchMove);
		this.#touchEvents.doubleTouchTranslated.on(this.onDoubleTouchTranslate);
		this.#touchEvents.doubleTouchRotated.on(this.onDoubleTouchRotate);
		this.#touchEvents.pinchAndZoomed.on(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.on(this.onMouseDown);
		this.#touchEvents.mouseReleased.on(this.onMouseUp);
		this.#touchEvents.mouseDragged.on(this.onMouseDrag);
		this.#touchEvents.mouseWheel.on(this.onWheel);

		this.#keyboardEvents.keyChanged.on(this.updateMovementFromKeys);
	}

	/** */
	private detachListeners(): void {
		this.#touchEvents.singleTouchMoved.off(this.onSingleTouchMove);
		this.#touchEvents.doubleTouchTranslated.off(this.onDoubleTouchTranslate);
		this.#touchEvents.doubleTouchRotated.off(this.onDoubleTouchRotate);
		this.#touchEvents.pinchAndZoomed.off(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.off(this.onMouseDown);
		this.#touchEvents.mouseReleased.off(this.onMouseUp);
		this.#touchEvents.mouseDragged.off(this.onMouseDrag);
		this.#touchEvents.mouseWheel.off(this.onWheel);

		this.#keyboardEvents.keyChanged.off(this.updateMovementFromKeys);
	}

	/**
	 * Enables / disables this controller
	 */
	set enabled(val: boolean) {
		this.#touchEvents.enabled = val;
		this.#enabled = val;

		if (!val) {
			// reset motion state to avoid motion on resume
			this.#mouseMotionState.reset();
			this.#keyboardMotionState.reset();
			this.#touchMotionState.reset();
			this.#actualMotionState.reset();
		}
	}

	/** @returns true if this controller is enabled */
	get enabled(): boolean {
		return this.#enabled;
	}

	/** @returns true if we have a valid target */
	get hasValidTarget(): boolean {
		return this.#currCamera.camPivotDistance > PIVOT_DISABLED_DISTANCE;
	}

	/** @returns the target point around which the rotation is orbiting. The target point is always placed along the camera's focal axis. */
	get target(): Vector3 | undefined {
		if (!this.hasValidTarget) return;
		return this.#currCamera.rotationPivot;
	}

	/** Sets the target point around which the rotation is orbiting. */
	set target(t: Vector3 | undefined) {
		if (!t) {
			this.removeTarget();
			return;
		}
		this.#camera.lookAt(t);
		const distance = this.camera.position.distanceTo(t);
		this.#currCamera.camPivotDistance = distance;
		this.#currCamera.rotationPivot = t.clone();
		const dir = this.#camera.getWorldDirection(new Vector3());
		this.camera.position.copy(t.clone().sub(dir.clone().multiplyScalar(distance)));
		const angles = cartesian2spherical(dir, this.#upDirection === Y_UP);
		this.#currCamera.phi = angles.phi;
		this.#currCamera.theta = angles.theta;
	}

	/**
	 * Compute the best position for the camera if the change the pivot, used to animate
	 * the camera before changing the pivot
	 *
	 * @param target The new desired target position to use as a pivot
	 * @returns The position/quaternion for the best camera to look at the new pivot point
	 */
	computeBestCameraPoseForTarget(target: Vector3): { position: Vector3; quaternion: Quaternion } {
		const dir = this.camera.getWorldDirection(new Vector3());
		const currDistance = this.hasValidTarget ? this.#currCamera.camPivotDistance : this.focusDistance;
		const mayDistance = this.camera.position.distanceTo(target);
		const distance = Math.min(currDistance, mayDistance, this.focusDistance);
		const position = target.clone().sub(dir.multiplyScalar(distance));
		const quaternion = this.camera.quaternion.clone();
		return { position, quaternion };
	}

	/**
	 * Hide the target placing it a PIVOT_DISABLE_DISTANCE from the camera and notify the targetDismissed event
	 *
	 * @param force Force the removal of the target even if it's already invalid, will always trigger the event
	 */
	removeTarget(force = false): void {
		if (!force && !this.hasValidTarget) {
			return;
		}
		const dir = this.camera.getWorldDirection(new Vector3());
		this.#currCamera.rotationPivot
			.copy(this.#camera.position)
			.add(dir.normalize().multiplyScalar(PIVOT_DISABLED_DISTANCE));
		this.#currCamera.camPivotDistance = PIVOT_DISABLED_DISTANCE;
		this.targetDismissed.emit();
	}

	/**
	 * @returns the handler of the touch events. Useful if another class wants to be
	 * notified e.g. when a tap gesture happens.
	 */
	get touchEvents(): TouchEvents {
		return this.#touchEvents;
	}

	/** @returns The camera that the controls are handling. */
	get camera(): SupportedCamera {
		return this.#camera;
	}

	/** Sets the camera that the controls are handling. */
	set camera(c: SupportedCamera) {
		this.#camera = c;
		this.updateFromCamera();
	}

	/**
	 * Attach this controls to an element to receive events from
	 *
	 * @param target The element to listen for events
	 */
	attach(target: HTMLElement): void {
		// to receive keyboard events.
		target.tabIndex = -1;
		this.#touchEvents.attach(target);
		this.#keyboardEvents.attach(target);
	}

	/**
	 * If attached to an element, detach all event linsteners
	 */
	detach(): void {
		this.#keyboardEvents.detach();
		this.#touchEvents.detach();
	}

	/** Disposes all resources and releases all event listeners registered by this object. */
	dispose(): void {
		this.detach();
		this.detachListeners();
		this.#touchEvents.dispose();
	}

	/**
	 * @returns whether the interactor rotates the view direction (like a first-person-shooter)
	 * or whether the interactor rotates the model (like a 360 image viewer)
	 */
	get pointerRotates(): PointerRotates {
		return this.#pointerRotates;
	}

	/** Sets the PointerRotates property. */
	set pointerRotates(p: PointerRotates) {
		this.#pointerRotates = p;
		this.#rotationMultiplier = this.#pointerRotates === PointerRotates.ViewDirection ? 1 : -1;
	}

	/** Ensures that this interactor's internal members describe the same pose as the camera member. */
	updateFromCamera = memberWithPrivateData(() => {
		const cameraWorldDir = new Vector3();
		const cam2pivot = new Vector3();

		return (): void => {
			if (this.#camera.up.z === 1) {
				this.#upDirection = Z_UP;
			} else if (this.#camera.up.y === 1) {
				this.#upDirection = Y_UP;
			} else {
				console.warn("WalkOrbitControls: camera up direction not supported.");
			}
			this.#camera.getWorldDirection(cameraWorldDir);
			this.#currCamera.rotationPivot.copy(this.#camera.position);
			cam2pivot.copy(cameraWorldDir);
			cam2pivot.multiplyScalar(this.#currCamera.camPivotDistance);
			this.#currCamera.rotationPivot.add(cam2pivot);
			const angles = cartesian2spherical(cameraWorldDir, this.#upDirection === Y_UP);
			this.#currCamera.phi = angles.phi;
			this.#currCamera.theta = angles.theta;
		};
	});

	/** @returns whether the camera is translating or not. */
	get isCameraMoving(): boolean {
		return this.#isCameraMoving;
	}
}
