import { isDownloadAbortError } from "@faro-lotv/foundation";
import { Texture } from "three";
import { ImageNodeFetch, ImageTree } from "./ImageTree";
import { NodeState } from "./LodTree";

/** Type of a class that can request textures to the LodPanoTextureProvider */
export type LodPanoTextureProviderClient = {
	/** Function called to notify the pano a new ImageTree node is available and can be shown */
	setNodeVisible(nodeIdx: number, texture: Texture): void;
};

/** A class that will load and provide textures from an ImageTree to multiple LodPano objects */
export class LodPanoTextureProvider {
	/**
	 * All the clients using this provider and what images they're requesting
	 */
	clients = new Map<LodPanoTextureProviderClient, number[]>();

	/**
	 * Max number of textures to download at once
	 */
	maxNodesToDownloadAtOnce = 4;

	/**
	 * The queue of nodes waiting for download.
	 */
	#nodesToDownloadQueue: number[] = [];

	/**
	 * The list of nodes whose image is currently downloading, implemented as a map node idx -> ImageNodeFetch object.
	 */
	#downloadingNodes = new Map<number, ImageNodeFetch | null>();

	/**
	 * List of downloaded nodes
	 */
	#downloadedNodes: number[] = [];

	/**
	 * Construct a new LodPanoTextureProvider on a specific ImageTree
	 *
	 * @param tree of images this class will provide textures for
	 */
	constructor(public tree: ImageTree) {}

	/**
	 * Allow a provider client to request the desired nodes
	 *
	 * @param client the client requesting the nodes
	 * @param nodes the nodes this clients wants
	 */
	requestsNodes(client: LodPanoTextureProviderClient, nodes: number[]): void {
		this.clients.set(client, nodes);
		for (const n of nodes) {
			const node = this.tree.getNode(n);
			if (node.state === NodeState.NotInUse) {
				node.state = NodeState.WaitingForDownload;
				this.#nodesToDownloadQueue.push(n);
			}
		}
		this.#processDownloads();
	}

	/**
	 * Remove a client for the list of clients of this provider
	 * Will trigger the disposal of all the textures if no clients are left
	 *
	 * @param client to remove
	 */
	removeClient(client: LodPanoTextureProviderClient): void {
		this.clients.delete(client);
		if (this.clients.size === 0) {
			this.dispose();
		}
	}

	/**
	 * Dispose all textures and resources for the controlled ImageTree
	 */
	dispose(): void {
		for (let id = 0; id < this.tree.nodeCount; ++id) {
			this.#releaseNode(id);
		}
		this.#nodesToDownloadQueue.length = 0;
		this.#downloadedNodes.length = 0;
		this.#downloadingNodes.clear();
	}

	/**
	 * Main algorithm for node download and management.
	 */
	#processDownloads(): void {
		// Process the queue of nodes to download. Pop nodes from there into _downloadingNodes until _downloadingNodes is full.
		while (this.#nodesToDownloadQueue.length > 0 && this.#downloadingNodes.size < this.maxNodesToDownloadAtOnce) {
			// In the line below, the queue is not empty for sure, therefore we can safely expect to pop it.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const n = this.#nodesToDownloadQueue.shift() as number;
			const node = this.tree.getNode(n);
			if (node.state !== NodeState.WaitingForDownload) {
				continue;
			}
			node.state = NodeState.Downloading;
			// Line below is needed to make sure that we never download at once more than the required amount of nodes.
			this.#downloadingNodes.set(n, null);
			this.tree
				.getNodeImage(n)
				.then((f: ImageNodeFetch): Promise<Texture> => {
					const pp = f.image();
					this.#downloadingNodes.set(n, f);
					return pp;
				})
				.then((image: Texture) => {
					this.#downloadingNodes.delete(n);
					this.#downloadedNodes.push(n);
					node.state = NodeState.InUse;
					node.image = image;
					for (const [client, nodes] of this.clients.entries()) {
						if (nodes.includes(n)) {
							client.setNodeVisible(n, image);
						}
					}
				})
				.catch((error: Error | string) => {
					// Log download error to console if it is not deliberate cancellation.
					this.#handleDownloadError(n, error);
				})
				.finally(() => {
					// Either in case of successful download or error, or cancellation, we cleanup
					// the download handler and remove n from _downloadingNodes.
					this.#cleanupDownload(n);
				});
		}
	}

	/**
	 * After node n has been downloaded (or its download has been canceled), here we remove the LodNodeFetch object that
	 * supported the download. Also, we call 'processDownloads' again to check whether there are other nodes in queue
	 * that need to be downloaded.
	 *
	 * @param n The downloaded node.
	 */
	#cleanupDownload(n: number): void {
		this.#downloadingNodes.delete(n);
		this.#processDownloads();
	}

	/**
	 * Logs download error to console if it is not deliberate cancellation.
	 *
	 * In this function, we filter out error messages that are deliberate cancellations of downloads,
	 * in order to not pollute the browser console.
	 *
	 * @param n The node that was downloading
	 * @param error The error message
	 */
	#handleDownloadError(n: number, error: Error | string): void {
		this.tree.getNode(n).state = NodeState.NotInUse;
		if (!isDownloadAbortError(error)) {
			console.log(`Error while downloading node ${n}:`);
			console.log(error);
		}
	}

	/**
	 * @param nodeIdx Index of the node to dispose from memory or to abort if downloading
	 */
	#releaseNode(nodeIdx: number): void {
		const node = this.tree.getNode(nodeIdx);
		switch (node.state) {
			case NodeState.WaitingForDownload:
				{
					const idx = this.#nodesToDownloadQueue.indexOf(node.id);
					if (idx !== -1) {
						this.#nodesToDownloadQueue.splice(idx, 1);
					}
				}
				break;
			case NodeState.Downloading:
				{
					const p = this.#downloadingNodes.get(nodeIdx);
					if (p) {
						p.abort();
						this.#downloadingNodes.delete(nodeIdx);
					}
				}
				break;
			case NodeState.InUse: {
				const idx = this.#downloadedNodes.indexOf(node.id);
				if (idx !== -1) {
					this.#nodesToDownloadQueue.splice(idx, 1);
					node.image?.dispose();
					node.image = undefined;
				}
			}
		}
		node.state = NodeState.NotInUse;
	}
}
