/** The API returned an error response. */
export class ApiResponseError<
  S extends number = number,
  B = Record<string, unknown>,
> extends Error {
  /** Add a more specific name to this error for better reporting */
  name = "API Response Error";

  /**
   * @param status The HTTP error code
   * @param statusText The corresponding text for the HTTP error code
   * @param body Contains the JSON of the responds, if the API provided one
   */
  constructor(
    public status: S,
    public statusText: string,
    public body?: B,
  ) {
    super(`${status}: ${statusText} - ${JSON.stringify(body)}`);
  }
}

/** The response returned by the API has an invalid format. */
export class ApiValidationError extends Error {
  /** Add a more specific name to this error for better reporting */
  name = "API Validation Error";

  /**
   * @param deserializedResponse The deserialized response returned by the API.
   */
  constructor(public deserializedResponse: unknown) {
    super(JSON.stringify(deserializedResponse));
  }
}
