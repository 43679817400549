import {
	GreaterEqualStencilFunc,
	KeepStencilOp,
	ReplaceStencilOp,
	ShaderLib,
	ShaderMaterial,
	Texture,
	UniformsUtils,
} from "three";
import frag from "../Shaders/FloorPlanTile.frag";
import vert from "../Shaders/FloorPlanTile.vert";

/**
 * The material used to render a floor plan tile.
 * This is just an extension of MeshBasicMaterial,
 * that supports fast updates of the texture
 */
export class FloorPlanTileMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = UniformsUtils.merge([ShaderLib.basic.uniforms]);

	isMeshBasicMaterial = true;

	/** Set the texture of the tile */
	set map(map: Texture | undefined) {
		if (!this.uniforms.map.value) {
			this.needsUpdate = true;
		}
		this.uniforms.map.value = map;
		this.uniformsNeedUpdate = true;
	}

	/** @returns The texture of the tile */
	get map(): Texture {
		return this.uniforms.map.value;
	}

	/** Change the uDepthLevel uniform */
	set depthLevel(value: number) {
		this.stencilRef = value;
		this.uniformsNeedUpdate = true;
	}

	/**
	 * Create a new tile with a given texture and with alpha test enabled by default
	 *
	 * @param map The texture rendered over this tile
	 */
	constructor(map?: Texture) {
		super();
		this.map = map;

		this.stencilWrite = true;
		this.stencilFunc = GreaterEqualStencilFunc;
		this.stencilFail = KeepStencilOp;
		this.stencilZFail = KeepStencilOp;
		this.stencilZPass = ReplaceStencilOp;

		this.transparent = true;
	}
}
