import { FastSsaoPass as LotvFastSsaoPass } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useState } from "react";
import { Camera } from "three";
import { attachPass } from "../attach-utils";

export type FastSSAOPassProps = {
  /** Whether this step is enabled or not */
  enabled?: boolean;
  /** The camera to use to compute the effect */
  camera?: Camera;
  /** SSAO strength*/
  strength?: number;
  /** SSAO radius*/
  radius?: number;
  /** SSAO bias*/
  angleBias?: number;
  /** Use large blur kernel or not */
  largeBlurKernel?: boolean;
};

/**
 * @returns A EffectPipeline pass that will apply fast SSAO to the entire scene
 */
export function FastSSAOPass({
  enabled = true,
  camera,
  strength = 1,
  radius = 1,
  angleBias = 0.05,
  largeBlurKernel = true,
}: FastSSAOPassProps): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const effectCamera = camera ?? defaultCamera;

  const [pass] = useState(() => new LotvFastSsaoPass(effectCamera));

  return (
    <primitive
      name="FastSSAOPass"
      object={pass}
      attach={attachPass}
      // Following props are part of the pass object
      /* eslint-disable react/no-unknown-property */
      camera={effectCamera}
      strength={strength}
      radius={radius}
      angleBias={angleBias}
      useLargeBlurKernel={largeBlurKernel}
      enabled={enabled}
      /* eslint-enable react/no-unknown-property */
    />
  );
}
