import { useEffect, useState } from "react";

/** @returns If there is a dragging event happening on the window */
export function useIsUserDragging(): boolean {
  const [isAppDragging, setIsAppDragging] = useState(false);

  useEffect(() => {
    let timeoutId: number | undefined;

    const onDragEnterOrOver = (e: DragEvent): void => {
      if (!e.dataTransfer) return;

      // By default, we forbid any dragging operation on the window
      // if not explicitly managed somewhere else
      e.preventDefault();
      e.dataTransfer.dropEffect = "none";
      e.dataTransfer.effectAllowed = "none";
      e.stopPropagation();

      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }

      setIsAppDragging(true);
    };

    const onDragLeave = (e: DragEvent): void => {
      if (!e.dataTransfer) return;

      // By default, we forbid any dragging operation on the window
      // if not explicitly managed somewhere else
      e.preventDefault();
      e.dataTransfer.dropEffect = "none";
      e.dataTransfer.effectAllowed = "none";
      e.stopPropagation();

      // This timeout prevents flickering of the UI when
      // a react component not managed by us steals the events
      timeoutId = window.setTimeout(() => setIsAppDragging(false), 100);
    };

    window.addEventListener("dragenter", onDragEnterOrOver);
    window.addEventListener("dragover", onDragEnterOrOver);
    window.addEventListener("dragleave", onDragLeave);

    return () => {
      window.removeEventListener("dragenter", onDragEnterOrOver);
      window.removeEventListener("dragover", onDragEnterOrOver);
      window.removeEventListener("dragleave", onDragLeave);
    };
  }, []);
  return isAppDragging;
}
