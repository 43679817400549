import { Disposable } from "@faro-lotv/foundation";
import { Camera, Mesh, Object3D, PlaneGeometry, Vector2, Vector3, Vector4, WebGLRenderer } from "three";
import { PanoBlendMaterial, PanoBlendType } from "../Materials/PanoBlendMaterial";
import { EnvironmentMap } from "../Utils";
import { Pano } from "./Pano";

/** Zoom in value to use if we animate toward a pano that is not visible from the starting position */
const DEFAULT_ZOOM_IN = 0.8;

/**
 * An object to render a smooth transition between two panorama objects
 */
export class PanoBlend extends Mesh<PlaneGeometry, PanoBlendMaterial> {
	override geometry = new PlaneGeometry(2, 2);
	override name = "PanoBlend";

	#fromEnvMap: EnvironmentMap;
	#toEnvMap: EnvironmentMap;
	#conns: Disposable[] = [];

	/**
	 * Construct a transition object
	 *
	 * @param from The starting transition image
	 * @param to The target image
	 * @param camera The camera used to render this transition
	 * @param renderer The renderer context to allocate temporary env maps
	 * @param blendType The transition type to use
	 */
	constructor(
		private from: Pano,
		private to: Pano,
		private camera: Camera,
		private renderer: WebGLRenderer,
		private blendType: PanoBlendType = "Fade",
	) {
		super();
		this.#fromEnvMap = this.createEnvMap(from);
		this.#toEnvMap = this.createEnvMap(to);
		this.material = new PanoBlendMaterial(this.#fromEnvMap, this.#toEnvMap, camera);
		this.#conns.push(from.textureChanged.on(() => this.#fromEnvMap.update()));
		this.#conns.push(to.textureChanged.on(() => this.#toEnvMap.update()));
		this.frustumCulled = false;
		if (blendType === "Zoom") {
			this.computeZoomRect();
		}
	}

	/**
	 * Dispose resources
	 */
	dispose(): void {
		this.#fromEnvMap.dispose();
		this.#toEnvMap.dispose();
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		this.material?.dispose();
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		this.geometry?.dispose();
		for (const c of this.#conns) c.dispose();
	}

	/**
	 * Create an environment map from an object
	 *
	 * @param object The environment object
	 * @returns The environment map
	 */
	private createEnvMap(object: Object3D): EnvironmentMap {
		object.updateMatrixWorld();
		const pos = object.localToWorld(new Vector3());
		return new EnvironmentMap(object, this.renderer, pos);
	}

	/** Update the blend factor */
	set blendFactor(factor: number) {
		this.material.blendFactor = factor;
	}

	/** @returns the current blend factor */
	get blendFactor(): number {
		return this.material.blendFactor;
	}

	/**
	 * Initialize all elements that are needed for starting the animation during the transition.
	 */
	private computeZoomRect(): void {
		// No setup needed on fade transitions
		if (this.blendType === "Fade") return;

		const startImage = this.from;
		const { depths } = startImage;
		if (!depths) {
			console.log("No depth information.");
			return;
		}

		// In this function we compute the position and size of the destination rectangle
		// of the zoom movement, in clip space. We take into account the current position,
		// the relative distance 'Dto' of the destination image , and the relative distance 'D' of the
		// objects we are seeing. From these distances, we compute the relative size 'factor' of
		// the destination rectangle in the following way:
		// const D = Math.abs(viewP.z);
		// const Dto = Math.abs(viewTo.z);
		// let factor = Math.abs(D - Dto) / D;

		const tarPos = new Vector3().setFromMatrixPosition(this.to.matrix);

		// Matrices
		const vMatrix = this.camera.matrixWorldInverse.clone();

		// ReProject
		const viewP = new Vector4(tarPos.x, tarPos.y, tarPos.z, 1).applyMatrix4(vMatrix);
		const clip = viewP.clone().applyMatrix4(this.camera.projectionMatrix);
		clip.divideScalar(clip.w);

		const { row, col } = depths.ndcToPixel(clip.x, clip.y, this.camera);
		const data = depths.validDepth3x3(row, col);
		if (!data || Number.isNaN(data.row) || Number.isNaN(data.col)) return;
		// P is now in world coordinates.
		const P = depths.coord(data.row, data.col);
		if (!P) return;

		const viewTo = new Vector4(P.x, P.y, P.z, 1).applyMatrix4(vMatrix);
		const clipTo = viewTo.clone().applyMatrix4(this.camera.projectionMatrix);
		clipTo.divideScalar(clipTo.w);

		const D = Math.abs(viewP.z);
		const Dto = Math.abs(viewTo.z);
		let factor = Math.abs(D - Dto) / D;
		// If 'factor' has a weird value (e.g. viewTo is behind the current camera)
		// we still want to show a zoom-in transition to a default value of 0.8
		if (factor >= 1) factor = DEFAULT_ZOOM_IN;

		// We now determine the 'zoom to' rectangle in clip coordinates.
		const rectCenter = new Vector2(clipTo.x * 0.5 + 0.5, clipTo.y * 0.5 + 0.5);
		const rectSize = 0.5 * factor;
		const diag = new Vector2(rectSize, rectSize);
		const toRectMax = rectCenter.clone().add(diag);
		const toRectMin = rectCenter.clone().sub(diag);
		const translation = new Vector2(0, 0);
		// if the triangle is outside clip space, we make sure it is inside.
		for (let c = 0; c < 2; ++c) {
			if (toRectMax.getComponent(c) > 1) translation.setComponent(c, 1 - toRectMax.getComponent(c));
			else if (toRectMin.getComponent(c) < 0) translation.setComponent(c, -toRectMin.getComponent(c));
		}
		toRectMin.add(translation);
		toRectMax.add(translation);

		this.material.setZoom(toRectMin, toRectMax);
	}

	override onBeforeRender = (renderer: WebGLRenderer): void => {
		this.material.update(renderer);
	};
}
