/**
 * Implementation of Lotv SphereControls
 * This file mirror how OrbitControls are implemented in react-three/drei
 */
import {
  SphereControls as SphereControlsImpl,
  SupportedCamera,
  ZoomBehavior,
} from "@faro-lotv/lotv";
import { UPDATE_CONTROLS_PRIORITY } from "@faro-lotv/spatial-ui";
import { ReactThreeFiber, useFrame, useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { Clock } from "three";
import { TypedEventCallback, useTypedEvent } from "../hooks";
import { useThreeEventTarget } from "../hooks/use-three-event-target";

export type SphereControlsProps = Omit<
  ReactThreeFiber.Overwrite<
    // This line will bring in to props any public property of the LotV SphereControls class
    ReactThreeFiber.Object3DNode<SphereControlsImpl, typeof SphereControlsImpl>,
    // These are additional property specific for the React component logic
    {
      /** The camera this controls will animage */
      camera?: SupportedCamera;
      /** The dom element used to receive the events */
      domElement?: HTMLElement;
      /** Inertia value in the range [0, 1) */
      inertia?: number;
      /** Minimum FOV angle in degrees. Min allowed value: 1 */
      minPerspFov?: number;
      /** Maximum FOV angle in degrees. Max allowed value: 170*/
      maxPerspFov?: number;
      /**
       * Speed of the perspective zoom animation, in degrees / second.
       *  Allowed values in the range [10, 1000]
       */
      zoomSpeed?: number;
      /** Behavior on wheel zoom on POI.*/
      zoomBehavior?: ZoomBehavior;
      /** True to register this controls as the R3F context control */
      isDefault?: boolean;
      /** true to enable this controls */
      isEnabled?: boolean;
      /** Callback executed when the user has interacted with the controls */
      onUserInteracted?: TypedEventCallback<
        SphereControlsImpl["userInteracted"]
      >;

      /**
       * Callback called when user wants to jump out of pano
       * NOTE: The onZoomOut prop is currently untested and is, most likely, broken
       */
      onZoomOut?(): void;

      /** how long(s) to wait when maxFov is reached to trigger zoomOutEvent */
      zoomOutDelay?: number;
    }
  >,
  // If there's a property called ref we need to omit it to not conflict with React ref property
  "ref"
>;

/**
 * The {ref} can be undefined as it is created only after we initialize it on the dom element
 */
export type SphereControlsRef = SphereControlsImpl | undefined;

/**
 * I'm using forwardRef here so it's possible to get a ref on the controls if needed
 */
export const SphereControls = forwardRef<
  SphereControlsRef,
  SphereControlsProps
>(function SphereControls(
  {
    camera,
    domElement,
    inertia,
    minPerspFov,
    maxPerspFov,
    zoomSpeed,
    zoomBehavior,
    onUserInteracted,
    onZoomOut,
    isDefault = false,
    isEnabled = true,
    zoomOutDelay = 1,
    // This contains all the SphereControls property we want just to forward
    ...restProps
  }: SphereControlsProps,
  ref,
) {
  // The entire 3RF state has been invalidated, need to recreate everything
  const invalidate = useThree((state) => state.invalidate);
  // This is the currently default registered R3F camera
  const defaultCamera = useThree((state) => state.camera);
  // Function to change the R3F state
  const set = useThree((state) => state.set);
  const get = useThree((state) => state.get);
  // R3F EventManager target
  const eventTarget = useThreeEventTarget(domElement);

  // Compute camera to control
  const explCamera = camera ?? defaultCamera;
  // Clock used to understand if user tries to jump out in a time lapse
  const clock = useMemo(() => new Clock(), []);
  // Boolean used to test on mobile devices in which SphereControls emits multiple time the zoom out event
  const isZoomOut = useRef(false);

  // Create control class
  const controls = useMemo(
    () => new SphereControlsImpl(explCamera),
    [explCamera],
  );
  // Ensure the controls are attached to the correct element to listen events from
  useEffect(() => {
    controls.attach(eventTarget);
    controls.maxPerspFovReached.on(() => {
      if (!clock.running) {
        clock.start();
      }

      // Expose the onZoomOut event for components to use
      if (clock.getElapsedTime() > zoomOutDelay && !isZoomOut.current) {
        isZoomOut.current = true;
        clock.stop();
        if (onZoomOut) {
          onZoomOut();
        }
      }
    });

    // When the controls changes disable previous one to disconnect from dom events
    return () => {
      controls.detach();
    };
  }, [
    clock,
    controls,
    eventTarget,
    invalidate,
    isZoomOut,
    onZoomOut,
    zoomOutDelay,
  ]);

  // Register this controls as the default one in the R3F context
  useEffect(() => {
    if (isDefault) {
      const old = get().controls;
      set({ controls });
      return () => set({ controls: old });
    }
  }, [isDefault, get, set, controls]);

  // Expose the userInteracted event for components to use
  useTypedEvent(controls.userInteracted, onUserInteracted);

  // If controls is enabled call updated at every frame
  useFrame((_, delta) => {
    if (controls.enabled) {
      controls.update(delta);
    }
    // The controls just moved the camera, executing this with UPDATE_CONTROLS_PRIORITY
    // so that it is guaranteed that camera monitoring and lod visibility tasks come after this.
  }, UPDATE_CONTROLS_PRIORITY);

  return (
    <primitive
      ref={ref}
      object={controls}
      enabled={isEnabled}
      // Following props are part of the controls
      // eslint-disable-next-line react/no-unknown-property
      inertia={inertia}
      // eslint-disable-next-line react/no-unknown-property
      minPerspFov={minPerspFov}
      // eslint-disable-next-line react/no-unknown-property
      maxPerspFov={maxPerspFov}
      // eslint-disable-next-line react/no-unknown-property
      zoomSpeed={zoomSpeed}
      // eslint-disable-next-line react/no-unknown-property
      zoomBehavior={zoomBehavior}
      {...restProps}
    />
  );
});
