import { Disposable } from "@faro-lotv/foundation";
import { Color, Group, Mesh, Object3D, Vector2, Vector3, Vector4, WebGLRenderer } from "three";
import { PanoColoredDollhouseMaterial } from "../Materials/PanoColoredDollhouseMaterial";
import { EnvironmentMap, isColoredMaterial, isTexturedMaterial } from "../Utils";
import { Pano } from "./Pano";

type DollHouseAnimObject = Object3D & { material: PanoColoredDollhouseMaterial };

/**
 * From an object (mesh or pointcloud) clone the object to be used during the transition with a special material
 *
 * @param object The original object
 * @param material The material to render the dollhouse effect
 * @returns The cloned object with a special material to render during the transition projection the pano colors
 */
function createTransitionObject(object: Mesh, material: PanoColoredDollhouseMaterial): DollHouseAnimObject {
	object.updateMatrixWorld();
	const origMat = object.material;
	const texture = isTexturedMaterial(origMat) ? origMat.map : undefined;
	const mesh = new Mesh(object.geometry, material);
	object.matrixWorld.decompose(mesh.position, mesh.quaternion, mesh.scale);
	mesh.onBeforeRender = (renderer) => {
		const viewport = renderer.getViewport(new Vector4()).multiplyScalar(renderer.getPixelRatio());
		const sz = new Vector2(viewport.z, viewport.w);

		const color = isColoredMaterial(material) ? material.color : new Color();
		material.update(sz, color, texture);
	};
	mesh.renderOrder = object.renderOrder;
	return mesh;
}

/**
 * A dollhouse colored using pano textures, the fragment will be colored with a blend of the colors of the two pano images
 * using the blendFactor property.
 * A blendFactor == 0 will use only the from pano
 * A blendFactor == 1 will use only the to pano
 * An intermediate value will blend the two colors
 * The points where the pano will give no color information wil be colored with the original mesh colors
 */
export class PanoColoredDollhouse extends Group {
	override children: DollHouseAnimObject[] = [];
	#blendFactor = 0;
	#material: PanoColoredDollhouseMaterial;
	#sourceEnvMap: EnvironmentMap;
	#toEnvMap: EnvironmentMap;
	#conns: Disposable[] = [];

	/**
	 * Construct a DollHouseTransition
	 *
	 * @param dollhouse The mesh/pointcloud or group of mesh/pointclouds to use during the transition
	 * @param from The source pano object
	 * @param to The target pano object
	 * @param renderer The renderer used to generate temporary cubemaps
	 */
	constructor(
		dollhouse: Object3D,
		from: Pano,
		to: Pano,
		private renderer: WebGLRenderer,
	) {
		super();
		this.renderOrder = dollhouse.renderOrder;
		this.frustumCulled = false;
		this.#sourceEnvMap = this.computeEnvSnapshot(from);
		this.#toEnvMap = this.computeEnvSnapshot(to);
		this.#material = new PanoColoredDollhouseMaterial(this.#sourceEnvMap, this.#toEnvMap);

		this.#conns.push(from.textureChanged.on(this.fromChanged.bind(this)));
		this.#conns.push(to.textureChanged.on(this.toChanged.bind(this)));

		const queue: Object3D[] = [];
		queue.push(dollhouse);
		while (queue.length > 0) {
			const obj = queue[0];
			queue.shift();
			if (obj instanceof Mesh) {
				this.add(createTransitionObject(obj, this.#material));
			}
			for (const child of obj.children) {
				queue.push(child);
			}
		}
	}

	/**
	 * Dispose all private resources of this object
	 */
	dispose(): void {
		this.#sourceEnvMap.dispose();
		this.#toEnvMap.dispose();
		this.#material.dispose();
		for (const conn of this.#conns) conn.dispose();
	}

	/**
	 * Compute an environment snapshot from a pano
	 *
	 * @param pano The pano we want to snapshot
	 * @returns The computed cubemap and position
	 */
	private computeEnvSnapshot(pano: Pano): EnvironmentMap {
		pano.updateMatrixWorld();
		const pos = pano.localToWorld(new Vector3());
		return new EnvironmentMap(pano, this.renderer, pos);
	}

	/**
	 * Source pano changed, update snapshot
	 */
	private fromChanged(): void {
		this.#sourceEnvMap.update();
	}

	/**
	 * To pano changed, update snapshot
	 */
	private toChanged(): void {
		this.#toEnvMap.update();
	}

	/** Change the pano blend factor */
	set blendFactor(perc: number) {
		this.#blendFactor = perc;
		for (const child of this.children) {
			child.material.blendFactor = perc;
		}
	}

	/** @returns the current blend factor */
	get blendFactor(): number {
		return this.#blendFactor;
	}
}
