import { Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { MouseEventHandler, ReactNode } from "react";
import { blue, ColorString, cyan, neutral } from "../../colors";
import { FontWeights } from "../../faro-theme";
import { Checkmark2Icon } from "../../icons";
import { FaroText } from "../../text/faro-text/faro-text";

export type FaroChipFilterProps = {
  /** The chip label */
  label: ReactNode;

  /** If defined show a counter in the chip */
  count?: number;

  /** True to show the checkmark @default false */
  showCheckmark?: boolean;

  /** Define to true to disable this chip @default false */
  disabled?: boolean;

  /** Define to true to make this chip selected @default false */
  selected?: boolean;

  /** True to use the dark variant of the filter chip @default false */
  dark?: boolean;

  /** Notify this chip was clicked */
  onClick?: MouseEventHandler<HTMLDivElement>;
};

type Colors = {
  backgroundColor: ColorString;
  color: ColorString;
  borderColor: ColorString;
  focusColor: ColorString;
  selectedBackground: ColorString;
  selectedText: ColorString;
};

const DARK_COLORS: Colors = {
  backgroundColor: neutral[999],
  color: neutral[300],
  borderColor: neutral[300],
  focusColor: cyan[400],
  selectedBackground: neutral[500],
  selectedText: cyan[400],
};

const LIGHT_COLORS: Colors = {
  backgroundColor: neutral[0],
  color: neutral[600],
  borderColor: neutral[999],
  focusColor: blue[500],
  selectedBackground: neutral[500],
  selectedText: blue[500],
};

/** @returns a specialized chip to be used for filters */
export function FaroChipFilter({
  label,
  count,
  showCheckmark = false,
  dark = false,
  disabled = false,
  selected = false,
  onClick,
}: FaroChipFilterProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;
  return (
    <Chip
      variant="outlined"
      onClick={onClick}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <FaroText variant="heading14" color="inherit">
            {label}
          </FaroText>
          {count !== undefined && <ChipCounter count={count} dark={dark} />}
        </Stack>
      }
      icon={
        showCheckmark ? (
          <Checkmark2Icon sx={{ fontSize: "16px" }} color="inherit" />
        ) : undefined
      }
      sx={{
        textTransform: "none",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
        color: selected ? colors.selectedText : colors.color,
        backgroundColor: selected
          ? `${colors.selectedBackground}40`
          : colors.backgroundColor,
        borderColor: selected ? colors.selectedText : colors.borderColor,
        "&.MuiChip-root": {
          height: "30px",
          px: 0,
          py: "8px",
          "&:hover": {
            borderColor: colors.selectedText,
            color: colors.selectedText,
            backgroundColor: selected
              ? `${colors.selectedBackground}26`
              : `color-mix(in srgb, ${colors.backgroundColor}, ${neutral[500]} 15%)`,
          },
          "&:active": {
            borderColor: colors.selectedText,
            color: colors.selectedText,
            backgroundColor: selected
              ? `${colors.selectedBackground}40`
              : `${neutral[500]}40`,
          },
          "&:focus": {
            color: colors.selectedText,
            border: `2px solid ${colors.focusColor}`,
          },
        },
      }}
    />
  );
}

/** @returns the optional counter in a filter chip */
function ChipCounter({
  count,
  dark,
}: Pick<FaroChipFilterProps, "count" | "dark">): JSX.Element {
  const background = dark ? cyan[600] : blue[400];
  const color = dark ? neutral[999] : neutral[0];

  return (
    <FaroText
      variant="bodyS"
      color={color}
      sx={{
        backgroundColor: background,
        borderRadius: 5,
        px: "4px",
        fontWeight: FontWeights.SemiBold,
        fontSize: "10px",
        height: "14px",
        minWidth: "14px",
        lineHeight: "14px",
      }}
    >
      {count}
    </FaroText>
  );
}
