import { Breadcrumbs, BreadcrumbsProps } from "@mui/material";
import { Children } from "react";

export type FaroBreadcrumbsProps = Omit<BreadcrumbsProps, "separator"> & {
  /**
   * Flag to show the full labels or shorten them with ellipses.
   *
   * @default false
   */
  showFullLabels?: boolean;
};

/**
 * @returns a styled list of links with a separator between each of them.
 * The link must be passed as a children of this component and should preferably use BreadcrumbsItem
 */
export function FaroBreadcrumbs({
  showFullLabels = false,
  sx,
  children,
  ...rest
}: FaroBreadcrumbsProps): JSX.Element {
  const numberOfChildren = Children.count(children);

  return (
    <Breadcrumbs
      aria-label="breadcrumbs"
      sx={{
        ">ol": {
          // Make the breadcrumbs stay on one line
          flexWrap: "nowrap",

          // Set the width of the parent container to be the minimum width,
          // so that the width of the children (li) will be calculated properly
          width: "min-content",

          // Add space between the separator and the labels
          gap: 2,

          ">li": {
            display: "flex",
            whiteSpace: "nowrap",

            // Set the items length as a percentage of the parent
            maxWidth: showFullLabels
              ? "100%"
              : `calc(100% / ${numberOfChildren})`,
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Breadcrumbs>
  );
}
