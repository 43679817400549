import { Material } from "three";
import { FullScreenQuad, Pass } from "three/examples/jsm/postprocessing/Pass.js";

/**
 * A simple base class for all passes that are just applying a material
 * full screen to encapsulate the quad an material disposal in a single place
 */
export class FullScreenPass<M extends Material> extends Pass {
	/** The FullScreenQuad used to render this pass */
	protected fsQuad: FullScreenQuad;
	/** The Material used to render this pass */
	material: M;

	/**
	 * Construct a FullScreenPass with a specific material
	 *
	 * @param material The material to use
	 */
	constructor(material: M) {
		super();
		this.material = material;
		this.fsQuad = new FullScreenQuad(this.material);
	}

	/**
	 * Dispose the material and the quad
	 */
	dispose(): void {
		this.material.dispose();
		this.fsQuad.dispose();
	}
}
