import { CSSProperties } from "react";
import { blue, cyan, neutral, red } from "../colors";

export enum DatePickerVariant {
  Light = "Light",
  Dark = "Dark",
}

export type VariantStyle = {
  /** Color applied to input field background and date picker background. */
  backgroundColor: CSSProperties["backgroundColor"];

  /**
   * Main color used for all selected states.
   * Color applied to the day dot, today day text, selected day background, day pressed background, focused day text
   */
  selectedColor: CSSProperties["color"];

  /** Color applied to the text of non selected days of the month, month text in header, months and years text in their separate view */
  secondaryColor: CSSProperties["color"];

  /** Color applied to day background on hover and the focused day background */
  hoverColor: CSSProperties["backgroundColor"];

  /** Color applied, when the date picker is disabled, to the input field icon and label */
  disabledPrimaryColor: CSSProperties["color"];

  /** Color applied, when the date picker is disabled, to the input field value and border */
  disabledSecondaryColor: CSSProperties["color"];

  /** Color applied, when the date picker is in error state, to the input field border and the label */
  errorColor: CSSProperties["color"];

  /** Color applied to the background of the input field when the date picker is in read only mode */
  readOnlyBackgroundColor: CSSProperties["backgroundColor"];

  /** Color applied to the input field's text value, text placeholder, border color and calendar icon */
  inputFieldDefaultColor: CSSProperties["color"];

  /**
   * Color applied, on hover of the input field or if the date picker is opened,
   * to its text value, text placeholder, border color and calendar icon.
   * (Note: the border, when the date picker is opened, will use the selectedColor)
   */
  inputFieldHoverColor: CSSProperties["color"];

  /** Color applied to the arrow icons to change month in the date picker */
  iconColor: CSSProperties["color"];

  /** Specific color applied to the footer's text inside the date picker */
  textColorFooter: CSSProperties["color"];

  /** Specific color applied to the week's days initials inside the date picker */
  textColorWeekDay: CSSProperties["color"];

  /** Specific color applied to the text of the selected day */
  textColorSelectedDay: CSSProperties["color"];

  /** Specific color applied to the text label on top of the input field (not the input field value) */
  textColorInputFieldLabel: CSSProperties["color"];

  /** Specific color applied to the text for days outside current month  */
  textColorDayOutsideMonth: CSSProperties["color"];
};

const lightVariant: VariantStyle = {
  backgroundColor: neutral[0],
  selectedColor: blue[500],
  secondaryColor: neutral[1000],
  hoverColor: neutral[500],
  disabledPrimaryColor: `${neutral[800]}80`,
  disabledSecondaryColor: `${neutral[500]}80`,
  errorColor: red[300],
  readOnlyBackgroundColor: neutral[100],
  inputFieldDefaultColor: neutral[600],
  inputFieldHoverColor: neutral[800],
  iconColor: `${neutral[1000]}89`,
  textColorFooter: neutral[500],
  textColorWeekDay: neutral[500],
  textColorSelectedDay: neutral[0],
  textColorInputFieldLabel: neutral[800],
  textColorDayOutsideMonth: neutral[500],
};

const darkVariant: VariantStyle = {
  backgroundColor: neutral[999],
  selectedColor: cyan[500],
  secondaryColor: neutral[0],
  hoverColor: neutral[100],
  disabledPrimaryColor: `${neutral[100]}80`,
  disabledSecondaryColor: `${neutral[500]}80`,
  errorColor: red[300],
  readOnlyBackgroundColor: neutral[900],
  inputFieldDefaultColor: neutral[500],
  inputFieldHoverColor: neutral[0],
  iconColor: neutral[100],
  textColorFooter: neutral[0],
  textColorWeekDay: neutral[300],
  textColorSelectedDay: neutral[0],
  textColorInputFieldLabel: neutral[100],
  textColorDayOutsideMonth: neutral[500],
};

export const variantMap: Record<DatePickerVariant, VariantStyle> = {
  Light: lightVariant,
  Dark: darkVariant,
};
