import { ShaderMaterial, Texture } from "three";
import vert from "../Shaders/TexturedQuad.vert";
import frag from "../Shaders/Blit.frag";
import { makeUniform } from "./Uniforms";

/**
 * A material to just render color/depth from textures to a render target
 */
export class BlitMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uColorTexture: makeUniform<Texture | null>(null),
		uDepthTexture: makeUniform<Texture | null>(null),
	};
	constructor() {
		super();
	}
}
