import { GLSL3, RawShaderMaterial, Texture, Vector4 } from "three";
import vert from "../Shaders/TexturedQuadRaw.vert";
import frag from "../Shaders/TripleFBOComposer.frag";
import { makeUniform } from "./Uniforms";

/**
 * A material to compose three framebuffers on top of a background FBO/color. Composition is done by blending
 * each one of the FBOs with a give opacity value. Depth-correct blending order is guaranteed pixel by pixel.
 */
export class TripleFBOComposerMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;

	override uniforms = {
		uColorTex0: makeUniform<Texture | null>(null),
		uDepthTex0: makeUniform<Texture | null>(null),
		uColorTex1: makeUniform<Texture | null>(null),
		uDepthTex1: makeUniform<Texture | null>(null),
		uColorTex2: makeUniform<Texture | null>(null),
		uDepthTex2: makeUniform<Texture | null>(null),
		uColorTex3: makeUniform<Texture | null>(null),
		uDepthTex3: makeUniform<Texture | null>(null),
		opacity1: makeUniform<number>(1.0),
		opacity2: makeUniform<number>(1.0),
		opacity3: makeUniform<number>(1.0),
		uBackground: makeUniform(new Vector4(0, 0, 0, 0)),
		uOverrideBackground: makeUniform(false),
		uMinOpacity: makeUniform<number>(0),
	};

	constructor() {
		super({ glslVersion: GLSL3 });
	}
}
