import { assert } from "@faro-lotv/foundation";
import {
	Box3,
	BufferAttribute,
	BufferGeometry,
	Color,
	Float32BufferAttribute,
	Sphere,
	Uint8BufferAttribute,
	Vector3,
} from "three";
import { PointCloudChunk } from "./PointCloudChunk";

const WHITE = 0xffffff;
export const DEFAULT_CHUNK_COLOR = new Color(WHITE);
export const DEFAULT_CHUNK_NORMAL = new Vector3(1, 0, 0);
export const DEFAULT_CHUNK_ATTRIBUTES: PointCloudAttributes = { normals: false, colors: true };
export const DEFAULT_AUTO_UPDATE_BOUNDING_SPHERE = true;
const DEFAULT_EMPTY_POSITION_ATTRIBUTE = new Float32BufferAttribute([], 0, false);
const RGB_MULTIPLIER = 255;

/**
 * Information on a specific pointcloud memory layout
 */
export type PointCloudAttributes = {
	/** Request the allocation of a normal buffer */
	normals: boolean;

	/** Request the allocation of a color buffer */
	colors: boolean;
};

/**
 * Create an array of normals using a default value
 *
 * @param size The number of normals in the array
 * @param value The value to use
 * @returns The computed array
 */
function createFixedNormalArray(size: number, value: Vector3): Float32Array {
	const arr = new Float32Array(size * 3);
	for (let point = 0; point < size; point++) {
		arr[point * 3 + 0] = value.x;
		arr[point * 3 + 1] = value.y;
		arr[point * 3 + 2] = value.z;
	}
	return arr;
}

/**
 * Create an array of colors using a default value
 *
 * @param size The number of colors in the array
 * @param value The value to use
 * @returns The compute array
 */
function createFixedColorArray(size: number, value: Color): Uint8Array {
	const arr = new Uint8Array(size * 4);
	for (let point = 0; point < size; point++) {
		arr[point * 3 + 0] = value.r * RGB_MULTIPLIER;
		arr[point * 3 + 1] = value.g * RGB_MULTIPLIER;
		arr[point * 3 + 2] = value.b * RGB_MULTIPLIER;
		arr[point * 3 + 3] = 255;
	}
	return arr;
}

/**
 * Compute the attributes to use from the passed chunk
 *
 * @param chunk The chunk to analyze
 * @returns The attribute to allocate to render the passed chunk
 */
export function inferAttributes(chunk: PointCloudChunk): PointCloudAttributes {
	return {
		normals: chunk.normals !== undefined,
		colors: chunk.colors !== undefined,
	};
}

export type PointCloudBufferGeometryOptions = {
	/** If defined only the specified attributes will be allocated */
	attributes?: PointCloudAttributes;

	/** Default color used when a chunk without color is appended to a pointcloud with colors */
	color: Color;

	/** Default normal used when a chunk without normals is appended to a pointcloud with normals */
	normal: Vector3;

	/** True to automatically re-compute the bounding sphere when the data changes */
	autoUpdateBoundingSphere: boolean;
};

export type PointCloudBufferAttributes = {
	position: Float32BufferAttribute;
	normal?: Float32BufferAttribute;
	color?: Uint8BufferAttribute;
};

/**
 * This class overrides the default point cloud geometry in order
 * to adapt the computations of the bounding box/sphere to our data structure.
 */
export class PointCloudBufferGeometry extends BufferGeometry {
	options: PointCloudBufferGeometryOptions;

	override attributes: PointCloudBufferAttributes = {
		position: DEFAULT_EMPTY_POSITION_ATTRIBUTE,
	};

	/** @returns the current allocated attributes for this geometry */
	get allocatedAttributes(): PointCloudAttributes {
		return {
			colors: this.attributes.color !== undefined,
			normals: this.attributes.normal !== undefined,
		};
	}

	/**
	 * Construct and initialize a new PointCloudBufferGeometry
	 *
	 * @param chunkOrCapacity a point cloud chunk or a fixed capacity
	 * @param options to customize this attribute allocation
	 */
	constructor(chunkOrCapacity: PointCloudChunk | number, options: Partial<PointCloudBufferGeometryOptions> = {}) {
		super();
		const init = this.initialize(chunkOrCapacity, options);
		this.options = init.options;
		this.attributes = init.attributes;
	}

	/**
	 * Initialize the PointCloudBufferGeometry internal buffers and options
	 *
	 * @param chunkOrCapacity a point cloud chunk or a fixed capacity
	 * @param options to customize this attribute allocation
	 * @returns the attributes and options used
	 */
	initialize(
		chunkOrCapacity: PointCloudChunk | number,
		options: Partial<PointCloudBufferGeometryOptions> = {},
	): { options: PointCloudBufferGeometryOptions; attributes: PointCloudBufferAttributes } {
		this.options = {
			color: DEFAULT_CHUNK_COLOR,
			normal: DEFAULT_CHUNK_NORMAL,
			autoUpdateBoundingSphere: DEFAULT_AUTO_UPDATE_BOUNDING_SPHERE,
			...options,
		};
		this.drawRange.start = 0;
		this.boundingBox = null;
		this.boundingSphere = null;
		if (typeof chunkOrCapacity === "number") {
			this.attributes = this.initGeometryByCapacity(chunkOrCapacity);
		} else {
			this.attributes = this.initGeometryFromChunk(chunkOrCapacity);
		}
		return {
			attributes: this.attributes,
			options: this.options,
		};
	}

	/**
	 * Initialize the point cloud geometry allocating the requested attributes using the data from a chunk
	 *
	 * @param chunk The chunk data to use to initialize the buffers
	 * @returns the position attribute so typescript knows it's been initialized for sure
	 */
	private initGeometryFromChunk(chunk: PointCloudChunk): PointCloudBufferAttributes {
		const attributes = this.options.attributes ?? inferAttributes(chunk);
		const bufferAttributes: PointCloudBufferAttributes = {
			position: new Float32BufferAttribute(chunk.positions, 3, false),
		};
		this.setAttribute("position", bufferAttributes.position);
		if (attributes.normals) {
			const normalArray = chunk.normals ?? createFixedNormalArray(chunk.numPoints, this.options.normal);
			bufferAttributes.normal = new Float32BufferAttribute(normalArray, 3, false);
			this.setAttribute("normal", bufferAttributes.normal);
		}
		if (attributes.colors) {
			const colorArray = chunk.colors ?? createFixedColorArray(chunk.numPoints, this.options.color);
			bufferAttributes.color = new Uint8BufferAttribute(colorArray, 4, true);
			this.setAttribute("color", bufferAttributes.color);
		}
		this.drawRange.count = chunk.numPoints;
		if (this.options.autoUpdateBoundingSphere) {
			this.computeBoundingSphere();
		}
		return bufferAttributes;
	}

	/**
	 * Initialize the point cloud geometry with an initial capacity
	 *
	 * @param capacity The initial capacity for all the buffers
	 * @returns the position attribute so typescript knows it's been initialized for sure
	 */
	private initGeometryByCapacity(capacity: number): PointCloudBufferAttributes {
		const attributes = this.options.attributes ?? DEFAULT_CHUNK_ATTRIBUTES;
		const bufferAttributes: PointCloudBufferAttributes = {
			position: new Float32BufferAttribute(capacity * 3, 3, false),
		};
		this.setAttribute("position", bufferAttributes.position);
		if (attributes.normals) {
			bufferAttributes.normal = new Float32BufferAttribute(new Float32Array(capacity * 3), 3, false);
			this.setAttribute("normal", bufferAttributes.normal);
		}
		if (attributes.colors) {
			bufferAttributes.color = new Uint8BufferAttribute(new Uint8Array(capacity * 4), 4, true);
			this.setAttribute("color", bufferAttributes.color);
		}
		this.drawRange.count = 0;
		return bufferAttributes;
	}

	/**
	 * Compute the bounding box from the positions attribute array
	 */
	override computeBoundingBox(): void {
		if (!this.boundingBox) {
			this.boundingBox = new Box3();
		}

		const point = new Vector3();
		const positions = this.attributes.position.array;
		for (let i = this.drawRange.start; i < this.drawRange.start + this.drawRange.count; i++) {
			const idx = 3 * i;
			if (idx < 0 || idx >= positions.length) {
				return;
			}
			point.x = positions[idx];
			point.y = positions[idx + 1];
			point.z = positions[idx + 2];
			this.boundingBox.expandByPoint(point);
		}
	}

	/**
	 * Compute the bounding sphere from the positions attribute array
	 */
	override computeBoundingSphere(): void {
		if (this.boundingSphere === null) {
			this.boundingSphere = new Sphere();
		}

		if (!this.boundingBox) {
			this.computeBoundingBox();
		}
		if (!this.boundingBox) throw new Error("Expected a bounding box after computeBoundingBox");

		this.boundingBox.getCenter(this.boundingSphere.center);
		this.boundingSphere.radius = this.boundingBox.min.distanceTo(this.boundingBox.max) / 2;
	}

	/** @returns the position attribute object */
	get positionAttribute(): Float32BufferAttribute {
		return this.attributes.position;
	}

	/** @returns the position array */
	get positionArray(): Float32Array {
		const { array } = this.positionAttribute;
		assert(array instanceof Float32Array, "Invalid positions array");
		return array;
	}

	/** @returns the normal attribute object */
	get normalAttribute(): Float32BufferAttribute | undefined {
		return this.attributes.normal;
	}

	/** @returns the normal array */
	get normalArray(): Float32Array | undefined {
		const array = this.normalAttribute?.array;
		if (!array) return;
		assert(array instanceof Float32Array, "Invalid normals array");
		return array;
	}

	/** @returns the color attribute object */
	get colorAttribute(): Uint8BufferAttribute | undefined {
		return this.attributes.color;
	}

	/** @returns the color array */
	get colorArray(): Uint8Array | undefined {
		const array = this.colorAttribute?.array;
		if (!array) return;
		assert(array instanceof Uint8Array, "Invalid colors array");
		return array;
	}

	/** @returns the number of point to render in this pointcloud */
	get size(): number {
		return this.drawRange.count;
	}

	/** @returns how many point this point cloud storage can store without a relocation */
	get capacity(): number {
		return this.positionArray.length / 3;
	}

	/** @returns how many point the pointcloud can append without a relocation */
	get residualCapacity(): number {
		return this.capacity - this.size;
	}

	/**
	 * Increase the capacity to allow space for more points, will relocate the memory
	 *
	 * @param increase the number of points we want to store more than the current capacity
	 */
	grow(increase: number): void {
		this.growPositions(increase);
		this.growNormals(increase);
		this.growColors(increase);
	}

	/**
	 * Grow the position array
	 *
	 * @param increase How many more points we want to store
	 */
	private growPositions(increase: number): void {
		const data = new Float32Array(this.positionArray.length + increase * 3);
		const { version } = this.positionAttribute;
		data.set(this.positionArray, 0);
		const attribute = new Float32BufferAttribute(data, 3, false);
		attribute.version = version;
		this.setAttribute("position", attribute);
	}

	/**
	 * Grow the normal array
	 *
	 * @param increase How many more points we want to store
	 */
	private growNormals(increase: number): void {
		const oldAttribute = this.normalAttribute;
		const oldData = this.normalArray;
		if (oldAttribute && oldData) {
			const data = new Float32Array(oldData.length + increase * 3);
			const { version } = oldAttribute;
			data.set(oldData, 0);
			const attribute = new Float32BufferAttribute(data, 3, false);
			attribute.version = version;
			this.setAttribute("normal", attribute);
		}
	}

	/**
	 * Grow the color array
	 *
	 * @param increase How many more points we want to store
	 */
	private growColors(increase: number): void {
		const oldAttribute = this.colorAttribute;
		const oldData = this.colorArray;
		if (oldAttribute && oldData) {
			const data = new Uint8Array(oldData.length + increase * 4);
			const { version } = oldAttribute;
			data.set(oldData, 0);
			const attribute = new Uint8BufferAttribute(data, 4, true);
			attribute.version = version;
			this.setAttribute("color", attribute);
		}
	}

	/**
	 * Append a chunk of points to this pointcloud
	 *
	 * @param chunk The chunk of points
	 * @param canGrow true if the pointcloud can grow its memory to make space for the chunk
	 * @returns true if we were able to append the points
	 */
	append(chunk: PointCloudChunk, canGrow: boolean): boolean {
		if (chunk.numPoints > this.residualCapacity) {
			if (!canGrow) return false;
			this.grow(Math.max(this.capacity, chunk.numPoints));
		}
		const offset = this.size;
		this.writePositions(chunk.positions, offset, chunk.numPoints);
		this.writeNormals(chunk.normals, offset, chunk.numPoints);
		this.writeColors(chunk.colors, offset, chunk.numPoints);
		this.drawRange.count += chunk.numPoints;
		if (this.options.autoUpdateBoundingSphere) {
			this.boundingBox = null;
			this.boundingSphere = null;
			this.computeBoundingSphere();
		}
		return true;
	}

	/**
	 * Write some values inside an attribute array, mark the wrote region to be updated at the next update
	 *
	 * @param attribute The attribute we want to change
	 * @param array The array to write to
	 * @param data The new data to write
	 * @param offset The offset (vertex) to start writing from
	 * @param count The number of vertices to change
	 */
	private writeToAttribute<T extends Float32Array | Uint8Array>(
		attribute: BufferAttribute,
		array: T,
		data: T,
		offset: number,
		count: number,
	): void {
		array.set(data, offset * attribute.itemSize);
		const updateRange = attribute.updateRanges.at(0);
		if (updateRange) {
			// If we already had a marked region extend it to cover all the new points
			const start = updateRange.start / attribute.itemSize;
			const end = start + updateRange.count / attribute.itemSize;
			const newStart = Math.min(start, offset);
			const newEnd = Math.max(end, offset + count);
			updateRange.start = newStart * attribute.itemSize;
			updateRange.count = (newEnd - newStart) * attribute.itemSize;
		} else {
			attribute.addUpdateRange(offset * attribute.itemSize, count * attribute.itemSize);
		}
		attribute.needsUpdate = true;
	}

	/**
	 * Write new data in the position attribute
	 *
	 * @param data The new data to write
	 * @param vertexPosition First vertex to overwrite
	 * @param count The number of vertices we will rewrite
	 */
	private writePositions(data: Float32Array, vertexPosition: number, count: number): void {
		const attribute = this.positionAttribute;
		const array = this.positionArray;
		this.writeToAttribute(attribute, array, data, vertexPosition, count);
	}

	/**
	 * Write new data in the normal attribute
	 *
	 * @param data The new data to write
	 * @param vertexNormal First vertex to overwrite
	 * @param count The number of vertices we will rewrite
	 */
	private writeNormals(data: Float32Array | undefined, vertexNormal: number, count: number): void {
		const attribute = this.normalAttribute;
		const array = this.normalArray;
		if (attribute && array) {
			const newData = data ?? createFixedNormalArray(count, this.options.normal);
			this.writeToAttribute(attribute, array, newData, vertexNormal, count);
		}
	}

	/**
	 * Write new data in the color attribute
	 *
	 * @param data The new data to write
	 * @param vertexColor First vertex to overwrite
	 * @param count The number of vertices we will rewrite
	 */
	private writeColors(data: Uint8Array | undefined, vertexColor: number, count: number): void {
		const attribute = this.colorAttribute;
		const array = this.colorArray;
		if (attribute && array) {
			const newData = data ?? createFixedColorArray(count, this.options.color);
			this.writeToAttribute(attribute, array, newData, vertexColor, count);
		}
	}

	/**
	 * Overwrite some points inside the pointcloud data
	 *
	 * @param chunk The chunk of points to write
	 * @param offset Index of the first point to rewrite
	 */
	set(chunk: PointCloudChunk, offset: number): void {
		if (offset > this.size) {
			throw new RangeError(
				"PointCloud set failed: Write offset needs to be inside the current valid range to not create gaps of invalid data",
			);
		}
		if (offset + chunk.numPoints > this.capacity) {
			throw new RangeError("PointCloud set failed: The new chunk will write after the current capacity");
		}
		this.writePositions(chunk.positions, offset, chunk.numPoints);
		this.writeNormals(chunk.normals, offset, chunk.numPoints);
		this.writeColors(chunk.colors, offset, chunk.numPoints);
	}

	/**
	 * Copy out a chunk of point from the pointcloud memory
	 *
	 * @param offset Index of the first point to copy out
	 * @param count Number of points to copy
	 * @returns a pointcloud chunk with the points
	 */
	getChunk(offset: number, count: number): PointCloudChunk {
		return {
			numPoints: count,
			positions: this.positionArray.slice(
				offset * this.positionAttribute.itemSize,
				(offset + count) * this.positionAttribute.itemSize,
			),
			colors:
				this.colorArray && this.colorAttribute
					? this.colorArray.slice(
							offset * this.colorAttribute.itemSize,
							(offset + count) * this.colorAttribute.itemSize,
						)
					: undefined,
			normals:
				this.normalArray && this.normalAttribute
					? this.normalArray.slice(
							offset * this.normalAttribute.itemSize,
							(offset + count) * this.normalAttribute.itemSize,
						)
					: undefined,
		};
	}

	/**
	 * Replace a chunk of points with a new chunk of points
	 *
	 * @param chunk The new points
	 * @param offset Index of the first point to replace
	 * @returns The replaced points
	 */
	replace(chunk: PointCloudChunk, offset: number): PointCloudChunk {
		const old = this.getChunk(offset, chunk.numPoints);
		this.set(chunk, offset);
		return old;
	}
}
