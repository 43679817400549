import { UseCustomCursorProps, useCustomCursor } from "@faro-lotv/flat-ui";
import { useThree } from "@react-three/fiber";

/**
 * Wrapper for useCustomCursor that applies the cursor on the current <Canvas />'s domElement
 *
 * @param props to pass into useCustomCursor
 */
export function useCustomCanvasCursor(
  props: Omit<UseCustomCursorProps, "domElement">,
): void {
  const gl = useThree((s) => s.gl);
  useCustomCursor({ ...props, domElement: gl.domElement });
}
