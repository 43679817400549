import { Stack, StepIconProps } from "@mui/material";
import { useMemo } from "react";
import { blue, neutral } from "../colors";
import { FaroText } from "../text/faro-text/faro-text";

/**
 * @returns Icon used for showing the state of the current step
 */
export function FaroStepIcon({
  active,
  completed,
  icon,
}: StepIconProps): JSX.Element {
  const iconColor = useMemo(() => {
    if (active) return blue[500];
    if (completed) return neutral[800];
    return neutral[100];
  }, [active, completed]);

  const isFutureStep = !active && !completed;

  return (
    <Stack
      component="span"
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: "50%",
        width: "36px",
        height: "36px",
        backgroundColor: iconColor,
      }}
    >
      <FaroText
        variant="heading20"
        color={isFutureStep ? neutral[800] : neutral[0]}
        sx={{ opacity: isFutureStep ? 0.5 : 1 }}
      >
        {icon}
      </FaroText>
    </Stack>
  );
}
