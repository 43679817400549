import {
	Box3,
	ClampToEdgeWrapping,
	DataTexture,
	FloatType,
	Matrix4,
	Mesh,
	NearestFilter,
	RGBAFormat,
	Sphere,
	Texture,
	UVMapping,
	Vector2,
	Vector3,
} from "three";

export const CAD_DEFAULT_GRAY = 0.8;
export const CAD_DEFAULT_COLOR = new Vector3(CAD_DEFAULT_GRAY, CAD_DEFAULT_GRAY, CAD_DEFAULT_GRAY);
export const CAD_DEFAULT_SPECULAR = 0.2;

/**
 * These two values of specular color and shininess define a specular light
 * resembling wood material or stone. To get metallic specular light, try
 * shininess = 400 (small spot) and specular color = 1,1,1 (intense spot).
 */
export const CAD_DEFAULT_SPECULAR_COLOR = new Vector3(CAD_DEFAULT_SPECULAR, CAD_DEFAULT_SPECULAR, CAD_DEFAULT_SPECULAR);
export const CAD_DEFAULT_SHININESS = 80;

const MAT_SHININESS_OFFSET = 12;
const VISIBILITY_FLAG_OFFSET = 15;

/** A simple type to read and write the material of each CAD node. */
export type CadNodeMaterial = {
	/** Ambient color. Values are from 0.0 to 1.0 */
	ambient: Vector3;
	/** Diffuse color. Values are from 0.0 to 1.0 */
	diffuse: Vector3;
	/** Specular color. Values are from 0.0 to 1.0 */
	specular: Vector3;
	/**
	 * Shininess. Defaults to CAD_DEFAULT_SHININESS. The higher this value,
	 * the smaller the spot of brilliant specular light on the surface. Therefore,
	 * the higher shininess the more metallic the material. The intensity of the
	 * specular light is instead determined by the 'specular' field of the CADNodeMaterial.
	 */
	shininess: number;
	/** Opacity from 0.0 to 1.0 */
	opacity: number;
};

/**
 * This internal class represents a single CAD node.
 */
export class CadNode {
	/** Visibility flag, added for model filtering */
	visible = true;
	/** Whether or not this node should be highlighted */
	highlighted = false;
	/** Node name */
	name = "";
	/** Node bounding box */
	boundingBox = new Box3();
	/** Node bounding sphere */
	boundingSphere = new Sphere();
	/** number of vertices of this geometry */
	vCount = 0;
	/** Offset of this geometry inside the buffer of all geometries */
	vOffset = 0;
	/** Number of indices of this geometry */
	iCount = 0;
	/** Offset of this geometry indices inside the buffer of all geometries */
	iOffset = 0;
	/** Node ID in the list of visible (unfiltered) nodes */
	indexInVisibleNodes = 0;
	/** Node pose */
	matrixWorld = new Matrix4();
	/** Node material */
	material: CadNodeMaterial = {
		ambient: CAD_DEFAULT_COLOR.clone(),
		diffuse: CAD_DEFAULT_COLOR.clone(),
		specular: CAD_DEFAULT_SPECULAR_COLOR.clone(),
		shininess: CAD_DEFAULT_SHININESS,
		opacity: 1.0,
	};
	/** Texture ID */
	textureId = -1;
	/**
	 * The ObjectID of the CAD part this mesh belongs to. The object ID is a unique
	 * number assigned to each CAD part in Autodesk SVF. Each CAD object may have
	 * one or more meshes.
	 */
	objectId: number | undefined = undefined;
	/** @returns whether this CAD part is transparent */
	get transparent(): boolean {
		return this.material.opacity !== 1.0;
	}
	/**
	 * Constructs this object storing the original mesh
	 *
	 * @param originalMesh the original mesh
	 */
	constructor(public originalMesh: Mesh) {}
}

/**
 * Buffers and data textures needed for the CadModelBasic to work
 */
export class CadBasicRenderBuffers {
	/** Data buffer to be loaded into the poses data texture */
	posesBuffer = new Float32Array();
	/** Data buffer to be loaded into the materials data texture */
	materialsBuffer = new Float32Array();
	/** Whether the poses buffer must be updated to GPU */
	posesDirty = true;
	/** Whether the materials buffer must be updated to GPU */
	materialsDirty = true;
	/** Data texture where all poses are loaded */
	posesDataTexture: Texture | null = null;
	/** Data texture where all materials are loaded */
	materialsDataTexture: Texture | null = null;
	/** Size of the two data textures for poses and materials, in pixels */
	#dataTexturesSize = new Vector2();

	/**
	 * Given sz, returns the smallest texture size (width, height) so that width*height > sz and width is a power of two.
	 *
	 * @param sz The input buffer size.
	 * @param result Vector on which the result will be written
	 * @returns the 'result' param
	 */
	#getValidTextureSize(sz: number, result: Vector2): Vector2 {
		let x = 64;
		let xsq = x * x;
		while (sz > xsq) {
			x *= 2;
			xsq = x * x;
		}
		// Below the y dimension of the texture size is set to be at least 4.
		// This avoids the case in which a texture has zero size in one of the dimensions
		// and allows lotv to render even empty models (e.g. when an imported models
		// has only lines and not surfaces).
		result.set(x, Math.max(4, Math.ceil(sz / x)));
		return result;
	}

	/**
	 * Uploads the argument buffer into a new data texture in GPU.
	 *
	 * @param buffer The data to store in the texture
	 * @returns A new data texture containing the given buffer
	 */
	#createDataTexture(buffer: Float32Array): DataTexture {
		return new DataTexture(
			buffer,
			this.#dataTexturesSize.x,
			this.#dataTexturesSize.y,
			RGBAFormat,
			FloatType,
			UVMapping,
			ClampToEdgeWrapping,
			ClampToEdgeWrapping,
			NearestFilter,
			NearestFilter,
		);
	}

	/**
	 * Allocates enough memory in the poses and materials buffers to store
	 * the given number of CAD nodes.
	 *
	 * @param numNodes The number of CAD nodes in the model.
	 */
	allocateBuffers(numNodes: number): void {
		// Poses buffer is a buffer that will be uploaded to a texture. For each view,
		// we store four vec4, therefore four triplets of four floats: X, Y, Z, W, translation.
		this.#getValidTextureSize(numNodes * 4, this.#dataTexturesSize);
		const dataTexSizeBytes = this.#dataTexturesSize.x * this.#dataTexturesSize.y * 4;
		this.posesBuffer = new Float32Array(dataTexSizeBytes);
		this.materialsBuffer = new Float32Array(dataTexSizeBytes);
	}

	/**
	 * If the poses data texture is dirty, it is updated to GPU
	 * On a NVIDIA desktop GPU, this update takes 0.15 -> 0.25 milliseconds
	 */
	maybeUpdatePosesTexture(): void {
		if (this.posesDirty) {
			if (this.posesDataTexture === null) {
				this.posesDataTexture = this.#createDataTexture(this.posesBuffer);
			}
			this.posesDataTexture.needsUpdate = true;
			this.posesDirty = false;
		}
	}

	/**
	 * If the materials data texture is dirty, it is updated to GPU
	 */
	maybeUpdateMaterialsTexture(): void {
		if (this.materialsDirty) {
			if (this.materialsDataTexture === null) {
				this.materialsDataTexture = this.#createDataTexture(this.materialsBuffer);
			}
			this.materialsDataTexture.needsUpdate = true;
			this.materialsDirty = false;
		}
	}

	/**
	 * Disposes the data textures
	 */
	dispose(): void {
		this.posesDataTexture?.dispose();
		this.materialsDataTexture?.dispose();
		this.posesDataTexture = null;
		this.materialsDataTexture = null;
		this.posesDirty = true;
		this.materialsDirty = true;
	}

	/**
	 *
	 * @param cadNode The node whose material must be updated to the buffer
	 * @param n Index of the node in the list
	 */
	setMaterial(cadNode: CadNode, n: number): void {
		const buffer = this.materialsBuffer;
		const offset = n * 16;
		const mat = cadNode.material;

		mat.ambient.toArray(buffer, offset);
		mat.diffuse.toArray(buffer, offset + 4);
		mat.specular.toArray(buffer, offset + 8);

		buffer[offset + MAT_SHININESS_OFFSET] = mat.shininess;
		buffer[offset + MAT_SHININESS_OFFSET + 1] = mat.opacity;
		buffer[offset + MAT_SHININESS_OFFSET + 2] = cadNode.textureId;
		buffer[offset + MAT_SHININESS_OFFSET + 3] = cadNode.highlighted ? 1 : 0;
		this.materialsDirty = true;
	}

	/**
	 *
	 * @param cadNode The cad node
	 * @param n Index of cad node in the nodes list
	 */
	updateVisibilityFlag(cadNode: CadNode, n: number): void {
		const o = n * 16 + VISIBILITY_FLAG_OFFSET;
		this.posesBuffer[o] = cadNode.visible ? 1.0 : 0.0;
		this.posesDirty = true;
	}

	/**
	 *
	 * @param cadNode The node whose pose must be set to the buffer.
	 * @param n index of the cad node
	 */
	setPose(cadNode: CadNode, n: number): void {
		const o = n * 16;
		this.posesBuffer.set(cadNode.matrixWorld.elements, o);
		this.updateVisibilityFlag(cadNode, n);
	}
}
