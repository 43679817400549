import { IElement } from "./i-element";
import { IElementBase, IElementType } from "./i-element-base";
import { createIElement, createIElementBase } from "./i-element-factory";

/**
 * Dummy values for required props of {@link IElementBase}.
 * Can be used for conveniently calling {@link createIElement} and {@link createIElementBase} in tests.
 */
export const IELEMENT_REQUIRED_PROPS_DUMMIES = {
  rootId: "randomRootId",
  type: IElementType.group,
  name: "newIElement",
  createdBy: "user123456789",
};

/**
 * A wrapper for {@link createIElementBase} that automatically includes {@link IELEMENT_REQUIRED_PROPS_DUMMIES}
 * for required IElementBase props, making it easier to create valid IElementBases in tests.
 *
 * @param props custom props to use for the new IElementBase
 * @returns a valid IElementBase with all values initialized.
 */
export function createTestIElementBase(
  props: Partial<IElementBase>,
): IElementBase {
  return createIElementBase({
    ...IELEMENT_REQUIRED_PROPS_DUMMIES,
    ...props,
  });
}

/**
 * A wrapper for {@link createIElement} that automatically includes {@link IELEMENT_REQUIRED_PROPS_DUMMIES}
 * for required IElementBase props, making it easier to create valid IElements in tests.
 *
 * @param props custom props to use for the new IElement. type and required props of the desired IElement type are required.
 * @returns a valid IElement of the desired type with all values initialized.
 */
export function createTestIElement<T extends IElement>(
  props: Omit<T, keyof IElementBase> &
    Partial<IElementBase> & {
      type: T["type"];
    },
): T {
  return createIElement<T>({
    ...IELEMENT_REQUIRED_PROPS_DUMMIES,
    ...props,
  });
}
