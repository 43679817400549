import { assert } from "@faro-lotv/foundation";
import { Box2, Box3, Camera, Frustum, Matrix4, PerspectiveCamera, Ray, Vector2, Vector3, Vector4 } from "three";
import { uToPhi, vToTheta } from "../Pano/TiledPano";
import { LeanBinaryHeap } from "../Utils/LeanBinaryHeap";
import { memberWithPrivateData } from "../Utils/MemoryUtils";
import { ImageTree, ImageTreeNode } from "./ImageTree";
import { WeightedNode } from "./VisibleNodeStrategy";

const DEFAULT_MIN_PIXEL_SIZE = 500;
const DEFAULT_FLOORPLAN_MIN_PIXEL_SIZE = 200;

/**
 * This interface describes the shape of a pluggable strategy to be used for determining which nodes of a tree should be shown
 * given a camera and a screen resolution.
 */
export interface VisibleTilesStrategy {
	/** @returns The maximum amount of points to be loaded in GPU. */
	get maxTilesInGPU(): number;
	/**
	 * @param n The new value of max points in GPU.
	 */
	set maxTilesInGPU(n: number);

	/**
	 * Given a camera, this algorithm returns the list of nodes that are visible from the camera, sorted by node index.
	 *
	 * @param matrix The 3D transformation matrix of the tree
	 * @param tree The tree on which to perform the computation
	 * @param camera The camera from which the scene is being rendered.
	 * @param screenSize The current screen size, in pixels.
	 * @returns the sorted list of indices of the visible nodes
	 */
	compute(matrix: Matrix4, tree: ImageTree, camera: Camera, screenSize: Vector2): WeightedNode[];
}

/**
 * Compute the projection-view-model matrix based on the camera position and
 * the object world matrix
 *
 * @param camera The input camera
 * @param worldModelMatrix The model matrix of the object
 * @returns The projection-view-model matrix for the current scene
 */
const computeMatrix = memberWithPrivateData(() => {
	const vMatrix = new Matrix4();
	const mMatrix = new Matrix4();
	const pvmMatrix = new Matrix4();

	return (camera: Camera, worldModelMatrix: Matrix4): Matrix4 => {
		vMatrix.copy(camera.matrixWorldInverse);
		vMatrix.setPosition(0, 0, 0);

		mMatrix.copy(worldModelMatrix);
		mMatrix.setPosition(0, 0, 0);

		pvmMatrix.multiplyMatrices(vMatrix, mMatrix);
		pvmMatrix.setPosition(0, 0, 0);

		pvmMatrix.premultiply(camera.projectionMatrix);
		return pvmMatrix;
	};
});

/**
 * Compute the bounding box of a sphere portion, defined by its angles
 *
 * @param phiStart The starting value of the azimuth angle
 * @param phiRange The variation range of the azimuth angle, such that the final angle will be phiStart + phiRange
 * @param thetaStart The starting value of the polar angle
 * @param thetaRange The variation range of the polar angle, such that the final angle will be thetaStart + thetaRange
 * @returns The 3d bounding box of the current sphere portion
 */
const sphericalToNDCBoundingBox = memberWithPrivateData(() => {
	const widthSegments = 4;
	const heightSegments = 4;
	const vertex = new Vector3();
	const bbox = new Box3();

	return (phiStart: number, phiRange: number, thetaStart: number, thetaRange: number): Box3 => {
		bbox.makeEmpty();
		for (let iy = 0; iy <= heightSegments; iy++) {
			const v = iy / heightSegments;
			for (let ix = 0; ix <= widthSegments; ix++) {
				const u = ix / widthSegments;

				vertex.x = Math.cos(phiStart + u * phiRange) * Math.cos(thetaStart + v * thetaRange);
				vertex.y = Math.sin(phiStart + u * phiRange) * Math.cos(thetaStart + v * thetaRange);
				vertex.z = Math.sin(thetaStart + v * thetaRange);
				bbox.expandByPoint(vertex);
			}
		}
		return bbox;
	};
});

/**
 * Compute the 3d bounding box of a specific tile
 *
 * @param rect The 2d bounding box of the tile
 * @returns The 3d bounding box fo the sphere portion corresponding to the tile
 */
function computeBoundingBox(rect: Box2): Box3 {
	const phi0 = uToPhi(rect.min.x * 2 - 1);
	const phi1 = uToPhi(rect.max.x * 2 - 1);
	const theta0 = vToTheta(rect.min.y * 2 - 1);
	const theta1 = vToTheta(rect.max.y * 2 - 1);
	return sphericalToNDCBoundingBox(
		Math.min(phi0, phi1),
		Math.abs(phi0 - phi1),
		Math.min(theta0, theta1),
		Math.abs(theta0 - theta1),
	);
}

const isNodeVisible = memberWithPrivateData(() => {
	const center = new Vector3();
	const ray = new Ray();

	return (node: ImageTreeNode, viewDir: Vector3, frustum: Frustum): { visible: boolean; metric: number } => {
		// Compute the bounding box of this tile
		const box = computeBoundingBox(node.rect);
		box.getCenter(center);

		// Compute the distance of the center of the bounding box
		// from the view direction provided
		ray.origin.set(0, 0, 0);
		ray.direction.copy(viewDir);
		const distance = ray.distanceSqToPoint(center);

		// Return the visibility and the metric for this tile.
		// The metric is the distance from the view direction weighted by the area of the tile
		const { max, min } = node.rect;
		return { visible: frustum.intersectsBox(box), metric: (max.x - min.x) * (max.y - min.y) * distance };
	};
});

/**
 * Compute how many pixels a pano tile occupies on screen
 *
 * @param node The input tile
 * @param pixelToAngleRatio The ratio between the vertical screen size (in pixel) and the vertical fov of the camera
 * @returns The number of pixels occupied by the tile on screen
 */
function panoTilePixelSize(node: ImageTreeNode, pixelToAngleRatio: number): number {
	const { rect } = node;
	const theta0 = vToTheta(rect.min.y * 2 - 1);
	const theta1 = vToTheta(rect.max.y * 2 - 1);
	const range = Math.abs(theta0 - theta1);
	return range * pixelToAngleRatio;
}

/**
 * Compute how many pixels a floor plan tile occupies on screen
 *
 * @param node The input tile
 * @param matrix The projection-view-model matrix of the scene
 * @param screenSize The size of the screen in pixels
 * @returns The number of pixels occupied by the tile on screen
 */
const floorPlanTileDistance = memberWithPrivateData(() => {
	const modelMatrix = new Matrix4();
	const trasl = new Matrix4();
	const scaleM = new Matrix4();
	const pvmMatrix = new Matrix4();
	const rectSize = new Vector2();
	const box = new Box2();
	const p = new Vector4();
	const p2 = new Vector2();
	const boxSize = new Vector2();

	return (node: ImageTreeNode, matrix: Matrix4, screenSize: Vector2): number => {
		const { rect } = node;

		const size = rect.getSize(rectSize);

		modelMatrix.multiplyMatrices(
			trasl.makeTranslation(0.5 * size.x + (rect.min.x - 0.5), -0.5 * size.y - (rect.min.y - 0.5), 0),
			scaleM.makeScale(size.x, size.y, 1),
		);
		pvmMatrix.multiplyMatrices(matrix, modelMatrix);

		box.makeEmpty();
		for (let i = 0; i < 2; ++i) {
			for (let j = 0; j < 2; ++j) {
				const x = i - 0.5;
				const y = j - 0.5;

				p.set(x, y, 0, 1).applyMatrix4(pvmMatrix);
				p.w = Math.abs(p.w);
				const invW = 1.0 / p.w;
				p.x = Math.max(Math.min(p.x * invW, 1), -1);
				p.y = Math.max(Math.min(p.y * invW, 1), -1);
				p2.set(p.x, p.y);
				box.expandByPoint(p2);
			}
		}

		box.getSize(boxSize);

		return Math.sqrt(boxSize.x * 0.5 * screenSize.x * boxSize.y * 0.5 * screenSize.y);
	};
});

/**
 * This class handles the default computation of which tiles from a LOD ImageTree are visible given a camera,
 * and screen resolution.
 *
 * It retrieves only the tiles that the camera frustum intersects and it orders them by their detail level
 * and distance from the camera view directions.
 * Tiles too small for the current zoom factor are discarded.
 */
export class DefaultVisibleTilesStrategy implements VisibleTilesStrategy {
	private pMaxTilesInGPU = Number.POSITIVE_INFINITY;

	private pMinPixelSize = DEFAULT_MIN_PIXEL_SIZE;

	private frustum = new Frustum();

	private priorityQueue = new LeanBinaryHeap<WeightedNode>((x: WeightedNode) => x.weight);

	private viewDirection = new Vector3();

	/**
	 * @returns The max number of tiles allowed to be loaded
	 */
	get maxTilesInGPU(): number {
		return this.pMaxTilesInGPU;
	}
	/**
	 * sets the max number of tiles allowed to be loaded
	 */
	set maxTilesInGPU(n: number) {
		this.pMaxTilesInGPU = n;
	}

	/**
	 * @returns The minimum number of pixels that should be occupied by a tile
	 */
	get minPixelSize(): number {
		return this.pMinPixelSize;
	}
	/**
	 * Sets the minimum number of pixels that should be occupied by a tile
	 */
	set minPixelSize(n: number) {
		this.pMinPixelSize = n;
	}

	/**
	 *Given a camera, this algorithm returns the list of nodes that are visible from the camera, sorted by node index.
	 *
	 * @param matrix The 3D transformation matrix of the tree
	 * @param tree The tree on which to perform the computation
	 * @param camera The camera from which the scene is being rendered.
	 * @param screenSize The current screen size, in pixels.
	 * @returns the list of indices of the visible nodes, sorted by screen occupancy from most prominent to least prominent.
	 */
	public compute(matrix: Matrix4, tree: ImageTree, camera: Camera, screenSize: Vector2): WeightedNode[] {
		assert(screenSize.lengthSq() > 0, "Invalid screen size");
		assert(camera instanceof PerspectiveCamera, "Invalid input camera");

		const pvmMatrix = computeMatrix(camera, matrix);
		this.frustum.setFromProjectionMatrix(pvmMatrix);
		camera.getWorldDirection(this.viewDirection);
		const fov = (camera.fov * Math.PI) / 180;
		const fovPercentage = screenSize.y / fov;

		const visibleTiles = new Array<WeightedNode>();
		this.priorityQueue.clear();

		const { rootNodes } = tree;
		for (const node of rootNodes) {
			const wn: WeightedNode = { id: node, weight: -1 };
			visibleTiles.push(wn);
			this.priorityQueue.push(wn);
		}

		// Breadth first iterate over the tree nodes adding their children up to #maxTilesInGPU
		while (this.priorityQueue.length > 0 && visibleTiles.length < this.maxTilesInGPU) {
			const n = this.priorityQueue.pop();

			const currNode = tree.getNode(n.id);

			// If this node has no children, nothing to do
			if (!currNode.children) continue;

			for (const child of currNode.children) {
				const { visible, metric } = isNodeVisible(child, this.viewDirection, this.frustum);
				if (!visible) {
					continue;
				}

				const pixelSize = panoTilePixelSize(child, fovPercentage);
				if (pixelSize < this.pMinPixelSize) {
					continue;
				}

				// Create element
				const tile = { id: child.id, weight: metric };

				// This child is visible, add it to the list
				visibleTiles.push(tile);

				// Add the child to the queue
				this.priorityQueue.push(tile);
			}
		}
		// Sort the visible tiles by increasing priority
		visibleTiles.sort((a: WeightedNode, b: WeightedNode): number => {
			return a.weight - b.weight;
		});
		return visibleTiles;
	}
}

/**
 * This class handles the default computation of which tiles from a LOD FloorPlan are visible given a camera,
 * and screen resolution.
 */
export class FloorPlanVisibleTilesStrategy implements VisibleTilesStrategy {
	private pMaxTilesInGPU = Number.POSITIVE_INFINITY;

	private pMinPixelSize = DEFAULT_FLOORPLAN_MIN_PIXEL_SIZE;

	private pVisibleLevel: number | undefined = undefined;

	private pvmMatrix = new Matrix4();

	private priorityQueue = new LeanBinaryHeap<WeightedNode>((x: WeightedNode) => -x.weight);

	/**
	 * @returns The max number of tiles allowed to be loaded
	 */
	get maxTilesInGPU(): number {
		return this.pMaxTilesInGPU;
	}
	/**
	 * sets the max number of tiles allowed to be loaded
	 */
	set maxTilesInGPU(n: number) {
		this.pMaxTilesInGPU = n;
	}

	/**
	 * @returns The minimum number of pixels that should be occupied by a tile
	 */
	get minPixelSize(): number {
		return this.pMinPixelSize;
	}
	/**
	 * Sets the minimum number of pixels that should be occupied by a tile
	 */
	set minPixelSize(n: number) {
		this.pMinPixelSize = n;
	}

	/** */
	set visibleLevel(n: number | undefined) {
		this.pVisibleLevel = n;
	}

	/**
	 *Given a camera, this algorithm returns the list of nodes that are visible from the camera, sorted by node index.
	 *
	 * @param matrix The 3D transformation matrix of the tree
	 * @param tree The tree on which to perform the computation
	 * @param camera The camera from which the scene is being rendered.
	 * @param screenSize The current screen size, in pixels.
	 * @returns the list of indices of the visible nodes, sorted by screen occupancy from most prominent to least prominent.
	 */
	public compute(matrix: Matrix4, tree: ImageTree, camera: Camera, screenSize: Vector2): WeightedNode[] {
		const visibleTiles = new Array<WeightedNode>();
		this.priorityQueue.clear();

		const { rootNodes } = tree;
		for (const node of rootNodes) {
			const wn: WeightedNode = { id: node, weight: -1 };
			visibleTiles.push(wn);
			this.priorityQueue.push(wn);
		}

		// When the minimap is collapsed, the camera projection matrix may contain some NaN
		// since camera.left and camera.right appear to be Infinity.
		if (!isMatrixValid(camera.projectionMatrix)) {
			return visibleTiles;
		}

		// compute the projection-view-matrix
		this.pvmMatrix.copy(camera.projectionMatrix).multiply(camera.matrixWorldInverse).multiply(matrix);

		// Breadth first iterate over the tree nodes adding their children up to #maxTilesInGPU
		while (this.priorityQueue.length > 0 && visibleTiles.length < this.maxTilesInGPU) {
			const n = this.priorityQueue.pop();

			const currNode = tree.getNode(n.id);

			// If this node has no children, nothing to do
			if (!currNode.children) continue;

			for (const child of currNode.children) {
				const pixelSize = floorPlanTileDistance(child, this.pvmMatrix, screenSize);

				if (pixelSize < this.pMinPixelSize) {
					continue;
				}

				// Create element
				const tile = { id: child.id, weight: pixelSize };

				// This child is visible, add it to the list
				if (this.pVisibleLevel === undefined || child.depth === this.pVisibleLevel) {
					visibleTiles.push(tile);
				}

				// Add the child to the queue
				this.priorityQueue.push(tile);
			}
		}

		visibleTiles.sort((a, b) => b.weight - a.weight);
		return visibleTiles;
	}
}

function isMatrixValid(m: Matrix4): boolean {
	return m.elements.every((v) => !isNaN(v));
}
