import { Vector2, Camera, DoubleSide } from "three";
import panoTransitionVert from "../Shaders/PanoBlend.vert";
import panoBlendZoomFrag from "../Shaders/PanoBlendZoom.frag";
import panoBlendFadeFrag from "../Shaders/PanoBlendFade.frag";
import { makeOptionalUniform } from "./Uniforms";
import { CubeMapsBlendMaterial } from "./CubeMapsBlendMaterial";
import { EnvironmentMap } from "../Utils";

export type PanoBlendType = "Zoom" | "Fade";

/**
 * A material to render two pano blended together
 * The blend is controlled by the blendFactor property
 * 0 will render only the starting pano
 * 1 will render only the target pano
 * intermediate values will render a blend of the two pano using the specified blending function
 */
export class PanoBlendMaterial extends CubeMapsBlendMaterial {
	extraUniforms = {
		uRectMin: makeOptionalUniform<Vector2>(),
		uRectMax: makeOptionalUniform<Vector2>(),
	};

	/**
	 * Construct the material initializing the shaders.
	 *
	 * @param source The 3D object associated to this material.
	 * @param target The image to visualize at the of the transition.
	 * @param camera The camera to render this transition
	 */
	constructor(source: EnvironmentMap, target: EnvironmentMap, camera: Camera) {
		super(source.map, target.map, camera);
		// Init to fade, enable zoom if setZoom is called
		this.vertexShader = panoTransitionVert;
		this.fragmentShader = panoBlendFadeFrag;
		this.side = DoubleSide;
		this.name = "PanoBlendMaterial";
	}

	/**
	 * Enable zoom blending
	 *
	 * @param min Min point for zoom rect
	 * @param max Max point for zoom rect
	 */
	setZoom(min: Vector2, max: Vector2): void {
		if (this.fragmentShader !== panoBlendZoomFrag) {
			this.fragmentShader = panoBlendZoomFrag;
			this.needsUpdate = true;
		}
		this.extraUniforms.uRectMin.value = min;
		this.extraUniforms.uRectMax.value = max;
		Object.assign(this.uniforms, this.extraUniforms);
		this.uniformsNeedUpdate = true;
	}
}
