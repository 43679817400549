import { TokenProvider } from "@faro-lotv/service-wires";
import { createContext, useContext } from "react";

/** Context providing authentication information */
export type AuthContext = {
  /** Requests the user to perform a login */
  requestLogin(): void;

  /** Logs out the user */
  logout(): void;

  /**
   * Function to get an authentication token. Available only inside the Authorization boundary
   *
   * @see HBEnsureProjectAccess for the HoloBuilder Authorization boundary
   */
  tokenProvider?: TokenProvider;

  /** Whether the current user is currently logged in */
  isLoggedIn: boolean;
};

export const AuthContext = createContext<AuthContext | undefined>(undefined);

/**
 * @returns the current AuthContext or error if not available
 */
export function useAuthContext(): AuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("useAuthContext() must be used within an AuthContext");
  }

  return context;
}
