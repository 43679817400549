import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementGroup,
  IElementMarkupPolygon,
  IElementType,
  IElementTypeHint,
  IPose,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import { DEFAULT_NODES_GROUP_SCALE_UNIFORM } from "../utils/mutation-constants";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddMarkupPolygon extends BaseMutation {
  /** The Group(Annotations) to create if the parent element is missing it */
  group?: CreateIElement<IElementGroup>;

  /** New MarkupPolygon */
  newElement: CreateIElement<IElementMarkupPolygon>;
}

export interface CreateMutationAddMarkupPolygon {
  /** Id to assign to the new MarkupPolygon element */
  newElementId: GUID;

  /** ID of the MarkupPolygon parent, Section(Area) or Section(FocusScan) */
  sectionId: GUID;

  /** ID of the Group(Nodes) that will contain the annotation if it exists, it will created if missing */
  groupId?: GUID;

  /** The type hint for this MarkupPolygon */
  typeHint: IElementTypeHint.mapAnnotation | IElementTypeHint.spaceAnnotation;

  /** Title of the Markup being created */
  name: string;

  /** ID of the root element */
  rootId: GUID;

  /** Local pose for this markup polygon*/
  pose?: IPose;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;
}

/**
 * Adds a new MarkupPolygon instance to an existing Section node.
 *
 *```
 * Section (Area/FocusScan)
 * └ Group (Annotation)
 *   └ MarkupPolygon (SpaceAnnotation/MapAnnotation)
 *
 * ```
 *
 * @returns A MutationAddMarkupPolygon object
 */
export function createMutationAddMarkupPolygon({
  newElementId,
  sectionId,
  groupId: maybeGroupId,
  typeHint,
  rootId,
  name,
  pose,
  createdAt = new Date().toISOString(),
}: CreateMutationAddMarkupPolygon): MutationAddMarkupPolygon {
  const elementId = maybeGroupId ?? sectionId;
  const groupId = maybeGroupId ?? generateGUID();

  const group: CreateIElement<IElementGroup> | undefined = maybeGroupId
    ? undefined
    : {
        type: IElementType.group,
        typeHint: IElementTypeHint.nodes,
        parentId: sectionId,
        id: groupId,
        rootId,
        name: "Annotations",
        childrenIds: [newElementId],
        xOr: false,
        createdAt,
        pose: {
          pos: null,
          rot: null,
          scale: {
            x: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
            y: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
            z: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          },
          isWorldRot: false,
          isWorldPose: false,
          isWorldScale: false,
          gps: null,
        },
      };

  const newElement: CreateIElement<IElementMarkupPolygon> = {
    type: IElementType.markupPolygon,
    typeHint,
    id: newElementId,
    rootId,
    parentId: groupId,
    childrenIds: [],
    points: [
      {
        state: null,
        x: 0,
        y: 0,
        z: 0,
      },
    ],
    pose,
    name,
    createdAt,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddMarkupPolygon, elementId),
    newElement,
    group,
  };
}
