precision highp float;

uniform sampler2D inputColorTex;
uniform sampler2D avgCountTex;
uniform sampler2D opaqueColorTex;
uniform sampler2D opaqueDepthTex;
uniform float opacity;
uniform float bias;
uniform vec3 tomoColor;
uniform float deviationFromAverage;   // Point count values higher than this are edges for sure and should not contribute to the average.
uniform float outlierRejectionFactor; // how to compute the outlier rejection threshold

in vec2 vUv;

out vec4 outColor;

// All the constants below have been found by trial and error in order
// to get a good adaptive visualization of the edges of a point cloud,
// keeping good performance also on Intel GPU
const float SEARCH_RADIUS_PIX = 7.0;      // How many pixels to look around the current pixel
const int NUM_OF_SAMPLES = 29;            // Number of samples to use - herustic, resulted from quality performance trials
const int NUM_OF_SPIRAL_TURNS = 11;       // Number of turns on spiral - herustic, resulted from quality performance trials
const float MIN_OUTPUT = 0.05;
const float EDGES_STRENGTH = 20.0;        // How fast the edge becomes full blue after surpassing the threshold

const float M_PI = 3.14159265;            //PI constant
const float INV_NUM_OF_SAMPLES = 1.0 / float(NUM_OF_SAMPLES);

// Pseudorandom function
const vec3 RANDOM_SCALE = vec3(12.9898, 78.233, 151.7182);
float random(float seed) {
    return fract(sin(dot(gl_FragCoord.xyz + seed, RANDOM_SCALE)) * 43758.5453 + seed);
}

float computePointCountThreshold() {
    vec2 sz = vec2(textureSize(inputColorTex, 0));
    vec2 pixSz = 1.0 / sz;
    float avgCount = 0.0;

    // compute average point count in the neighbourhood of the current pixel
    float initialAngle = 2.0 * M_PI * random(0.0);
    float samples[NUM_OF_SAMPLES] = float[NUM_OF_SAMPLES](
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 0.0);
    for (int sampleNumber = 0; sampleNumber < NUM_OF_SAMPLES; sampleNumber++) {
        // Sample the pixels surrounding the current one in a spiral pattern
        float sampleProgress = (float(sampleNumber) + 0.5) * INV_NUM_OF_SAMPLES;
        float angle = sampleProgress * (float(NUM_OF_SPIRAL_TURNS) * 2.0 * M_PI) + initialAngle;

        float sampleDistance = sampleProgress * SEARCH_RADIUS_PIX + 1.0;
        vec2 angleUnitVector = vec2(cos(angle), sin(angle));
        vec2 samplePix = angleUnitVector * sampleDistance * pixSz;
        // We are only interested in the .r component, because the point cloud
        // is blended with white, therefore the three RGB components will be equal.
        float value = texture(inputColorTex, vUv + samplePix).r;
        avgCount += value;
        samples[sampleNumber] = value;
    }
    avgCount *= INV_NUM_OF_SAMPLES;

    // compute standard deviation of the samples
    float stddev = 0.0;
    for (int sampleNumber = 0; sampleNumber < NUM_OF_SAMPLES; sampleNumber++) {
        float value = samples[sampleNumber];
        float d = value - avgCount;
        d *= d;
        stddev += d;
    }
    stddev *= INV_NUM_OF_SAMPLES;
    stddev = sqrt(stddev);

    // Recompute the average, filtering out the outliers
    float outlierThreshold = avgCount + outlierRejectionFactor * stddev;
    avgCount = 0.0;
    float counter = 0.0;
    for (int sampleNumber = 0; sampleNumber < NUM_OF_SAMPLES; sampleNumber++) {
        float value = samples[sampleNumber];
        if(value < outlierThreshold) {
            avgCount += value;
            counter++;
        }
    }

    if(counter == 0.0) {
        return 0.0;
    }

    avgCount /= counter;
    // Add a fraction of the standard deviation to the average,
    // since we want to detect points that are above the average
    avgCount += deviationFromAverage * stddev;
    // Set a minimum output to the average, to avoid dividing by zero later on
    avgCount = max(MIN_OUTPUT, avgCount);
    return avgCount;
}

// The idea of this shader is to take as input (inputColorTex) the point cloud rendered
// with additive blending. Walls and relevant edges will result in many points summing up
// in a single pixels. Floors and nonrelevant features will result in few points or zero.
// To detect whether the current pixels is an edge or not, we look around the current pixel
// along a spiral with a randomized starting angle. In this way we can look at a 16x16 neighbourhood
// spending only NUM_OF_SAMPLES texel lookups. We compute the average point count of the samples
// in the neighbourhood. If the current point count is significantly higher than the average, then the
// current pixel belongs to an edge.
// This appoach allows to not overdo strong edges and to still show something for small edges, accomodating
// for a great variety of datasets and for very diverse features within the same dataset.
void main() {
    gl_FragDepth = texture(opaqueDepthTex, vUv).r;
    vec4 background = texture(opaqueColorTex, vUv);

    float pointCount = texture(inputColorTex, vUv).r;
    float avgCount = computePointCountThreshold();
    if(avgCount == 0.0) {
        outColor = background;
        return;
    }
    // In the equation below, subtracting by the bias is crucial
    // to remove unpleasant moire effects all over the point cloud.
    float e = (pointCount - avgCount) / avgCount - bias;
    if(e < 0.0) {
        outColor = background;
    }
    else {
        float bgAlpha = pow((1.0 - opacity), EDGES_STRENGTH * e);
        outColor = vec4(tomoColor * (1.0 - bgAlpha) + bgAlpha * background.rgb, 1.0);
    }
}


