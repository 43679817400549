import { GLSL3, RawShaderMaterial, Texture, Vector2 } from "three";
import { makeUniform } from "../Materials/Uniforms";
import frag from "../Shaders/MipGapFill.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { CameraMode } from "../Utils/CameraUtils";

/**
 * This enum provides a parameter to optimize the gap filling
 * shader in different ways. It is possible to have a faster shader
 * by searching in a narrower neighbourhood of each pixel (ideal for Intel
 * integrated GPUs on laptops). It is also possible to have a shader that
 * closes bigger gaps, at the expense of some speed (advised on Nvidia GPUs
 * and iPads.)
 */
export enum GapFillOptimizedFor {
	Speed = 0,
	GapSize = 1,
}

/**
 * A shader that performs gap filling using 4 mipmaps of the current FBO, and
 * covers both perspective and orthographic projections. Can be optimized for
 * speed for for bigger gap size.
 */
export class MipGapFillMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;

	#cameraMode: CameraMode;
	#optimizedFor: GapFillOptimizedFor;

	/**
	 * This shader reads as input textures all downsampled versions of the input FBO: 2z2, 4z4, 8z8, and 16x16.
	 * However, the depth and color texture of the 8x8 FBO and the color texture of the 16X16 FBO are not used.
	 * This may change in the future when the shader could be optimized for high-DPI displays and therefore
	 * it could be needed to use the 8x8 pixels to search in a broader neighbourhood of the current pixel.
	 */
	override uniforms = {
		// Depth textures of the input FBO at different downsample levels.
		depthTex1: makeUniform<Texture | null>(null),
		depthTex2: makeUniform<Texture | null>(null),
		depthTex4: makeUniform<Texture | null>(null),
		depthTex16: makeUniform<Texture | null>(null),

		// Color textures of the input FBO at different downsample levels.
		colorTex1: makeUniform<Texture | null>(null),
		colorTex2: makeUniform<Texture | null>(null),
		colorTex4: makeUniform<Texture | null>(null),

		// Resolution of the input FBO
		texSize1: makeUniform(new Vector2()),

		// Used only in perspective projection
		depthToFrustumHeight: makeUniform<number>(1),

		// Two members below are used only in orthographic projection
		depthLevel: makeUniform<number>(0),
		finalDepthBias: makeUniform<number>(0),

		// near and far plane of the camera that is rendering the scene.
		nearPlane: makeUniform<number>(0),
		farPlane: makeUniform<number>(0),

		// Boolean to show or not the points to be gapfilled with debug colors.
		debugColors: makeUniform(false),
	};

	/**
	 * Constructs an instance of the mip gap fill material.
	 *
	 * @param cm The camera projection mode: perspective or orthographic.
	 * @param o How the shader should be optimized
	 */
	constructor(cm: CameraMode, o: GapFillOptimizedFor) {
		super({ glslVersion: GLSL3 });
		this.#cameraMode = cm;
		this.#optimizedFor = o;
		const proj = cm === CameraMode.Orthographic ? "#define ORTHO_PROJECTION\n" : "";
		const opt = o === GapFillOptimizedFor.GapSize ? "#define OPTIMIZED_FOR_GAP_SIZE\n" : "";
		this.fragmentShader = `${proj}${opt}${frag}`;
	}

	/** @returns the projection for which this shader is built: perspective or orthographic. */
	get cameraMode(): CameraMode {
		return this.#cameraMode;
	}

	/** @returns the optimization profile this shader was built with. */
	get optimizedFor(): GapFillOptimizedFor {
		return this.#optimizedFor;
	}
}
