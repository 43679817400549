import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementGenericPointCloud,
  IElementSection,
  IElementTimeSeries,
  IElementType,
  IElementTypeHint,
  IPose,
  IRefCoordSystemTransform,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * This mutation adds a new PointCloud to a project. The final IElement structure is:
 *
 * ```
 * Section (Floor)
 * └ TimeSeries (DataSession)
 *   └ Section (DataSession)
 *     └ Section (DataSetPCloudUpload)
 *       └ PointCloud
 * ```
 *
 * Note that point cloud streams are not stored in the session section.
 *
 * Depending on whether the Timeseries already exists, the mutation's elementId is either the Section(typeHint:Floor)
 * or the existing Timeseries.
 */
export interface MutationAddPointCloud extends BaseMutation {
  /** The type of the mutation to apply */
  type: MutationTypes.MutationAddPointCloud;

  /**
   * A new parent/wrapper section to the point cloud
   * Contains point cloud streams directly and can contain multiple sessions
   * for point clouds files.
   */
  section: CreateIElement<IElementSection>;

  /**
   * A new parent/wrapper section for the point cloud files.
   * This is a child of the `section` element.
   */
  session: CreateIElement<IElementSection>;

  /**
   * A new Timeseries for the laser scans of the sheets.
   * Provide only if no timeseries for laser scans exists yet.
   */
  timeSeries?: CreateIElement<IElementTimeSeries>;

  /** New point cloud element */
  newElement: CreateIElement<IElementGenericPointCloud>;
}

/**
 * All the data required to create a new IElementGenericPointCloud
 */
export type CreateMutationAddPointCloudArguments = {
  /** Id of the floor that will contain the new PointCloud */
  floorId: GUID;

  /** Id of the root of the project */
  rootId: GUID;

  /** Id of an already existing TimeSeries to use for this PointCloud, if undefined a new TimeSeries will be created */
  timeseriesId?: GUID;

  /** Type of the new PointCloud */
  type: IElementGenericPointCloud["type"];

  /** Name for the new PointCloud node */
  name: string;

  /** Name of the file */
  fileName: string;

  /** file md5Hash encoded in base 16 (hexadecimal). */
  md5Hash: string;

  /** Size of the file, in bytes. */
  fileSize: number;

  /** Url to the new PointCloud file */
  uri: string;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default today
   */
  createdAt?: string;

  /** Custom pose for this Point Cloud */
  pose?: IPose;

  /** The Reference coord system matrix of the point cloud data */
  refCoordSystemMatrix?: IRefCoordSystemTransform | null;

  /** Custom metadata for this Point Cloud */
  metaDataMap?: Record<string, unknown>;

  /** True if the user marked this cloud as geo-referenced during import. */
  isGeoReferenced: boolean;
};

/**
 * The mutation creates a structure like this:
 *
 * ```
 * TimeSeries (LaserScans)
 * └ Section (LaserScans)
 *   └ Section (DataSetPCloudUpload)
 *     └ PointCloud
 * ```
 *
 * @returns a mutation to add a new PointCloud to a project
 */
export function createMutationAddPointCloud({
  floorId,
  rootId,
  timeseriesId,
  type,
  name,
  md5Hash,
  fileSize,
  fileName,
  uri,
  pose,
  refCoordSystemMatrix,
  metaDataMap,
  createdAt = new Date().toISOString(),
  isGeoReferenced,
}: CreateMutationAddPointCloudArguments): MutationAddPointCloud {
  const elementId = timeseriesId ?? floorId;
  const sectionId = generateGUID();
  const sessionId = generateGUID();
  const targetTimeseriesId = timeseriesId ?? generateGUID();
  const newElementId = generateGUID();

  // GeoslamDataSets requires the Z to Y adjustment pose to be applied to the element directly
  // and not to the entire data set
  const dataSetType = dataSetTypeByFileType(type);
  const sessionPose =
    dataSetType === IElementTypeHint.dataSetGeoSlam ? null : pose;
  const elementPose =
    dataSetType === IElementTypeHint.dataSetGeoSlam ? pose : null;

  // Create a new timeseries if it's missing
  const timeSeries: CreateIElement<IElementTimeSeries> | undefined =
    timeseriesId
      ? undefined
      : {
          type: IElementType.timeSeries,
          typeHint: IElementTypeHint.dataSession,
          id: targetTimeseriesId,
          rootId,
          parentId: floorId,
          childrenIds: [sectionId],
          name: "Laser Scans",
          createdAt,
        };

  return {
    ...createBaseMutation(MutationTypes.MutationAddPointCloud, elementId),
    timeSeries,
    section: {
      name,
      type: IElementType.section,
      typeHint: IElementTypeHint.dataSession,
      id: sectionId,
      rootId,
      parentId: targetTimeseriesId,
      childrenIds: [sessionId],
      createdAt,
      pose: {
        pos: null,
        rot: null,
        scale: { x: 1, y: 1, z: 1 },
        isWorldRot: isGeoReferenced,
        isWorldScale: true,
        gps: null,
        isWorldPose: isGeoReferenced,
      },
    },
    session: {
      name: `${name} session 1`,
      type: IElementType.section,
      typeHint: dataSetTypeByFileType(type),
      id: sessionId,
      rootId,
      parentId: sectionId,
      childrenIds: [newElementId],
      createdAt,
      pose: sessionPose,
    },
    newElement: {
      type,
      name,
      uri,
      md5Hash,
      fileSize,
      fileName,
      id: newElementId,
      rootId,
      parentId: sessionId,
      childrenIds: null,
      createdAt,
      metaDataMap,
      refCoordSystemMatrix,
      pose: elementPose
        ? { ...elementPose }
        : {
            isWorldRot: false,
            gps: null,
            pos: null,
            rot: null,
            scale: null,
          },
    },
  };
}

/**
 * @param type of the point cloud file uploaded
 * @returns the proper DataSetTypeHint to use for the newly created Section
 */
function dataSetTypeByFileType(
  type: IElementGenericPointCloud["type"],
): IElementTypeHint {
  if (type === IElementType.pointCloudGeoSlam) {
    return IElementTypeHint.dataSetGeoSlam;
  }
  return IElementTypeHint.dataSetPCloudUpload;
}
