import { MAX_NAME_LENGTH } from "@faro-lotv/ielement-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { TextField } from "../../input/text-field";

export type AnnotationEditorHeaderProps = {
  /** Current value of the title text field */
  title?: string;

  /**
   * If true the title input field will be focused and its text will be selected, when the form is first shown.
   *
   * @default false
   */
  autoFocusTitle?: boolean;

  /** Function called when the content of the title text field changes */
  onTitleChange?(title: string): void;

  /** if `true` the component will be disabled */
  disabled: boolean;
};

/**
 * @returns the UI control for getting the title of an annotation
 */
export function AnnotationEditorHeader({
  // The default value is set to avoid warning logs that appear when going from undefined to a string
  // The assigned value it's a specific value used by the Dropdown Api
  title = "",
  autoFocusTitle,
  onTitleChange,
  disabled,
}: AnnotationEditorHeaderProps): JSX.Element {
  const [isFirstFocus, setIsFirstFocus] = useState(true);

  const clampText = useCallback(
    (text: string) => onTitleChange?.(text.slice(0, MAX_NAME_LENGTH)),
    [onTitleChange],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  // Focus the title input field when the form is first shown
  useEffect(() => {
    if (!inputRef.current || !autoFocusTitle) return;

    inputRef.current.focus();
  }, [autoFocusTitle]);

  return (
    <TextField
      inputRef={inputRef}
      disabled={disabled}
      text={title}
      fullWidth
      variant="underlined"
      dark
      placeholder="Insert a title"
      sx={{
        height: "fit-content",
        minHeight: "32px",
        input: { fontSize: "1rem" },
      }}
      onTextChanged={clampText}
      maxCharacterCount={MAX_NAME_LENGTH}
      shouldShowCounter
      multiline
      inputProps={{ style: { fontSize: "1rem" } }}
      helpText={
        title.length === MAX_NAME_LENGTH ? "Maximum limit reached" : undefined
      }
      onFocus={(e) => {
        // Select the text on the first focus
        if (isFirstFocus && autoFocusTitle) {
          e.target.select();
          setIsFirstFocus(false);
        }
      }}
    />
  );
}
