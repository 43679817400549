import { assert } from "@faro-lotv/foundation";
import { useSnackbar } from "notistack";
import { createContext, useContext } from "react";

export type { SnackbarKey } from "notistack";

type ToastContext = ReturnType<typeof useSnackbar>;

/** A context to be able to expose notistack functions inside R3F using a ContextBridge */
export const ToastContext = createContext<ToastContext | undefined>(undefined);

/** @returns the current bound ToastContext */
export function useToastContext(): ToastContext {
  const ctx = useContext(ToastContext);
  assert(ctx, "useToastContext should be used only inside a ToastContext");
  return ctx;
}
