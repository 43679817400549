import { SnackbarProvider, useSnackbar } from "notistack";
import { PropsWithChildren } from "react";
import { ToastContext } from "./toast-context";

/**
 * @returns Necessary context to allow usage of the useToast hook
 */
export function ToastProvider({
  children,
}: PropsWithChildren<unknown>): JSX.Element {
  return (
    <SnackbarProvider>
      <NotistackContextBridge>{children}</NotistackContextBridge>
    </SnackbarProvider>
  );
}

/** @returns a component that will bridge the notistack hidden context in our own ToastContext */
function NotistackContextBridge({ children }: PropsWithChildren): JSX.Element {
  const snackbarContext = useSnackbar();

  return (
    <ToastContext.Provider value={snackbarContext}>
      {children}
    </ToastContext.Provider>
  );
}
