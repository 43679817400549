import { Box, Link, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import {
  useBreakpointMdUp,
  useBreakpointSmUp,
  useBreakpointXlUp,
  useBreakpointXsUp,
} from "../../hooks/use-breakpoints";
import { FaroButton } from "../button/faro-button";
import { FontWeights } from "../faro-theme";
import { SphereXGTextIcon } from "../icons/icons";
import { FaroText, FaroTextVariant } from "../text/faro-text/faro-text";
import { ReactComponent as ErrorPageBackground } from "./error-page-background.svg";
import { ErrorAction } from "./error-types";

export interface ErrorDetailsPageProps {
  /** The statement introducing the error to the user */
  errorStatement: ReactNode;

  /**
   * A message guiding the user to solve the problem.
   * It's preferred to keep this under 80 characters to make sure it fits in
   * less than three lines on the default screen size
   */
  helpText: ReactNode;

  /** The technical error name */
  errorName: ReactNode;

  /** The technical error message causing the error */
  errorMessage: ReactNode;

  /** A list of possible actions (links) the user can visit to help solve the error */
  actions: ErrorAction[];

  /** Special action with a long label to show as a link below the other actions */
  specialAction?: ErrorAction;

  /** Whether to include the logo */
  showLogo?: boolean;
}

/**
 * @returns a responsive page, that informs the user about a major error and provides guidance and actions towards resolving it.
 */
export function ErrorDetailsPage({
  errorName,
  errorMessage,
  errorStatement,
  helpText,
  actions,
  specialAction,
  showLogo = true,
}: ErrorDetailsPageProps): JSX.Element {
  const isMd = useBreakpointMdUp();

  return (
    <>
      {showLogo && (
        <SphereXGTextIcon
          sx={{
            width: "125px",
            height: "64px",
            position: "absolute",
            top: 16,
            left: 0,

            // Making sure the logo is not rendered left from the text (on the vertical axis)
            ml: "21px",
          }}
        />
      )}

      <Stack
        direction="row"
        width="100%"
        minHeight="100%"
        justifyContent="center"
        spacing={{
          xs: 0,
          md: 3.5,
          lg: 4,
          xl: 6,
        }}
      >
        <Stack
          direction="column"
          width={{ md: "55%" }}
          justifyContent="center"
          spacing={2}
          textAlign={{ xs: "center", md: "left" }}
          py={3}
          px={{
            xs: 2.5,
            md: 3,
            lg: 7.75,
            xl: 15,
          }}
        >
          <Stack
            // Grows to balance the bottom stack item
            flexGrow={isMd ? 0 : 1}
            minHeight={showLogo ? "64px" : 0}
            pb={{ xs: 12 }}
          />
          <ErrorInfo errorStatement={errorStatement} helpText={helpText} />
          <Actions actions={actions} />
          <SpecialAction action={specialAction} />
          <Stack
            // Grows to position the ErrorReasonInfor at flex end
            flexGrow={isMd ? 0 : 1}
          >
            <ErrorReasonInfo
              errorName={errorName}
              errorMessage={errorMessage}
            />
          </Stack>
        </Stack>

        {isMd ? (
          <Box component="div" flexGrow={1}>
            <Background />
          </Box>
        ) : (
          <Box
            component="div"
            // absolute removes it from the flex layout, moving it behind the elements
            position="absolute"
            zIndex={-1}
            width="100%"
            height="100%"
          >
            <Background />
          </Box>
        )}
      </Stack>
    </>
  );
}

function Background(): JSX.Element {
  const isMd = useBreakpointMdUp();

  return (
    <Box
      component="div"
      width="100%"
      height="100%"
      // The new relative root makes it possible to hide the overflow of the absolute background element
      position="relative"
      overflow="hidden"
    >
      <Box
        component={ErrorPageBackground}
        position="absolute"
        height="100%"
        width={isMd ? "auto" : "100%"}
        color="gray100"
        left={{ xs: "50%", md: 0 }}
        sx={{
          opacity: 0.3,
          transform: { xs: "translateX(-50%)", md: "none" },
        }}
      />
    </Box>
  );
}

/**
 * @returns the main error information for the user properly formatted
 */
function ErrorInfo({
  errorStatement,
  helpText,
}: Pick<ErrorDetailsPageProps, "errorStatement" | "helpText">): JSX.Element {
  const isXL = useBreakpointXlUp();
  const isXS = useBreakpointXsUp();

  let errorStatementVariant = FaroTextVariant.heading24;
  if (isXL) {
    errorStatementVariant = FaroTextVariant.heading50;
  } else if (isXS) {
    errorStatementVariant = FaroTextVariant.heading20;
  }

  return (
    <Stack direction="column">
      <FaroText variant={errorStatementVariant}>{errorStatement}</FaroText>
      <FaroText variant="heading16" sx={{ color: "gray600", mt: 2.5 }}>
        {helpText}
      </FaroText>
    </Stack>
  );
}

type ActionsProps = {
  /** List of actions (links) the user can do to solve the current error */
  actions: ErrorAction[];
};

/**
 * @returns a row of buttons one for each action the user can take to solve the error
 */
function Actions({ actions }: ActionsProps): JSX.Element | null {
  const isSm = useBreakpointSmUp();

  if (actions.length === 0) {
    return null;
  }
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={isSm ? 4 : 3}
      justifyContent={{ xs: "center", md: "flex-start" }}
      alignItems="center"
      pt={2}
    >
      {actions.map((action, index) => (
        <FaroButton
          key={index}
          variant={action.primary ? "primary" : "secondary"}
          size="l"
          href={
            action.action instanceof URL ? action.action.toString() : undefined
          }
          onClick={
            typeof action.action === "function" ? action.action : undefined
          }
        >
          {action.label}
        </FaroButton>
      ))}
    </Stack>
  );
}

type SpecialActionProps = {
  /** The special action to show as a link below the buttons */
  action?: ErrorAction;
};

/** @returns the error special action formatted as a link */
function SpecialAction({ action }: SpecialActionProps): JSX.Element | null {
  if (!action) {
    return null;
  }

  return (
    <Link
      href={action.action instanceof URL ? action.action.toString() : undefined}
      onClick={typeof action.action === "function" ? action.action : undefined}
      target="_blank"
      color="blue500"
      sx={{ mt: 2 }}
    >
      <Typography fontWeight="600" fontSize="1.25rem">
        {action.label}
      </Typography>
    </Link>
  );
}

/** @returns a small information box with the original error data */
function ErrorReasonInfo({
  errorName,
  errorMessage,
}: Pick<ErrorDetailsPageProps, "errorName" | "errorMessage">): JSX.Element {
  const isMd = useBreakpointMdUp();
  const isXl = useBreakpointXlUp();

  let textVariant = FaroTextVariant.labelM;
  if (isXl) {
    textVariant = FaroTextVariant.heading16;
  } else if (isMd) {
    textVariant = FaroTextVariant.labelL;
  }

  return (
    <Stack
      direction="column"
      height="100%"
      pt={{ xs: 0, md: 6 }}
      pb={{ xs: 12 }}
      gap={1}
      justifyContent="flex-end"
    >
      <FaroText
        variant={textVariant}
        sx={{
          color: "gray600",
          fontSize: { m: "1.125rem" },
          fontWeight: FontWeights.SemiBold,
        }}
      >
        {errorName}
      </FaroText>
      <FaroText
        variant={textVariant}
        sx={{
          color: "gray600",
          fontSize: { m: "1.125rem" },
          fontWeight: FontWeights.Regular,
        }}
      >
        {errorMessage}
      </FaroText>
    </Stack>
  );
}
