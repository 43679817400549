import { Matrix4 } from "three";
import { LodTree } from "./LodTree";
import { OptimizationHint, WeightedNode } from "./VisibleNodeStrategy";

const MAX_TARGET_PIXELS_PER_POINT = 50;
export const INFINITE_SIZE_ON_SCREEN = 1000 * 1000 * 1000;

/**
 * A small object useful to sort visible nodes by their screen occupancy.
 */
export class SizedNode {
	id: number;
	sizeOnScreen: number;
	pixelsPerPoint: number;

	/**
	 * Constructs a record with a node ID and its computed screen size and pixels per point.
	 *
	 * @param i The node id
	 * @param s The estimated size on screen
	 * @param p The computed pixels per point
	 */
	constructor(i?: number, s?: number, p?: number) {
		this.id = i ?? 0;
		this.sizeOnScreen = s ?? INFINITE_SIZE_ON_SCREEN;
		this.pixelsPerPoint = p ?? 0;
	}
}

/**
 * This abstract class encapsulates common code for all visible nodes strategies.
 */
export abstract class AbstractVisibleNodesStrategy {
	optimizationHint = OptimizationHint.cameraChanging;
	protected pTargetPixelsPerPoint = 2;
	protected pMaxPointsInGPU = 10 * 1000 * 1000;
	protected pCloudScale = 1;

	/**
	 * Copies the props of rhs to this object
	 *
	 * @param rhs right hand side of assignment
	 */
	protected copy(rhs: AbstractVisibleNodesStrategy): void {
		// The optimization hint is not copied because it depends on the rendering pipeline's state
		this.optimizationHint = OptimizationHint.cameraChanging;
		this.pTargetPixelsPerPoint = rhs.pTargetPixelsPerPoint;
		this.pMaxPointsInGPU = rhs.pMaxPointsInGPU;
		this.pCloudScale = rhs.pCloudScale;
	}

	/**
	 * Extracting the scale along X from the cloud world matrix.
	 *
	 * @param m The cloud world matrix.
	 */
	protected extractCloudScale(m: Matrix4): void {
		// In the world matrix, the scale along X|Y|Z is just the length of
		// the X|Y|Z rotation vector of the matrix. So we compute the length
		// of the X rotation vector and we assume that the scaling is uniform
		// so is the same also for the two other vectors.
		// In threejs, m.elements is sorted column-major.
		const x = m.elements[0];
		const y = m.elements[1];
		const z = m.elements[2];
		this.pCloudScale = Math.sqrt(x * x + y * y + z * z);
	}

	/**
	 *
	 * @param visibleNodes The unsorted list of visible nodes
	 * @param tree The LodTree
	 * @returns The number of points in the visible nodes
	 */
	protected trimVisibleNodes(visibleNodes: SizedNode[], tree: LodTree): number {
		visibleNodes.sort((a: SizedNode, b: SizedNode): number => {
			return b.sizeOnScreen - a.sizeOnScreen;
		});
		let nPoints = 0;
		let i = 0;
		while (i < visibleNodes.length && nPoints < this.pMaxPointsInGPU) {
			nPoints += tree.getNode(visibleNodes[i].id).numPoints;
			i++;
		}
		// this should resize down the array.
		visibleNodes.length = i;
		return nPoints;
	}

	/**
	 * Trims a given list of nodes so that the total points in the nodes are not more than _maxPointsInGPU.
	 * Retains the nodes that occupy most space on screen
	 *
	 * @param visibleNodes The uncapped list of visible nodes, with their size on screen in pixels.
	 * @param tree The lod tree.
	 * @returns The sorted list of capped visible nodes.
	 */
	protected capVisibleNodesByNumPoints(visibleNodes: SizedNode[], tree: LodTree): WeightedNode[] {
		this.trimVisibleNodes(visibleNodes, tree);
		return visibleNodes.map((value: SizedNode) => {
			return { id: value.id, weight: value.sizeOnScreen };
		});
	}

	/** @returns The maximum amount of points to be loaded in GPU. */
	get maxPointsInGpu(): number {
		return this.pMaxPointsInGPU;
	}

	/**
	 * @param n The new value of max points in GPU.
	 */
	set maxPointsInGpu(n: number) {
		if (n < 10 * 1000) {
			console.log("Error: max points in GPU parameter cannot be lower than 10000.");
		} else {
			this.pMaxPointsInGPU = n;
		}
	}

	/** @returns the target point density on screen, used to determine how many nodes to render from the LOD tree. */
	get targetPixelsPerPoint(): number {
		return this.pTargetPixelsPerPoint;
	}

	/**
	 * @param n the target point density on screen, used to determine how many nodes to render from the LOD tree.
	 * Should lie in the interval [0.05, 50]
	 */
	set targetPixelsPerPoint(n: number) {
		if (n < 0 || n > MAX_TARGET_PIXELS_PER_POINT) {
			console.log("Error: pixels per point parameter should be greater than 0 and smaller than 50");
		} else {
			this.pTargetPixelsPerPoint = n;
		}
	}
}
