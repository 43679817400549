precision highp float;

#ifdef ORTHO_PROJECTION

float depthToViewZ( const in float linearClipZ, const in float near, const in float far ) {
	return linearClipZ * ( near - far ) - near;
}

#else

float depthToViewZ( const in float invClipZ, const in float near, const in float far ) {
	return ( near * far ) / ( ( far - near ) * invClipZ - far );
}

#endif

out vec4 outColor;

uniform sampler2D uColorTex0;    // color texture
uniform sampler2D uDepthTex0; 	// depth texture
uniform sampler2D uColorTex1;    // color texture
uniform sampler2D uDepthTex1; 	// depth texture

uniform float uNearPlane;
uniform float uFarPlane;

uniform float uMinOpacity;
uniform float opacity1;

#ifndef SINGLE_FBO

uniform sampler2D uColorTex2;    // color texture
uniform sampler2D uDepthTex2; 	// depth texture
uniform float opacity2;

uniform float uDepthOffset;
uniform bool uFalseColors;
uniform bool uThermoCompare;
uniform float uThermoThreshold;

#endif 

uniform bool uOverrideBackground;
uniform vec4 uBackground;

in vec2 vUv;

#ifndef SINGLE_FBO

#include <lotv_color_conversions>
const float greenmult = 1.0 / 3.0;

#endif

void main()
{
    float depth0 = uOverrideBackground ? 1.0 : texture(uDepthTex0, vUv).s;
    float camDepth0 = depthToViewZ(depth0, uNearPlane, uFarPlane);
    vec4 color0 = uOverrideBackground ? uBackground : texture(uColorTex0, vUv);

    float depth1 = opacity1 > uMinOpacity ? texture(uDepthTex1, vUv).s : 1.0 ;
    float camDepth1 = depthToViewZ(depth1, uNearPlane, uFarPlane);
    vec4 color1 = opacity1 > uMinOpacity ? texture(uColorTex1, vUv) : vec4(0.0);

#ifdef SINGLE_FBO
    color1.a *= opacity1;
    if(camDepth1 >= camDepth0) {
        // blending color1 on top of color0
        float oneMinusAlpha = 1.0 - color1.a;
        outColor = vec4(color1.rgb * color1.a + color0.rgb * oneMinusAlpha, color1.a + oneMinusAlpha * color0.a);
        gl_FragDepth = depth1;
    } else {
        // blending color0 on top of color1
        float oneMinusAlpha = 1.0 - color0.a;
        outColor = vec4(color0.rgb * color0.a + color1.rgb * oneMinusAlpha, color0.a + oneMinusAlpha * color1.a);
        gl_FragDepth = depth0;

    }
#else

    float depth2 = opacity2 > uMinOpacity ? texture(uDepthTex2, vUv).s : 1.0;
    float camDepth2 = depthToViewZ(depth2, uNearPlane, uFarPlane);
    vec4 color2 = opacity2 > uMinOpacity ? texture(uColorTex2, vUv) : vec4(0.0);

    if( (uThermoCompare || uFalseColors) && depth1 == 1.0 && depth2 == 1.0) {
        // blending color1 on top of the background
        float oneMinusAlpha = 1.0 - color1.a;
        outColor = vec4(color1.a * color1.rgb + color0.rgb * oneMinusAlpha, color1.a + oneMinusAlpha * color0.a);
        // blending color2 on top of the result
        oneMinusAlpha = 1.0 - color2.a;
        outColor = vec4(color2.a * color2.rgb + outColor.rgb * oneMinusAlpha, color2.a + oneMinusAlpha * outColor.a);
        gl_FragDepth = depth1;
        return;
    }

    if(uThermoCompare) {
        gl_FragDepth = min(depth1, depth2);
        float dist = (camDepth1 - camDepth2 + uDepthOffset) / uThermoThreshold;
        float l1 = dot(color1.rgb, extractLuma);
        if(dist > 1.0 || dist < -1.0) {
            outColor = color1;
        }
        else {
            outColor = vec4(hsl2rgb(vec3(greenmult - dist * greenmult, 1.0, 0.5 * l1)), 1.0);
        }
    }
    else if(uFalseColors)
    {
        float l1 = dot(color1.rgb, extractLuma);
        float l2 = dot(color2.rgb, extractLuma);
        vec3 fc1 = vec3(l1, 0, 0);
        vec3 fc2 = vec3(0, l2, 0);
        if(camDepth1 > camDepth2 + uDepthOffset)
        {
            outColor = vec4(0.7 * fc1 + 0.3 * fc2, 1.0);
            gl_FragDepth = depth1;
        }
        else
        {
            outColor = vec4(0.3 * fc1 + 0.7 * fc2, 1.0);
            gl_FragDepth = depth2;
        }
    }
    else
    {
        // Performing blending inside this shader. According of whether
        // color1 is in front of color2 or the other way around, the correct
        // blending equation is applied, accounting also for the background color.
        color1.a *= opacity1;
        color2.a *= opacity2;

        camDepth2 = camDepth2 + uDepthOffset;

        vec4 blendLayer1;
        vec4 blendLayer2;

        if (camDepth0 >= camDepth1 && camDepth1 >= camDepth2) 
        {
            outColor = color2;
            blendLayer1 = color1;
            blendLayer2 = color0;
            gl_FragDepth = depth0;
        } 
        else if (camDepth0 >= camDepth2 && camDepth2 >= camDepth1) 
        {
            outColor = color1;
            blendLayer1 = color2;
            blendLayer2 = color0;
            gl_FragDepth = depth0;
        } 
        else if (camDepth1 >= camDepth0 && camDepth0 >= camDepth2) 
        {
            outColor = color2;
            blendLayer1 = color0;
            blendLayer2 = color1;
            gl_FragDepth = depth1;
        } 
        else if (camDepth1 >= camDepth2 && camDepth2 >= camDepth0) 
        {
            outColor = color0;
            blendLayer1 = color2;
            blendLayer2 = color1;
            gl_FragDepth = depth1;
        } 
        else if (camDepth2 >= camDepth0 && camDepth0 >= camDepth1) {
            outColor = color1;
            blendLayer1 = color0;
            blendLayer2 = color2;
            gl_FragDepth = depth2;
        }
        else {
            outColor = color0;
            blendLayer1 = color1;
            blendLayer2 = color2;
            gl_FragDepth = depth2;
        }

        // Apply the two blend layers
        float oneMinusAlpha = 1.0 - blendLayer1.a;
        outColor = vec4(blendLayer1.a * blendLayer1.rgb + outColor.rgb * oneMinusAlpha, blendLayer1.a + oneMinusAlpha * outColor.a);

        oneMinusAlpha = 1.0 - blendLayer2.a;
        outColor = vec4(blendLayer2.a * blendLayer2.rgb + outColor.rgb * oneMinusAlpha, blendLayer2.a + oneMinusAlpha * outColor.a);
    }
#endif
}
