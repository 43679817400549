import { assert, FetchError } from "@faro-lotv/foundation";

/** Max number of workers instance for each worker */
const DEFAULT_POOL_SIZE = 4;

/** A wrapper for a fixed number of worker that will return every time a different worker */
class WorkerPool {
	/** All the worker instances */
	workers: Worker[] = [];

	/** Id if the next worker to return */
	next = 0;

	/**
	 * Create a WorkerPool for a specific worker type
	 *
	 * @param workerUrl url of the worker source code to load
	 */
	constructor(private workerUrl: string) {
		// Init all the workers
		for (let i = 0; i < DEFAULT_POOL_SIZE; ++i) {
			this.workers.push(new Worker(this.workerUrl));
		}
	}

	/** @returns the next worker instance of this pool */
	get(): Worker {
		const worker = this.workers[this.next];
		this.next += 1;
		if (this.next >= this.workers.length) {
			this.next = 0;
		}
		return worker;
	}
}

/**
 * List of all available workers, need to map to the name.workers.ts in the /src/workers folder
 */
type WorkerNames = "WSNodes" | "PointCloudLoading" | "PotreeNodes" | "GltfLoading" | "Orthophoto";

/** Urls of the deployed workers to use, by default expect them in the /workers folder of the current origin */
let workerUrls: Record<WorkerNames, string> | undefined;

/**
 * Register the deployed lotv workers in the lotv library
 *
 * @param urls mapping of each worker url
 */
export function initLotvWorkers(urls: Record<WorkerNames, string>): void {
	workerUrls = urls;
}

/**
 * Store the already loaded workers and return them on further requests
 */
const workersMap = new Map<string, Promise<WorkerPool>>();

/**
 * Load a worker by name, if already loaded return the previous loaded instance
 *
 * @param name The worker name, map to a file in src/workers/{name}.worker.ts
 * @returns The worker instance
 */
export async function loadWorker(name: WorkerNames): Promise<Worker> {
	const workerUrl = workerUrls?.[name];
	assert(workerUrl, "WebWorkers need to be initialized with the Lotv.initLotvWorkers function");
	let promise = workersMap.get(name);
	if (promise) {
		return (await promise).get();
	}
	promise = (async () => {
		const req = await fetch(workerUrl);
		if (!req.ok) {
			throw new FetchError("lotv", `${name} worker at url ${workerUrl}`);
		}
		const text = await req.text();
		const blob = new Blob([text], { type: "application/javascript" });
		const blobUrl = URL.createObjectURL(blob);
		return new WorkerPool(blobUrl);
	})();
	workersMap.set(name, promise);
	return (await promise).get();
}

/**
 * @returns The URL pointing to a worker, if it exists
 * @param name The name of the worker
 */
export function getWorkerUrl(name: WorkerNames): string | undefined {
	return workerUrls?.[name];
}

/**
 * Deallocate all existing workers, useful for testing
 */
export function clearWorkerCache(): void {
	workersMap.clear();
}
