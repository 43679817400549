import { Optional } from "@faro-lotv/foundation";
import { GUID, IElementWithFileUri } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Changes the file stored in an IElement
 */
export interface MutationExchangeFileUri extends BaseMutation {
  type: MutationTypes.MutationExchangeFileUri;

  /** The uri of the new file */
  uri: NonNullable<IElementWithFileUri["uri"]>;

  /** The md5 hash of the new file */
  md5Hash: NonNullable<IElementWithFileUri["md5Hash"]>;

  /** The size of the new file in bytes */
  fileSize: NonNullable<IElementWithFileUri["fileSize"]>;

  /** The file name of the new file */
  fileName: IElementWithFileUri["fileName"];
}

/** Parameters describing a new file to store in an IElement */
export type ExchangeFileProps = Optional<
  Pick<MutationExchangeFileUri, "uri" | "md5Hash" | "fileSize" | "fileName">,
  "fileName"
>;

/**
 * @returns a mutation to change an IElement's file
 * @param elementId id of the element to change the file of. Note: Check the Project API documentation for supported IElement types.
 * @param newFile information about the new file
 */
export function createMutationExchangeFileUri(
  elementId: GUID,
  newFile: ExchangeFileProps,
): MutationExchangeFileUri {
  return {
    ...createBaseMutation(MutationTypes.MutationExchangeFileUri, elementId),
    uri: newFile.uri,
    md5Hash: newFile.md5Hash,
    fileSize: newFile.fileSize,
    fileName: newFile.fileName ?? null,
  };
}
