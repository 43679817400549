import { useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { Object3D } from "three";
import { useThreeEventTarget } from "../hooks";
import { CreateAnnotationControlsLogic } from "./create-annotation-controls-logic";

interface CreateAnnotationControlsProps {
  /** The mesh to render */
  mesh: Object3D;

  /** Called when the annotation is updated, with the updated mesh */
  onAnnotationUpdated(mesh?: Object3D): void;

  /** Called when the annotation is created, with the final mesh */
  onAnnotationCreated(mesh: Object3D): void;

  /** Called when the user start to drag the pointer to create a new annotation */
  onDragStart?(): void;

  /** Called when the user start stop the drag interaction to create a new annotation */
  onDragEnd?(): void;

  /** Whether the controls are enabled */
  isEnabled: boolean;
}

/**
 *  @returns the controls and logic for creating annotations
 */
export function CreateAnnotationControls({
  mesh,
  onAnnotationCreated,
  onAnnotationUpdated,
  onDragStart,
  onDragEnd,
  isEnabled,
}: CreateAnnotationControlsProps): JSX.Element | null {
  const { camera, raycaster } = useThree();

  const domElement = useThreeEventTarget();

  const controls = useMemo(
    () =>
      new CreateAnnotationControlsLogic(camera, raycaster, domElement, mesh),
    [camera, raycaster, domElement, mesh],
  );

  useEffect(() => {
    if (controls && isEnabled) controls.start();
    return () => controls.stop();
  }, [controls, isEnabled]);

  return (
    <primitive
      object={controls}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      /* eslint-disable react/no-unknown-property */
      onAnnotationUpdated={onAnnotationUpdated}
      onAnnotationCreated={onAnnotationCreated}
      /* eslint-enable react/no-unknown-property */
    />
  );
}
