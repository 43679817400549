import { Quaternion, Vector2, Vector3, Vector4 } from "three";

/**
 * An interpolation function is a function that returns an intermediate value
 * between two values based on a certain percentage, that should be included
 * in the [0, 1] interval.
 */
export type InterpolationFunction<T> = (from: T, to: T, perc: number) => T;

/**
 * A set of interpolation functions for common types.
 */
export const Interpolation = {
	/**
	 * Interpolate a scalar
	 *
	 * @param from The starting value of the interpolation
	 * @param to The ending value of the interpolation
	 * @param progress The current percentage of interpolation, in [0, 1]
	 * @returns The interpolated value
	 */
	number(from: number, to: number, progress: number): number {
		return from * (1 - progress) + to * progress;
	},

	/**
	 * Interpolate a THREE.Vector2
	 *
	 * @param from The starting value of the interpolation
	 * @param to The ending value of the interpolation
	 * @param progress The current percentage of interpolation, in [0, 1]
	 * @returns The interpolated value
	 */
	vector2(from: Vector2, to: Vector2, progress: number): Vector2 {
		return new Vector2().lerpVectors(from, to, progress);
	},

	/**
	 * Interpolate a THREE.Vector3
	 *
	 * @param from The starting value of the interpolation
	 * @param to The ending value of the interpolation
	 * @param progress The current percentage of interpolation, in [0, 1]
	 * @returns The interpolated value
	 */
	vector3(from: Vector3, to: Vector3, progress: number): Vector3 {
		return new Vector3().lerpVectors(from, to, progress);
	},

	/**
	 * Interpolate a THREE.Vector4
	 *
	 * @param from The starting value of the interpolation
	 * @param to The ending value of the interpolation
	 * @param progress The current percentage of interpolation, in [0, 1]
	 * @returns The interpolated value
	 */
	vector4(from: Vector4, to: Vector4, progress: number): Vector4 {
		return new Vector4().lerpVectors(from, to, progress);
	},

	/**
	 * Interpolate a THREE.Quaternion
	 *
	 * @param from The starting value of the interpolation
	 * @param to The ending value of the interpolation
	 * @param progress The current percentage of interpolation, in [0, 1]
	 * @returns The interpolated value
	 */
	quaternion(from: Quaternion, to: Quaternion, progress: number): Quaternion {
		const res = new Quaternion();
		res.slerpQuaternions(from, to, progress);
		return res;
	},

	/**
	 * Return the default interpolation function based on the type of the input property
	 *
	 * @param property The property for which we want a valid interpolant
	 * @returns The interpolation function for the input property
	 */
	getDefaultInterpolant<T>(property: T): InterpolationFunction<T> | undefined {
		let interpolant = undefined;

		if (typeof property === "number") {
			interpolant = this.number;
		} else if (property instanceof Vector2) {
			interpolant = this.vector2;
		} else if (property instanceof Vector3) {
			interpolant = this.vector3;
		} else if (property instanceof Vector4) {
			interpolant = this.vector4;
		} else if (property instanceof Quaternion) {
			interpolant = this.quaternion;
		}

		if (interpolant === undefined) {
			return undefined;
		}

		// Typescript cannot infer the correct function type here
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return interpolant as InterpolationFunction<T>;
	},
};
