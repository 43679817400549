/**
 * Implementing some Sphere API response types. See also
 * https://v2.project.api.dev.holobuilder.com/swagger/index.html#tag/SphereProxy/paths/~1v1~1{projectId}~1proxy/post
 */

export type SphereApiValidationProblemDetails = {
  type: "ApiValidationProblemDetails";

  /** Object containing keys of invalid or missing fields mapped to an array of associated human-readable errors. */
  errors: Record<string, []> | null;

  /** A human-readable summary of the errors. */
  title: string | null;
};

export type SphereValidationError = SphereError & {
  data: SphereApiValidationProblemDetails;
};

export type SphereError = {
  error: string;
};

export type SphereSuccess = string;

/**
 * @param obj The response from the Sphere API
 * @returns Whether o is a SphereError
 */
export function isSphereAPIerror(
  obj: Record<string, unknown> | string,
): obj is SphereError {
  if (typeof obj === "string") {
    return false;
  }
  const ret: Partial<SphereValidationError> = obj;
  return ret.error !== undefined;
}

/**
 * @param obj The response from the Sphere API
 * @returns Whether o is a SphereValidationError
 */
export function isSphereAPIValidationError(
  obj: Record<string, unknown> | string,
): obj is SphereValidationError {
  if (!isSphereAPIerror(obj)) {
    return false;
  }
  const ret: Partial<SphereValidationError> = obj;
  // After a lot of testing with the project API, the checks below are the correct way
  // to implement this guard.
  return (
    !!ret.data &&
    // Allow this check as part of the type guard
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    ret.data.type === "ApiValidationProblemDetails"
  );
}
