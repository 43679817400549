import {
  Box,
  ToggleButton as MUIToggleButton,
  ToggleButtonProps as MUIToggleButtonProps,
} from "@mui/material";
import { forwardRef } from "react";
import { cyan, neutral } from "../colors";

/** The size of the spinner in pixels */
const SPINNER_SIZE = 18;

/** The thickness of the spinner in pixels */
const SPINNER_THICKNESS = 2;

export type ToggleButtonProps = MUIToggleButtonProps & {
  /** Show an optional spinner on top of the button */
  showSpinner?: boolean;
  // true to prevent the onClick event from propagating whether the button is disabled or not
  neverPropagateOnClick?: boolean;
};

/** @returns A custom ToggleButton that can be disabled while preserving the ToolTip */
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(
    {
      children,
      value,
      disabled,
      onClick,
      sx,
      showSpinner,
      neverPropagateOnClick,
      ...props
    }: ToggleButtonProps,
    ref,
  ) {
    const extraSx: ToggleButtonProps["sx"] = disabled
      ? {
          ":hover": {
            backgroundColor: "transparent",
            cursor: "default",
          },
          backgroundColor: "transparent",
        }
      : {};
    return (
      <MUIToggleButton
        ref={ref}
        value={value}
        sx={[extraSx, ...(Array.isArray(sx) ? sx : [sx])]}
        disableRipple={disabled}
        onClick={(event: React.MouseEvent<HTMLElement>, value) => {
          if (neverPropagateOnClick) {
            event.stopPropagation();
          }
          if (!disabled && onClick) onClick(event, value);
        }}
        {...props}
      >
        <div style={{ opacity: disabled ? 0.5 : 1, lineHeight: 0 }}>
          {children}
        </div>
        {showSpinner && <Spinner />}
      </MUIToggleButton>
    );
  },
);

/** @returns A partial circle rotating in loop */
function Spinner(): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "inline-block",
        width: SPINNER_SIZE,
        height: SPINNER_SIZE,
        position: "absolute",
        "@keyframes rotation": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
        animation: "rotation 1.4s linear infinite",
      }}
    >
      <svg
        style={{ display: "block", color: cyan[400] }}
        viewBox={`${SPINNER_SIZE / 2} ${SPINNER_SIZE / 2} ${SPINNER_SIZE} ${SPINNER_SIZE}`}
      >
        <circle
          cx={SPINNER_SIZE}
          cy={SPINNER_SIZE}
          r={(SPINNER_SIZE - 2 * SPINNER_THICKNESS) / 2}
          fill="none"
          stroke={neutral[999]}
          strokeWidth={SPINNER_THICKNESS * 2}
          strokeDasharray="80px, 200px"
          strokeDashoffset={-15}
        />
        <circle
          cx={SPINNER_SIZE}
          cy={SPINNER_SIZE}
          r={(SPINNER_SIZE - 2 * SPINNER_THICKNESS) / 2}
          fill="none"
          stroke="currentColor"
          strokeWidth={SPINNER_THICKNESS}
          strokeDasharray="80px, 200px"
          strokeDashoffset={-15}
        />
      </svg>
    </Box>
  );
}
