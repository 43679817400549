import Close from "@mui/icons-material/Close";
import { Stack, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { ReactNode } from "react";
import { FaroButton } from "../button/faro-button";
import { FontWeights } from "../faro-theme";
import { IconButton } from "../icon-button/icon-button";
import {
  CheckmarkCircleFillIcon,
  ExclamationMarkCircleFillIcon,
  InfoCircleFillIcon,
  XMarkCircleFillIcon,
} from "../icons/icons";

export type BannerVariants = "success" | "info" | "warning" | "error";

export type BannerProps = {
  /** Possible variants of the banner*/
  variant: BannerVariants;

  /** Title of this banner */
  title?: string;

  /** Main content of this banner */
  children?: ReactNode;

  /** Additional styling */
  sx?: SxProps<Theme>;

  /** The action that can be triggered by the banner */
  action?: {
    /** Label for the action button */
    label: string;

    /** Action to perform, which can be either a URL to open or a callback function to be called */
    action?: string | (() => void);
  };

  /** Callback executed when user closes the notification. Leave undefined to always show the notification. */
  onClose?(): void;
};

/** @returns the desired icon based on the banner variant */
function BannerIcon({
  variant,
  sx,
}: Pick<BannerProps, "variant" | "sx">): JSX.Element {
  switch (variant) {
    case "success":
      return <CheckmarkCircleFillIcon sx={{ color: "success.main", ...sx }} />;
    case "info":
      return <InfoCircleFillIcon sx={{ color: "info.main", ...sx }} />;
    case "warning":
      return (
        <ExclamationMarkCircleFillIcon sx={{ color: "warning.main", ...sx }} />
      );
    case "error":
      return <XMarkCircleFillIcon sx={{ color: "error.main", ...sx }} />;
  }
}

/** @returns the properly configured action button based on the Banner action props */
function BannerAction({
  action,
}: Required<Pick<BannerProps, "action">>): JSX.Element {
  const href = typeof action.action === "string" ? action.action : undefined;
  const callback =
    typeof action.action === "string" ? undefined : action.action;
  return (
    <FaroButton
      role="action-button"
      variant="ghost"
      href={href}
      onClick={callback}
    >
      {action.label}
    </FaroButton>
  );
}

/**
 * @returns a dismissible notification bar spanning the full width of the parent component with an optional action
 */
export function Banner({
  variant,
  title,
  children,
  sx,
  action,
  onClose,
}: BannerProps): JSX.Element {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      role="banner"
      sx={{
        p: 1,
        color: "darkGrey",
        minHeight: "40px",
        border: ({ palette }) => `1px solid ${palette.black10}`,
        background: ({ palette }) => palette[variant].light,
        ...sx,
      }}
    >
      <BannerIcon variant={variant} sx={{ ml: 1 }} />
      {title && (
        <Typography
          component="span"
          role="title"
          fontStyle="inherit"
          fontWeight={FontWeights.SemiBold}
        >
          {title}
        </Typography>
      )}

      <Typography
        component="span"
        role="message"
        fontStyle="inherit"
        sx={{
          flexGrow: 1,
        }}
      >
        {children}
      </Typography>

      {action?.action && <BannerAction action={action} />}

      {onClose && (
        <IconButton role="close-button" onClick={onClose}>
          <Close sx={{ width: "20px", height: "20px" }} />
        </IconButton>
      )}
    </Stack>
  );
}
