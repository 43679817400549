import { generateGUID } from "@faro-lotv/foundation";
import {
  GUID,
  IElementAnalysis,
  IElementGroup,
  IElementSection,
  IElementType,
  IElementTypeHint,
  IElementWithFileUri,
  IPose,
  ISOTimeString,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  DEFAULT_ANALYSIS_SECTION_SCALE_UNIFORM,
  DEFAULT_NODES_GROUP_SCALE_UNIFORM,
} from "../utils/mutation-constants";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

export interface MutationAddAnalysis extends BaseMutation {
  /** The Group (Nodes) to create if the parent element is missing it */
  group?: CreateIElement<IElementGroup>;

  /** The Section (Analysis) to create if the parent element is missing it */
  section?: CreateIElement<IElementSection>;

  /** New Analysis */
  newElement: CreateIElement<IElementAnalysis>;
}

export interface CreateMutationAddAnalysis {
  /** ID of the analysis parent: Section(Area) or Section(FocusScan) */
  sectionId: GUID;

  /** ID of the Group(Nodes) that will contain the Analysis section if it exists, it will be created if missing */
  groupId?: GUID;

  /** ID of the Section (Analysis) that will contain the Analysis IElement if it exists, it will be created if missing */
  analysisSectionId?: GUID;

  /** ID of the new IElementAnalysis */
  newElementId: GUID;

  /** Title of the Analysis being created */
  name: string;

  /** uri of analysis json file */
  signedUri: string;

  /** The md5 hash of the new file */
  md5Hash: NonNullable<IElementWithFileUri["md5Hash"]>;

  /** The size of the new file in bytes */
  fileSize: NonNullable<IElementWithFileUri["fileSize"]>;

  /** The file name of the new file */
  fileName: IElementWithFileUri["fileName"];

  /** ID of the root element */
  rootId: GUID;

  /** Local pose for this markup polygon*/
  pose?: IPose;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default now
   */
  createdAt?: ISOTimeString;
}

/**
 * Adds a new Analysis instance to an existing Section node.
 *
 *```
 * Section (Area/FocusScan)
 * └ Group (Nodes)
 *   └ Section (Analysis)
 *     └ Analysis (PlaneFitColormap)
 *
 * ```
 *
 * @returns A MutationAddAnalysis object
 */
export function createMutationAddAnalysis({
  sectionId,
  newElementId,
  groupId: maybeGroupId,
  analysisSectionId: maybeAnalysisSectionId,
  signedUri,
  md5Hash,
  fileName,
  fileSize,
  rootId,
  name,
  pose,
  createdAt = new Date().toISOString(),
}: CreateMutationAddAnalysis): MutationAddAnalysis {
  const elementId = maybeAnalysisSectionId ?? maybeGroupId ?? sectionId;
  const groupId = maybeGroupId ?? generateGUID();
  const analysisSectionID = maybeAnalysisSectionId ?? generateGUID();

  const group: CreateIElement<IElementGroup> | undefined = maybeGroupId
    ? undefined
    : {
        type: IElementType.group,
        typeHint: IElementTypeHint.nodes,
        parentId: sectionId,
        id: groupId,
        rootId,
        name: "Annotations",
        childrenIds: [analysisSectionID],
        xOr: false,
        createdAt,
        pose: {
          pos: null,
          rot: null,
          scale: {
            x: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
            y: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
            z: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          },
          isWorldRot: false,
          isWorldPose: false,
          isWorldScale: false,
          gps: null,
        },
      };

  const analysisSection: CreateIElement<IElementSection> | undefined =
    maybeAnalysisSectionId
      ? undefined
      : {
          type: IElementType.section,
          typeHint: IElementTypeHint.analysis,
          parentId: groupId,
          id: analysisSectionID,
          rootId,
          name: "Analyses",
          childrenIds: [newElementId],
          createdAt,
          pose: {
            pos: null,
            rot: null,
            scale: {
              x: DEFAULT_ANALYSIS_SECTION_SCALE_UNIFORM,
              y: DEFAULT_ANALYSIS_SECTION_SCALE_UNIFORM,
              z: DEFAULT_ANALYSIS_SECTION_SCALE_UNIFORM,
            },
            isWorldRot: false,
            isWorldPose: false,
            isWorldScale: false,
            gps: null,
          },
        };

  const newElement: CreateIElement<IElementAnalysis> = {
    type: IElementType.analysis,
    typeHint: IElementTypeHint.planeFitColormap,
    id: newElementId,
    rootId,
    parentId: analysisSectionID,
    childrenIds: [],
    signedUri,
    uri: signedUri,
    md5Hash,
    fileName,
    fileSize,
    pose,
    name,
    createdAt,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddAnalysis, elementId),
    newElement,
    group,
    section: analysisSection,
  };
}
