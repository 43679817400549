import { WebGLRenderer, WebGLRendererParameters } from "three";

/** The graphic processing unit the renderer is operating on */
export enum GPUvendor {
	/** Powerful GPU used on desktop or laptop PCs */
	NVIDIA = "NVIDIA",
	/** Energy-saving GPU used on desktop or laptop PCs */
	Intel = "Intel",
	/** GPU used on iPads and Apple devices */
	Apple = "Apple",
	/** Graphic processor present on most Android smartphones */
	Qualcomm = "Qualcomm",
	/** Other GPU */
	Other = "Other",
}

/**
 * A specialization of the WebGLRenderer to support device capability awareness
 *
 * Furthermore, this LotvRenderer inherits from a custom version of threejs' WebGLRenderer.
 * This custom WebGLRenderer contains a fix on handling of cube map textures, needed for
 * the suspend animation logic of the viewer.
 */
export class LotvRenderer extends WebGLRenderer {
	#gpuVendor: GPUvendor;
	#vendor: string = "";
	#renderer: string = "";

	#computeGpuVendor(): GPUvendor {
		const gl = this.getContext();
		const dbgRenderInfo = gl.getExtension("WEBGL_debug_renderer_info");
		if (dbgRenderInfo !== null) {
			this.#renderer = gl.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL);
			this.#vendor = gl.getParameter(dbgRenderInfo.UNMASKED_VENDOR_WEBGL);
			const vendor = this.#vendor.toLowerCase();
			if (vendor.indexOf("nvidia") >= 0) return GPUvendor.NVIDIA;
			else if (vendor.indexOf("intel") >= 0) return GPUvendor.Intel;
			else if (vendor.indexOf("qualcomm") >= 0) return GPUvendor.Qualcomm;
			else if (vendor.indexOf("apple") >= 0) return GPUvendor.Apple;
		}
		return GPUvendor.Other;
	}

	/**
	 * @param parameters The parameters for the ThreeJs WebGLRenderer constructor.
	 */
	constructor(parameters?: WebGLRendererParameters) {
		super(parameters);

		this.#gpuVendor = this.#computeGpuVendor();
	}

	/** @returns the GPU type */
	get gpuVendor(): GPUvendor {
		return this.#gpuVendor;
	}

	/** @returns the platform vendor name */
	get vendorName(): string {
		return this.#vendor;
	}

	/** @returns the GL renderer name */
	get rendererName(): string {
		return this.#renderer;
	}

	/** @returns whether this renderer supports high quality SSAO. */
	get supportsHighQualitySSAO(): boolean {
		// High-quality SSAO require a lot of texture lookpus per pixel per frame.
		// This is only feasible in GPUs with dedicated memory for textures.
		// For example, Apple M1 platforms have powerful GPUs but they share memory with the
		// CPU, hence high-quality SSAO runs slow.
		// After many tests, high-quality SSAO is disabled on all Apple platforms.
		return this.#gpuVendor === GPUvendor.NVIDIA;
	}
}
