import { BillboardLineMaterial, BillboardSprite } from "@faro-lotv/lotv";
import { useLotvDispose } from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import {
  BufferGeometry,
  Color,
  Line,
  Texture,
  Vector2,
  Vector3,
  Vector4,
} from "three";
import { useThreeEventTarget } from "../hooks";

interface IProps {
  /** 3D position of the annotation's target */
  pos: Vector3;

  /** texture of annotation's sprite. */
  spriteTexture: Texture;

  /** texture of the annotation's target. */
  targetTexture?: Texture;

  /**
   * Offset from the annotation's target. It's the position of the annotation's sprite.
   * It's a Vector2 because it's always facing the camera.
   */
  offset?: Vector2;

  /** Color of the line that connects the annotation's target to the annotation's sprite. */
  lineColor?: Color;

  /** Minimum size of the annotation's sprite. */
  minSize?: number;

  /** Maximum size of the annotation's sprite. */
  maxSize?: number;

  /** Minimum size of the annotation's target. */
  targetMinSize?: number;

  /** Maximum size of the annotation's target. */
  targetMaxSize?: number;

  /** Flag to decide if render the occluded parts of the annotations or not. */
  shouldDrawOccluded?: boolean;

  /** callback function on clicking of annotation  */
  onClick?(): void;

  /** callback function on hovering over annotation  */
  onPointerOver?(): void;

  /** callback on position change of the description dialog related to the markup */
  onDescriptionDialogPosChanged?(pos: Vector2): void;
}

// Default values
const faroBlue = 0x00aaff;
const defaultLineColor = new Color(faroBlue);
const defaultMinSize = 32;
const defaultMaxSize = 32;
const defaultTargetMinSize = 11;
const defaultTargetMaxSize = 11;

/**
 * @returns Component that renders an Annotation.
 *
 * The annotation is composed of a sprite, a target and a line that connects them.
 * It uses the BillboardSprite and BillboardLine classes.
 *
 * The annotation can display some text when clicked.
 */
export function OccludedAnnotation({
  pos,
  offset,
  spriteTexture,
  targetTexture,
  lineColor = defaultLineColor,
  minSize = defaultMinSize,
  maxSize = defaultMaxSize,
  targetMinSize = defaultTargetMinSize,
  targetMaxSize = defaultTargetMaxSize,
  shouldDrawOccluded = true,
  onClick,
  onPointerOver,
  onDescriptionDialogPosChanged,
}: IProps): JSX.Element {
  const root = useThree((s) => s.gl.domElement.parentElement);

  const domElement = useThreeEventTarget();

  if (!root) throw Error("Parent of the canvas does not exist");

  const annotationSprite = useMemo(
    () =>
      new BillboardSprite({
        size: 1,
        position: new Vector3(),
        color: new Color("white"),
        symbol: spriteTexture,
      }),
    [spriteTexture],
  );

  // Update material if props change.
  useEffect(() => {
    if (offset) {
      annotationSprite.material.offset = new Vector2(offset.x, offset.y);
    }
    // drawOccluded flag set to true, means that the occluded parts of the sprite will also be rendered.
    annotationSprite.material.drawOccluded = shouldDrawOccluded;
    annotationSprite.minSize = minSize;
    annotationSprite.maxSize = maxSize;
    annotationSprite.scale.set(0.1, 0.1, 0.1);
  }, [offset, shouldDrawOccluded, maxSize, minSize, annotationSprite]);

  // Create the annotation's target.
  const annotationTarget = useMemo(
    () =>
      new BillboardSprite({
        size: 0.5,
        position: new Vector3(),
        color: new Color("white"),
        symbol: targetTexture,
      }),
    [targetTexture],
  );

  // Update material if props change.
  useEffect(() => {
    // drawOccluded flag set to true, means that the occluded parts of the target will also be rendered.
    annotationTarget.material.drawOccluded = shouldDrawOccluded;
    annotationTarget.minSize = targetMinSize;
    annotationTarget.maxSize = targetMaxSize;
  }, [shouldDrawOccluded, targetMinSize, targetMaxSize, annotationTarget]);

  // Create the line.
  const line = useMemo(
    () => new Line(new BufferGeometry(), new BillboardLineMaterial()),
    [],
  );

  // Update line material and geometry if props change.
  useEffect(() => {
    if (!offset) return;
    // drawOccluded flag set to true, means that the occluded parts of the line will also be rendered.
    line.material.drawOccluded = shouldDrawOccluded;
    line.material.color = new Vector3(lineColor.r, lineColor.g, lineColor.b);
    line.geometry.setFromPoints([new Vector2(), offset]);
  }, [
    line.geometry,
    line.material,
    lineColor.b,
    lineColor.g,
    lineColor.r,
    offset,
    shouldDrawOccluded,
  ]);

  // Update the positions of the necessary objects of annotations as the pos changes
  useEffect(() => {
    annotationSprite.position.set(pos.x, pos.y, pos.z);
    annotationTarget.position.set(pos.x, pos.y, pos.z);
    line.position.set(pos.x, pos.y, pos.z);
  }, [
    annotationSprite.position,
    annotationTarget.position,
    line.position,
    pos,
  ]);

  useFrame(({ camera }) => {
    // When there is no subscription to pos change of description dialog then no need to calculate it
    if (!onDescriptionDialogPosChanged) return;

    const position = new Vector4(0, 0, 0, 1);
    annotationSprite.updateMatrixWorld();
    // Get world coordinates.
    position.applyMatrix4(annotationSprite.matrixWorld);

    // Get view space coordinates.
    position.applyMatrix4(camera.matrixWorldInverse);
    // Check if it's behind the camera.
    if (position.z > 0) {
      return;
    }
    if (offset) position.add(new Vector4(offset.x, offset.y, 0, 0));

    // Apply projection matrix to get clip space coordinates.
    const projected = position.applyMatrix4(camera.projectionMatrix);

    // Coordinates in clip space.
    const clipSpacePosition = new Vector2(
      projected.x / projected.w,
      projected.y / projected.w,
    );

    // Get the pixel position.
    const xPos = (clipSpacePosition.x + 1) * (domElement.clientWidth / 2);
    const yPos = (1 - clipSpacePosition.y) * (domElement.clientHeight / 2);

    // update the description dialog's position.
    onDescriptionDialogPosChanged(new Vector2(xPos, yPos));
  });

  // Safe dispose the annotation's objects.
  useLotvDispose(annotationSprite);
  useLotvDispose(annotationTarget);
  useLotvDispose(line);

  return (
    <>
      <primitive
        object={annotationSprite}
        onClick={onClick}
        onPointerOver={onPointerOver}
      />

      {offset && <primitive object={line} />}

      {targetTexture && <primitive object={annotationTarget} />}
    </>
  );
}
