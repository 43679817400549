import { EventDispatcher } from "three";
import { SupportedCamera } from "../Utils";
import { SphereControls } from "./SphereControls";
import { WalkOrbitControls } from "./WalkOrbitControls";

/**
 * A SyncableControl is a camera controller whose camera can be changed externally
 * and synchronized back to the owning controller.
 */
export type SyncableControls = EventDispatcher & {
	/** Whether the controls are enabled */
	enabled: boolean;
	/** The camera managed by this controls instance */
	camera: SupportedCamera;
	/** Tells this object that the camera managed has been changed externally */
	updateFromCamera(): void;
};

/**
 * @param controls The controls object to be checked
 * @returns whether the argument object satisfies the interface of syncable controls
 */
export function isSyncableControls(controls: EventDispatcher | null): controls is SyncableControls {
	return !!controls && (controls instanceof SphereControls || controls instanceof WalkOrbitControls);
}
