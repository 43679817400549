import {
  Collapse,
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren, useState } from "react";
import { blue, cyan, green, neutral, red, yellow } from "../colors";
import { FontWeights } from "../faro-theme";
import {
  FaroIconButton,
  FaroIconButtonSizes,
} from "../icon-button/faro-icon-button";
import {
  ArrowDownIcon,
  CheckmarkCircleFillIcon,
  ExclamationMarkCircleFillIcon,
  InfoCircleFillIcon,
  XMarkCircleFillIcon,
} from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";

/** All different style variants for the FARO Button */
export enum AlertVariants {
  /** Used to notify users of a successfully completed flow, or part of it. */
  success = "success",

  /** Used to give information about a specific tool, workflow, or any task-related information worth knowing. */
  info = "info",

  /** Used to warn users of a possibly negative and undesirable outcome in their flow. */
  warning = "warning",

  /** Used to alert that something has gone wrong in the task. */
  error = "error",

  /** Used to highlight an important piece of information that does not fall under any other alert category. */
  neutral = "neutral",
}

type IconComponent = ({ sx }: { sx: SxProps<Theme> }) => JSX.Element;

/** The icon for each variant */
const VARIANT_ICON_MAP: Record<AlertVariants, IconComponent | false> = {
  [AlertVariants.success]: CheckmarkCircleFillIcon,
  [AlertVariants.info]: InfoCircleFillIcon,
  [AlertVariants.warning]: ExclamationMarkCircleFillIcon,
  [AlertVariants.error]: XMarkCircleFillIcon,
  [AlertVariants.neutral]: false,
};

type ColorMap = Record<AlertVariants, string>;

type AlertColors = {
  /** The color of the alert background */
  backgroundColor: ColorMap;
  /** The color of the icon on the side of the title */
  iconColor: ColorMap;
  /** The color of the text in the alert */
  textColor: string | undefined;
  /** The color of the arrow to expand the alert */
  arrowColor: string;
};

/** The colors for the dark variant */
const DARK_COLORS: AlertColors = {
  backgroundColor: {
    [AlertVariants.success]: green[900],
    [AlertVariants.info]: cyan[900],
    [AlertVariants.warning]: yellow[900],
    [AlertVariants.error]: red[900],
    [AlertVariants.neutral]: grey[900],
  },
  iconColor: {
    [AlertVariants.success]: green[400],
    [AlertVariants.info]: cyan[500],
    [AlertVariants.warning]: yellow[400],
    [AlertVariants.error]: red[300],
    [AlertVariants.neutral]: grey[400],
  },
  textColor: neutral[0],
  arrowColor: neutral[0],
};

/** The colors for the dark variant */
const LIGHT_COLORS: AlertColors = {
  backgroundColor: {
    [AlertVariants.success]: green[50],
    [AlertVariants.info]: blue[50],
    [AlertVariants.warning]: yellow[50],
    [AlertVariants.error]: red[50],
    [AlertVariants.neutral]: blue[50],
  },
  iconColor: {
    [AlertVariants.success]: green[700],
    [AlertVariants.info]: blue[500],
    [AlertVariants.warning]: yellow[500],
    [AlertVariants.error]: red[500],
    [AlertVariants.neutral]: grey[500],
  },
  textColor: undefined,
  arrowColor: neutral[800],
};

export type AlertProps = {
  /** The title of the alert */
  title: string | JSX.Element;

  /** The description of the alert */
  description?: string | JSX.Element;

  /** The variant of the alert */
  variant?: `${AlertVariants}`;

  /** Whether the alert is collapsible like an accordion */
  isCollapsible?: boolean;

  /** Component to use instead of the default X button */
  action?: MuiAlertProps["action"];

  /** Optional style to apply to the alert */
  sx?: SxProps<Theme>;

  /** Use the dark version of the alert */
  dark?: boolean;
};

/**
 * @returns an alert following the FARO Design
 */
export function Alert({
  title,
  description,
  variant = "neutral",
  isCollapsible = false,
  action,
  sx,
  dark = false,
  children,
}: PropsWithChildren<AlertProps>): JSX.Element {
  const [isOpen, setIsOpen] = useState(!isCollapsible);

  const colors = dark ? DARK_COLORS : LIGHT_COLORS;
  const VariantIcon = VARIANT_ICON_MAP[variant];
  return (
    <MuiAlert
      sx={{
        backgroundColor: colors.backgroundColor[variant],
        cursor: `${isCollapsible ? "pointer" : "default"}`,
        [".MuiAlert-message"]: { width: "100%" },
        ...sx,
      }}
      icon={false}
      onClick={() => isCollapsible && setIsOpen(!isOpen)}
      action={action}
    >
      <Stack direction="row" gap={1} alignItems="flex-start">
        {VariantIcon && (
          <VariantIcon
            sx={{
              color: colors.iconColor[variant],
              height: "20px",
              width: "20px",
            }}
          />
        )}
        <FaroText
          variant="bodyM"
          sx={{ fontWeight: FontWeights.SemiBold }}
          color={colors.textColor}
        >
          {title}
        </FaroText>
        {isCollapsible && (
          <FaroIconButton
            size={FaroIconButtonSizes.s}
            sx={{
              ml: "auto",
              [">:first-of-type"]: {
                m: 0,
                color: colors.arrowColor,
              },
            }}
          >
            <ArrowDownIcon
              sx={{
                transform: `${isOpen ? "rotate(180deg)" : ""}`,
              }}
            />
          </FaroIconButton>
        )}
      </Stack>
      {description && (
        <Collapse in={isOpen} unmountOnExit sx={{ mt: 2 }}>
          <FaroText variant="bodyM" color={colors.textColor}>
            {description}
          </FaroText>
        </Collapse>
      )}
      {children}
    </MuiAlert>
  );
}
