import { ShaderChunk } from "three";
import colorConversions from "../Shaders/ColorConversions.frag";
import lotvLightingComputations from "../Shaders/LotvLightningComputations.frag";

/**
 * This function adds to threes' 'shader chunk' dictionary our fragment code
 * that we want to reuse in multiple shaders.
 */
export function initShaderChunk(): void {
	const alreadyInitialized = "lotv_lighting_computations" in ShaderChunk;
	if (!alreadyInitialized) {
		Object.assign(ShaderChunk, {
			lotv_lighting_computations: lotvLightingComputations,
			lotv_color_conversions: colorConversions,
		});
	}
}
