import { FormControlLabel, Radio, RadioProps } from "@mui/material";
import { ReactNode } from "react";
import { blue, cyan, neutral, red } from "../colors";
import { ReactComponent as RadioCheckedDark } from "./icons/radio-checked-dark.svg";
import { ReactComponent as RadioCheckedLight } from "./icons/radio-checked-light.svg";
import { ReactComponent as RadioUnchecked } from "./icons/radio-unchecked.svg";

export type FaroRadioProps = Pick<
  RadioProps,
  "value" | "checked" | "onChange" | "disabled"
> & {
  /** The content to show in the label */
  label?: ReactNode;

  /** Whether to enable dark styles */
  dark?: boolean;

  /** Whether to enable error styles */
  error?: boolean;
};

const LIGHT_COLORS = {
  idleColor: neutral[800],
  hoverColor: blue[500],

  error: red[500],

  fillUnchecked: neutral[0],
  fillUncheckedError: red[50],

  colorChecked: blue[600],
  colorCheckedError: red[600],
  fillChecked: blue[500],
  fillCheckedError: red[500],
  fillCheckedHover: blue[600],

  outlineFocused: neutral[999],
};

const DARK_COLORS = {
  idleColor: neutral[100],
  hoverColor: cyan[500],

  error: red[300],

  fillUnchecked: neutral[800],
  fillUncheckedError: red[900],

  colorChecked: cyan[300],
  colorCheckedError: red[200],
  fillChecked: cyan[400],
  fillCheckedError: red[300],
  fillCheckedHover: cyan[300],

  outlineFocused: neutral[100],
};

/**
 * @returns a single radio button. To create a full radio list, use multiple in a FaroRadioGroup.
 */
export function FaroRadio({
  value,
  label,
  dark,
  error,
  ...radioProps
}: FaroRadioProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  const RadioChecked = dark ? RadioCheckedDark : RadioCheckedLight;

  return (
    <FormControlLabel
      value={value}
      label={label}
      control={
        <Radio
          icon={<RadioUnchecked className="FaroRadio-radioIconUnchecked" />}
          checkedIcon={<RadioChecked className="FaroRadio-radioIconChecked" />}
          sx={{
            color: colors.idleColor,

            "&:hover": {
              color: colors.hoverColor,
            },
          }}
          disableRipple
          {...radioProps}
        />
      }
      sx={{
        color: colors.idleColor,
        width: "fit-content",
        ml: 0,
        pr: 0.5,

        ".MuiRadio-root": {
          p: 1,
          pl: 0,
        },

        ".MuiFormControlLabel-label": {
          fontSize: "0.875rem",
        },

        ".FaroRadio-radioIconUnchecked": {
          color: error ? colors.error : colors.idleColor,
          fill: error ? colors.fillUncheckedError : colors.fillUnchecked,
        },

        ".FaroRadio-radioIconChecked": {
          color: error ? colors.colorCheckedError : colors.colorChecked,
          fill: error ? colors.fillCheckedError : colors.fillChecked,
        },

        "&:hover:not(&.Mui-disabled)": {
          color: colors.hoverColor,

          ".FaroRadio-radioIconUnchecked": {
            color: colors.hoverColor,
          },

          ".FaroRadio-radioIconChecked": {
            color: colors.fillCheckedHover,
            fill: colors.fillCheckedHover,
          },
        },

        // only shown on keyboard navigation
        "&:has(:focus-visible)": {
          outline: "1px solid",
          outlineColor: colors.outlineFocused,
          borderRadius: "4px",
        },

        "&.Mui-disabled": {
          opacity: 0.5,
        },
      }}
    />
  );
}
