import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  PopperProps,
  Stack,
  SxProps,
} from "@mui/material";
import { Instance } from "@popperjs/core";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { ColorString, blue, cyan, neutral } from "../colors";
import { FaroIconButton } from "../icon-button/faro-icon-button";
import { CloseIcon } from "../icons";

type PopoverColors = {
  color: ColorString;
  highlightColor: ColorString;
  backgroundColor: ColorString;
  shadowColor?: ColorString;
};

const LIGHT_COLORS: Readonly<PopoverColors> = Object.freeze({
  color: neutral[800],
  highlightColor: blue[500],
  backgroundColor: neutral[50],
  shadowColor: neutral[1000],
});

const DARK_COLORS: Readonly<PopoverColors> = Object.freeze({
  color: neutral[100],
  highlightColor: cyan[400],
  backgroundColor: neutral[950],
});

/**
 * Styles to correctly position the arrow tip in the popover.
 *
 * Inspired by:
 * - https://github.com/mui/material-ui/blob/v5.15.18/docs/data/material/components/popper/ScrollPlayground.js
 * - https://stackoverflow.com/a/76948170
 */
const ARROW_STYLE = {
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: 1.5,
    },
    "& .MuiPopper-arrow": {
      top: 0,
      "&::before": {
        transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: 1.5,
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      "&::before": {
        transform: "translateX(-50%) translateY(-50%) rotate(-135deg)",
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: 1.5,
    },
    "& .MuiPopper-arrow": {
      left: 0,
      "&::before": {
        transform: "translateX(-50%) translateY(-50%) rotate(-45deg)",
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: 1.5,
    },
    "& .MuiPopper-arrow": {
      right: 0,
      "&::before": {
        transform: "translateX(-50%) translateY(-50%) rotate(135deg)",
      },
    },
  },
} satisfies SxProps;

export type BasePopoverProps = PropsWithChildren<
  Omit<PopperProps, "children">
> & {
  /**
   * If set to `true`, use the dark theme variant of the design.
   */
  dark?: boolean;

  /** The top bar of the popover, on the top left. */
  titleBar?: string | JSX.Element;

  /** A callback to execute when the popover is closed by the user. */
  onClose?(): void;

  /**
   * Whether the popover closes when the user clicks outside of it.
   *
   * @default true
   */
  closeOnClickOutside?: boolean;

  /**
   * Whether to show a close button in the top-right corner
   *
   * @default true
   */
  showCloseButton?: boolean;

  /**
   * Whether the popper location should be recalculated on every animation frame.
   *
   * @default false
   */
  isAnimated?: boolean;

  /** Styles applied to the popper body */
  popperSx?: SxProps;
};

/**
 * @returns The base popover element, which styles the outer container and takes care of correct positioning of the arrow tip.
 */
export function BasePopover({
  dark,
  titleBar,
  onClose,
  children,
  closeOnClickOutside = true,
  showCloseButton = true,
  isAnimated = false,
  open,
  sx,
  popperSx,
  modifiers,
  ...rest
}: BasePopoverProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  const popperRef = useRef<Instance>(null);
  const [arrowRef, setArrowRef] = useState(null);

  const arrowSize = "10px";

  // Updates a popper's location periodically, if movement of the anchor is expected
  useEffect(() => {
    let isRunning = true;

    function update(): void {
      popperRef.current?.update();

      if (isRunning) requestAnimationFrame(update);
    }

    if (isAnimated && open) {
      requestAnimationFrame(update);
    }

    return () => {
      isRunning = false;
    };
  }, [popperRef, isAnimated, open]);

  return (
    <Popper
      popperRef={popperRef}
      open={open}
      sx={[
        ARROW_STYLE,
        {
          zIndex: 1,
          "& > div": {
            position: "relative",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      modifiers={[
        {
          name: "arrow",
          options: {
            element: arrowRef,
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 4,
          },
        },
        ...(modifiers ?? []),
      ]}
      {...rest}
    >
      {/* div needed for correct arrow positioning */}
      <div>
        {/* Arrow tip */}
        <Box
          component="div"
          className="MuiPopper-arrow"
          ref={setArrowRef}
          sx={{
            // A pseudo element is used to avoid clashes with the styles set by MUI
            "&:before": {
              position: "absolute",
              display: "block",
              content: '""',
              width: arrowSize,
              height: arrowSize,
              backgroundColor: colors.backgroundColor,
              borderTop: `1px solid ${colors.color}33`,
              borderLeft: `1px solid ${colors.color}33`,
            },
          }}
        />
        <Paper
          sx={{
            minWidth: "12rem",
            maxWidth: "20rem",
            color: colors.color,
            backgroundColor: colors.backgroundColor,
            border: `1px solid ${colors.color}33`,
            boxShadow: colors.shadowColor
              ? `0px 2px 6px ${colors.shadowColor}19`
              : "none",
            p: 2.5,
            overflow: "visible",
            ...popperSx,
          }}
        >
          <ClickAwayListener
            onClickAway={() => {
              if (closeOnClickOutside && onClose) {
                onClose();
              }
            }}
          >
            <Stack gap={1.25}>
              {(!!titleBar || showCloseButton) && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={1.25}
                >
                  <Stack direction="row" alignItems="center" gap={1.25}>
                    {titleBar}
                  </Stack>
                  {showCloseButton && (
                    <FaroIconButton
                      size="s"
                      onClick={onClose}
                      color={colors.color}
                      hoverColor={colors.highlightColor}
                      sx={{ "&>:first-of-type": { m: 0 } }}
                    >
                      <CloseIcon />
                    </FaroIconButton>
                  )}
                </Stack>
              )}
              {children}
            </Stack>
          </ClickAwayListener>
        </Paper>
      </div>
    </Popper>
  );
}
