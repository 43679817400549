import { WebGLRenderTarget, WebGLRenderer } from "three";
import { BlitMaterial } from "../Materials/BlitMaterial";
import { EffectPipeline } from "./EffectPipeline";
import { FboContainer } from "./FboContainer";
import { FullScreenPass } from "./FullScreenPass";

/** A pass that stores the intermediate result of an effect pipeline into an offscreen FBO, for later use */
export class StoreFboPass extends FullScreenPass<BlitMaterial> implements FboContainer {
	#offscreenFbo: WebGLRenderTarget;

	constructor() {
		super(new BlitMaterial());
		this.#offscreenFbo = EffectPipeline.fboTemplate(4, 4);
		this.needsSwap = false;
	}

	/**
	 * Copies the 'readBuffer' FBO, both color and depth texture, to the owned offscreen FBO.
	 *
	 * @param renderer renderer used to render the effect
	 * @param writeBuffer Buffer to write by renderer
	 * @param readBuffer Buffer to read the textures by renderer
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		if (this.#offscreenFbo.width !== readBuffer.width || this.#offscreenFbo.height !== readBuffer.height) {
			this.#offscreenFbo.dispose();
			this.#offscreenFbo = EffectPipeline.fboTemplate(readBuffer.width, readBuffer.height);
		}

		this.material.uniforms.uColorTexture.value = readBuffer.texture;
		this.material.uniforms.uDepthTexture.value = readBuffer.depthTexture;
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;
		renderer.setRenderTarget(this.#offscreenFbo);
		this.fsQuad.render(renderer);
		renderer.autoClear = oldAutoClear;
	}

	/** @inheritdoc */
	override dispose(): void {
		super.dispose();
		this.#offscreenFbo.dispose();
	}

	/** @returns the saved offscreen FBO */
	get offscreenFbo(): WebGLRenderTarget {
		return this.#offscreenFbo;
	}
}
