import { Vector3 } from "three";

/**
 * @param position we want to search for
 * @param candidates positions available
 * @returns the index of the closest candidate position to the searched position or undefined if candidates is empty
 */
export function findClosestIndex(
  position: Vector3,
  candidates: Vector3[],
): number | undefined {
  return candidates.reduce<number | undefined>((prev, curr, currentIndex) => {
    if (prev === undefined) {
      return currentIndex;
    }
    if (
      curr.distanceToSquared(position) <
      candidates[prev].distanceToSquared(position)
    ) {
      return currentIndex;
    }
    return prev;
  }, undefined);
}
