import { Camera, Scene } from "three";
import { Pass } from "three/examples/jsm/postprocessing/Pass.js";

/**
 * An object that has a public camera property
 */
type WithCamera = {
	camera: Camera;
};

/**
 * An object that has a public camera scene
 */
type WithScene = {
	scene: Scene;
};

/**
 * Type guard to check if a pass has a public camera property
 *
 * @param p The pass to check
 * @returns true if it has a public camera property
 */
export function passWithCamera<P extends Pass>(p: P & Partial<WithCamera>): p is P & WithCamera {
	return p.camera !== undefined && p.camera instanceof Camera;
}

/**
 * Type guard to check if a pass has a public scene property
 *
 * @param p The pass to check
 * @returns true if it has a public scene property
 */
export function passWithScene<P extends Pass>(p: P & Partial<WithScene>): p is P & WithScene {
	return p.scene !== undefined && p.scene instanceof Scene;
}

/**
 * A function to update the camera bound to a pass if it has a public camera property
 *
 * @param p The pass to update
 * @param camera The new camera
 */
export function changeCamera(p: Pass, camera: Camera): void {
	if (passWithCamera(p)) {
		p.camera = camera;
	}
}

/**
 * A function to update the scene bound to a pass if it has a public scene property
 *
 * @param p The pass to update
 * @param scene The new scene
 */
export function changeScene(p: Pass, scene: Scene): void {
	if (passWithScene(p)) {
		p.scene = scene;
	}
}
