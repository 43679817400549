import { Box, Typography } from "@mui/material";
import { blue, cyan } from "../colors";
import { FontWeights } from "../faro-theme";

/**
 * Tags to show next to the label. Should be used to mark the minority of fields.
 *  - "star" marks a field as required, but must have an accompanying legend visible in the form.
 *  - "required" marks a field as required
 *  - "optional" marks a field as optional
 */
export type TagTypes = "star" | "required" | "optional";

export type InputLabelTagProps = {
  /** The tag type to show */
  tag?: TagTypes;

  /** Enables dark mode styles */
  dark?: boolean;
};

/** @returns a tag for a specific tag type to show next to the label */
export function InputLabelTag({
  tag,
  dark,
}: InputLabelTagProps): JSX.Element | null {
  if (!tag) return null;

  if (tag === "star") {
    const color = dark ? cyan[500] : blue[500];
    return (
      <Box component="span" sx={{ color, pl: 0.5 }}>
        *
      </Box>
    );
  }

  return (
    <Typography
      sx={{ pl: 0.5 }}
      component="span"
      fontSize="0.75rem"
      fontWeight={FontWeights.Regular}
    >
      ({tag})
    </Typography>
  );
}
