/**
 * @param dashboardCompanyUrl url to the company page in the sphere dashboard (Eg: https://sphere.holobuilder.com/companyId)
 * @returns the dashboard url for the active projects list for the company
 *
 * The core api backends returns the dashboardCompanyUrl to use in the project metadata
 */
export function getActiveProjectsPage(dashboardCompanyUrl: string): string {
  return `${dashboardCompanyUrl}/projects/active`;
}

/**
 * @param dashboardCompanyUrl url to the company page in the sphere dashboard (Eg: https://sphere.holobuilder.com/companyId)
 * @param projectId of the project to compute the url for
 * @returns the dashboard url for the active projects list for the company
 *
 * The core api backends returns the dashboardCompanyUrl to use in the project metadata
 */
export function getProjectDetailsPage(
  dashboardCompanyUrl: string,
  projectId: string,
): string {
  return `${dashboardCompanyUrl}/projects/${projectId}/details`;
}

/**
 * @returns the dashboard url to open the page showing the list of raw data for data preparation
 * @param dashboardCompanyUrl url to the company page in the sphere dashboard (Eg: https://sphere.holobuilder.com/companyId)
 * @param projectId of the project to compute the url for
 */
export function getProjectRawDataPage(
  dashboardCompanyUrl: string,
  projectId: string,
): string {
  // TODO: Remove &enableBeta flag https://faro01.atlassian.net/browse/NRT-1303
  return `${dashboardCompanyUrl}/projects/${projectId}/data-management?type=rawData&enableBeta=true`;
}

/**
 * @returns the dashboard url to open the page showing the list of prepared data
 * @param dashboardCompanyUrl url to the company page in the sphere dashboard (Eg: https://sphere.holobuilder.com/companyId)
 * @param projectId of the project to compute the url for
 */
export function getProjectPreparedDataPage(
  dashboardCompanyUrl: string,
  projectId: string,
): string {
  // TODO: Remove &enableBeta flag https://faro01.atlassian.net/browse/NRT-1303
  return `${dashboardCompanyUrl}/projects/${projectId}/data-management?type=preparedData&enableBeta=true`;
}
