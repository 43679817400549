/**
 * @returns The URL to the login page of the user module that redirects back to the current page
 * @param userModuleUrl Base URL of the user module
 */
export function getUserModuleLoginUrl(userModuleUrl: string): string {
  return `${userModuleUrl}#login?os=web&redirectUrl=${encodeURIComponent(
    window.location.href,
  )}`;
}

/**
 * @returns The URL to the logout page of the user module
 * @param userModuleUrl Base URL of the user module
 */
export function getUserModuleLogoutUrl(userModuleUrl: string): string {
  return `${userModuleUrl}#logout`;
}

/**
 * @returns The login URL to use for the login via the ULM
 * @param ulmUrl URL of the Unified Login Mask
 */
export function getUlmLoginUrl(ulmUrl: string): string {
  const loginUrl = new URL(
    `login?client=sphereViewer&parent_url=${window.encodeURIComponent(
      window.location.href,
    )}`,
    ulmUrl,
  );

  return loginUrl.toString();
}
