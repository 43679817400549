import { useEffect } from "react";

/**
 * Override the current cursor with a different one when a condition is met
 *
 * @param cursor to apply
 * @param condition when to use the override cursor
 */
export function useOverrideCursor(cursor: string, condition: boolean): void {
  useEffect(() => {
    const prevCursor = document.body.style.cursor;
    if (condition && prevCursor !== cursor) {
      document.body.style.cursor = cursor;
      return () => {
        document.body.style.cursor = prevCursor;
      };
    }
  }, [condition, cursor]);
}
