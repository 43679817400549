import { Divider } from "@mui/material";
import { neutral } from "../colors";

export type FaroMenuSeparatorProps = {
  /** true to use the dark variant of the component @default false */
  dark?: boolean;
};

/** @returns a divider properly styled to be used in a FaroMenu */
export function FaroMenuSeparator({
  dark,
}: FaroMenuSeparatorProps): JSX.Element {
  const color = dark ? neutral[800] : neutral[200];

  return <Divider sx={{ borderColor: color }} />;
}
