import { EasingFunction } from "@faro-lotv/foundation";
import { ObjectPropertyAnimation, ParallelAnimation } from "@faro-lotv/lotv";
import { UPDATE_CONTROLS_PRIORITY } from "@faro-lotv/spatial-ui";
import {
  Camera,
  Quaternion,
  Vector3,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { useState } from "react";
import { PerspectiveCamera } from "three";
import { parseQuaternion, parseVector3 } from "../utils/props";

/** State in which we want the camera to be at the end of the animation  */
export interface CameraTargetState {
  /** Final camera position */
  position?: Vector3;
  /** Final camera quaternion */
  quaternion?: Quaternion;
  /** Final camera fov */
  fov?: number;
  /** Final camera zoom */
  zoom?: number;
}

export interface IProps {
  /** The camera state to transition towards */
  target: CameraTargetState;

  /** The camera to animate, default to R3F default camera */
  camera?: Camera;

  /** The length in seconds of the transition */
  duration?: number;

  /** Easing function to use */
  easing?: EasingFunction;

  /** Callback executed when the transition finishes */
  onTransitionFinished?(): void;
}

/**
 * @returns Transitions the main camera from the source to the target camera.
 */
export function CameraTransition({
  camera: initCamera,
  target,
  easing,
  duration = 1,
  onTransitionFinished,
}: IProps): null {
  const defCamera = useThree((s) => s.camera);
  const camera = initCamera ?? defCamera;

  const [animation] = useState(() => {
    const animations = new ParallelAnimation([]);
    if (target.position) {
      animations.append(
        new ObjectPropertyAnimation(
          camera,
          "position",
          camera.position,
          parseVector3(target.position),
          { duration, ease: easing },
        ),
      );
    }
    if (target.quaternion) {
      animations.append(
        new ObjectPropertyAnimation(
          camera,
          "quaternion",
          camera.quaternion,
          parseQuaternion(target.quaternion),
          { duration, ease: easing },
        ),
      );
    }
    if (target.fov && camera instanceof PerspectiveCamera) {
      animations.append(
        new ObjectPropertyAnimation(camera, "fov", camera.fov, target.fov, {
          duration,
          ease: easing,
        }),
      );
    }
    if (target.zoom) {
      animations.append(
        new ObjectPropertyAnimation(camera, "zoom", camera.zoom, target.zoom, {
          duration,
          ease: easing,
        }),
      );
    }

    animations.completed.on(() => {
      onTransitionFinished?.();
    });

    return animations;
  });

  useFrame((_, delta) => {
    animation.startIfNeeded();
    animation.update(delta);
    if (!!target.zoom || !!target.fov) {
      camera.updateProjectionMatrix();
    }

    // Camera animation just moved the camera, executing this with UPDATE_CONTROLS_PRIORITY
    // so that it is guaranteed that camera monitoring and lod visibility tasks come after this.
  }, UPDATE_CONTROLS_PRIORITY);

  return null;
}
