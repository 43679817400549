import {
  GUID,
  IElementBase,
  IPose,
  IQuat,
  IRefCoordSystemTransform,
  IVec3,
} from "@faro-lotv/ielement-types";
import { Mutation } from ".";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Change the pose of an Element
 */
export interface MutationSetElementPose extends BaseMutation {
  type: MutationTypes.MutationSetElementPose;

  /** Thew new pose of the element */
  pose: IElementBase["pose"];
}

/**
 * @returns a mutation to change an IElement pose
 * @param elementId id of the element to move
 * @param pose new pose of the element
 */
export function createMutationSetElementPose(
  elementId: GUID,
  pose: IElementBase["pose"],
): MutationSetElementPose {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementPose, elementId),
    pose,
  };
}

/** Change the position of an IElement */
export interface MutationSetElementPosition extends BaseMutation {
  type: MutationTypes.MutationSetElementPosition;

  /** The new IElement position */
  position: IPose["pos"] | null;
}

/**
 * @returns a mutation to change an IElement position
 * @param elementId of the element to move
 * @param position the new position of the element
 */
export function createMutationSetElementPosition(
  elementId: GUID,
  position: IPose["pos"],
): MutationSetElementPosition {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementPosition, elementId),
    position,
  };
}

/**
 * Check if the mutation is a valid MutationSetElementPosition
 *
 * @param mutation The mutation to check
 * @returns True if the data is a valid MutationSetElementPosition
 */
export function isMutationSetElementPosition(
  mutation: Mutation,
): mutation is MutationSetElementPosition {
  return mutation.type === MutationTypes.MutationSetElementPosition;
}

/** Change the rotation of an IElement */
export interface MutationSetElementRotation extends BaseMutation {
  type: MutationTypes.MutationSetElementRotation;

  /** The new IElement position */
  rotation: IPose["rot"] | null;
}

/**
 * @returns a mutation to change an IElement rotation
 * @param elementId of the element to move
 * @param rotation the new rotation of the element
 */
export function createMutationSetElementRotation(
  elementId: GUID,
  rotation: IPose["rot"],
): MutationSetElementRotation {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementRotation, elementId),
    rotation,
  };
}

/** Change the rotation of an IElementImg360 */
export interface MutationSetImg360Rotation extends MutationSetElementRotation {
  /** false if the Rot is not accurate/precise (e.g. set form compass) */
  isRotationAccurate: boolean | null;
}

/**
 * @returns a mutation to change an IElementImg360 relative rotation
 * @param elementId of the element to move
 * @param rotation the new rotation of the element (with respect to its parent)
 * @param isRotationAccurate value for the isRotationAccurate field
 */
export function createMutationSetImg360Rotation(
  elementId: GUID,
  rotation: IPose["rot"],
  isRotationAccurate: boolean | null,
): MutationSetImg360Rotation {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementRotation, elementId),
    rotation,
    isRotationAccurate,
  };
}

/** Change the scale of an IElement */
export interface MutationSetElementScale extends BaseMutation {
  type: MutationTypes.MutationSetElementScale;

  /** The new IElement position */
  scale: IPose["scale"] | null;
}

/**
 * @returns a mutation to change an IElement scale
 * @param elementId of the element to move
 * @param scale the new scale of the element
 */
export function createMutationSetElementScale(
  elementId: GUID,
  scale: IPose["scale"],
): MutationSetElementScale {
  return {
    ...createBaseMutation(MutationTypes.MutationSetElementScale, elementId),
    scale,
  };
}

/** Set an IPose of a DataSet IElement in world coordinate system (i.e. with isWorldPose===true) */
export interface MutationSetDataSessionWorldPose extends BaseMutation {
  type: MutationTypes.MutationSetDataSessionWorldPose;

  /** The new area position */
  pose: IPose;
}

/**
 * @returns a mutation to change the absolute Pose (scale and optionally translation+rotation) of a DataSession element
 * @param dataSessionId GUID of the DataSession being aligned
 * @param scale scale to be applied to the element (1.0 when the element is already in system units)
 * @param rotation optional rotation to be applied to the element from the system coordinates system (null means no rotation)
 * @param position optional translation to be applied to the element from the system origin (null means no translation)
 */
export function createMutationSetDataSessionWorldPose(
  dataSessionId: GUID,
  scale: number,
  rotation: IQuat | null,
  position: IVec3 | null,
): MutationSetDataSessionWorldPose {
  return {
    ...createBaseMutation(
      MutationTypes.MutationSetDataSessionWorldPose,
      dataSessionId,
    ),
    pose: {
      isWorldPose: true,
      isWorldRot: true,
      isWorldScale: true,
      scale: { x: scale, y: scale, z: scale },
      rot: rotation,
      pos: position,
      gps: null,
    },
  };
}

/** Change the absolute position of a area/sheet */
export interface MutationSetAreaWorldPose extends BaseMutation {
  type: MutationTypes.MutationSetAreaWorldPose;

  /** The new area position */
  pose: IPose;
}

/**
 * @returns a mutation to change the absolute Pose (scale and optionally translation+rotation) of a area/sheet
 * @param areaId GUID of the area being aligned
 * @param scale scale to be applied to the area (1.0 when the area is already in system units)
 * @param rotation optional rotation to be applied to the area from the system coordinates system (null means no rotation)
 * @param position optional translation to be applied to the area from the system origin (null means no translation)
 */
export function createMutationSetAreaWorldPose(
  areaId: GUID,
  scale: number,
  rotation: IQuat | null,
  position: IVec3 | null,
): MutationSetAreaWorldPose {
  return {
    ...createBaseMutation(MutationTypes.MutationSetAreaWorldPose, areaId),
    pose: {
      isWorldPose: true,
      isWorldRot: true,
      isWorldScale: true,
      scale: { x: scale, y: scale, z: scale },
      rot: rotation,
      pos: position,
      gps: null,
    },
  };
}

/** Change the reference coordinate system matrix of the iElement */
export interface MutationSetElementRefCoordSystemMatrix extends BaseMutation {
  type: MutationTypes.MutationSetElementRefCoordSystemMatrix;

  /** The new  reference system matrix */
  refCoordSystemMatrix: IRefCoordSystemTransform | null;
}

/**
 * @returns Mutation to modify an iElement refCoordSystemMatrix
 * @param id The id of the iElement
 * @param refCoordSystemMatrix The new refCoordSystemMatrix of the iElement
 */
export function createMutationSetRefCoordSystemMatrix(
  id: GUID,
  refCoordSystemMatrix: IRefCoordSystemTransform | null,
): MutationSetElementRefCoordSystemMatrix {
  return {
    ...createBaseMutation(
      MutationTypes.MutationSetElementRefCoordSystemMatrix,
      id,
    ),
    refCoordSystemMatrix,
  };
}
