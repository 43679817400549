// Textures handled by this material. The maximum length is 15 textures,
// and the texture array is a list of (default, hovered, selected) triplets,
// one triplet for each placeholder label.
uniform sampler2D textures[MAX_TEXTURES];

// Alpha threshold
uniform float alphaTest;

flat in int vResourceId;
flat in int vHidden;
#ifdef USE_COLORS
in vec3 vColor;
#endif

float eps = 0.0001;

bool isGray(float r, float g, float b) {
   return abs(r - g) < eps && abs(g - b) < eps;
}

void main() {
	if (vHidden == 1) {
		discard;
		return;
	}

	// Compute uv coordinates for this point
	vec2 uv = vec2( gl_PointCoord.x, 1.0 - gl_PointCoord.y);

	// Texture lookup depending on placeholder state
	vec4 texColor = vec4(1., 1., 1., 1);
	// WebGL does not allow random access to sampler arrays: hence this switch
	switch (vResourceId) {
		case 0: texColor = texture2D(textures[0], uv); break;
		case 1: texColor = texture2D(textures[1], uv); break;
		case 2: texColor = texture2D(textures[2], uv); break;
		case 3: texColor = texture2D(textures[3], uv); break;
		case 4: texColor = texture2D(textures[4], uv); break;
		case 5: texColor = texture2D(textures[5], uv); break;
		case 6: texColor = texture2D(textures[6], uv); break;
		case 7: texColor = texture2D(textures[7], uv); break;
		case 8: texColor = texture2D(textures[8], uv); break;
		case 9: texColor = texture2D(textures[9], uv); break;
		case 10: texColor = texture2D(textures[10], uv); break;
		case 11: texColor = texture2D(textures[11], uv); break;
		case 12: texColor = texture2D(textures[12], uv); break;
		case 13: texColor = texture2D(textures[13], uv); break;
		case 14: texColor = texture2D(textures[14], uv); break;
	}

    // Discard fragments below alpha threshold
	if ( texColor.a < alphaTest ) discard;

	vec4 outColor = texColor;
	#ifdef USE_COLORS
		// Don't replace any gray
		if (!isGray(texColor.r, texColor.g, texColor.b)) {
			outColor.xyz = vColor;
		}
	#endif
	gl_FragColor = outColor;
}
