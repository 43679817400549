import { assert } from "@faro-lotv/foundation";
import { Camera, Vector2, WebGLRenderTarget, WebGLRenderer } from "three";
import { EffectPipeline } from "../PostProcessing/EffectPipeline";
import { CadRenderingMode, ICadModel } from "./ICadModel";

const TWO_POW_16 = 65536;
const TWO_POW_8 = 256;

/**
 * A class with the capability of rendering the drawIDs of a CAD model
 * to an offscreen FBO, and to compute which CAD part was rendered at
 * a given screen coordinate.
 */
export class CadPartIDsRenderer {
	#cadModel: ICadModel;
	#renderer: WebGLRenderer;
	#drawIDsFbo: WebGLRenderTarget;
	#drawIDsBuffer: Uint8Array;
	#drawIDsDirty = true;

	// Size of the offscreen FBO, in pixels
	#size: Vector2;

	/**
	 * Creates an object with the capability of rendering the drawIDs of a CAD model
	 * to an offscreen FBO, and to compute which CAD part was rendered at a given screen
	 * coordinate.
	 *
	 * @param cad The CAD model whose drawIDs must be rendered
	 * @param renderer The renderer to be used
	 */
	constructor(cad: ICadModel, renderer: WebGLRenderer) {
		this.#cadModel = cad;
		this.#renderer = renderer;

		const sz = renderer.getSize(new Vector2());
		sz.multiplyScalar(renderer.getPixelRatio());
		this.#drawIDsFbo = EffectPipeline.fboTemplate(sz.x, sz.y);
		this.#drawIDsBuffer = new Uint8Array(4 * sz.x * sz.y);
		this.#size = sz;
	}

	/** Invalidates the model drawIDs buffer */
	invalidate(): void {
		this.#drawIDsDirty = true;
	}

	/**
	 * Update the draw id buffer if needed
	 *
	 * @param camera to use to render the ids
	 */
	updateIfNeeded(camera: Camera): void {
		if (this.#drawIDsDirty) {
			this.#renderDrawIDs(camera);
			this.#drawIDsDirty = false;
		}
	}

	/**
	 * Renders the cad model drawIDs on an offscreen FBO
	 *
	 * @param camera to use to render the ids
	 */
	#renderDrawIDs(camera: Camera): void {
		// Setting the CAD model in 'render draw IDs' mode.
		this.#cadModel.renderingMode = CadRenderingMode.PartsIDs;
		const oldVis = this.#cadModel.visible;
		this.#cadModel.visible = true;

		// Rendering the CAD model to the offscreen FBO
		const renderTarget = this.#renderer.getRenderTarget();
		this.#renderer.setRenderTarget(this.#drawIDsFbo);
		const oldAutoClear = this.#renderer.autoClear;
		this.#renderer.autoClear = true;

		this.#renderer.render(this.#cadModel, camera);

		// copying rendered colors from the FBO in GPU to a buffer in CPU memory.
		this.#renderer.readRenderTargetPixels(
			this.#drawIDsFbo,
			0,
			0,
			this.#drawIDsFbo.width,
			this.#drawIDsFbo.height,
			this.#drawIDsBuffer,
		);
		this.#renderer.setRenderTarget(renderTarget);
		// restoring opaque CAD mode
		this.#cadModel.renderingMode = CadRenderingMode.OpaqueScene;
		this.#cadModel.visible = oldVis;

		// Restoring rendering parameters
		this.#renderer.autoClear = oldAutoClear;
	}

	/**
	 * Returns the draw ID that was rendered at a given pixel,
	 * decoding it from the RGB uint8 triplet. Returns -1
	 * if no valid draw ID could be decoded.
	 *
	 * @param x X coordinate of pointer, in screen pixels
	 * @param y Y coordinate of pointer, in screen pixels
	 * @returns the hovered drawId, or -1
	 */
	#drawIDatPixel(x: number, y: number): number {
		const o = 4 * (x + (this.#drawIDsFbo.height - 1 - y) * this.#drawIDsFbo.width);
		if (o >= this.#drawIDsBuffer.length) return -1;
		const r: number = this.#drawIDsBuffer[o];
		const g: number = this.#drawIDsBuffer[o + 1];
		const b: number = this.#drawIDsBuffer[o + 2];
		let drawId = r * TWO_POW_16 + g * TWO_POW_8 + b;
		drawId -= 2;
		drawId /= 2;
		return drawId;
	}

	/**
	 * Returns the ID of the CAD part that was rendered at the given
	 * screen coordinate, in pixels. Returns -1 if no CAD part was rendered
	 * at the given pixel.
	 *
	 * @param x X coordinate of pointer, in screen pixels
	 * @param y Y coordinate of pointer, in screen pixels
	 * @returns the Id of the cad part rendered at that pixel, or undefined.
	 */
	drawIdAtPixel(x: number, y: number): number | undefined {
		assert(!this.#drawIDsDirty, "Update draw ids before calling cadPartAtPixel");
		const drawId = this.#drawIDatPixel(x, y);
		if (drawId < 0 || drawId >= this.#cadModel.nodesCount()) return undefined;
		return drawId;
	}

	/**
	 * Resizes the offscreen FBO used by this object
	 *
	 * @param width The new wisth in pixels
	 * @param height The new height in pixels
	 */
	resize(width: number, height: number): void {
		if (width === 0 || height === 0) return;
		this.#drawIDsFbo.dispose();
		this.#drawIDsFbo = EffectPipeline.fboTemplate(width, height);
		this.#drawIDsBuffer = new Uint8Array(4 * width * height);
		this.#drawIDsDirty = true;
		this.#size.set(width, height);
	}

	/** Disposes the GPU resources allocatd by this object */
	dispose(): void {
		this.#drawIDsFbo.dispose();
	}

	/**
	 * @returns the size of the offscreen FBO used by this object
	 */
	get size(): Vector2 {
		return this.#size;
	}
}
