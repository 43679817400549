import {
	Color,
	DoubleSide,
	ShaderLib,
	ShaderMaterial,
	ShaderMaterialParameters,
	Texture,
	Vector2,
	Vector3,
} from "three";
import frag from "../Shaders/LineSegment.frag";
import vert from "../Shaders/LineSegment.vert";
import { makeUniform } from "./Uniforms";

export type LineSegmentMaterialParameters = ShaderMaterialParameters & {
	/** The hald direction of the segment in 3D */
	direction?: Vector3;
	/** The thickness in pixels of the segment*/
	thickness?: number;
	/** The color of the segment */
	diffuse?: Color;
	/** The opacity of the segment */
	opacity?: number;
	/** The optional texture for coloring the segment */
	map?: Texture;
};

/**
 * Material for drawing a segment as a pair of triangles
 */
export class LineSegmentMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		...ShaderLib.basic.uniforms,
		direction: makeUniform(new Vector3()),
		thickness: makeUniform(1),
		diffuse: makeUniform(new Color()),
		opacity: makeUniform(1),
		viewport: makeUniform(new Vector2()),
		map: makeUniform<Texture | null>(null),
	};

	/**
	 * Create a segment renderable.
	 *
	 * @param parameters The input parameters used to initialize the material
	 */
	constructor(parameters: LineSegmentMaterialParameters) {
		super();
		this.type = "LineSegmentMaterial";
		this.side = DoubleSide;
		this.setValues(parameters);
	}

	/** @returns The half direction of the segment */
	get direction(): Vector3 {
		return this.uniforms.direction.value;
	}

	/** Set the half direction of the segment */
	set direction(d: Vector3) {
		this.uniforms.direction.value = d;
		this.uniformsNeedUpdate = true;
	}

	/** @returns The thickness of the line in pixels */
	get thickness(): number {
		return this.uniforms.thickness.value;
	}

	/** Set the thickness of the line in pixels */
	set thickness(t: number) {
		this.uniforms.thickness.value = t;
		this.uniformsNeedUpdate = true;
	}

	/** @returns The color of the segment */
	get diffuse(): Color {
		return this.uniforms.diffuse.value;
	}

	/** Set the color of the segment */
	set diffuse(c: Color) {
		this.uniforms.diffuse.value = c;
		this.uniformsNeedUpdate = true;
	}

	/** @returns The current viewport */
	get viewport(): Vector2 {
		return this.uniforms.viewport.value;
	}

	/** Set the current viewport */
	set viewport(v: Vector2) {
		this.uniforms.viewport.value = v;
		this.uniformsNeedUpdate = true;
	}

	/** @returns The texture */
	get map(): Texture | null {
		return this.uniforms.map.value;
	}

	/** Set the current viewport */
	set map(t: Texture | null) {
		this.uniforms.map.value = t;
		this.uniformsNeedUpdate = true;
	}
}
