import { useLayoutEffect } from "react";

/**
 * Call a specific callback immediately and on an interval, clear the interval on unmount
 *
 * @param callback the callback to call
 * @param timeMs how often to call the callback in milliseconds
 */
export function useInterval(callback: () => void, timeMs: number): void {
  useLayoutEffect(() => {
    // Call the callback one time at first
    callback();
    const id = setInterval(callback, timeMs);
    return () => {
      clearInterval(id);
    };
  }, [callback, timeMs]);
}
