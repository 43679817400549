import { Listener } from "@faro-lotv/foundation";
import { KeyboardEvents } from "@faro-lotv/lotv";
import { useEffect, useState } from "react";
import { useThreeEventTarget } from "./use-three-event-target";

/**
 * @returns A `Lotv.KeyboardEvents` instance attached to the current r3f's context's dom-element,
 * only emitting when the context is in focus.
 *
 * This allows you to access common keyboard-related functionality,
 * such as checking whether a key is currently pressed.
 * @param target The target element to use to intercept keyboard events (defaults to the r3f event target)
 */
export function useKeyboardEvents(target?: HTMLElement): KeyboardEvents {
  const [keyboardEvents] = useState(() => new KeyboardEvents());

  const defaultTarget = useThreeEventTarget();

  useEffect(() => {
    keyboardEvents.attach(target ?? defaultTarget);

    return () => {
      keyboardEvents.detach();
    };
  }, [keyboardEvents, target, defaultTarget]);

  return keyboardEvents;
}

/**
 * Helper to use the keyPressed event of the KeyboardEvent without the useEffect boilerplate
 *
 * @param keyboardEvents The keyboardEvents instance to attach events too (see useKeyboardEvents())
 * @param callback The callback to execute on an keyPressed event
 */
export function useKeyPressedCallback(
  keyboardEvents: KeyboardEvents,
  callback: Listener<KeyboardEvent>,
): void {
  useEffect(() => {
    keyboardEvents.keyPressed.on(callback);

    return () => {
      keyboardEvents.keyPressed.off(callback);
    };
  }, [keyboardEvents, callback]);
}

/**
 * @returns reactive state for whether a particular key is pressed
 * @param keyboardEvents a KeyboardEvents instance to get the information from
 * @param key The key to check (uses the KeyboardEvent.key property)
 */
export function useIsKeyPressed(
  keyboardEvents: KeyboardEvents,
  key: string,
): boolean {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  useEffect(() => {
    function onChange(): void {
      setIsKeyPressed(keyboardEvents.isKeyPressed(key));
    }

    // Initialize the state when dependencies change
    onChange();

    keyboardEvents.keyChanged.on(onChange);
    return () => {
      keyboardEvents.keyChanged.off(onChange);
    };
  }, [keyboardEvents, key]);

  return isKeyPressed;
}
