import { EyeDomePass as LotvEyeDome } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useState } from "react";
import { Camera } from "three";
import { attachPass } from "../attach-utils";

export type EyeDomePassProps = {
  /** The camera to use to compute the effect */
  camera?: Camera;
  /** EDL strength. Clamped to not less than 0 */
  strength?: number;
  /** EDL neighborhood computation radius, in pixels. Clamped to not less than 1 */
  radius?: number;
  /**
   * Threshold in meters used to distinguish wether two neighboring pixels are on the same surface or on a depth discontinuity.
   * This allows to have the option of coloring depth discontinuities with the black outlines or not.
   */
  depthDiscontinuityBias?: number;
  /** Whether the shader shows only the eye dome shade in grayscale, or whether it blends with the original color */
  showShadeOnly?: boolean;
  /** whether the shader shows the black outlines on depth discontinuities or not*/
  showBlackOutlines?: boolean;

  /** true to enable this pass */
  enabled?: boolean;
};

/**
 * @returns A EffectPipeline pass that will apply eye dome to the entire scene
 */
export function EyeDomePass({
  camera,
  strength = 1.0,
  radius = 1.0,
  depthDiscontinuityBias = 0.05,
  showShadeOnly = false,
  showBlackOutlines = false,
  enabled,
}: EyeDomePassProps): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const effectCamera = camera ?? defaultCamera;

  const [pass] = useState(() => new LotvEyeDome(effectCamera));

  return (
    <primitive
      object={pass}
      attach={attachPass}
      camera={effectCamera}
      enabled={enabled}
      // Following props are part of the pass object
      /* eslint-disable react/no-unknown-property */
      strength={strength}
      radius={radius}
      depthDiscontinuityBias={depthDiscontinuityBias}
      showShadeOnly={showShadeOnly}
      showBlackOutlines={showBlackOutlines}
    />
  );
}
