import { FaroStatusText, Option } from "@faro-lotv/flat-ui";
import { PropOptional, validatePrimitive } from "@faro-lotv/foundation";
import {
  AnnotationStatus,
  ExternalMarkupIElement,
  IElementType,
} from "@faro-lotv/ielement-types";
import { isAnnotationStatus } from "@faro-lotv/service-wires";

export type MarkupModelMetaData = {
  /** Color string of the Markup */
  color?: string;

  /** Type of the animation of the Markup */
  nodeAnimationType?: string;

  /**
   * Name of the type of the 3d material of the Markup.
   * This is currently ignored and instead MeshBasicMaterial is used
   */
  nodeMaterialName?: string;

  /**
   * Number of side of the model to render.
   * If equal to 2 it will be rendered with DoubleSide.
   * Otherwise the FrontSide will be rendered
   */
  textureRenderingSides?: number;
};

/**
 * @param metaData object to check
 * @returns true if the metaData passed is a MarkupModelMetaData
 */
export function isMarkupModelMetaData(
  metaData: Record<string, unknown> | null,
): metaData is MarkupModelMetaData {
  return (
    !!metaData &&
    validatePrimitive(metaData, "color", "string", PropOptional) &&
    validatePrimitive(metaData, "nodeAnimationType", "string", PropOptional) &&
    validatePrimitive(metaData, "nodeMaterialName", "string", PropOptional) &&
    validatePrimitive(metaData, "textureRenderingSides", "number", PropOptional)
  );
}

/** List of available options to use for the DropDownMarkupField's value property */
export const STATUS_OPTIONS: Option[] = Object.values(AnnotationStatus)
  .filter((status) => status !== AnnotationStatus.Unclassified)
  .map((status) => ({
    key: status,
    value: status,
    label: FaroStatusText[status],
  }));

/**
 * @returns a display name for an annotation status or undefined if the annotation has no status. Allows any string to be passed,
 * since IElements may contain arbitrary data.
 * @param status The status value out of an IElement
 */
export function getAnnotationStatusDisplayName(
  status: string | null | undefined,
): string | undefined {
  if (status && isAnnotationStatus(status)) {
    return FaroStatusText[status];
  }
  return status ?? undefined;
}

/**
 * @returns returns the provider name for an external markup type
 * @param externalMarkup the type of the external markup iElement
 */
export function getExternalMarkupProviderName(
  externalMarkup?: ExternalMarkupIElement,
): string | undefined {
  if (!externalMarkup) return;

  switch (externalMarkup.type) {
    case IElementType.markupBim360:
      return "Autodesk BIM 360";
    case IElementType.markupProcoreRfi:
      return "Procore RFI";
    case IElementType.markupProcoreObservation:
      return "Procore Observation";
    case IElementType.markupAccIssue:
      return "ACC Issue";
    case IElementType.markupAccRfi:
      return "ACC RFI";
  }

  return "External Provider";
}
