import { Vector3, Object3D, Camera } from "three";

// Vector to reuse (so that we don't create a new instance every time) in the function to calculate the position.
const v1 = new Vector3();

/**
 * Computes the screen position, in pixels, of a given 3D object seen from a given camera
 * and a viewport of a given pixel size.The position is rounded to the closest integer value.
 * This is useful when displaying html elements that move together with a given 3D position,
 * therefore the result is rounded to avoid HTML boxes, borders and text to become blurry.
 *
 * @param el The object 3D whose screen coordinates need to be computed. El may also be the 3D position of the object.
 * @param camera The camera that is rendering the object
 * @param size The viewport resolution in pixels
 * @param size.width Viewport width
 * @param size.height Viewport height
 * @returns the pixel position of the 3D object in the given viewport, rounded to the closest integer position.
 */
export function roundedCalculatePosition(
  el: Object3D | Vector3,
  camera: Camera,
  size: { width: number; height: number },
): [number, number] {
  // get object position in world coordinates
  const objectPos =
    el instanceof Object3D
      ? v1.setFromMatrixPosition(el.matrixWorld)
      : v1.copy(el);
  // compute object position in clip coordinates
  objectPos.project(camera);
  // compute object position in screen coordinates, rounding the result.
  const widthHalf = size.width / 2;
  const heightHalf = size.height / 2;

  const x = Math.round(objectPos.x * widthHalf + widthHalf);
  const y = Math.round(-(objectPos.y * heightHalf) + heightHalf);
  return [x, y];
}
