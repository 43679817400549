import {
  CROSSHAIR_CENTER,
  CrosshairMarkerBlue,
  CrosshairMarkerOrange,
  CrosshairWithCircle,
} from "@faro-lotv/flat-ui";
import { pixels2m } from "@faro-lotv/lotv";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { MOUSE, Mesh, Vector2, Vector3, Vector3Tuple } from "three";
import { useThreeEventTarget, useViewportRef } from "../hooks";
import { useSvg } from "../hooks/use-svg";
import { PinSprite } from "../utils/pin-sprite";
import { MOUSE_BUTTONS, eventWithTarget } from "./two-point-alignment";

export type AnchorPairPlacementProps = PropsWithChildren & {
  // Callback for when the pointer is pressed down on a handle
  onPointerDown?(event: ThreeEvent<PointerEvent>): void;
  // Callback for when a handle is released
  onPointerUp?(event: ThreeEvent<PointerEvent>): void;

  // initial anchor1 position in W.C.S in meter
  anchor1Position?: Vector3Tuple;

  // initial anchor2 position in W.C.S in meter
  anchor2Position?: Vector3Tuple;

  // Callback to change/set anchor1 position
  changeAnchor1Position(newPosition: Vector3Tuple | undefined): void;

  // Callback to change/set anchor2 position
  changeAnchor2Position(newPosition: Vector3Tuple | undefined): void;
};

// The sprite's anchor point for crosshair, and the point around which the sprite rotates.
const SPRITE_CENTER = new Vector2(0.5, 0.5);
/**
 * The component that implements anchor point positioning and dragging.
 * Up to two anchor points can be placed in the scene.
 * An invisible fullScreenQuad is used as background object. An anchor point is added or removed
 * by setting the corresponding pin object visible or invisible.
 *
 * @returns The React object that contains the invisible fullScreenQuad as background, 2 object3D of PinSprite, plus passed children objects
 */
export function AnchorPairPlacement({
  onPointerDown,
  onPointerUp,
  anchor1Position,
  anchor2Position,
  changeAnchor1Position,
  changeAnchor2Position,
  children,
}: AnchorPairPlacementProps): JSX.Element {
  const pinTexture1 = useSvg(CrosshairMarkerBlue, 90, 90);
  const pinTexture2 = useSvg(CrosshairMarkerOrange, 90, 90);

  // A bunch of references to the objects and data for real time manipulation.

  /** Reference to the first pin */
  const pin1Ref = useRef<Mesh>(null);
  /** Reference to the second pin */
  const pin2Ref = useRef<Mesh>(null);
  /** 2D coordinates on screen, used to discriminate between a click and a drag */
  const clientCoords = useRef<Vector2>(new Vector2());
  /** The current target for a dragging action: it can be pin1 or pin2 */
  const dragTarget = useRef<unknown>(null);

  /** An object storing temporary data to avoid reallocations */
  const TEMPVector = useMemo(() => new Vector3(), []);

  const camera = useThree((s) => s.camera);

  const domElement = useThreeEventTarget();
  const setCrosshairCursor = useCallback(
    (crosshair: boolean) => {
      if (crosshair) {
        domElement.style.cursor = `url("${CrosshairWithCircle}") ${CROSSHAIR_CENTER} ${CROSSHAIR_CENTER}, auto`;
      } else {
        domElement.style.cursor = "default";
      }
    },
    [domElement.style],
  );

  // Keep track of the element on which the dragging event is happening
  const startDrag = useCallback((e: ThreeEvent<PointerEvent>) => {
    if (!dragTarget.current) dragTarget.current = e.eventObject;
  }, []);

  // Reset the drag element
  const endDrag = useCallback(() => {
    if (!dragTarget.current) return;
    dragTarget.current = null;
  }, []);

  // Compute the 3D coordinates of a point picked, while forcing the Y
  // coordinate to always be on the near plane
  const computeEventPoint = useCallback(
    (e: ThreeEvent<PointerEvent> | ThreeEvent<MouseEvent>, p: Vector3) => {
      return p.copy(e.unprojectedPoint).setY(camera.position.y - camera.near);
    },
    [camera],
  );

  // Translate pin to the new position
  const pinDrag = useCallback(
    (e: ThreeEvent<PointerEvent>): void => {
      if (
        dragTarget.current !== pin1Ref.current &&
        dragTarget.current !== pin2Ref.current
      ) {
        return;
      }
      const newPos = computeEventPoint(e, TEMPVector);
      if (dragTarget.current === pin1Ref.current && pin1Ref.current?.visible) {
        pin1Ref.current.position.copy(newPos);
        changeAnchor1Position(newPos.toArray());
      }
      if (dragTarget.current === pin2Ref.current && pin2Ref.current?.visible) {
        pin2Ref.current.position.copy(newPos);
        changeAnchor2Position(newPos.toArray());
      }
    },
    [
      computeEventPoint,
      TEMPVector,
      changeAnchor1Position,
      changeAnchor2Position,
    ],
  );

  // Create a new pin (if possible)
  const tryDropPin = useCallback(
    (position: Vector3): void => {
      // Drop pin 1 if it is not visible, otherwise drop pin 2
      if (pin1Ref.current?.visible === false) {
        pin1Ref.current.position.copy(position);
        pin1Ref.current.visible = true;
        changeAnchor1Position(position.toArray());
      } else if (pin2Ref.current?.visible === false) {
        pin2Ref.current.position.copy(position);
        pin2Ref.current.visible = true;
        changeAnchor2Position(position.toArray());
      }
      setCrosshairCursor(false);
    },
    [changeAnchor1Position, changeAnchor2Position, setCrosshairCursor],
  );

  // Attempt to drop a pin
  const onDropPin = useCallback(
    (e: ThreeEvent<MouseEvent>): void => {
      // Don't drop pins on a drag (if we moved the mouse since mouse down)
      if (
        Math.abs(e.clientX - clientCoords.current.x) <= 2 &&
        Math.abs(e.clientY - clientCoords.current.y) <= 2
      ) {
        const pinPosition = computeEventPoint(e, TEMPVector);
        tryDropPin(pinPosition);
      }
    },
    [computeEventPoint, tryDropPin, TEMPVector],
  );

  // Handle pointer down on either pin, used to capture the initial state before dragging starts.
  const onPinPointerDown = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (e.nativeEvent.button === MOUSE.LEFT && e.eventObject.visible) {
        e.stopPropagation();

        onPointerDown?.(e);

        if (eventWithTarget(e)) {
          e.target.setPointerCapture(e.nativeEvent.pointerId);
        }
        dragTarget.current = undefined;
      }
    },
    [onPointerDown],
  );

  // Handle pointer up on either pin
  const onPinPointerUp = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      onPointerUp?.(e);
      e.stopPropagation();

      if (eventWithTarget(e)) {
        e.target.releasePointerCapture(e.nativeEvent.pointerId);
      }

      if (!e.eventObject.visible) {
        return;
      }
      endDrag();
    },
    [endDrag, onPointerUp],
  );

  const removePin = useCallback(
    (e: ThreeEvent<MouseEvent>) => {
      if (!e.eventObject.visible) return;
      e.eventObject.visible = false;
      if (e.eventObject === pin1Ref.current) {
        changeAnchor1Position(undefined);
      } else if (e.eventObject === pin2Ref.current) {
        changeAnchor2Position(undefined);
      }
      e.stopPropagation();
      setCrosshairCursor(true);
    },
    [changeAnchor1Position, changeAnchor2Position, setCrosshairCursor],
  );

  // Handle pointer drag on pin 1
  const onPin1PointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (pin1Ref.current?.visible) setCrosshairCursor(false);
      startDrag(e);
      e.stopPropagation();
      if (
        e.nativeEvent.buttons === MOUSE_BUTTONS.PRIMARY &&
        pin1Ref.current?.visible &&
        dragTarget.current === pin1Ref.current
      ) {
        pinDrag(e);
      }
    },
    [setCrosshairCursor, startDrag, pinDrag],
  );

  // Handle pointer drag on pin 2
  const onPin2PointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (pin2Ref.current?.visible) setCrosshairCursor(false);
      startDrag(e);
      e.stopPropagation();
      if (
        e.nativeEvent.buttons === MOUSE_BUTTONS.PRIMARY &&
        pin2Ref.current?.visible &&
        dragTarget.current === pin2Ref.current
      ) {
        pinDrag(e);
      }
    },
    [pinDrag, setCrosshairCursor, startDrag],
  );

  // Handle pointer down on the background plane
  const onBackgroundPointerDown = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (e.nativeEvent.button !== MOUSE.LEFT) return;
      clientCoords.current.set(e.clientX, e.clientY);
      dragTarget.current = undefined;

      onPointerDown?.(e);

      e.stopPropagation();
    },
    [onPointerDown],
  );

  // Handle pointer up on the background plane potentially dropping a pin
  const onBackgroundPointerUp = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (e.nativeEvent.button !== MOUSE.LEFT) return;
      onPointerUp?.(e);
      e.stopPropagation();
    },
    [onPointerUp],
  );

  // Handle pointer move on the background plane
  const onBackgroundPointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      e.stopPropagation();
      if (pin1Ref.current?.visible && pin2Ref.current?.visible) {
        setCrosshairCursor(false);
      } else {
        setCrosshairCursor(true);
      }
    },
    [setCrosshairCursor],
  );

  // Get the correct viewport size if we're in a View
  const viewport = useViewportRef();
  const pinSize = 90;
  const opacity = 1;

  // Update the pin scale at every frame to keep it in pixel size
  useFrame(({ size, camera }) => {
    if (pin1Ref.current && pin2Ref.current) {
      // Compute a scale factor to account from the distance to the camera
      // to keep the pins at a fixed pixel size
      const height = viewport.current?.height ?? size.height;
      const dist = pin1Ref.current
        .getWorldPosition(TEMPVector)
        .distanceTo(camera.position);
      const camDistanceScaleFactor = pixels2m(pinSize, camera, height, dist);

      // Compute the pin scale taking into account the scale applied to the entire group
      const scale =
        pin1Ref.current.parent?.getWorldScale(TEMPVector) ??
        TEMPVector.set(1, 1, 1);
      pin1Ref.current.scale
        .set(1 / scale.x, 1 / scale.y, 1 / scale.z)
        .multiplyScalar(camDistanceScaleFactor);
      pin2Ref.current.scale.copy(pin1Ref.current.scale);
    }
  }, 0);

  return (
    <group>
      {children}
      <fullScreenQuad
        visible={false}
        onPointerDown={onBackgroundPointerDown}
        onPointerUp={onBackgroundPointerUp}
        onPointerMove={onBackgroundPointerMove}
        onClick={onDropPin}
      />
      <object3D
        ref={pin1Ref}
        visible={anchor1Position !== undefined}
        onPointerDown={onPinPointerDown}
        onPointerUp={onPinPointerUp}
        onPointerMove={onPin1PointerMove}
        onContextMenu={removePin}
        name="Pin1"
        position={anchor1Position}
      >
        <PinSprite
          opacity={opacity}
          texture={pinTexture1}
          center={SPRITE_CENTER}
        />
      </object3D>
      <object3D
        ref={pin2Ref}
        visible={anchor2Position !== undefined}
        onPointerDown={onPinPointerDown}
        onPointerUp={onPinPointerUp}
        onPointerMove={onPin2PointerMove}
        onContextMenu={removePin}
        name="Pin2"
        position={anchor2Position}
      >
        <PinSprite
          opacity={opacity}
          texture={pinTexture2}
          center={SPRITE_CENTER}
        />
      </object3D>
    </group>
  );
}
