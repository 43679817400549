import { MutableRefObject } from "react";
import { Vector3 } from "three";
import { useInterval } from "../hooks/use-interval";

/** How often in ms to update the sub sampling */
const SUB_SAMPLING_UPDATE_INTERVAL = 200;

/** State information passed to the computeHiddenPlaceholders function */
export type ComputeHiddenPlaceholdersState = {
  /** The previously hidden placeholders */
  previousHidden: number[];

  /** All possible positions */
  positions: Vector3[];

  /** The index of the currently selected placeholder */
  selectedIndex?: number;

  /** True if the points have changed from the last computation */
  havePointsChanged: boolean;
};

/** A function used to compute the hidden placeholders */
export type HiddenWaypointsComputer = (
  params: ComputeHiddenPlaceholdersState,
) => number[];

export type ObjectWithHiddenWaypoints = {
  hidden: number[];
};

/** Props for the useHideWaypoints hook */
export type UseHideWaypointsProps = {
  /** The render object with hidden waypoints */
  waypointsObject: ObjectWithHiddenWaypoints | null;

  /** The function to compute the hidden waypoints */
  updateHiddenWaypoints: HiddenWaypointsComputer | undefined;

  /** List of all the waypoints */
  allWaypoints: Vector3[];

  /** Index of the selected waypoint if available  */
  selectedIndex: number | undefined;

  /** A flag to check if the points have changed from the last update */
  havePointsChanged: MutableRefObject<boolean>;
};

/** An hook to update the list of hidden waypoints */
export function useHideWaypoints({
  waypointsObject,
  updateHiddenWaypoints,
  allWaypoints,
  selectedIndex,
  havePointsChanged,
}: UseHideWaypointsProps): void {
  // Compute which placeholders should be hidden
  useInterval(() => {
    if (waypointsObject?.hidden) {
      if (updateHiddenWaypoints) {
        waypointsObject.hidden = updateHiddenWaypoints({
          previousHidden: waypointsObject.hidden,
          positions: allWaypoints,
          selectedIndex,
          havePointsChanged: havePointsChanged.current,
        });
        havePointsChanged.current = false;
      } else {
        waypointsObject.hidden = [];
      }
    }
  }, SUB_SAMPLING_UPDATE_INTERVAL);
}
