uniform vec3 diffuse;
uniform float opacity;
uniform float linewidth;

#ifdef USE_DASH

	uniform float dashOffset;
	uniform float dashSize;
	uniform float gapSize;

#endif

varying float vLineDistance;

#ifdef WORLD_UNITS

	varying vec4 worldPos;
	varying vec3 worldStart;
	varying vec3 worldEnd;

	#ifdef USE_DASH

		varying vec2 vUv;

	#endif

#else

	varying vec2 vUv;

#endif

#include <common>
#include <color_pars_fragment>
#include <fog_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

vec2 closestLineToLine(vec3 p1, vec3 p2, vec3 p3, vec3 p4) {

	float mua;
	float mub;

	vec3 p13 = p1 - p3;
	vec3 p43 = p4 - p3;

	vec3 p21 = p2 - p1;

	float d1343 = dot( p13, p43 );
	float d4321 = dot( p43, p21 );
	float d1321 = dot( p13, p21 );
	float d4343 = dot( p43, p43 );
	float d2121 = dot( p21, p21 );

	float denom = d2121 * d4343 - d4321 * d4321;

	float numer = d1343 * d4321 - d1321 * d4343;

	mua = numer / denom;
	mua = clamp( mua, 0.0, 1.0 );
	mub = ( d1343 + d4321 * ( mua ) ) / d4343;
	mub = clamp( mub, 0.0, 1.0 );

	return vec2( mua, mub );

}

void main() {

	#include <clipping_planes_fragment>

	#ifdef USE_DASH

		if ( vUv.y < - 1.0 || vUv.y > 1.0 ) discard; // discard endcaps

		if ( mod( vLineDistance + dashOffset, dashSize + gapSize ) > dashSize ) discard; // todo - FIX

	#endif

	float alpha = opacity;

	#ifdef WORLD_UNITS

		// Find the closest points on the view ray and the line segment
		vec3 rayEnd = normalize( worldPos.xyz ) * 1e5;
		vec3 lineDir = worldEnd - worldStart;
		vec2 params = closestLineToLine( worldStart, worldEnd, vec3( 0.0, 0.0, 0.0 ), rayEnd );

		vec3 p1 = worldStart + lineDir * params.x;
		vec3 p2 = rayEnd * params.y;
		vec3 delta = p1 - p2;
		float len = length( delta );
		float norm = len / linewidth;

		#ifndef USE_DASH

			#ifdef USE_ALPHA_TO_COVERAGE

				float dnorm = fwidth( norm );
				alpha = 1.0 - smoothstep( 0.5 - dnorm, 0.5 + dnorm, norm );

			#else

				if ( norm > 0.5 ) {

					discard;

				}

			#endif

		#endif

	#else

		/** LOTV changes start here*/

		#ifndef USE_ALPHA_TO_COVERAGE

			// vUv are the coordinates of the current fragment inside the line quad,
			// ranging inside [-1 1]. vUv.x describes how far is the fragment along the
			// line perpendicular, vUv.y describes how far is the fragment along the line distance.

			// The relative portion of the line that is completely opaque
			float notBlendedLength = 0.7;
			// The relative portion of the line that is faded to transparent for anti-aliasing
			float blendedLength = 1.0 - notBlendedLength;

			// The distance that the fragement is from the completely opaque part of the line
			float fragAbsBlendDistance = max(abs(vUv.x) - notBlendedLength, 0.0);
			// The relative position of the fragement on the part that has to be blended
			float fragRelBlendDistance = min(fragAbsBlendDistance / blendedLength, 1.0);

			// smoothStep makes a nicer transition than linear
			// Also consider the total opacity of the line
			alpha = opacity * smoothstep(0.0, 1.0, 1.0 - fragRelBlendDistance);

			if ( abs( vUv.y ) > 1.0 ) {
				// if we enter here, it means that the fragment is before the start
				// of the ideal line or after the end of the ideal line. The fragment belongs
				// to the rounded start or end, so we just blend it according to how far is
				// from the ideal line.

				float a = vUv.x;
				float b = ( vUv.y > 0.0 ) ? vUv.y - 1.0 : vUv.y + 1.0;
				float len2 = a * a + b * b;

				if ( len2 > 1.0 ) discard;

				alpha *= 1.0-len2;
			}

		#endif

		/** LOTV changes end here*/

	#endif

	vec4 diffuseColor = vec4( diffuse, alpha );

	#include <logdepthbuf_fragment>
	#include <color_fragment>

	gl_FragColor = vec4( diffuseColor.rgb, alpha );

	#include <tonemapping_fragment>
	#include <colorspace_fragment>
	#include <fog_fragment>
	#include <premultiplied_alpha_fragment>

}
