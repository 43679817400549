import { assert } from "@faro-lotv/foundation";
import { ColorString } from "../components";

// Value used to convert RGB values to hexadecimal format
const MAX_COLOR_VALUE = 255;

// Length (bits) of a color string in hexadecimal format
const COLOR_STRING_LENGTH = 24;

// Regular expression to validate hexadecimal color strings
const HEX_REGEX = /^#[0-9A-Fa-f]{6}$/;

/**
 * Interpolates between two colors based on a given value.
 *
 * @param firstColor The first color in hexadecimal format.
 * @param secondColor The second color in hexadecimal format.
 * @param value The value between 0 and 1 representing the interpolation point.
 * @returns The interpolated color as a hexadecimal string.
 */
export function interpolateColor(
  firstColor: ColorString,
  secondColor: ColorString,
  value: number,
): ColorString | undefined {
  assert(
    firstColor.match(HEX_REGEX),
    "First color must be a valid hexadecimal color string",
  );
  assert(
    secondColor.match(HEX_REGEX),
    "Second color must be a valid hexadecimal color string",
  );
  assert(value >= 0 && value <= 1, "Value must be between 0 and 1");

  // Remove the # from the string and convert number from hex to decimal
  const startRGB = parseInt(firstColor.slice(1), 16);
  // Each hex digit is 4 bits, so we shift the bits to the right by 16 (4 hex values) to get the red component (remaining 2 hex values)
  const startR = startRGB >> 16;
  // Now we shift the bits to the right by 8 (2 hex values),
  // so that only the green and red components are left in the number,
  // and use the bitwise AND operator with 255 (0xFF) to get the first two values (2 hex) of the remaining bits (green component)
  const startG = (startRGB >> 8) & MAX_COLOR_VALUE;
  // Use the bitwise AND operator with 255 (0xFF) to get the first two values (2 hex) (blue component)
  const startB = startRGB & MAX_COLOR_VALUE;

  const endRGB = parseInt(secondColor.slice(1), 16);
  const endR = endRGB >> 16;
  const endG = (endRGB >> 8) & MAX_COLOR_VALUE;
  const endB = endRGB & MAX_COLOR_VALUE;

  // Interpolate the red, green, and blue components separately
  const r = Math.round(startR + (endR - startR) * value);
  const g = Math.round(startG + (endG - startG) * value);
  const b = Math.round(startB + (endB - startB) * value);

  // Convert the interpolated RGB values back to a hex color string
  // Place the red in the first 2 hex values, green in the next 2, and blue in the last 2
  // 1 << 24 adds a one to the 25th bit, to make sure the number is at least 24 bits long (6 hex values),
  // so that the leading zeros are not removed.
  // The leading 1 is then removed with the slice method, and the number is converted to a hexadecimal string.
  return `#${((1 << COLOR_STRING_LENGTH) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}
