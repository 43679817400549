precision highp float;

out vec4 outColor;

// Optional background FBO to compose on
uniform sampler2D uColorTex0;
uniform sampler2D uDepthTex0;

// Input FBO 1
uniform sampler2D uColorTex1;
uniform sampler2D uDepthTex1;
uniform float opacity1;

// Input FBO 2
uniform sampler2D uColorTex2;
uniform sampler2D uDepthTex2;
uniform float opacity2;

// Input FBO 3
uniform sampler2D uColorTex3;
uniform sampler2D uDepthTex3;
uniform float opacity3;

uniform bool uOverrideBackground;
uniform vec4 uBackground;

uniform float uMinOpacity;

in vec2 vUv;

void main()
{
    // Step 1: read the four input (color, opacity, depth) values
    float depths[6] = float[6](0.0, 0.0, -1.0, 0.0, 0.0, -1.0);
    vec4 colorsIn[4] = vec4[4](vec4(0.0), vec4(0.0), vec4(0.0), vec4(0.0));

    depths[0] = uOverrideBackground ? 1.0 : texture(uDepthTex0, vUv).s;
    colorsIn[0] = uOverrideBackground ? uBackground : texture(uColorTex0, vUv);

    depths[1] = opacity1 > uMinOpacity ? texture(uDepthTex1, vUv).s : 1.0 ;
    colorsIn[1] = opacity1 > uMinOpacity ? texture(uColorTex1, vUv) : vec4(0.0);

    depths[3] = opacity2 > uMinOpacity ? texture(uDepthTex2, vUv).s : 1.0;
    colorsIn[2] = opacity2 > uMinOpacity ? texture(uColorTex2, vUv) : vec4(0.0);

    depths[4] = opacity3 > uMinOpacity ? texture(uDepthTex3, vUv).s : 1.0;
    colorsIn[3] = opacity3 > uMinOpacity ? texture(uColorTex3, vUv) : vec4(0.0);

    // Step 2: account for user-set opacity values
    colorsIn[1].a *= opacity1;
    colorsIn[2].a *= opacity2;
    colorsIn[3].a *= opacity3;

    // Step 3: blend the four input colors, in the correct depth order.
    // Colors should be blended from the farthest to the closest with the 
    // standard back-to-front blending equation.

    // Step 3a: sort the four pixels from farthest to closest, with an
    // unrolled loop of merge sort:
    float tempDepth = 0.0;
    vec4 tempCol = vec4(0.0);

    // ensure that depths[0] and colorsIn[0] contain the farthest pixels between fbo 0 and 1
    if(depths[0] < depths[1]) {
        tempDepth = depths[0]; depths[0] = depths[1]; depths[1] = tempDepth;
        tempCol = colorsIn[0]; colorsIn[0] = colorsIn[1]; colorsIn[1] = tempCol;
    }

    // ensure that depths[3] and colorsIn[2] contain the farthest pixels between fbo 2 and 3
    if(depths[3] < depths[4]) {
        tempDepth = depths[3]; depths[3] = depths[4]; depths[4] = tempDepth;
        tempCol = colorsIn[2]; colorsIn[2] = colorsIn[3]; colorsIn[3] = tempCol;
    }

    // Now the vectors 'colorsIn[0 - 1]' and 'colorsIn[2 - 3]' are sorted. Merge them into 'colors'
    int a = 0;  // 'a' is the index inside the first two elements of 'colorsIn'
    int b = 0;  // 'b' is the index inside the last two elements of 'colorsIn'

    vec4 colors[4] = vec4[4](vec4(0.0), vec4(0.0), vec4(0.0), vec4(0.0));

    if(depths[a] < depths[3 + b]) {
        colors[0] = colorsIn[2 + b];
        b++;
    } else {
        colors[0] = colorsIn[a];
        a++;
    }

    if(depths[a] < depths[3 + b]) {
        colors[1] = colorsIn[2 + b];
        b++;
    } else {
        colors[1] = colorsIn[a];
        a++;
    }

    if(depths[a] < depths[3 + b]) {
        colors[2] = colorsIn[2 + b];
        b++;
    } else {
        colors[2] = colorsIn[a];
        a++;
    }

    if(depths[a] < depths[3 + b]) {
        colors[3] = colorsIn[2 + b];
        gl_FragDepth = depths[3 + b];
        b++;
    } else {
        colors[3] = colorsIn[a];
        gl_FragDepth = depths[a];
        a++;
    }

    // Step 3b: Blend the four input colors in the correct order, with the back-to-front blending equation.
    outColor = colors[0];

    float oneMinusAlpha = 1.0 - colors[1].a;
    outColor = vec4(colors[1].a * colors[1].rgb + outColor.rgb * oneMinusAlpha, colors[1].a + oneMinusAlpha * outColor.a);

    oneMinusAlpha = 1.0 - colors[2].a;
    outColor = vec4(colors[2].a * colors[2].rgb + outColor.rgb * oneMinusAlpha, colors[2].a + oneMinusAlpha * outColor.a);

    oneMinusAlpha = 1.0 - colors[3].a;
    outColor = vec4(colors[3].a * colors[3].rgb + outColor.rgb * oneMinusAlpha, colors[3].a + oneMinusAlpha * outColor.a);
}
