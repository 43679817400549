import {
  IElementGenericImgSheet,
  isIElementImgSheetTiled,
} from "@faro-lotv/ielement-types";
import { Matrix4, Vector3, Vector3Tuple } from "three";

/**
 * @param sheet to compute the size of
 * @param scale of the sheet
 * @returns the width/height of the sheet
 */
export function getSheetSize(
  sheet: IElementGenericImgSheet,
  scale: Vector3Tuple = [1, 1, 1],
): { width: number; height: number } {
  if (isIElementImgSheetTiled(sheet)) {
    return {
      width: 100 * scale[0],
      height: 100 * scale[1],
    };
  }
  return {
    width: (sheet.size?.x ?? 1) * scale[0],
    height: (sheet.size?.z ?? 1) * scale[1],
  };
}

/**
 * @param sheet to compute the center of
 * @param pose of the sheet
 * @returns the 3d position of the sheet center
 */
export function getSheetCenter(
  sheet: IElementGenericImgSheet,
  pose: Matrix4,
): Vector3 {
  const { width, height } = getSheetSize(sheet);
  return new Vector3(width / 2, height / 2, 0).applyMatrix4(pose);
}

/**
 * @param sheet to compute the diagonal for
 * @param scale of the sheet
 * @returns the sheet diagonal length
 */
export function getSheetDiagonal(
  sheet: IElementGenericImgSheet,
  scale: Vector3Tuple = [1, 1, 1],
): number {
  const { width, height } = getSheetSize(sheet, scale);

  return Math.sqrt(width * width + height * height);
}
