/**
 * Quick algorithm to compute difference between two sorted arrays.
 * Precondition: A and B are sorted from smallest to greatest.
 * The complexity is linear in the total length of A and B.
 *
 * @param A The first sorted array
 * @param B The second sorted array
 * @returns Two sorted arrays, the first has the elements present in A and not in B, the second has the elements present in B and not in A
 */
export function computeArrayDifferences(A: number[], B: number[]): { AminusB: number[]; BminusA: number[] } {
	let AminusB = new Array<number>();
	let BminusA = new Array<number>();
	if (B.length === 0) {
		AminusB = A;
	} else if (A.length === 0) {
		BminusA = B;
	} else {
		// since the two lists of nodes are ordered, we simply sweep through the lists.
		let Aid = 0;
		let Bid = 0;
		while (Aid < A.length || Bid < B.length) {
			if (Bid >= B.length) {
				AminusB.push(A[Aid]);
				Aid++;
			} else if (Aid >= A.length) {
				BminusA.push(B[Bid]);
				Bid++;
			} else {
				const a = A[Aid];
				const b = B[Bid];
				if (a === b) {
					Aid++;
					Bid++;
				} else if (b < a) {
					BminusA.push(b);
					Bid++;
				} else {
					// if we enter here, a < b
					AminusB.push(a);
					Aid++;
				}
			}
		}
	}
	return { AminusB, BminusA };
}

/**
 *
 * @param a The first array
 * @param b The second array
 * @returns True iff arrays a and b are equal in size and elements.
 */
export function equals(a: number[], b: number[]): boolean {
	return a.length === b.length && a.every((v, i) => v === b[i]);
}

/**
 * Returns the index of the lower bound of 'v' in the sorted array 'A'. In other words,
 * returns the index of the first element in A that is not less (i.e. greater or equal) than v.
 * If all elements of A are strictly less than v, the function returns A.length.
 * Precondition: A is sorted from smallest to greatest.
 * Complexity: log(A.length)
 *
 * @param A The sorted array to perform binary search onto
 * @param v The value to search for
 * @returns The index of the first element in A that is not less (i.e. greater or equal) than v, or A.length if no
 * such element exists
 */
export function lowerBound(A: number[], v: number): number {
	let count = A.length;
	let step = 0;
	let first = 0;
	let pos = 0;
	while (count > 0) {
		step = Math.floor(count / 2);
		pos = first + step;
		if (A[pos] < v) {
			first = pos + 1;
			count -= step + 1;
		} else {
			count = step;
		}
	}
	return first;
}

/**
 *
 * @param elements A list of T elements, sorted according to 'isLess'
 * @param v The value to search for
 * @param isLess A comparison predicate that returns true when the first argument is 'less' than the second.
 * @returns The index of the first element in 'elements' that is not less (i.e. greater or equal) than v, or
 * elements.length if no such element exists
 */
export function lowerBoundTyped<T>(elements: T[], v: number, isLess: (t: T, n: number) => boolean): number {
	let count = elements.length;
	let step = 0;
	let first = 0;
	let pos = 0;
	while (count > 0) {
		step = Math.floor(count / 2);
		pos = first + step;
		if (isLess(elements[pos], v)) {
			first = pos + 1;
			count -= step + 1;
		} else {
			count = step;
		}
	}
	return first;
}

/**
 * Returns the index of value v in the sorted array A, or -1 if v is not in A.
 * This function assumes that A is sorted and therfore binary search can be performed, the
 * performance is therefore better than a linear search.
 * Precondition: A is sorted from smallest to greatest.
 * Complexity: log(A.length)
 *
 * @param A The sorted array to perform binary search onto.
 * @param v The value to search for.
 * @returns The index of value v in the sorted array A, or undefined if v is not in A.
 */
export function indexOf(A: number[], v: number): number | undefined {
	const idx = lowerBound(A, v);
	return idx < A.length && A[idx] === v ? idx : undefined;
}

/**
 * Returns whether array A contains value v. This function assumes that A is sorted
 * from bottom to top and therefore fast binary search for v can be used.
 * Precondition: A is sorted from bottom to top
 * Complexity: log(A.length);
 *
 * @param A The sorted array to perform binary search onto.
 * @param v The value to search for.
 * @returns True if A contains v, false otherwise.
 */
export function includes(A: number[], v: number): boolean {
	const idx = lowerBound(A, v);
	return idx < A.length && A[idx] === v;
}

/**
 * Returns whether the array A is sorted from smallest to greatest.
 *
 * @param A The array of numbers to be checked for ordering
 * @returns Whether A is sorted from bottom to top.
 */
export function isSorted(A: number[]): boolean {
	if (A.length < 2) {
		return true;
	}
	for (let i = 0; i < A.length - 1; ++i) {
		if (A[i] > A[i + 1]) {
			return false;
		}
	}
	return true;
}

/**
 * Create an array filled with data from start to end
 *
 * @param start the first number in the array
 * @param end the last number in the array
 * @returns the array with the numbers start-end
 */
export function range(start: number, end: number): number[] {
	return new Array<number>(end - start + 1).map((_, idx) => start + idx);
}

/**
 * Removes all duplicate elements from the sorted array A.
 *
 * @param A the sorted input array, sorted from smallest to greatest
 * @returns the input array without duplicates.
 */
export function removeDuplicates(A: number[]): number[] {
	const r: number[] = [];
	if (A.length === 0) {
		return r;
	}
	r.push(A[0]);
	// let duplicates = 0;
	for (let i = 1; i < A.length; ++i) {
		if (A[i - 1] !== A[i]) {
			r.push(A[i]);
		}
	}
	return r;
}
