varying vec2 vUv;

uniform sampler2D uColorTexture;
uniform sampler2D uDepthTexture;

uniform bool showPlanes;

const int k_Horizontal = 15;
const int k_LeaningBL = 14;
const int k_LeaningBR = 13;
const int k_LeaningB = 12;
const int k_LeaningTL = 11;
const int k_LeaningL = 10;
const int k_SaddleTR = 9;
const int k_LeaningHardBL = 8;
const int k_LeaningTR = 7;
const int k_SaddleTL = 6;
const int k_LeaningR = 5;
const int k_LeaningHardBR = 4;
const int k_LeaningT = 3;
const int k_LeaningHardTL =2;
const int k_LeaningHardTR = 1;
const int k_EmptyPix = 0;

void main() {
	gl_FragDepth = texture(uDepthTexture, vUv).r;
    vec4 tf = texture(uColorTexture, vUv);
    vec3 c = vec3(0.0, 0.0, 1.0);
    if(showPlanes) {
        int planeLabel = int(tf.a);
        switch(planeLabel) {
            case k_LeaningBL: 
            case k_LeaningHardBL: c = vec3(-0.577, -0.577, 0.577) * 0.5 + 0.5; break;
            case k_LeaningB: c = vec3(0.0, -0.707, 0.707) * 0.5 + 0.5; break;
            case k_LeaningBR: 
            case k_LeaningHardBR: c = vec3(0.577, -0.577, 0.577) * 0.5 + 0.5; break;
            case k_LeaningR: c = vec3(0.707, 0.0, 0.707) * 0.5 + 0.5; break;
            case k_LeaningTR: 
            case k_LeaningHardTR: c = vec3(0.577, 0.577, 0.577) * 0.5 + 0.5; break;
            case k_LeaningT: c = vec3(0.0, 0.707, 0.707) * 0.5 + 0.5; break;
            case k_LeaningTL: 
            case k_LeaningHardTL: c = vec3(-0.577, 0.577, 0.577) * 0.5 + 0.5; break;
            case k_LeaningL: c = vec3(-0.707, 0.0, 0.707) * 0.5 + 0.5; break;
            case k_EmptyPix: c = tf.rgb; break;
        }
    }
    else {
        c = tf.rgb;
    }
	gl_FragColor = vec4(c, 1.0);
}
