import { Object3D } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { CadModel } from "./CadModel";
import { adjustTexturesEncoding } from "./CadModelPrivateUtils";
import { ICadModel } from "./ICadModel";
import { loadStreamCadModelChunks } from "./StreamCadChunk";
import { StreamCadModel } from "./StreamCadModel";

/**
 * Returns whether the given object is a Cad model
 *
 * @param o The object to check for the type
 * @returns Whether o is a CadModel
 */
export function isCadModel(o: Object3D | null): o is ICadModel {
	return o !== null && (o instanceof CadModel || o instanceof StreamCadModel);
}

/**
 * Loads a CAD model given an URL and a renderer
 *
 * This function creates a CAD model given the URL of a GLTF file. The returned object
 * provides better rendering performance thanpassing the plain threeJS scene graph to the threeJS renderer.
 *
 * @param url The URL pointing to a valid GLTF model.
 * @returns a CadModel object optimized for high-performance cad rendering.
 */
export async function loadCadModel(url: string): Promise<CadModel> {
	const loader = new GLTFLoader();
	const gltf = await loader.loadAsync(url);
	return createCadModel(gltf.scene);
}

/**
 * Construct a Cad Model from the root node of a CAD assembly graph. All meshes that are
 * children of the root are added to the cad model as parts. Poses, materials, textures and transparency
 * properties are correctly taken care of by the Cad Model object. The object returned can be added to the
 * threeJS scene and always provides better semantics and better performances than the raw threeJS scene graph.
 *
 * @param root The root node of the CAD scene graph
 * @returns a CadModel object optimized for high-performance cad rendering.
 */
export function createCadModel(root: Object3D): CadModel {
	// Adjusting all textures to linear encoding
	adjustTexturesEncoding(root);

	return new CadModel(root);
}

/**
 * Creates a StreamCadModel from a given URL of the metadata describing list of chunks.
 *
 * @param url The URL pointing to the metadata of the stream cad model.
 * @param getMaxByteLength function returning the maximum number of bytes to load in memory.
 * @returns A StreamCadModel object
 */
export async function createStreamCadModel(url: string, getMaxByteLength: () => number): Promise<StreamCadModel> {
	const chunks = await loadStreamCadModelChunks(new URL(url));
	return new StreamCadModel(chunks, getMaxByteLength);
}
