
const float PI = 3.141592654;

// viewport dimension
uniform highp vec4 uViewport;
// panorama texture  (color)
uniform samplerCube uCurTex;
uniform samplerCube uTarTex;
uniform mat4 uCurInvPVMMatrix;
uniform mat4 uTarInvPVMMatrix;
uniform float uBlendStep;
uniform vec2 uRectMin;
uniform vec2 uRectMax;

/**
 * Compute the texture coordinates on the cubemap from the texture coordinates on the viewport
 * 
 * @param vec - the fragemnt coordinates in canvas space in range [0, 1]
 * @param invPVMMatrix - the inverse project view model matrix for the target cuebmap
 */
vec3 computeTexCoord(vec2 vec, mat4 invPVMMatrix) {
    // depth 0.9 is used because 1.0 fails on android, -1.0 fails on VR, 0.999 gives artifact on ios, 0.9 works everywhere
    vec4 v = vec4(vec * 2.0 - 1.0, 0.9, 1.0);
    
    v = invPVMMatrix * v;
    return normalize(v.xyz / v.w);
}


void main() {
    vec2 fTexCoord = (gl_FragCoord.xy - uViewport.xy) / uViewport.zw;
    vec2 texCoordInsideRect = uRectMin + (uRectMax - uRectMin) * fTexCoord;
    vec2 texCoordInterp = (1.0 - uBlendStep) * fTexCoord + uBlendStep * texCoordInsideRect;
    vec3 curTexSpherical = computeTexCoord(texCoordInterp, uCurInvPVMMatrix);

    float fcube = uBlendStep * uBlendStep * uBlendStep;
    vec2 rectMin = (1.0 - fcube) * uRectMin;
    vec2 rectMax = fcube * vec2(1.0, 1.0) +  (1.0 - fcube) * uRectMax;
    
    if(fTexCoord.x >= rectMin.x && fTexCoord.x <= rectMax.x && 
        fTexCoord.y >= rectMin.y && fTexCoord.y <= rectMax.y)
    {
        //we are inside to rect
        vec2 texCoordTo = (fTexCoord - rectMin) / (rectMax - rectMin);
        vec2 d = texCoordTo - vec2(0.5, 0.5);
        float manhattanDist = max(abs(d.x), abs(d.y));
        //Inside to rect we want to blend away the borders of the to image to remove abrupt edge with from image outside the rect
        float blendBorders = (0.5 - manhattanDist) * 2.0;
        float blend = min(fcube, blendBorders);
        // But we also want that towards the end of the transition the to image is blended over the from image to avoid
        // abrupt change at the end of the transition.
        float finalBlend = (1.0 - fcube) * blend + fcube * fcube;
        vec3 tarTexSpherical = computeTexCoord(texCoordTo, uTarInvPVMMatrix);
        gl_FragColor = (1.0 - finalBlend) * textureCube(uCurTex, curTexSpherical) + finalBlend * textureCube(uTarTex, tarTexSpherical);
    }
    else
    {
        //we are outside to rect
        gl_FragColor = textureCube(uCurTex, curTexSpherical);
    }
}
