import { assert, EXPIRATION_NEVER_DATE, GUID } from "@faro-lotv/foundation";
import { isValid } from "@faro-lotv/ielement-types";
import { ProjectApi } from "../project-api";
import {
  SignElementUrlResult,
  SignElementUrlsOutcome,
} from "../project-api-types";

/** The value returned by the signIElementUris function */
type SignUrisReturn = {
  /** When the new signatures will expires */
  expiresOn: Date;

  /** All the signed urls */
  signedUrls: string[];
};

/**
 * Request the backend to sign some uris linked to an IElement
 *
 * The signature requests are attached to a specific IElement ID, as the user access is checked
 * against a specific element id
 * If the url can't be signed the original url will be returned to remain compatible with serving
 * files that can't be signed (Eg: Demo project data)
 *
 * @param projectApi client instance to use to request the signatures
 * @param elementId of the element to sign the uri for
 * @param uris to sign
 * @returns the signed urls and the expire date of the new signature
 */
export async function signIElementUris(
  projectApi: Pick<ProjectApi, "signUrls">,
  elementId: GUID,
  uris: string[],
): Promise<SignUrisReturn> {
  try {
    const res = await projectApi.signUrls({
      elements: [
        {
          elementId,
          urls: uris,
        },
      ],
    });
    const elementResult = res.elements.at(0);
    assert(
      elementResult?.outcome === SignElementUrlsOutcome.succeeded,
      `SignElementUrlsOutcome: ${elementResult?.outcome}`,
    );
    assert(
      elementResult.urls.every(
        ({ result }) => result === SignElementUrlResult.succeeded,
      ),
      `SignElementUrlResults: ${elementResult.urls.map(({ result }) => result)}`,
    );
    assert(
      elementResult.urls.length === uris.length,
      "SignElement length mismatch",
    );

    const signedUrls = elementResult.urls.map(({ signedUrl }) => signedUrl);
    assert(signedUrls.every(isValid), `SignedUrls: ${signedUrls.join(", ")}`);
    return {
      signedUrls,
      expiresOn: new Date(res.expiresOn),
    };
  } catch (reason) {
    // TODO (https://faro01.atlassian.net/browse/SWEB-5212): Remove url fallback when the migration to signed urls will be completed
    // For now just log the error to the console to be able to diagnose when the signing will fail
    // eslint-disable-next-line no-console
    console.warn(`Failed to sign urls for element ${elementId}: ${reason}`);
    return {
      signedUrls: uris,
      expiresOn: EXPIRATION_NEVER_DATE,
    };
  }
}
