import { assert } from "@faro-lotv/foundation";

/**
 * Decode a CDI(compressed depth image) buffer to an array of floats
 *
 * @param view a DataView over the input encoded buffer
 * @returns a Float32Array with the decoded depths
 */
export function decodeCdiBuffer(view: DataView): Float32Array {
	const CDI_HEADER = "CDI";
	const HEADER_START = 0;
	const VER_START = 3;
	const SCALE_START = 4;
	const WIDTH_START = 12;
	const HEIGHT_START = 14;
	const DATA_START = 16;

	const header = getAsciiString(view, HEADER_START, CDI_HEADER.length);
	assert(header === CDI_HEADER, "Input buffer header does not match the expected header for compressed depth images");

	const ver = view.getUint8(VER_START);
	assert(ver === 1, "The current decoding code support only version 1 of the compressed depth image format");

	const scale = view.getFloat64(SCALE_START, true);
	const width = view.getUint16(WIDTH_START, true);
	const height = view.getUint16(HEIGHT_START, true);

	const size = width * height;
	return parseDepths(view, DATA_START, size, scale);
}

/**
 * Compose the encoded u32 normalized values in the CDI payload and converts them to the original float depth values
 *
 * For each float depth value the code need to:
 * - extract the 4 byte components that are split in 4 sub-arrays
 * - reverse the delta on the single components (composing each component with the previous value, wrapping)
 * - merge the 4 bytes in a Uint32 using a little endian encoding
 * - apply the scale defined in the CDI file header to map the normalized integer depth to the original real depth value
 *
 * @param view over the encoded buffer
 * @param start of the data payload that contains the 4 components arrays
 * @param size of the final image, that matches the size of each components array
 * @param scale to map between the normalized integer depths and the real float depth
 * @returns an array with all the decoded float depths value
 */
function parseDepths(view: DataView, start: number, size: number, scale: number): Float32Array {
	// Array that will contains the returned depths
	const depths = new Float32Array(size);

	// Array used to compose 8 byte in a Uint32 and reverse the delta of the components
	// initially it contains all zeroes, so we can safely use it to reverse the delta of the first
	// value in the components arrays
	const parts = new Uint8Array(4);
	const u32view = new DataView(parts.buffer);

	// Half the scale, used to convert between the normalized integer to the real float value
	const halfScale = scale / 2;

	for (let i = 0; i < size; ++i) {
		// Compute the normalized depth value merging the 4 separate components
		// reversing the delta composing with the previous processed value
		parts[0] += view.getUint8(start + size * 0 + i);
		parts[1] += view.getUint8(start + size * 1 + i);
		parts[2] += view.getUint8(start + size * 2 + i);
		parts[3] += view.getUint8(start + size * 3 + i);
		const normalizedDepth = u32view.getUint32(0, true);

		// Compute the depth float value applying the defined scale
		if (normalizedDepth === 0) {
			depths[i] = 0;
		} else {
			depths[i] = normalizedDepth * scale + halfScale;
		}
	}
	return depths;
}

/**
 * @param view on the raw data buffer
 * @param from index of the first byte to get
 * @param length of the string to get
 * @returns a string computed from the ascii values in the data buffer
 */
function getAsciiString(view: DataView, from: number, length: number): string {
	return new TextDecoder().decode(new Uint8Array(view.buffer, from, length));
}
