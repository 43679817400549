import { OVERRIDE_RENDERING_CALL } from "@faro-lotv/spatial-ui";
import { RenderCallback, useFrame } from "@react-three/fiber";
import { useContext, useLayoutEffect } from "react";
import { ViewContext } from "./view";

/**
 * @returns A component that will replace the current render call in R3F with the one passed in the props
 */
function CustomR3FRenderCall({ callback }: CustomRenderCallProps): null {
  useFrame((state, delta, frames) => {
    callback(state, delta, frames);
  }, OVERRIDE_RENDERING_CALL);

  return null;
}

type CustomViewRenderCallProps = CustomRenderCallProps & {
  /** The context of the current ACT View this component is rendered */
  viewContext: ViewContext;
};

/**
 * @returns A component that will replace the current render call in a ACT View with the one passed in the props
 */
function CustomViewRenderCall({
  callback,
  viewContext,
}: CustomViewRenderCallProps): null {
  useLayoutEffect(() => {
    const oldCallback = viewContext.renderCallback;
    viewContext.renderCallback = callback;
    return () => {
      viewContext.renderCallback = oldCallback;
    };
  }, [callback, viewContext]);

  return null;
}

export type CustomRenderCallProps = {
  /** The function to use to render the current scene, replace basic R3F rendering */
  callback: RenderCallback;
};

/**
 * @returns A component that will replace the current render call but will work even used inside a ACT View
 */
export function CustomRenderCall({
  callback,
}: CustomRenderCallProps): JSX.Element {
  const viewContext = useContext(ViewContext);

  if (viewContext) {
    return (
      <CustomViewRenderCall callback={callback} viewContext={viewContext} />
    );
  }
  return <CustomR3FRenderCall callback={callback} />;
}
