import { AbortError, TypedEvent } from "@faro-lotv/foundation";
import { Texture } from "three";
import { EquirectangularDepthImage } from "../Pano/EquirectangularDepthImage";
import { Loader } from "../Utils/Loader";
import { lotvCache } from "../Utils/LotvCache";
import { TextureFetchRequest } from "../Utils/TextureLoader";
import { DepthRequest, WSInstance } from "./WSInstance";
import { WSScanDesc } from "./responses/WSScanDesc.gen";

/**
 * Select the next width to use from a list of available widths
 *
 * @param availableWidths List of available widths
 * @param urlFunction Function to compute image url from width
 * @param minWidth Min width to fetch
 * @param maxWidth Max width to fetch
 * @param currentWidth Current loaded width
 * @param cache Cache to check if images are already available to skip download
 * @returns The next width to load if there's one better or undefined
 */
export function nextBestWidth(
	availableWidths: number[],
	urlFunction: (width: number) => string,
	minWidth: number,
	maxWidth: number,
	currentWidth?: number,
	cache = lotvCache,
): number | undefined {
	const widths = availableWidths.filter((w) => w > (currentWidth ?? minWidth - 1) && w <= maxWidth);
	for (const width of widths) {
		if (cache.isCached(urlFunction(width))) {
			return width;
		}
	}
	if (widths.length === 0) return undefined;
	return widths[widths.length - 1];
}

/**
 * Compute the url of the next image to fetch if a better one is available
 *
 * @param ws The webshare instance we're connected to
 * @param projectName The project name
 * @param desc The current pano description
 * @param options The options (max/min width)
 * @param currentWidth The current loaded width
 * @returns An url to the next image to get or undefined if we already have the best one
 */
export function nextImageUrl(
	ws: WSInstance,
	projectName: string,
	desc: WSScanDesc,
	options: WSPanoObjectOptions,
	currentWidth?: number,
): string | undefined {
	const gray = desc.PanoramaColorWidths.length === 0;
	const widths = gray ? desc.PanoramaGrayWidths : desc.PanoramaColorWidths;
	const urlFunction = (width: number): string => {
		return ws.computePanoTextureUrl(projectName, desc.UUID, gray, width);
	};
	const width = nextBestWidth(widths, urlFunction, options.minWidth, options.maxWidth, currentWidth);
	if (!width) return;
	return ws.computePanoTextureUrl(projectName, desc.UUID, gray, width);
}

/**
 * Default values for the WSPanoObjectOptions
 */
export const WSPanoObjectDefaults = {
	/** Minimum color width to fetch */
	minWidth: 512,
	/** Max color width to fetch */
	maxWidth: 4096,
	/** Min depth width to fetch */
	minDepthWidth: 512,
	/** Max depth width to fetch */
	maxDepthWidth: 1024,
	/** True to auto fetch more data automatically after rendering */
	autoFetch: true,
};

/**
 * Options to customize the WSPanoObject behavior
 */
export type WSPanoObjectOptions = typeof WSPanoObjectDefaults;

/**
 * A webshare pano object, extends EquirectangularObject with webshare fetching logic
 */
export class WSPanoLoader extends Loader {
	/** Event to signal that a new texture is available */
	textureReady = new TypedEvent<Texture>();
	/** Event to signal that a new depth texture is available */
	depthsReady = new TypedEvent<EquirectangularDepthImage>();
	/** Options attached to this loader */
	options = { ...WSPanoObjectDefaults };
	/** Currently loaded color texture */
	texture: Texture;
	/** Currently loaded depth texture */
	depths?: EquirectangularDepthImage;

	/** Pending texture download data if one is pending */
	#pendingTexture?: TextureFetchRequest;
	/** Pending depth download data if one is pending */
	#pendingDepth?: DepthRequest;

	/**
	 * Construct a new WSPanoObject
	 *
	 * @param ws The webshare instance this object is from (to fetch further data)
	 * @param projectName The name of this pano project
	 * @param desc The description of this object
	 * @param initialTexture The initial color texture
	 * @param options Customization options for this object
	 */
	constructor(
		private ws: WSInstance,
		private projectName: string,
		private desc: WSScanDesc,
		private initialTexture: Texture,
		options: Partial<WSPanoObjectOptions> = {},
	) {
		super();
		Object.assign(this.options, options);
		this.texture = initialTexture;
	}

	/**
	 * Start fetching the next resolution of depths if it's missing and no depth fetch is in progress
	 */
	private fetchMissingDepths(): void {
		const depthWidth = nextBestWidth(
			this.desc.DistanceImageWidths,
			(width) => this.ws.computePanoDepthUrl(this.projectName, this.desc.UUID, width),
			this.options.minDepthWidth,
			this.options.maxDepthWidth,
			this.depths?.image.width,
		);
		if (depthWidth && !this.#pendingDepth) {
			const pendingDepth = this.ws.getPanoDepthTexture(this.projectName, this.desc.UUID, depthWidth);
			this.#pendingDepth = pendingDepth;
			this.#pendingDepth.promise
				.then((depthTexture) => {
					this.depths = depthTexture;
					this.depthsReady.emit(this.depths);
				})
				.catch((error) => {
					if (!(error instanceof AbortError)) {
						console.warn("Image depth fetch failed:", error);
					}
				})
				.finally(() => {
					if (this.#pendingDepth === pendingDepth) {
						this.#pendingDepth = undefined;
					}
				});
		}
	}

	/**
	 * Start fetching the next resolution of colors if it's missing and no color fetch is in progress
	 */
	private fetchMissingColors(): void {
		if (this.#pendingTexture) return;
		const url = nextImageUrl(
			this.ws,
			this.projectName,
			this.desc,
			this.options,
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
			this.texture?.image.naturalWidth ?? this.texture?.image.width,
		);
		if (!url) return;
		const pendingTexture = this.ws.textureLoader.load(url);
		this.#pendingTexture = pendingTexture;
		pendingTexture.promise
			.then((texture) => {
				this.texture = texture;
				this.textureReady.emit(this.texture);
			})
			.catch((error) => {
				if (!(error instanceof AbortError)) {
					console.warn("Image fetch failed:", error);
				}
			})
			.finally(() => {
				if (this.#pendingTexture === pendingTexture) {
					this.#pendingTexture = undefined;
				}
			});
	}

	/**
	 * Fetch more high res data if available
	 */
	loadMore(): void {
		// Prioritize depths if missing
		if (this.depths) {
			// If we have depths prioritize colors
			this.fetchMissingColors();
			this.fetchMissingDepths();
		} else {
			this.fetchMissingDepths();
		}
	}

	/**
	 * Cancel pending texture and depths downloads
	 */
	override cancelPending(): void {
		if (this.#pendingTexture !== undefined) {
			this.#pendingTexture.abort.abort();
			this.#pendingTexture = undefined;
		}
		if (this.#pendingDepth !== undefined) {
			this.#pendingDepth.abort.abort();
			this.#pendingDepth = undefined;
		}
	}
}
