import { includes } from "@faro-lotv/foundation";
import { IElement } from "./i-element";
import { compareById } from "./i-element-base";

/**
 * @param reference element to use
 * @param candidates elements to search into
 * @returns the closest element in the candidates list to the reference element
 */
export function pickClosestInTime<
  Candidate extends Element,
  Element extends IElement,
>(reference: Element, candidates: Candidate[]): Candidate | undefined {
  if (includes(candidates, reference, compareById)) {
    return reference;
  }
  const referenceTimePoint = Date.parse(reference.createdAt);
  let target: Candidate | undefined;
  let targetDistance: number | undefined;
  for (const candidate of candidates) {
    if (!target || targetDistance === undefined) {
      target = candidate;
      targetDistance = Math.abs(
        Date.parse(target.createdAt) - referenceTimePoint,
      );
      continue;
    }
    const pcDistance = Math.abs(
      Date.parse(candidate.createdAt) - referenceTimePoint,
    );
    if (pcDistance < targetDistance) {
      target = candidate;
      targetDistance = pcDistance;
    }
  }
  return target;
}

/**
 * Determines whether two elements are created on the same date.
 * The date is determined using the timezone saved in the IElement's timestamp.
 *
 * @returns whether two IElements have been created on the same date
 * @param elementA The first IElement
 * @param elementB The second IElement
 */
export function areCreatedOnSameDate(
  elementA: IElement,
  elementB: IElement,
): boolean {
  const dateA = new Date(elementA.createdAt);
  const dateB = new Date(elementB.createdAt);

  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  );
}
