import { ColorString, neutral } from "../../colors";
import { Checkmark2Icon } from "../../icons";
import { FaroText } from "../../text/faro-text/faro-text";
import { FaroChip, FaroChipProps } from "../chip";

export type FaroChipToggleProps = {
  /** Label for the chip */
  label: FaroChipProps["label"];

  /** True to set this chip as selected */
  selected?: boolean;

  /** True to disable the chip */
  disabled?: boolean;

  /** True to use the dark theme for the chip */
  dark?: boolean;

  /** Notify the chip was clicked */
  onClick: FaroChipProps["onClick"];
};

type Colors = {
  backgroundColor: ColorString;
  color: ColorString;
  borderColor: ColorString;
  focusColor: ColorString;
  selectedBackground: ColorString;
  selectedText: ColorString;
};

const DARK_COLORS: Colors = {
  backgroundColor: neutral[999],
  color: neutral[300],
  borderColor: neutral[300],
  focusColor: neutral[0],
  selectedBackground: neutral[100],
  selectedText: neutral[999],
};

const LIGHT_COLORS: Colors = {
  backgroundColor: neutral[0],
  color: neutral[600],
  borderColor: neutral[300],
  focusColor: neutral[999],
  selectedBackground: neutral[800],
  selectedText: neutral[0],
};

/** @returns a custom FaroChip that can be used as a toggle selector */
export function FaroChipToggle({
  selected,
  disabled,
  dark,
  label,
  ...rest
}: FaroChipToggleProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;
  return (
    <FaroChip
      variant="outlined"
      label={
        <FaroText variant="heading14" color="inherit">
          {label}
        </FaroText>
      }
      borderColor={colors.borderColor}
      backgroundColor={
        selected ? colors.selectedBackground : colors.backgroundColor
      }
      color={selected ? colors.selectedText : colors.color}
      icon={selected ? <Checkmark2Icon /> : undefined}
      {...rest}
      sx={{
        textTransform: "none",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
        "&.MuiChip-root": {
          height: "30px",
          px: "5px",
          py: "8px",
          "&:hover": {
            backgroundColor: selected
              ? colors.selectedBackground
              : `color-mix(in srgb, ${colors.backgroundColor}, ${neutral[500]} 15%)`,
          },
          "&:active": {
            backgroundColor: selected
              ? colors.selectedBackground
              : `${neutral[500]}40`,
          },
          "&:focus": {
            border: `2px solid ${colors.focusColor}`,
          },
        },
      }}
    />
  );
}
