import { AbortError, TypedEvent, isDownloadAbortError } from "@faro-lotv/foundation";
import { Group } from "three";
import { loadGltf } from "../Utils";
import { StreamCadChunk } from "./StreamCadChunk";

/** Max number of retries when failed to download a chunk */
const MAX_RETRY_COUNT = 3;

/**
 * Implementation of fetching model chunks for streaming CAD model
 */
export class StreamCadFetcher {
	/** Event emitted when a chunk is downloaded successfully */
	chunkReady = new TypedEvent<{ chunk: StreamCadChunk; model: Group }>();
	/** Event emitted when a chunk is aborted */
	chunkAborted = new TypedEvent<{ chunk: StreamCadChunk }>();
	/** Event emitted when a chunk failed to download */
	chunkFailed = new TypedEvent<{ chunk: StreamCadChunk; reason: Error }>();

	#isBusy = false;

	/** Controller used to abort chunk downloading */
	#controller: AbortController | undefined = undefined;

	/** @returns Whether the fetcher is currently downloading a chunk */
	get isBusy(): boolean {
		return this.#isBusy;
	}

	/**
	 * Start fetching a chunk. This method returns immediately and will emit
	 * chunkReady/chunkFailed event when a chunk is downloaded success/failed.
	 *
	 * @param chunk The model chunk to download
	 * @returns Whether the fetcher is able to start downloading the chunk
	 */
	fetch(chunk: StreamCadChunk): boolean {
		if (this.#isBusy) {
			// fetcher is currently downloading a chunk
			console.warn("Fetcher is currently downloading a chunk, wait for it to finish.");
			return false;
		}
		this.#isBusy = true;
		this.#fetchChunk(chunk, 0);
		return true;
	}

	/**
	 * Fetch a chunk with retries.
	 *
	 * @param chunk The model chunk to download
	 * @param retryCount The number of retries
	 */
	#fetchChunk(chunk: StreamCadChunk, retryCount: number): void {
		this.#controller = new AbortController();
		loadGltf(chunk.url.toString(), undefined, this.#controller.signal)
			.then((model) => {
				// chunk downloaded successfully
				this.#isBusy = false;
				this.chunkReady.emit({ chunk, model });
			})
			.catch((err: Error) => {
				if (isDownloadAbortError(err)) {
					// download aborted
					this.#isBusy = false;
					this.chunkAborted.emit({ chunk });
					return;
				}
				console.error(err);
				if (retryCount <= MAX_RETRY_COUNT) {
					// try download it again
					this.#fetchChunk(chunk, retryCount + 1);
				} else {
					// chunk download failed
					this.#isBusy = false;
					this.chunkFailed.emit({ chunk, reason: err });
				}
			})
			.finally(() => {
				this.#controller = undefined;
			});
	}

	/** Abort the current fetching chunk before it has completed */
	abort(): void {
		// `isDownloadAbortError` requires `AbortError` to be thrown
		this.#controller?.abort(new AbortError("StreamCadFetcher"));
	}

	/**
	 * Fetch one chunk asynchronously.
	 *
	 * @param chunk The model chunk to download
	 * @returns The fetched group or undefined if download failed
	 */
	async fetchAsync(chunk: StreamCadChunk): Promise<Group | undefined> {
		for (let retryCount = 0; retryCount <= MAX_RETRY_COUNT; retryCount++) {
			try {
				return await loadGltf(chunk.url.toString());
			} catch (err) {
				console.error(err);
			}
		}
	}
}
