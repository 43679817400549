import { neutral } from "@faro-lotv/flat-ui";
import { Box, SxProps, Theme } from "@mui/material";
import { Html } from "@react-three/drei";
import {
  MutableRefObject,
  PropsWithChildren,
  forwardRef,
  useEffect,
} from "react";
import { Vector3 } from "three";
import { roundedCalculatePosition } from "../utils/rounded-calculate-position";

export type DescriptionBoxProps = {
  /** 3D position to anchor the box to. */
  position?: Vector3;

  /** Reference to the target HTML Element that will became the box's parent.  */
  portal?: MutableRefObject<HTMLElement>;

  /** Style to apply to the box. */
  sx?: SxProps<Theme>;
};

/**
 * Component that renders an HTML box from withing the Canvas.
 *
 * @returns the description box.
 */
export const DescriptionBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DescriptionBoxProps>
>(function DescriptionBox(
  {
    position = new Vector3(),
    portal,
    sx,
    children,
  }: PropsWithChildren<DescriptionBoxProps>,
  ref,
): JSX.Element {
  // If the portal prop is set, make the box visible only inside the portal's element area, otherwise clip it out.
  useEffect(() => {
    if (!portal?.current) return;
    // Save the original value to restore it in the cleanup function.
    const oldClickPath = portal.current.style.clipPath;

    // Use the old portal.current because it might have changed before reaching the cleanup function.
    const oldPortal = portal.current;

    // Clip everything outside the portal or view area.
    // Otherwise the component will be shown in the canvas area (it will be shown even outside the view).
    portal.current.style.clipPath =
      "polygon(100% 0%, 100% 100%, 0% 100%, 0% 0%)";

    return () => {
      oldPortal.style.clipPath = oldClickPath;
    };
  }, [portal]);

  return (
    <Html
      ref={ref}
      portal={portal}
      position={position}
      calculatePosition={roundedCalculatePosition}
    >
      <Box
        component="div"
        sx={{
          margin: "0px",
          padding: "0px",
          backgroundColor: `${neutral[900]}CC`,
          backdropFilter: "blur(4px) brightness(28%) opacity(80%)",
          borderRadius: "6px",
          border: `1px solid ${neutral[0]}1A`,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Html>
  );
});
