import { useCallback, useState } from "react";

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((val: T) => T)) => void, () => void];
export function useLocalStorage<T>(
  key: string,
): [T | undefined, (value: T | ((val?: T) => T)) => void, () => void];
/**
 *  Provides ability to store, retrieve the key-value from localStorage
 *
 * @param key The key to be stored in the localStorage
 * @param initialValue The value corresponding to the key to be stored in the localStorage
 * @returns the value of the given key, a function to create/modify the value and a function to remove the value
 */
export function useLocalStorage<T>(
  key: string,
  initialValue?: T,
): [T | undefined, (value: T | ((val?: T) => T)) => void, () => void] {
  // State to store our value
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      throw new Error(
        `Error while getting a value from localStorage: ${error}`,
      );
    }
  });

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val?: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        // Save to local storage
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        throw new Error(
          `Error while setting a value to localStorage: ${error}`,
        );
      }
    },
    [key, storedValue, setStoredValue],
  );

  const removeValue = useCallback(() => {
    try {
      // Remove from local storage
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(key);
      }
      // After removing the value from local storage, resetting the state variable back to undefined, hence the assertion
      setStoredValue(undefined);
    } catch (error) {
      throw new Error(
        `Error while removing a value from localStorage: ${error}`,
      );
    }
  }, [key]);

  return [storedValue, setValue, removeValue];
}
