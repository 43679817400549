import { useThree } from "@react-three/fiber";
import { useState } from "react";
import { Camera, Scene } from "three";
import { RenderPass as ThreeRenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { attachPass } from "../attach-utils";

export type RenderPassProps = {
  /** Custom scene to render in the render pass */
  scene?: Scene;

  /** Custom camera to render in the render pass */
  camera?: Camera;
};

/**
 * @returns An EffectPipeline pass that will render the entire scene
 */
export function RenderPass({ scene, camera }: RenderPassProps): JSX.Element {
  const defaultScene = useThree((s) => s.scene);
  const defaultCamera = useThree((s) => s.camera);

  const effectScene = scene ?? defaultScene;
  const effectCamera = camera ?? defaultCamera;

  const [pass] = useState(() => new ThreeRenderPass(effectScene, effectCamera));

  return <primitive object={pass} attach={attachPass} />;
}
