import { GLSL3, RawShaderMaterial, Texture } from "three";
import frag from "../Shaders/SsCompose.frag";
import vert from "../Shaders/TexturedQuadRaw.vert";
import { makeUniform } from "./Uniforms";
/**
 * A shader to mix two textures, currently working for 1D textures.
 */
export class SsComposeMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uEffectTex: makeUniform<Texture | null>(null),
		uColorTex: makeUniform<Texture | null>(null),
		uDepthTex: makeUniform<Texture | null>(null),
	};

	constructor() {
		super({ glslVersion: GLSL3 });
	}
}
