import { FloorPlanVisibleTilesStrategy, LodFloorPlan } from "@faro-lotv/lotv";
import {
  UPDATE_LOD_STRUCTURES_PRIORITY,
  useLotvDispose,
} from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Vector2 } from "three";
import { FloorPlanIElementData, loadLodFloorPlan } from "./floor-plan-loading";

export type FloorPlanRendererBaseProps = {
  /** FloorPlan to render */
  floorPlan: LodFloorPlan;

  /** The minimum number of pixels for a tile to be visible */
  minPixelSize: number;
};

/**
 * @returns A rendere to render a lod floor plan, do not handle the resources
 *
 * Do not own the floorPlan resources, they need to be disposed externally from this component
 * @see Lotv.safeDispose
 */
export function FloorPlanRendererBase({
  floorPlan,
  minPixelSize,
}: FloorPlanRendererBaseProps): JSX.Element {
  const gl = useThree((state) => state.gl);
  const camera = useThree((state) => state.camera);

  const sheetWidth = floorPlan.tree.width;
  const sheetHeight = floorPlan.tree.height;

  useEffect(() => {
    if (
      floorPlan.visibleTilesStrategy instanceof FloorPlanVisibleTilesStrategy
    ) {
      floorPlan.visibleTilesStrategy.minPixelSize = minPixelSize;
    }
  }, [floorPlan, minPixelSize]);

  // Update the visible tiles on each frame change
  useFrame(() => {
    const size = gl.getSize(new Vector2());

    /**
     * Each frame we compute which tiles should be visible and which not.
     */
    floorPlan.updateVisibleNodes(
      camera,
      size.multiplyScalar(gl.getPixelRatio()),
    );
    // calling this hook with UPDATE_LOD_STRUCTURES_PRIORITY so it is executed
    // after the camera has been moved by controls and animations.
  }, UPDATE_LOD_STRUCTURES_PRIORITY);

  return (
    <primitive
      object={floorPlan}
      // Resize the [1, 1] quad geometry to the full image size
      scale={[sheetWidth, sheetHeight, 1]}
      // Place the origin to the angle and not the center of the geometry
      position-x={sheetWidth / 2}
      position-y={sheetHeight / 2}
      dispose={null}
    />
  );
}

type FloorPlanRendererProps = {
  /** IElement for this floor plan */
  iElement: FloorPlanIElementData;

  /** The minimum number of pixels for a tile to be visible */
  minPixelSize: number;
};

/**
 * @returns Load, manage resources and render an LodFloorPlan
 */
export function FloorPlanRenderer({
  iElement,
  minPixelSize,
}: FloorPlanRendererProps): JSX.Element | null {
  const [floorPlan, setFloorPlan] = useState<LodFloorPlan>();

  useEffect(() => {
    loadLodFloorPlan(iElement).then(setFloorPlan);
  }, [iElement]);

  useLotvDispose(floorPlan);

  if (!floorPlan) return null;

  return (
    <FloorPlanRendererBase floorPlan={floorPlan} minPixelSize={minPixelSize} />
  );
}
