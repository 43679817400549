import { DoubleSide, ShaderMaterial, Texture, UniformsLib, UniformsUtils, Vector3 } from "three";
import cadModelFrag from "../Shaders/CadModel/CadModel.frag";
import cadModelVert from "../Shaders/CadModel/CadModel.vert";
import cadModelIDsFrag from "../Shaders/CadModel/CadModelIDs.frag";
import cadModelIDsVert from "../Shaders/CadModel/CadModelIDs.vert";
import { initShaderChunk } from "../Shaders/InitShaderChunk";
import { makeUniform } from "./Uniforms";

/** The maximum number of color textures available in GPU */
export const MAX_NUMBER_OF_COLOR_TEXTURES = 14;

/**
 * Material to draw a CAD model made of many sub-parts
 */
export class CadModelMaterial extends ShaderMaterial {
	override vertexShader = cadModelVert;

	override fragmentShader = cadModelFrag;

	override uniforms = UniformsUtils.merge([
		UniformsLib.lights,
		{
			poseTexture: makeUniform<Texture | null>(null),
			materialTexture: makeUniform<Texture | null>(null),
			highlightingColor: makeUniform<Vector3 | null>(new Vector3()),
			colorTextures: makeUniform(new Array<Texture>()),
			clippingInLocalTransform: makeUniform(false),
		},
	]);

	/**  Needed so that webglrenderer assigns lights settings to this shader */
	lights = true;

	override defines = {
		COLOR_TEXTURES: 0,
		MAX_NUMBER_OF_COLOR_TEXTURES,
	};

	/**
	 * Constructs a new instance of CadModelMaterial
	 */
	constructor() {
		super();
		this.side = DoubleSide;
		initShaderChunk();
	}

	/** @returns whether this material supports color textures */
	get isTextured(): boolean {
		return this.defines.COLOR_TEXTURES === 1;
	}

	/** Sets whether this material supports color textures */
	set isTextured(t: boolean) {
		this.defines.COLOR_TEXTURES = t ? 1 : 0;
		this.needsUpdate = true;
	}
}

/** A material to draw a color code representing the ID of a single CAD part */
export class CadModelIDsMaterial extends ShaderMaterial {
	override vertexShader = cadModelIDsVert;

	override fragmentShader = cadModelIDsFrag;

	override uniforms = {
		poseTexture: makeUniform<Texture | null>(null),
		drawIdOffset: makeUniform<number>(0),
	};

	/**
	 * Constructs a new instance of CadModelMaterial
	 */
	constructor() {
		super();
		this.side = DoubleSide;
	}
}
