import { GUID } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Moves a data session to a different area
 */
export interface MutationMoveDataSession extends BaseMutation {
  type: MutationTypes.MutationMoveDataSession;

  /** The ID of the area to move to */
  areaId: GUID;
}

/**
 * @returns a mutation to move a data session to a different area
 * @param elementId id of the data session to move
 * @param areaId the area to move it to
 */
export function createMutationMoveDataSession(
  elementId: GUID,
  areaId: GUID,
): MutationMoveDataSession {
  return {
    ...createBaseMutation(MutationTypes.MutationMoveDataSession, elementId),
    areaId,
  };
}
