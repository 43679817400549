import { Divider } from "@mui/material";
import { useRef, useState } from "react";
import { neutral, red } from "../../colors";
import { FaroIconButton } from "../../icon-button/faro-icon-button";
import { ThreeDotsIcon } from "../../icons/icons";
import { FaroMenu, FaroMenuItem } from "../../menu";
import type { AnnotationViewerProps } from "./annotation-viewer-types";

type AnnotationMenuProps = Pick<
  AnnotationViewerProps,
  "canEdit" | "onDelete" | "onEdit" | "onCopyToClipboard" | "onContextMenuOpen"
>;

/**
 * @returns Three dots menu allowing user to trigger different actions (editing, deleting, etc.)
 */
export function AnnotationViewerMenu({
  canEdit,
  onEdit,
  onDelete,
  onCopyToClipboard,
  onContextMenuOpen,
}: AnnotationMenuProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FaroIconButton
        ref={button}
        aria-label="annotation menu"
        color={neutral[0]}
        onClick={(e) => {
          e.stopPropagation();
          onContextMenuOpen?.();
          setIsOpen(true);
        }}
      >
        <ThreeDotsIcon />
      </FaroIconButton>
      <FaroMenu
        dark
        anchorEl={button.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        autoFocus={false}
      >
        {onCopyToClipboard && (
          <FaroMenuItem
            onClick={(e) => {
              onCopyToClipboard();
              setIsOpen(false);
              e.stopPropagation();
            }}
            color={neutral[0]}
            label="Copy to clipboard"
          />
        )}
        {onCopyToClipboard && canEdit && <Divider />}
        {canEdit && (
          <div>
            <FaroMenuItem
              onClick={() => {
                onEdit();
                setIsOpen(false);
              }}
              color={neutral[0]}
              label="Edit"
            />
            <Divider />
            <FaroMenuItem
              onClick={() => {
                onDelete();
                setIsOpen(false);
              }}
              color={red[300]}
              highlightColor={red[300]}
              label="Delete"
            />
          </div>
        )}
      </FaroMenu>
    </>
  );
}
